import difference from 'lodash/difference'
import camelCase from 'lodash/camelCase'

import referralService from '@services/referralService'
import analyticsService from '@services/analyticsService'
import {
  REFERRAL_SHOW_TOGGLE_KEY,
  REFERRAL_SHOW_UPDATE_RECIPIENTS,
  REFERRAL_SHOW_SET_ALERT,
  REFERRAL_SHOW_STATE_CHANGE,
  REFERRAL_SHOW_FORM_FIELD_CHANGE,
  REFERRAL_SHOW_SERVICE_DETAILS_FIELD_CHANGE,
  REFERRAL_SHOW_RESET_FORM,
  REFERRAL_SHOW_FORM_FIELD_DELETE,
  REFERRAL_SHOW_SET_SERVICE_DETAILS_FIELD_ERRORS,
  serviceUnitFields,
  serviceDetailFields
} from '../constants'
import {
  bootstrapApp,
  setAnalyticsReferrer,
  loadEntities,
  updateEntity,
  removeEntity
} from '@actions'
import { getRecord, getAssociation } from '@selectors'
import attachmentService from '@services/attachmentService'
import teamService from '@services/teamService'

export const submitNote = (referralId) => {
  return (dispatch, getState) => {
    const noteContent = getState().referralView.form.note.content.value
    const analyticsReferrer = getState().global.analyticsReferrer

    dispatch(toggleKey('disableSubmitButton', true))
    referralService.createNote(referralId, noteContent, analyticsReferrer).then(response => {
      dispatch(loadEntities(response.data))
      dispatch(setAlert('createNote', null))
      dispatch(updateEntity({
        type: 'referral',
        id: referralId,
        updatedObject: { id: response.data.data.id, type: response.data.data.type },
        path: ['relationships', 'notes'],
        updateMethod: 'append'
      }))

      dispatch(onFormFieldChange('note', 'content', ''))
    }).finally(() => dispatch(toggleKey('disableSubmitButton', false)))
  }
}

export const loadTeams = () => {
  return (dispatch, getState) => {
    if (!getState().referralView.hasFetchedTeams) {
      teamService.search({ usesCoordinate: true }).then(response => {
        dispatch(loadEntities(response))
        dispatch(toggleKey('hasFetchedTeams', true))
      })
    }
  }
}

export const createReferralConsent = (referralId) => {
  return (dispatch) => {
    referralService.createReferralConsent(referralId).then(response => {
      dispatch(toggleKey('previousConsent', true))
      dispatch(toggleKey('showConsentModal', false))
      window.scrollTo(0, 0)
      dispatch(setAlert('consentBanner', {
        type: 'success',
        text: window.local.t('referrals.show.consent.consent_collected')
      }))
    }).catch(() => {
      dispatch(toggleKey('showConsentModal', false))
      window.scrollTo(0, 0)
      dispatch(setAlert('consentBanner', {
        type: 'error',
        text: window.local.t('referrals.show.consent.consent_not_collected')
      }))
    })
  }
}

export const loadReferral = (referralId) => {
  return (dispatch, getState) => {
    referralService.getReferral(referralId).then(response => {
      dispatch(loadEntities(response))
      if (response.data.relationships.referral_consents.data.length > 0) {
        dispatch(toggleKey('previousConsent', true))
      }
      const analyticsReferrer = getState().global.analyticsReferrer
      return analyticsService.track(
        'Referral',
        'Viewed',
        { id: response.data.id },
        analyticsReferrer
      )
    }).then(response => {
      dispatch(setAnalyticsReferrer({
        analyticsReferrerId: response.data.event_id,
        analyticsReferrerType: 'Referral Viewed'
      }))
    })
  }
}

export const loadReferralPdf = () => {
  return (dispatch) => {
    dispatch(loadEntities(window.referralProps))
    dispatch(bootstrapApp(window.bootstrap.data.attributes))
    dispatch(loadEntities(window.bootstrap.data.attributes.current_user))
  }
}

export const resetForm = (formName) => ({
  type: REFERRAL_SHOW_RESET_FORM,
  payload: { formName }
})

export const toggleKey = (key, toggleValue) => ({
  type: REFERRAL_SHOW_TOGGLE_KEY,
  payload: { key, toggleValue }
})

export const updateRecipients = (recipients) => ({
  type: REFERRAL_SHOW_UPDATE_RECIPIENTS,
  payload: { recipients }
})

export const setAlert = (key, alert) => ({
  type: REFERRAL_SHOW_SET_ALERT,
  payload: { key, alert }
})

export const onFormFieldChange = (formName, field, value) => {
  let assignedValue

  // programAssignmentServiceDetails sections must be initialized in this action,
  // resulting in an empty object, to be populated via onServiceDetailsFieldChange
  if (formName === 'programAssignmentServiceDetails') {
    assignedValue = {}
  } else {
    assignedValue = { errors: [], value }
  }

  return {
    type: REFERRAL_SHOW_FORM_FIELD_CHANGE,
    payload: { formName, field, value: assignedValue }
  }
}

export const onServiceDetailsFieldChange = ({ programAssignmentId, field, value }) => ({
  type: REFERRAL_SHOW_SERVICE_DETAILS_FIELD_CHANGE,
  payload: { programAssignmentId, field, value }
})

export const onReferralStateChange = (newState) => ({
  type: REFERRAL_SHOW_STATE_CHANGE,
  payload: { newState }
})

// `fields` can either be an array of fields to delete, or a single string
const onFormFieldDelete = (formName, fields) => ({
  type: REFERRAL_SHOW_FORM_FIELD_DELETE,
  payload: { formName, fields }
})

export const onReferralResourceProgramAssignmentChange = (programAssignments) => {
  return (dispatch, getState) => {
    const previousAssignmentIds = getState().referralView.form
      .referralEncounter
      .referralResourceProgramAssignments
      .value
      .map(assignment => assignment.id)
    const selectedIds = programAssignments.map(assignment => assignment.id)
    const removedIds = difference(previousAssignmentIds, selectedIds)
    const newlySelectedIds = difference(selectedIds, previousAssignmentIds)

    // Update referral encounter form state
    dispatch(onFormFieldChange(
      'referralEncounter',
      'referralResourceProgramAssignments',
      programAssignments
    ))

    // Remove unselected IDs from service details form
    if (removedIds.length) {
      dispatch(onFormFieldDelete('programAssignmentServiceDetails', removedIds))
    }

    // Initialize service details form with newly selected IDs
    newlySelectedIds.forEach(id => {
      dispatch(onFormFieldChange(
        'programAssignmentServiceDetails',
        id,
        {}
      ))
    })

    dispatch(updateExpandedSectionsFromAssignmentChange(removedIds, selectedIds))
  }
}

// First, if program assignments were unselected, remove their IDs from the expanded state
// Then, auto-expand the first selected assignment, if no others are already expanded
const updateExpandedSectionsFromAssignmentChange = (
  removedAssignmentIds,
  selectedAssignmentIds
) => {
  return (dispatch, getState) => {
    const { expandedReferralEncounterModalSections: expandedSections } = getState().referralView

    // First, remove unselected assignments from expandedSections state
    const updatedExpandedSections = difference(expandedSections, removedAssignmentIds)

    // Next, if empty, auto-expand the first selected assignment ID
    if (!updatedExpandedSections.length && selectedAssignmentIds.length) {
      updatedExpandedSections.push(selectedAssignmentIds[0])
    }

    // If changed, dispatch action to update expandedReferralEncounterModalSections
    if (updatedExpandedSections.sort() !== expandedSections.sort()) {
      dispatch(toggleKey(
        'expandedReferralEncounterModalSections',
        updatedExpandedSections
      ))
    }
  }
}

export const onServiceUnitChange = (programAssignmentId) => {
  return (dispatch, getState) => {
    return (newServiceUnit) => {
      // Reset fields dependent on the selected service unit
      const fieldPaths = serviceUnitFields.map(field => `${programAssignmentId}.${field}`)
      dispatch(onFormFieldDelete('programAssignmentServiceDetails', fieldPaths))

      dispatch(onServiceDetailsFieldChange({
        programAssignmentId,
        field: 'serviceUnit',
        value: newServiceUnit
      }))
    }
  }
}

export const onAllServiceDetailsFieldChange = (field) => {
  return (dispatch, getState) => {
    return (value) => {
      const { programAssignmentServiceDetails } = getState().referralView.form

      Object.keys(programAssignmentServiceDetails).forEach(programAssignmentId => {
        dispatch(onServiceDetailsFieldChange({
          programAssignmentId,
          field,
          value
        }))
      })
    }
  }
}

export const onReferralStateUpdate = (referralId) => {
  return (dispatch, getState) => {
    const referral = getRecord(getState(), 'referral', referralId)
    const currentState = getAssociation(getState(), referral, 'currentState')
    const newState = getState().referralView.newState
    const cancelationReason = (newState.contextCode && newState.contextCode !== 'other_context') ||
      (newState.contextCode === 'other_context' && newState.context)
    const analyticsReferrer = getState().global.analyticsReferrer

    if (newState.value === 'canceled' && !cancelationReason) {
      dispatch(toggleKey('showCancelStateModal', true))
      return
    } else {
      dispatch(toggleKey('showCancelStateModal', false))
    }

    referralService.createState(
      referralId,
      currentState.id,
      newState.value,
      newState.contextCode === 'other_context' ? newState.context : null,
      newState.contextCode,
      analyticsReferrer
    ).then((response) => {
      dispatch(loadEntities(response.data))
      dispatch(updateEntity({
        type: 'referral',
        id: referralId,
        updatedObject: { id: response.data.data.id, type: response.data.data.type },
        path: ['relationships', 'currentState']
      }))
      dispatch(updateEntity({
        type: 'referral',
        id: referralId,
        updatedObject: { id: response.data.data.id, type: response.data.data.type },
        path: ['relationships', 'referralStates'],
        updateMethod: 'append'
      }))

      const responseNewState = response.data.data.attributes.status

      dispatch(onReferralStateChange({
        label: responseNewState,
        value: responseNewState
      }))
      dispatch(setAlert('updateReferralState', null))
      dispatch(toggleKey('showEditStateView', false))
    }).catch((error) => {
      dispatch(setAlert('updateReferralState', {
        type: 'error',
        text: error.response.data.message || 'The server encountered an error.'
      }))
      dispatch(toggleKey('showEditStateView', false))
    })
  }
}

export const submitAssignedUsers = (referralId) => {
  return (dispatch, getState) => {
    referralService.updateRecipients(referralId, getState().referralView.recipients).then((response) => {
      dispatch(loadEntities(response.data.assignedUsers))
      dispatch(updateEntity({
        type: 'referral',
        id: referralId,
        updatedObject: response.data.assignedUsers.data,
        path: ['relationships', 'assignedUsers']
      }))
      dispatch(toggleKey('showEditAssignedUsersView', false))
      dispatch(setAlert('assignedUsers', {
        type: 'success',
        text: window.local.t('referrals.edit_recipients.success')
      }))
    }).catch(() => {
      dispatch(setAlert('assignedUsers', {
        type: 'error',
        text: window.local.t('referrals.edit_recipients.failure')
      }))
    })
  }
}

export const uploadAttachment = (referralId, file) => {
  return (dispatch, getState) => {
    attachmentService.create(file, 'referral_id', referralId, getState().global.analyticsReferrer).then((response) => {
      dispatch(loadEntities(response.data))
      dispatch(updateEntity({
        type: 'referral',
        id: referralId,
        updatedObject: { id: response.data.data.id, type: response.data.data.type },
        path: ['relationships', 'attachments'],
        updateMethod: 'append'
      }))
      dispatch(toggleKey('showUploadAttachmentModal', false))
    }).catch(error => {
      dispatch(setAlert('uploadAttachment', {
        type: 'error',
        text: error.response.data.errors[0].detail
      }))
    })
  }
}

export const destroyAttachment = (referralId, attachmentId) => {
  return (dispatch, getState) => {
    attachmentService.destroy(attachmentId).then((response) => {
      dispatch(toggleKey('pendingDeleteAttachmentId', null))
      dispatch(removeEntity({
        type: 'attachment',
        id: attachmentId
      }))
    }).catch(error => {
      dispatch(setAlert('destroyAttachment', {
        type: 'error',
        text: error.response.data.errors[0].detail
      }))
    })
  }
}

export const submitEncounter = (referralId) => {
  return (dispatch, getState) => {
    dispatch(toggleKey('disableSubmitButton', true))
    const { referralEncounter, programAssignmentServiceDetails } = getState().referralView.form
    const { analyticsReferrer } = getState().global

    referralService.createEncounter({
      referralId,
      analyticsReferrer,
      referralEncounter,
      programAssignmentServiceDetails
    }).then(response => {
      dispatch(toggleKey('disableSubmitButton', false))
      dispatch(loadEntities(response.data))
      dispatch(resetEncounterModal())
      displayTimedEncounterSuccessModal(dispatch)
      fetchUpdatedReferralState(dispatch, referralId)
    }).catch(({ response }) => {
      dispatch(toggleKey('disableSubmitButton', false))
      const errors = serviceDetailErrors(response.data.errors)
      const failedAssignmentIds = Object.keys(errors).map(id => parseInt(id))

      Object.entries(errors).forEach(([programAssignmentId, fields]) => {
        Object.entries(fields).forEach(([field, errors]) => {
          dispatch(setServiceDetailsFieldErrors({
            programAssignmentId,
            field,
            errors
          }))
        })
      })

      dispatch(setAlert('referralEncounter', {
        type: 'error',
        text: window.local.t('referrals.encounter_modal.failure')
      }))

      dispatch(expandProgramAssignmentSectionsWithErrors(failedAssignmentIds))
    })
  }
}

export const resetEncounterModal = () => {
  return (dispatch, getState) => {
    dispatch(setAlert('referralEncounter', null))
    dispatch(resetForm('referralEncounter'))
    dispatch(resetForm('programAssignmentServiceDetails'))
    dispatch(toggleKey('expandedReferralEncounterModalSections', []))
  }
}

export const resetServiceDetailsFormErrors = () => {
  return (dispatch, getState) => {
    const serviceDetailsForm = getState().referralView.form.programAssignmentServiceDetails
    const programAssignmentIds = Object.keys(serviceDetailsForm)

    programAssignmentIds.forEach(programAssignmentId => {
      serviceDetailFields.forEach(field => {
        // Only reset errors for fields already in state
        if (!serviceDetailsForm[programAssignmentId][field]) return

        dispatch(setServiceDetailsFieldErrors({
          programAssignmentId,
          field,
          errors: []
        }))
      })
    })
  }
}

// NOTE: Exported to use when setting up tests for other actions, not for testing directly
export const setServiceDetailsFieldErrors = ({ programAssignmentId, field, errors }) => ({
  type: REFERRAL_SHOW_SET_SERVICE_DETAILS_FIELD_ERRORS,
  payload: { programAssignmentId, field, errors }
})

const fetchUpdatedReferralState = (dispatch, referralId) => {
  referralService.getReferral(
    referralId,
    {
      included: ['referral_states', 'current_state']
    }
  ).then(response => {
    dispatch(loadEntities(response))
  })
}

const displayTimedEncounterSuccessModal = (dispatch) => {
  dispatch(toggleKey('referralEncounterModalPage', '3'))

  setTimeout(() => {
    dispatch(toggleKey('showEncounterModal', false))
    dispatch(toggleKey('referralEncounterModalPage', '1'))
  }, 2000)
}

const serviceDetailErrors = (errors) => {
  return errors
    .filter(e => serviceDetailFields.includes(camelCase(e.meta.field_type)))
    .reduce((acc, error) => {
      const {
        referral_resource_program_assignment_id: programAssignmentId,
        field_type: fieldType
      } = error.meta
      const field = camelCase(fieldType)

      if (!acc[programAssignmentId]) {
        acc[programAssignmentId] = {}
      }

      if (!acc[programAssignmentId][field]) {
        acc[programAssignmentId][field] = []
      }

      acc[programAssignmentId][field].push(error.detail)

      return acc
    }, {})
}

const expandProgramAssignmentSectionsWithErrors = (sectionIdsWithErrors) => {
  return (dispatch, getState) => {
    const { expandedReferralEncounterModalSections: expandedSections } = getState().referralView

    dispatch(toggleKey(
      'expandedReferralEncounterModalSections',
      [
        ...expandedSections,
        ...sectionIdsWithErrors
      ]
    ))
  }
}
