import React from 'react'
import { useParams } from 'react-router-dom'

import useFetchCartShareData from './hooks/useFetchCartShareData'
import useTitle from '@hooks/useTitle'

import Spacing from '@components/Spacing'
import Grid from '@material-ui/core/Grid'
import LoadingDataWrapper from '@components/LoadingDataWrapper'
import CartShare from './CartShare'

const CartShareScene = (props) => {
  const { cartShareUuid } = useParams()
  const { loading, id } = useFetchCartShareData(cartShareUuid)

  useTitle(window.local.t('cart_share.page_title'))

  return (
    <Spacing paddingBottom={4}>
      <LoadingDataWrapper
        loadingData={loading}
        display='block'
        size={80}
      >
        <>
          {id && <CartShare cartShareUuid={id}>
            <CartShare.Header />
            <Spacing paddingX={{ xs: 3, sm: 4, md: 12 }} paddingY={8}>
              <Grid
                container
                spacing={8}
                direction='row'
                justify='center'
              >
                <Grid item xs={12} sm={9} md={8}>
                  <CartShare.ReferralList />
                </Grid>
                <Grid item xs={12} sm={3} md={4}>
                  <CartShare.DownloadButton />
                </Grid>
              </Grid>
            </Spacing>
          </CartShare>}
        </>
      </LoadingDataWrapper>
    </Spacing>
  )
}

export default CartShareScene
