import {
  UNSTARTED,
  IN_REVIEW,
  SERVICE_IN_PROGRESS,
  SERVICE_COMPLETED,
  CANCELED
} from '@constants/referralState'

const isUnstarted = (state) => state === UNSTARTED
const isInReview = (state) => state === IN_REVIEW
const isServiceInProgress = (state) => state === SERVICE_IN_PROGRESS
const isServiceCompleted = (state) => state === SERVICE_COMPLETED
const isCanceled = (state) => state === CANCELED

export {
  isUnstarted,
  isInReview,
  isServiceInProgress,
  isServiceCompleted,
  isCanceled
}
