import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import some from 'lodash/some'
import { connect, useSelector } from 'react-redux'
import classnames from 'classnames'

import useEffectOnMount from '@hooks/useEffectOnMount'
import useLocalStorage from '@hooks/useLocalStorage'

import Alert from '@components/Alert'
import AttestationAlert from './AttestationAlert'
import CartFeedbackCard from '@components/CartFeedbackCard'
import CheckoutHeader from './CheckoutHeader'
import Collapse from '@components/Animations/Collapse'
import ContentSection from '@components/ContentSection'
import CreateReferralsButton from './CreateReferralsButton'
import LoadingDataWrapper from '@components/LoadingDataWrapper/LoadingDataWrapper'
import ErrorText from '@components/ErrorText/ErrorText'
import EmptyCart from './EmptyCart'
import { Header } from '@components/Header'
import PageTitle from '@components/PageTitle'
import Spacing from '@components/Spacing'
import PatientReferralSidebar from './PatientReferralSidebar'
import ReferralCard from './referralCard'
import SubmitCartModal from './SubmitCartModal'
import {
  setCartItems,
  updateCartItem,
  toggleClientConsentRequired,
  toggleClientConsentGranted,
  submitCart
} from '@features/referralCheckout'
import { useGetCartQuery } from '@services/internalAPI'
import {
  selectRemainingCartItems,
  selectCartItemsWithErrors,
  isClientConsentGranted,
  isClientConsentRequired
} from './selectors'
import {
  countRemainingCartItemsByTiers,
  getCartItemsForReferralTiers,
  isConsentGrantedForCartItems
} from './ReferralCheckoutUtil'
import { NETWORKED_TIERS } from '@constants/networkTiers'

import './ReferralCheckout.scss'

const ReferralCheckout = (props) => {
  const params = new URLSearchParams(window.location.search)
  const patientId = params.get('patient_id')
  const [removeSelectedProgramIds] = useLocalStorage('selectedProgramIds', []).slice(-1)
  const { data, error, isLoading } = useGetCartQuery(patientId)
  const links = data ? data.data.links : {}
  const { name: currentCompany } = useSelector((store) => store.global.currentCompany.attributes)

  const patientData = restructuredPatientData(data)

  const unsubmittedCartItem = () => {
    return props.remainingCartItems.some(cartItem =>
      !cartItem.submittedSuccess && (cartItem.note || cartItem.file))
  }

  const leavingPageWarning = (event) => {
    if (unsubmittedCartItem()) {
      event.preventDefault()
      event.returnValue = false
      return true
    }
  }

  useEffectOnMount(() => {
    props.setCartItems(props.cartItems)
  })

  useEffect(() => {
    if (props.remainingCartItems.length) {
      window.removeEventListener('beforeunload', leavingPageWarning)
      window.addEventListener('beforeunload', leavingPageWarning)
    }

    return () => {
      window.removeEventListener('beforeunload', leavingPageWarning)
    }
  })

  const handleClientConsentClicked = (tiers) => {
    return () => toggleClientConsentGranted(tiers)
  }

  const onChangeNote = (cartItemId) => {
    return (value) => props.updateCartItem(cartItemId, { note: value })
  }

  const onRemoveAttachment = (cartItemId) => {
    return (event) => props.updateCartItem(cartItemId, { file: null })
  }

  const onFileUpload = (cartItemId) => {
    return (event) => props.updateCartItem(cartItemId, { file: event.target.files[0] })
  }

  const countSuccessfullySubmittedCartItems = () => {
    return props.storedCartItems.filter((cartItem) => cartItem.submittedSuccess).length
  }

  const getCartItems = (tiers) => {
    return getCartItemsForReferralTiers(props.remainingCartItems, tiers)
  }

  const countRemainingCartItems = (tiers) => {
    return countRemainingCartItemsByTiers(props.remainingCartItems, tiers)
  }

  const toggleClientConsentGranted = (tiers) => {
    const cartItemIds = getCartItems(tiers).map((cartItem) => cartItem.id)
    props.toggleClientConsentGranted(cartItemIds)
  }

  const toggleClientConsentRequired = (cartItemId) => {
    return (event) => props.toggleClientConsentRequired(cartItemId)
  }

  const showAttestationAlert = (tiers) => {
    const cartItems = getCartItems(tiers)

    return some(cartItems, { consentRequired: true })
  }

  const isClientConsentGrantedForSection = (tiers) => {
    return isConsentGrantedForCartItems(getCartItems(tiers))
  }

  const submitCartItems = () => {
    props.submitCart(
      patientData,
      props.analytics
    )
    // clear selected ids from local storage
    removeSelectedProgramIds()
  }

  const renderCartItems = (tiers) => {
    return getCartItems(tiers).map((cartItem) => (
      <ReferralCard
        key={cartItem.id}
        onChangeNote={onChangeNote(cartItem.id)}
        onFileUpload={onFileUpload(cartItem.id)}
        onToggleContactService={toggleClientConsentRequired(cartItem.id)}
        onRemoveAttachment={onRemoveAttachment(cartItem.id)}
        analytics={props.analytics}
        cartItem={cartItem}
        resourceSitesPath={links.resource_sites}
        submitComplete={cartItem.submittedSuccess} />
       )
    )
  }

  const renderCreateReferralsButton = (isMobile) => (
    <CreateReferralsButton
      clientConsentRequired={props.clientConsentRequired}
      clientConsentGranted={props.clientConsentGranted}
      numberOfRemainingItems={props.remainingCartItems.length}
      submitCartItems={submitCartItems}
      numberOfCoordinateReferrals={countRemainingCartItems(NETWORKED_TIERS)}
      showReferAnotherPatientForm={false}
      isMobile={isMobile} />
  )

  const renderSidebar = (isMobile) => (
    <PatientReferralSidebar
      patientsOptionsPath={links.patient_options}
      patientsPath={links.patients}
      numberOfCoordinateReferrals={countRemainingCartItems(NETWORKED_TIERS)}
      patient={patientData}
      cartPath={links.self}
      printableCartPath={links.print}
      createReferralsButton={renderCreateReferralsButton(false)}
      isMobile={isMobile}
    />
  )

  const renderReferralSection = (key, tiers, isLastItem, company) => {
    return (
      <Collapse key={key} collapse={countRemainingCartItems(tiers) === 0}>
        <section
          className={classnames(
            'ReferralCheckout__ReferralSection',
            { 'ReferralCheckout__ReferralSection--last': isLastItem }
          )}
          data-test={`checkout-${key}-referrals`}
          data-testid={`ReferralCheckout__ReferralSection--${key}`}
        >
          <div className='ReferralCheckout__ReferralTitle'>
            <Header rank='3' text={window.local.t(`checkout.${key}_title`)} />
            <p>{window.local.t(`checkout.${key}_context`, { client_name: patientData.full_name })}</p>
          </div>
          {showAttestationAlert(tiers) &&
            <AttestationAlert
              clientConsentGranted={isClientConsentGrantedForSection(tiers)}
              clientName={patientData.full_name}
              onConsentClicked={handleClientConsentClicked(tiers)}
              sectionKey={key}
              company={company}
            />

          }
          {renderCartItems(tiers)}
        </section>
      </Collapse>
    )
  }

  const renderReferralSections = () => {
    return (
      <>
        {props.referralSections.map(({ key, tiers }, index, fullArray) => {
          const isLastItem = index === (fullArray.length - 1)
          return renderReferralSection(key, tiers, isLastItem, currentCompany)
        })}

        <Spacing marginBottom={8}>
          <CartFeedbackCard cartID={props.cartId} clientID={patientData.id} />
        </Spacing>
      </>
    )
  }

  const renderMainContent = () => {
    const itemsRemaining = props.remainingCartItems.length > 0
    const showErrorAlert = props.cartItemsWithErrors.length > 0 && itemsRemaining
    return (
      <>
        {showErrorAlert &&
          <Alert
            title={window.local.t('checkout.error.alert.title')}
            body={window.local.t('checkout.error.alert.body')}
            type='error' />
        }
        {renderReferralSections()}
        {!itemsRemaining && <EmptyCart />}
      </>
    )
  }

  return (
    <PageTitle title={window.local.t('checkout.document_title')}>
      <SubmitCartModal
        open={props.submitModalOpened}
        completed={countSuccessfullySubmittedCartItems()}
        totalToComplete={props.remainingCartItems.length}
        patient={patientData}
        cartId={props.cartId}
      />
      <LoadingDataWrapper
        loadingData={isLoading}
        display='block'
        size={80}
      >
        <>
          {
            data &&
              <>
                <CheckoutHeader resourcePath={links.add_resources} />
                <ContentSection>
                  <Grid container spacing={5} justify='space-between'>
                    <Grid item xs={12} md={8}>
                      {renderMainContent()}
                    </Grid>
                    <Hidden smDown>
                      <Grid item md={4} style={{ maxWidth: '340px' }} role='regionForContaint'>
                        {renderSidebar(false)}
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Hidden mdUp>
                    <div>
                      {renderSidebar(true)}
                      {renderCreateReferralsButton(true)}
                    </div>
                  </Hidden>
                </ContentSection>
              </>
          }
          {error && <ErrorText text='Not authorized.' isCentered withIcon />}
        </>
      </LoadingDataWrapper>
    </PageTitle>
  )
}

//  To get patient data as similar as it get it from props.patient
export const restructuredPatientData = (data) => {
  const correctedPatientData1 = {}
  if (!data) {
    return correctedPatientData1
  }
  const patientObject = data.included.find(obj => obj.type === 'patient')
  // To add patient path in the patientobject
  const patient_path = patientObject.links.self
  const phoneObject = data.included.find(obj => obj.type === 'phone')
  // To add patient phones array patientobject
  const phones = []
  if (phoneObject) { phones.push(phoneObject.attributes) }

  const correctedPatientData = {
    ...patientObject.attributes,
    patient_path,
    phones
  }
  return correctedPatientData
}

const cartItem = PropTypes.shape({
  id: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  organizationName: PropTypes.string,
  serviceName: PropTypes.string.isRequired,
  matchingPrograms: PropTypes.array.isRequired,
  serviceOfferings: PropTypes.array.isRequired
})

ReferralCheckout.propTypes = {
  analytics: PropTypes.shape({
    referrerId: PropTypes.string,
    referrerType: PropTypes.string,
    segmentFunnelId: PropTypes.string
  }).isRequired,
  clientConsentRequired: PropTypes.bool.isRequired,
  clientConsentGranted: PropTypes.bool.isRequired,
  cartId: PropTypes.number.isRequired,
  cartItems: PropTypes.arrayOf(
    cartItem
  ).isRequired,
  remainingCartItems: PropTypes.arrayOf(
    cartItem
  ).isRequired,
  cartItemsWithErrors: PropTypes.arrayOf(
    cartItem
  ).isRequired,
  storedCartItems: PropTypes.arrayOf(
    cartItem
  ).isRequired,
  patient: PropTypes.object.isRequired,
  submitModalOpened: PropTypes.bool.isRequired,
  referralSections: PropTypes.array.isRequired,
  setCartItems: PropTypes.func.isRequired,
  updateCartItem: PropTypes.func.isRequired,
  toggleClientConsentGranted: PropTypes.func.isRequired,
  toggleClientConsentRequired: PropTypes.func.isRequired,
  submitCart: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  submitModalOpened: state.referralCheckout.submitModalOpened,
  storedCartItems: state.referralCheckout.cartItems,
  remainingCartItems: selectRemainingCartItems(state),
  cartItemsWithErrors: selectCartItemsWithErrors(state),
  clientConsentGranted: isClientConsentGranted(state),
  clientConsentRequired: isClientConsentRequired(state),
  errorAlertOpen: state.referralCheckout.errorAlertOpen,
  referralSections: state.referralCheckout.referralSections
})

const mapDispatchToProps = {
  setCartItems,
  updateCartItem,
  toggleClientConsentGranted,
  toggleClientConsentRequired,
  submitCart
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralCheckout)
