import { phoneIsPresentInComposite, identifierIsPresentInComposite } from '@scenes/clients/ClientMerge/utils'

const phoneExistsOnClient = (phone, client) => {
  const clientPhones = client.phones
  if (!clientPhones) { return false }

  return Boolean(clientPhones.find(currentPhone => currentPhone.id === phone.id))
}

export const addPhoneToComposite = (state, clientID, phone) => {
  const { compositeClient } = state
  const clientFromID = [state.firstPatient, state.secondPatient]
    .find(patient => patient.id === clientID)

  if (!phoneExistsOnClient(phone, clientFromID) || phoneIsPresentInComposite(phone, state.compositeClient)) {
    return state
  }

  const phoneObject = { source: clientID, value: phone }

  const existingPhones = compositeClient.phones
  const compositePhones = existingPhones ? existingPhones.concat([phoneObject]) : [phoneObject]

  return {
    ...state,
    compositeClient: { ...compositeClient, phones: compositePhones }
  }
}

const identifierExistsOnClient = (identifier, client) => {
  if (!client.identifiers) return false

  return Boolean(
    client.identifiers.find(currentIdentifier => identifier.id === currentIdentifier.id)
  )
}

export const addIdentifierToComposite = (state, clientID, identifier) => {
  const { compositeClient } = state
  const clientFromID = [state.firstPatient, state.secondPatient]
    .find(patient => patient.id === clientID)

  if (!identifierExistsOnClient(identifier, clientFromID) ||
    identifierIsPresentInComposite(identifier, compositeClient)) {
    return state
  }

  const identifierObject = { source: clientID, value: identifier }

  const existingIdentifiers = compositeClient.identifiers
  const compositeIdentifiers = existingIdentifiers ? existingIdentifiers.concat([identifierObject]) : [identifierObject]

  return {
    ...state,
    compositeClient: {
      ...compositeClient,
      identifiers: compositeIdentifiers
    }
  }
}

export const removePhoneFromComposite = (state, targetPhone) => {
  const { compositeClient } = state
  if (!compositeClient.phones) { return state }

  const phonesExcludingTarget = compositeClient
    .phones
    .filter(currentPhone => targetPhone.id !== currentPhone.value.id)

  return {
    ...state,
    compositeClient: {
      ...compositeClient,
      phones: phonesExcludingTarget
    }
  }
}

export const addAttributeToComposite = (state, clientID, attributeName) => {
  let selectedPatients = {}

  const patients = [state.firstPatient, state.secondPatient]
  patients.forEach(patient => {
    selectedPatients[patient.id] = patient
  })

  const attributeObject = {
    source: clientID,
    value: selectedPatients[clientID][attributeName]
  }

  return {
    ...state,
    compositeClient: {
      ...state.compositeClient,
      [attributeName]: attributeObject
    }
  }
}

export const removeAttributeFromComposite = (state, attributeName) => {
  const compositeClientState = state.compositeClient
  const { [attributeName]: value, ...compositeWithoutAttribute } = compositeClientState

  return {
    ...state,
    compositeClient: compositeWithoutAttribute
  }
}

export const removeIdentifierFromComposite = (state, targetIdentifier) => {
  const { compositeClient } = state
  if (!compositeClient.identifiers) { return state }

  const identifiersExcludingTarget = compositeClient
    .identifiers
    .filter(currentIdentifier => currentIdentifier.value.id !== targetIdentifier.id)

  return {
    ...state,
    compositeClient: {
      ...compositeClient,
      identifiers: identifiersExcludingTarget
    }
  }
}

const addressExistsOnClient = (address, client) => {
  if (!client.address) return false

  return client.address.id === address.id
}

export const addressExistsInComposite = (address, compositeClient) => {
  if (!compositeClient.address) return false

  return address.id === compositeClient.address.value.id
}

export const addAddressToComposite = (state, clientID, targetAddress) => {
  const { compositeClient } = state
  const clientFromID = [state.firstPatient, state.secondPatient]
    .find(patient => patient.id === clientID)

  const addressCannotBeAdded = !addressExistsOnClient(targetAddress, clientFromID) ||
    addressExistsInComposite(targetAddress, compositeClient)
  if (addressCannotBeAdded) return state

  const addressObject = { source: clientID, value: targetAddress }

  return {
    ...state,
    compositeClient: { ...compositeClient, address: addressObject }
  }
}

export const removeAddressFromComposite = (state, targetAddress) => {
  const { compositeClient } = state

  if (targetAddress.id !== compositeClient.address.value.id) return state

  return {
    ...state,
    compositeClient: { ...state.compositeClient, address: null }
  }
}
