import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Spacing from '@components/Spacing'
import FieldErrors from '@components/Form/FieldErrors'
import IconButton from '@components/IconButton'

import './Card.scss'

class Card extends PureComponent {
  cardEmphasisClasses () {
    const { outlineColor, errors, success } = this.props

    return classNames(
      'Card',
      [`Card--outlined-${outlineColor}`],
      {
        'Card--error': errors.length,
        'Card--success': success
      }
    )
  }

  contentEmphasisClasses () {
    const { innerBorderColor, shaded, footer } = this.props

    return classNames(
      'Card__content',
      {
        'Card__content--shaded': shaded,
        [`Card__content--inner-border-${innerBorderColor}`]: innerBorderColor,
        'Card__content--inner-border--with-footer': footer
      }
    )
  }

  renderHeader () {
    const { onRemoveCard, header } = this.props

    return (
      <Spacing marginBottom={4}>
        <header className={classNames(
          'Card__header',
          { 'Card__header--with-close': onRemoveCard }
        )}>
          {onRemoveCard &&
            <IconButton
              aria-label='Remove'
              onClick={onRemoveCard}
              icon='close'
            />
          }

          {header}
        </header>
      </Spacing>
    )
  }

  renderChildrenOrContent () {
    if (this.props.content) {
      return (
        <>
          {this.props.header && this.renderHeader()}
          <div>{this.props.content}</div>
        </>
      )
    } else {
      return (
        <>
          {this.props.header && this.renderHeader()}
          {this.props.children}
        </>
      )
    }
  }

  render () {
    return (
      <div className='Card__wrapper'>
        <article className={this.cardEmphasisClasses()}>
          <div
            className={this.contentEmphasisClasses()}
            data-test='Card__content'
          >
            <Spacing padding={this.props.reducedSpacing ? 2 : 4}>
              {this.renderChildrenOrContent()}
            </Spacing>
          </div>

          {this.props.footer && <footer>{this.props.footer}</footer>}
        </article>

        <FieldErrors errors={this.props.errors} showErrorMessages />
      </div>
    )
  }
}

Card.defaultProps = {
  errors: [],
  outlineColor: 'gray',
  shaded: false,
  success: false
}

Card.propTypes = {
  // Props common to all instances
  errors: PropTypes.arrayOf(PropTypes.string),
  innerBorderColor: PropTypes.oneOf([
    'gray',
    'blue'
  ]),
  outlineColor: PropTypes.oneOf([
    'gray',
    'blue'
  ]),
  shaded: PropTypes.bool,
  success: PropTypes.bool,

  // Updated props
  children: (props, propName, componentName) => {
    if (props.content) {
      // Valid if content is present, and children is missing
      if (!props[propName]) return

      return new Error(`${componentName} may only be supplied either 'content' or '${propName}' prop.`)
    }

    PropTypes.checkPropTypes(
      { [propName]: PropTypes.node.isRequired },
      props,
      propName,
      componentName
    )
  },
  footer: PropTypes.element,
  reducedSpacing: PropTypes.bool,

  // Legacy props
  content: (props, propName, componentName) => {
    if (props.children) {
      // Valid if children is present, and content is missing
      if (!props[propName]) return

      return new Error(`${componentName} may only be supplied either 'children' or '${propName}' prop.`)
    }

    PropTypes.checkPropTypes(
      { [propName]: PropTypes.element.isRequired },
      props,
      propName,
      componentName
    )
  },
  header: (props, propName, componentName) => {
    PropTypes.checkPropTypes(
      { [propName]: PropTypes.element },
      props,
      propName,
      componentName
    )
  },
  onRemoveCard: (props, propName, componentName) => {
    if (!props.header && props[propName]) {
      return new Error(`${componentName} may only be supplied '${propName}' when 'header' is present.`)
    } else {
      PropTypes.checkPropTypes(
        { [propName]: PropTypes.func },
        props,
        propName,
        componentName
      )
    }
  }
}

export default Card
