import React, { Fragment, PureComponent } from 'react'
import Spacing from '@components/Spacing/Spacing'
import Story from '../Story'

const wrapComponentInBorder = (component) => {
  return <div style={{ border: '1px solid black', display: 'inline-block' }}>{component}</div>
}

class Spacings extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Types of Padding' key='padding-types'>
          {wrapComponentInBorder(<Spacing padding={2}>padding</Spacing>)}
          {wrapComponentInBorder(<Spacing paddingHorizontal={2}>paddingHorizontal</Spacing>)}
          {wrapComponentInBorder(<Spacing paddingVertical={2}>paddingVertical</Spacing>)}
          {wrapComponentInBorder(<Spacing paddingLeft={2}>paddingLeft</Spacing>)}
          {wrapComponentInBorder(<Spacing paddingRight={2}>paddingRight</Spacing>)}
          {wrapComponentInBorder(<Spacing paddingTop={2}>paddingTop</Spacing>)}
          {wrapComponentInBorder(<Spacing paddingBottom={2}>paddingBottom</Spacing>)}
        </Story>
        <Story title='Types of Margin' key='margin-types'>
          {wrapComponentInBorder(<Spacing margin={2}>margin</Spacing>)}
          {wrapComponentInBorder(<Spacing marginHorizontal={2}>marginHorizontal</Spacing>)}
          {wrapComponentInBorder(<Spacing marginVertical={2}>marginVertical</Spacing>)}
          {wrapComponentInBorder(<Spacing marginLeft={2}>marginLeft</Spacing>)}
          {wrapComponentInBorder(<Spacing marginRight={2}>marginRight</Spacing>)}
          {wrapComponentInBorder(<Spacing marginTop={2}>marginTop</Spacing>)}
          {wrapComponentInBorder(<Spacing marginBottom={2}>marginBottom</Spacing>)}
        </Story>
        <Story title='Padding Sizes' key='padding-sizes'>
          <p>
            Our spacing system works in 8px increments, the size you pass to
            a padding property is multiplied by 8px. Valid values are integers
            from 1 to 12 and 0.5 for 4px padding.
          </p>
          <Spacing paddingLeft={0.5}>
            paddingLeft: 0.5
          </Spacing>
          {
            [...Array(12).keys()].map((i) => {
              return <Spacing paddingLeft={i + 1}>
                paddingLeft: {i + 1}
              </Spacing>
            })
          }
        </Story>
        <Story title='Margin Sizes' key='margin-sizes'>
          <p>
            Our spacing system works in 8px increments, the size you pass to
            a margin property is multiplied by 8px. Valid values are integers
            from 1 to 12 and 0.5 for 4px margin.
          </p>
          <Spacing marginLeft={0.5}>
            marginLeft: 0.5
          </Spacing>
          {
            [...Array(12).keys()].map((i) => {
              return <Spacing marginLeft={i + 1}>
                marginLeft: {i + 1}
              </Spacing>
            })
          }
        </Story>
        <Story title='Responsive Sizing' key='responsive-sizing'>
          <p>
            Our spacing system can take an object of breakpoints instead of
            a single value. This enables you to use different spacings for
            different screen sizes. The component below has a padding of 5
            on xl screens, 4 on lg, 3 on md, 2 on sm, and 1 on xs.
          </p>
          <Spacing marginLeft={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
            marginLeft: {'{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }'}
          </Spacing>
        </Story>
      </Fragment>
    )
  }
}

Spacings.displayName = 'Spacings'

export default Spacings
