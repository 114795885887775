import { useMemo } from 'react'
import useCreateRecord from '@hooks/useCreateRecord'

import { stringifyQueryParams } from '@utils/browserUtil'
import { serializeCartShare } from './cartSharesUtil'

import { localeStringGenerator } from '@utils/localeUtil'
import cartSharesService from '@services/cartSharesService'

const getCreateCartShare = () => () => {
  const { loading, createRecord } = useCreateRecord()

  const createCartShare = (formData) => createRecord(
    () => cartSharesService.create(serializeCartShare(formData))
  )

  return useMemo(() => ({ loading, createCartShare }))
}

const downloadCartPdfLink = ({ patientId, cartId, language }) => {
  const queryParams = stringifyQueryParams({ current_language: language ? language.label : 'English' })
  const basePath = `/en/clients/${patientId}/carts/${cartId}/download`

  return `${basePath}?${queryParams}`
}

export default {
  downloadCartPdfLink,
  getString: localeStringGenerator('cart_share'),
  getCreateCartShare: getCreateCartShare()
}
