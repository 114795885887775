import { useRef, useEffect } from 'react'

export default function useEffectOnMount (callback, dep = []) {
  const componentHasMounted = useRef(false)
  useEffect(() => {
    if (!componentHasMounted.current) {
      componentHasMounted.current = true
      return callback()
    }
  }, dep)
}
