import axios from 'axios'

const search = ({ query, patientAccessing, usesCoordinate, teamId }) => {
  const data = {
    params: {
      name: query,
      team_id: teamId,
      patient_accessing: patientAccessing,
      uses_coordinate: usesCoordinate
    }
  }

  return axios.get('/api/internal/users', data).then(response => {
    return response.data.data.map(user => ({
      id: user.attributes.id,
      full_name: user.attributes.full_name
    }))
  })
}

export {
  search
}
