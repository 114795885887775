import { useSelector } from 'react-redux'
import { getAssociation } from '@selectors'

const useGetAssociation = (record, associationName, { modelName, defaultValue = null } = {}) => {
  const association = useSelector(state => getAssociation(state, record, associationName, modelName))

  return association || defaultValue
}

export default useGetAssociation
