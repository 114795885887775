import React, { Fragment, PureComponent } from 'react'
import ElementWithTooltip from '@components/ElementWithTooltip'
import Story from '../Story'

class ElementWithTooltips extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='ElementWithTooltip - Basic'>
          <div style={{ display: 'inline-block' }}>
            <ElementWithTooltip tooltipContent='This is the tooltip text'>
              <p>Hover over me to see the tooltip</p>
            </ElementWithTooltip>
          </div>
        </Story>

        <Story title='ElementWithTooltip - Custom Placement (bottom)'>
          <div style={{ display: 'inline-block' }}>
            <ElementWithTooltip
              tooltipContent='This is the tooltip text'
              tooltipOptions={{ placement: 'bottom' }}
            >
              <p>Hover over me to see the tooltip at the bottom</p>
            </ElementWithTooltip>
          </div>
        </Story>

        <Story title='ElementWithTooltip - Disabled via parent component'>
          <div style={{ display: 'inline-block' }}>
            <ElementWithTooltip
              tooltipContent='This is the tooltip text'
              enabled={false}
            >
              <p>Hover over me to not see the tooltip</p>
            </ElementWithTooltip>
          </div>
        </Story>
      </Fragment>
    )
  }
}

ElementWithTooltips.displayName = 'ElementWithTooltips'

export default ElementWithTooltips
