const cssVariables = {
  // colors
  gray: {
    main: '#999999',
    fill: '#EBEBEB'
  },
  blue: {
    light: '#F6F9FF',
    main: '#4D90FE'
  },
  lightGray: '#FDFDFD',
  boxBorderGray: '#DCDFE2',
  boxShadow: 'rgba(82, 93, 111, 0.4)',
  textGray: '#525D6F',
  borderRadius: '3px',

  red: '#F01E33',

  gridContainer: {
    maxWidth: '1240px',
    margin: '0 100px'
  }
}

export default cssVariables
