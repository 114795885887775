import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useCurrentUser from '@hooks/useCurrentUser'
import { formatDate } from '@utils/formatterUtil'

import AddNoteForm from './AddNoteForm'

import Button from '@components/Button'
import Grid from '@material-ui/core/Grid'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import { ClientGoalsContext } from '../ClientGoals'

const Note = ({ note, goal, ...props }) => {
  const {
    getString,
    getDeleteNote,
    getUpdateGoalEntity
  } = useContext(ClientGoalsContext)
  const { owner } = note
  const currentUser = useCurrentUser()
  const updateGoalEntity = getUpdateGoalEntity(goal)
  const ownNote = owner && owner.id === currentUser.id
  const { loading: deletingNote, deleteNote } = getDeleteNote(note.id)
  const noteClasses = classNames([
    'ClientGoalNotes__Note',
    {
      'ClientGoalNotes__Note--deleting': deletingNote
    }
  ])

  const handleNoteDeleted = () => {
    const noteCount = goal.noteCount - 1
    let noteTeamAttributions

    // delete the note
    deleteNote()
    // update note count and attribution
    // @TODO remove this when we convert this to an API request to
    // refresh the current goal
    if (noteCount === 0) {
      noteTeamAttributions = []
    } else {
      noteTeamAttributions = goal.noteTeamAttributions
    }

    updateGoalEntity({
      noteCount,
      noteTeamAttributions
    })
  }

  const renderNoteAddedText = () => {
    const displayName = ownNote ? getString('you') : owner.creator
    return (
      <>
        <strong>{displayName}{' '}
          {getString('notes.added_note')} on {formatDate(note.createdAt)}:</strong>
      </>
    )
  }

  return (
    <article className={noteClasses}>
      <header>
        <Spacing paddingBottom={1} paddingTop={2}>
          <Grid
            container
            spacing={1}
            direction={'row'}
            justify='space-between'
            alignItems='flex-end'
          >
            <Grid item>
              <Text tag='div' style='body-text'>
                {renderNoteAddedText()}
              </Text>
            </Grid>
            {note.canDelete && (
              <Grid item>
                <Button type='tertiary' onClick={handleNoteDeleted}>
                  {getString('notes.delete')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Spacing>
      </header>

      <section className='ClientGoalNotes__Note__Body'>
        <Spacing paddingX={2} paddingY={4}>
          <Text tag='div' style='body-text'>
            {note.content}
          </Text>
        </Spacing>
      </section>
    </article>
  )
}

Note.propTypes = {
  goal: PropTypes.object.isRequired,
  note: PropTypes.object.isRequired
}

const ClientGoalNotes = ({ goal, ...props }) => {
  const {
    getGoalNotes,
    loading
  } = useContext(ClientGoalsContext)
  const notes = getGoalNotes(goal)

  return (loading || (
    <section className='ClientGoalNotes'>
      {notes && (
        <Spacing paddingBottom={2}>
          {notes.map((note) => <Note goal={goal} note={note} key={note.id} />)}
        </Spacing>
      )}
      <AddNoteForm goal={goal} />
    </section>
  ))
}

ClientGoalNotes.propTypes = {
  goal: PropTypes.object.isRequired
}

export default ClientGoalNotes
