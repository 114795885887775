import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import useTitle from '@hooks/useTitle'
import FavoriteResourceSites from '@scenes/resourceSites/FavoriteResourceSites'
import GuidedSearchForm from '@scenes/resourceSites/GuidedSearchForm'
import Text from '@components/Text'

import './SearchOverview.scss'

const SearchOverview = ({ currentUser, usesCoordinate }) => {
  const formTarget = '/resource_sites'
  useTitle(window.local.t('resource_sites.search_page_title'))

  return (
    <div className='SearchOverview' data-testid='SearchOverview'>
      <section className='SearchOverview__form'>
        <header className='SearchOverview__form__header'>
          <Text style='page-header' tag='h1' brandingEmphasis>
            {window.local.t('resource_sites.search_overview.title')}
          </Text>
        </header>

        <GuidedSearchForm target={formTarget} />
      </section>

      <FavoriteResourceSites userID={currentUser.id} usesCoordinate={usesCoordinate} />
    </div>
  )
}

SearchOverview.propTypes = {
  currentUser: PropTypes.object.isRequired,
  usesCoordinate: PropTypes.bool.isRequired
}

const mapDispatchToProps = {
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.global.currentUser,
    usesCoordinate: state.global.settings.uses_coordinate
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchOverview)
