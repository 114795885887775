import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { loadEntities } from '@actions'

const useFetchData = (fetchData, dependencies = [], defaultDataObj = {}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(defaultDataObj)

  useEffect(() => {
    if (!fetchData) return

    fetchData()
      .then(response => {
        dispatch(loadEntities(response))
        setLoading(false)
        setData(response.data)
      }).catch(error => {
        console.error(error)
        Sentry.captureException(error)
      })
  }, [...dependencies])

  return { loading, data }
}

export default useFetchData
