import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getRecord } from '@selectors'
import Text from '@components/Text'
import Spacing from '@components/Spacing'

const IntakeResponse = props => {
  const responsesForThisQuestion =
    props.responses.filter(response => String(response.intakeQuestionId) === props.questionID)

  const renderResponses = () => responsesForThisQuestion.map(response => (
    <Text key={response.id} tag='div' renderNewlines>{response.value}</Text>
  ))

  return (
    <Spacing tag='section' marginBottom={props.finalQuestion ? 0 : 2}>
      <header>
        <Text style='sub-header-extra-small' tag='h4'>{props.question.prompt}</Text>
      </header>

      <Spacing marginTop={1}>
        {responsesForThisQuestion.length
          ? renderResponses()
          : <Text>No response provided</Text>}
      </Spacing>
    </Spacing>
  )
}

IntakeResponse.propTypes = {
  questionID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  question: PropTypes.object.isRequired,
  responses: PropTypes.array.isRequired,
  finalQuestion: PropTypes.bool
}

const mapStateToProps = (state, props) => {
  const question = getRecord(state, 'intake_question', props.questionID)

  return {
    question
  }
}

export default connect(mapStateToProps)(IntakeResponse)
