export const initialState = {
  textModal: {
    open: false
  },
  hasPublicSearch: false,
  surveyResultsMapCenter: [],
  findMoreResourcesLink: null,
  printLink: null,
  screeningHomeLink: null,
  patientInformation: null
}
