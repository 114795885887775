import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api'
import { useSelector } from 'react-redux'

import CenterMarker from './CenterMarker'
import ResourceSiteMarker from './ResourceSiteMarker'

const ResourceSitesMap = ({ containerStyle, centerPoint, resourceSites }) => {
  const [openMarkerID, updateOpenMarker] = useState(null)
  const googleMapsAPIKey = useSelector(state => state.global.config.googleMapsAPIKey)

  const resizeToFitAllMarkers = map => {
    const bounds = new window.google.maps.LatLngBounds()
    bounds.extend(
      new window.google.maps.LatLng(
        parseFloat(centerPoint.lat),
        parseFloat(centerPoint.lng)
      )
    )

    resourceSites.map(resourceSite => {
      const { address: { latitude: lat, longitude: lng } } = resourceSite

      bounds.extend(
        new window.google.maps.LatLng(
          parseFloat(lat), parseFloat(lng)
        )
      )
    })

    map.fitBounds(bounds)
  }

  return (
    <LoadScriptNext googleMapsApiKey={googleMapsAPIKey} version={3.47}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={resizeToFitAllMarkers}
        zoom={16}
        aria-label='Resource site map'
      > <CenterMarker coordinates={centerPoint} />
        {resourceSites.map((resourceSite) =>
          <ResourceSiteMarker
            key={resourceSite.id}
            onMarkerClick={updateOpenMarker}
            resourceSite={resourceSite}
            showInfoWindow={openMarkerID === resourceSite.id}
          />)}
      </GoogleMap>
    </LoadScriptNext>
  )
}

ResourceSitesMap.propTypes = {
  centerPoint: PropTypes.object.isRequired,
  resourceSites: PropTypes.array.isRequired,
  containerStyle: PropTypes.object
}

ResourceSitesMap.defaultProps = {
  containerStyle: { height: '288px', width: '100%' },
  resourceSites: []
}

export default ResourceSitesMap
