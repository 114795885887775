import omit from 'lodash/omit'

import {
  REFERRAL_SHOW_TOGGLE_KEY,
  REFERRAL_SHOW_UPDATE_RECIPIENTS,
  REFERRAL_SHOW_SET_ALERT,
  REFERRAL_SHOW_STATE_CHANGE,
  REFERRAL_SHOW_FORM_FIELD_CHANGE,
  REFERRAL_SHOW_SERVICE_DETAILS_FIELD_CHANGE,
  REFERRAL_SHOW_RESET_FORM,
  REFERRAL_SHOW_FORM_FIELD_DELETE,
  REFERRAL_SHOW_SET_SERVICE_DETAILS_FIELD_ERRORS
} from '../constants'

import { initialState } from './initial-state'

function referralShowReducer (state = initialState, action) {
  switch (action.type) {
    case REFERRAL_SHOW_TOGGLE_KEY:
      return {
        ...state,
        [action.payload.key]: action.payload.toggleValue
      }

    case REFERRAL_SHOW_UPDATE_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload.recipients
      }

    case REFERRAL_SHOW_SET_ALERT:
      return {
        ...state,
        alert: {
          [action.payload.key]: action.payload.alert
        }
      }

    case REFERRAL_SHOW_STATE_CHANGE:
      return {
        ...state,
        newState: action.payload.newState
      }

    case REFERRAL_SHOW_FORM_FIELD_CHANGE: {
      const { formName, field, value } = action.payload

      return {
        ...state,
        form: {
          ...state.form,
          [formName]: {
            ...state.form[formName],
            [field]: value
          }
        }
      }
    }

    case REFERRAL_SHOW_SERVICE_DETAILS_FIELD_CHANGE: {
      const { programAssignmentId, field, value } = action.payload
      const serviceDetailsState = { ...state.form.programAssignmentServiceDetails }

      return {
        ...state,
        form: {
          ...state.form,
          programAssignmentServiceDetails: {
            ...serviceDetailsState,
            [programAssignmentId]: {
              ...serviceDetailsState[programAssignmentId],
              [field]: {
                errors: [],
                value
              }
            }
          }
        }
      }
    }

    case REFERRAL_SHOW_RESET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.formName]: initialState.form[action.payload.formName]
        }
      }

    case REFERRAL_SHOW_FORM_FIELD_DELETE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.formName]: omit(
            state.form[action.payload.formName],
            action.payload.fields
          )
        }
      }

    case REFERRAL_SHOW_SET_SERVICE_DETAILS_FIELD_ERRORS: {
      const { programAssignmentId, field, errors } = action.payload
      const serviceDetailsState = { ...state.form.programAssignmentServiceDetails }

      return {
        ...state,
        form: {
          ...state.form,
          programAssignmentServiceDetails: {
            ...serviceDetailsState,
            [programAssignmentId]: {
              ...serviceDetailsState[programAssignmentId],
              [field]: {
                ...serviceDetailsState[programAssignmentId][field],
                errors
              }
            }
          }
        }
      }
    }

    default: return state
  }
}

export default referralShowReducer
