import axios from 'axios'
import once from 'lodash/once'

const BASE_ENDPOINT = '/api/internal/spa'

const logError = error => { console.error('Error:', error) }

// This network request from the `get` function is only
// allowed to be called once due to the high number of
// network requests that would otherwise be made without
// this safeguard.
const get = () => {
  return axios.get(BASE_ENDPOINT)
    .then(response => response.data)
    .catch(logError)
}

const safeguardGet = () => {
  if (process.env.NODE_ENV === 'test') {
    return get
  } else {
    return once(get)
  }
}

export default {
  get: safeguardGet()
}
