import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { StoryContext } from './storyContext'
import './Story.scss'
import reactElementToJSXString from 'react-element-to-jsx-string'

export const idFromTitle = (title) => `story-id-${title.toLowerCase().replace(' ', '-')}`

class Story extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      showCode: false
    }
  }

  componentDidMount () {
    this.context.addStory(this.props.title)
  }

  componentWillUnmount () {
    this.context.removeStory(this.props.title)
  }

  renderComponentCode () {
    return (
      <div>
        {reactElementToJSXString(
          <>{this.props.children}</>,
          {
            displayName: (component) => {
              if (!component.type.displayName) {
                console.warn('Warning: Must add displayName to component. Example:\n Alert.displayName = \'Alert\'')
              }
              return component.type.displayName || component.type.name
            }
          }
        )}
      </div>
    )
  }

  render () {
    const { children, title } = this.props

    return (
      <div className='Story__content'>
        <div className='Story__anchor' id={idFromTitle(title)} />
        <h3 className='Story__heading'>
          {title}
          <button
            className='Story__heading__button'
            onClick={() => this.setState({ showCode: !this.state.showCode })}
          >
            {this.state.showCode ? 'Hide Code' : 'Show Code'}
          </button>
        </h3>
        {this.state.showCode && this.renderComponentCode() }
        {children}
      </div>
    )
  }
}

Story.contextType = StoryContext

Story.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Story
