import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import {
  onReferralResourceProgramAssignmentChange,
  toggleKey
} from '../actions'

import ClientEnrolledForm from './ClientEnrolledForm'
import ServiceNotProvidedForm from './ServiceNotProvidedForm'
import ServiceProvidedForm from './ServiceProvidedForm'
import WaitlistedForm from './WaitlistedForm'
import ExpandableTextArea from '@components/ExpandableTextArea'
import Text from '@components/Text'
import Spacing from '@components/Spacing'
import Icon from '@components/Icon'
import Button from '@components/Button'
import ModalContentSeparator from '@components/Modal/ContentSeparator'

class PageTwoContent extends PureComponent {
  constructor (props) {
    super(props)

    this.onProgramAssignmentRemoval = this.onProgramAssignmentRemoval.bind(this)
    this.onProgramAssignmentSectionToggle = this.onProgramAssignmentSectionToggle.bind(this)
    this.renderSectionNameAndToggle = this.renderSectionNameAndToggle.bind(this)
    this.renderRemovalButtonFor = this.renderRemovalButtonFor.bind(this)
  }

  onProgramAssignmentRemoval (removedAssignmentId) {
    return () => {
      const remainingAssignments = this.props.selectedProgramAssignments
        .filter(assignment => assignment.id !== removedAssignmentId)

      this.props.onReferralResourceProgramAssignmentChange(remainingAssignments)
    }
  }

  onProgramAssignmentSectionToggle (programAssignmentId) {
    return () => {
      const { expandedSections } = this.props
      const sectionExpanded = expandedSections.includes(programAssignmentId)
      let updatedExpandedSections

      if (sectionExpanded) {
        updatedExpandedSections = expandedSections.filter(id => id !== programAssignmentId)
      } else {
        updatedExpandedSections = [...expandedSections, programAssignmentId]
      }

      this.props.toggleKey(
        'expandedReferralEncounterModalSections',
        updatedExpandedSections
      )
    }
  }

  renderSectionNameAndToggle (programAssignment) {
    const { expandedSections, showSectionToggles } = this.props
    const iconKey = expandedSections.includes(programAssignment.id)
      ? 'keyboard_arrow_up'
      : 'keyboard_arrow_down'
    const className = classNames({
      'text-with-icon': showSectionToggles,
      'ReferralEncounterModal__service-detail-header--clickable': showSectionToggles
    })
    const Tag = showSectionToggles ? 'button' : 'div'

    return (
      <Tag
        className={className}
        onClick={showSectionToggles
          ? this.onProgramAssignmentSectionToggle(programAssignment.id)
          : undefined
        }
        type='button'
      >
        <Text style='sub-header-extra-small'>
          {programAssignment.resourceProgramName}
        </Text>

        {showSectionToggles &&
          <div data-testid='service-details-section__toggle'>
            <Spacing marginLeft={0.5}>
              <Icon iconKey={iconKey} size={12} />
            </Spacing>
          </div>
        }
      </Tag>
    )
  }

  renderRemovalButtonFor (programAssignmentId) {
    if (this.props.showSectionToggles) {
      return (
        <Button
          type='tertiary'
          onClick={this.onProgramAssignmentRemoval(programAssignmentId)}
        >
          {window.local.t('referrals.encounter_modal.remove')}
        </Button>
      )
    }
  }

  renderActionForm () {
    switch (this.props.selectedAction) {
      case 'enrolled':
        return (
          <ClientEnrolledForm
            onServiceDetailsFieldChange={this.props.onServiceDetailsFieldChange}
            renderRemovalButtonFor={this.renderRemovalButtonFor}
            renderSectionNameAndToggle={this.renderSectionNameAndToggle}
          />
        )
      case 'service_provided':
        return (
          <ServiceProvidedForm
            onServiceDetailsFieldChange={this.props.onServiceDetailsFieldChange}
            renderRemovalButtonFor={this.renderRemovalButtonFor}
            renderSectionNameAndToggle={this.renderSectionNameAndToggle}
          />
        )
      case 'service_not_provided':
        return (
          <ServiceNotProvidedForm />
        )
      case 'waitlisted':
        return (
          <WaitlistedForm />
        )
      default:
        // base case when we just want a note on page two
        // needs nothing extra
        // currently active for contact_successful and contact_unsuccessful
    }
  }

  render () {
    return (
      <div className='ReferralEncounterModal__form'>
        <ModalContentSeparator />

        {this.renderActionForm()}

        <ExpandableTextArea
          label={window.local.t('referrals.encounter_modal.note.label')}
          onChange={this.props.onFormFieldChange('note')}
          placeholder={window.local.t('referrals.encounter_modal.note.placeholder')}
          value={this.props.encounterNote}
          dataTest='referral-encounter-modal__note'
        />
      </div>
    )
  }
}

PageTwoContent.propTypes = {
  onFormFieldChange: PropTypes.func.isRequired,
  onReferralResourceProgramAssignmentChange: PropTypes.func.isRequired,
  onServiceDetailsFieldChange: PropTypes.func.isRequired,
  toggleKey: PropTypes.func.isRequired,

  encounterNote: PropTypes.string,
  expandedSections: PropTypes.array,
  selectedAction: PropTypes.string,
  selectedProgramAssignments: PropTypes.array,
  showSectionToggles: PropTypes.bool
}

const mapDispatchToProps = {
  onReferralResourceProgramAssignmentChange,
  toggleKey
}

const mapStateToProps = (state, props) => {
  const {
    action: { value: action },
    note: { value: note },
    referralResourceProgramAssignments: { value: selectedProgramAssignments }
  } = state.referralView.form.referralEncounter
  const { expandedReferralEncounterModalSections } = state.referralView

  return {
    encounterNote: note,
    expandedSections: expandedReferralEncounterModalSections,
    selectedAction: action && action.value,
    selectedProgramAssignments,
    showSectionToggles: selectedProgramAssignments.length > 1
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTwoContent)
