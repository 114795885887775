import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Spacing from '@components/Spacing'
import Icon from '@components/Icon'

import './RemovableTag.scss'

const RemovableTag = ({ children, onRemove, style }) => {
  const handleRemoveClicked = (e) => {
    e.preventDefault()
    e.stopPropagation()

    onRemove()
  }

  return (
    <div className={classNames('RemovableTag', `RemovableTag--${style}`)}>
      <Spacing marginRight={0.5} tag='span'>
        {children}
      </Spacing>

      <button
        className={classNames('RemovableTag__button', `RemovableTag__button--${style}`)}
        onClick={handleRemoveClicked}
        aria-label='Remove'
        type='button'
        data-testid='RemovableTag__button'
      >
        <Icon size={12} iconKey='close' verticalAlignment='middle' />
      </button>
    </div>
  )
}

RemovableTag.defaultProps = {
  style: 'default'
}

RemovableTag.propTypes = {
  children: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  style: PropTypes.oneOf([
    'attachment',
    'default',
    'guided-search'
  ])
}

RemovableTag.displayName = 'RemovableTag'

export default RemovableTag
