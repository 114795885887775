import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  addAddressToCompositeClient,
  addAttributeToCompositeClient,
  addIdentifierToCompositeClient,
  addPhoneToCompositeClient,
  prePopulateCompositeFromSelection,
  removeAddressFromCompositeClient,
  removeAttributeFromComposite,
  removeIdentifierFromCompositeClient,
  removePhoneFromCompositeClient
} from '@scenes/clients/ClientMerge/actions'
import {
  emptyFieldsInComposite,
  unselectableAttributesBetweenClients
} from '@scenes/clients/ClientMerge/utils'
import CompositePatientBuilder from '@scenes/clients/ClientMerge/components/CompositePatientBuilder'
import PageHeader from '@components/PageHeader'

import './BuildProfileView.css'

const BuildProfileView = props => {
  const shouldValidateFormCompleted = () => (
    props.localeContext !== 'default'
  )

  const validateCompositeForm = () => {
    if (shouldValidateFormCompleted()) {
      return emptyFieldsInComposite(props).length > 0
    } else {
      return false
    }
  }

  const { prePopulateCompositeFromSelection, localeContext, match, ...passThruProps } = props
  const mergeID = match.params.mergeID

  return (
    <div className='BuildProfileView'>
      <PageHeader
        title={window.local.t(`client_merge.build_profile.header_title.${localeContext}`)}
        description={window.local.t(`client_merge.build_profile.header_copy.${localeContext}`)}
        backLink={{
          path: window.local.t(`client_merge.build_profile.header_back_link_url.${localeContext}`, { mergeID }),
          text: window.local.t(`client_merge.build_profile.header_back_link.${localeContext}`)
        }}
      />
      <CompositePatientBuilder
        formDisabled={validateCompositeForm()}
        localeContext={localeContext}
        match={match}
        {...passThruProps}
      />
    </div>
  )
}

BuildProfileView.propTypes = {
  addAttributeToCompositeClient: PropTypes.func.isRequired,
  addPhoneToCompositeClient: PropTypes.func.isRequired,
  compositeClient: PropTypes.object.isRequired,
  localeContext: PropTypes.string.isRequired,
  prePopulateCompositeFromSelection: PropTypes.func.isRequired,
  removeAttributeFromComposite: PropTypes.func.isRequired,
  removePhoneFromCompositeClient: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  firstPatient: PropTypes.object,
  secondPatient: PropTypes.object
}

const mapStateToProps = state => {
  const firstPatient = state.clientMerge.firstPatient
  const secondPatient = state.clientMerge.secondPatient
  const unselectableAttributes = unselectableAttributesBetweenClients({ firstPatient, secondPatient })

  return {
    unselectableAttributes,
    compositeClient: state.clientMerge.compositeClient,
    firstPatient: state.clientMerge.firstPatient,
    secondPatient: state.clientMerge.secondPatient
  }
}

const mapDispatchToProps = {
  addAddressToCompositeClient,
  addAttributeToCompositeClient,
  addIdentifierToCompositeClient,
  addPhoneToCompositeClient,
  prePopulateCompositeFromSelection,
  removeAddressFromCompositeClient,
  removeAttributeFromComposite,
  removeIdentifierFromCompositeClient,
  removePhoneFromCompositeClient
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildProfileView)
