import React, { Fragment, PureComponent } from 'react'
import PaginationBar from '@components/PaginationBar'
import Story from '../Story'
import Spacing from '@components/Spacing'

class PaginationBars extends PureComponent {
  constructor (props) {
    super(props)

    this.state = { activePage: 2 }
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  handlePageChange (pageNumber) {
    this.setState({ activePage: pageNumber })
  }

  render () {
    return (
      <Fragment>
        <Story title='Basic Pagination Bar'>
          <Spacing marginTop={4} marginBottom={4}>
            <PaginationBar
              activePage={this.state.activePage}
              onChange={this.handlePageChange}
              itemsCountPerPage={10}
              totalItemsCount={42}
            />
          </Spacing>
        </Story>
        <div>Current Page is {this.state.activePage}</div>
      </Fragment>
    )
  }
}

PaginationBars.displayName = 'PaginationBar'

export default PaginationBars
