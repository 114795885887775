import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'

class NotificationCount extends React.Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    e.preventDefault()

    this.props.handleClick()
  }

  renderNotificationCountValue () {
    const { count } = this.props

    if (count) {
      return (
        <div
          className='notification-count__value'
        >
          {count}
        </div>
      )
    }
  }

  render () {
    const { t } = this.props

    return (
      <a
        href='#'
        onClick={this.handleClick}
        className='session-nav-link notification-count'
        data-test='notification-count'
      >
        <div className='notification-count__bell-container'>
          <Icon
            iconKey='notifications_none'
            className='notification-count__bell-icon mi--22'
          />
          {this.renderNotificationCountValue()}
        </div>
        <div className='notification-count__text is-hidden--tablet'>
          {t.notifications.navbar_title}
        </div>
      </a>
    )
  }
}

NotificationCount.propTypes = {
  handleClick: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

export default NotificationCount
