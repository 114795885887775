import React from 'react'
import PropTypes from 'prop-types'
import { Marker } from '@react-google-maps/api'

const customMarker = 'https://mt.google.com/vt/icon?color=ff004C13&name=icons/spotlight/spotlight-waypoint-blue.png'

const CenterMarker = (props) => {
  const { lat, lng } = props.coordinates

  return (
    <Marker
      icon={customMarker}
      position={{ lat, lng }}
    />
  )
}

CenterMarker.propTypes = {
  coordinates: PropTypes.object.isRequired
}

export default CenterMarker
