import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '@components/Icon'

import './CheckboxButton.css'

class CheckboxButton extends Component {
  getClassNames () {
    let classNames = {
      CheckboxButton: 'CheckboxButton',
      Icon: 'CheckboxButton__content__icon'
    }

    if (this.props.selected) {
      classNames.CheckboxButton += ' CheckboxButton--selected'
      classNames.Icon += ' mi--blue'
    }

    return classNames
  }

  render () {
    const classNames = this.getClassNames()

    return (
      <label className={classNames.CheckboxButton}>
        <div className='CheckboxButton__content'>
          <Icon
            className={classNames.Icon}
            iconKey={this.props.icon}
            size={16}
          />
          {this.props.label}
        </div>
        <input
          type='checkbox'
          checked={this.props.selected}
          onChange={this.props.onChange}
          name={this.props.name}
          aria-label={this.props.label}
        />
      </label>
    )
  }
}

CheckboxButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool
}

export default CheckboxButton
