import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import ButtonLink from '@components/legacy/ButtonLink'
import SourcePatient from '@scenes/clients/ClientMerge/components/SourcePatient'
import CompositePatient from '@scenes/clients/ClientMerge/components/CompositePatient'
import cssVariables from '@config/theme/variables'

class CompositePatientBuilder extends Component {
  render () {
    const { localeContext, match } = this.props
    const mergeID = match.params.mergeID

    return (
      <div style={cssVariables.gridContainer}>
        <Grid container>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <h2 className='hfysg-h2 BuildProfileView__header'>
                {window.local.t('client_merge.build_profile.original_profiles.header')}
              </h2>
            </Grid>
            <Grid item container xs={12} spacing={3} style={{ height: '88%' }}>
              <Grid item xs={6}>
                <SourcePatient
                  addAddressAction={this.props.addAddressToCompositeClient}
                  addIdentifierAction={this.props.addIdentifierToCompositeClient}
                  addPhoneToCompositeClient={this.props.addPhoneToCompositeClient}
                  compositeClient={this.props.compositeClient}
                  handleTagSelection={this.props.addAttributeToCompositeClient}
                  profile={this.props.firstPatient}
                />
              </Grid>
              <Grid item xs={6}>
                <SourcePatient
                  addAddressAction={this.props.addAddressToCompositeClient}
                  addPhoneToCompositeClient={this.props.addPhoneToCompositeClient}
                  addIdentifierAction={this.props.addIdentifierToCompositeClient}
                  compositeClient={this.props.compositeClient}
                  handleTagSelection={this.props.addAttributeToCompositeClient}
                  profile={this.props.secondPatient}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <h2 className='hfysg-h2 BuildProfileView__header'>
                {window.local.t('client_merge.build_profile.new_profile.header')}
              </h2>
            </Grid>
            <Grid item xs={12}>
              <CompositePatient
                addressRemovalAction={this.props.removeAddressFromCompositeClient}
                attributeRemovalAction={this.props.removeAttributeFromComposite}
                identifierRemovalAction={this.props.removeIdentifierFromCompositeClient}
                phoneRemovalAction={this.props.removePhoneFromCompositeClient}
                unselectableAttributes={this.props.unselectableAttributes}
                {...this.props.compositeClient}
              />

              <div className='BuildProfileView__submit'>
                <ButtonLink disabled={this.props.formDisabled} to={window.local.t(`client_merge.build_profile.call_to_action_url.${localeContext}`, { mergeID })}>
                  {window.local.t(`client_merge.build_profile.call_to_action.${localeContext}`)}
                </ButtonLink>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

CompositePatientBuilder.propTypes = {
  compositeClient: PropTypes.object.isRequired,
  addAddressToCompositeClient: PropTypes.func,
  addAttributeToCompositeClient: PropTypes.func,
  addIdentifierToCompositeClient: PropTypes.func,
  addPhoneToCompositeClient: PropTypes.func,
  formDisabled: PropTypes.bool.isRequired,
  localeContext: PropTypes.string.isRequired,
  firstPatient: PropTypes.object,
  removeAddressFromCompositeClient: PropTypes.func,
  removeAttributeFromComposite: PropTypes.func,
  removeIdentifierFromCompositeClient: PropTypes.func,
  removePhoneFromCompositeClient: PropTypes.func,
  secondPatient: PropTypes.object,
  match: PropTypes.object.isRequired,
  unselectableAttributes: PropTypes.array
}

export default CompositePatientBuilder
