import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { PageHeader } from '@components/Header'
import { Column, Row } from '@components/Layout'
import Spacing from '@components/Spacing'
import SurveyResultsPageActions from './SurveyResultsPageActions'
import SearchResultCard from '@scenes/resourceSites/SearchResultCard'
import ResourceSitesMap from '@scenes/resourceSites/ResourceSitesMap'
import DownloadModal from '@scenes/resourceSites/components/DownloadModal'

import { getMatchedResourceSites } from '@scenes/unauthenticated/actions'
import { useParams } from 'react-router-dom'
import { denormalizeRecords } from '@selectors'

const renderResultsList = (props) => {
  if (props.resourceSiteMatches.length) {
    return <div className='SurveyResultsPage__results__list'>
      {props.downloadModalOpen && <DownloadModal />}
      {props.resourceSiteMatches.map((resourceSite, i) => (
        <Spacing marginBottom={4} key={i}>
          <SearchResultCard
            analyticsReferrer={props.analyticsReferrer}
            currentUser={props.currentUser}
            featureFlags={props.featureFlags}
            resourceSite={resourceSite}
            settings={props.settings}
            languages={props.languages}
          />
        </Spacing>
      ))}
    </div>
  }
}

const renderMap = ({
  featureFlags,
  resourceSiteMatches,
  surveyResultsMapCenter
}) => {
  const mappableResourceSites = []

  if (!featureFlags.cap_improvements_flag || surveyResultsMapCenter.length === 0) {
    return null
  }
  resourceSiteMatches.forEach((resourceSite) => {
    if (resourceSite.address) {
      mappableResourceSites.push(resourceSite)
    }
  })

  if (mappableResourceSites.length) {
    return <div data-testid='SurveyResultsGoogleMap'>
      <ResourceSitesMap
        centerPoint={surveyResultsMapCenter[0]}
        resourceSites={mappableResourceSites}
      />
    </div>
  }
}

const renderActions = (props) => {
  const { findMoreResourcesLink, hasPublicSearch, printLink, screeningHomeLink } = props

  return <SurveyResultsPageActions
    findMoreResourcesLink={findMoreResourcesLink}
    hasPublicSearch={hasPublicSearch}
    printLink={printLink}
    screeningHomeLink={screeningHomeLink} />
}

const SurveyResultsPage = (props) => {
  const { companySlug, surveyId } = useParams()

  useEffect(() => { props.getMatchedResourceSites(surveyId, companySlug) }, [props.hasPublicSearch])

  const mapElement = renderMap(props)

  return (
    <div className='wrapper page-surveys-results'>
      <PageHeader
        headerClassName={'results-header'}
        text={local.t('surveys.results.header')}
        rank='1'
        description={local.t('surveys.results.header_description')} />
      {renderActions(props)}
      { mapElement
        ? <Row>
          <Column span='9'>{renderResultsList(props)}</Column>
          <Column span='3'>{mapElement}</Column>
        </Row>
        : renderResultsList(props)
      }
    </div>
  )
}

SurveyResultsPage.propTypes = {
  analyticsReferrer: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  downloadModalOpen: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
  findMoreResourcesLink: PropTypes.string,
  getMatchedResourceSites: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  hasPublicSearch: PropTypes.bool,
  printLink: PropTypes.string,
  screeningHomeLink: PropTypes.string,
  surveyResultsMapCenter: PropTypes.array,
  languages: PropTypes.object,
  resourceSiteMatches: PropTypes.array
}

const mapStateToProps = (state, props) => {
  const { findMoreResourcesLink, hasPublicSearch, printLink, screeningHomeLink, surveyResultsMapCenter = [] } = state.unauthenticated
  const analyticsReferrer = state.global.analyticsReferrer
  const downloadModalOpen = state.resourceSites.downloadModal.open
  const currentUser = state.global.currentUser
  const featureFlags = state.global.featureFlags
  const settings = state.global.settings
  const languages = state.global.languages

  const results = Object.values(state.global.entities['resource_site_search_result'] || [])
  const resourceSiteMatches = denormalizeRecords(state, results)

  return {
    analyticsReferrer,
    downloadModalOpen,
    currentUser,
    featureFlags,
    findMoreResourcesLink,
    printLink,
    screeningHomeLink,
    settings,
    hasPublicSearch,
    languages,
    resourceSiteMatches,
    surveyResultsMapCenter
  }
}

const mapDispatchToProps = {
  getMatchedResourceSites
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResultsPage)
