import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import every from 'lodash/every'

import { CLOSED_LOOP, COORDINATE, TRACK } from '@constants/referralTypes'
import { appendParamsToFormData } from '@utils/browserUtil'
import { getRecord } from '@selectors'
import useEffectAfterMount from '@hooks/useEffectAfterMount'
import { legacyFind as legacyFindClient } from '@services/clientsService'
import { formattedStateForHTTPRequest } from '@scenes/resourceSites/utils/followupStep'
import View from './View'
import analyticsService from '@services/analyticsService'
import referralService from '@services/referralService'
import shareFormValid from '@scenes/resourceSites/utils/shareFormValidation'
import { selectSearchMetadataFormParams } from '@scenes/resourceSites/ResourceSite/selectors'
import { ReferralContext } from '../Referral/Referral'

const ReferralModal = ({
  analytics,
  attachmentsEnabled,
  attestationRequired,
  client,
  closedLoopable,
  currentUser,
  displayAttachmentsDisabledMessage,
  hidePatientUI,
  id,
  isCoordinate,
  languages,
  memberLedCLREnabled,
  name,
  privacyStatus,
  programs,
  showFollowupStep,
  textMessagingEnabled,
  organizationName,
  ...props
}) => {
  const { state, changeState, resetState } = useContext(ReferralContext)

  const referralContext = () => {
    if (isCoordinate) {
      return COORDINATE
    } else if (closedLoopable) {
      return CLOSED_LOOP
    } else {
      return TRACK
    }
  }

  const toggleModal = () => {
    if (state.showModal) {
      resetState()
    } else {
      trackModalActivity('modal_opened')
      changeState({ showModal: true })
    }
  }

  const trackModalActivity = (eventType) => {
    return analyticsService.track(
      'Referral',
      eventType,
      {
        resource_site_id: id,
        resource_site_name: name,
        is_coordinate: isCoordinate
      },
      {
        analytics_referrer_type: analytics.referrerType,
        analytics_referrer_id: analytics.referrerId
      }
    )
  }

  const handleReferralSubmissionWithoutFollowup = (event) => {
    const form = event.target
    const data = new window.FormData(form)

    appendParamsToFormData(data, props.searchMetadataParams)

    referralService.create(data).then((response) => {
      document.querySelector('.ModalContainer').scrollTop = 0
      changeState({
        isProcessing: false,
        showShareActions: true,
        referralId: response.data.id
      })
      requestPhoneData()
    }).catch((errors) => {
      const errorData = errors.response.data
      const errorMessage = errorData.errors && errorData.errors.length
        ? errorData.errors[0]
        : 'No Error Message'

      changeState({
        submissionError: errorMessage,
        showShareActions: false,
        isProcessing: false
      })
    })
  }

  const handleReferralSubmissionWithFollowup = (event) => {
    const form = event.target
    const data = new window.FormData(form)

    appendParamsToFormData(data, props.searchMetadataParams)

    referralService.create(data).then((response) => {
      document.querySelector('.ModalContainer').scrollTop = 0
      changeState({
        isProcessing: false,
        modalPage: 'followup',
        showShareActions: false,
        referralId: response.data.id
      })
      requestPhoneData()
    }).catch((errors) => {
      const errorData = errors.response.data
      const errorMessage = errorData.errors && errorData.errors.length
        ? errorData.errors[0]
        : 'No Error Message'

      changeState({
        submissionError: errorMessage,
        showShareActions: false,
        isProcessing: false
      })
    })
  }

  const handleFollowupSubmission = () => {
    const data = formattedStateForHTTPRequest(state.followupForm)

    referralService
      .submitFollowup({ referralID: state.referralId, data })
      .then(response => {
        changeState({
          isProcessing: false,
          showShareActions: true
        })
      })
      .catch(errors => {
        changeState({
          isProcessing: false,
          submissionError: errors.response.data.errors[0]
        })
      })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()

    if (!state.formIsValid) return

    changeState({
      isProcessing: true
    })

    const { modalPage } = state

    if (modalPage === 'creation') {
      showFollowupStep
        ? handleReferralSubmissionWithFollowup(event)
        : handleReferralSubmissionWithoutFollowup(event)
    } else if (showFollowupStep && modalPage === 'followup') {
      handleFollowupSubmission()
    }
  }

  const requestPhoneData = () => {
    legacyFindClient(
      state.formSelections.client.value,
      { included: ['address', 'identifiers', 'phones'] }
    ).then((client) => {
      changeState({
        phones: client.phones,
        email: client.email

      })
    }).catch((errors) => {
      changeState({ phones: [], email: '' })
      console.error(errors)
    })
  }

  const referralShareBody = () => {
    const { referralId, shareFormSelections } = state
    const { email, language, sendEmail, sendText, phoneNumber } = shareFormSelections

    const referralShareData = {
      language: language.value,
      send_text: sendText,
      send_email: sendEmail
    }

    if (phoneNumber) {
      // Dynamically determine backend phone key based on whether it's a new or
      // existing phone
      const phoneKey = phoneNumber.__isNew__ ? 'phone_number' : 'phone_id'

      referralShareData[phoneKey] = phoneNumber.value
    }

    if (email && email.length) {
      referralShareData.email = email
    }

    return {
      referral_id: referralId,
      referral_share: referralShareData
    }
  }

  const handleShareSubmit = (event) => {
    event.preventDefault()

    const { referralId } = state

    if (!state.shareFormValid) return

    changeState({
      isProcessing: true
    })

    referralService.share(referralShareBody(), referralId).then(() => {
      changeState({
        isProcessing: false,
        showShareActions: false,
        showModal: false
      })

      performPdfDownload()
    })
  }

  const performPdfDownload = () => {
    const { referrerId } = analytics
    const language = state.shareFormSelections.language.label
    const queryParams = new URLSearchParams({
      current_language: language,
      referrer_id: referrerId,
      ...props.searchMetadataParams
    })

    if (state.shareFormSelections.downloadPDF) {
      const downloadUrl = `/en/resource_sites/${id}.pdf?${queryParams.toString()}`

      window.location.href = downloadUrl
    }
  }

  const isFormValid = () => {
    const { requiredFields, formSelections } = state
    const requiredFieldsAreValid = () => every(requiredFields, field => {
      if (Array.isArray(formSelections[field])) return formSelections[field].length > 0

      return !!formSelections[field]
    })
    const closedLoopReferralFieldsAreValid = () => {
      // if field closed loopable and closed loop checkbox is checked
      if ((attestationRequired || closedLoopable) && formSelections.closedLoop) {
        return formSelections.consent
      }

      return true
    }

    return requiredFieldsAreValid() && closedLoopReferralFieldsAreValid()
  }

  const validateReferralFormState = () => {
    const { modalPage } = state

    if (modalPage === 'creation') {
      changeState({
        formIsValid: isFormValid()
      })
    }
  }

  useEffectAfterMount(() => {
    validateReferralFormState()
  }, [JSON.stringify(state.formSelections)])

  const handleSelectChange = (fieldName) => {
    return (selection, event) => {
      const formSelections = { ...state.formSelections }

      switch (event.action) {
        case 'select-option':
          formSelections[fieldName] = selection
          break
        case 'clear':
          formSelections[fieldName] = null
          break
        case 'remove-value':
          formSelections[fieldName] = selection.length === 0 ? null : selection
          break
      }

      changeState({ formSelections })
    }
  }

  const handleShareSelectChange = (fieldName) => {
    return (selection, event) => {
      const shareFormSelections = { ...state.shareFormSelections }

      switch (event.action) {
        case 'select-option':
        case 'create-option':
          shareFormSelections[fieldName] = selection
          changeState({ shareFormSelections })
          break
        case 'clear':
        case 'remove-value':
          shareFormSelections[fieldName] = null
          break
      }

      changeState({ shareFormSelections })
    }
  }

  const handleEmailInputChange = (email) => {
    changeState({
      shareFormSelections: {
        ...state.shareFormSelections,
        email
      }
    })
  }

  const handleFollowupFormChange = (newState) => {
    changeState({
      followupForm: {
        ...state.followupForm,
        ...newState
      }
    })
  }

  const validateFollowupForm = () => {
    const requiredIntakeQuestions = Object
      .values(state.followupForm.intakeQuestions)
      .filter(({ required }) => required === true)

    const requiredIntakeQuestionsHaveBeenAnswered =
      requiredIntakeQuestions.every(({ value }) => !isEmpty(value))

    changeState({
      followupFormValid: requiredIntakeQuestionsHaveBeenAnswered
    })
  }

  useEffectAfterMount(() => {
    validateFollowupForm()
  }, [JSON.stringify(state.followupForm)])

  const handleDownloadPDFClick = (e) => {
    e.stopPropagation()

    const shouldDownload = !state.shareFormSelections.downloadPDF

    changeState({
      shareFormSelections: {
        ...state.shareFormSelections,
        downloadPDF: shouldDownload
      }
    })
  }

  const handleSendTextClick = (e) => {
    e.stopPropagation()

    const shouldText = !state.shareFormSelections.sendText

    changeState({
      shareFormSelections: {
        ...state.shareFormSelections,
        sendText: shouldText,
        phoneNumber: null
      }
    })
  }

  const handleSendEmailClick = (e) => {
    e.stopPropagation()

    const shouldText = !state.shareFormSelections.sendEmail

    changeState({
      shareFormSelections: {
        ...state.shareFormSelections,
        sendEmail: shouldText,
        email: state.email
      }
    })
  }

  const handleMemberLedCLRConsentClick = () => {
    const shareFormSelections = { ...state.shareFormSelections }

    shareFormSelections.memberLedCLRConsent = !shareFormSelections.memberLedCLRConsent

    changeState({ shareFormSelections })
  }

  const validateShareForm = () => {
    changeState({
      shareFormValid: shareFormValid(state.shareFormSelections, memberLedCLREnabled)
    })
  }

  useEffectAfterMount(() => {
    validateShareForm()
  }, [JSON.stringify(state.shareFormSelections)])

  const handleConsentToggle = () => {
    const formSelections = { ...state.formSelections }

    formSelections.consent = !formSelections.consent

    changeState({ formSelections })
  }

  const handleClosedLoopConsentToggle = () => {
    const formSelections = { ...state.formSelections }

    formSelections.closedLoop = !formSelections.closedLoop

    // If we are unchecking the closed loop referral option,
    // then we also want to remove client consent
    if (!formSelections.closedLoop) {
      formSelections.consent = false
    }

    changeState({ formSelections })
  }

  return (
    <View
      state={state}
      data={{
        analytics,
        attachmentsEnabled,
        client,
        closedLoopable,
        displayAttachmentsDisabledMessage,
        attestationRequired,
        currentUser,
        hidePatientUI,
        id,
        isCoordinate,
        languages: languages,
        name,
        privacyStatus,
        programs,
        referralContext: referralContext(),
        memberLedCLREnabled,
        textMessagingEnabled,
        organizationName
      }}
      behavior={{
        handleConsentToggle: handleConsentToggle,
        handleClosedLoopConsentToggle: handleClosedLoopConsentToggle,
        handleDownloadPDFClick: handleDownloadPDFClick,
        handleEmailInputChange: handleEmailInputChange,
        handleFollowupFormChange: handleFollowupFormChange,
        handleFormSubmit: handleFormSubmit,
        handleMemberLedCLRConsentClick: handleMemberLedCLRConsentClick,
        handleShareSubmit: handleShareSubmit,
        handleSelectChange: handleSelectChange,
        handleSendEmailClick: handleSendEmailClick,
        handleSendTextClick: handleSendTextClick,
        handleShareSelectChange: handleShareSelectChange,
        toggleModal: toggleModal
      }}
    />
  )
}

ReferralModal.propTypes = {
  analytics: PropTypes.object.isRequired,
  closedLoopable: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  languages: PropTypes.object.isRequired,
  attachmentsEnabled: PropTypes.bool,
  displayAttachmentsDisabledMessage: PropTypes.bool,
  attestationRequired: PropTypes.bool,
  client: PropTypes.object,
  hidePatientUI: PropTypes.bool,
  id: PropTypes.number,
  isCoordinate: PropTypes.bool,
  name: PropTypes.string,
  privacyStatus: PropTypes.string,
  programs: PropTypes.array,
  memberLedCLREnabled: PropTypes.bool,
  showFollowupStep: PropTypes.bool,
  textMessagingEnabled: PropTypes.bool,
  searchMetadataParams: PropTypes.object,
  organizationName: PropTypes.string
}

const mapStateToProps = (state, props) => {
  const intakeFormsEnabled = state.global.featureFlags.intake_forms_flag

  // NOTE: Pull acceptsFollowup from props for ResourceSite and from state for Search.
  const onResourceSitePage = props.acceptsFollowup !== null && props.acceptsFollowup !== undefined
  const acceptsFollowup = onResourceSitePage ? props.acceptsFollowup
    : getRecord(state, 'resource_site_search_result', props.id).acceptsFollowup
  const showFollowupStep = intakeFormsEnabled && acceptsFollowup

  return {
    currentUser: state.global.currentUser,
    searchMetadataParams: selectSearchMetadataFormParams(state, props.id),
    showFollowupStep
  }
}

export default connect(mapStateToProps)(ReferralModal)
