import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Text from '@components/Text'
import GuidedSearchResult from '../GuidedSearchResult'

class AutoCompleteResult extends PureComponent {
  resultName () {
    return this.props.result.attributes.name
  }

  renderFormattedResultText () {
    const { term } = this.props

    if (!term.length) {
      return <Text style='body-text' tag='span'>{this.resultName()}</Text>
    }

    const regexp = new RegExp(`(${term})`, 'ig')

    return this.resultName()
      .split(regexp)
      .filter(substring => substring.length)
      .map((substring, i) => {
        const style = substring.match(regexp) ? 'body-text' : 'body-text-emphasis'

        return <Text key={i} style={style} tag='span'>{substring}</Text>
      })
  }

  render () {
    return (
      <GuidedSearchResult result={this.props.result} onClick={this.props.onClick}>
        {this.renderFormattedResultText()}
      </GuidedSearchResult>
    )
  }
}

AutoCompleteResult.propTypes = {
  onClick: PropTypes.func,
  result: PropTypes.object,
  term: PropTypes.string
}

AutoCompleteResult.defaultProps = {
  term: ''
}

AutoCompleteResult.displayName = 'AutoCompleteResult'

export default AutoCompleteResult
