import { CLIENT_DETAIL_ENDPOINT } from '@constants/apiEndpoints'
import { getApiResource } from '@utils/apiUtil'

const type = 'need'

export const getClientNeedsResource = clientId => (
  getApiResource([CLIENT_DETAIL_ENDPOINT, clientId, 'needs'].join('/'))
)

export default {
  type
}
