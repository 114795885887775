import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import './StyleguideEntryContainer.scss'
import { Link, withRouter } from 'react-router-dom'
import WrappedChildren from './WrappedChildren'
import { idFromTitle } from './Story'

class StyleguideEntryContainer extends PureComponent {
  constructor (props) {
    super(props)

    this.addStory = this.addStory.bind(this)
    this.removeStory = this.removeStory.bind(this)

    this.state = {
      stories: {}
    }
  }

  addStory (componentName) {
    // These callbacks are provided via a context to indiviual stories to
    // allow them to register with this component.
    return (story) => {
      this.setState((previousState) => {
        return {
          stories: { [componentName]: [...(previousState.stories[componentName] || []), story] }
        }
      })
    }
  }

  removeStory (componentName) {
    // When a story is unmounted it calls this function to unregister from this component.
    return (story) => {
      this.setState((previousState) => ({
        stories: { [componentName]: previousState.stories[componentName].filter((s) => s !== story) }
      }))
    }
  }

  renderSubNavigation (selected, child) {
    const title = child.type.displayName

    if (selected && this.state.stories[title]) {
      return this.state.stories[title].map((title) => {
        return <li key={title}>
          <a
            className='StyleguideEntry__sidebar__buttons'
            href={`#${idFromTitle(title)}`}
          >
            {title}
          </a>
        </li>
      })
    } else {
      return null
    }
  }

  renderSidebar () {
    const { children } = this.props

    return React.Children.map(children, (child) => {
      const title = child.type.displayName
      const selected = this.props.location.pathname.includes(title)
      const className = 'StyleguideEntry__sidebar__buttons' + (selected ? '--selected' : '')

      return <li key={title}>
        <Link
          className={className}
          to={title}
        >
          {title}
        </Link>
        <ul className='StyleguideEntry__sidebar__list'>
          {this.renderSubNavigation(selected, child)}
        </ul>
      </li>
    })
  }

  render () {
    return (
      <Grid container justify='space-between'>
        <Grid item xs={3} className='StyleguideEntry__sidebar-wrapper'>
          <aside className='StyleguideEntry__sidebar'>
            <h2 className='hfysg-h2 StyleguideEntry__sidebar__title'>Component Menu</h2>
            <ul className='StyleguideEntry__sidebar__list'>
              { this.renderSidebar() }
            </ul>
          </aside>
        </Grid>
        <Grid item xs={9}>
          <main className='StyleguideEntry__content'>
            <WrappedChildren
              addStory={this.addStory}
              removeStory={this.removeStory}
              children={this.props.children} />
          </main>
        </Grid>
      </Grid>
    )
  }
}

StyleguideEntryContainer.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
}

export default withRouter(StyleguideEntryContainer)
