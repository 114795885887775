export const formatIdentifierForDisplay = ({ type, value }) => {
  const label = type.charAt(0).toUpperCase() + type.substring(1)

  return `${label}: ${value}`
}

export default (object) => {
  return ({
    ...object,
    displayValue: formatIdentifierForDisplay(object)
  })
}
