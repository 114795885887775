import { useMemo } from 'react'

import useFetchData from '@hooks/useFetchData'
import resourceSiteService from '@services/resourceSiteService'

const DEFAULT_INCLUDED = [
  'resource_programs',
  'primary_phone',
  'address'
]

export default function useFetchResourceSiteData (resourceSiteId, included) {
  const fetchDataOptions = {
    included: included || DEFAULT_INCLUDED
  }

  // load client data
  const { loading, data } = useFetchData(
    () => resourceSiteService.show(resourceSiteId, fetchDataOptions),
    [resourceSiteId]
  )

  return useMemo(() => ({ loading, data }), [loading, data.id])
}
