import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import SuccessModal from '@components/SuccessModal'
import './AttachmentDeletedModal.scss'

export default class AttachmentDeletedModal extends PureComponent {
  render () {
    return (
      <SuccessModal
        title={window.local.t('attachments.delete_attachment.success')}
        open={this.props.open}
        dataBehavior='AttachmentDeletedModal'
      />
    )
  }
}

AttachmentDeletedModal.propTypes = {
  open: PropTypes.bool.isRequired
}
