import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'
import { fromNow } from '@utils/timeUtil'

const NotificationItem = ({ item, markOneAsRead }) => {
  const { id, icon, title, description } = item
  const hasBeenViewed = !!item.viewed_at
  const highlightClass = hasBeenViewed ? '' : 'notification-item--highlight'
  const pathPrefix = '/en'
  const path = `${pathPrefix}/notifications/${id}`

  function onLinkClick (e) {
    e.stopPropagation()
  }

  return (
    <article
      className={`notification-item ${highlightClass}`}
      data-test='notification-item'
      data-id={id}
      onClick={() => markOneAsRead(id)}
    >
      <Icon iconKey={icon} className='notification-item__icon mi--20' />

      <div className='notification-item__content'>
        <h1 className='notification-item__content__title'>
          {title}
        </h1>
        <a
          href={path}
          className='notification-item__content__description'
          onClick={onLinkClick}
        >
          {description}
        </a>
        <div className='notification-item__content__timestamp'>
          {fromNow(new Date(item.created_at))}
        </div>
      </div>
    </article>
  )
}

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  markOneAsRead: PropTypes.func.isRequired
}

export default NotificationItem
