import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import fetch from 'isomorphic-fetch'

import { headers as apiConfigHeaders } from '@config/apiConfig'

const appendAPIHeaders = (headers) => {
  Object.entries(apiConfigHeaders).forEach(([key, value]) => {
    headers.set(key, value)
  })

  return headers
}

export const baseURL = () => window.location.origin

export const baseQuery = fetchBaseQuery({
  baseUrl: baseURL(),
  fetchFn: fetch,
  prepareHeaders: appendAPIHeaders
})
