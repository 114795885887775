import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Link from '@material-ui/core/Link'

import Card from '@components/Card'
import CartFeedbackSuccessModal from './CartFeedbackSuccessModal'
import CartFeedbackErrorModal from './CartFeedbackErrorModal'
import CartFeedbackFormModal from './CartFeedbackFormModal'
import Text from '@components/Text'

import { useCreateCartFeedbackMutation } from '@services/internalAPI'

const CartFeedbackCard = ({ cartID, clientID }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [postFeedback] = useCreateCartFeedbackMutation()

  const handleCloseSuccessModal = () => { setSuccessModalOpen(false) }
  const handleErrorModalClose = () => { setErrorModalOpen(false) }
  const handleModalClose = () => { setModalOpen(false) }
  const handleModalOpen = () => { setModalOpen(true) }

  const handleSubmit = (values, _form, _callback) => {
    postFeedback({
      requestBody: {
        feedback: {
          client_id: clientID,
          message: values.feedback
        }
      },
      cartID
    })
      .unwrap()
      .then(() => {
        setModalOpen(false)
        setSuccessModalOpen(true)
        setTimeout(handleCloseSuccessModal, 3000)
      })
      .catch(() => {
        setModalOpen(false)
        setErrorModalOpen(true)
        setTimeout(handleErrorModalClose, 3000)
      })
  }

  return (
    <>
      <Card shaded reducedSpacing>
        <Text alignment='center' style='body-text-small' tag='p'>
          How are your results? Would you like to <Link style={{ verticalAlign: 'inherit' }} component='button' onClick={handleModalOpen}>provide feedback</Link>?
        </Text>
      </Card>

      <CartFeedbackSuccessModal open={successModalOpen} />
      <CartFeedbackErrorModal open={errorModalOpen} onClose={handleErrorModalClose} />
      <CartFeedbackFormModal
        handleSubmit={handleSubmit}
        onClose={handleModalClose}
        open={modalOpen}
      />
    </>
  )
}

CartFeedbackCard.propTypes = {
  cartID: PropTypes.number.isRequired,
  clientID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
}

export default CartFeedbackCard
