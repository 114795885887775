export const initialCompositeClient = {
  identifiers: [],
  phones: []
}

export const initialErrors = {
  firstPatient: [],
  secondPatient: []
}

export const initialState = {
  firstPatient: null,
  secondPatient: null,
  compositeClient: null,
  errors: initialErrors,
  selectionsValid: false,
  options: []
}
