import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CircularProgress from '@material-ui/core/CircularProgress'

import './LoadingSpinner.css'

class LoadingSpinner extends Component {
  getClassName () {
    return classNames(
      'LoadingSpinner',
      {
        'LoadingSpinner--inline': this.props.display === 'inline'
      }
    )
  }

  render () {
    const { size } = this.props

    return (
      <div className={this.getClassName()} data-testid='LoadingSpinner'>
        <CircularProgress
          size={size}
        />
      </div>
    )
  }
}

LoadingSpinner.defaultProps = {
  size: 60,
  display: 'block'
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  display: PropTypes.oneOf(['block', 'inline'])
}

export default LoadingSpinner
