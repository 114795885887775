import React from 'react'
import Button from '@components/Button'
import PropTypes from 'prop-types'

import WhitelabeledScreeningTextButton from '@scenes/unauthenticated/WhitelabeledScreeningTextButton'

const SurveyResultsPageActions = (props) => {
  const { local } = window
  const { findMoreResourcesLink, hasPublicSearch, printLink, screeningHomeLink } = props

  const onClick = (link, openInNewTab = false) => {
    openInNewTab ? window.open(link, '_blank') : window.location = link
  }

  return (
    <div className='survey-results-buttons'>
      <Button
        dataTest={'survy-print-results-button'}
        children={local.t('surveys.results.print')}
        type='primary'
        onClick={() => onClick(printLink, true)}
      />
      <WhitelabeledScreeningTextButton />
      { hasPublicSearch
        ? <Button
          dataTest={'survy-results-find-more-resources-button'}
          children={local.t('surveys.results.find_more_resources')}
          type='primary'
          onClick={() => onClick(findMoreResourcesLink)}
        />
        : <Button
          dataTest={'survy-results-screening-home-button'}
          children={local.t('surveys.results.screening_home')}
          type='primary'
          onClick={() => onClick(screeningHomeLink)}
        />
      }
    </div>
  )
}

SurveyResultsPageActions.propTypes = {
  findMoreResourcesLink: PropTypes.string,
  hasPublicSearch: PropTypes.bool,
  printLink: PropTypes.string,
  screeningHomeLink: PropTypes.string
}

export default SurveyResultsPageActions
