const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const isEmail = (string) => {
  const regex = RegExp(/^[a-z0-9](\.?\+?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i)
  return regex.test(string)
}

const isPhone = (string = '') => {
  const digitsOnly = string.replace(/\D*/g, '')

  return /^1?[0-9]{10}$/.test(digitsOnly)
}

const searchSafeString = string => string.replace(/[[\]()\\]/ig, '')

export {
  capitalize,
  isEmail,
  isPhone,
  searchSafeString
}
