import {
  ADD_ADDRESS_TO_COMPOSITE_CLIENT,
  ADD_ATTRIBUTE_TO_COMPOSITE_CLIENT,
  ADD_CLIENT,
  ADD_CLIENT_SELECT_ERROR,
  ADD_IDENTIFIER_TO_COMPOSITE_CLIENT,
  ADD_NOTE,
  ADD_PHONE_TO_COMPOSITE_CLIENT,
  LOAD_MERGE_INTO_STATE,
  PREPOPULATE_COMPOSITE_FROM_SELECTION,
  REMOVE_ADDRESS_FROM_COMPOSITE_CLIENT,
  REMOVE_ATTRIBUTE_FROM_COMPOSITE_CLIENT,
  REMOVE_CLIENT_SELECTION,
  REMOVE_IDENTIFIER_FROM_COMPOSITE_CLIENT,
  REMOVE_PHONE_FROM_COMPOSITE_CLIENT,
  RESET_COMPOSITE_CLIENT,
  SET_SELECTION_VALIDATION_READY_STATE
} from '@scenes/clients/ClientMerge/constants'
import {
  addAddressToComposite,
  addAttributeToComposite,
  addIdentifierToComposite,
  addPhoneToComposite,
  removeAddressFromComposite,
  removeAttributeFromComposite,
  removeIdentifierFromComposite,
  removePhoneFromComposite
} from './composite-client-utilities'
import { assignInferredStatusToCompositeClient } from '@scenes/clients/ClientMerge/utils'
import { initialState, initialCompositeClient } from './initial-state'

function getIndexFromDirection (direction) {
  if (direction === 'first') return 'firstPatient'
  if (direction === 'second') return 'secondPatient'
}

function clientMergeReducer (state = initialState, action) {
  switch (action.type) {
    case ADD_ADDRESS_TO_COMPOSITE_CLIENT:
      return addAddressToComposite(
        state,
        action.payload.sourceClientId,
        action.payload.address
      )

    case ADD_ATTRIBUTE_TO_COMPOSITE_CLIENT:
      return addAttributeToComposite(
        state,
        action.payload.sourceClientId,
        action.payload.attributeName
      )

    case ADD_IDENTIFIER_TO_COMPOSITE_CLIENT:
      return addIdentifierToComposite(
        state,
        action.payload.sourceClientId,
        action.payload.identifier
      )

    case ADD_NOTE:
      return {
        ...state,
        note: action.payload
      }

    case ADD_PHONE_TO_COMPOSITE_CLIENT:
      return addPhoneToComposite(
        state,
        action.payload.sourceClientId,
        action.payload.phone
      )

    case ADD_CLIENT:
      let patientLabel = getIndexFromDirection(action.payload.direction)
      const removedErrorState = {
        ...state.errors,
        [patientLabel]: []
      }

      return {
        ...state,
        [`${action.payload.direction}Patient`]: action.payload.client,
        compositeClient: null,
        errors: removedErrorState
      }

    case LOAD_MERGE_INTO_STATE:
      const compositeClient = assignInferredStatusToCompositeClient(
        action.payload.clients,
        action.payload.compositeClient
      )

      return {
        ...state,
        ...action.payload,
        compositeClient,
        firstPatient: action.payload.clients[0],
        secondPatient: action.payload.clients[1]
      }

    case PREPOPULATE_COMPOSITE_FROM_SELECTION:
      return {
        ...state,
        compositeClient: {
          ...initialCompositeClient,
          ...action.payload
        }
      }

    case REMOVE_ADDRESS_FROM_COMPOSITE_CLIENT:
      return removeAddressFromComposite(state, action.payload.address)

    case REMOVE_ATTRIBUTE_FROM_COMPOSITE_CLIENT:
      return removeAttributeFromComposite(state, action.payload.attributeName)

    case REMOVE_IDENTIFIER_FROM_COMPOSITE_CLIENT:
      return removeIdentifierFromComposite(state, action.payload.identifier)

    case REMOVE_CLIENT_SELECTION:
      return {
        ...state,
        [`${action.payload}Patient`]: null,
        compositeClient: null,
        errors: initialState.errors
      }

    case REMOVE_PHONE_FROM_COMPOSITE_CLIENT:
      return removePhoneFromComposite(state, action.payload.phone)

    case SET_SELECTION_VALIDATION_READY_STATE:
      const selectionsValid = action.payload

      return {
        ...state,
        selectionsValid,
        errors: selectionsValid ? initialState.errors : state.errors
      }

    case ADD_CLIENT_SELECT_ERROR:
      let patient = getIndexFromDirection(action.payload.direction)
      const newErrorsState = { ...state.errors }

      newErrorsState[patient].push(action.payload.error)

      return {
        ...state,
        errors: newErrorsState
      }

    case RESET_COMPOSITE_CLIENT:
      return {
        ...state,
        compositeClient: initialCompositeClient
      }

    default: return state
  }
}

export default clientMergeReducer
