import { localeStringGenerator } from '@utils/localeUtil'

export default function goalValidator (setError) {
  return ({ title, filterCategories }) => {
    const getString = localeStringGenerator('patients.goals.form.validation')

    if (!title) {
      setError('title', [getString('no_title')])

      return false
    }

    if (!filterCategories || !filterCategories.length) {
      setError('filterCategories', [getString('no_filter_categories')])

      return false
    }

    return true
  }
}
