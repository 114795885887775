import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import useEffectOnMount from '@hooks/useEffectOnMount'
import useLocalStorage from '@hooks/useLocalStorage'

import { setFormValue, toggleKey } from '@actions'
import { handleClientChange, loadSearchOptions, submitSearchForm } from '@scenes/resourceSites/actions'
import { getRecord } from '@selectors'
import { currentRadiusOption, guidedSearchRadiusOptions } from '@scenes/resourceSites/utils'

import Button from '@components/Button'
import ClientSearchField from './ClientSearchField'
import DistanceField from './DistanceField'
import ElementWithTooltip from '@components/ElementWithTooltip'
import Form from '@components/Form'
import GeoFilterField from './GeoFilterField'
import GuidedSearchField from '@components/SearchFilters/components/GuidedSearchField'
import Icon from '@components/Icon'
import RadioButton from '@components/RadioButton'
import Select from '@components/Select'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import LoadingDataWrapper from '@components/LoadingDataWrapper'

import './GuidedSearchForm.scss'

const GuidedSearchForm = ({
  handleClientChange,
  loadSearchOptions,
  setFormValue,
  submitSearchForm,
  toggleKey,
  ...props
}) => {
  const storedSelectedProgramIds = props.search.selectedProgramIds.value || []
  const [selectedProgramIds, setSelectedProgramIds] = useLocalStorage('selectedProgramIds', storedSelectedProgramIds)

  // Replaces componentDidMount
  useEffectOnMount(() => {
    if (props.clientBasedSearch && !props.client && props.search.clientId.value) {
      toggleKey({
        namespace: 'resourceSites',
        key: 'loadingSearchParams',
        value: true
      })

      handleClientChange(props.search.clientId.value, ['address'])
    }

    if (!props.filterCategories.length || !props.resourcePrograms.length) {
      loadSearchOptions()
    }

    // TODO: When the `integrated_search_results_flag` is removed, remove the whole following
    // conditional in addition to making the TODO change noted in resourceSites/reducers
    if (props.featureFlags.integrated_search_results_flag) {
      setFormValue({
        namespace: 'resourceSites',
        form: 'search',
        key: 'resourceKind',
        value: 'integrated'
      })
    }

    setSelectedProgramIds(storedSelectedProgramIds)
  })

  const onInputValueChange = (value) => {
    setFormValue({
      namespace: 'resourceSites',
      form: 'search',
      key: 'inputValue',
      value
    })
  }

  const onRadiusChange = (valueObj) => {
    setFormValue({
      namespace: 'resourceSites',
      form: 'search',
      key: 'radius',
      value: valueObj && valueObj.value
    })
  }

  const onGeoFilterChange = (value) => {
    if (props.search.geofilterInferred.value) {
      setFormValue({
        namespace: 'resourceSites',
        form: 'search',
        key: 'geofilterInferred',
        value: false
      })
    }

    setFormValue({
      namespace: 'resourceSites',
      form: 'search',
      key: 'geofilter',
      value
    })
  }

  const onMatchChange = (value) => {
    setFormValue({
      namespace: 'resourceSites',
      form: 'search',
      key: 'matchType',
      value
    })
  }

  const onSelectedProgramsChange = (programIds) => {
    // Store program IDs in local storage
    setSelectedProgramIds(programIds)
    // Store program IDs in redux store
    setFormValue({
      namespace: 'resourceSites',
      form: 'search',
      key: 'selectedProgramIds',
      value: programIds
    })
  }

  const submitSearch = () => {
    submitSearchForm({
      history: props.history,
      target: props.target
    })
  }

  const renderServiceOfferingsField = () => {
    return (
      <div className='GuidedSearchForm__service-offerings'>
        <Spacing marginRight={1}>
          <Text brandingEmphasis style='body-text'>
            {window.local.t('resource_sites.guided_search_form.service_offerings.label')}
          </Text>
        </Spacing>

        <GuidedSearchField
          filterCategories={props.filterCategories}
          id='search_what'
          inputValue={props.search.inputValue.value}
          onInputValueChange={onInputValueChange}
          onSelectedProgramsChange={onSelectedProgramsChange}
          programs={props.resourcePrograms}
          selectedProgramIds={selectedProgramIds}
        />
      </div>
    )
  }

  const renderMatchField = () => {
    return (
      <div className='GuidedSearchForm__match'>
        <Text brandingEmphasis style='body-text'>
          {window.local.t('resource_sites.guided_search_form.match.label')}
        </Text>

        <Spacing marginX={1}>
          <Text brandingEmphasis style='body-text'>
            <RadioButton
              label={window.local.t('resource_sites.guided_search_form.match.any.label')}
              ariaLabel={window.local.t('resource_sites.guided_search_form.match.any.aria_label')}
              name='match'
              value='any'
              checked={props.search.matchType.value === 'any'}
              onChange={() => onMatchChange('any')}
            />
          </Text>
        </Spacing>

        <Text brandingEmphasis style='body-text'>
          <RadioButton
            label={window.local.t('resource_sites.guided_search_form.match.all.label')}
            ariaLabel={window.local.t('resource_sites.guided_search_form.match.all.aria_label')}
            name='match'
            value='all'
            checked={props.search.matchType.value === 'all'}
            onChange={() => onMatchChange('all')}
          />
        </Text>
        <Spacing marginLeft={0.5}>
          <Text brandingEmphasis style='body-text'>
            {window.local.t('resource_sites.guided_search_form.match.suffix')}
          </Text>
        </Spacing>
      </div>
    )
  }

  const renderRadiusField = () => {
    const currentOption = currentRadiusOption(props.search.radius.value)

    return (
      <div className='GuidedSearchForm__radius'>
        <Spacing marginRight={1}>
          <Text brandingEmphasis style='body-text'>
            {window.local.t('resource_sites.guided_search_form.radius.label')}
          </Text>
        </Spacing>

        <Select
          required
          maxMenuHeight={null}
          onChange={onRadiusChange}
          options={guidedSearchRadiusOptions()}
          value={currentOption}
          showDropdownIndicator
          hideClearIndicator
          classNamePrefix='radius-options'
        />
      </div>
    )
  }

  const renderSubmit = () => {
    return (
      <div className='GuidedSearchForm__submit'>
        <ElementWithTooltip
          enabled={!props.search.geofilter.value}
          tooltipContent={window.local.t('resource_sites.guided_search_form.submit.tooltip')}
        >
          <Button
            data-test='guided-search-submit'
            disabled={!props.search.geofilter.value}
            onClick={submitSearch}
            className='Button--primaryDark'
            htmlType='submit'
            type='primaryDark'
          >
            <Text withIcon tag='span' style='buttons'>
              {window.local.t('resource_sites.guided_search_form.submit.button')}

              <Icon iconKey='search' size={12} />
            </Text>
          </Button>
        </ElementWithTooltip>
      </div>
    )
  }

  return (
    <div className='GuidedSearchForm' data-testid='GuidedSearchForm'>
      <Spacing paddingX={{ xs: 3 }}>
        <LoadingDataWrapper loadingData={props.clientBasedSearch &&
          props.loadingSearchParams
        }>
          <Form onSubmit={submitSearch}>
            <Spacing paddingX={0.5} paddingY={1}>
              <Spacing marginBottom={2}>
                {renderServiceOfferingsField()}
              </Spacing>

              {renderMatchField()}
            </Spacing>

            <Spacing paddingX={0.5} paddingY={1}>
              {props.clientBasedSearch
                ? <ClientSearchField onClientChange={handleClientChange} client={props.client} />
                : renderRadiusField()}
            </Spacing>

            <Spacing paddingX={0.5} paddingY={1}>
              <Spacing marginBottom={1}>
                <GeoFilterField
                  clientBasedSearch={props.clientBasedSearch}
                  errors={props.search.geofilter.errors}
                  onChange={onGeoFilterChange}
                  value={props.search.geofilter.value}
                />
              </Spacing>

              <Spacing marginLeft={4}>
                {
                  props.clientBasedSearch &&
                  <DistanceField onChange={onRadiusChange} radius={props.search.radius} />
                }
              </Spacing>
            </Spacing>

            <Spacing paddingX={0.5} paddingY={1}>
              {renderSubmit()}
            </Spacing>
          </Form>
        </LoadingDataWrapper>
      </Spacing>
    </div>
  )
}

GuidedSearchForm.propTypes = {
  handleClientChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  featureFlags: PropTypes.object.isRequired,
  loadingSearchParams: PropTypes.bool.isRequired,
  loadSearchOptions: PropTypes.func.isRequired,
  search: PropTypes.shape({
    geofilter: PropTypes.object.isRequired,
    inputValue: PropTypes.object.isRequired,
    matchType: PropTypes.shape({
      value: PropTypes.oneOf(['any', 'all'])
    }),
    radius: PropTypes.object.isRequired,
    selectedProgramIds: PropTypes.object.isRequired,
    geofilterInferred: PropTypes.object,
    clientId: PropTypes.object
  }).isRequired,
  setFormValue: PropTypes.func.isRequired,
  submitSearchForm: PropTypes.func.isRequired,
  toggleKey: PropTypes.func.isRequired,
  client: PropTypes.object,
  clientBasedSearch: PropTypes.bool,
  filterCategories: PropTypes.array,
  resourcePrograms: PropTypes.array,
  target: PropTypes.string
}

GuidedSearchForm.defaultProps = {
  clientBasedSearch: false
}

const mapDispatchToProps = {
  handleClientChange,
  loadSearchOptions,
  setFormValue,
  submitSearchForm,
  toggleKey
}

const mapStateToProps = (state, props) => {
  const searchOptions = getRecord(state, 'search_options', null) || {}

  const filterCategories = sortBy(searchOptions.filterCategories, 'attributes.name')
  const resourcePrograms = sortBy(searchOptions.resourcePrograms, 'attributes.name')
  const patientAccess = state.global.settings.patient_access
  const clientBasedSearch = patientAccess
  const client = getRecord(state, 'patient', state.resourceSites.search.clientId.value)
  const { featureFlags } = state.global

  return {
    client,
    search: state.resourceSites.search,
    clientBasedSearch,
    featureFlags,
    filterCategories,
    resourcePrograms,
    loadingSearchParams: state.resourceSites.loadingSearchParams
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GuidedSearchForm))
