import React from 'react'
import PropTypes from 'prop-types'

import Text from '@components/Text'
import Icon from '@components/Icon'
import Spacing from '@components/Spacing'
import Button from '@components/Button'

const Confirmation = (props) => {
  return (
    <div
      className='SelfReferral__Confirmation'
      style={{ textAlign: 'center' }}
    >
      <Icon
        iconKey='check_circle'
        color='green'
        size={64}
      />

      <Spacing margin={2}>
        <Text tag='p' style='body-text-emphasis'>
          We've contacted this organization.
        </Text>

        <Text tag='p'>
          We've reached out to this organization and will update you if we hear back. In the meantime, download their information to keep handy.
        </Text>
      </Spacing>

      <Button type='primary' onClick={props.downloadPdf}>
        Download PDF
      </Button>
    </div>
  )
}

Confirmation.propTypes = {
  downloadPdf: PropTypes.func.isRequired
}

export default Confirmation
