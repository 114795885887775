import React from 'react'
import PropTypes from 'prop-types'

import DlRow from '@components/DlRow'

const DlList = ({ listItems }) => {
  return (
    <dl style={{ lineHeight: '1', margin: '0' }}>
      <h4 style={{ marginBottom: '24px' }} className='hfysg-h4'>Screening Answers</h4>
      {listItems.map((listItem, i) => (
        <DlRow
          key={listItem.id}
          dt={listItem.question_prompt}
          dd={listItem.human_value}
          header={`Question ${i + 1}`}
        />
      ))}
    </dl>
  )
}

DlList.propTypes = {
  listItems: PropTypes.array.isRequired
}

export default DlList
