import every from 'lodash/every'

export const getCartItemsForReferralTiers = (cartItems = [], tiers) => (
  cartItems.filter((cartItem) => tiers.includes(cartItem.tier))
)

export const countRemainingCartItemsByTiers = (cartItems, tiers) => (getCartItemsForReferralTiers(cartItems, tiers).length
)

export const getConsentRequiredCartItems = (cartItems) => (
  cartItems.filter((cartItem) => cartItem.consentRequired)
)

export const isConsentGrantedForCartItems = (cartItems) => (
  every(getConsentRequiredCartItems(cartItems), { consentGranted: true })
)
