import {
  BOOTSTRAP_APP,
  FLUSH_ENTITIES,
  LOAD_ENTITIES,
  REMOVE_ENTITY,
  ADD_ENTITY,
  SET_ANALYTICS_REFERRER,
  SET_FORM_FIELD_ERRORS,
  SET_FORM_VALUE,
  TOGGLE_KEY,
  LOAD_PAGINATION_DATA,
  UPDATE_ENTITY
} from '@constants'

import { loadJsonApiObject } from '@utils/apiUtil'

export const bootstrapApp = payload => ({
  type: BOOTSTRAP_APP, payload
})

export const flushEntities = (type) => ({
  type: FLUSH_ENTITIES, payload: { type: type }
})

export const setAnalyticsReferrer = ({ analyticsReferrerId, analyticsReferrerType } = {}) => ({
  type: SET_ANALYTICS_REFERRER, payload: { analyticsReferrerId, analyticsReferrerType }
})

export const loadEntities = data => ({
  type: LOAD_ENTITIES, payload: { entities: loadJsonApiObject(data) }
})

export const updateEntity = ({ type, id, updatedObject, path = [], updateMethod = 'replace' }) => ({
  type: UPDATE_ENTITY, payload: { type, id, updatedObject, path, updateMethod }
})

export const removeEntity = ({ type, id }) => ({
  type: REMOVE_ENTITY, payload: { type, id }
})

export const addEntity = data => ({
  type: ADD_ENTITY, payload: { entities: loadJsonApiObject(data) }
})

export const setFormValue = ({ namespace, form, key, value }) => ({
  type: `${namespace}/${SET_FORM_VALUE}`, payload: { form, key, value }
})

export const setFormFieldErrors = ({ namespace, form, key, errors }) => ({
  type: `${namespace}/${SET_FORM_FIELD_ERRORS}`, payload: { form, key, errors }
})

export const toggleKey = ({ namespace, key, value }) => ({
  type: `${namespace}/${TOGGLE_KEY}`, payload: { key, value }
})

export const loadPaginationData = ({ namespace, metadata }) => ({
  type: `${namespace}/${LOAD_PAGINATION_DATA}`,
  payload: metadata
})
