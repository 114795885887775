import React from 'react'
import PropTypes from 'prop-types'

import Toggle from '../Toggle'

class ToggleFilter extends React.PureComponent {
  get filterClasses () {
    let filterClasses = ['toggle-filter']

    if (this.props.isToggled) {
      filterClasses.push('toggle-filter--applied')
    }

    return filterClasses.join(' ')
  }

  get coordinateIcon () {
    return (
      <i
        className='material-icons mi--16 notranslate'
        data-test='coordinate-earmark'
      >wifi_tethering</i>
    )
  }

  render () {
    const { title, isToggled, onChange, inputName } = this.props
    return (
      <div className={this.filterClasses} data-test-toggle={title}>
        <p className='toggle-filter__title'>
          {this.props.coordinate ? this.coordinateIcon : ''}
          {title}
        </p>
        <Toggle isToggled={isToggled} name={inputName} onChange={onChange} />
      </div>
    )
  }
}

ToggleFilter.propTypes = {
  inputName: PropTypes.string.isRequired,
  isToggled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  coordinate: PropTypes.bool
}

export default ToggleFilter
