import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import Grid from '@material-ui/core/Grid'

import { getRecord, getAssociation, denormalizeRecords, getCollectionFromRelationship } from '@selectors'
import { formatName } from '@utils/formatterUtil'

import {
  attachmentEvents,
  noteEvents,
  referralPatientFollowupEvents,
  referralStateEvents,
  referralEncounterEvents,
  intakeResponseSetEvent,
  userText
} from '../utils/referralTimelineUtil'

import {
  EVENT_TYPE_ATTACHMENT,
  EVENT_TYPE_NOTE,
  EVENT_TYPE_ENCOUNTER,
  EVENT_TYPE_CLOSED_LOOP_STATUS_UPDATE,
  EVENT_TYPE_MEMBER_LED_CLOSED_LOOP_STATUS_UPDATE,
  EVENT_TYPE_REFERRAL_PATIENT_FOLLOWUP,
  EVENT_TYPE_STATUS_UPDATE,
  EVENT_TYPE_CREATED,
  EVENT_TYPE_INTAKE_RESPONSE_SET
} from '../constants'

import Text from '@components/Text'
import Spacing from '@components/Spacing'

import AttachmentEvent from './AttachmentEvent'
import CreatedEvent from './CreatedEvent'
import EncounterEvent from './EncounterEvent'
import NoteEvent from './NoteEvent'
import ClosedLoopStatusUpdateEvent from './ClosedLoopStatusUpdateEvent'
import MemberLedClosedLoopStatusUpdateEvent from './MemberLedClosedLoopStatusUpdateEvent'
import StatusUpdateEvent from './StatusUpdateEvent'
import ReferralPatientFollowupEvent from './ReferralPatientFollowupEvent'
import IntakeResponseSetEvent from './IntakeResponseSetEvent'

import './ReferralTimeline.scss'

class ReferralTimeline extends PureComponent {
  events () {
    return [
      ...attachmentEvents({
        attachments: this.props.attachments,
        analyticsReferrer: this.props.analyticsReferrer
      }),
      ...intakeResponseSetEvent({
        intakeResponseSet: this.props.intakeResponseSet,
        referrer: this.props.referrer
      }),
      ...noteEvents({
        notes: this.props.notes
      }),
      ...referralPatientFollowupEvents({
        patient: this.props.patient,
        referralPatientFollowups: this.props.referralPatientFollowups
      }),
      ...referralStateEvents({
        referralStates: this.props.referralStates
      }),
      ...referralEncounterEvents({
        referralEncounters: this.props.referralEncounters,
        programAssignments: this.props.programAssignments
      })
    ]
  }

  sortedEvents () {
    return this.events().sort((event1, event2) => {
      return moment(event1.timestamp).isBefore(moment(event2.timestamp)) ? 1 : -1
    })
  }

  processedEvents () {
    const { currentUser, pdf, referral } = this.props
    const { closedLoop, serviceName } = referral
    const events = this.sortedEvents()

    return events.map(event => {
      const isLastElement = event === events[events.length - 1]
      const user = event.user && userText({ actingUser: event.user, currentUser, pdf, serviceName })

      event['isLastElement'] = isLastElement
      event.userText = user
      event.closedLoop = closedLoop
      event.serviceName = serviceName

      // NOTE: Properties specific to CreatedEvent
      // selfReferral: Used to select correct i18n
      // userText: Updated to reflect self referrals being created by the referred client
      if (event.type === EVENT_TYPE_CREATED) {
        const { selfReferral } = this.props.referral

        event.selfReferral = selfReferral

        if (selfReferral) {
          event.userText = formatName(this.props.patient)
        }
      }

      return event
    })
  }

  renderEvent (event) {
    switch (event.type) {
      case EVENT_TYPE_ATTACHMENT:
        return <AttachmentEvent {...event} />
      case EVENT_TYPE_NOTE:
        return <NoteEvent {...event} />
      case EVENT_TYPE_ENCOUNTER:
        return <EncounterEvent {...event} />
      case EVENT_TYPE_CLOSED_LOOP_STATUS_UPDATE:
        return <ClosedLoopStatusUpdateEvent {...event} />
      case EVENT_TYPE_MEMBER_LED_CLOSED_LOOP_STATUS_UPDATE:
        return <MemberLedClosedLoopStatusUpdateEvent {...event} />
      case EVENT_TYPE_STATUS_UPDATE:
        return <StatusUpdateEvent {...event} />
      case EVENT_TYPE_CREATED:
        return <CreatedEvent {...event} />
      case EVENT_TYPE_REFERRAL_PATIENT_FOLLOWUP:
        return <ReferralPatientFollowupEvent {...event} />
      case EVENT_TYPE_INTAKE_RESPONSE_SET:
        return <IntakeResponseSetEvent {...event} />
    }
  }

  renderTimelineHeader () {
    return <Spacing marginBottom={4}>
      <Text style='sub-header-medium' tag='h2'>
        {window.local.t('referrals.timeline.header')}
      </Text>
    </Spacing>
  }

  renderTimeSince (event, pdf) {
    const browser_tz = moment.tz.guess()
    if (pdf) {
      return <Text style='body-text-secondary'>
        {moment(event.timestamp).format('MM/DD/YYYY hh:mm a')}
      </Text>
    } else {
      console.log("test", event)
      return <Text style='body-text-secondary'>
        {moment.tz(event.timestamp, browser_tz).format('MM/DD/YYYY hh:mm a z')}
      </Text>
    }
  }

  renderTimelineDot () {
    return <div className='ReferralTimeline__timeline-dot-container'>
      <div className='ReferralTimeline__timeline-dot-background'>
        <div className='ReferralTimeline__timeline-dot' />
      </div>
    </div>
  }

  renderEvents () {
    const events = this.processedEvents()

    return events.map(event => {
      return (
        <Grid key={event.key} data-test={event.key} container alignItems='baseline' justify='space-between'>
          <Grid item xs={2}>
            {this.renderTimeSince(event, this.props.pdf)}
          </Grid>
          <Grid item xs={10}>
            {this.renderTimelineDot()}
            {this.renderEvent(event)}
          </Grid>
        </Grid>
      )
    })
  }

  render () {
    return (
      <div
        className='ReferralTimeline_container'
        data-test='referral-activity-timeline'
        data-testid='ReferralTimeline'
      >
        {this.renderTimelineHeader()}
        {this.renderEvents()}
      </div>
    )
  }
}

ReferralTimeline.propTypes = {
  analyticsReferrer: PropTypes.object,
  attachments: PropTypes.array,
  currentUser: PropTypes.object,
  intakeResponseSet: PropTypes.object,
  notes: PropTypes.array,
  patient: PropTypes.object.isRequired,
  programAssignments: PropTypes.array,
  referralEncounters: PropTypes.array,
  referralPatientFollowups: PropTypes.array,
  referral: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  referralId: PropTypes.string.isRequired,
  referrer: PropTypes.object.isRequired,
  referralStates: PropTypes.array,
  pdf: PropTypes.bool
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const attachments = getAssociation(state, referral, 'attachments')
  const referralEncounters = getCollectionFromRelationship({
    state,
    type: 'referral_encounter',
    association: 'referral',
    id: props.referralId
  })
  const referrer = getAssociation(state, referral, 'referrer')

  return {
    analyticsReferrer: state.global.analyticsReferrer,
    attachments: denormalizeRecords(state, attachments),
    currentUser: state.global.currentUser,
    notes: denormalizeRecords(state, getAssociation(state, referral, 'notes')),
    patient: getAssociation(state, referral, 'patient'),
    programAssignments: getAssociation(state, referral, 'referralResourceProgramAssignments'),
    referralEncounters: denormalizeRecords(state, referralEncounters),
    referralPatientFollowups: getAssociation(state, referral, 'referralPatientFollowups'),
    referralStates: denormalizeRecords(state, getAssociation(state, referral, 'referralStates')),
    intakeResponseSet: getAssociation(state, referral, 'intakeResponseSet'),
    referral,
    referrer
  }
}

export default connect(mapStateToProps, null)(ReferralTimeline)
