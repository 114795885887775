import React from 'react'
import PropTypes from 'prop-types'

const VimeoVideo = ({ height, width, title, videoId }) => {
  return (
    <div>
      <iframe
        src={`https://player.vimeo.com/video/${videoId}`}
        style={{
          width: width,
          height: height
        }}
        title={title}
        frameBorder='0'
        allowFullScreen
        allow='autoplay; fullscreen'
      />
      <script src='https://player.vimeo.com/api/player.js' />
    </div>
  )
}

VimeoVideo.defaultProps = {
  height: '300px',
  width: '100%'
}

VimeoVideo.propTypes = {
  videoId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
}

export default VimeoVideo
