import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useEffectAfterMount from '@hooks/useEffectAfterMount'

import Checkbox from '@components/Form/Checkbox'

const ToggleStatus = ({ statuses, type, entity, onChange, label, ...props }) => {
  const toggleChecked = () => setChecked(!checked)
  const { opened: openStatus, closed: closedStatus } = statuses
  const [checked, setChecked] = useState(entity.status === closedStatus)

  useEffectAfterMount(() => {
    onChange(checked ? closedStatus : openStatus)
  }, [checked])

  return (
    <Checkbox
      id={`${type}-${entity.id}-toggle-status`}
      name={'toggle_entity_status'}
      label={label}
      onChange={toggleChecked}
      defaultChecked={checked}
      {...props}
    />
  )
}

ToggleStatus.propTypes = {
  entity: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  statuses: PropTypes.shape({
    opened: PropTypes.string,
    closed: PropTypes.string
  }).isRequired,
  type: PropTypes.string.isRequired
}

export default ToggleStatus
