import React, { PureComponent, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'

import Text from '@components/Text'
import Card from '@components/Card'
import Story from '../Story'

class Cards extends PureComponent {
  render () {
    const textContent = <Text>
      Every day is taco ipsum tuesday. Fish tacos with cabbage slaw and a side of chips and guac. These tacos are lit. Black or pinto beans? Does guac cost extra?
    </Text>

    return (
      <Fragment>
        <Story title='Default Card'>
          <Grid item xs={8}>
            <Card>{textContent}</Card>
          </Grid>
        </Story>

        <Story title='Outline variations'>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Card outlineColor='gray'>{textContent}</Card>
            </Grid>

            <Grid item xs={8}>
              <Card outlineColor='blue'>{textContent}</Card>
            </Grid>
          </Grid>
        </Story>

        <Story title='Inner-border variations'>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Card innerBorderColor='gray'>{textContent}</Card>
            </Grid>

            <Grid item xs={8}>
              <Card innerBorderColor='blue'>{textContent}</Card>
            </Grid>
          </Grid>
        </Story>

        <Story title='Shaded'>
          <Grid item xs={8}>
            <Card shaded>{textContent}</Card>
          </Grid>
        </Story>

        <Story title='Success state'>
          <Grid item xs={8}>
            <Card success>{textContent}</Card>
          </Grid>
        </Story>

        <Story title='Error state'>
          <Grid item xs={8}>
            <Card errors={['Error message 1', 'Error message 2']}>
              {textContent}
            </Card>
          </Grid>
        </Story>
      </Fragment>
    )
  }
}

Cards.displayName = 'Cards'

export default Cards
