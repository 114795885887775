import isEmpty from 'lodash/isEmpty'

import Phone from './Phone'
import PatientIdentifier from './PatientIdentifier'

const attributeMap = {
  date_of_birth: 'dateOfBirth',
  email: 'email',
  first_name: 'firstName',
  gender: 'gender',
  last_name: 'lastName',
  middle_name: 'middleName'
}

const adaptAddress = address => ({
  id: address.id,
  line1: address.line_1.value,
  line2: address.line_2.value,
  city: address.city.value,
  state: address.state.value,
  zipcode: address.zipcode.value
})

export default (object) => {
  if (isEmpty(object)) return {}

  let attributes = {
    gender: ''
  }

  Object.keys(object).forEach(attributeName => {
    const jsName = attributeMap[attributeName]
    if (jsName) {
      attributes[jsName] = object[attributeName]
    }
  })

  if (object.address) {
    attributes['address'] = {
      source: object.address.source,
      value: adaptAddress(object.address.value)
    }
  }

  if (object.phones) {
    attributes['phones'] = object.phones.map(phone => ({
      source: phone.source,
      value: Phone(phone.value)
    }))
  }

  if (object.identifiers) {
    attributes['identifiers'] = object.identifiers.map(identifier => ({
      source: identifier.source,
      value: PatientIdentifier(identifier.value)
    }))
  }

  return attributes
}
