import React from 'react'
import PropTypes from 'prop-types'
import LegacyButton from '@components/legacy/LegacyButton'
import Icon from '@components/Icon'
import './CreateReferralsButton.scss'

const CreateReferralsButton = ({
  clientConsentRequired,
  clientConsentGranted,
  showReferAnotherPatientForm,
  numberOfRemainingItems,
  ...props
}) => {
  const mobileClassName = props.isMobile ? 'CreateReferralsButton-mobile' : null
  const dataTest = props.isMobile ? 'checkout__referrals-mobile-submit' : 'checkout__referrals-submit'

  const callToActionsDisabled = () => {
    if (numberOfRemainingItems === 0) return true

    if (!clientConsentRequired) return showReferAnotherPatientForm

    const clientConsentRequiredAndGranted = clientConsentRequired && clientConsentGranted
    return showReferAnotherPatientForm || !clientConsentRequiredAndGranted
  }

  return (
    <div className={mobileClassName}>
      <LegacyButton
        data-test={dataTest}
        onClick={props.submitCartItems}
        disabled={callToActionsDisabled()}>
        {window.local.t('checkout.complete_button')}
        <Icon className='mi--push-left' iconKey='create' size={12} />
      </LegacyButton>
    </div>
  )
}

CreateReferralsButton.propTypes = {
  clientConsentRequired: PropTypes.bool,
  clientConsentGranted: PropTypes.bool,
  showReferAnotherPatientForm: PropTypes.bool,
  numberOfRemainingItems: PropTypes.number.isRequired,
  submitCartItems: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
}

export default CreateReferralsButton
