import React from 'react'
import PropTypes from 'prop-types'

import TextDiff from '@scenes/clients/ClientMerge/components/TextDiff'

class TextDiffGroup extends React.PureComponent {
  render () {
    const showDiff = this.props.showDiff
    const showDefault = !showDiff
    const showStrike = !this.props.compositeClientAttribute.inferred && showDiff

    return (
      <div>
        <TextDiff
          visualizeIntent={showDefault}
          text={this.props.selectedText}
          kind='default'
        />
        <TextDiff
          visualizeIntent={showDiff}
          text={this.props.selectedText}
          kind='highlight'
        />
        <TextDiff
          visualizeIntent={showStrike}
          text={this.props.remainderText}
          kind='strike'
        />
      </div>
    )
  }
}

TextDiffGroup.propTypes = {
  showDiff: PropTypes.bool.isRequired,
  selectedText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  remainderText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  compositeClientAttribute: PropTypes.object.isRequired
}

export default TextDiffGroup
