import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { NEED_STATUSES } from './constants'

import ExpansionPanel from '@components/ExpansionPanel'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import ToggleNeedStatus from './ToggleNeedStatus'

import { ClientNeedsContext } from './ClientNeeds'

const ListItem = ({ need, ...props }) => {
  const {
    getLastUpdatedDate,
    getResourceProgramTitles,
    getString
  } = useContext(ClientNeedsContext)
  const resourceProgramTitles = getResourceProgramTitles(need)

  return (
    <article className='ClientNeeds__ListItem'>
      <Spacing paddingY={3} paddingX={2}>
        <Spacing paddingBottom={1}>
          <Text tag='div' style='body-text-small'>
            {getString('list.item.updated_on', {
              date: getLastUpdatedDate(need)
            })}
          </Text>
        </Spacing>
        <Spacing paddingBottom={1}>
          <Text tag='h4' style='sub-header-small'>
            {need.title}
          </Text>
        </Spacing>
        {resourceProgramTitles && (
          <Text tag='p' style='body-text-small'>
            {resourceProgramTitles}
          </Text>
        )}
        <Spacing paddingTop={2}>
          <ToggleNeedStatus need={need} />
        </Spacing>
      </Spacing>
    </article>
  )
}

ListItem.propTypes = {
  need: PropTypes.object.isRequired
}

const List = ({ status, ...props }) => {
  const {
    getNeedsForStatus,
    getString
  } = useContext(ClientNeedsContext)
  const combinedClassNames = classNames('ClientNeeds__List', `ClientNeeds__List--${status}`)
  const needs = getNeedsForStatus(status)
  const { opened: openedStatus } = NEED_STATUSES
  const expanded = status === openedStatus

  return (
    <section className={combinedClassNames}>
      <ExpansionPanel
        expandText={getString(`list.status.${status}`, { count: needs.length })}
        collapseText={getString(`list.status.${status}`, { count: needs.length })}
        expanded={expanded}
        withHeader
      >
        {needs.map((need) => <ListItem need={need} key={need.id} />)}
        {!needs.length && (
          <article className='ClientNeeds__ListItem ClientNeeds__ListItem--empty'>
            <Spacing paddingY={3} paddingX={2}>
              <Text tag='p'>{getString(`empty.${status}`)}</Text>
            </Spacing>
          </article>
        )}
      </ExpansionPanel>
    </section>
  )
}

List.propTypes = {
  status: PropTypes.string.isRequired
}

List.defaultProps = {
  status: 'opened'
}

export default List
