import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateEntity, loadEntities } from '@actions'

const useUpdateRecord = ({ entityUpdaters = [], useLoading = true } = {}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const updateRecord = (updateRecordRequest) => {
    const request = updateRecordRequest()

    request.then(response => {
      dispatch(loadEntities(response))
      entityUpdaters.forEach(entityUpdater => {
        dispatch(updateEntity(entityUpdater))
      })
      if (useLoading) setLoading(false)
    })

    return request
  }

  return { loading, updateRecord }
}

export default useUpdateRecord
