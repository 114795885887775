import React, { Component } from 'react'
import ElementWithTooltip from '@components/ElementWithTooltip'
import StampWithIcon from '@components/StampWithIcon'
import PropTypes from 'prop-types'
import '../StampWithIcon/StampWithIcon.scss'

class StampWithIconAndToolTip extends Component {
  render () {
    const { text, width, tooltipContent, color, iconKey, iconSize } = this.props

    return (
      <ElementWithTooltip tooltipContent={tooltipContent} width={width} {...this.props}>
        <StampWithIcon text={text} color={color} iconKey={iconKey} iconSize={iconSize} />
      </ElementWithTooltip>
    )
  }
}

StampWithIconAndToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  iconKey: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
  iconSize: PropTypes.number.isRequired,
  color: PropTypes.oneOf([
    'red',
    'orange',
    'green',
    'grey',
    'blue',
    'blue-05'
  ]).isRequired
}

export default StampWithIconAndToolTip
