import React, { createContext } from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import List from './List'

import './ClientNeeds.scss'

import useClientNeeds from './useClientNeeds'

export const ClientNeedsContext = createContext()

const { Provider } = ClientNeedsContext

const ClientNeeds = ({
  children,
  clientId,
  ...props }) => {
  const value = useClientNeeds(clientId)

  return (
    <Provider value={value}>
      <div className='ClientNeeds' {...props}>
        {children}
      </div>
    </Provider>
  )
}

ClientNeeds.propTypes = {
  children: PropTypes.node.isRequired,
  clientId: PropTypes.number.isRequired
}

ClientNeeds.Header = Header
ClientNeeds.List = List

export default ClientNeeds
