import React from 'react'

import PageHeader from '@components/PageHeader'

const MemberPage = () => {
  return (
    <PageHeader
      title={window.local.t('closed_loop_referrals.cbo.title')}
      description={window.local.t('closed_loop_referrals.cbo.description')}
    />
  )
}

export default MemberPage

