import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './Alert.scss'
import Icon from '@components/Icon'

const getIconKeyByType = (type) => {
  switch (type) {
    case 'success':
      return 'check_circle'
    case 'warning':
      return 'warning'
    case 'error':
      return 'error'
  }
}

class Alert extends PureComponent {
  getAlertTitleClassName () {
    const { type, inline } = this.props
    const classNames = ['Alert__title', 'Alert__title--align-icon']

    if (inline) {
      classNames.push(`Alert__title--${type}-inline`)
    } else {
      classNames.push(`Alert__title--${type}`)
    }

    return classNames.join(' ')
  }
  render () {
    const { title, body, type, inline } = this.props
    const outerDivClassName = inline ? '' : `Alert__box Alert--${type}`

    return (
      <div className={outerDivClassName}>
        <div className={this.getAlertTitleClassName()}>
          <div>
            <Icon
              size={12}
              iconKey={getIconKeyByType(type)}
              verticalAlignment='middle'
            />
          </div>
          <div className='Alert__title__text'>{title}</div>
        </div>
        <div className='Alert__body'>
          {body}
        </div>
        <div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Alert.displayName = 'Alert'

Alert.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  inline: PropTypes.bool,
  body: PropTypes.node,
  type: PropTypes.oneOf([
    'success',
    'error',
    'warning'
  ])
}

export default Alert
