import {
  SET_FORM_VALUE,
  SET_FORM_FIELD_ERRORS,
  TOGGLE_KEY,
  LOAD_PAGINATION_DATA
} from '@constants'

import {
  setFormFieldErrors,
  setFormValue,
  toggleKey,
  loadPaginationData
} from '@reducers'

import {
  getInitialState
} from './initial-state'

import {
  CLOSE_DOWNLOAD_MODAL,
  CLOSE_EDIT_MODAL,
  CLOSE_SHARE_MODAL,
  LOAD_SHAREABLE_USERS,
  OPEN_DOWNLOAD_MODAL,
  SET_DOWNLOAD_MODAL_PROPS,
  OPEN_EDIT_MODAL,
  OPEN_SHARE_MODAL
} from '@scenes/resourceSites/constants'

function resourceSitesReducers (state = getInitialState(), action) {
  switch (action.type) {
    case `resourceSites/${SET_FORM_VALUE}`:
      return setFormValue(state, action)
    case `resourceSites/${SET_FORM_FIELD_ERRORS}`:
      return setFormFieldErrors(state, action)
    case `resourceSites/${TOGGLE_KEY}`:
      return toggleKey(state, action)
    case `resourceSites/${LOAD_PAGINATION_DATA}`:
      return loadPaginationData(state, action)
    case CLOSE_DOWNLOAD_MODAL:
      return {
        ...state,
        downloadModal: {
          ...state.downloadModal,
          open: false,
          resourceSiteId: null
        }
      }
    case CLOSE_EDIT_MODAL:
      return {
        ...state,
        editModal: {
          ...state.editModal,
          editModalOpen: false,
          resourceSiteId: null
        }
      }
    case CLOSE_SHARE_MODAL:
      return {
        ...state,
        shareModal: {
          ...state.shareModal,
          shareModalOpen: false,
          resourceSiteId: null
        }
      }
    case LOAD_SHAREABLE_USERS:
      return {
        ...state,
        shareModal: {
          ...state.shareModal,
          shareableUsers: action.payload.users
        }
      }
    case OPEN_DOWNLOAD_MODAL:
      return {
        ...state,
        downloadModal: {
          ...state.downloadModal,
          open: true,
          resourceSiteId: action.payload.resourceSiteId
        }
      }
    case SET_DOWNLOAD_MODAL_PROPS:
      return {
        ...state,
        downloadModal: {
          ...state.downloadModal,
          path: action.payload.path,
          queryParams: action.payload.queryParams,
          referrerId: action.payload.referrerId
        }
      }
    case OPEN_EDIT_MODAL:
      return {
        ...state,
        editModal: {
          ...state.editModal,
          editModalOpen: true,
          resourceSiteId: action.payload.resourceSiteId
        }
      }
    case OPEN_SHARE_MODAL:
      return {
        ...state,
        shareModal: {
          ...state.shareModal,
          resourceSiteId: action.payload.resourceSiteId,
          shareModalOpen: true
        }
      }
    default:
      return state
  }
}

export default resourceSitesReducers
