import React from 'react'
import PropTypes from 'prop-types'
import Fade from '@material-ui/core/Fade'
import './TextDiff.css'

const classNames = {
  highlight: 'TextDiff--highlight',
  strike: 'TextDiff--strikethrough',
  unselectable: 'TextDiff--unselectable',
  default: ''
}

class TextDiff extends React.PureComponent {
  render () {
    if (!this.props.visualizeIntent) return null
    const { kind } = this.props

    return (
      <div>
        <Fade in={this.props.visualizeIntent}>
          <div className={`TextDiff ${classNames[kind]}`}>
            {this.props.text}
          </div>
        </Fade>
      </div>
    )
  }
}

TextDiff.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]),
  visualizeIntent: PropTypes.bool.isRequired,
  kind: PropTypes.string.isRequired
}

export default TextDiff
