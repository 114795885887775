const ClosedLoopEndUserAttestation = () => {
  const { local } = window
  let description, header, checkboxLabel

  header = null
  description = local.t('referral_modal.fields.client_consent.description.closed_loop')
  checkboxLabel = local.t('referral_modal.fields.client_consent.label.closed_loop')

  return [header, checkboxLabel, description]
}

export default ClosedLoopEndUserAttestation
