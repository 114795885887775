import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from '@components/Modal'
import Button from '@components/Button'
import RadioButton from '@components/RadioButton'
import TextInput from '@components/TextInput'
import { toggleKey, onReferralStateChange, onReferralStateUpdate } from '../actions'

const supportedCancelationReasons = [
  'client_not_qualified',
  'client_no_show',
  'created_by_mistake',
  'client_declined_services',
  'insufficient_program_capacity',
  'client_issue_resolved',
  'reviewed_cannot_share_updates',
  'other_context'
]

class ReferralStateCancelModal extends PureComponent {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onOtherReasonChange = this.onOtherReasonChange.bind(this)
  }

  onChange (value) {
    const context = value === 'other_context' ? this.props.newState.context : ''

    this.props.onReferralStateChange({ ...this.props.newState, contextCode: value, context })
  }

  onOtherReasonChange (value) {
    this.props.onReferralStateChange({ ...this.props.newState, context: value })
  }

  renderModalContent () {
    return <Fragment>
      {supportedCancelationReasons.map(reason => (
        <RadioButton
          label={window.local.t(`referrals.show.referral_state_cancel_modal.answers.${reason}`)}
          name='referral-state-cancel-modal'
          checked={this.props.newState.contextCode === reason}
          value={reason}
          onChange={this.onChange} />
      ))}

      <TextInput
        id='cancel-other-note'
        disabled={this.props.newState.contextCode !== 'other_context'}
        label={window.local.t('referrals.show.referral_state_cancel_modal.other.label')}
        placeholder={window.local.t('referrals.show.referral_state_cancel_modal.other.placeholder')}
        value={this.props.newState.context}
        onChange={this.onOtherReasonChange} />
    </Fragment>
  }

  renderModalActions () {
    const submitIsEnabled = (this.props.newState.contextCode && this.props.newState.contextCode !== 'other_context') ||
      (this.props.newState.contextCode === 'other_context' && this.props.newState.context)

    return (
      <Button
        dataTest='cancel-modal-submit-button'
        disabled={!submitIsEnabled}
        type='primary'
        onClick={() => this.props.onReferralStateUpdate(this.props.referralId)}
      >
        {window.local.t('referrals.show.referral_state_cancel_modal.button')}
      </Button>
    )
  }

  render () {
    if (this.props.showCancelStateModal) {
      return <Modal
        dataTest='referral-state-cancel-modal'
        open={this.props.showCancelStateModal}
        onClose={() => this.props.toggleKey('showCancelStateModal', false)}
        headerText={window.local.t('referrals.show.referral_state_cancel_modal.title')}
        content={this.renderModalContent()}
        actions={this.renderModalActions()}
      />
    } else {
      return null
    }
  }
}

ReferralStateCancelModal.propTypes = {
  showCancelStateModal: PropTypes.bool,
  onReferralStateChange: PropTypes.func,
  onReferralStateUpdate: PropTypes.func,
  toggleKey: PropTypes.func.isRequired,
  newState: PropTypes.object,
  referralId: PropTypes.string
}

const mapStateToProps = (state, props) => {
  return {
    showCancelStateModal: state.referralView.showCancelStateModal,
    newState: state.referralView.newState
  }
}

const mapDispatchToProps = {
  onReferralStateChange,
  onReferralStateUpdate,
  toggleKey
}
export default connect(mapStateToProps, mapDispatchToProps)(ReferralStateCancelModal)
