import without from 'lodash/without'

export const REFERRAL_SHOW_TOGGLE_KEY = 'REFERRAL_SHOW_TOGGLE_KEY'
export const REFERRAL_SHOW_UPDATE_RECIPIENTS = 'REFERRAL_SHOW_UPDATE_RECIPIENTS'
export const REFERRAL_SHOW_SET_ALERT = 'REFERRAL_SHOW_SET_ALERT'
export const REFERRAL_SHOW_STATE_CHANGE = 'REFERRAL_SHOW_STATE_CHANGE'
export const REFERRAL_SHOW_FORM_FIELD_CHANGE = 'REFERRAL_SHOW_FORM_FIELD_CHANGE'
export const REFERRAL_SHOW_SERVICE_DETAILS_FIELD_CHANGE = 'REFERRAL_SHOW_SERVICE_DETAILS_FIELD_CHANGE'
export const REFERRAL_SHOW_RESET_FORM = 'REFERRAL_SHOW_RESET_FORM'
export const REFERRAL_SHOW_FORM_FIELD_DELETE = 'REFERRAL_SHOW_FORM_FIELD_DELETE'
export const REFERRAL_SHOW_SET_SERVICE_DETAILS_FIELD_ERRORS = 'REFERRAL_SHOW_SET_SERVICE_DETAILS_FIELD_ERRORS'

export const actions = [
  'contact_successful',
  'contact_unsuccessful',
  'waitlisted',
  'enrolled',
  'service_provided',
  'service_not_provided'
]

export const durationUnits = [
  'minutes',
  'hours',
  'days',
  'weeks',
  'months',
  'years'
]

export const serviceUnitFields = [
  'quantity',
  'durationValue',
  'durationUnit'
]

export const serviceDetailFields = [
  'serviceUnit',
  'completionStatus',
  'serviceFrequency',
  'reasonNotProvided',
  'endDate',
  ...serviceUnitFields
]

export const serviceUnitFieldsMapping = {
  caregiver_service: 'duration',
  construction_assistance: 'duration',
  general_service: 'duration',
  group_meeting: 'duration',
  meal: 'quantity',
  package: 'quantity',
  payment: 'quantity',
  program_enrollment: 'duration',
  residence: 'duration',
  ride: 'duration',
  solo_meeting: 'duration',
  assessment: 'duration', // i18n: 'Test / Assessment'
  voucher: 'quantity'
}

export const serviceUnits = Object.keys(serviceUnitFieldsMapping)

export const completionStatuses = [
  'complete',
  'incomplete'
]

export const serviceFrequencies = [
  'one_time',
  'ongoing'
]

export const reasonsNotProvided = [
  'no_contact',
  'not_eligible',
  'no_consent',
  'service_unavailable'
]

// referral timeline events
export const EVENT_TYPE_ATTACHMENT = 'attachment'
export const EVENT_TYPE_NOTE = 'note'
export const EVENT_TYPE_ENCOUNTER = 'action'
export const EVENT_TYPE_CLOSED_LOOP_STATUS_UPDATE = 'closed_loop_status_update'
export const EVENT_TYPE_MEMBER_LED_CLOSED_LOOP_STATUS_UPDATE = 'member_led_closed_loop_status_update'
export const EVENT_TYPE_REFERRAL_PATIENT_FOLLOWUP = 'referral_patient_followup'
export const EVENT_TYPE_STATUS_UPDATE = 'status_update'
export const EVENT_TYPE_CREATED = 'created'
export const EVENT_TYPE_INTAKE_RESPONSE_SET = 'intake_response_set'

export const actionsToDisplayIndividually = [
  'enrolled',
  'service_provided'
]

export const actionsToDisplayCollectively = without(actions, ...actionsToDisplayIndividually)
