import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { removeEntity } from '@actions'

const useDestroyRecord = (id, type) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const destroyRecord = destroyRecordRequest => {
    const request = destroyRecordRequest()

    setLoading(true)

    request.then(() => {
      dispatch(removeEntity({ type, id }))
    })

    return request
  }

  return { loading, destroyRecord }
}

export default useDestroyRecord
