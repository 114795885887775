import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GuidedSearchEnhancedInputSelection from '@components/SearchFilters/components/GuidedSearchEnhancedInputSelection'
import GuidedSearchEnhancedInput from '@components/SearchFilters/components/GuidedSearchEnhancedInput'
import ResourceProgramNavigator from '@components/SearchFilters/components/ResourceProgramNavigator'
import AutoCompleter from '@components/SearchFilters/components/AutoCompleter'
import { BACKSPACE_KEYCODE } from '@constants/keyboardInputCodes'
import difference from 'lodash/difference'

import './GuidedSearchField.scss'

class GuidedSearchField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      displayProgramNavigator: false,
      displayAutoCompleter: false
    }

    this.wrappingDiv = React.createRef()
    this.enhancedInput = React.createRef()

    this.onFocus = this.onFocus.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.selectProgram = this.selectProgram.bind(this)
    this.selectProgramFromCompleter = this.selectProgramFromCompleter.bind(this)
    this.selectNonProgramFromCompleter = this.selectNonProgramFromCompleter.bind(this)
    this.removeSelectedProgram = this.removeSelectedProgram.bind(this)
    this.handleProgramDelete = this.handleProgramDelete.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }

  onInputChange (inputValue) {
    this.props.onInputValueChange(inputValue)

    this.setState({
      displayProgramNavigator: false,
      displayAutoCompleter: !!inputValue
    })
  }

  handleOutsideClick (e) {
    if (this.wrappingDiv.current.contains(e.target)) return

    this.setState({
      displayProgramNavigator: false,
      displayAutoCompleter: false
    })
  }

  componentDidUpdate (prevProps) {
    if (difference(this.props.selectedProgramIds, prevProps.selectedProgramIds).length) {
      this.enhancedInput.current.focus()
    }
  }

  componentDidMount () {
    document.body.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount () {
    document.body.removeEventListener('click', this.handleOutsideClick)
  }

  selectedProgramObjects () {
    return this.props.selectedProgramIds.reduce((programs, programId) => {
      const programObj = (this.props.programs || []).find(resourceProgram => (
        resourceProgram.attributes.id === +programId
      ))

      if (programObj) {
        programs.push(programObj)
      }

      return programs
    }, [])
  }

  selectProgram (program) {
    this.props.onSelectedProgramsChange([
      ...this.props.selectedProgramIds,
      program.attributes.id
    ])
  }

  selectProgramFromCompleter (program) {
    this.selectProgram(program)
    this.onInputChange('')
  }

  selectNonProgramFromCompleter () {
    this.setState({
      displayAutoCompleter: false
    })
  }

  handleProgramDelete (e) {
    const { selectedProgramIds } = this.props

    if (e.keyCode === BACKSPACE_KEYCODE && !this.enhancedInput.current.value && selectedProgramIds.length) {
      const lastIndex = selectedProgramIds.length - 1
      this.removeSelectedProgram(selectedProgramIds[lastIndex])
    }
  }

  removeSelectedProgram (programId) {
    const programIds = this.props.selectedProgramIds
      .filter(selectedProgramId => programId !== selectedProgramId)

    this.props.onSelectedProgramsChange(programIds)
  }

  onFocus () {
    if (this.state.displayAutoCompleter) {
      this.setState({
        displayProgramNavigator: false,
        displayAutoCompleter: false
      })
    } else {
      this.setState({ displayProgramNavigator: true })
    }
  }

  renderSelectedPrograms () {
    return this.selectedProgramObjects().map((program) => (
      <GuidedSearchEnhancedInputSelection
        key={program.attributes.id}
        inputName='resource_site[resource_program_ids][]'
        selection={program}
        onDelete={() => this.removeSelectedProgram(program.attributes.id)}
      />
    ))
  }

  render () {
    return (
      <div
        className='GuidedSearchField'
        ref={this.wrappingDiv}
        data-testid='GuidedSearchField'
      >
        <GuidedSearchEnhancedInput
          data-test='guided-search-input'
          id={this.props.id}
          onChange={this.onInputChange}
          onFocus={this.onFocus}
          onKeyDown={this.handleProgramDelete}
          ref={this.enhancedInput}
          placeholder={window.local.t('search_filters.guided_search_field.placeholder')}
          value={this.props.inputValue}
        >
          {this.renderSelectedPrograms()}
        </GuidedSearchEnhancedInput>

        {this.state.displayProgramNavigator &&
          <ResourceProgramNavigator
            filterCategories={this.props.filterCategories}
            resourcePrograms={this.props.programs}
            onProgramSelect={this.selectProgram}
            selectedPrograms={this.selectedProgramObjects()}
          />
        }

        {this.state.displayAutoCompleter &&
          <AutoCompleter
            term={this.props.inputValue}
            options={this.props.programs}
            onOptionSelect={this.selectProgramFromCompleter}
            onNonOptionSelect={this.selectNonProgramFromCompleter}
            selectedOptions={this.selectedProgramObjects()}
          />
        }
      </div>
    )
  }
}

GuidedSearchField.propTypes = {
  filterCategories: PropTypes.array.isRequired,
  programs: PropTypes.array.isRequired,
  inputValue: PropTypes.string.isRequired,
  selectedProgramIds: PropTypes.array.isRequired,
  id: PropTypes.string,
  onInputValueChange: PropTypes.func.isRequired,
  onSelectedProgramsChange: PropTypes.func.isRequired
}

GuidedSearchField.defaultProps = {
  filterCategories: [],
  programs: [],
  inputValue: '',
  selectedProgramIds: []
}

GuidedSearchField.displayName = 'GuidedSearchField'

export default GuidedSearchField
