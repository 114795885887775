import every from 'lodash/every'
import { isEmail } from '@utils/stringUtil'

const validateShareByDownloadPdf = (shareFormSelections) => {
  return shareFormSelections.downloadPDF
}

// TODO: Remove this function when we remove the member_led_closed_loop_referrals_flag
const validateLegacyShareByText = (shareFormSelections) => {
  const { phoneNumber, sendText } = shareFormSelections

  return phoneNumber && sendText
}

const validateShareByText = (shareFormSelections, memberLedCLREnabled) => {
  const { memberLedCLRConsent, phoneNumber, sendText } = shareFormSelections

  // TODO: Remove this conditional when we remove the member_led_closed_loop_referrals_flag
  if (!memberLedCLREnabled) return validateLegacyShareByText(shareFormSelections)

  return memberLedCLRConsent && sendText && phoneNumber
}

const validateShareByEmail = (shareFormSelections) => {
  const { email, memberLedCLRConsent, sendEmail } = shareFormSelections

  return sendEmail && memberLedCLRConsent && isEmail(email)
}

const SHARE_VALIDATION_MAP = {
  sendEmail: validateShareByEmail,
  sendText: validateShareByText,
  downloadPDF: validateShareByDownloadPdf
}

const shareFormValid = (shareFormSelections, memberLedCLREnabled) => {
  // Reduce to an array of enabled share methods
  const enabledShareMethods = Object.keys(SHARE_VALIDATION_MAP).reduce((acc, validationKey) => {
    if (shareFormSelections[validationKey]) {
      acc.push(validationKey)
    }

    return acc
  }, [])

  // Verify that at least one method is enabled, and that all methods enabled are valid
  const shareFormValid = enabledShareMethods.length && every(enabledShareMethods, (validationKey) => {
    const validationFunction = SHARE_VALIDATION_MAP[validationKey]

    return validationFunction(shareFormSelections, memberLedCLREnabled)
  })

  return shareFormValid
}

export default shareFormValid
