import { useMemo } from 'react'

import getPropCollections from './propCollections'

export default function useCartShare (resourceSite) {
  const propCollections = getPropCollections(resourceSite)

  const value = useMemo(() => ({
    resourceSite,
    ...propCollections
  }), [resourceSite])

  return value
}
