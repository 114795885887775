export const IN_NETWORK_FILTER_PARAM = 'coordinate_only'
export const IN_NETWORK_FILTER_STATE_KEY = 'inNetwork'
export const PREFERRED_PARTNERS_FILTER_PARAM = 'preferred_partners_only'
export const PREFERRED_PARTNERS_STATE_KEY = 'inNetworkPreferred'
export const PREFERRED_PROVIDERS_FILTER_PARAM = 'preferred_providers_only'
export const PREFERRED_PROVIDERS_STATE_KEY = 'preferredProviders'
export const VERIFIED_FILTER_PARAM = 'verified_only'
export const VERIFIED_FILTER_STATE_KEY = 'verified'

export const ALL_NETWORK_TIER_STATE_KEYS = [
  IN_NETWORK_FILTER_STATE_KEY,
  PREFERRED_PARTNERS_STATE_KEY,
  PREFERRED_PROVIDERS_STATE_KEY,
  VERIFIED_FILTER_STATE_KEY,
]

export const SEARCH_QUERY_KEY_MAPPING = {
  geofilter: 'geo_filter',
  geofilterInferred: 'geofilterInferred',
  inputValue: 'search',
  matchType: 'match',
  radius: 'radius',
  resourceKind: 'resource_kind',
  selectedProgramIds: 'resource_site[resource_program_ids]',
  selectedEligibilityIds: 'resource_site[eligibility_ids]',
  accessibility: 'ada_compliant_only',
  [IN_NETWORK_FILTER_STATE_KEY]: IN_NETWORK_FILTER_PARAM,
  [PREFERRED_PARTNERS_STATE_KEY]: PREFERRED_PARTNERS_FILTER_PARAM,
  [PREFERRED_PROVIDERS_STATE_KEY]: PREFERRED_PROVIDERS_FILTER_PARAM,
  [VERIFIED_FILTER_STATE_KEY]: VERIFIED_FILTER_PARAM,
  page: 'page',
  clientId: 'client_id',
  showScores: 'show_scores'
}

export const DEFAULT_SEARCH_FORM_VALUES = {
  clientId: null,
  geofilter: '',
  geofilterInferred: false,
  // TODO: When the `integrated_search_results_flag` is removed and integrated search is the new
  // default everywhere, change the default resourceKind value below to 'integrated'
  resourceKind: 'local',
  inputValue: '',
  matchType: 'any',
  radius: '50miles',
  selectedProgramIds: [],
  selectedEligibilityIds: [],
  accessibility: 'off',
  [IN_NETWORK_FILTER_STATE_KEY]: 'off',
  [PREFERRED_PARTNERS_STATE_KEY]: 'off',
  [PREFERRED_PROVIDERS_STATE_KEY]: 'off',
  [VERIFIED_FILTER_STATE_KEY]: 'off',
  page: 1,
  showScores: null
}

export const CLOSE_SHARE_MODAL = 'CLOSE_SHARE_MODAL'
export const LOAD_SHAREABLE_USERS = 'LOAD_SHAREABLE_USERS'
export const OPEN_SHARE_MODAL = 'OPEN_SHARE_MODAL'

export const CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL'
export const OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL'

export const CLOSE_DOWNLOAD_MODAL = 'CLOSE_DOWNLOAD_MODAL'
export const OPEN_DOWNLOAD_MODAL = 'OPEN_DOWNLOAD_MODAL'
export const SET_DOWNLOAD_MODAL_PROPS = 'SET_DOWNLOAD_MODAL_PROPS'
