import React, { createContext } from 'react'
import PropTypes from 'prop-types'

import './CoverageAreaManager.scss'

import CoverageAreaCards from './CoverageAreaCards'

import useCoverageAreaManager from './useCoverageAreaManager'

export const CoverageAreaManagerContext = createContext()

const { Provider } = CoverageAreaManagerContext

const CoverageAreaManager = ({
  children,
  resourceSiteId,
  ...props }) => {
  const value = useCoverageAreaManager(resourceSiteId)

  return (
    <Provider value={value}>
      <div className='CoverageAreaManager' {...props}>
        {children}
      </div>
    </Provider>
  )
}

CoverageAreaManager.propTypes = {
  children: PropTypes.node.isRequired,
  resourceSiteId: PropTypes.number.isRequired
}

CoverageAreaManager.CoverageAreaCards = CoverageAreaCards

export default CoverageAreaManager
