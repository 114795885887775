import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CLIENT_INDEX } from '@constants'

import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

import { formatDate } from '@utils/formatterUtil'
import clientMergeService from '@services/clientMergeService'
import {
  loadMergeIntoState
} from '@scenes/clients/ClientMerge/actions'
import { emptyFieldsInComposite, unselectableAttributesBetweenClients } from '@scenes/clients/ClientMerge/utils'

import ClientDiff from '@scenes/clients/ClientMerge/components/ClientDiff'
import Icon from '@components/Icon'
import SuccessModal from '@components/SuccessModal'
import PageHeader from '@components/PageHeader'
import { Header } from '@components/Header'
import Datum from '@components/Datum'
import LegacyButton from '@components/legacy/LegacyButton'
import ButtonLink from '@components/legacy/ButtonLink'
import LoadingSpinner from '@components/LoadingSpinner'
import ExpandableTextArea from '@components/ExpandableTextArea'

import './ShowMergeView.css'

const initialState = {
  showRejectModal: false,
  showAcceptModal: false,
  waitingServerResponse: false,
  contextNote: ''
}

const STATUS = {
  reject: 'rejected',
  accept: 'accepted'
}

class ShowMergeView extends React.Component {
  constructor (props) {
    super(props)

    this.state = initialState

    this.toggleModal = this.toggleModal.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
  }

  componentDidMount () {
    const { mergeID } = this.props.match.params
    const { isLoading, loadMergeIntoState } = this.props

    if (isLoading) {
      clientMergeService
        .find(mergeID)
        .then(merge => {
          loadMergeIntoState(merge)
        })
    }
  }

  conditionallyRenderNote () {
    const { note } = this.props

    if (note) {
      return <p className='ShowMergeView__note'>{`"${note}"`}</p>
    }
  }

  editPath () {
    return `${this.props.match.params.mergeID}/build-profile`
  }

  toggleModal (kind) {
    return () => {
      this.setState({
        [`show${kind}Modal`]: !this.state[`show${kind}Modal`]
      })
    }
  }

  handleTextChange (value) {
    this.setState({
      contextNote: value
    })
  }

  renderModal (kind) {
    const { compositeClient, secondPatient, firstPatient, requester } = this.props
    const { mergeID } = this.props.match.params
    const { local } = window
    const statusKey = kind.toLowerCase()

    return (
      <Dialog
        open={this.state[`show${kind}Modal`]}
        classes={{
          paper: 'ShowMergeView__Modal'
        }}
        disableEscapeKeyDown
      >
        <Header
          className='ShowMergeView__Modal__title'
          rank='3'
          text={local.t(`client_merge.review.modals.${statusKey}.title`)}
        />
        <p className='ShowMergeView__Modal__copy'>
          {local.t(`client_merge.review.modals.${statusKey}.copy`)}
        </p>
        <IconButton aria-label='Close'
          classes={{ root: 'ShowMergeView__Modal__Close' }}
          onClick={this.toggleModal(kind)}
        >
          <Icon iconKey='close' size={24} />
        </IconButton>

        <ExpandableTextArea
          placeholder={local.t(`client_merge.review.modals.${statusKey}.placeholder`)}
          value={this.state.contextNote}
          id='contextNote'
          onChange={this.handleTextChange}
          maxLength={500}
          label={`Note for ${requester.fullName}`}
        />

        <LegacyButton
          className='ShowMergeView__Modal__CTA'
          onClick={() => {
            clientMergeService.update(
              mergeID,
              { compositeClient, secondPatient, firstPatient, contextNote: this.state.contextNote },
              STATUS[statusKey]
            ).then((response) => {
              if (response.status === 200) {
                this.setState({
                  showRejectModal: false,
                  showAcceptModal: false,
                  waitingServerResponse: true
                }, () => {
                  window.location = CLIENT_INDEX
                })
              }
            })
          }}
        >
          {local.t('client_merge.review.modals.submit')}
        </LegacyButton>
      </Dialog>
    )
  }

  renderButtons () {
    const { allAttributesSelected } = this.props
    const { local } = window

    if (allAttributesSelected) {
      return (
        <React.Fragment>
          <SuccessModal open={this.state.waitingServerResponse} />
          {this.renderModal('Reject')}
          {this.renderModal('Accept')}
          <div className='ShowMergeView__PrimaryButtonsRow'>
            <LegacyButton
              className='ShowMergeView__AcceptButton'
              disabled={!allAttributesSelected}
              onClick={this.toggleModal('Accept')}
            >
              {local.t('client_merge.review.sidebar.buttons.approve')}
            </LegacyButton>
          </div>
          <Grid
            container
            direction='row'
            justify='space-between'
            classes={{ 'container': 'ShowMergeView__SecondaryButtonsRow' }}
          >
            <Grid item xs={6} classes={{ 'grid-xs-6': 'ShowMergeView__SecondaryButtonContainer' }}>
              <LegacyButton
                variant='outlined'
                color='red'
                onClick={this.toggleModal('Reject')}
              >
                {local.t('client_merge.review.sidebar.buttons.reject')}
              </LegacyButton>
            </Grid>
            <Grid item xs={6} classes={{ 'grid-xs-6': 'ShowMergeView__SecondaryButtonContainer' }}>
              <ButtonLink variant='outlined' to={this.editPath()}>
                {local.t('client_merge.review.sidebar.buttons.edit')}
              </ButtonLink>
            </Grid>
          </Grid>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <SuccessModal open={this.state.waitingServerResponse} />
          {this.renderModal('Reject')}
          <div className='ShowMergeView__PrimaryButtonsRow'>
            <ButtonLink to={this.editPath()} className='ShowMergeView__EditButton'>
              {local.t('client_merge.review.sidebar.buttons.continue_edit')}
            </ButtonLink>
          </div>
          <div className='ShowMergeView__SecondaryButtonsRow'>
            <LegacyButton
              variant='outlined'
              color='red'
              onClick={this.toggleModal('Reject')}
            >
              {local.t('client_merge.review.sidebar.buttons.reject')}
            </LegacyButton>
          </div>
        </React.Fragment>
      )
    }
  }

  render () {
    const { createdAt, isLoading, match, loadMergeIntoState, requester, ...clientDiffProps } = this.props
    const { local } = window

    if (isLoading) return <LoadingSpinner />

    return (
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
      >
        <Grid container direction='row'>
          <PageHeader
            title={local.t('client_merge.header_title.review')}
            description={
              <span dangerouslySetInnerHTML={{ __html: local.t('client_merge.header_copy.review') }} />
            }
            backLink={{ path: '/en/clients', isExternal: true, text: local.t('client_merge.header_back_link') }}
          />
        </Grid>

        <ClientDiff {...clientDiffProps}>
          <Grid item xs={4} className='ShowMergeView__sidebar'>
            {this.conditionallyRenderNote()}
            <Grid
              container
              direction='row'
              justify='flex-start'
            >
              <Grid item xs={6}>
                <Datum
                  label={local.t('client_merge.review.sidebar.requested_by')}
                  value={<a href={requester.url}>{requester.fullName}</a>}
                />
              </Grid>
              <Grid item xs={6}>
                <Datum
                  label={local.t('client_merge.review.sidebar.request_date')}
                  value={formatDate(createdAt)}
                />
              </Grid>
            </Grid>
            <Datum
              label={local.t('client_merge.review.sidebar.clients_impacted')}
              value={
                <div>
                  <div>
                    <a href={clientDiffProps.firstPatient.url}>{clientDiffProps.firstPatient.fullNameBackwards}</a>
                  </div>
                  <div>
                    <a href={clientDiffProps.secondPatient.url}>{clientDiffProps.secondPatient.fullNameBackwards}</a>
                  </div>
                </div>
              }
            />
            {this.renderButtons()}
          </Grid>
        </ClientDiff>
      </Grid>
    )
  }
}

ShowMergeView.propTypes = {
  allAttributesSelected: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadMergeIntoState: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  compositeClient: PropTypes.object,
  firstPatient: PropTypes.object,
  secondPatient: PropTypes.object,
  requester: PropTypes.object,
  createdAt: PropTypes.string,
  note: PropTypes.string,
  unselectableAttributes: PropTypes.array
}

const mapStateToProps = state => {
  const allAttributesSelected = state.clientMerge.compositeClient
    ? !emptyFieldsInComposite(state.clientMerge).length
    : false

  const firstPatient = state.clientMerge.firstPatient
  const secondPatient = state.clientMerge.secondPatient

  const unselectableAttributes = firstPatient && secondPatient
    ? unselectableAttributesBetweenClients({ firstPatient, secondPatient })
    : []

  return {
    unselectableAttributes: unselectableAttributes,
    allAttributesSelected: allAttributesSelected,
    isLoading: !state.clientMerge.compositeClient,
    compositeClient: state.clientMerge.compositeClient,
    firstPatient: state.clientMerge.firstPatient,
    secondPatient: state.clientMerge.secondPatient,
    requester: state.clientMerge.requester,
    createdAt: state.clientMerge.createdAt,
    note: state.clientMerge.note
  }
}

const mapDispatchToProps = {
  loadMergeIntoState
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowMergeView)
