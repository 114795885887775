import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAssociation, getRecord } from '@selectors'
import { onReferralResourceProgramAssignmentChange } from '../actions'

import Select from '@components/Select'
import DatePicker from '@components/DatePicker'
import ModalContentSeparator from '@components/Modal/ContentSeparator'
import Spacing from '@components/Spacing'
import ReferralEncounterActionSelect from '../ReferralEncounterActionSelect/ReferralEncounterActionSelect'

import './ReferralEncounterModal.scss'

class PageOneContent extends PureComponent {
  render () {
    const { onFormFieldChange, formSelections, programAssignments } = this.props

    return (
      <div className='ReferralEncounterModal__form ReferralEncounterModal__form--required'>
        <ModalContentSeparator />

        <Spacing tag='div' marginBottom={3}>
          <ReferralEncounterActionSelect
            dataTest='referral-encounter-modal__select-action'
            label={window.local.t('referrals.encounter_modal.actions.label')}
          />
        </Spacing>

        <Spacing tag='div' marginBottom={3}>
          <DatePicker
            selected={formSelections.dateOfAction}
            onChange={onFormFieldChange('dateOfAction')}
            required
            label={window.local.t('referrals.encounter_modal.date_of_action')}
            id='referral_encounter_date_of_action'
          />
        </Spacing>

        <Select
          dataTest='referral-encounter-modal__select-programs'
          required
          onChange={this.props.onReferralResourceProgramAssignmentChange}
          options={programAssignments}
          getOptionLabel={(option) => option.resourceProgramName}
          getOptionValue={(option) => option.id}
          label={window.local.t('referrals.encounter_modal.resource_programs.label')}
          placeholder={window.local.t('referrals.encounter_modal.resource_programs.placeholder')}
          isMulti
          value={formSelections.referralResourceProgramAssignments}
          showDropdownIndicator
          hideClearIndicator
        />
      </div>
    )
  }
}

PageOneContent.propTypes = {
  formSelections: PropTypes.shape({
    dateOfAction: PropTypes.instanceOf(Date),
    referralResourceProgramAssignments: PropTypes.array
  }),
  onFormFieldChange: PropTypes.func.isRequired,
  onReferralResourceProgramAssignmentChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  referralId: PropTypes.string.isRequired,
  programAssignments: PropTypes.array.isRequired
}

const mapDispatchToProps = {
  onReferralResourceProgramAssignmentChange
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const {
    dateOfAction: { value: dateOfAction },
    referralResourceProgramAssignments: { value: referralResourceProgramAssignments }
  } = state.referralView.form.referralEncounter

  return {
    formSelections: { dateOfAction, referralResourceProgramAssignments },
    programAssignments: getAssociation(
      state,
      referral,
      'referralResourceProgramAssignments'
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageOneContent)
