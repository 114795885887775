import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './DraggableTagContainer.css'

class DraggableTagContainer extends Component {
  render () {
    const { label, children } = this.props
    const modifierClass = children ? 'has-content' : 'empty'

    return (
      <div className={`DraggableTagContainer DraggableTagContainer--${modifierClass}`}>
        <label className='DraggableTagContainer__label'>
          {label}
        </label>

        <div className={`DraggableTagContainer__tags DraggableTagContainer__tags--${modifierClass}`}>
          {children}
        </div>
      </div>
    )
  }
}

DraggableTagContainer.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any
}

export default DraggableTagContainer
