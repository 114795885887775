import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'
import ContentSection from '@components/ContentSection'

import './CheckoutHeader.scss'

const CheckoutHeader = ({ resourcePath }) => {
  return (
    <ContentSection
      emphasis
      bottomMargin
      contentWidth='wide'
      title={window.local.t('checkout.title')}
    >
      {window.local.t('checkout.context')}
      <a
        className='orbital-smallcaps text-with-icon CheckoutHeader__link'
        data-test='checkout-header__add-resources'
        href={resourcePath}
      >
        {window.local.t('checkout.add_with_search')}
        <Icon size={12} iconKey='add_circle' className='mi--push-left' />
      </a>
    </ContentSection>
  )
}

CheckoutHeader.propTypes = {
  resourcePath: PropTypes.string.isRequired
}

export default CheckoutHeader
