import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'

class EmptyFieldHeaderWarning extends React.PureComponent {
  render () {
    const hasBlanks = this.props.emptyFields.length > 0
    if (!hasBlanks) return null

    return (
      <div className='ReviewView__header-warning'>
        <Icon theme='round' iconKey='warning' size={16} className='mi--orange mi--align-bottom' />
        <p className='ReviewView__header-warning__content'>
          {this.props.children}
        </p>
      </div>
    )
  }
}

EmptyFieldHeaderWarning.propTypes = {
  children: PropTypes.node.isRequired,
  emptyFields: PropTypes.array
}

export default EmptyFieldHeaderWarning
