import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import BuildProfileView from '@scenes/clients/ClientMerge/BuildProfileView'
import ReviewView from '@scenes/clients/ClientMerge/ReviewView'
import SelectionView from '@scenes/clients/ClientMerge/SelectionView'
import ShowMergeView from '@scenes/clients/ClientMerge/ShowMergeView'
import AuthorizedRoute from '@components/AuthorizedRoute'
import PageTitle from '@components/PageTitle'
import { isTeamAdmin } from '@utils/userUtil'

const Routes = ({ currentUser }) => {
  return (
    <Router basename='/en/client-merge'>
      <PageTitle title={window.local.t('client_merge.app_title')}>
        <Switch>
          <AuthorizedRoute
            exact
            condition={() => !isTeamAdmin(currentUser)}
            redirect={{ to: '/admin', local: true }}
            path='/'
            component={(props) => <SelectionView localeContext='default' {...props} />}
          />
          <Route exact path='/build-profile' component={(props) => <BuildProfileView localeContext='default' {...props} />} />
          <Route exact path='/review' component={(props) => <ReviewView localeContext='default' {...props} />} />

          <AuthorizedRoute
            condition={() => isTeamAdmin(currentUser)}
            redirect={{ to: '/', local: true }}
          >
            <Route exact path='/admin' component={(props) => <SelectionView localeContext='admin' {...props} />} />
            <Route exact path='/admin/build-profile' component={(props) => <BuildProfileView localeContext='admin' {...props} />} />
            <Route exact path='/admin/review' component={(props) => <ReviewView localeContext='admin' {...props} />} />

            <Route exact path='/:mergeID/build-profile' component={(props) => <BuildProfileView localeContext='review' {...props} />} />
            <Route path='/:mergeID' component={(props) => <ShowMergeView localeContext='review' {...props} />} />
          </AuthorizedRoute>

        </Switch>
      </PageTitle>
    </Router>
  )
}

Routes.propTypes = {
  currentUser: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    currentUser: state.global.currentUser.attributes
  }
}

export default connect(mapStateToProps)(Routes)
