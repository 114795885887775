import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DraggableTag from '@scenes/clients/ClientMerge/components/DraggableTag'
import DashedModule from '@scenes/clients/ClientMerge/components/DashedModule'
import AddressTag from '@scenes/clients/ClientMerge/components/AddressTag'
import IdentifierTag from '@scenes/clients/ClientMerge/components/IdentifierTag'
import PhoneTag from '@scenes/clients/ClientMerge/components/PhoneTag'
import {
  attributeExistsInComposite,
  iconDataFor,
  identifierIsPresentInComposite,
  identifierOfTypeExistsInComposite,
  labelFor,
  phoneIsPresentInComposite,
  selectedMessage,
  valueIsUsedInComposite
} from '@scenes/clients/ClientMerge/utils'
import { addressExistsInComposite } from '@scenes/clients/ClientMerge/reducers/composite-client-utilities'
import { displayValueFor } from '@models/Client'
import './SourcePatient.css'

const renderableAttributes = [
  'firstName',
  'middleName',
  'lastName',
  'dateOfBirth',
  'gender',
  'email'
]

class SourcePatient extends Component {
  constructor (props) {
    super(props)

    this.renderAttributeTag = this.renderAttributeTag.bind(this)
  }

  attributeWasInferred (attributeName, compositeClient) {
    return compositeClient[attributeName] && compositeClient[attributeName].inferred
  }

  skipRenderingAttributeTag (attributeName, attributeValue) {
    const { compositeClient } = this.props

    return (
      attributeValue === null ||
        attributeValue === undefined ||
        this.attributeWasInferred(attributeName, compositeClient) ||
        valueIsUsedInComposite(attributeName, attributeValue, compositeClient)
    )
  }

  skipRenderingIdentifierTag (identifier) {
    return identifierIsPresentInComposite(identifier, this.props.compositeClient)
  }

  skipRenderingPhoneTag (phone) {
    return phoneIsPresentInComposite(phone, this.props.compositeClient)
  }

  renderAttributeTag ({ key, value }, index = 0) {
    if (renderableAttributes.includes(key)) {
      if (this.skipRenderingAttributeTag(key, value)) { return }

      const icon = iconDataFor(key)
      const label = labelFor(window.local, key)
      const invalidMessage = selectedMessage(window.local, key)
      const clientId = this.props.profile.id
      const displayValue = displayValueFor(key, value)

      return (
        <DraggableTag
          attributeName={key}
          clientId={clientId}
          handleTagSelection={this.props.handleTagSelection}
          icon={icon}
          key={index}
          label={label}
          value={displayValue}
          invalid={attributeExistsInComposite(key, this.props.compositeClient)}
          invalidMessage={invalidMessage}
        />
      )
    }
  }

  renderAttributeTags () {
    let attributes = []
    let profile = Object.entries(this.props.profile)

    profile.forEach(attr => attributes.push({ key: attr[0], value: attr[1] }))

    return attributes.map(this.renderAttributeTag)
  }

  renderAddressTag () {
    const { compositeClient, profile } = this.props

    if (!profile.address) return
    if (this.attributeWasInferred('address', compositeClient)) return
    if (addressExistsInComposite(profile.address, compositeClient)) return

    return (
      <AddressTag
        address={this.props.profile.address}
        attributeName='address'
        clientId={this.props.profile.id}
        invalid={attributeExistsInComposite('address', compositeClient)}
        removable={false}
        selectionAction={this.props.addAddressAction}
        invalidMessage={selectedMessage(window.local, 'address')}
      />
    )
  }

  renderIdentifierTags () {
    const { identifiers } = this.props.profile
    if (!identifiers) return

    return identifiers
      .filter(identifier => !this.skipRenderingIdentifierTag(identifier))
      .map((identifier, index) => (
        <IdentifierTag
          attributeName='identifier'
          clientId={this.props.profile.id}
          invalid={identifierOfTypeExistsInComposite(identifier, this.props.compositeClient)}
          invalidMessage={selectedMessage(window.local, 'identifiers')}
          handleClick={this.props.addIdentifierAction}
          identifier={identifier}
          key={index}
          removable={false}
        />
      ))
  }

  renderPhoneTags () {
    const { phones } = this.props.profile
    if (!phones) return

    return phones
      .filter(phone => !this.skipRenderingPhoneTag(phone))
      .map((phone, index) => (
        <PhoneTag
          attributeName='phone'
          clientId={this.props.profile.id}
          invalid={this.props.compositeClient['phones'].length === 3}
          invalidMessage={selectedMessage(window.local, 'phone')}
          handleClick={this.props.addPhoneToCompositeClient}
          key={index}
          phone={phone}
          removable={false}
        />
      ))
  }

  render () {
    const { firstName, lastName } = this.props.profile

    return (
      <DashedModule>
        <h6 className='hfysg-h6 SourcePatient__header'>
          {`${firstName} ${lastName}`}
        </h6>

        {this.renderAttributeTags()}

        {this.renderAddressTag()}

        {this.renderIdentifierTags()}

        {this.renderPhoneTags()}
      </DashedModule>
    )
  }
}

SourcePatient.propTypes = {
  compositeClient: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  addAddressAction: PropTypes.func,
  addIdentifierAction: PropTypes.func,
  addPhoneToCompositeClient: PropTypes.func,
  handleTagSelection: PropTypes.func
}

export default SourcePatient
