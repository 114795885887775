import axios from 'axios'
import apiConfig from '@config/apiConfig'
import referralService from '@services/referralService'
import { isOutOfNetworkClr } from '@utils/networkTiersUtil'

const legacyBaseUrl = '/en/cart_items'
const baseUrl = '/api/internal/cart_items'

export const removeItem = (cartItemId, analytics) => {
  const params = {
    'analytics_referrer_type': analytics.referrerType,
    'analytics_referrer_id': analytics.referrerId
  }

  return axios(apiConfig.destroy(`${legacyBaseUrl}/${cartItemId}`, params))
}

export const addItem = (resourceSiteId, metadata) => {
  const data = {
    ...metadata,
    cart_item: {
      resource_site_id: resourceSiteId
    }
  }

  return axios(apiConfig.post(baseUrl, data))
    .then(response => response.data)
    .catch(error => { console.error('addItem', error) })
}

const addReferralData = (formData, referral) => {
  formData.append('referral[resource_site_id]', referral.item.resource_site_id)
  formData.append('referral[cart_id]', referral.item.cart_id)
  if (referral.matchingPrograms) {
    referral.matchingPrograms.forEach((program) => {
      formData.append('referral[resource_program_ids][]', program.id)
    })
  }
  formData.append('referral[notes_attributes][0][content]', referral.note)
  if (referral.file) {
    formData.append('referral[attachments_attributes][0][asset]', referral.file)
  }
}

const isCartItemCloosedLoop = (cartItem) => (
  isOutOfNetworkClr(cartItem.tier) && cartItem.consentRequired && cartItem.consentGranted
)

const constructBaseForm = (analytics, patient, cartItem, currentUser) => {
  const formData = new global.FormData()

  formData.append('analytics_referrer_id', 'not instrumented')
  formData.append('analytics_referrer_type', 'Checkout Completed')
  formData.append('utf', '✓')
  formData.append('referral[patient_id]', patient.id)
  formData.append('referral[segment_funnel_id]', analytics.segmentFunnelId)
  formData.append('referral[end_user_attestation]', cartItem.consentGranted ? 'on' : 'off')
  formData.append('referral[notes_attributes][0][user_id]', currentUser.id)
  formData.append('referral[attachments_attributes][0][user_id]', currentUser.id)
  formData.append('referral[closed_loop]', isCartItemCloosedLoop(cartItem) ? 'on' : 'off')

  return formData
}

export const createReferrals = (referralCartItems, patient, analytics, currentUser) => {
  return referralCartItems.map((cartItem) => {
    const formData = constructBaseForm(analytics, patient, cartItem, currentUser)
    formData.append('cart_item_id', cartItem.id)
    addReferralData(formData, cartItem)

    return referralService.create(formData).then((response) => {
      return { cartItemId: cartItem.id, response }
    }, (errors) => {
      const errorText = errors.response.data.errors
        ? errors.response.data.errors[0]
        : window.local.t('checkout.error.create_server_error')

      return { cartItemId: cartItem.id, errorText }
    })
  })
}
