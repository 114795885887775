import React from 'react'
import PropTypes from 'prop-types'

import DlList from '@components/DlList'
import PageExpander from '@components/PageExpander'

const SurveyExpander = ({ count, survey }) => {
  return (
    <PageExpander
      triggerShowText={`Show ${count} Answers`}
      triggerHideText={`Hide ${count} Answers`}
      children={<DlList listItems={survey.explicit_responses} />}
    />
  )
}

SurveyExpander.propTypes = {
  count: PropTypes.number.isRequired,
  survey: PropTypes.object.isRequired
}

export default SurveyExpander
