import axios from 'axios'
import apiConfig from '@config/apiConfig'

const baseEndpoint = '/api/internal/attachments/'

const create = (file, attachableType, attachableId, analytics) => {
  const formData = new window.FormData()
  formData.append('attachment[asset]', file)
  formData.append(attachableType, attachableId)
  formData.append('analytics_referrer_type', analytics.analytics_referrer_type)
  formData.append('analytics_referrer_id', analytics.analytics_referrer_id)

  return axios(
    apiConfig.post(
      baseEndpoint,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  )
}

const destroy = (id) => {
  return axios(apiConfig.destroy(baseEndpoint + id))
}

export default {
  create,
  destroy
}
