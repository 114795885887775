import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import LoadingSpinner from '@components/LoadingSpinner'
import BootstrapApp from '@components/BootstrapApp'
import theme from '@config/theme'
import { createStoreFunction } from '@root/store'

export const componentWithAppWrapper = (component, props, useBootstrap = true) => () => {
  const Component = component

  let innerComponent = ''

  if (useBootstrap) {
    innerComponent = <BootstrapApp loader={<LoadingSpinner />}>
      <Component {...props} />
    </BootstrapApp>
  } else {
    innerComponent = <Component {...props} />
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={createStoreFunction()}>
        {innerComponent}
      </Provider>
    </MuiThemeProvider>
  )
}
