import React, { Fragment, PureComponent } from 'react'
import EmphasisBackground from '@components/EmphasisBackground'
import Story from '../Story'

class EmphasisBackgrounds extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Emphasis Level High'>
          <EmphasisBackground emphasisLevel='high'>
            emphasisLevel='high'
          </EmphasisBackground>
        </Story>
        <Story title='Emphasis Level Medium'>
          <EmphasisBackground emphasisLevel='medium'>
            emphasisLevel='medium'
          </EmphasisBackground>
        </Story>
      </Fragment>
    )
  }
}

EmphasisBackgrounds.displayName = 'EmphasisBackgrounds'

export default EmphasisBackgrounds
