import isPlainObject from 'lodash/isPlainObject'
import snakeCase from 'lodash/snakeCase'
const queryString = require('query-string-for-all')

export const canRenderIconVariants = () => {
  const ua = window.navigator.userAgent
  var isIE = /MSIE|Trident/.test(ua)

  return isIE
}

export const getQueryParams = (urlOrSearch = window.location.href) => {
  const search = urlOrSearch.split('?')[1]

  const parsed = queryString.parse(search, { arrayFormat: 'bracket' })
  return parsed
}

// formData should be an instance of FormData
export const appendParamsToFormData = (formData, params) => {
  if (!(formData instanceof FormData)) throw new Error(`formData must be an instance of FormData`)

  return Object.keys(params).forEach((key) => {
    const value = params[key]
    formData.append(key, value)
  })
}

export const stringifyQueryParams = (obj) => {
  return queryString.stringify(obj, { arrayFormat: 'bracket' })
}

// This is an oversimplified method for serializing an object into
// a query string, without URI encoding the keys or values.
// It should never be used to submit user input to the server.
export const stringifyDecodedQueryParams = (obj) => {
  return [...Object.entries(obj)].map(([key, value]) => {
    const parsedValue = Array.isArray(value) ? value.join(',') : value

    return `${key}=${encodeURI(parsedValue)}`
  }).join('&')
}

export const getAbsolutePath = (location, relativePath) => {
  let currentPath = location.pathname

  if (currentPath[currentPath.length - 1] === '/') {
    currentPath = currentPath.slice(0, -1)
  }

  return `${currentPath}/${relativePath}`
}

export const transformNestedObjectForRails = (obj, convertKeys = false) => {
  const convertedObj = {}
  Object.keys(obj).forEach(key => {
    const _key = convertKeys ? snakeCase(key) : key
    const value = obj[key]
    if (value && isPlainObject(value)) {
      Object.keys(value).forEach(valueKey => {
        const _valueKey = convertKeys ? snakeCase(valueKey) : valueKey
        convertedObj[`${_key}[${_valueKey}]`] = value[valueKey]
      })
    } else {
      convertedObj[_key] = value
    }
  })

  return convertedObj
}

export const getUrlFromPattern = (pattern, resource) => {
  const matcher = /\/:(\w*)/g
  const replacer = (match, key) => {
    return `/${resource[key]}`
  }

  return pattern.replace(matcher, replacer)
}
