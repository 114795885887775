import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@components/Button'
import Icon from '@components/Icon'
import LanguageSelect from '@components/Forms/Elements/LanguageSelect'
import Modal from '@components/Modal'
import Spacing from '@components/Spacing'
import SuccessModal from '@components/SuccessModal'
import Text from '@components/Text'
import { closeDownloadModal } from '@scenes/resourceSites/actions'
import { getRecord } from '@selectors'

const millisecondsToKeepSuccessModalOpen = 5000

const DownloadModal = (props) => {
  const { analyticsReferrer, resourceSite, closeDownloadModal, path } = props
  const [selectedLanguage, setLanguage] = useState()
  const [downloading, setDownloading] = useState(false)

  const buildDownloadPath = () => {
    const pdfLink = path || resourceSite.links.pdf.pdfLink
    const analyticsReferrerID = analyticsReferrer.analytics_referrer_id
    const queryParams = new URLSearchParams({
      ...props.queryParams,
      'current_language': selectedLanguage,
      'referrer_id': analyticsReferrerID
    })

    return `${pdfLink}/?${queryParams.toString()}`
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setDownloading(true)
    window.location.assign(buildDownloadPath())
    setTimeout(
      () => { setDownloading(false); closeDownloadModal() },
      millisecondsToKeepSuccessModalOpen
    )
  }

  if (downloading) return <DownloadPendingModal />

  return (
    <Modal
      dataTest='ResourceSiteDownloadModal'
      headerText={window.local.t('resource_sites.download_modal.prompt.title')}
      actions={<DownloadSubmitButton />}
      content={
        <div>
          <Spacing marginBottom={2}>
            <DownloadModalSubHead resourceSite={resourceSite} />
          </Spacing>

          <LanguageSelect
            label={window.local.t('resource_sites.download_modal.prompt.language_selector.label')}
            maxMenuHeight={120}
            name='language'
            onChange={(value) => { value && setLanguage(value.label) }}
          />
        </div>
      }
      onClose={() => { closeDownloadModal() }}
      onSubmit={handleSubmit}
      open
    />
  )
}

const DownloadPendingModal = () => <SuccessModal
  title={window.local.t('resource_sites.download_modal.success.title')}
  text={window.local.t('resource_sites.download_modal.success.context')}
  open
/>

DownloadModal.propTypes = {
  closeDownloadModal: PropTypes.func.isRequired,
  resourceSite: PropTypes.object.isRequired,
  analyticsReferrer: PropTypes.object,
  path: PropTypes.string,
  queryParams: PropTypes.object
}

const DownloadModalSubHead = ({ resourceSite: { serviceName } }) => (
  <Spacing marginTop={2}>
    <Text>{serviceName}</Text>
  </Spacing>
)

DownloadModalSubHead.propTypes = {
  resourceSite: PropTypes.object.isRequired
}

const DownloadSubmitButton = () => (
  <Spacing marginTop={12}>
    <Button type='primary' htmlType='submit' data-testid='download-submit'>
      <Icon iconKey='picture_as_pdf' size={12} />
      <span>&nbsp;{window.local.t('resource_sites.download_modal.prompt.call_to_action.label')}</span>
    </Button>
  </Spacing>
)

// TODO: 🔥Eliminate the `ownProps` usage when LegacyResourceSiteDownloadButton is removed
const mapStateToProps = (state, ownProps) => {
  const { resourceSites: { downloadModal: { resourceSiteId } } } = state
  const { resourceSites: { downloadModal } } = state

  return {
    analyticsReferrer: ownProps.analyticsReferrer || state.global.analyticsReferrer,
    resourceSite: ownProps.resourceSite || getRecord(state, 'resource_site_search_result', resourceSiteId),
    path: downloadModal.path,
    queryParams: downloadModal.queryParams
  }
}

const mapDispatchToProps = {
  closeDownloadModal
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadModal)
