import axios from 'axios'

import Client from '@models/Client'
import ClientAdapter from './../adapters/clientAdapter'
import config from '@config/app'
import { CLIENT_DETAIL_ENDPOINT } from '@constants/apiEndpoints'
import { getApiResource } from '@utils/apiUtil'

const internalPatientAPI = CLIENT_DETAIL_ENDPOINT + '/'

const type = 'patient'

export const search = (query) => {
  return axios.get(`${config.baseUrl}/options/clients?query=${query}`).then((response) => {
    return response
  }).catch((err) => {
    console.log(err)
  })
}

export const resource = getApiResource(CLIENT_DETAIL_ENDPOINT)

// TODO: Remove this at some point. We should not use adapters at the service-level
export const legacyFind = (id, options = {}) => {
  const included = options.included || []

  return axios.get(internalPatientAPI + id, { params: { included } })
    .then(response => Client(new ClientAdapter(response).object))
    .catch(err => { console.log(err) })
}

export default {
  search,
  type,
  ...resource
}
