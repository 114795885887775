import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DraggableTag from '@scenes/clients/ClientMerge/components/DraggableTag'

class IdentifierTag extends Component {
  constructor (props) {
    super(props)

    this.handleSelection = this.handleSelection.bind(this)
    this.handleRemoval = this.handleRemoval.bind(this)
  }

  handleSelection () {
    this.props.handleClick({
      identifier: this.props.identifier,
      sourceClientId: this.props.clientId
    })
  }

  handleRemoval () {
    this.props.removalAction({ identifier: this.props.identifier })
  }

  render () {
    const { identifier, handleClick, ...passThruProps } = this.props
    const { displayValue } = identifier

    return (
      <DraggableTag
        {...passThruProps}
        handleRemoval={this.handleRemoval}
        handleTagSelection={this.handleSelection}
        invalid={this.props.invalid}
        invalidMessage={this.props.invalidMessage}
        icon='assignment_ind'
      >
        {displayValue}
      </DraggableTag>
    )
  }
}

IdentifierTag.propTypes = {
  clientId: PropTypes.number.isRequired,
  identifier: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  invalidMessage: PropTypes.string,
  handleClick: PropTypes.func,
  removalAction: PropTypes.func
}

export default IdentifierTag
