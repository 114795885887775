import React from 'react'
import PropTypes from 'prop-types'
import './Column.css'

class Column extends React.PureComponent {
  constructor (props) {
    super(props)

    this.columnSpan = this.columnSpan.bind(this)
    this.columnGutter = this.columnGutter.bind(this)
  }

  columnGutter () {
    if (this.props.gutter) return `Column--gutter-${this.props.gutter}`
    return ''
  }

  columnSpan () {
    if (this.props.span) {
      const percent = (this.props.span / 12) * 100
      return `${percent}%`
    }
  }

  render () {
    return (
      <div className={`Column ${this.columnGutter()}`} style={{ width: this.columnSpan() }}>
        {this.props.children}
      </div>
    )
  }
}

Column.propTypes = {
  gutter: PropTypes.string,
  span: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Column
