import React from 'react'
import PropTypes from 'prop-types'

import TextInput from './TextInput'

const ReactFinalFormTextInput = ({ input, meta, ...rest }) => {
  const { error, submitError, touched } = meta
  const fieldError = (touched && error) || submitError
  const showErrorMessages = typeof fieldError !== 'undefined'

  return (
    <>
      <TextInput
        {...rest}
        value={input.value}
        onChange={(value) => { input.onChange(value) }}
        errors={fieldError ? [fieldError] : undefined}
        showErrorMessages={showErrorMessages}
        dataTestid={`${input.name}-input`}
      />

    </>
  )
}

ReactFinalFormTextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default ReactFinalFormTextInput
