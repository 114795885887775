import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ButtonBase from '@material-ui/core/ButtonBase'
import Zoom from '@material-ui/core/Zoom'

import ElementWithTooltip from '@components/ElementWithTooltip'
import Icon from '@components/Icon'
import {
  optionalBoolNotCombinedWithInvalid,
  optionalBoolNotCombinedWithRemovable
} from './custom-validations'
import './DraggableTag.css'

class DraggableTag extends Component {
  constructor (props) {
    super(props)

    this.handleRemoval = this.handleRemoval.bind(this)
    this.handleTagClick = this.handleTagClick.bind(this)
  }

  shouldRenderPopover () {
    return this.props.invalid && this.props.invalidMessage
  }

  handleRemoval () {
    const attributeName = this.props
    this.props.handleRemoval(attributeName)
  }

  modifierClassName () {
    const { removable, invalid } = this.props

    if (removable) {
      return 'DraggableTag--removable'
    } else if (invalid) {
      return 'DraggableTag--invalid'
    } else {
      return ''
    }
  }

  handleTagClick (event) {
    setTimeout(() => {
      if (this.props.invalid) {
        event.preventDefault()
      } else if (this.props.removable) {
        this.handleRemoval()
      } else if (this.props.handleTagSelection) {
        const { attributeName, clientId } = this.props
        this.props.handleTagSelection({ sourceClientId: clientId, attributeName })
      }
    }, 250)
  }

  renderText () {
    const { label, value } = this.props

    if (label) {
      return `${label}: ${value}`
    } else {
      return value
    }
  }

  renderRemoveIcon () {
    return (
      <Icon
        className='mi--blue'
        iconKey='close'
        size={16}
        onClick={this.handleRemoval}
      />
    )
  }

  renderWithTooltip () {
    return (
      <ElementWithTooltip
        tooltipContent={this.props.invalidMessage}
      >
        {this.renderButton()}
      </ElementWithTooltip>
    )
  }

  renderButton () {
    const tagChildren = this.props.children
    return (
      <ButtonBase
        onClick={this.handleTagClick}
        classes={{ root: `DraggableTag ${this.modifierClassName()}` }}
      >
        <Icon iconKey={this.props.icon} size={16} />

        <span className='DraggableTag__text'>
          {tagChildren || this.renderText()}
        </span>

        {this.props.removable && this.renderRemoveIcon()}
      </ButtonBase>
    )
  }

  render () {
    const { invalid, invalidMessage } = this.props
    if (invalid && invalidMessage) {
      return (
        <div className='DraggableTag__container'>
          {this.renderWithTooltip()}
        </div>
      )
    } else {
      return (
        <div className='DraggableTag__container'>
          <Zoom in>
            {this.renderButton()}
          </Zoom>
        </div>
      )
    }
  }
}

DraggableTag.propTypes = {
  attributeName: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  invalid: optionalBoolNotCombinedWithRemovable,
  removable: optionalBoolNotCombinedWithInvalid,
  children: PropTypes.any,
  clientId: PropTypes.number,
  handleRemoval: PropTypes.func,
  handleTagSelection: PropTypes.func,
  invalidMessage: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string
}

export default DraggableTag
