import {
  PRIVATE,
  NOT_PRIVATE
} from '@constants/privacyStatus'

const isPrivate = (status) => status === PRIVATE
const isNotPrivate = (status) => status === NOT_PRIVATE || !status

export {
  isPrivate,
  isNotPrivate
}
