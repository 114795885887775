import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import reject from 'lodash/reject'
import compact from 'lodash/compact'

import {
  IN_NETWORK_FILTER_PARAM,
  PREFERRED_PARTNERS_FILTER_PARAM,
  PREFERRED_PROVIDERS_FILTER_PARAM,
  VERIFIED_FILTER_PARAM
} from '@scenes/resourceSites/constants'
import AutoCompleteFilter from './components/AutoCompleteFilter'
import GuidedSearchFilter from './components/GuidedSearchFilter'
import TierFilter from './components/TierFilter'
import ToggleFilter from './components/ToggleFilter'
import networkFilterOptions from '@scenes/resourceSites/config/searchFilterOptions'

class SearchFilters extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      inNetwork: props.coordinate_partners_only === 'on',
      inNetworkPreferred: props.preferred_partners_only === 'on',
      preferredProviders: props.preferred_providers_only === 'on',
      verified: props.verified_only === 'on',
      accessibility: props.accessibility === 'on',
      selectedEligibilityIds: props.selected_eligibility_ids || []
    }

    this.pristineState = this.state

    this.enablePartnerType = this.enablePartnerType.bind(this)
    this.selectEligibility = this.selectEligibility.bind(this)
    this.removeSelectedEligibility = this.removeSelectedEligibility.bind(this)
    this.resetPartnerTypes = this.resetPartnerTypes.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
  }

  onFilterChange (filterName) {
    this.setState({ [filterName]: !this.state[filterName] }, this.submitSearch)
  }

  submitSearch () {
    this.props.onFilterChange(this.state)
    this.pristineState = this.state
  }

  cancelFilter (filterName) {
    if (!this.isPristine(filterName)) {
      this.setState({
        [filterName]: this.pristineState[filterName]
      })
    }
  }

  selectEligibility (eligibility) {
    this.setState({
      selectedEligibilityIds: [...this.state.selectedEligibilityIds, eligibility.id]
    })
  }

  selectedEligibilities () {
    return compact(this.state.selectedEligibilityIds.map(id => {
      return this.props.eligibilities.find(eligibility => +eligibility.id === +id)
    }))
  }

  removeSelectedEligibility (eligibility) {
    const selectedEligibilityIds = reject(this.state.selectedEligibilityIds, (selectedEligibilityId) => {
      return +eligibility.id === +selectedEligibilityId
    })

    this.setState({ selectedEligibilityIds })
  }

  resetPartnerTypes () {
    this.setState({
      inNetwork: this.pristineState.inNetwork,
      inNetworkPreferred: this.pristineState.inNetworkPreferred,
      verified: this.pristineState.verified
    })
  }

  enablePartnerType (type, callback) {
    switch (type) {
      case PREFERRED_PARTNERS_FILTER_PARAM:
        this.setState({
          inNetwork: false,
          inNetworkPreferred: true,
          verified: false
        })
        break
      case IN_NETWORK_FILTER_PARAM:
        this.setState({
          inNetwork: true,
          inNetworkPreferred: false,
          verified: false
        })
        break
      case VERIFIED_FILTER_PARAM:
        this.setState({
          inNetwork: false,
          inNetworkPreferred: false,
          verified: true
        })
        break
      default:
        this.setState({
          inNetwork: false,
          inNetworkPreferred: false,
          verified: false
        }, callback)
    }
  }

  isPristine (filterName) {
    return isEqual(
      this.state[filterName],
      this.pristineState[filterName]
    )
  }

  get isCoordinatePartnersPristine () {
    return isEqual(
      this.state.inNetwork,
      this.pristineState.inNetwork
    )
  }

  get isPreferredPartnersPristine () {
    return isEqual(
      this.state.inNetworkPreferred,
      this.pristineState.inNetworkPreferred
    )
  }

  get isVerifiedPristine () {
    return isEqual(
      this.state.verified,
      this.pristineState.verified
    )
  }

  get tiersArePristine () {
    return this.isCoordinatePartnersPristine &&
      this.isPreferredPartnersPristine &&
      this.isVerifiedPristine
  }

  filtersCount (filterName) {
    if (this.state[filterName].length === undefined) {
      return this.state[filterName] ? 1 : 0
    } else {
      return this.state[filterName].length
    }
  }

  get partnersFilter () {
    if (this.props.team_uses_coordinate) {
      return 'inNetwork'
    } else {
      return 'preferredProviders'
    }
  }

  selectedTier () {
    if (this.state.inNetworkPreferred) {
      return PREFERRED_PARTNERS_FILTER_PARAM
    } else if (this.state.inNetwork) {
      return IN_NETWORK_FILTER_PARAM
    } else if (this.state.verified) {
      return VERIFIED_FILTER_PARAM
    }
  }

  get partnersToggleProps () {
    const toggleProps = {
      isToggled: this.state[this.partnersFilter],
      onChange: () => this.onFilterChange(this.partnersFilter)
    }

    if (this.props.team_uses_coordinate) {
      return {
        ...toggleProps,
        title: window.local.t('search_filters.legacy_toggle.in_network'),
        inputName: IN_NETWORK_FILTER_PARAM,
        coordinate: true
      }
    } else {
      return {
        ...toggleProps,
        title: window.local.t('search_filters.tier_filters.preferred_providers.title'),
        inputName: PREFERRED_PROVIDERS_FILTER_PARAM,
        coordinate: false
      }
    }
  }

  get isEligibilitiesFilterApplied () {
    return this.state.selectedEligibilityIds.length > 0
  }

  get hideTierFilters () {
    return !this.props.isAuthenticated
  }

  get shouldRenderNewTierFilters () {
    return this.props.contractedNetwork && this.props.team_uses_coordinate
  }

  renderLegacyProviderTypeFilter () {
    if (this.hideTierFilters) return
    if (this.shouldRenderNewTierFilters) return

    return <ToggleFilter {...this.partnersToggleProps} />
  }

  renderTierFilters () {
    if (!this.shouldRenderNewTierFilters) return

    const filterCount = this.filtersCount('inNetworkPreferred') ||
      this.filtersCount('inNetwork') ||
      this.filtersCount('verified')

    return (
      <GuidedSearchFilter
        clearable
        filtersCount={filterCount}
        name={window.local.t('search_filters.tier_filters.dropdown.title')}
        onCancel={this.resetPartnerTypes}
        onClear={() => { this.enablePartnerType(null, this.submitGuidedSearchForm) }}
        onSubmit={this.submitSearch}
        pristine={this.tiersArePristine}
        singleOption
      >
        <TierFilter
          instructions={window.local.t('search_filters.tier_filters.dropdown.context')}
          onClose={() => {}}
          onSelect={this.enablePartnerType}
          options={networkFilterOptions(window, { teamUsesCoordinate: this.props.team_uses_coordinate, contractedNetwork: this.props.contractedNetwork })}
          selectedOption={this.selectedTier()}
        />
      </GuidedSearchFilter>
    )
  }

  render () {
    return (
      <div className={classNames('guided-search__filters', this.props.className)}>
        <div className='guided-search__filters__heading'>
          Filter resources by:
        </div>
        {this.renderLegacyProviderTypeFilter()}
        <ToggleFilter
          title={window.local.t('resource_sites.ada_compliant.title')}
          isToggled={this.state.accessibility}
          inputName='ada_compliant_only'
          onChange={() => this.onFilterChange('accessibility')}
        />
        <GuidedSearchFilter
          applied={this.isEligibilitiesFilterApplied}
          filtersCount={this.filtersCount('selectedEligibilityIds')}
          name={window.local.t('resource_sites.eligibilities.title')}
          onCancel={() => this.cancelFilter('selectedEligibilityIds')}
          onSubmit={this.submitSearch}
          pristine={this.isPristine('selectedEligibilityIds')}
        >
          <AutoCompleteFilter
            description={window.local.t('resource_sites.eligibilities.description')}
            placeholder={window.local.t('resource_sites.eligibilities.placeholder')}
            inputName='resource_site[eligibility_ids][]'
            options={this.props.eligibilities}
            selectedOptions={this.selectedEligibilities()}
            onOptionSelect={this.selectEligibility}
            onOptionRemoval={this.removeSelectedEligibility}
          />
        </GuidedSearchFilter>
        {this.renderTierFilters()}
      </div>
    )
  }
}

SearchFilters.propTypes = {
  onFilterChange: PropTypes.func,
  accessibility: PropTypes.string,
  className: PropTypes.string,
  contractedNetwork: PropTypes.bool,
  coordinate_partners_only: PropTypes.string,
  eligibilities: PropTypes.array,
  preferred_partners_only: PropTypes.string,
  preferred_providers_only: PropTypes.string,
  verified_only: PropTypes.string,
  selected_eligibility_ids: PropTypes.array,
  team_uses_coordinate: PropTypes.bool,
  isAuthenticated: PropTypes.bool
}

SearchFilters.defaultProps = {
  contractedNetwork: false,
  eligibilities: [],
  onFilterChange: () => {},
  team_uses_coordinate: false
}

export default SearchFilters
