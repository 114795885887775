import React from 'react'
import PropTypes from 'prop-types'
import last from 'lodash/last'

import AutoCompleter from '../AutoCompleter'
import GuidedSearchEnhancedInput from '../GuidedSearchEnhancedInput'
import GuidedSearchEnhancedInputSelection from '../GuidedSearchEnhancedInputSelection'

const BACKSPACE_KEY_CODE = 8

class AutoCompleteFilter extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      inputValue: ''
    }

    this.enhancedInput = React.createRef()

    this.onInputChange = this.onInputChange.bind(this)
    this.onInputKeyDown = this.onInputKeyDown.bind(this)
    this.onOptionSelect = this.onOptionSelect.bind(this)
  }

  onInputChange (value) {
    this.setState({
      inputValue: value
    })
  }

  onInputKeyDown (e) {
    const input = this.enhancedInput.current

    if (e.keyCode === BACKSPACE_KEY_CODE && !input.value) {
      this.props.onOptionRemoval(last(this.props.selectedOptions))
    }
  }

  onOptionSelect (option) {
    this.props.onOptionSelect(option)
    this.setState({ inputValue: '' })
  }

  get maxSuggestions () {
    if (this.state.inputValue.length > 0) {
      return undefined
    } else {
      return this.props.options.length
    }
  }

  render () {
    const selectedEligibilities = this.props.selectedOptions.map((selectedOption, id) => {
      return (
        <GuidedSearchEnhancedInputSelection key={id}
          inputName='resource_site[eligibility_ids][]'
          selection={selectedOption}
          onDelete={this.props.onOptionRemoval}
        />
      )
    })

    return (
      <div className='auto-complete-filter'>
        <div className='auto-complete-filter__header'>
          <p className='auto-complete-filter__description'>{ this.props.description }</p>
          <div className='guided-search-field'>
            <GuidedSearchEnhancedInput
              data-test='guided-search-input'
              onChange={this.onInputChange}
              onKeyDown={this.onInputKeyDown}
              ref={this.enhancedInput}
              placeholder={this.props.placeholder}
              value={this.state.inputValue}
            >
              { selectedEligibilities }
            </GuidedSearchEnhancedInput>
          </div>
        </div>

        <AutoCompleter
          term={this.state.inputValue}
          options={this.props.options}
          onOptionSelect={this.onOptionSelect}
          selectedOptions={this.props.selectedOptions}
          maxSuggestions={this.maxSuggestions}
        />
      </div>
    )
  }
}

AutoCompleteFilter.propTypes = {
  onOptionSelect: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onOptionRemoval: PropTypes.func.isRequired,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array
}

AutoCompleteFilter.displayName = 'AutoCompleteFilter'

export default AutoCompleteFilter
