import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import useOutsideClick from '@root/hooks/useOutsideClick'
import resourceSitesActionPolicy from '@policies/resourceSitesActionPolicy'
import {
  openDownloadModal,
  openEditModal,
  openShareModal
} from '@scenes/resourceSites/actions'

import Button from '@components/Button'
import DownloadButton from '@scenes/resourceSites/components/DownloadButton'
import FavoriteToggle from '@scenes/resourceSites/components/FavoriteToggle'
import Icon from '@components/Icon'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import '@components/Button/Button.scss'
import './ResourceSiteActionMenu.scss'
import { selectSearchMetadataFormParams } from '../ResourceSite/selectors'

const localeFor = (i18nPath, options = {}) => window.local.t(
  `resource_sites.action_menu.${i18nPath}`,
  options
)

const ResourceSiteActionMenu = (props) => {
  const siteId = props.resourceSite.id
  const [isExpanded, setExpanded] = useState(false)
  const actionMenuRef = useRef()
  const { currentUser } = props

  useOutsideClick(actionMenuRef, () => setExpanded(false), [])

  const toggleDropdownMenu = () => {
    setExpanded(!isExpanded)
  }

  const iconKey = `keyboard_arrow_${isExpanded ? 'up' : 'down'}`
  const moreActionsButton = (
    <li className='ResourceSiteActionMenu__more'>
      <Button
        type='toolbar'
        aria-label={localeFor('more.aria_label')}
        onClick={toggleDropdownMenu}
      >
        <Text style='buttons' withIcon>
          {localeFor('more.label')}
          <Icon iconKey={iconKey} color='black' size={12} />
        </Text>
      </Button>
    </li>
  )

  let editButton
  const editButtonContents = <Text tag='span' style='sub-header-extra-small' withIcon>
    {localeFor('edit')}
    <Icon iconKey='edit' size={12} />
  </Text>

  if (props.companyListings.includes(siteId)) {
    editButton = <a
      href={props.resourceSite.links.edit}
      className='Button Button--toolbar'
      data-testid='ResourceSiteActionMenu__dropdown__edit'
    >
      {editButtonContents}
    </a>
  } else {
    editButton = <Button type='toolbar' onClick={() => { props.openEditModal(siteId) }}>
      {editButtonContents}
    </Button>
  }

  const dropdownMenu = () => {
    const {
      openDownloadModal,
      openShareModal,
      downloadPath,
      searchMetadataParams
    } = props

    return (
      <ul className='ResourceSiteActionMenu__dropdown'>
        <li>
          <DownloadButton
            openDownloadModal={openDownloadModal}
            path={`${downloadPath}`}
            queryParams={searchMetadataParams}
            resourceSiteId={String(siteId)}
          />
        </li>

        {resourceSitesActionPolicy.canShare(currentUser) && <li>
          <Button type='toolbar' onClick={() => { openShareModal(siteId) }}>
            <Text tag='span' style='buttons' withIcon>
              {localeFor('share')}
              <Icon iconKey='share' size={12} />
            </Text>
          </Button>
        </li>}

        {resourceSitesActionPolicy.canEdit(currentUser) && <li>{editButton}</li>}
      </ul>
    )
  }

  const renderFavoriteToggle = () => {
    const {
      analyticsReferrer,
      resourceSite: { currentUserFavoriteId, id: resourceSiteId }
    } = props

    return (
      <Spacing marginRight={2}>
        <FavoriteToggle
          analyticsReferrer={analyticsReferrer}
          favoriteId={currentUserFavoriteId}
          resourceSiteId={resourceSiteId}
        />
      </Spacing>
    )
  }

  return (
    <ul className='ResourceSiteActionMenu' data-testid='ResourceSiteActionMenu'>

      {resourceSitesActionPolicy.canFavorite(currentUser) && renderFavoriteToggle()}

      <div ref={actionMenuRef}>
        {moreActionsButton}
        {isExpanded && dropdownMenu()}
      </div>
    </ul>
  )
}

ResourceSiteActionMenu.defaultProps = {
}

ResourceSiteActionMenu.propTypes = {
  analyticsReferrer: PropTypes.object.isRequired,
  companyListings: PropTypes.array.isRequired,
  openDownloadModal: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openShareModal: PropTypes.func.isRequired,
  resourceSite: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  downloadPath: PropTypes.string.isRequired,
  searchMetadataParams: PropTypes.object

}

const mapDispatchToProps = {
  openDownloadModal,
  openEditModal,
  openShareModal
}

const mapStateToProps = (state, props) => {
  return {
    analyticsReferrer: state.global.analyticsReferrer,
    companyListings: state.global.currentUser.attributes.company_listings,
    currentUser: state.global.currentUser,
    searchMetadataParams: selectSearchMetadataFormParams(state, props.resourceSite.id)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSiteActionMenu)
