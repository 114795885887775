import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import truncate from 'lodash/truncate'
import Grid from '@material-ui/core/Grid'

import { formatPhoneNumber, formatDistance } from '@utils/formatterUtil'
import { IN_NETWORK_PREFERRED } from '@constants/networkTiers'
import { isInNetwork, isOutOfNetworkClr } from '@utils/networkTiersUtil'
import { outsideOfSearchRadius, determineLargestCoverageArea } from '@utils/coverageAreaUtil'
import { stringifyDecodedQueryParams } from '@utils/browserUtil'
import { selectSearchMetadataFormParams } from '@scenes/resourceSites/ResourceSite/selectors'

import ResourceSiteTierBanner from '@scenes/resourceSites/ResourceSiteTierBanner'
import ResourceSiteActionMenu from '@scenes/resourceSites/ResourceSiteActionMenu'
import SearchResultCardActions from './SearchResultCardActions'

import Card from '@components/Card'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import Icon from '@components/Icon'
import AddressDisplay from '@components/AddressDisplay'
import StampWithIconAndToolTip from '@components/StampWithIconAndToolTip'

import './SearchResultCard.scss'

const localeFor = (i18nPath, options = {}) =>
  window.local.t(`resource_sites.search_result.${i18nPath}`, options)

const serviceDescription = (description, link) => {
  const longDescription = description.length > 140

  return (
    <Text tag='p' style='body-text-secondary'>
      {truncate(description, { length: 140 })}

      {longDescription && ' '}

      {longDescription && (
        <a href={link} target='_blank'>{localeFor('content.more_details')}</a>
      )}
    </Text>
  )
}

const addressInformation = (address) => (
  <Grid container spacing={1}>
    <Grid item>
      <Icon iconKey='place' size={16} />
    </Grid>
    <Grid item>
      {address ? (
        <AddressDisplay address={address} />
      ) : (
        <Text>{localeFor('footer.none_listed', { attribute: 'address' })}</Text>
      )}
    </Grid>
  </Grid>
)

const phoneInformation = (phone) => (
  <Grid container spacing={1}>
    <Grid item>
      <Icon iconKey='phone' size={16} />
    </Grid>
    <Grid item>
      <Text>
        {phone ? (
          <a href={`tel:${phone.number}`}>{formatPhoneNumber(phone)}</a>
        ) : (
          localeFor('footer.none_listed', { attribute: 'phone' })
        )}
      </Text>
    </Grid>
  </Grid>
)

const websiteInformation = (website) => (
  <Grid container spacing={1}>
    <Grid item>
      <Icon iconKey='web_asset' size={16} />
    </Grid>
    <Grid item>
      <Text>
        {website ? (
          <a href={`${website}`} target='_blank'>{website.substring(0, 40)}{website.length >= 40 && '...'}</a>
        ) : (
          localeFor('footer.none_listed', { attribute: 'website' })
        )}
      </Text>
    </Grid>
  </Grid>
)

const emailInformation = (email) => (
  <Grid container spacing={1}>
    <Grid item>
      <Icon iconKey='email' size={16} />
    </Grid>
    <Grid item>
      <Text>
        {email ? (
          <a href={`mailto:${email}`}>{email}</a>
        ) : (
          localeFor('footer.none_listed', { attribute: 'email' })
        )}
      </Text>
    </Grid>
  </Grid>
)

const renderNoContactSection = () => (
  <Grid container spacing={1}>
    <Grid item>
      <Icon iconKey='info' size={16} />
    </Grid>
    <Grid item>
      <Text>
        {localeFor('footer.none_listed', { attribute: 'contact information' })}
      </Text>
    </Grid>
  </Grid>
)

const renderContactInformation = (phone, address, email, website) => {
  if (!phone && !address && !email && !website) {
    return renderNoContactSection()
  } else {
    return (
      <Fragment>
        {addressInformation(address)}
        {phoneInformation(phone)}
        {websiteInformation(website)}
        {emailInformation(email)}
      </Fragment>
    )
  }
}

class SearchResultCard extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.toggleExpansion = this.toggleExpansion.bind(this)
  }

  tierColor () {
    return this.displayBanner() ? 'blue' : 'gray'
  }

  displayBanner () {
    let tier = this.props.resourceSite.tier
    return isInNetwork(tier) || isOutOfNetworkClr(tier)
  }

  toggleExpansion () {
    this.setState({ expanded: !this.state.expanded })
  }

  resourceSiteUrl () {
    const { resourceSite } = this.props

    return resourceSite.links.self
  }

  referrerId () {
    const {
      analyticsReferrer: { analytics_referrer_id: referrerId }
    } = this.props

    return referrerId
  }

  resourceMetadataUrlParams () {
    const { selectSearchMetadataFormParams } = this.props
    const searchParams = {
      ...selectSearchMetadataFormParams,
      referrer_id: this.referrerId()
    }

    return stringifyDecodedQueryParams(searchParams)
  }

  resourceDetailsLink () {
    return [this.resourceSiteUrl(), this.resourceMetadataUrlParams()].join('?')
  }

  resourceDownloadLink () {
    return this.props.resourceSite.links.pdf
  }

  integratedSearchEnabled () {
    return this.props.featureFlags.integrated_search_results_flag === true
  }

  renderHeader () {
    const {
      organizationName,
      serviceName,
      tier,
      privacyStatus
    } = this.props.resourceSite
    const {
      uses_coordinate
    } = this.props.settings

    return (
      <header className='SearchResultCard__header'>
        <div className='SearchResultCard__Banner'>
          {this.displayBanner() && (
            <Spacing marginBottom={3}>
              <ResourceSiteTierBanner tier={tier} />
            </Spacing>
          )}
        </div>

        <div className='SearchResultCard__MenuContainer'>
          <ResourceSiteActionMenu
            resourceSite={this.props.resourceSite}
            downloadPath={this.resourceDownloadLink()}
          />
        </div>

        <div className='SearchResultCard__NameContainer'>
          {organizationName && (
            <Spacing marginBottom={0.5}>
              <Text tag='div' style='sub-header-extra-small'>
                {organizationName}
              </Text>
            </Spacing>
          )}

          <Spacing marginBottom={1}>
            <Text data-test='resource-link-for-result' tag='div' style='sub-header-medium'>
              <a href={this.resourceDetailsLink()} target='_blank'>{serviceName}</a>
            </Text>
          </Spacing>

          {(privacyStatus === 'private' && uses_coordinate === true) && this.renderPrivacyStatusFlag()}
        </div>
      </header>
    )
  }

  renderPrivacyStatusFlag () {
    const { privacyStatus } = this.props.resourceSite
    return (
      <Spacing marginBottom={1}>
        <StampWithIconAndToolTip text={privacyStatus} color='orange' iconKey='info' iconSize={12}
          tooltipContent={window.local.t('resource_sites.privacy_tooltip')} width='108px' />
      </Spacing>
    )
  }

  renderDistanceAway () {
    const { distanceAway, serviceRegion, coverageAreaTypes } = this.props.resourceSite
    const { searchRadius } = this.props
    const searchRadiusNumber = formatDistance(searchRadius)

    if (!distanceAway) return

    if (coverageAreaTypes && outsideOfSearchRadius(distanceAway, searchRadiusNumber)) {
      const locationType = determineLargestCoverageArea(coverageAreaTypes)

      return(
        <Grid item>
          <Text tag='span' withIcon>
            <Icon iconKey='place' size={16} />
            {localeFor('content.farther_than_radius', { location_type: locationType })}
          </Text>
        </Grid>
      )
    } else {
      return (
        <Grid item>
          <Text tag='span' withIcon>
            <Icon iconKey='place' size={16} />
            {localeFor('content.distance', { smart_count: distanceAway })}
            {serviceRegion && <>
              {' '}({localeFor(`content.service_region.${serviceRegion}`)})
            </>}
          </Text>
        </Grid>
      )
    }
  }

  renderServiceArea () {
    const { serviceRegion, distanceAway } = this.props.resourceSite

    if (distanceAway || !serviceRegion) return

    return (
      <Grid item>
        <Text tag='span' withIcon>
          <Icon iconKey='near_me' size={16} />
          {localeFor(`content.service_region.${serviceRegion}`)}
        </Text>
      </Grid>
    )
  }

  renderContent () {
    const {
      description,
      locationRemoteServices,
      score
    } = this.props.resourceSite

    return (
      <Spacing marginTop={2} marginBottom={1}>
        <Grid container spacing={3}>
          {this.renderDistanceAway()}
          {this.integratedSearchEnabled() && this.renderServiceArea()}

          {locationRemoteServices && (
            <Grid item>
              <Text tag='span' withIcon>
                <Icon iconKey='devices' size={16} />
                {localeFor('content.remote_services')}
              </Text>
            </Grid>
          )}
        </Grid>

        <Spacing marginTop={1}>
          {description && serviceDescription(description, this.resourceDetailsLink())}
        </Spacing>

        {this.matchedOfferingsFor()}

        <SearchResultCardActions
          analyticsReferrer={this.props.analyticsReferrer}
          currentUser={this.props.currentUser}
          featureFlags={this.props.featureFlags}
          resourceSite={this.props.resourceSite}
          settings={this.props.settings}
          client={this.props.client}
          languages={this.props.languages}
        />
        {score && (<>
          <Spacing marginTop={1}>
            <Text>Search Ranking Score: {score}</Text>
          </Spacing>
        </>)}
      </Spacing>
    )
  }

  renderToggle () {
    return (
      <button
        className={`SearchResultCard__toggle SearchResultCard__toggle--${this.tierColor()}`}
        aria-label={localeFor('content.toggle')}
        onClick={this.toggleExpansion}
      >
        <Icon
          iconKey={
            this.state.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }
          size={20}
          color='black'
          verticalAlignment='middle'
        />
      </button>
    )
  }

  matchedOfferingsFor () {
    const { matchedProgramIds, resourcePrograms } = this.props.resourceSite
    let programNames = []

    if (matchedProgramIds && matchedProgramIds.length > 0) {
      programNames = matchedProgramIds.map(
        (id) =>
          resourcePrograms.find(function (resourceProgram) {
            return resourceProgram.id === id
          }).name
      )
    }

    if (programNames.length > 0) {
      return (
        <Text tag='p'>
          <Text tag='span' style='body-text-emphasis'>
            {localeFor('content.matched_offerings')}:
          </Text>
          <Text tag='span'> {programNames.sort().join(', ')}</Text>
        </Text>
      )
    }
  }

  additionalServiceInfoFor (content, i18nKey) {
    return (
      <Fragment>
        <Spacing marginBottom={0.5}>
          <Text tag='div' style='sub-header-extra-small'>
            {localeFor(`footer.${i18nKey}`)}
          </Text>
        </Spacing>
        <Text tag='p'>
          {content.length
            ? content.sort().join(', ')
            : localeFor('footer.none_listed', { attribute: i18nKey })}
        </Text>
      </Fragment>
    )
  }
  contactInformation () {
    const { primaryPhone, address, email, website } = this.props.resourceSite
    return (
      <Fragment>
        <Spacing marginBottom={0.5}>
          <Text tag='div' style='sub-header-extra-small'>
            {localeFor('footer.contact')}
          </Text>
        </Spacing>
        <Grid container spacing={1}>
          {renderContactInformation(primaryPhone, address, email, website)}
        </Grid>
      </Fragment>
    )
  }

  renderFooter () {
    const {
      eligibilities,
      languages,
      resourcePrograms
    } = this.props.resourceSite

    const resourceProgramContent = resourcePrograms.map(
      (resourceProgram) => resourceProgram.name
    )

    return (
      <div
        className={`SearchResultCard__footer SearchResultCard__footer--${this.tierColor()}`}
        data-testid='SearchResultCard__ExpandedFooter'
      >
        <Spacing paddingX={4} paddingTop={3} paddingBottom={4}>
          <Spacing marginBottom={3}>
            <Text style='sub-header-extra-small'>
              {localeFor('footer.additional_info')}
            </Text>
          </Spacing>

          <Grid container spacing={3} wrap='wrap-reverse'>
            <Grid item xs={12} md={7}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {this.additionalServiceInfoFor(
                    eligibilities,
                    'eligibilities'
                  )}
                </Grid>
                <Grid item xs={12}>
                  {this.additionalServiceInfoFor(languages, 'languages')}
                </Grid>
                <Grid item xs={12}>
                  {this.additionalServiceInfoFor(
                    resourceProgramContent,
                    'service_offerings'
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={5}>
              {this.contactInformation()}
            </Grid>
          </Grid>
        </Spacing>
      </div>
    )
  }

  render () {
    const { id, tier } = this.props.resourceSite
    const footer = this.state.expanded ? this.renderFooter() : null

    return (
      <Card
        outlineColor={this.tierColor()}
        innerBorderColor={this.tierColor()}
        shaded={tier === IN_NETWORK_PREFERRED}
        footer={footer}
      >
        <div
          className='SearchResultCard'
          data-testid={`SearchResultCard--${id}`}
        >
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderToggle()}
        </div>
      </Card>
    )
  }
}

SearchResultCard.propTypes = {
  analyticsReferrer: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  featureFlags: PropTypes.object.isRequired,
  resourceSite: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  client: PropTypes.object,
  languages: PropTypes.object,
  selectSearchMetadataFormParams: PropTypes.object.isRequired,
  searchRadius: PropTypes.string.isRequired
}

const mapStateToProps = (state, { resourceSite, ...props }) => {
  return {
    selectSearchMetadataFormParams: selectSearchMetadataFormParams(state, resourceSite.id),
    searchRadius: state.resourceSites.search.radius.value
  }
}

export default connect(mapStateToProps)(SearchResultCard)
