import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'

import {
  bootstrapApp,
  loadEntities
} from '@actions'
import bootstrapService from '@services/bootstrapService'

function initSentry (dsn, currentUserId) {
  if (dsn && dsn.length) {
    Sentry.init({ dsn, whitelistUrls: [/.*packs.*\.js/] })
    Sentry.setUser({ id: currentUserId })
    console.log('Sentry initialized')
  }
}

class BootstrapApp extends React.Component {
  componentDidMount () {
    this.props.bootstrapApp()
  }

  render () {
    const { loader, children, isReady } = this.props

    return isReady ? children : loader
  }
}

BootstrapApp.defaultProps = {
  children: null,
  loader: null
}

BootstrapApp.propTypes = {
  bootstrapApp: PropTypes.func.isRequired,
  children: PropTypes.node,
  isReady: PropTypes.bool.isRequired,
  loader: PropTypes.node
}

const mapStateToProps = state => {
  return {
    isReady: state.global.isReady
  }
}

const mapDispatchToProps = dispatch => {
  return {
    bootstrapApp: () => {
      bootstrapService.get().then(function (response) {
        const data = response.data.attributes

        const currentUserId = data.current_user.data.id
        initSentry(data.config.sentry, currentUserId)
        dispatch(bootstrapApp(data))
        dispatch(loadEntities(data.current_user))
        dispatch(loadEntities(data.active_cart))
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BootstrapApp)
