// NOTE: Use camelCase, as @actions/loadEntities converts entity names from snake_case
// Example: Find all resource programs within a resource site's has_many relationship
// getAssociation(state, resourceSite, 'resourcePrograms')
export const getAssociation = (state, record, associationName, modelName = null) => {
  if (!record) {
    return null
  }

  const associations = record.relationships[associationName]

  if (typeof associations === 'undefined') {
    throw new Error(`Record is missing association: ${associationName}`)
  }

  if (associations === null) {
    return null
  }

  // this accounts for cases where the relationship name does not match the model name
  // ex: patient.identifiers (relationship name) vs. patient_identifiers (model name)
  const associationType = (association) => {
    return modelName || association.type
  }

  if (Array.isArray(associations)) {
    return associations.map(association => getRecord(state, associationType(association), association.id))
  } else {
    return getRecord(state, associationType(associations), associations.id)
  }
}

// This method is useful for grabbing a collection of records all with the same condition.
// Ex: grab all teams that are associated to company id 1
// This is useful when we don't actually have the company id 1 record in the entites,
// in which case we cannot use the `getAssociation` function
export const getCollectionFromRelationship = ({ state, type, association, id }) => {
  const missingParams = [state, type, association, id].filter(param => !param)
  if (missingParams.length) {
    throw new Error(`Missing required parameter`)
  }

  if (!state.global.entities[type]) {
    return []
  }

  return (Object.values(state.global.entities[type]) || []).filter(record => {
    return record.relationships[association] &&
      String(record.relationships[association].id) === String(id)
  })
}

// This function takes a record and denormalizes it. So given a record in the store, this method
// will find all the relationships, and add them as sub objects to the record.
const denormalizeRecord = (state, record) => {
  return Object.keys(record.relationships).reduce((denomralizedRecord, relationship) => {
    denomralizedRecord[relationship] = getAssociation(state, record, relationship)

    return denomralizedRecord
  }, { ...record })
}

export const denormalizeRecords = (state, records) => {
  if (!records) {
    throw new Error('Record cannot be null')
  }

  if (Array.isArray(records)) {
    return records.map(record => {
      return denormalizeRecord(state, record)
    })
  } else {
    return denormalizeRecord(state, records)
  }
}

export const getRecord = (state, type, id) => {
  if (!state.global.entities[type] || !state.global.entities[type][id]) {
    return null
  }

  return state.global.entities[type][id]
}

export const getNewRecords = (state, type) => {
  if (!state.global.newEntities[type]) {
    return null
  }

  return state.global.newEntities[type]
}

export const getCurrentCompany = state => state.global.currentCompany
