import React from 'react'
import PropTypes from 'prop-types'
import { InfoWindow } from '@react-google-maps/api'

import AddressDisplay from '@components/AddressDisplay'
import Text from '@components/Text'

const ResourceSiteInfoWindow = ({ resourceSite, ...passThruProps }) => {
  const { links, serviceName, address } = resourceSite
  const { latitude: lat, longitude: lng } = address
  const iconOffset = new window.google.maps.Size(0, -40)

  return (
    <InfoWindow
      options={{ pixelOffset: iconOffset }}
      position={{ lat, lng }}
      {...passThruProps}
    >
      <header>
        <Text style='sub-header-small'>
          <a href={links.self}>{serviceName}</a>
        </Text>

        <AddressDisplay address={address} />
      </header>
    </InfoWindow>
  )
}

ResourceSiteInfoWindow.propTypes = {
  resourceSite: PropTypes.object.isRequired
}

export default ResourceSiteInfoWindow
