import { CLIENT_DETAIL_ENDPOINT } from '@constants/apiEndpoints'
import { getApiResource } from '@utils/apiUtil'

const type = 'patient_goal'
export const getClientGoalResource = clientId => (
  getApiResource([CLIENT_DETAIL_ENDPOINT, clientId, 'goals'].join('/'))
)

export default {
  type
}
