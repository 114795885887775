import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'
import Text from '@components/Text'

import './ResourceProgram.scss'

class ResourceProgram extends PureComponent {
  render () {
    const { program } = this.props

    return (
      <span className='ResourceProgram' data-testid='ResourceProgram'>
        <Text tag='span'>{program.attributes.name}</Text>

        {!!program.attributes.childIds.length && <Icon
          iconKey='keyboard_arrow_right'
          size={16}
          data-testid='ResourceProgram__icon'
        />}
      </span>
    )
  }
}

ResourceProgram.propTypes = {
  program: PropTypes.shape({
    attributes: PropTypes.shape({
      childIds: PropTypes.array,
      name: PropTypes.string
    })
  }).isRequired
}

ResourceProgram.displayName = 'ResourceProgram'

export default ResourceProgram
