import React, { Fragment, Component } from 'react'
import DatePicker from '@components/DatePicker'
import Checkbox from '@components/Form/Checkbox'
import Story from '../Story'

class DatePickers extends Component {
  constructor (props) {
    super(props)

    this.state = {
      basic: null,
      label: null,
      label_required: null,
      prefilled: new Date(),
      minimum_date: null,
      errors: null,
      showErrorMessages: false
    }

    this.toggleErrorMessages = this.toggleErrorMessages.bind(this)
  }

  onChange (key) {
    return (date) => {
      this.setState({ [key]: date })
    }
  }

  toggleErrorMessages () {
    this.setState({ showErrorMessages: !this.state.showErrorMessages })
  }

  render () {
    return (
      <Fragment>
        <Story title='Basic DatePicker' key='basic'>
          <DatePicker
            onChange={this.onChange('basic')}
            selected={this.state.basic}
          />
        </Story>

        <Story title='DatePicker with Label' key='label'>
          <DatePicker
            onChange={this.onChange('label')}
            selected={this.state.label}
            label='DatePicker with a label'
          />
        </Story>

        <Story title='DatePicker with required Label' key='label_required'>
          <DatePicker
            onChange={this.onChange('label_required')}
            selected={this.state.label_required}
            label='DatePicker with a required label'
            required
          />
        </Story>

        <Story title='Prefilled DatePicker' key='prefilled'>
          <DatePicker
            onChange={this.onChange('prefilled')}
            selected={this.state.prefilled}
          />
        </Story>

        <Story title='DatePicker with minimum date' key='minimum_date'>
          <DatePicker
            minDate={new Date()}
            onChange={this.onChange('minimum_date')}
            selected={this.state.minimum_date}
          />
        </Story>

        <Story title='DatePicker with errors' key='errors'>
          <Checkbox
            id='showErrorMessages'
            onChange={this.toggleErrorMessages}
            label='Show error messages below'
          />

          <DatePicker
            errors={['Error message 1', 'Error message 2']}
            onChange={this.onChange('errors')}
            selected={this.state.errors}
            showErrorMessages={this.state.showErrorMessages}
          />
        </Story>
      </Fragment>
    )
  }
}

DatePickers.displayName = 'DatePickers'

export default DatePickers
