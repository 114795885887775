import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Text from '@components/Text'
import Spacing from '@components/Spacing'
import './Label.scss'

class Label extends PureComponent {
  renderRequiredAccent () {
    return (
      <Spacing tag='span' paddingLeft={0.5}>
        <Text style='error-message' display='inline'>*</Text>
      </Spacing>
    )
  }

  renderHintText () {
    return (
      <Spacing tag='div' marginBottom={2}>
        <Text style='form-fields-hint'>
          {this.props.hint}
        </Text>
      </Spacing>
    )
  }

  render () {
    const { id, text, required, disabled, hint, display } = this.props
    const textStyleName = disabled ? 'form-fields-disabled' : 'form-fields'
    const klassNames = classNames(
      'Label',
      { 'Label--disabled': disabled },
      `display-${display}`
    )

    return (
      <div className={klassNames}>
        <Spacing tag='div' marginBottom={1}>
          <label htmlFor={id} className='Label__text'>
            <Text style={textStyleName}>{text}</Text>
            {required && this.renderRequiredAccent()}
          </label>
        </Spacing>

        {hint && this.renderHintText()}
      </div>
    )
  }
}

Label.defaultProps = {
  required: false,
  disabled: false,
  display: 'block'
}

Label.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  display: PropTypes.oneOf(['block', 'inline', 'inline-block'])
}

export default Label
