import React from 'react'
import PropTypes from 'prop-types'

import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

class DiffToggle extends React.PureComponent {
  render () {
    const label = window.local.t('client_merge.review.show_edits')
    const control = (<Switch checked={this.props.showDiff} onChange={this.props.toggleShowDiff} value={label} color='primary' />)

    return (
      <FormControl component='fieldset'>
        <FormControlLabel
          value={label}
          control={control}
          label={label}
          labelPlacement='start'
        />
      </FormControl>
    )
  }
}

DiffToggle.propTypes = {
  showDiff: PropTypes.bool.isRequired,
  toggleShowDiff: PropTypes.func.isRequired
}

export default DiffToggle
