import React, { useContext } from 'react'

import AddGoalForm from './Forms/AddGoalForm'

import Grid from '@material-ui/core/Grid'
import Text from '@components/Text'
import Spacing from '@components/Spacing'

import { ClientGoalsContext } from './ClientGoals'

const Header = props => {
  const {
    getMostRecentGoal,
    getClient,
    getGoalLastUpdatedDate,
    getString
  } = useContext(ClientGoalsContext)

  const getHeaderText = () => {
    const clientName = getClient().firstName
    const mostRecentGoal = getMostRecentGoal()
    const lastUpdatedDate = getGoalLastUpdatedDate(mostRecentGoal)

    if (!mostRecentGoal) return getString('last_updated.never', { clientName })

    return getString('last_updated.on_date', { clientName, date: lastUpdatedDate })
  }

  return (
    <header className='ClientGoals__Header'>
      <Grid
        container
        spacing={1}
        direction='row'
        justify='center'
        alignItems='baseline'
      >
        <Grid item xc={12} sm={8} md={9} xl={10}>
          <Spacing paddingBottom={1}>
            <Text tag='h3' style='sub-header-medium'>
              {getHeaderText()}
            </Text>
          </Spacing>
        </Grid>
        <Grid item xs={12} sm={4} md={3} xl={2}>
          <Spacing paddingBottom={2}>
            <AddGoalForm />
          </Spacing>
        </Grid>
      </Grid>
    </header>
  )
}

export default Header
