import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { GOAL_STATUSES } from '../constants'

import ToggleStatus from '@components/Forms/Elements/ToggleStatus'

import { ClientGoalsContext } from '../ClientGoals'

const ToggleGoalStatus = ({ goal, ...props }) => {
  const { getClient, getUpdateGoal, getString, getGoalAssociations } = useContext(ClientGoalsContext)
  const { updateGoal } = getUpdateGoal(false)
  const { user } = getGoalAssociations(goal)
  const updateParams = { patientId: getClient().id, userId: user.id }

  const handleStatusChange = (status) => {
    const updatedStatus = { status }
    const formData = { ...updateParams, ...updatedStatus }

    updateGoal(goal.id, formData)
  }

  return (
    <ToggleStatus
      type='goal'
      entity={goal}
      statuses={GOAL_STATUSES}
      label={getString('form.label.status')}
      onChange={handleStatusChange}
      isDisabled={!goal.canEdit}
    />
  )
}

ToggleGoalStatus.propTypes = {
  goal: PropTypes.object
}

export default ToggleGoalStatus
