import React from 'react'
import PropTypes from 'prop-types'

const PageHeader = ({ headerClassName, text, description, rank }) => {
  const HeaderTag = `h${rank}`

  return (
    <header className={headerClassName}>
      <HeaderTag className={`hfysg-h${rank}`}>
        {text}
      </HeaderTag>
      {description && <p className='hfysg-body'>{description}</p>}
    </header>
  )
}

PageHeader.propTypes = {
  rank: PropTypes.oneOf([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6'
  ]).isRequired,
  headerClassName: PropTypes.string,
  text: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default PageHeader
