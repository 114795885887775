import React from 'react'
import PropTypes from 'prop-types'
import './SmallModule.css'
import Banner from './../Banner'
import Icon from '@components/Icon'

class SmallModule extends React.Component {
  conditionallyRenderBanner () {
    if (this.props.banner) {
      const { text, width } = this.props.banner

      return (
        <Banner text={text} width={width} color='blue-05' />
      )
    }
  }

  renderBlankOrInlineLink () {
    if (this.props.subtitleLinkInline) {
      return (
        <a href={this.props.subtitleLink} className='SmallModule__header__link'>
          {this.props.subtitle}
          <Icon className='mi--align-top' iconKey='keyboard_arrow_right' size={16} />
        </a>
      )
    } else {
      return (
        <a href={this.props.subtitleLink} className='SmallModule__header__link' target='_blank'>
          {this.props.subtitle}
          <Icon className='mi--align-top' iconKey='keyboard_arrow_right' size={16} />
        </a>
      )
    }
  }

  conditionallyRenderSubtitle () {
    if (this.props.subtitle && this.props.subtitleLink) {
      return (
        <h6 className='hfysg-h6'>
          {this.renderBlankOrInlineLink()}
        </h6>
      )
    } else if (this.props.subtitle) {
      return (
        <h6 className='hfysg-h6'>
          {this.props.subtitle}
        </h6>
      )
    }
  }

  conditionallyRenderHeaderBottom () {
    if (this.props.headerBottom) return this.props.headerBottom
  }

  conditionallyRenderHeaderRight () {
    if (this.props.headerRight) return this.props.headerRight
  }

  renderSmallModuleContainerClass () {
    const classNames = ['hfysg-body', 'SmallModule']
    if (!this.props.hasMarginBottom) {
      classNames.push('SmallModule--without_bottom_margin')
    }
    return classNames.join(' ')
  }

  renderHeaderClass () {
    const classNames = ['SmallModule__header']
    if (!this.props.hasHeaderBackground) {
      classNames.push('SmallModule__header--no-bg')
    }
    return classNames.join(' ')
  }

  render () {
    const { title } = this.props

    return (
      <section className={this.renderSmallModuleContainerClass()}>
        <header className={this.renderHeaderClass()}>
          <div className='SmallModule__header__top'>
            {this.conditionallyRenderBanner()}
            {this.conditionallyRenderHeaderRight()}
          </div>

          <h3 className='hfysg-h3 SmallModule__header__title'>
            {title}
          </h3>
          {this.conditionallyRenderSubtitle()}
          {this.conditionallyRenderHeaderBottom()}
        </header>

        <div className='SmallModule__content'>
          {this.props.children}
        </div>
      </section>
    )
  }
}

SmallModule.defaultProps = { hasMarginBottom: true, hasHeaderBackground: true }

SmallModule.propTypes = {
  banner: PropTypes.shape({
    text: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired
  }),
  children: PropTypes.node.isRequired,
  hasMarginBottom: PropTypes.bool.isRequired,
  hasHeaderBackground: PropTypes.bool.isRequired,
  headerRight: PropTypes.node,
  headerBottom: PropTypes.node,
  subtitle: PropTypes.string,
  subtitleLink: PropTypes.string,
  subtitleLinkInline: PropTypes.bool,
  title: PropTypes.string.isRequired
}

export default SmallModule
