import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import Button from '@components/Button'
import Alert from '@components/Alert'
import Modal from '@components/Modal'
import FileAttachmentFormControl from '@components/FileAttachmentFormControl'

export default class UploadAttachmentModal extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      file: null
    }

    this.onFileSelect = this.onFileSelect.bind(this)
    this.onFileClear = this.onFileClear.bind(this)
    this.onFileUpload = this.onFileUpload.bind(this)
  }

  onFileSelect (file) {
    this.setState({
      file: file
    })
  }

  onFileUpload () {
    this.props.onUpload(this.state.file)
  }

  onFileClear () {
    this.setState({ file: null })
    this.props.onFileClear()
  }

  renderModalContent () {
    return (
      <Fragment>
        <FileAttachmentFormControl
          id='upload-modal-attachment-control'
          name='upload-modal-attachment-control'
          directions={window.local.t('components.upload_attachment_modal.context')}
          onFileSelect={this.onFileSelect}
          onFileClear={this.onFileClear}
          file={this.state.file}
        />
      </Fragment>
    )
  }

  renderModalActions () {
    return (
      <Grid container spacing={4} justify='space-between'>
        <Grid item xs={6}>
          <Button type='secondary' onClick={this.props.onClose}>
            {window.local.t('components.upload_attachment_modal.cancel_button')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type='primary' onClick={this.onFileUpload} disabled={!this.state.file}>
            {window.local.t('components.upload_attachment_modal.upload_button')}
          </Button>
        </Grid>
        {this.props.warning &&
          <Grid item xs={12}>
            <Alert type='warning' title={this.props.warning} inline />
          </Grid>
        }
        {this.props.error &&
          <Grid item xs={12}>
            <Alert type='error' title={this.props.error} inline />
          </Grid>
        }
      </Grid>
    )
  }

  render () {
    const { open, onClose } = this.props

    return (
      <Modal
        open={open}
        onClose={onClose}
        headerText={window.local.t('components.upload_attachment_modal.title')}
        content={this.renderModalContent()}
        actions={this.renderModalActions()}
        dataTest='attachment-modal'
      />
    )
  }
}

UploadAttachmentModal.defaultProps = {
  onFileClear: () => {}
}

UploadAttachmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  warning: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onFileClear: PropTypes.func,
  onUpload: PropTypes.func.isRequired
}
