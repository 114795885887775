import { useMemo } from 'react'

import clientsService from '@services/clientsService'
import useGetRecord from '@hooks/useGetRecord'

import createPropGetters from './propGetters'

export default function useClientGoals (clientId, options = {}) {
  const client = useGetRecord(clientsService.type, clientId)
  const propGetters = createPropGetters(client)

  const value = useMemo(() => ({
    ...propGetters
  }), [client])

  return value
}
