import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

const duration = 500

// This component collapses out and collapses to height of 0.
// Unfortunately, this is complicated as we need to put the
// height into the style. In order to do this without hardcoding
// a height, we let the component render as is, then when we're
// ready to collapse it, we measure the height, specify that height
// in the style, then let the transition run.
const transitionStyles = (height) => {
  const enterState = { opacity: 1, height: height || 'auto' }
  const endState = {
    opacity: 0,
    height: 0,
    transition: `opacity ${duration}ms ease-in-out, height ${duration}ms ease-in-out`
  }

  return {
    entering: enterState,
    entered: enterState,
    exiting: endState,
    exited: endState
  }
}

class Collapse extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      collapsing: this.props.collapse,
      height: null
    }

    // Grab a ref to the div so we can get the height
    this.divRef = React.createRef()
  }

  componentDidUpdate (prevProps, prevState) {
    // If we just set the height, we're now ready to collapse
    // the component. We set collapse to true in a timeout to give
    // the DOM a chance to update with the new height style.
    if (prevState.height === null && prevState.height !== this.state.height) {
      setTimeout(() => {
        this.setState({
          collapsing: true
        })
      }, 0)
    }

    const collapseChanged = prevProps.collapse !== this.props.collapse
    const isInitializingCollapse = this.props.collapse && collapseChanged
    const isUncollapsing = !this.props.collapse && collapseChanged

    // We're about to collapse the component and need to get the
    // height before we do.
    if (isInitializingCollapse) {
      // eslint-disable-next-line
      this.setState({
        height: this.divRef.current.scrollHeight
      })
    }
    if (isUncollapsing) {
      // eslint-disable-next-line
      this.setState({
        height: null,
        collapsing: false
      })
    }
  }

  render () {
    const { children } = this.props

    return (
      <Transition appear in={!this.state.collapsing} timeout={duration}>
        {state => {
          return (
            <div ref={this.divRef} className='Collapse__component' style={{
              ...transitionStyles(this.state.height)[state]
            }}>
              {children}
            </div>
          )
        }}
      </Transition>
    )
  }
}

// When collapse is true, we trigger the animation
Collapse.propTypes = {
  collapse: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired
}

export default Collapse
