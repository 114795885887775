import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Spacing from '@components/Spacing'
import Grid from '@material-ui/core/Grid'
import Text from '@components/Text'
import Icon from '@components/Icon'
import AddressDisplay from '@components/AddressDisplay'

import { ResourceSiteContext } from './ResourceSite'

const ContactInfo = ({
  className = '',
  headerStyle = 'sub-header-small',
  ...props
}) => {
  const {
    associations: { getPhone, getAddress },
    formatters: { phoneNumber, website },
    getString
  } = useContext(ResourceSiteContext)

  const combinedClassNames = classNames('ResourceSite__ContactInfo', className)

  return (
    <section className={combinedClassNames}>
      <Spacing marginBottom={1}>
        <Text tag='h4' style={headerStyle}>
          {getString('contact_info.label')}
        </Text>
      </Spacing>

      <Grid container spacing={1}>
        <Grid item>
          <Icon iconKey='place' size={16} />
        </Grid>
        <Grid item xs={11}>
          {getAddress() ? (
            <AddressDisplay address={getAddress()} />
          ) : (
            <Text>{getString('contact_info.none_listed', { attribute: 'address' })}</Text>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <Icon iconKey='phone' size={16} />
        </Grid>

        <Grid item xs={11}>
          <Text>
            {getPhone() ? (
              <a href={`tel:${getPhone().number}`}>{phoneNumber()}</a>
            ) : (
              getString('contact_info.none_listed', { attribute: 'phone' })
            )}
          </Text>
        </Grid>
      </Grid>

      {website() && (<>
        <Grid container spacing={1}>
          <Grid item>
            <Icon iconKey='open_in_new' size={16} />
          </Grid>

          <Grid item xs={11}>
            <Text>
              <a href={website()} target='blank'>{website()}</a>
            </Text>
          </Grid>
        </Grid>
      </>)}
    </section>
  )
}

ContactInfo.propTypes = {
  className: PropTypes.string,
  headerStyle: PropTypes.string
}

export default ContactInfo
