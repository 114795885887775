import React, { useState } from 'react'

import Icon from '@components/Icon'
import Button from '@components/Button'
import Text from '@components/Text'

import { toggleFavorite } from '@services/favoritesService'

import PropTypes from 'prop-types'

const FavoriteToggle = (props) => {
  const localeFor = (i18nPath, options = {}) => window.local.t(
    `resource_sites.action_menu.${i18nPath}`,
    options
  )

  const [favoriteId, setFavoriteId] = useState(props.favoriteId)
  const [siteId] = useState(props.resourceSiteId)
  const [isError, setError] = useState(false)

  const favoriteState = { favoriteId: favoriteId, siteId: siteId }

  const handleToggleFavorite = () => {
    toggleFavorite(favoriteState, props.analyticsReferrer)
      .then(res => {
        if (res.success) {
          setFavoriteId(res.favoriteId)
          setError(false)
        } else { setError(true) }
      })
  }

  const buttonText = () => {
    return localeFor(`favorite.label.${favoriteId ? 'favorited' : 'favorite'}`)
  }

  const favoriteIcon = () => {
    return isError ? 'report_problem' : 'favorite'
  }

  const heartColor = () => {
    if (isError) {
      return 'orange'
    } else if (favoriteId) {
      return 'red'
    } else {
      return 'black'
    }
  }

  return (
    <li className='ResourceSiteActionMenu__favorite'>
      <Button
        type='toolbar'
        aria-label={localeFor(`favorite.aria_label.${favoriteId ? 'add' : 'remove'}`)}
        onClick={handleToggleFavorite}
      >
        <Text style='buttons' withIcon>
          {buttonText()}
          <Icon iconKey={favoriteIcon()} color={heartColor()} size={12} />
        </Text>
      </Button>
    </li>
  )
}

FavoriteToggle.propTypes = {
  analyticsReferrer: PropTypes.object.isRequired,
  resourceSiteId: PropTypes.number.isRequired,
  favoriteId: PropTypes.number
}

export default FavoriteToggle
