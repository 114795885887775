import React from 'react'
import { useParams } from 'react-router-dom'

import ResourceSiteSearchResults from '@scenes/resourceSites/ResourceSiteSearchResults/ResourceSiteSearchResults'

const PublicSearchResults = (props) => {
  const { companySlug } = useParams()
  const baseUrl = `/${companySlug}`

  return (
    <ResourceSiteSearchResults
      pageTitle={window.local.t('resource_sites.search_page_title')}
      baseUrl={baseUrl}
      {...props}
    />
  )
}

PublicSearchResults.propTypes = {}

export default PublicSearchResults
