import React from 'react'
import PropTypes from 'prop-types'

import './Form.scss'

const onFormSubmit = (onSubmit) => (event) => {
  event.preventDefault()
  onSubmit()
}

const Form = (props) => {
  return (
    <form role='form' className='Form' onSubmit={onFormSubmit(props.onSubmit)}>
      {props.children}
    </form>
  )
}

Form.defaultProps = {
  onSubmit: () => {}
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node
}

export default Form
