import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'

import { CLIENT_INDEX } from '@constants'

import { addNote } from '@scenes/clients/ClientMerge/actions'
import mergeService from '@scenes/clients/ClientMerge/services/mergeService'
import { unselectableAttributesBetweenClients } from '@scenes/clients/ClientMerge/utils'

import SuccessModal from '@components/SuccessModal'
import PageHeader from '@components/PageHeader'
import ClientDiff from '@scenes/clients/ClientMerge/components/ClientDiff'
import { ReviewAddComment } from './components'

import './ReviewView.css'

const SUCCESS_STATUS = 201

const ReviewView = ({
  addNote,
  compositeClient,
  firstPatient,
  localeContext,
  note,
  secondPatient,
  unselectableAttributes
}) => {
  const local = window.local
  const [requestSuccess, setRequestSuccess] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    mergeService.post({ note, compositeClient, secondPatient, firstPatient })
      .then((response) => {
        if (response.status === SUCCESS_STATUS) {
          setRequestSuccess(true)
          setTimeout(() => {
            window.location = CLIENT_INDEX
          }, 2000)
        }
      }).catch((err) => {
        console.log('error', err)
      })
  }

  const handleTextChange = (value) => addNote(value)

  return (
    <Grid
      container
      direction='row'
      justify='flex-start'
      alignItems='flex-start'
    >
      <SuccessModal
        title={local.t(`client_merge.review.dialog.title.${localeContext}`, { _: '' })}
        text={local.t(`client_merge.review.dialog.text.${localeContext}`, { _: '' })}
        open={requestSuccess}
      />
      <Grid container direction='row'>
        <PageHeader
          title={local.t(`client_merge.review.header_title.${localeContext}`)}
          description={
            <span dangerouslySetInnerHTML={{ __html: local.t('client_merge.review.header_copy') }} />
          }
          backLink={{
            path: local.t(`client_merge.review.header_back_link_url.${localeContext}`),
            text: local.t(`client_merge.review.header_back_link.${localeContext}`)
          }}
        />
      </Grid>
      <ClientDiff
        firstPatient={firstPatient}
        secondPatient={secondPatient}
        localeContext={localeContext}
        compositeClient={compositeClient}
        unselectableAttributes={unselectableAttributes}
      >
        <ReviewAddComment
          handleSubmit={handleSubmit}
          handleChange={handleTextChange}
          localeContext={localeContext}
        />
      </ClientDiff>
    </Grid>
  )
}

ReviewView.propTypes = {
  addNote: PropTypes.func.isRequired,
  compositeClient: PropTypes.object.isRequired,
  firstPatient: PropTypes.object.isRequired,
  localeContext: PropTypes.string.isRequired,
  secondPatient: PropTypes.object.isRequired,
  note: PropTypes.string,
  unselectableAttributes: PropTypes.array.isRequired
}

const mapStateToProps = state => {
  const firstPatient = state.clientMerge.firstPatient
  const secondPatient = state.clientMerge.secondPatient
  const unselectableAttributes = unselectableAttributesBetweenClients({ firstPatient, secondPatient })

  return {
    unselectableAttributes,
    firstPatient: state.clientMerge.firstPatient,
    secondPatient: state.clientMerge.secondPatient,
    compositeClient: state.clientMerge.compositeClient,
    note: state.clientMerge.note
  }
}

const mapDispatchToProps = dispatch => ({
  addNote: textValue => dispatch(addNote(textValue))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewView)
