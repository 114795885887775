import React, { PureComponent, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import LinkButton from '@components/LinkButton'
import Story from '../Story'

const LinkButtons = () => {
  return (
    <>
    <Story title='Link Button'>
      <Grid item xs={3}>
        <LinkButton to="/PhoneInput">Link button to next section</LinkButton>
      </Grid>
    </Story>
    </>
  )
}

LinkButtons.displayName = 'LinkButtons'

export default LinkButtons

