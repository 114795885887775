const formatIdentifierForAPI = (object) => {
  if (!object) return null

  return {
    id: object.value.id,
    source: object.source,
    value: {
      identifier_type: object.value.type,
      normalized_value: object.value.value
    }
  }
}

const formatPhoneForApi = (object) => {
  if (!object) return null

  return {
    ...object,
    value: {
      number: object.value.number,
      descriptor: object.value.descriptor
    },
    id: object.value.id
  }
}

const formatAddressForApi = (object) => {
  if (!object) return null

  return {
    ...object,
    id: object.value.id,
    value: {
      line_1: object.value.line1,
      line_2: object.value.line2,
      city: object.value.city,
      state: object.value.state,
      zipcode: object.value.zipcode
    }
  }
}

const formatExternalIdForApi = (object) => {
  if (!object) return null

  return {
    source: object.source,
    value: object.value.value
  }
}

export const splitExternalIdFromIdentifiers = (identifiers) => {
  if (!identifiers) return []

  return {
    identifiers: identifiers.filter(identifier => (identifier.value.type !== 'external')),
    externalId: identifiers.find(identifier => (identifier.value.type === 'external'))
  }
}

const serialize = (props, additionalProps) => {
  const { compositeClient, firstPatient, secondPatient, note } = props

  const splitIdentifiers = splitExternalIdFromIdentifiers(compositeClient.identifiers)

  return {
    patient_merge_request: {
      patient_ids: [
        firstPatient.id,
        secondPatient.id
      ],
      composite_patient_data: {
        patient: {
          first_name: compositeClient.firstName,
          middle_name: compositeClient.middleName,
          last_name: compositeClient.lastName,
          gender: compositeClient.gender,
          external_id: formatExternalIdForApi(splitIdentifiers.externalId),
          email: compositeClient.email,
          date_of_birth: compositeClient.dateOfBirth
        },
        address: formatAddressForApi(compositeClient.address),
        phones: compositeClient.phones && compositeClient.phones.map(formatPhoneForApi),
        identifiers: splitIdentifiers.identifiers && splitIdentifiers.identifiers.map(formatIdentifierForAPI)
      },
      note: note,
      ...additionalProps
    }
  }
}

export default {
  serialize
}
