// NOTE: THIS IS NOT A FINAL EXAMPLE OF HOW FEATURE FILES SHOULD BE BUILT OUT. This is an
// intermediate version which follows the
// [Ducks pattern](https://github.com/erikras/ducks-modular-redux)
//
// Eventually, this should move to `createSlice` from Redux Toolkit and then these comments can be
// removed

import { createReducer } from '@reduxjs/toolkit'

import { createReferrals, removeItem } from '@services/cartService'
import { removeCartItemFromNonSpa, updateCartCountNonSpa } from '@services/updateNonSpa'
import { selectRemainingCartItems } from '@scenes/referralCheckout/selectors'
import {
  IN_NETWORK,
  OUT_OF_NETWORK,
  OUT_OF_NETWORK_CLR,
  NETWORKED_TIERS
} from '@constants/networkTiers'

// Constants

const REFERRAL_CHECKOUT_ERROR_TYPE_REMOVE = 'remove'
const REFERRAL_CHECKOUT_ERROR_TYPE_SERVER = 'server'
const REFERRAL_CHECKOUT_ERROR_TYPE_SERVICE_OFFERINGS = 'service_offerings'
const REFERRAL_CHECKOUT_SET_CART_ITEMS = 'REFERRAL_CHECKOUT_SET_CART_ITEMS'
const REFERRAL_CHECKOUT_SET_ERROR = 'REFERRAL_CHECKOUT_SET_ERROR'
const REFERRAL_CHECKOUT_SET_PATIENT_ID = 'REFERRAL_CHECKOUT_SET_PATIENT_ID'
const REFERRAL_CHECKOUT_TOGGLE_CLIENT_CONSENT_GRANTED = 'REFERRAL_CHECKOUT_TOGGLE_CLIENT_CONSENT_GRANTED'
const REFERRAL_CHECKOUT_TOGGLE_CLIENT_CONSENT_REQUIRED = 'REFERRAL_CHECKOUT_TOGGLE_CLIENT_CONSENT_REQUIRED'
const REFERRAL_CHECKOUT_TOGGLE_KEY = 'REFERRAL_CHECKOUT_TOGGLE_KEY'
const REFERRAL_CHECKOUT_UPDATE_CART_ITEM = 'REFERRAL_CHECKOUT_UPDATE_CART_ITEM'

// Reducer

const referralSections = [
  {
    key: IN_NETWORK,
    tiers: NETWORKED_TIERS
  },
  {
    key: OUT_OF_NETWORK_CLR,
    tiers: [OUT_OF_NETWORK_CLR]
  },
  {
    key: OUT_OF_NETWORK,
    tiers: [OUT_OF_NETWORK]
  }
]

const initialState = {
  submitModalOpened: false,
  cartItems: [],
  showReferAnotherPatientForm: false,
  showSidebarModalOnMobile: false,
  patientId: null,
  referralSections
}

const getInitialCartState = ({ tier }) => ({
  collapse: false,
  removed: false,
  submittedSuccess: false,
  errorObject: {
    service_offerings: null,
    remove: null,
    server: null
  },
  note: '',
  file: null,
  consentRequired: NETWORKED_TIERS.includes(tier) || tier === OUT_OF_NETWORK_CLR,
  consentGranted: false,
  matchingPrograms: {}
})

export default createReducer(initialState, builder => {
  builder
    .addCase(REFERRAL_CHECKOUT_SET_CART_ITEMS, (state, action) => {
      state['cartItems'] = action.payload.cartItems.map(cartItem => ({
        ...getInitialCartState(cartItem),
        ...cartItem
      }))
    })
    .addCase(REFERRAL_CHECKOUT_UPDATE_CART_ITEM, (state, action) => {
      state.cartItems = state.cartItems.map(cartItem => {
        if (cartItem.id === action.payload.cartItemId) {
          return { ...cartItem, ...action.payload.cartItemUpdatedAttributes }
        } else {
          return cartItem
        }
      })
    })
    .addCase(REFERRAL_CHECKOUT_SET_ERROR, (state, action) => {
      state.cartItems = state.cartItems.map((cartItem) => {
        if (cartItem.id !== action.payload.cartItemId) return cartItem

        return {
          ...cartItem,
          errorObject: {
            ...cartItem.errorObject,
            [action.payload.errorType]: action.payload.errorText
          }
        }
      })
    })
    .addCase(REFERRAL_CHECKOUT_TOGGLE_KEY, (state, action) => {
      state[action.payload.key] = action.payload.toggleValue
    })
    .addCase(REFERRAL_CHECKOUT_TOGGLE_CLIENT_CONSENT_GRANTED, (state, action) => {
      state.cartItems = state.cartItems.map((cartItem) => {
        if (!action.payload.cartItemIds.includes(cartItem.id)) return cartItem

        return {
          ...cartItem,
          consentGranted: !cartItem.consentGranted
        }
      })
    })
    .addCase(REFERRAL_CHECKOUT_TOGGLE_CLIENT_CONSENT_REQUIRED, (state, action) => {
      state.cartItems = state.cartItems.map((cartItem) => {
        if (cartItem.id !== action.payload.cartItemId) return cartItem

        return {
          ...cartItem,
          consentRequired: !cartItem.consentRequired
        }
      })
    })
    .addCase(REFERRAL_CHECKOUT_SET_PATIENT_ID, (state, action) => {
      state.patientId = action.payload.patientId
    })
})

// Action Creators

export const setCartItems = cartItems => ({
  type: REFERRAL_CHECKOUT_SET_CART_ITEMS,
  payload: { cartItems }
})

export const clearErrors = (cartItemId, cartItemUpdatedAttributes) => {
  return (dispatch) => {
    const keys = Object.keys(cartItemUpdatedAttributes)
    if (keys.includes('file')) {
      dispatch(setError(cartItemId, REFERRAL_CHECKOUT_ERROR_TYPE_SERVER, null))
    }
    if (keys.includes('matchingPrograms')) {
      dispatch(setError(cartItemId, REFERRAL_CHECKOUT_ERROR_TYPE_SERVICE_OFFERINGS, null))
    }
  }
}

export const updateCartItem = (cartItemId, cartItemUpdatedAttributes) => {
  return (dispatch) => {
    dispatch(clearErrors(cartItemId, cartItemUpdatedAttributes))

    dispatch({
      type: REFERRAL_CHECKOUT_UPDATE_CART_ITEM,
      payload: { cartItemId, cartItemUpdatedAttributes }
    })

    return Promise.resolve()
  }
}

export const setError = (cartItemId, errorType, errorText) => ({
  type: REFERRAL_CHECKOUT_SET_ERROR,
  payload: { cartItemId, errorType, errorText }
})

export const toggleKey = (key, toggleValue) => ({
  type: REFERRAL_CHECKOUT_TOGGLE_KEY,
  payload: { key, toggleValue }
})

export const toggleClientConsentGranted = (cartItemIds) => ({
  type: REFERRAL_CHECKOUT_TOGGLE_CLIENT_CONSENT_GRANTED,
  payload: { cartItemIds }
})

export const toggleClientConsentRequired = (cartItemId) => ({
  type: REFERRAL_CHECKOUT_TOGGLE_CLIENT_CONSENT_REQUIRED,
  payload: { cartItemId }
})

export const setPatientId = patientId => ({
  type: REFERRAL_CHECKOUT_SET_PATIENT_ID,
  payload: { patientId }
})

export const removeCartItemUI = (cartItemId) => {
  return (dispatch) => {
    return dispatch(updateCartItem(cartItemId, { errorObject: {}, removed: true }))
  }
}

export const removeCartItem = (cartItemId, analytics) => {
  return (dispatch, getState) => {
    dispatch(updateCartItem(cartItemId, { collapse: true }))
    removeItem(cartItemId, analytics)
      .then(() => {
        dispatch(removeCartItemUI(cartItemId)).then(() => {
          // After updating is successful let's remove this item from the non-SPA
          // parts of the app.
          removeCartItemFromNonSpa(cartItemId)
          updateCartCountNonSpa(selectRemainingCartItems(getState()).length)
        })
      })
      .catch((e) => {
        dispatch(setError(
          cartItemId,
          REFERRAL_CHECKOUT_ERROR_TYPE_REMOVE,
          window.local.t('checkout.error.delete_server_error')
        ))
        dispatch(updateCartItem(cartItemId, {
          collapse: false
        }))
      })
  }
}

const validateReferrals = () => {
  return (dispatch, getState) => {
    const cartItemsWithoutServiceOfferings = selectRemainingCartItems(getState())
      .filter((cartItem) => cartItem.matchingPrograms.length === 0)

    cartItemsWithoutServiceOfferings.forEach((cartItem) => {
      dispatch(setError(
        cartItem.id,
        REFERRAL_CHECKOUT_ERROR_TYPE_SERVICE_OFFERINGS,
        window.local.t('checkout.error.no_services_selected_error')
      ))
    })

    return cartItemsWithoutServiceOfferings.length === 0
  }
}

export const submitCart = (patient, analytics) => {
  return (dispatch, getState) => {
    if (dispatch(validateReferrals())) {
      const state = getState()

      dispatch(toggleKey('submitModalOpened', true))

      createReferrals(
        selectRemainingCartItems(state),
        patient,
        analytics,
        state.global.currentUser
      ).forEach((promise) => {
        promise.then(({ cartItemId, response, errorText }) => {
          if (errorText) {
            dispatch(toggleKey('submitModalOpened', false))
            dispatch(setError(cartItemId, REFERRAL_CHECKOUT_ERROR_TYPE_SERVER, errorText))
          } else {
            dispatch(updateCartItem(cartItemId, { submittedSuccess: true, errorObject: {} }))
          }
        })
      })
    }
  }
}
