import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import Button from '@components/Button'
import ExpandableTextArea from '@components/ExpandableTextArea'
import TextInput from '@components/TextInput'
import Select from '@components/Select'
import Spacing from '@components/Spacing'

import { ClientGoalsContext } from '../ClientGoals'

export const ClientGoalFormContext = createContext()

const { Provider } = ClientGoalFormContext

const ClientGoalForm = ({ children, formState, updateField, ...props }) => {
  const value = { formState, updateField }

  return (
    <Provider value={value}>
      <div className='ClientGoalForm' {...props}>
        {children}
      </div>
    </Provider>
  )
}

ClientGoalForm.propTypes = {
  children: PropTypes.node.isRequired,
  formState: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired
}

ClientGoalForm.Body = ({ loading, disabledFields = {}, ...props }) => {
  const { getString, getFilterCategories } = useContext(ClientGoalsContext)
  const filterCategories = getFilterCategories()
  const {
    formState: { data: formData, errors: formErrors },
    updateField
  } = useContext(ClientGoalFormContext)
  const handleSelectFilterCategoriesChange = selection => {
    // short term fix until we connect to backend
    updateField('filterCategories', selection)
    updateField('filterCategoryIds', selection.map(option => option.id))
    updateField('relatedNeeds', selection.map(option => option.name))
  }

  return (
    <section className='ClientGoalForm__Body'>
      <Spacing paddingBottom={2}>
        <Spacing paddingBottom={2}>
          <TextInput
            errors={formErrors.title}
            name='ClientGoal__Title'
            placeholder={getString('form.placeholder.generic')}
            onChange={(value) => { updateField('title', value) }}
            value={formData.title}
            label={getString('form.label.title')}
            showErrorMessages
            disabled={loading || disabledFields.title}
            required
          />
        </Spacing>
        <Select
          name='ClientGoal__SelectFilterCategories'
          onChange={handleSelectFilterCategoriesChange}
          options={filterCategories}
          errors={formErrors.filterCategories}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          label={getString('form.label.related_needs')}
          placeholder={getString('form.placeholder.related_needs')}
          value={formData.filterCategories}
          disabled={loading || disabledFields.selectFilterCategories}
          showDropdownIndicator
          required
          showErrorMessages
          isMulti
        />
        <Spacing paddingBottom={1}>
          <ExpandableTextArea
            name='ClientGoal__Description'
            placeholder={getString('form.placeholder.generic')}
            onChange={(value) => { updateField('description', value) }}
            value={formData.description}
            label={getString('form.label.description')}
            disabled={loading || disabledFields.description}
          />
        </Spacing>
      </Spacing>
    </section>
  )
}

ClientGoalForm.Body.propTypes = {
  loading: PropTypes.bool,
  disabledFields: PropTypes.shape({
    title: PropTypes.bool,
    selectFilterCategories: PropTypes.bool,
    description: PropTypes.bool
  })
}

ClientGoalForm.Submit = ({ loading, buttonText, ...props }) => {
  const { getString } = useContext(ClientGoalsContext)

  return (
    <Button htmlType='submit' type='primary' disabled={loading} dataTest='goal-form-submit'>
      {buttonText || getString('form.add_goal.trigger')}
    </Button>
  )
}

ClientGoalForm.Submit.propTypes = {
  buttonText: PropTypes.string.isRequired,
  loading: PropTypes.bool
}

export default ClientGoalForm
