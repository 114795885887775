import React, { PureComponent, Fragment } from 'react'
import Select from '@components/Select'
import Checkbox from '@components/Form/Checkbox'
import Story from '../Story'

const items = [
  { name: 'Alice', id: 1 },
  { name: 'Bob', id: 2 },
  { name: 'Charlie', id: 3 },
  { name: 'Dave', id: 4 },
  { name: 'Evan', id: 5 }
]

const filter = (query, items) => {
  return items.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
}

const loadOptions = (query, callback) => {
  setTimeout(() => {
    callback(filter(query, items))
  }, 200)
}

class Selects extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      showErrorMessages: false
    }

    this.toggleErrorMessages = this.toggleErrorMessages.bind(this)
  }

  toggleErrorMessages () {
    this.setState({ showErrorMessages: !this.state.showErrorMessages })
  }

  render () {
    return (
      <Fragment>
        <Story title='Basic Select' key='basic'>
          <Select
            dataTest={`select-test`}
            required
            options={items}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            label='Test Label'
            placeholder='Select a user'
            isMulti
          />
        </Story>

        <Story title='Select With Error' key='errors'>
          <Checkbox
            id='showErrorMessages'
            name='showErrorMessages'
            onChange={this.toggleErrorMessages}
            label='Show error messages below'
          />

          <Select
            dataTest={`select-test`}
            required
            options={items}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            label='Test Label'
            placeholder='Select a user'
            isMulti
            errors={['Error number 1', 'Error number 2']}
            showErrorMessages={this.state.showErrorMessages}
          />
        </Story>

        <Story title='Single Select' key='single'>
          <Select
            dataTest={`select-test`}
            required
            options={items}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            label='Test Label'
            placeholder='Select a user'
          />
        </Story>

        <Story title='Async Select' key='async'>
          <Select
            dataTest={`select-test`}
            required
            loadOptions={loadOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            label='Test Label'
            placeholder='Select a user'
            isMulti
          />
        </Story>
      </Fragment>
    )
  }
}

Selects.displayName = 'Selects'

export default Selects
