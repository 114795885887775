import { useMemo } from 'react'
import useStateReducer from '@hooks/useStateReducer'

const getInitialState = ({ client, closedLoopable, requiredFields }) => {
  const state = {
    showModal: false,
    modalPage: 'creation',
    showShareActions: false,
    isProcessing: false,
    formIsValid: false,
    submissionError: null,
    shareFormValid: false,
    followupFormValid: false,
    formSelections: {
      client: client,
      programs: null,
      consent: null
    },
    referralId: null,
    phones: null,
    email: null,
    followupForm: {
      intakeQuestions: {}
    },
    requiredFields,
    shareFormSelections: {
      memberLedCLRConsent: false,
      sendEmail: false,
      sendText: false,
      language: {
        label: 'English',
        value: 'en'
      },
      phoneNumber: null,
      email: '',
      downloadPDF: false,
      programs: null
    }
  }

  if (closedLoopable) {
    state.formSelections.closedLoop = true
  }

  return state
}

const getStateReducer = (props) => {
  const stateReducer = useStateReducer(getInitialState(props))

  return useMemo(() => stateReducer)
}

export default getStateReducer
