import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'

import useCurrentUser from '@hooks/useCurrentUser'

import Button from '@components/Button'
import ExpandableTextArea from '@components/ExpandableTextArea'
import Icon from '@components/Icon'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import { ClientGoalsContext } from '../ClientGoals'

const AddNoteForm = ({ goal, ...props }) => {
  var textareaRef
  const {
    getString,
    getAddNote,
    getUpdateGoalEntity
  } = useContext(ClientGoalsContext)
  const [content, setContent] = useState('')
  const { loading, addNote } = getAddNote(goal.id)
  const updateGoalEntity = getUpdateGoalEntity(goal)
  const currentUser = useCurrentUser()
  const AddNoteFormSubmitted = (e) => {
    e.preventDefault()
    if (content) {
      // create new note
      addNote({ content, goalId: goal.id })
      // update note count and attribution
      updateGoalEntity({
        noteCount: goal.noteCount + 1,
        noteTeamAttributions: uniq([
          ...goal.noteTeamAttributions,
          currentUser.team_name
        ])
      })
      // reset text field
      setContent('')
    } else {
      textareaRef.focus()
    }
  }

  return (
    <section className='AddNoteForm'>
      <form onSubmit={AddNoteFormSubmitted}>
        <Spacing paddingBottom={1}>
          <ExpandableTextArea
            name='AddNoteForm__Content'
            placeholder={getString('notes.form.placeholder')}
            onChange={(value) => { setContent(value) }}
            value={content}
            label={getString('notes.form.add_note')}
            refProp={tag => { textareaRef = tag }}
          />
        </Spacing>
        <Button htmlType='submit' type='expansion' disabled={loading}>
          <Text style='buttons' withIcon>
            <Icon iconKey='add_circle' size={16} />{' '}
            {getString('notes.form.add_note')}
          </Text>
        </Button>
      </form>
    </section>
  )
}

AddNoteForm.propTypes = {
  goal: PropTypes.object.isRequired
}

export default AddNoteForm
