import React from 'react'
import PropTypes from 'prop-types'
import { Marker } from '@react-google-maps/api'

import ResourceSiteInfoWindow from './ResourceSiteInfoWindow'

const ResourceSiteMarker = props => {
  const { onMarkerClick, resourceSite, showInfoWindow, ...passThruProps } = props
  const { id, address: { latitude: lat, longitude: lng } } = resourceSite

  return (
    <Marker
      position={{ lat, lng }}
      key={id}
      onClick={() => { onMarkerClick(id) }}
      {...passThruProps}
    >
      {showInfoWindow &&
        <ResourceSiteInfoWindow
          onCloseClick={() => { onMarkerClick(null) }}
          resourceSite={resourceSite}
        />}
    </Marker>
  )
}

ResourceSiteMarker.propTypes = {
  onMarkerClick: PropTypes.func.isRequired,
  resourceSite: PropTypes.object.isRequired,
  showInfoWindow: PropTypes.bool
}

export default ResourceSiteMarker
