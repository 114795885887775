import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'
import Text from '@components/Text'

import './FilterCategory.scss'

class FilterCategory extends Component {
  constructor (props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick (e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.onClick(this.props.category)
  }

  render () {
    const category = this.props.category

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <li className='FilterCategory' data-testid='FilterCategory'>
        <a
          href='#'
          onClick={this.onClick}
          className='FilterCategory__clickable'
          role='button'
        >
          <Text>{category.attributes.name}</Text>
          <Icon iconKey='keyboard_arrow_right' size={16} />
        </a>
      </li>
    )
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

FilterCategory.propTypes = {
  category: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  onClick: PropTypes.func.isRequired
}

FilterCategory.displayName = 'FilterCategory'

export default FilterCategory
