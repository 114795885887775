export const referralEncounterCancelReasons = [
  'no_contact',
  'not_eligible',
  'no_consent',
  'service_unavailable'
]

export const referralEncounterCancelReasonOptions = (local) => {
  return referralEncounterCancelReasons.map(reason => {
    return {
      value: reason,
      label: local.t(`referrals.encounter_modal.reason_not_provided.options.${reason}`)
    }
  })
}
