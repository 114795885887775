import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'
import Select from '@components/Select'
import Text from '@components/Text'

import { currentRadiusOption, guidedSearchRadiusOptions } from '@scenes/resourceSites/utils'

import css from '@config/theme/variables'
import './DistanceField.scss'

const localeFor = (i18nPath, options = {}) => window.local.t(
  `resource_sites.guided_search_form.distance_field.${i18nPath}`,
  options
)

class DistanceField extends React.PureComponent {
  customComponents () {
    const SingleValue = (props) => {
      const isExpanded = props.selectProps.menuIsOpen
      const iconKey = `keyboard_arrow_${isExpanded ? 'up' : 'down'}`

      return (
        <Text brandingEmphasis style='body-text' withIcon>
          <span>
            {localeFor('within')}
            <span className='DistanceField__radius'>
              {`${props.data.label}.`}
            </span>
          </span>
          <Icon iconKey={iconKey} size={20} />
        </Text>
      )
    }

    return { SingleValue }
  }

  customStyles () {
    const control = (base) => {
      return {
        ...base,
        'backgroundColor': 'none',
        'border': '0px',
        'caretColor': 'transparent'
      }
    }

    const option = (base) => {
      return {
        ...base,
        backgroundColor: 'inherit',
        // makes the disabled menu header the same color as the radius options
        color: css.textGray
      }
    }

    return { control, option }
  }

  options () {
    const radiusOptions = guidedSearchRadiusOptions()

    radiusOptions.unshift({
      isDisabled: true,
      value: 'menuLabel',
      label: localeFor('menu_header')
    })

    return radiusOptions
  }

  selectedOption () {
    return currentRadiusOption(this.props.radius.value)
  }

  render () {
    return (
      <div className='DistanceField' data-testid='DistanceField'>
        <Select
          components={this.customComponents()}
          hideClearIndicator
          inputId={'DistanceField__input'}
          maxMenuHeight={null}
          onChange={this.props.onChange}
          options={this.options()}
          required
          styles={this.customStyles()}
          value={this.selectedOption()}
        />
      </div>
    )
  }
}

DistanceField.propTypes = {
  onChange: PropTypes.func.isRequired,
  radius: PropTypes.object.isRequired
}

export default DistanceField
