import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Icon from '@components/Icon'
import Button from '@components/Button'
import Spacing from '@components/Spacing'
import { denormalizeRecords, getRecord } from '@selectors'
import {
  addResourceToCart,
  removeResourceFromCart,
  removeCartItemFromSpaUI
} from '@scenes/resourceSites/actions'
import { exposeRemoveCartItemForCarePlanDrawer } from '@services/updateNonSpa'
import { selectSearchMetadataObject } from '@scenes/resourceSites/ResourceSite/selectors'

import './AddRemoveCartActions.scss'

const RemoveCartItemAction = (props) => {
  useEffect(() => {
    exposeRemoveCartItemForCarePlanDrawer(props.cartItemId, props.removeCartItemFromSpaUI)
  }, [props.cartItemId, props.removeCartItemFromSpaUI])

  return (
    <div data-testid='RemoveCart__button' className='RemoveCartItemAction'>
      <Button type='secondary' onClick={() => props.removeResourceFromCart(props.cartItemId)}>
        {window.local.t('resource_sites.search_result.cart_actions.remove_item')}
        <Spacing marginLeft={0.5} tag='span'>
          <Icon size={12} iconKey='check_circle' verticalAlignment='middle' />
        </Spacing>
      </Button>
    </div>
  )
}

RemoveCartItemAction.propTypes = {
  cartItemId: PropTypes.number.isRequired,
  removeResourceFromCart: PropTypes.func.isRequired,
  removeCartItemFromSpaUI: PropTypes.func.isRequired
}

const AddCartItemAction = ({ addResourceToCart, resourceSiteId, metadata }) => {
  return (
    <div data-testid='AddCart__button' className='AddCartItemAction'>
      <Button
        type='secondary'
        onClick={() => addResourceToCart(resourceSiteId, metadata)}
      >
        {window.local.t('resource_sites.search_result.cart_actions.add_item')}
        <Spacing marginLeft={0.5} tag='span'>
          <Icon size={12} iconKey='add_circle' verticalAlignment='middle' />
        </Spacing>
      </Button>
    </div>
  )
}

AddCartItemAction.propTypes = {
  metadata: PropTypes.object.isRequired,
  addResourceToCart: PropTypes.func.isRequired,
  resourceSiteId: PropTypes.number.isRequired
}

const AddRemoveCartActions = props => {
  if (props.cartItem) {
    return (
      <RemoveCartItemAction
        cartItemId={props.cartItem.id}
        removeResourceFromCart={props.removeResourceFromCart}
        removeCartItemFromSpaUI={props.removeCartItemFromSpaUI}
      />
    )
  } else {
    return (
      <AddCartItemAction
        metadata={props.metadata}
        resourceSiteId={props.resourceSiteId}
        addResourceToCart={props.addResourceToCart}
      />
    )
  }
}

AddRemoveCartActions.propTypes = {
  addResourceToCart: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  removeResourceFromCart: PropTypes.func.isRequired,
  removeCartItemFromSpaUI: PropTypes.func.isRequired,
  resourceSiteId: PropTypes.number.isRequired,
  cartItem: PropTypes.object
}

const mapStateToProps = (state, props) => {
  const { resourceSiteId } = props
  const activeCart = denormalizeRecords(state,
    getRecord(state, 'cart', state.global.activeCartId)
  )
  const cartItem = activeCart.cartItems.find(cartItem => (
    cartItem.resourceSiteId === resourceSiteId
  ))

  return {
    metadata: {
      ...state.global.analyticsReferrer,
      ...selectSearchMetadataObject(state, resourceSiteId)
    },
    cartItem
  }
}

const mapDispatchToProps = {
  addResourceToCart,
  removeResourceFromCart,
  removeCartItemFromSpaUI
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRemoveCartActions)
