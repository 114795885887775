import axios from 'axios'
import snakeCase from 'lodash/snakeCase'
import mapKeys from 'lodash/mapKeys'
import apiConfig from '@config/apiConfig'

const UNAUTHENTICATED_REFERRALS_PATH = '/api/internal/unauthenticated/referrals'
const UNAUTHENTICATED_ANALYTICS_PATH = '/api/internal/unauthenticated/analytics/'
const UNAUTHENTICATED_SURVEY_RESULTS_PATH = '/api/internal/unauthenticated/guest_screenings/results/'

const submitTextForm = (formData, url) => {
  return axios(apiConfig.post(url, formData)).catch((err) => {
    return err.response
  })
}

const logError = error => { console.error('Error:', error) }

// TODO: Use lodash `once`, like in bootstrapService.js
const closedLoopReferrals = {
  get: (referralUuid) => {
    const url = `${UNAUTHENTICATED_REFERRALS_PATH}/${referralUuid}`

    return axios.get(url)
      .then(response => response.data)
      .catch(logError)
  },
  create: {
    notes: (referralUuid, formData) => {
      const url = `${UNAUTHENTICATED_REFERRALS_PATH}/${referralUuid}/closed_loop/notes`
      return axios(apiConfig.post(url, formData))
        .catch((err) => {
          return err.response
        })
    },
    referralEncounters: (referralUuid, formData) => {
      const url = `${UNAUTHENTICATED_REFERRALS_PATH}/${referralUuid}/closed_loop/referral_encounters`
      return axios(apiConfig.post(url, formData))
        .catch((err) => {
          return err.response
        })
    }
  }
}

const matchedResourceSites = {
  get: (surveyId, screeningClient) => {
    const url = `${UNAUTHENTICATED_SURVEY_RESULTS_PATH}${surveyId}?screening_client=${screeningClient}`

    return axios.get(url)
      .then(response => response.data)
      .catch(logError)
  }
}

const selfReferrals = {
  create: ({ companyId, patientParams, referralParams }) => {
    return axios(apiConfig.post(
      '/api/internal/unauthenticated/referrals/',
      {
        patient: normalizeParams(patientParams),
        referral: normalizeParams(referralParams),
        company_id: companyId
      }
    )).catch(error => {
      if (error.response) return error.response

      logError(error)
    })
  }
}

const analytics = {
  create: ({ modelName, eventType, properties }) => {
    const data = {
      model_name: modelName,
      event_type: eventType,
      properties
    }

    return axios(apiConfig.post(UNAUTHENTICATED_ANALYTICS_PATH, data)).catch(error => {
      if (error.response) return error.response

      logError(error)
    })
  }
}

const normalizeParams = (params) => {
  return mapKeys(params, (_, key) => snakeCase(key))
}

export default {
  submitTextForm,
  selfReferrals,
  closedLoopReferrals,
  matchedResourceSites,
  analytics
}
