import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'
import Text from '@components/Text'
import { setDownloadModalProps } from '@scenes/resourceSites/actions'

const localeFor = (i18nPath, options = {}) => window.local.t(
  `resource_sites.action_menu.${i18nPath}`,
  options
)

export const DownloadButton = props => {
  const {
    openDownloadModal,
    setDownloadModalProps,
    resourceSiteId,
    analyticsReferrer: { analytics_referrer_id: analyticsReferrerId },
    path,
    queryParams = {},
    ...passThruProps
  } = props

  const searchParams = { 'referrer_id': analyticsReferrerId, ...queryParams }

  const searchParamsString = new URLSearchParams(searchParams).toString()
  const pathWithParams = `${path}?${searchParamsString}`

  const handleClick = event => {
    event.preventDefault()
    setDownloadModalProps({ queryParams: searchParams, path })
    openDownloadModal(resourceSiteId)
  }

  return (
    <a
      href={pathWithParams}
      className='Button Button--toolbar'
      aria-label={localeFor('download_aria')}
      onClick={handleClick}
      data-testid='ResourceSiteActionMenu__DownloadLink'
      {...passThruProps}
    >
      <Text tag='span' withIcon style='sub-header-extra-small'>
        {localeFor('download')}
        <Icon iconKey='file_download' size={12} />
      </Text>
    </a>
  )
}

DownloadButton.propTypes = {
  openDownloadModal: PropTypes.func.isRequired,
  setDownloadModalProps: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  analyticsReferrer: PropTypes.object.isRequired,
  resourceSiteId: PropTypes.string.isRequired,
  queryParams: PropTypes.object
}

DownloadButton.defaultProps = {}

const mapStateToProps = (state, ownProps) => {
  // @TODO Remove this when we remove LegacyResourceSiteDownloadButton
  return {
    analyticsReferrer: ownProps.analyticsReferrer || state.global.analyticsReferrer
  }
}

const mapDispatchToProps = {
  setDownloadModalProps
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton)
