import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { NEED_STATUSES } from './constants'

import ToggleStatus from '@components/Forms/Elements/ToggleStatus'

import { ClientNeedsContext } from './ClientNeeds'

const ToggleNeedStatus = ({ need, ...props }) => {
  const { getClient, getUpdateNeed, getString } = useContext(ClientNeedsContext)
  const { updateNeed } = getUpdateNeed(false)
  const updateParams = { patientId: getClient().id }

  const handleStatusChange = (status) => {
    const updatedStatus = { status }
    const params = { ...updateParams, ...need, ...updatedStatus }

    updateNeed(need.id, params)
  }

  return (
    <ToggleStatus
      type='need'
      entity={need}
      statuses={NEED_STATUSES}
      label={getString('list.item.toggle_status')}
      onChange={handleStatusChange}
    />
  )
}

ToggleNeedStatus.propTypes = {
  need: PropTypes.object
}

export default ToggleNeedStatus
