import {
  IN_NETWORK_FILTER_PARAM,
  IN_NETWORK_FILTER_STATE_KEY,
  PREFERRED_PARTNERS_FILTER_PARAM,
  PREFERRED_PARTNERS_STATE_KEY,
  PREFERRED_PROVIDERS_FILTER_PARAM,
  PREFERRED_PROVIDERS_STATE_KEY,
  VERIFIED_FILTER_PARAM,
  VERIFIED_FILTER_STATE_KEY,
} from '@scenes/resourceSites/constants'

const paramMap = {
  [IN_NETWORK_FILTER_PARAM]: IN_NETWORK_FILTER_STATE_KEY,
  [PREFERRED_PARTNERS_FILTER_PARAM]: PREFERRED_PARTNERS_STATE_KEY,
  [PREFERRED_PROVIDERS_FILTER_PARAM]: PREFERRED_PROVIDERS_STATE_KEY,
  [VERIFIED_FILTER_PARAM]: VERIFIED_FILTER_STATE_KEY,
}

export const queryParamToStateKey = (queryParam) => (paramMap[queryParam])

export const preferredProviderOptions = (window) => ({
  name: PREFERRED_PROVIDERS_FILTER_PARAM,
  label: window.local.t('search_filters.tier_filters.preferred_providers.title'),
  description: window.local.t('search_filters.tier_filters.preferred_providers.context')
})

export const preferredPartnersOptions = (window) => ({
  name: PREFERRED_PARTNERS_FILTER_PARAM,
  label: window.local.t('search_filters.tier_filters.preferred_partners.title'),
  description: window.local.t('search_filters.tier_filters.preferred_partners.context')
})

export const inNetworkOptions = (window) => ({
  name: IN_NETWORK_FILTER_PARAM,
  label: window.local.t('search_filters.tier_filters.in_network.title'),
  description: window.local.t('search_filters.tier_filters.in_network.context')
})

export const verifiedOptions = (window) => ({
  name: VERIFIED_FILTER_PARAM,
  label: window.local.t('search_filters.tier_filters.verified_network.title'),
  description: window.local.t('search_filters.tier_filters.verified_network.context')
})

const preferredPartnerNetworkFilterOptions = (window) => ([
  preferredPartnersOptions(window),
  inNetworkOptions(window),
  verifiedOptions(window)
])

export default (window, settings = {}) => {
  if (settings.teamUsesCoordinate && settings.contractedNetwork) {
    return preferredPartnerNetworkFilterOptions(window)
  } else if (settings.teamUsesCoordinate) {
    return [inNetworkOptions(window)]
  } else {
    return [preferredProviderOptions(window)]
  }
}
