import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'
import Button from '@components/Button'
import Text from '@components/Text'

const ExpandButton = (props) => {
  // onClick handled by MUI when insideCard is true
  const onClick = props.insideCard ? undefined : props.toggleExpanded

  const text = props.expanded ? props.collapseText : props.expandText

  return (
    <Button type='expansion' onClick={onClick} disabled={props.isDisabled} data-testid='ExpandButton'>
      <Text style='buttons' withIcon>
        {text}
        <Icon iconKey='keyboard_arrow_down' size={12} />
      </Text>
    </Button>
  )
}

ExpandButton.propTypes = {
  collapseText: PropTypes.string.isRequired,
  expandText: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  insideCard: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

export default ExpandButton
