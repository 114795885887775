import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQuery } from '@services/baseApi'

export const internalAPI = createApi({
  reducerPath: 'internalAPI',
  tagTypes: ['FormSubmission'],
  baseQuery,
  endpoints: (builder) => ({
    createCartFeedback: builder.mutation({
      query: ({ cartID, requestBody }) => ({
        url: `/api/internal/cart/${cartID}/feedback`,
        method: 'POST',
        body: requestBody
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'FormSubmission', id }],
      transformResponse: response => response.data
    }),
    getCart: builder.query({
      query: (id) => `/api/internal/cart?patient_id=${id}&included[]=patient.phones`
    })
  })
})

export const { useCreateCartFeedbackMutation, useGetCartQuery } = internalAPI
