import React, { useContext } from 'react'
import { ClientDetailsContext } from '../ClientDetails'

const EditClientButton = ({ ...props }) => {
  const { client: {
    canEdit,
    links
  }, getString } = useContext(ClientDetailsContext)

  return (canEdit && (
    <a className='Button Button--primary' href={links.edit} {...props}>
      {getString('buttons.edit_client')}
    </a>
  ))
}

export default EditClientButton
