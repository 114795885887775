import React, { Component } from 'react'
import PropTypes from 'prop-types'
import last from 'lodash/last'
import without from 'lodash/without'

import Text from '@components/Text'
import Icon from '@components/Icon'
import Spacing from '@components/Spacing'

import GuidedSearchResult from '@components/SearchFilters/components/GuidedSearchResult'
import ResourceProgram from '@components/SearchFilters/components/ResourceProgram'
import FilterCategory from '@components/SearchFilters/components/FilterCategory'

import './ResourceProgramNavigator.scss'

class ResourceProgramNavigator extends Component {
  constructor () {
    super()

    this.state = {
      selectionHistory: []
    }

    this.onFilterClick = this.onFilterClick.bind(this)
    this.onResourceProgramClick = this.onResourceProgramClick.bind(this)
    this.goBack = this.goBack.bind(this)
    this.onApplyAll = this.onApplyAll.bind(this)
  }

  get showCategories () {
    return this.state.selectionHistory.length === 0
  }

  get resourceProgramIds () {
    const currentCategory = this.currentSelection

    if (currentCategory.type === 'filter_category') {
      return this._filterCategoryChildrenIds()
    } else {
      return currentCategory.attributes.childIds
    }
  }

  get resourcePrograms () {
    if (this.state.selectionHistory.length === 0) return []

    const { selectedPrograms } = this.props
    const resourceProgramIds = this.resourceProgramIds

    return this.props.resourcePrograms
      .filter(({ attributes: { id } }) => resourceProgramIds.includes(id))
      .filter(({ attributes: { id } }) => !selectedPrograms.find(selection => selection.attributes.id === id))
  }

  get currentSelection () {
    return last(this.state.selectionHistory)
  }

  get currentSelectionHasChildren () {
    return (
      this.currentSelection &&
      this.currentSelection.attributes.childIds &&
      this.currentSelection.attributes.childIds.length > 0
    )
  }

  _filterCategoryChildrenIds () {
    return this.props.resourcePrograms
      .filter(program => this._isCurrentCategoryProgramId(program.attributes.id))
      .filter(program => this._isFirstLevelAncestorForCurrentCategory(program))
      .map(program => program.attributes.id)
  }

  _isCurrentCategoryProgramId (id) {
    const currentCategory = this.currentSelection

    return currentCategory.attributes.resourceProgramIds.includes(id)
  }

  _isFirstLevelAncestorForCurrentCategory (program) {
    return !program.attributes.parentIds.some(id => this._isCurrentCategoryProgramId(id))
  }

  onFilterClick (category) {
    this.pushHistory(category)
  }

  allSelected (object) {
    const unselected = without(this.resourcePrograms, object)

    return unselected.length === 0
  }

  pushHistory (object) {
    this.setState({
      selectionHistory: [...this.state.selectionHistory, object]
    })
  }

  onResourceProgramClick (resourceProgram) {
    if (resourceProgram.attributes.childIds.length > 0) {
      this.pushHistory(resourceProgram)
    } else {
      this.props.onProgramSelect(resourceProgram)
      if (this.allSelected(resourceProgram)) {
        this.goBack()
      }
    }
  }

  goBack (e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    this.setState({
      selectionHistory: this.state.selectionHistory.slice(0, -1)
    })
  }

  onApplyAll () {
    this.props.onProgramSelect(this.currentSelection)
    this.goBack()
  }

  renderFilterCategoryTree () {
    const categories = this.props.filterCategories.map((category, i) => (
      <FilterCategory key={i} category={category} onClick={this.onFilterClick} />
    ))

    return (
      <nav>
        <header className='ResourceProgramNavigator__header'>
          <Text style='body-text-emphasis'>
            {window.local.t('search_filters.resource_program_navigator.service_offerings')}
          </Text>
        </header>

        <ul>{categories}</ul>
      </nav>
    )
  }

  renderResourceProgramTree () {
    const programs = this.resourcePrograms.map((program, i) => (
      <GuidedSearchResult key={i} result={program} onClick={this.onResourceProgramClick}>
        <ResourceProgram program={program} />
      </GuidedSearchResult>
    ))

    return (
      <ul>
        {this.renderBackButton()}
        {programs}
        {this.currentSelectionHasChildren && this.renderApplyAll()}
      </ul>
    )
  }

  renderBackButton () {
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <li className='ResourceProgramNavigator__back'>
        <a
          href='#'
          onClick={this.goBack}
          className='ResourceProgramNavigator__back__clickable'
          role='button'
        >
          <Spacing marginRight={0.5} tag='span'>
            <Icon iconKey='keyboard_arrow_left' size={16} verticalAlignment='middle' />
          </Spacing>

          <Text>
            {window.local.t('search_filters.resource_program_navigator.back')}
          </Text>
        </a>
      </li>
    )
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }

  renderApplyAll () {
    return (
      <GuidedSearchResult result={this.currentSelection} onClick={this.onApplyAll}>
        <Text>
          {window.local.t(
            'search_filters.resource_program_navigator.apply_all',
            { selection_name: this.currentSelection.attributes.name }
          )}
        </Text>
      </GuidedSearchResult>
    )
  }

  render () {
    return (
      <div
        className='ResourceProgramNavigator'
        data-testid='ResourceProgramNavigator'
      >
        {this.showCategories && this.renderFilterCategoryTree()}

        {!this.showCategories && this.renderResourceProgramTree()}
      </div>
    )
  }
}

ResourceProgramNavigator.propTypes = {
  filterCategories: PropTypes.array.isRequired,
  onProgramSelect: PropTypes.func.isRequired,
  resourcePrograms: PropTypes.array.isRequired,
  selectedPrograms: PropTypes.array.isRequired
}

ResourceProgramNavigator.defaultProps = {
  filterCategories: [],
  onProgramSelect: () => {},
  resourcePrograms: [],
  selectedPrograms: []
}

ResourceProgramNavigator.displayName = 'ResourceProgramNavigator'

export default ResourceProgramNavigator
