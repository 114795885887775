import { startCase } from 'lodash'

import { formatPhoneNumber, formatDate, formatName, formatAddress } from '@utils/formatterUtil'
import { formatIdentifiers, sortIdentifiers, formatAssignedUsers } from './clientDetailsUtil'
import { localeStringGenerator } from '@utils/localeUtil'

const formattedAddress = (address) => () => {
  if (!address) return ''

  return formatAddress(address)
}

const formattedAssignedUsers = (assignedUsers, team) => () => {
  return formatAssignedUsers(assignedUsers, team)
}

const formattedDateOfBirth = (client) => () => {
  if (!client) return ''

  return formatDate(client.dateOfBirth)
}

const formattedGender = (client) => () => {
  if (!client) return ''

  return startCase(client.gender)
}

const formattedName = (client) => () => {
  if (!client) return ''

  return formatName(client)
}

const formattedPhoneNumber = (phone) => () => {
  if (!phone) return ''

  return formatPhoneNumber(phone)
}

const formattedIdentifiers = (client, identifiers) => () => {
  // External ID should not be grouped with other identifiers (Medicaid, etc)
  // Instead, it will be displayed in its own section
  const formattedIdentifiers = formatIdentifiers(client, identifiers)
    .filter(id => id.type !== 'External')

  return sortIdentifiers(formattedIdentifiers)
}

const createPropGetters = (client, {
  address,
  assignedUsers,
  assignedUserTeam,
  identifiers,
  interventions,
  phone,
  populations
}) => ({
  getAssignedUsers: () => (assignedUsers || []),
  getString: localeStringGenerator('patients'),
  getFormattedAddress: formattedAddress(address),
  getFormattedAssignedUsers: formattedAssignedUsers(assignedUsers, assignedUserTeam),
  getFormattedDateOfBirth: formattedDateOfBirth(client),
  getFormattedGender: formattedGender(client),
  getFormattedIdentifiers: formattedIdentifiers(client, identifiers),
  getFormattedName: formattedName(client),
  getFormattedPhoneNumber: formattedPhoneNumber(phone),
  getInterventions: () => (interventions || []),
  getPopulations: () => (populations || [])
})

export default createPropGetters
