import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SelectWithMenu from '@components/SelectWithMenu'
import Alert from '@components/Alert'
import Text from '@components/Text'
import Spacing from '@components/Spacing'
import Button from '@components/Button'
import './ReferralAssignedUsers.scss'
import debounce from 'lodash/debounce'
import { search } from '@services/usersService'
import { loadTeams, toggleKey, updateRecipients, submitAssignedUsers, setAlert } from '../actions'
import {
  denormalizeRecords,
  getCollectionFromRelationship,
  getAssociation,
  getRecord
} from '@selectors'
import { convertObjectToCamelCase } from '@utils/apiUtil'

class ReferralAssignedUsers extends PureComponent {
  constructor (props) {
    super(props)

    props.updateRecipients(props.assignedUsers)

    this.onToggleClick = this.onToggleClick.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.saveReferralAssignedUsers = this.saveReferralAssignedUsers.bind(this)
    this.renderAlert = this.renderAlert.bind(this)
    this.renderReferralAssignedUsers = this.renderReferralAssignedUsers.bind(this)
    this.loadOptions = debounce(
      this.loadOptions.bind(this),
      500
    )
    this.loadSelectionMenuOptions = this.loadSelectionMenuOptions.bind(this)
  }

  loadSelectionMenuOptions (teamValue, callback) {
    search({ patientAccessing: true, usesCoordinate: true, teamId: teamValue }).then((users) => {
      callback(
        convertObjectToCamelCase(users)
      )
    })
  }

  loadOptions (query, callback) {
    search({ patientAccessing: true, usesCoordinate: true, query }).then((users) => {
      callback(
        convertObjectToCamelCase(users)
      )
    })
  }

  saveReferralAssignedUsers () {
    this.props.submitAssignedUsers(this.props.referralId)
  }

  onToggleClick () {
    this.props.loadTeams()
    this.props.setAlert('assignedUsers', null)
    this.props.toggleKey('showEditAssignedUsersView', true)
  }

  onCancel () {
    this.props.toggleKey('showEditAssignedUsersView', false)
  }

  renderAlert () {
    if (this.props.alert) {
      return (
        <Alert
          inline
          type={this.props.alert.type}
          title={this.props.alert.text} />
      )
    }
  }

  renderReferralAssignedUsers () {
    if (this.props.canAssignUsers || this.props.assignedUsers.length) {
      return <Text style='body-text' tag='span'>
        {this.props.assignedUsers.map(user => user.fullName).join(', ')}
      </Text>
    } else {
      return <Text style='body-text-secondary' tag='span'>
        {window.local.t('referrals.show.assigned_users.none')}
      </Text>
    }
  }

  renderToggle () {
    if (this.props.showEditAssignedUsersView && this.props.hasFetchedTeams) {
      return (
        <div className='ReferralAssignedUsers__controls'>
          <Text style='body-text-emphasis' tag='span'>
            {window.local.t('referrals.assigned_users')}
          </Text>
          <span className='ReferralAssignedUsers__select-menu'>
            <SelectWithMenu
              isMulti
              id='referral_assigned_user_ids'
              onChange={(value, event) => this.props.updateRecipients(value)}
              value={this.props.assignedUsers}
              getOptionLabel={(option) => option.fullName}
              getOptionValue={(option) => option.id}
              loadOptions={this.loadOptions}
              selectionMenuTitle={window.local.t('referrals.show.team_selection_menu_title')}
              selectionMenu={this.props.teams}
              loadSelectionMenuOptions={this.loadSelectionMenuOptions}
              getMenuLabel={(option) => option.name}
              getMenuValue={(option) => option.id}
              placeholder={window.local.t('referrals.show.select_placeholder')} />
          </span>
          {this.renderAlert()}
          <Spacing marginLeft={4} tag='span'>
            <Button type='tertiary' onClick={this.saveReferralAssignedUsers}>
              {window.local.t('referrals.edit_recipients.save')}
            </Button>
          </Spacing>
          <Spacing marginLeft={2} tag='span'>
            <Button type='tertiary' onClick={this.onCancel}>
              {window.local.t('referrals.edit_recipients.cancel')}
            </Button>
          </Spacing>
        </div>
      )
    } else {
      return (
        <Fragment>
          <Text style='body-text-emphasis' tag='span'>
            {window.local.t('referrals.assigned_users')}
          </Text>
          {this.renderReferralAssignedUsers()}
          <Spacing marginX={4} tag='span'>
            <Button type='tertiary' onClick={this.onToggleClick}>
              {window.local.t('referrals.edit_recipients.toggle')}
            </Button>
          </Spacing>
        </Fragment>
      )
    }
  }

  render () {
    if (this.props.referral.canAssignUsers) {
      return (
        <div className='ReferralAssignedUsers__container' data-test='recipients'>
          {this.renderToggle()}
        </div>
      )
    } else {
      return null
    }
  }
}

export const usersType = PropTypes.arrayOf(
  PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number
  })
)

ReferralAssignedUsers.propTypes = {
  alert: PropTypes.object,
  assignedUsers: usersType.isRequired,
  canAssignUsers: PropTypes.bool,
  hasFetchedTeams: PropTypes.bool.isRequired,
  loadTeams: PropTypes.func.isRequired,
  referral: PropTypes.object.isRequired,
  referralId: PropTypes.string.isRequired,
  setAlert: PropTypes.func.isRequired,
  showEditAssignedUsersView: PropTypes.bool,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  submitAssignedUsers: PropTypes.func.isRequired,
  toggleKey: PropTypes.func.isRequired,
  updateRecipients: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const assignedUsers = denormalizeRecords(state, getAssociation(state, referral, 'assignedUsers'))
  const resourceSite = getAssociation(state, referral, 'resourceSite')

  const currentUser = getRecord(state, 'user', state.global.currentUser.id)
  const currentUserTeams = getCollectionFromRelationship({
    association: 'company',
    id: currentUser.relationships.company.id,
    state,
    type: 'team'
  }).filter(team => team.usesCoordinate)

  return {
    alert: state.referralView.alert.assignedUsers,
    assignedUsers,
    hasFetchedTeams: state.referralView.hasFetchedTeams,
    recipients: state.referralView.recipients,
    referral,
    resourceSite,
    showEditAssignedUsersView: state.referralView.showEditAssignedUsersView,
    teams: currentUserTeams
  }
}

const mapDispatchToProps = {
  loadTeams,
  toggleKey,
  updateRecipients,
  submitAssignedUsers,
  setAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralAssignedUsers)
