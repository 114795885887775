import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { toggleKey } from '@features/referralCheckout'
import ReferAnotherPatientForm from '../ReferAnotherPatientForm'
import PatientSidebarDetail from '../PatientSidebarDetail'
import LegacyModal from '../LegacyModal'

import LegacyButton from '@components/legacy/LegacyButton'
import Icon from '@components/Icon'
import Spacing from '@components/Spacing'

import './PatientReferralSidebar.scss'

export class PatientReferralSidebar extends Component {
  constructor (props) {
    super(props)

    this.redirectToCartPathWithNewPatient = this.redirectToCartPathWithNewPatient.bind(this)
    this.openPrintableCart = this.openPrintableCart.bind(this)
    this.renderSidebarContent = this.renderSidebarContent.bind(this)
  }

  patientPath (patientId) {
    return `${this.props.patientsPath}/${patientId}`
  }

  disablePrint () {
    return this.props.showReferAnotherPatientForm
  }

  redirectToCartPathWithNewPatient () {
    window.location.href = `${this.props.cartPath}`
  }

  openPrintableCart () {
    window.open(this.props.printableCartPath, '_blank')
  }

  renderPatientSidebarDetail () {
    return (
      <PatientSidebarDetail
        patient={this.props.patient}
        subsection={
          <section className='page-sidebar-panel__subsection'>
            <p className='page-sidebar-panel__subsection-text'>
              {window.local.t('checkout.refer_another_client.prompt')}
            </p>

            <button
              className='PatientReferralSidebar__switch-client'
              type='button'
              onClick={() => this.props.toggleKey('showReferAnotherPatientForm', true)}
            >
              {window.local.t('checkout.refer_another_client.link')}
            </button>
          </section>
        }
      />
    )
  }

  renderReferAnotherPatientForm () {
    const { patientsOptionsPath, toggleKey } = this.props
    return (
      <ReferAnotherPatientForm
        loadPatientsPath={patientsOptionsPath}
        hideReferAnotherPatientForm={() => toggleKey('showReferAnotherPatientForm', false)}
        confirmUpdatePatient={this.redirectToCartPathWithNewPatient}
      />
    )
  }

  renderSidebarContent (isMobile) {
    // We hide the sidebar using this class rather than removing it from the DOM
    // so that we can keep the sidebar the same height even when we flip between
    // the detailed view and the refer another patient view. This styling will
    // keep the sidebar the height of whichever view is taller.
    const sidebarClassNames = (referAnotherPatient) => {
      if (referAnotherPatient) {
        return {
          detailClassName: 'PatientReferralSidebar__hidden-content',
          referAnotherClassName: 'PatientReferralSidebar__shown-content'
        }
      } else {
        return {
          detailClassName: 'PatientReferralSidebar__shown-content',
          referAnotherClassName: 'PatientReferralSidebar__hidden-content'
        }
      }
    }

    const { detailClassName, referAnotherClassName } = sidebarClassNames(this.props.showReferAnotherPatientForm)
    const content = (
      <div className='PatientReferralSidebar__content'>
        <div className={detailClassName}>{this.renderPatientSidebarDetail()}</div>
        <div className={referAnotherClassName}>{this.renderReferAnotherPatientForm()}</div>
      </div>
    )

    if (isMobile) {
      return content
    } else {
      return (
        <div>
          <Spacing marginBottom={4}>{content}</Spacing>

          <footer role='Patient-footer' data-test='checkout-client-footer' className='page-sidebar__footer'>
            <div className='PatientReferralSidebar__button-spacing'>
              {this.props.createReferralsButton}
            </div>
            <div className='PatientReferralSidebar__button-spacing'>
              <LegacyButton
                disabled={this.disablePrint()}
                onClick={this.openPrintableCart}
                variant='outlined'
              >
                {window.local.t('checkout.print_button')}
                <Icon className='mi--push-left' iconKey='print' size={12} />
              </LegacyButton>
            </div>
          </footer>
        </div>
      )
    }
  }

  render () {
    if (this.props.isMobile) {
      if (this.props.showSidebarModalOnMobile) {
        return (
          <LegacyModal onClose={() => this.props.toggleKey('showSidebarModalOnMobile', false)}>
            {this.renderSidebarContent(this.props.isMobile)}
          </LegacyModal>
        )
      } else {
        return (
          <button
            type='button'
            onClick={() => this.props.toggleKey('showSidebarModalOnMobile', true)}
            className='PatientReferralSidebar__show-mobile-modal orbital-button'
            style={{
            }}
          >
            <Icon className='orbital-button__icon' iconKey='assignment_ind' size={24} />
          </button>
        )
      }
    } else {
      return (
        <aside
          data-test='checkout-client'
          data-testid='checkout-client'
          data-behavior='checkout-client'
          data-url={this.patientPath(this.props.patient.id)}
        >
          <div className='sticky'>
            {this.renderSidebarContent(this.props.isMobile)}
          </div>
        </aside>
      )
    }
  }
}

PatientReferralSidebar.propTypes = {
  patientsOptionsPath: PropTypes.string.isRequired,
  patientsPath: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
  cartPath: PropTypes.string.isRequired,
  printableCartPath: PropTypes.string.isRequired,
  createReferralsButton: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
  showReferAnotherPatientForm: PropTypes.bool,
  toggleKey: PropTypes.func.isRequired,
  showSidebarModalOnMobile: PropTypes.bool
}

const mapStateToProps = state => ({
  showReferAnotherPatientForm: state.referralCheckout.showReferAnotherPatientForm,
  showSidebarModalOnMobile: state.referralCheckout.showSidebarModalOnMobile
})

const mapDispatchToProps = {
  toggleKey
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientReferralSidebar)
