import React, { Fragment, PureComponent } from 'react'
import Stamp from '@components/Stamp'
import StampWithIcon from '@components/StampWithIcon'
import StampWithIconAndToolTip from '@components/StampWithIconAndToolTip'
import Story from '../Story'

class Stamps extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Red Stamp'>
          <Stamp text='Red stamp' color='red' />
        </Story>
        <Story title='Orange Stamp'>
          <Stamp text='Orange stamp' color='orange' />
        </Story>
        <Story title='Green Stamp'>
          <Stamp text='Green stamp' color='green' />
        </Story>
        <Story title='Grey Stamp'>
          <Stamp text='Grey stamp' color='grey' />
        </Story>
        <Story title='Blue Stamp'>
          <Stamp text='Blue stamp' color='blue' />
        </Story>
        <Story title='Blue-05 Stamp'>
          <Stamp text='Blue-05 stamp' color='blue-05' />
        </Story>
        <Story title='Stamp With Icon'>
          <StampWithIcon text='Stamp With Icon' color='orange'  iconKey='info' size={12}/>
        </Story> 
        <Story title='Stamp With Icon and ToolTip'>
          <StampWithIconAndToolTip text='Stamp With Icon and ToolTip' color='blue-05'  iconKey='info' size={12} tooltipContent='tooltip'/>
        </Story> 
      </Fragment>
    )
  }
}

Stamps.displayName = 'Stamps'

export default Stamps
