const getAllReferralEncountersForResourceProgramAssignment = (resourceProgramAssignment, referralEncounters) => {
  return referralEncounters.reduce((accum, referralEncounter) => {
    referralEncounter.serviceDetails.forEach(serviceDetail => {
      if (serviceDetail.referralResourceProgramAssignmentId === resourceProgramAssignment.id) {
        accum.push(referralEncounter)
      }
    })

    return accum
  }, [])
}

const getMostRecentEncounterForResourceProgramAssignment = (resourceProgramAssignment, referralEncounters) => {
  const allReferralEncountersForResourceProgram =
    getAllReferralEncountersForResourceProgramAssignment(resourceProgramAssignment, referralEncounters)

  return sortReferralEncountersByDates(allReferralEncountersForResourceProgram)[0]
}

export const mostRecentEncounterPerResourceProgram = (resourceProgramAssignments, referralEncounters) => {
  return resourceProgramAssignments.reduce((accum, resourceProgramAssignment) => {
    accum[resourceProgramAssignment.id] =
      getMostRecentEncounterForResourceProgramAssignment(resourceProgramAssignment, referralEncounters)

    return accum
  }, {})
}

export const sortReferralEncountersByDates = (referralEncounters) => {
  return referralEncounters
    .sort((leftReferralEncounter, rightReferralEncounter) => (
      new Date(rightReferralEncounter.createdAt) - new Date(leftReferralEncounter.createdAt)
    ))
    .sort((leftReferralEncounter, rightReferralEncounter) => (
      new Date(rightReferralEncounter.dateOfAction) - new Date(leftReferralEncounter.dateOfAction)
    ))
}
