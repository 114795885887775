import React from 'react'
import PropTypes from 'prop-types'

import './Datum.scss'

export default class Datum extends React.PureComponent {
  render () {
    const { label, value } = this.props

    return (
      <dl className='Datum'>
        <dt className='Datum__label'>{label.toUpperCase()}</dt>
        <dd className='Datum__value'>{value}</dd>
      </dl>
    )
  }
}

Datum.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired
}
