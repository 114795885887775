import axios from 'axios'
import apiConfig from '@config/apiConfig'

const requestToUpdate = (formData, id) => {
  return axios(apiConfig.post(`/api/internal/resource_sites/${id}/request_to_update`, formData)).catch((err) => {
    console.error(err)
  })
}

export default {
  requestToUpdate
}
