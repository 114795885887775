const metaTag = document.querySelector('meta[name="csrf-token"]')

export const headers = {
  'X-CSRF-Token': metaTag && metaTag.getAttribute('content'),
  'Accept': 'application/json'
}

const get = (url) => {
  return {
    method: 'get',
    url: url,
    headers
  }
}

const post = (url, data) => {
  return {
    method: 'POST',
    url: url,
    data: data,
    headers
  }
}

const put = (url, data) => {
  return {
    method: 'PUT',
    url: url,
    data: data,
    headers
  }
}

const destroy = (url, data) => {
  return {
    method: 'DELETE',
    url: url,
    data: data,
    headers
  }
}

export default {
  get,
  post,
  put,
  destroy
}
