import React from 'react'
import PropTypes from 'prop-types'
import Pagination from 'react-js-pagination'

import './PaginationBar.scss'
function PaginationBar (props) {
  return (
    <Pagination
      activePage={props.activePage}
      itemsCountPerPage={props.itemsCountPerPage}
      onChange={props.onChange}
      totalItemsCount={props.totalItemsCount}
      innerClass={'PaginationBar'}
      itemClass={'PaginationBar__item'}
      linkClass={'PaginationBar__link'}
      activeLinkClass={'PaginationBar__link--active'}
    />
  )
}

PaginationBar.defaultProps = {
  itemsCountPerPage: 10
}

PaginationBar.propTypes = {
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  totalItemsCount: PropTypes.number.isRequired
}

export default PaginationBar
