import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import Grid from '@material-ui/core/Grid'

import './GuidedSearchEnhancedInput.scss'

class GuidedSearchEnhancedInput extends React.Component {
  constructor (props) {
    super(props)

    this.input = React.createRef()
    this.inputId = props.id || `guided-search-enhanced-input-id-${uuid()}`

    this.onChange = this.onChange.bind(this)
    this.onWrapperClick = this.onWrapperClick.bind(this)
  }

  onChange (e) {
    this.props.onChange(e.target.value)
  }

  onWrapperClick () {
    this.focus()
  }

  focus () {
    this.input.current.focus()
  }

  get value () {
    return this.input.current.value
  }

  renderPlaceholder () {
    const { value, children, placeholder } = this.props

    if (value.length || children.length) return

    return (
      <span className='GuidedSearchEnhancedInput__placeholder'>
        {placeholder}
      </span>
    )
  }

  render () {
    const { value } = this.props
    const width = value.length ? '318px' : '1px'

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div
        className='GuidedSearchEnhancedInput'
        onClick={this.onWrapperClick}
        data-testid='GuidedSearchEnhancedInput'
      >
        <Grid container spacing={1} alignItems='center'>
          {this.props.children}

          <Grid item>
            <input
              data-test={this.props['data-test']}
              data-testid='GuidedSearchEnhancedInput__input'
              id={this.inputId}
              autoComplete='off'
              onChange={this.onChange}
              onFocus={this.props.onFocus}
              onKeyDown={this.props.onKeyDown}
              ref={this.input}
              style={{ width }}
              className='GuidedSearchEnhancedInput__input'
              value={value}
              type='text'
            />
          </Grid>
        </Grid>

        {this.props.placeholder && this.renderPlaceholder()}
      </div>
    )
    /* eslint-enable jsx-a11y/click-events-have-key-events */
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

GuidedSearchEnhancedInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  'data-test': PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
}

GuidedSearchEnhancedInput.displayName = 'GuidedSearchEnhancedInput'

export default GuidedSearchEnhancedInput
