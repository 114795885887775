import axios from 'axios'
import apiConfig from '@config/apiConfig'

const shareableUsers = () => {
  return axios(apiConfig.get('options/authorized_sharable_users')).catch((err) => {
    console.error(err)
  })
}

const share = (formData, id) => {
  return axios(apiConfig.post(`/api/internal/resource_sites/${id}/shares`, formData)).catch((err) => {
    console.error(err)
  })
}

export default {
  share,
  shareableUsers
}
