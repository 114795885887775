import PropTypes from 'prop-types'

import useTitle from '@hooks/useTitle'

const PageTitle = ({ title, children }) => {
  useTitle(title)

  return children
}

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}

export default PageTitle
