import React from 'react'
import PropTypes from 'prop-types'

import '../Alert/Alert.scss'

import Alert from '@components/Alert'
import Checkbox from '@components/Form/Checkbox'
import Button from '@components/Button'
import Form from '@components/Form'
import Spacing from '@components/Spacing'

const AlertWithCheckbox = (props) => {
  const {
    title,
    inline,
    body,
    type,
    id,
    label,
    onChange,
    onSubmit,
    name,
    buttonDisabled,
    isVisible,
    defaultChecked
  } = props

  const checkVisbility = () => {
    if (isVisible === false) {
      return 'none'
    }
  }

  return (
    <div style={{ display: checkVisbility() }}>
      <Alert title={title} inline={inline} body={body} type={type}>
        <Spacing marginTop={3} marginLeft={4}>
          <Form onSubmit={onSubmit}>
            <Checkbox
              id={id}
              label={label}
              onChange={onChange}
              name={name}
              defaultChecked={defaultChecked}
            />

            <Spacing marginTop={2}>
              <Button
                data-test={`confirm-${id}`}
                children='Confirm'
                disabled={buttonDisabled}
                htmlType='submit'
                type='primaryDark'
                inheritWidth
              />
            </Spacing>
          </Form>
        </Spacing>
      </Alert>
    </div>
  )
}

AlertWithCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.node,
  inline: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  body: PropTypes.node,
  type: PropTypes.oneOf([
    'success',
    'error',
    'warning'
  ]),
  buttonDisabled: PropTypes.bool
}

export default AlertWithCheckbox
