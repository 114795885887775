import { getQueryParams } from '@utils/browserUtil'
import {
  DEFAULT_SEARCH_FORM_VALUES,
  IN_NETWORK_FILTER_STATE_KEY,
  PREFERRED_PARTNERS_STATE_KEY,
  PREFERRED_PROVIDERS_STATE_KEY,
  VERIFIED_FILTER_STATE_KEY,
  SEARCH_QUERY_KEY_MAPPING
} from '@scenes/resourceSites/constants'

// This function derives the default value
// either from query params
// or falls back to internal defaults
const getDefaultValue = (reduxKey) => {
  const queryParams = getQueryParams()
  const queryParamKey = SEARCH_QUERY_KEY_MAPPING[reduxKey]
  const queryParamValue = queryParams[queryParamKey]

  return sanitizedQueryParamValue(queryParamValue) || DEFAULT_SEARCH_FORM_VALUES[reduxKey]
}

// Converts 'true' and 'false' strings to their respective boolean values
const sanitizedQueryParamValue = (value) => {
  if (typeof value !== 'string') return value

  if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  } else {
    return value
  }
}

export const getInitialState = () => {
  return {
    createModalOpen: false,
    downloadModal: {
      open: false,
      resourceSiteId: null
    },
    editModal: {
      editModalOpen: false,
      resourceSiteId: null
    },
    loadingSearchResults: false,
    loadingSearchParams: false,
    search: {
      clientId: {
        value: getDefaultValue('clientId')
      },
      resourceKind: {
        value: getDefaultValue('resourceKind')
      },
      geofilter: {
        errors: [],
        value: getDefaultValue('geofilter')
      },
      geofilterInferred: {
        value: getDefaultValue('geofilterInferred')
      },
      inputValue: {
        value: getDefaultValue('inputValue')
      },
      matchType: {
        value: getDefaultValue('matchType')
      },
      radius: {
        value: getDefaultValue('radius')
      },
      selectedProgramIds: {
        value: getDefaultValue('selectedProgramIds')
      },
      selectedEligibilityIds: {
        value: getDefaultValue('selectedEligibilityIds')
      },
      [IN_NETWORK_FILTER_STATE_KEY]: {
        value: getDefaultValue(IN_NETWORK_FILTER_STATE_KEY)
      },
      [PREFERRED_PARTNERS_STATE_KEY]: {
        value: getDefaultValue(PREFERRED_PARTNERS_STATE_KEY)
      },
      [PREFERRED_PROVIDERS_STATE_KEY]: {
        value: getDefaultValue(PREFERRED_PROVIDERS_STATE_KEY)
      },
      [VERIFIED_FILTER_STATE_KEY]: {
        value: getDefaultValue(VERIFIED_FILTER_STATE_KEY)
      },
      accessibility: {
        value: getDefaultValue('accessibility')
      },
      page: {
        value: getDefaultValue('page')
      },
      showScores: {
        value: getDefaultValue('showScores')
      }
    },
    searchFailed: false,
    shareModal: {
      resourceSiteId: null,
      shareableUsers: [],
      shareModalOpen: false
    }
  }
}
