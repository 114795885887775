import { formatDate } from '@utils/formatterUtil'
import { serviceUnitFieldsMapping } from '@scenes/referralShow/constants'

function formatServiceUnit (serviceUnit) {
  const serviceUnitKey = serviceUnit || 'unknown'

  return window.local.t(`referrals.show.service_details.service_units.${serviceUnitKey}`)
}

function formatQuantity (quantity) {
  if (!quantity) return ''

  return window.local.t('referrals.show.service_details.quantity', { quantity })
}

function formatDurationUnit (durationUnit, durationValue) {
  if (!durationUnit) return ''

  return window.local.t(`general.time_units.${durationUnit}`, { smart_count: durationValue })
}

function formatDuration (durationUnit, durationValue) {
  if (!durationValue) return ''

  return window.local.t(
    'referrals.show.service_details.duration',
    {
      duration_unit: formatDurationUnit(durationUnit, durationValue),
      duration_value: durationValue
    }
  )
}

function enrolledText (serviceDetail, dateOfAction) {
  const { durationUnit, durationValue, serviceFrequency, serviceUnit, quantity } = serviceDetail
  const serviceUnitType = serviceUnitFieldsMapping[serviceUnit]
  let detailsText = ''
  let frequencyText = ''

  const baseText = window.local.t(
    'referrals.show.service_details.actions.enrolled.base',
    {
      date_of_action: dateOfAction,
      service_unit: formatServiceUnit(serviceUnit)
    }
  )

  if (serviceUnitType === 'quantity') {
    detailsText = ` ${formatQuantity(quantity)}`
  } else if (serviceUnitType === 'duration') {
    detailsText = ` ${formatDuration(durationUnit, durationValue)}`
  }

  if (serviceFrequency) {
    frequencyText = ` ${window.local.t(
      `referrals.show.service_details.actions.enrolled.service_frequency.${serviceFrequency}`
    )}`
  }

  return baseText + detailsText + frequencyText
}

function serviceProvidedText (serviceDetail, dateOfAction) {
  const { completionStatus, durationUnit, durationValue, serviceUnit, quantity } = serviceDetail
  let detailsText = ''
  let completionStatusText = ''

  const baseText = window.local.t(
    'referrals.show.service_details.actions.service_provided.base',
    {
      date_of_action: dateOfAction,
      service_unit: formatServiceUnit(serviceUnit)
    }
  )

  if (serviceUnitFieldsMapping[serviceUnit] === 'quantity') {
    detailsText = ` ${formatQuantity(quantity)}`
  } else {
    detailsText = ` ${formatDuration(durationUnit, durationValue)}`
  }

  if (completionStatus) {
    completionStatusText = ` ${window.local.t(
      `referrals.show.service_details.actions.service_provided.completion_status.${completionStatus}`
    )}`
  }

  return baseText + detailsText + completionStatusText
}

function waitlistedText (serviceDetail, dateOfAction) {
  let detailsText = ''

  const baseText = window.local.t(
    'referrals.show.service_details.actions.waitlisted.base',
    {
      date_of_action: dateOfAction
    }
  )

  if (serviceDetail.endDate) {
    detailsText = ` ${window.local.t(
      'referrals.show.service_details.actions.waitlisted.details',
      {
        end_date: formatDate(serviceDetail.endDate)
      }
    )}`
  }

  return baseText + detailsText
}

function serviceNotProvidedText (serviceDetail, dateOfAction) {
  return window.local.t(
    `referrals.show.service_details.actions.service_not_provided.${serviceDetail.reasonNotProvided}`,
    {
      date_of_action: dateOfAction
    }
  )
}

function contactSuccessfulText (serviceDetail, dateOfAction) {
  return window.local.t(
    'referrals.show.service_details.actions.contact_successful',
    {
      date_of_action: dateOfAction
    }
  )
}

function contactUnsuccessfulText (serviceDetail, dateOfAction) {
  return window.local.t(
    'referrals.show.service_details.actions.contact_unsuccessful',
    {
      date_of_action: dateOfAction
    }
  )
}

export function serviceDetailSummaryText ({ referralEncounter, resourceProgramAssignmentId }) {
  const serviceDetail = referralEncounter.serviceDetails.find(serviceDetail => (
    serviceDetail.referralResourceProgramAssignmentId === resourceProgramAssignmentId
  ))
  const dateOfAction = formatDate(referralEncounter.dateOfAction)

  switch (referralEncounter.action) {
    case 'service_provided':
      return serviceProvidedText(serviceDetail, dateOfAction)
    case 'enrolled':
      return enrolledText(serviceDetail, dateOfAction)
    case 'waitlisted':
      return waitlistedText(serviceDetail, dateOfAction)
    case 'service_not_provided':
      return serviceNotProvidedText(serviceDetail, dateOfAction)
    case 'contact_successful':
      return contactSuccessfulText(serviceDetail, dateOfAction)
    case 'contact_unsuccessful':
      return contactUnsuccessfulText(serviceDetail, dateOfAction)
  }
}
