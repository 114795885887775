import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import {
  addClientAndPrepopulateCompositeClient,
  addClientSelectError,
  removeClientSelection,
  setSelectionValidationReadyState
} from '@scenes/clients/ClientMerge/actions'
import SelectSyncer from '@scenes/clients/ClientMerge/components/SelectSyncer'
import PageHeader from '@components/PageHeader'
import ButtonLink from '@components/legacy/ButtonLink'
import SmallModule from '@components/SmallModule'
import cssVariables from '@config/theme/variables'
import './SelectionView.css'

const SelectionView = props => {
  const local = window.local
  const { localeContext } = props

  const inReadyState = () => {
    const { firstPatient, secondPatient, selectionsValid } = props
    const hasSelections = firstPatient && secondPatient
    return hasSelections && selectionsValid
  }

  return (
    <div className='SelectionView'>
      <PageHeader
        title={local.t(`client_merge.header_title.${localeContext}`)}
        description={local.t(`client_merge.header_copy.${localeContext}`)}
        backLink={{
          path: '/en/clients',
          text: local.t('client_merge.header_back_link'),
          isExternal: true
        }}
      />
      <div style={cssVariables.gridContainer}>
        <header>
          <h2 className='SelectionView__title'>
            {local.t(`client_merge.client_selection.section_header.${localeContext}`)}
          </h2>
        </header>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={8}>
            <SelectSyncer {...props} />
          </Grid>

          <Grid item xs={1} />

          <Grid item xs={3}>
            <SmallModule
              title={local.t(`client_merge.sidebar.title.${localeContext}`)}
              banner={{
                text: local.t(`client_merge.sidebar.banner`),
                width: '164px'
              }}
            >
              <p className='hfysg-p'>
                {local.t(`client_merge.sidebar.copy_a.${localeContext}`)}
              </p>

              <p
                className='hfysg-p'
                dangerouslySetInnerHTML={
                  { __html: local.t(`client_merge.sidebar.copy_b.${localeContext}`) }
                }
              />
            </SmallModule>

            <ButtonLink
              disabled={!inReadyState()} to={local.t(`client_merge.sidebar.call_to_action_url.${localeContext}`)}>
              {local.t(`client_merge.sidebar.call_to_action.${localeContext}`)}
            </ButtonLink>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

SelectionView.propTypes = {
  errors: PropTypes.object,
  firstPatient: PropTypes.object,
  localeContext: PropTypes.string.isRequired,
  secondPatient: PropTypes.object,
  selectionsValid: PropTypes.bool.isRequired,
  setSelectionValidationReadyState: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  errors: state.clientMerge.errors,
  firstPatient: state.clientMerge.firstPatient,
  options: state.clientMerge.options,
  secondPatient: state.clientMerge.secondPatient,
  selectionsValid: state.clientMerge.selectionsValid
})

const mapDispatchToProps = dispatch => ({
  addClient: (client, direction) => dispatch(addClientAndPrepopulateCompositeClient({ client, direction })),
  addClientSelectError: (error, direction) => dispatch(addClientSelectError({ error, direction })),
  removeClientSelection: direction => dispatch(removeClientSelection(direction)),
  setSelectionValidationReadyState: ready => dispatch(setSelectionValidationReadyState(ready))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectionView)
