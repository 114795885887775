import { useSelector } from 'react-redux'
import { getCollectionFromRelationship } from '@selectors'

const useGetCollection = (type, association, id) => (
  useSelector(state => (
    getCollectionFromRelationship({ state, type, association, id })
  ))
)

export default useGetCollection
