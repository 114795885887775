import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import uuid from 'uuid'
import get from 'lodash/get'
import { serviceUnitFieldsMapping, durationUnits } from '../constants'
import { format } from '@utils/selectUtil'
import { onServiceUnitChange } from '../actions'

import Grid from '@material-ui/core/Grid'
import Select from '@components/Select'
import Spacing from '@components/Spacing'
import TextInput from '@components/TextInput'
import Label from '@components/Label'

import './ReferralEncounterModal.scss'

class ServiceUnitSubform extends PureComponent {
  renderServiceUnitSelect () {
    const {
      onServiceUnitChange,
      serviceUnitLabelKey,
      formSelections: { serviceUnit },
      required,
      programAssignmentId
    } = this.props

    return (
      <Select
        dataTest='referral-encounter-modal__service-unit'
        onChange={onServiceUnitChange(programAssignmentId)}
        options={format.transformToOptions(
          Object.keys(serviceUnitFieldsMapping),
          'referrals.encounter_modal.service_units'
        )}
        label={window.local.t(`referrals.encounter_modal.service_units.label.${serviceUnitLabelKey}`)}
        placeholder={window.local.t('referrals.encounter_modal.service_units.placeholder')}
        value={serviceUnit && serviceUnit.value}
        showDropdownIndicator
        hideClearIndicator
        required={required}
        errors={serviceUnit && serviceUnit.errors}
      />
    )
  }

  renderQuantityField () {
    const {
      onServiceDetailsFieldChange,
      formSelections: { quantity },
      required,
      programAssignmentId
    } = this.props

    return (
      <Grid container direction='row' spacing={1}>
        <Grid item xs={4}>
          <TextInput
            label={window.local.t('referrals.encounter_modal.quantity.label')}
            onChange={onServiceDetailsFieldChange(programAssignmentId, 'quantity')}
            value={quantity && quantity.value}
            required={required}
            errors={quantity && quantity.errors}
          />
        </Grid>
      </Grid>
    )
  }

  renderDurationFields () {
    const textFieldId = `text-input-id-${uuid()}`
    const {
      onServiceDetailsFieldChange,
      formSelections: { durationUnit, durationValue },
      required,
      programAssignmentId
    } = this.props

    return (
      <Fragment>
        <Label
          id={textFieldId}
          text={window.local.t('referrals.encounter_modal.duration_value.label')}
          required={required}
        />

        <Grid container direction='row' spacing={1}>
          <Grid item xs={4}>
            <TextInput
              id={textFieldId}
              onChange={onServiceDetailsFieldChange(
                programAssignmentId,
                'durationValue'
              )}
              value={durationValue && durationValue.value}
              required={required}
              errors={durationValue && durationValue.errors}
            />
          </Grid>

          <Grid item xs={8}>
            <Select
              onChange={onServiceDetailsFieldChange(
                programAssignmentId,
                'durationUnit'
              )}
              options={format.transformToOptions(
                durationUnits,
                'referrals.encounter_modal.duration_units'
              )}
              placeholder={window.local.t('referrals.encounter_modal.duration_units.placeholder')}
              value={durationUnit && durationUnit.value}
              showDropdownIndicator
              hideClearIndicator
              required={required}
              errors={durationUnit && durationUnit.errors}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  renderQuantityOrDurationField () {
    const serviceUnit = get(
      this.props.formSelections,
      'serviceUnit.value.value',
      null
    )

    if (!serviceUnit) return null

    const fieldToRender = serviceUnitFieldsMapping[serviceUnit]

    if (fieldToRender === 'duration') {
      return this.renderDurationFields()
    } else {
      return this.renderQuantityField()
    }
  }

  render () {
    return (
      <div data-testid={`service-detail-subform-${this.props.programAssignmentId}`}>
        <Spacing marginBottom={3}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={6}>
              {this.renderServiceUnitSelect()}
            </Grid>

            <Grid item xs={6}>
              {this.renderQuantityOrDurationField()}
            </Grid>
          </Grid>
        </Spacing>
      </div>
    )
  }
}

ServiceUnitSubform.propTypes = {
  formSelections: PropTypes.shape({
    durationUnit: PropTypes.object,
    durationValue: PropTypes.object,
    quantity: PropTypes.object,
    serviceUnit: PropTypes.object
  }),
  onServiceDetailsFieldChange: PropTypes.func.isRequired,
  onServiceUnitChange: PropTypes.func.isRequired,
  programAssignmentId: PropTypes.number.isRequired,
  required: PropTypes.bool.isRequired,
  serviceUnitLabelKey: PropTypes.string.isRequired
}

const mapDispatchToProps = {
  onServiceUnitChange
}

const mapStateToProps = (state, props) => {
  const { programAssignmentServiceDetails } = state.referralView.form

  return {
    formSelections: programAssignmentServiceDetails[props.programAssignmentId]
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceUnitSubform)
