import Phone from './Phone'

export function fullNameBackwards (object) {
  return `${object.lastName}, ${object.firstName}`
}

export function formattedNameWithBirthday (object) {
  return `${object.firstName} ${object.lastName} (${object.dateOfBirth})`
}

export function formatGender (gender) {
  if (!gender) return

  return gender.toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const attributeDisplayValues = {
  gender: (value) => (formatGender(value))
}

export const displayValueFor = (attributeName, value, defaultValue = '') => {
  if (attributeDisplayValues[attributeName]) {
    return attributeDisplayValues[attributeName](value)
  }

  return value || defaultValue
}

export default function Client (object) {
  return {
    ...object,
    formattedNameWithBirthday: formattedNameWithBirthday(object),
    fullNameBackwards: fullNameBackwards(object),
    gender: object.gender,
    phones: (object.phones || []).map(phoneObject => Phone(phoneObject)),
    text: formattedNameWithBirthday(object),
    object
  }
}
