import React from 'react'
import PropTypes from 'prop-types'

import { isInNetworkPreferred, isOutOfNetworkClr } from '@utils/networkTiersUtil'
import { NETWORKED_TIERS, IN_NETWORK, OUT_OF_NETWORK_CLR } from '@constants/networkTiers'

import Banner from '@components/Banner'
import ElementWithTooltip from '@components/ElementWithTooltip'
import './ResourceSiteTierBanner.css'

const ResourceSiteTierBanner = ({ tier }) => {

  const localeKeyForTier = (tierName) => {
    if (isInNetworkPreferred(tierName))
      return "preferred";
    else if (isOutOfNetworkClr(tierName))
      return OUT_OF_NETWORK_CLR
    else
      return IN_NETWORK
  }

  const partnershipTypeDescription = (tier) => {
    const localeKey = localeKeyForTier(tier)

    return window.local.t(`partnership_types.${localeKey}.context`)
  }

  return (
    <div className='ResourceSiteTierBanner'>
      <ElementWithTooltip
        tooltipContent={partnershipTypeDescription(tier)}
      >
        <Banner
          text={window.local.t(`resource_sites.resource_site_tier_banner.${tier}`)}
          width='200px'
          color={!isInNetworkPreferred(tier) ? 'blue-05' : 'blue'}
        />
      </ElementWithTooltip>
    </div>
  )
}

ResourceSiteTierBanner.propTypes = {
  tier: PropTypes.oneOf([...NETWORKED_TIERS, OUT_OF_NETWORK_CLR]).isRequired
}

export default ResourceSiteTierBanner
