import axios from 'axios'

const search = ({ usesCoordinate } = {}) => {
  const data = {
    params: {
      uses_coordinate: usesCoordinate
    }
  }

  return axios.get('/api/internal/teams', data)
    .then(response => response.data)
}

export default {
  search
}
