import React, { useState } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'

import Spacing from '@components/Spacing'
import Text from '@components/Text'

const TierFilterOption = (props) => {
  const { currentSelection, name, label, description, onChange } = props
  const isCurrentlySelected = currentSelection === name

  const [internalUUID] = useState(uuid())
  const fieldID = `tier-filter-option-${name}-${internalUUID}`

  return (
    <div key={fieldID} className='TierFilter__Option__Container'>
      <Spacing marginRight={2}>
        <input
          checked={isCurrentlySelected}
          className='TierFilter__Option__Input'
          id={fieldID}
          name={name}
          onChange={onChange}
          type='radio'
          value={isCurrentlySelected && 'on'}
        />
      </Spacing>
      <label htmlFor={fieldID}>
        <Text style='sub-header-small'>{label}</Text>
        {description && (<Text style='body-text-small' tag='div'>{description}</Text>)}
      </label>
    </div>
  )
}

TierFilterOption.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  currentSelection: PropTypes.string,
  description: PropTypes.string
}

export default React.memo(TierFilterOption)
