import React from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import classNames from 'classnames'
import uuid from 'uuid'

import Label from '@components/Label'
import './ExpandableTextArea.scss'

class ExpandableTextArea extends React.Component {
  constructor (props) {
    super(props)

    this.uuid = uuid()

    this.inputId = this.props.id || `expandable-text-area-id-${uuid()}`

    this.onChange = this.onChange.bind(this)
  }

  onChange (event) {
    this.props.onChange(event.target.value)
  }

  getMinRows () {
    return this.props.inline ? 1 : 6
  }

  getTextAreaClasses () {
    return classNames(
      'ExpandableTextArea__textarea',
      { 'ExpandableTextArea__textarea--withPadding': !this.props.inline }
    )
  }

  render () {
    return (
      <div className='ExpandableTextArea'>
        {this.props.label && <Label
          text={this.props.label}
          id={this.inputId}
          required={this.props.required}
        />}

        <TextareaAutosize
          id={this.inputId}
          className={this.getTextAreaClasses()}
          data-test={this.props.dataTest}
          maxLength={this.props.maxLength}
          minRows={this.getMinRows()}
          name={this.props.name}
          onChange={this.onChange}
          placeholder={this.props.placeholder}
          value={this.props.value}
          ref={this.props.refProp}
        />
      </div>
    )
  }
}

ExpandableTextArea.defaultProps = {
  inline: false,
  onChange: () => {}
}

ExpandableTextArea.propTypes = {
  dataTest: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  inline: PropTypes.bool,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) })
  ]),
  required: PropTypes.bool,
  value: PropTypes.string
}

export default ExpandableTextArea
