import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import ClosedLoopReferralsPage from '@scenes/unauthenticated/ClosedLoopReferrals/cbo/ClosedLoopReferralsPage'
import ClosedLoopReferralsThankYou from '@scenes/unauthenticated/ClosedLoopReferrals/cbo/ClosedLoopReferralsThankYou'
import MemberClosedLoopReferralsPage from '@scenes/unauthenticated/ClosedLoopReferrals/members/MemberPage'
import PublicSearchOverview from '@scenes/unauthenticated/PublicSearch/PublicSearchOverview'
import PublicSearchResults from '@scenes/unauthenticated/PublicSearch/PublicSearchResults'
import CartShareScene from '@scenes/carts/CartShareScene'
import SurveyResultsPage from '@scenes/unauthenticated/components/survey/SurveyResultsPage'

export const routes = (props) => {
  return (
    <Switch>
      <Route
        exact
        path='/:companySlug/search'
        component={(props) => <PublicSearchResults {...props} />}
      />
      <Route
        exact
        path='/:companySlug'
        component={(props) => <PublicSearchOverview {...props} />}
      />

      <Route
        path='/closed_loop_referrals/members/:referralUuid'
        component={(props) => <MemberClosedLoopReferralsPage {...props} accept />}
      />
      <Route
        path='/closed_loop_referrals/:referralUuid/accept'
        component={(props) => <ClosedLoopReferralsPage {...props} action='accept' />}
      />
      <Route
        path='/closed_loop_referrals/:referralUuid/decline'
        component={(props) => <ClosedLoopReferralsPage {...props} action='decline' />}
      />
      <Route
        path='/closed_loop_referrals/:referralUuid/complete'
        component={(props) => <ClosedLoopReferralsPage {...props} action='complete' />}
      />
      <Route
        path='/closed_loop_referrals/:referralUuid/thank_you'
        component={(props) => <ClosedLoopReferralsThankYou {...props} />}
      />
      <Route
        path='/cart_shares/:cartShareUuid'
        component={(props) => <CartShareScene {...props} />}
      />
      <Route
        path='/:companySlug/results/:surveyId'
        component={(props) => <SurveyResultsPage {...props} />}
      />
    </Switch>
  )
}

export default (props) => (
  <Router basename='/en'>
    {routes(props)}
  </Router>
)
