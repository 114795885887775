export const guidedSearchRadiusOptions = () => {
  return [1, 5, 15, 30, 50, 100].map(option => ({
    value: `${option}miles`,
    label: window.local.t(
      'resource_sites.guided_search_form.radius.miles',
      { smart_count: option, value: option }
    )
  }))
}

export const currentRadiusOption = (radius) => {
  return guidedSearchRadiusOptions().find(option => {
    return option.value === radius
  })
}
