import React from 'react'
import PropTypes from 'prop-types'
import './Spacing.scss'

class Spacing extends React.PureComponent {
  constructor (props) {
    super(props)

    this.getStyle = this.getStyle.bind(this)
  }

  getClasses (baseClassName, value) {
    if (value) {
      if (typeof value === 'number') {
        return `Spacing--xs-${baseClassName}-${2 * value}`
      } else {
        return Object.keys(value).map(key => `Spacing--${key}-${baseClassName}-${2 * value[key]}`).join(' ')
      }
    }

    return ''
  }

  getStyle (type) {
    return Object.keys(this.props)
      .filter(key => key.includes(type))
      .map(key => this.getClasses(key, this.props[key]))
      .join(' ')
  }

  render () {
    const { tag } = this.props
    const Tag = tag

    return (
      <Tag
        className={`Spacing ${this.getStyle('padding')} ${this.getStyle('margin')}`}
        data-test={this.props.dataTest}
        data-testid={this.props.dataTest}
      >
        {this.props.children}
      </Tag>
    )
  }
}

Spacing.displayName = 'Spacing'

Spacing.defaultProps = {
  tag: 'div'
}

const validValuesArray = PropTypes.oneOf([...[...Array(12).keys()].map(i => i + 1), 0.5, 0])

const allowedValues = PropTypes.oneOfType([
  validValuesArray,
  PropTypes.shape({
    xs: validValuesArray,
    sm: validValuesArray,
    md: validValuesArray,
    lg: validValuesArray,
    xl: validValuesArray
  })
])

/* eslint-disable react/no-unused-prop-types */
Spacing.propTypes = {
  children: PropTypes.node,
  dataTest: PropTypes.string,
  padding: allowedValues,
  paddingY: allowedValues,
  paddingX: allowedValues,
  paddingLeft: allowedValues,
  paddingRight: allowedValues,
  paddingTop: allowedValues,
  paddingBottom: allowedValues,
  margin: allowedValues,
  marginY: allowedValues,
  marginX: allowedValues,
  marginLeft: allowedValues,
  marginRight: allowedValues,
  marginTop: allowedValues,
  marginBottom: allowedValues,
  tag: PropTypes.oneOf([
    'div',
    'section',
    'span'
  ])
}
/* eslint-enable react/no-unused-prop-types */

export default Spacing
