import React from 'react'

const style = {
  borderColor: 'transparent transparent rgb(250, 250, 250) transparent',
  borderStyle: 'solid',
  borderWidth: '0px 20px 20px',
  height: '0',
  left: '80px',
  position: 'absolute',
  top: '-20px',
  width: '0'
}

const borderStyle = {
  ...style,
  ...{
    borderColor: 'transparent transparent rgb(220, 223, 226) transparent',
    left: '80px',
    position: 'absolute',
    top: '-21px',
    transform: 'scale(1.01)'
  }
}

const Triangle = () => (
  <div>
    <div style={borderStyle} />
    <div style={style} />
  </div>
)

export default Triangle
