import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import SearchOverview from '@scenes/resourceSites/SearchOverview/SearchOverview'
import ResourceSiteSearchResults from '@scenes/resourceSites/ResourceSiteSearchResults/ResourceSiteSearchResults'

export const routes = (props) => {
  return (
    <Switch>
      <Route exact path='/resource_sites' component={(props) => <ResourceSiteSearchResults {...props} />} />
      <Route exact path='/resource_sites/overview' component={(props) => <SearchOverview {...props} />} />
    </Switch>
  )
}

export default (props) => {
  return <Router basename='/en'>
    {routes(props)}
  </Router>
}
