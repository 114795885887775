import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'

class EmptyFieldWarning extends React.PureComponent {
  render () {
    return (
      <div className='ReviewView__attribute ReviewView__attribute--blank'>
        <h6 className='ReviewView__attribute__header'>
          <Icon theme='round' iconKey='warning' size={14} className='mi--orange mi--align-bottom' />
          {this.props.label}
        </h6>
        <span className='ReviewView__attribute__blank-field' />
      </div>
    )
  }
}

EmptyFieldWarning.propTypes = {
  label: PropTypes.string.isRequired
}

export default EmptyFieldWarning
