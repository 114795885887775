export const serializeCartShare = ({
  language,
  sendText,
  phone,
  sendEmail,
  email,
  cartId,
  patientId
}) => {
  const constructedPhone = constructPhone(sendText, phone)
  const params = {
    patient_id: patientId,
    cart_id: cartId,
    cart_share: {
      language: language && language.value,
      send_text: sendText,
      phone_id: constructedPhone.id,
      phone_number: constructedPhone.number,
      send_email: sendEmail,
      email
    }
  }

  return params
}

const constructPhone = (sendText, phone) => {
  if (!sendText) return {}

  return {
    id: !phone.__isNew__ ? phone.value : null, // null if custom option
    number: phone.__isNew__ ? phone.label : null // null if known option
  }
}
