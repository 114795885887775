import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Collapse from '@material-ui/core/Collapse'

import ExpandButton from './ExpandButton'

const ExpansionPanelUnstyled = (props) => {
  const combinedClassNames = classNames(
    'ExpansionPanel',
    'ExpansionPanel--no-card',
    {
      'ExpansionPanel--expanded': props.expanded,
      'ExpansionPanel--with-header': props.withHeader
    }
  )

  return (
    <div className={combinedClassNames}>
      <div className='ExpansionPanel__title--no-card'>
        {props.title}
        <ExpandButton {...props} />
      </div>

      <Collapse in={props.expanded}>
        <div className='ExpansionPanel__content'>
          {props.children}
        </div>
      </Collapse>
    </div>
  )
}

ExpansionPanelUnstyled.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  withHeader: PropTypes.bool,

  title: PropTypes.node
}

export default ExpansionPanelUnstyled
