import groupBy from 'lodash/groupBy'
import { adaptUserObject } from '@adapters/userAdapter'

class ClientMergeAdapter {
  constructor (networkResponse) {
    this.data = networkResponse.data.data
    this.included = networkResponse.data.included
    this.addressAttributes = this.filterIncludedByType('Address') || []
    this.identifierAttributes = this.filterIncludedByType('PatientIdentifier') || []
    this.patientAttributes = this.filterIncludedByType('Patient') || []
    this.phoneAttributes = this.filterIncludedByType('Phone') || []

    if (Array.isArray(this.data)) {
      this.collection = this.adaptCollection(this.data)
    } else {
      this.object = this.adaptObject(this.data)
    }
  }

  filterIncludedByType (name) {
    return this.included.filter(includedObject => (
      includedObject.attributes.source_object_type === name
    ))
  }

  adaptCollection (collection) {
    return collection.map(object => this.adaptObject(object))
  }

  adaptObject (object) {
    const patientIds = object.relationships.patients.data.map(patient => patient.id)
    const requesterId = object.relationships.user.data.id

    return {
      createdAt: object.attributes.created_at,
      note: object.attributes.note,
      compositeClient: this.adaptCompositeClient(object),
      id: object.attributes.id,
      clients: this.adaptPatients(patientIds),
      requester: this.adaptRequester(requesterId)
    }
  }

  adaptCompositeClient (object) {
    let composite = {}

    this.patientAttributes.forEach(includedObject => {
      const attrs = includedObject.attributes
      composite[attrs.source_column] = {
        source: attrs.source_object_id,
        value: attrs.value
      }
    })

    if (this.addressAttributes.length) {
      const addressSourcePatientID = this.addressAttributes[0].attributes.patient_id
      const addressSourceObjectID = this.addressAttributes[0].attributes.source_object_id

      let addressLines = {}
      this.addressAttributes.forEach((addressObject) => {
        const attrs = addressObject.attributes

        addressLines[attrs.source_column] = attrs
      })
      addressLines['id'] = addressSourceObjectID

      composite['address'] = {
        source: addressSourcePatientID,
        value: addressLines
      }
    } else {
      composite['address'] = null
    }

    const groupBySourceObjectID = attribute => attribute.attributes.source_object_id
    const groupedPhoneAttributes = groupBy(
      this.phoneAttributes,
      groupBySourceObjectID
    )

    composite['phones'] = Object
      .values(groupedPhoneAttributes)
      .map((attributes) => {
        const descriptor = attributes.find(attr => attr.attributes.source_column === 'descriptor')
        const extension = attributes.find(attr => attr.attributes.source_column === 'extension')
        const number = attributes.find(attr => attr.attributes.source_column === 'number')

        return {
          source: attributes[0].attributes.patient_id,
          value: {
            id: attributes[0].attributes.source_object_id,
            descriptor: descriptor && descriptor.attributes.value,
            extension: extension && extension.attributes.value,
            number: number && number.attributes.value
          }
        }
      })

    composite['phones'] = Object
      .values(groupedPhoneAttributes)
      .map((attributes) => {
        const descriptor = attributes.find(attr => attr.attributes.source_column === 'descriptor')
        const extension = attributes.find(attr => attr.attributes.source_column === 'extension')
        const number = attributes.find(attr => attr.attributes.source_column === 'number')

        return {
          source: attributes[0].attributes.patient_id,
          value: {
            id: attributes[0].attributes.source_object_id,
            descriptor: descriptor && descriptor.attributes.value,
            extension: extension && extension.attributes.value,
            number: number && number.attributes.value
          }
        }
      })

    const groupedIdentifierAttributes = groupBy(
      this.identifierAttributes,
      groupBySourceObjectID
    )

    composite['identifiers'] = Object
      .values(groupedIdentifierAttributes)
      .map(attributes => ({
        source: attributes[0].attributes.patient_id,
        value: {
          id: attributes[0].attributes.source_object_id,
          type: attributes.find(attr => attr.attributes.source_column === 'identifier_type').attributes.value,
          value: attributes.find(attr => attr.attributes.source_column === 'normalized_value').attributes.value
        }
      }))

    if (composite.external_id) {
      composite.identifiers.unshift({
        source: composite.external_id.source,
        value: {
          id: composite.external_id.value,
          type: 'external',
          value: composite.external_id.value
        }
      })

      delete composite.external_id
    }

    return composite
  }

  adaptRequester (requesterId) {
    const userObject = this.included.find(includedObject => (
      includedObject.type === 'user' && includedObject.id === requesterId
    ))

    return adaptUserObject(userObject)
  }

  adaptPatients (patientIds) {
    return patientIds.map(patientId => {
      const patientObject = this.included.find(includedObject => {
        return includedObject.type === 'patient' && includedObject.id === patientId
      })

      const { attributes } = patientObject
      return {
        id: attributes.id,
        firstName: attributes.first_name,
        lastName: attributes.last_name,
        middleName: attributes.middle_name,
        externalId: attributes.external_id,
        gender: attributes.gender,
        url: patientObject.links.self
      }
    })
  }
}

export default ClientMergeAdapter
