import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Spacing from '@components/Spacing'
import Select from '@components/Select'
import { format } from '@utils/selectUtil'
import { reasonsNotProvided } from '@scenes/referralShow/constants'
import { onAllServiceDetailsFieldChange } from '@scenes/referralShow/actions'

class ServiceNotProvidedForm extends PureComponent {
  render () {
    const {
      onAllServiceDetailsFieldChange,
      formSelections: { reasonNotProvided }
    } = this.props

    return (
      <Spacing marginBottom={3}>
        <Select
          dataTest='referral-encounter-modal__reason-not-provided'
          onChange={onAllServiceDetailsFieldChange('reasonNotProvided')}
          options={format.transformToOptions(
            reasonsNotProvided,
            'referrals.encounter_modal.reason_not_provided.options'
          )}
          label={window.local.t('referrals.encounter_modal.reason_not_provided.label')}
          placeholder={window.local.t('referrals.encounter_modal.reason_not_provided.placeholder')}
          value={reasonNotProvided && reasonNotProvided.value}
          showDropdownIndicator
          hideClearIndicator
          required
          errors={reasonNotProvided && reasonNotProvided.errors}
        />
      </Spacing>
    )
  }
}

ServiceNotProvidedForm.propTypes = {
  formSelections: PropTypes.object,
  onAllServiceDetailsFieldChange: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  onAllServiceDetailsFieldChange
}

const mapStateToProps = (state, props) => {
  const { programAssignmentServiceDetails } = state.referralView.form

  return {
    formSelections: Object.values(programAssignmentServiceDetails)[0]
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceNotProvidedForm)
