import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import TextInput from '@components/TextInput'
import DatePicker from '@components/DatePicker'
import Button from '@components/Button'
import Spacing from '@components/Spacing'
import Form from '@components/Form'
import Select from '@components/Select'
import Checkbox from '@components/Form/Checkbox'
import Text from '@components/Text'
import FieldErrors from '@components/Form/FieldErrors'

import ContactPreferences from './ContactPreferences'

const closedLoopableCta = (
  <Spacing marginTop={2}>
    <Text tag='p'>
      <Text style='body-text-emphasis'>Note:</Text> we will do our best to connect with this organization. However, we recommend that you attempt to contact them as well.
    </Text>
  </Spacing>
)

const CreateForm = (props) => {
  const { id: patientId = null, first_name: fName = null, last_name: lName = null, date_of_birth: dob = null } = props.initialPatientInformation || {}
  const disableInput = patientId !== null
  const [firstName, setFirstName] = useState(fName)
  const [lastName, setLastName] = useState(lName)
  const [dateOfBirth, setDateOfBirth] = useState(dob ? new Date(`${dob}T00:00:00`) : null)
  const [email, setEmail] = useState(null)
  const [phone, setPhoneNumber] = useState(null)
  const [selectedPrograms, setPrograms] = useState([])
  const [endUserAttestation, setEndUserAttestation] = useState(false)
  const [contactPreference, setContactPreference] = useState(null)
  const { companyId, resourceSite } = props

  const contactPreferencesProps = {
    fullScreen: props.fullScreen,
    setContactPreference,
    setEmail,
    setPhoneNumber
  }

  const canSubmitForm = firstName &&
    lastName &&
    dateOfBirth &&
    (email || phone) &&
    selectedPrograms.length &&
    endUserAttestation

  const submitReferral = () => {
    // When text is entered then removed, email becomes empty string, fails constraint validations
    const normalizedEmail = (email && email.trim()) || null
    const patientParams = {
      id: patientId,
      firstName,
      lastName,
      dateOfBirth,
      email: normalizedEmail,
      phone
    }
    const referralParams = {
      resourceProgramUuids: selectedPrograms.map(program => program.uuid),
      resourceSiteUuid: resourceSite.uuid,
      endUserAttestation,
      contactPreference
    }
    props.submitReferral({ companyId, patientParams, referralParams })
  }

  return (
    <div className='SelfReferralModal__CreateForm'>
      <Form onSubmit={submitReferral}>
        <FieldErrors errors={props.errors} showErrorMessages />

        <Spacing marginBottom={0.5}>
          <Text style='sub-header-extra-small'>Service</Text>
        </Spacing>

        <Spacing marginBottom={2}>
          <Text tag='h4'>{props.resourceSite.serviceName}</Text>
        </Spacing>

        <Select
          required
          showDropdownIndicator
          onChange={setPrograms}
          options={props.resourceSite.resourcePrograms}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.uuid}
          label='Requested Services'
          isMulti
        />

        <hr style={{ marginTop: '0.25rem', marginBottom: '1rem' }} />

        <Spacing marginBottom={0.5}>
          <Text tag='h4' style='sub-header-small'>Contact Information</Text>
        </Spacing>

        <Spacing marginBottom={2}>
          <Text tag='p'>
            We'll share your information with this organization so they can get in touch, and use it to keep you updated as they respond to your request for services.
          </Text>
        </Spacing>

        <Grid container direction='row' spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput label='First Name'required onChange={setFirstName} value={firstName} disabled={disableInput} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label='Last Name'required onChange={setLastName} value={lastName} disabled={disableInput} />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              label='Date of Birth'
              required
              onChange={setDateOfBirth}
              selected={dateOfBirth}
              maxDate={new Date()}
              disabled={disableInput}
            />
          </Grid>

          <Grid item xs={12}>
            <ContactPreferences {...contactPreferencesProps} />
          </Grid>
        </Grid>

        <hr style={{ margin: '1rem 0' }} />

        <Spacing marginBottom={2}>
          <Checkbox
            required
            onChange={() => { setEndUserAttestation(!endUserAttestation) }}
            id='referral_end_user_attestation'
            name='referral[end_user_attestation]'
            label="I authorize sharing my contact information with this organization's email address and consent to receiving updates about this request via the contact method given"
          />
        </Spacing>

        <Button type='primaryDark' htmlType='submit' disabled={!canSubmitForm}>
          Contact
        </Button>

        {resourceSite.closedLoopable && closedLoopableCta}
      </Form>
    </div>
  )
}

CreateForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  errors: PropTypes.array.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  resourceSite: PropTypes.object.isRequired,
  submitReferral: PropTypes.func.isRequired,
  initialPatientInformation: PropTypes.object
}

export default CreateForm
