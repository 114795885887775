import { useSelector } from 'react-redux'
import { getRecord } from '@selectors'

const useGetRecord = (type, id) => {
  const record = useSelector(state => getRecord(state, type, id))

  return record
}

export default useGetRecord
