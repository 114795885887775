import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './Button.scss'

class Button extends React.PureComponent {
  getClasses () {
    return classNames(
      'Button',
      `Button--${this.props.type}`,
      {
        'Button--disabled': this.props.disabled,
        'Button--width--inherit': this.props.inheritWidth
      }
    )
  }

  render () {
    const {
      children, dataTest, disabled, onClick, role, htmlType,

      // Unused props extracted to avoid adding DOM attributes
      inheritWidth,
      type,

      ...passThruProps
    } = this.props

    return (
      <button
        type={htmlType}
        role={role}
        data-test={dataTest}
        data-testid={dataTest}
        disabled={disabled}
        onClick={onClick}
        className={this.getClasses()}
        {...passThruProps}
      >
        {children}
      </button>
    )
  }
}

Button.displayName = 'Button'

Button.defaultProps = {
  htmlType: 'button',
  role: 'button'
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'expansion',
    'primary',
    'primaryDark',
    'secondary',
    'tertiary',
    'toolbar'
  ]).isRequired,

  'aria-label': PropTypes.string,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  htmlType: PropTypes.oneOf(['button', 'submit']),
  inheritWidth: PropTypes.bool,
  onClick: PropTypes.func,
  role: PropTypes.oneOf(['button', 'link'])
}

export default Button
