import {
  OUT_OF_NETWORK_CLR,
  OUT_OF_NETWORK_TIERS,
  IN_NETWORK_PREFERRED,
  NETWORKED_TIERS
} from '@constants/networkTiers'

const isOutOfNetwork = (tier) => OUT_OF_NETWORK_TIERS.includes(tier)

const isOutOfNetworkClr = (tier) => tier === OUT_OF_NETWORK_CLR

const isInNetwork = (tier) => NETWORKED_TIERS.includes(tier)

const isInNetworkPreferred = (tier) => tier === IN_NETWORK_PREFERRED

export {
  isOutOfNetwork,
  isOutOfNetworkClr,
  isInNetwork,
  isInNetworkPreferred
}
