import axios from 'axios'

import apiConfig from '@config/apiConfig'

const endpoint = '/api/internal/flash'

const createFlash = (type, message) => {
  return axios(apiConfig.post(endpoint, { type, message }))
}

export {
  createFlash
}
