import { capitalize } from '@utils/stringUtil'

const languages = (languages) => {
  return Object.keys(languages).map(key => {
    return selectObject(
      key,
      languages[key]
    )
  })
}

const phones = (phones) => {
  return phones.map(phone => {
    return selectObject(
      `${capitalize(phone.descriptor)}: ${phone.number}`,
      phone.id
    )
  })
}

const selectObject = (label, value) => {
  return {
    label, value
  }
}

const transformToOptions = (array, labelDestination) => {
  return array.map(value => {
    return {
      value,
      label: window.local.t(`${labelDestination}.${value}`)
    }
  })
}

const format = {
  languages,
  phones,
  transformToOptions
}

export {
  format
}
