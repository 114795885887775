import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

import Dialog from '@material-ui/core/Dialog'
import Spacing from '@components/Spacing'
import ProgressBar from './ProgressBar'
import SuccessContent from './SuccessContent'
import CartShareForm from '@scenes/referralCheckout/CartShares/Forms/CartShareForm'

const SubmitCartModal = (props) => {
  // Determines whether to use MUI Fullscreen Dialog mode
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const fullScreenPaddingBottom = fullScreen ? 4 : 0

  const finishedProcessing = props.completed === props.totalToComplete

  const classes = classNames('Modal', 'SubmitCartModal', {
    'SubmitCartModal--progress': !finishedProcessing,
    'SubmitCartModal--share-success': finishedProcessing
  })

  return (
    <Dialog
      open={props.open}
      fullScreen={fullScreen}
      scroll='body'
      classes={{ paper: classes }}
      // aria-label={TODO}
      data-testid='SubmitCartModal'
      disableEscapeKeyDown
    >
      {finishedProcessing && <>
        <Spacing paddingBottom={fullScreenPaddingBottom}>
          <SuccessContent clientName={props.patient.full_name} />
          <CartShareForm patient={props.patient} cartId={props.cartId} />
        </Spacing>
      </>}

      {!finishedProcessing && <ProgressBar
        completed={props.completed}
        totalToComplete={props.totalToComplete}
      />}
    </Dialog>
  )
}

SubmitCartModal.propTypes = {
  cartId: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  patient: PropTypes.object.isRequired,
  totalToComplete: PropTypes.number.isRequired
}

export default SubmitCartModal
