import React, { Fragment } from 'react'
import axios from 'axios'

import apiConfig from '@config/apiConfig'
import DatePicker from '@components/DatePicker'

const surveyUtils = {
  submit: (identifiers, requiresIdentifiers, screeningClientSlug, values) => {
    const { first_name: firstName, last_name: lastName, date_of_birth: dateOfBirth, gender,
      zip_code: zipCode, identifier, phone, accepts_texts: acceptsTexts
    } = values
    const jsonData = {
      first_name: firstName,
      last_name: lastName,
      date_of_birth: dateOfBirth,
      gender,
      phone,
      accepts_texts: acceptsTexts,
      screening_client: screeningClientSlug
    }
    const identifiersAttributes = []
    jsonData.address_attributes = { zipcode: zipCode }

    if (requiresIdentifiers) {
      identifiers.forEach((id, index) => identifiersAttributes.push(identifier[`identifier${index + 1}`]))
      jsonData.identifier_attributes = identifiersAttributes
    }

    return axios(apiConfig.post('/api/internal/unauthenticated/guest_screenings/contacts', jsonData)).then((response) => {
      const { data: { url } } = response
      window.location = url
    }).catch(err => {
      throw new Error(err.response.data.alert)
    })
  },

  renderDatePicker: (input, meta, maxDate) => {
    const { value, onChange, ...rest } = input
    const selected = value ? new Date(value) : null
    const { error, submitError, touched } = meta
    const fieldError = (touched && error) || submitError
    const showErrorMessages = typeof fieldError !== 'undefined'

    return (
      <Fragment>
        <DatePicker
          selected={selected}
          onChange={date => onChange(date)} {...rest}
          errors={fieldError ? [fieldError] : undefined}
          showErrorMessages={showErrorMessages}
          dataTestid={`${input.name}-input`}
          maxDate={maxDate}
        />
      </Fragment>
    )
  },

  notBlankValidation: (value) => {
    return value ? undefined : window.local.t('cannot_be_blank')
  }
}

export default surveyUtils
