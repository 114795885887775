import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/Icon'
import Spacing from '@components/Spacing'
import TierFilterOption from '@components/SearchFilters/components/TierFilterOption'

import './TierFilter.scss'

class TierFilter extends React.PureComponent {
  constructor (props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.renderOption = this.renderOption.bind(this)
    this.selectTier = this.selectTier.bind(this)
  }

  handleClose (event) {
    event.preventDefault()

    this.props.onClose()
  }

  selectTier (event) {
    this.props.onSelect(event.currentTarget.name)
  }

  renderOption (option = {}) {
    return (
      <TierFilterOption
        currentSelection={this.props.selectedOption}
        key={option.name}
        onChange={this.selectTier}
        {...option}
      />
    )
  }

  render () {
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <div>
        <header className='TierFilter__Instructions'>
          <Spacing paddingY={3} paddingX={4}>
            {this.props.instructions}

            <a href='' className='TierFilter__Close-Button' onClick={this.props.onClose}>
              <Icon iconKey='close' size={20} />
            </a>
          </Spacing>
        </header>
        <Spacing marginY={3} marginX={4}>
          {this.props.options.map(this.renderOption)}
        </Spacing>
      </div>
    )
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

TierFilter.propTypes = {
  instructions: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.string
}

export default TierFilter
