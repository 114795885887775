import React from 'react'
import PropTypes from 'prop-types'

import { Form, Field } from 'react-final-form'

import DialogActions from '@material-ui/core/DialogActions'

import Button from '@components/Button'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import TextInput from '@components/TextInput/ReactFinalFormTextInput'

const ResourceSitePrivacyStatusForm = ({ onSuccess, onClose, confirmText }) => {
  const validations = (values) => {
    const errors = {}

    if (values.verification !== confirmText) {
      errors.verification = 'The verification is invalid'
    }

    return errors
  }

  const handleSubmit = (e) => {
    onSuccess()
  }

  return (
    <Form onSubmit={handleSubmit}
      validate={validations}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Spacing marginBottom={2}>
            <Text>
              If you are absolutely sure about this change, type <br />
              <strong>{confirmText}</strong> into the box below and click "Confirm"
            </Text>
          </Spacing>

          <Spacing marginBottom={2}>
            <Field
              component={TextInput}
              name='verification'
              required
            />
          </Spacing>
          <DialogActions>
            <Button onClick={onClose} type='tertiary'>
              Cancel
            </Button>
            <Button
              children='Confirm'
              disabled={invalid}
              htmlType='submit'
              type='primary'
              inheritWidth
            />
          </DialogActions>
        </form>
      )}
    />
  )
}

ResourceSitePrivacyStatusForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired
}

export default ResourceSitePrivacyStatusForm
