import difference from 'lodash/difference'
import forOwn from 'lodash/forOwn'
import every from 'lodash/every'

const basicMergeableAttributes = [
  'dateOfBirth',
  'email',
  'firstName',
  'gender',
  'lastName',
  'middleName'
]

const complexMergeableAttributes = [
  'address',
  'identifiers',
  'phones'
]

const mergeableAttributes = Array.prototype.concat(basicMergeableAttributes, complexMergeableAttributes)

const requiredAttributesForClients = (state) => {
  const unselectableAttributes = unselectableAttributesBetweenClients(state)

  return difference(mergeableAttributes, unselectableAttributes)
}

export const selectedAttributesInComposite = (state) => {
  const { compositeClient } = state

  return Object
    .keys(compositeClient)
    .filter(attributeKey => {
      return !isEmpty(compositeClient[attributeKey])
    })
}

export const emptyFieldsInComposite = (state) => {
  const selectedAttributes = selectedAttributesInComposite(state)
  const requiredAttributes = requiredAttributesForClients(state)

  console.log('requiredAttributes:', requiredAttributes)
  console.log('selectedAttributes:', selectedAttributes)

  const diff = difference(requiredAttributes, selectedAttributes)

  console.log('diff:', diff)

  return diff
}

const dataMappings = (local = null) => ({
  firstName: {
    label: local && local.t('attributes.patient.first_name.label'),
    selectedMessage: local && local.t('attributes.patient.first_name.messages.selected'),
    icon: 'assignment_ind'
  },
  middleName: {
    label: local && local.t('attributes.patient.middle_name.label'),
    selectedMessage: local && local.t('attributes.patient.middle_name.messages.selected'),
    icon: 'assignment_ind'
  },
  lastName: {
    label: local && local.t('attributes.patient.last_name.label'),
    selectedMessage: local && local.t('attributes.patient.last_name.messages.selected'),
    icon: 'assignment_ind'
  },
  clientId: {
    label: null,
    icon: 'assignment_ind'
  },
  identifiers: {
    icon: 'assignment_ind',
    selectedMessage: local && local.t('attributes.patient.identifiers.messages.selected')
  },
  dateOfBirth: {
    label: null,
    selectedMessage: local && local.t('attributes.patient.date_of_birth.messages.selected'),
    icon: 'calendar_today'
  },
  gender: {
    label: null,
    selectedMessage: local && local.t('attributes.patient.gender.messages.selected'),
    icon: 'wc'
  },
  phone: {
    label: null,
    selectedMessage: local && local.t('attributes.patient.phones.messages.selected'),
    icon: 'phone'
  },
  phones: {
    icon: 'phone'
  },
  email: {
    label: null,
    selectedMessage: local && local.t('attributes.patient.email.messages.selected'),
    icon: 'send'
  },
  address: {
    label: null,
    selectedMessage: local && local.t('attributes.patient.address.messages.selected'),
    icon: 'place'
  }
})

export const iconDataFor = attribute => dataMappings()[attribute].icon

export const labelFor = (local, attribute) => dataMappings(local)[attribute].label

export const selectedMessage = (local, attributeName) => dataMappings(local)[attributeName].selectedMessage

const attributeIsMergeable = attributeName => mergeableAttributes.includes(attributeName)

const singleLineAddress = address => Object.values(address).join('||')

const phoneString = phone => (`${phone.number}::ext:${phone.extension || ''}::type:${phone.descriptor}`)

export const identifierString = identifier => `type:${identifier.type}::value:${identifier.value}`

const addressesAreTheSame = addresses => {
  const [firstAddress, secondAddress] = addresses.map((address) => {
    const { id, ...restOfAddress } = address
    return restOfAddress ? singleLineAddress(restOfAddress) : null
  })

  return firstAddress === secondAddress
}

export const getAttributeFromOtherPatient = (patients, compositePatient, attribute) => {
  if (!compositePatient[attribute]) return

  const source = compositePatient[attribute].source
  const filterOutSourcePatient = patient => (patient.id !== source && patient[attribute])

  const [patient] = patients.filter(filterOutSourcePatient)
  if (patient) {
    return patient[attribute]
  } else {
    return null
  }
}

const phoneDuplicates = (firstPatient, secondPatient) => {
  let firstPatientPhoneStore = {}
  let secondPatientPhoneStore = {}

  firstPatient.phones.forEach(currentPhone => {
    firstPatientPhoneStore[phoneString(currentPhone)] = currentPhone
  })
  secondPatient.phones.forEach(currentPhone => {
    secondPatientPhoneStore[phoneString(currentPhone)] = currentPhone
  })

  const duplicatePhoneKeys = Object.keys(firstPatientPhoneStore)
    .filter(phoneString => Object.keys(secondPatientPhoneStore).includes(phoneString))

  let duplicatePhones = []
  duplicatePhoneKeys.forEach(phoneKey => {
    duplicatePhones.push({
      inferred: true,
      source: firstPatient.id,
      value: firstPatientPhoneStore[phoneKey]
    })
  })

  return duplicatePhones
}

const identifierDuplicates = (firstPatient, secondPatient) => {
  let firstPatientIdentifierStore = {}
  let secondPatientIdentifierStore = {}

  firstPatient.identifiers.forEach(currentIdentifier => {
    firstPatientIdentifierStore[identifierString(currentIdentifier)] = currentIdentifier
  })
  secondPatient.identifiers.forEach(currentIdentifier => {
    secondPatientIdentifierStore[identifierString(currentIdentifier)] = currentIdentifier
  })

  const duplicateIdentifierKeys = Object.keys(firstPatientIdentifierStore)
    .filter(identifierString => Object.keys(secondPatientIdentifierStore).includes(identifierString))

  let duplicateIdentifiers = []
  duplicateIdentifierKeys.forEach(identifierKey => {
    duplicateIdentifiers.push({
      inferred: true,
      source: firstPatient.id,
      value: firstPatientIdentifierStore[identifierKey]
    })
  })

  return duplicateIdentifiers
}

export const commonAttributesBetweenClients = (state) => {
  const { firstPatient, secondPatient } = state
  const firstPatientKeys = Object.keys(firstPatient).filter(attributeIsMergeable)
  const secondPatientKeys = Object.keys(secondPatient).filter(attributeIsMergeable)
  const allUniqueKeys = [...new Set(firstPatientKeys.concat(secondPatientKeys))]

  let exactDuplicates = {}
  allUniqueKeys.forEach(key => {
    const currentPatient = firstPatient[key] ? firstPatient : secondPatient
    const value = currentPatient[key]
    if (value === null || value === undefined) return

    const valuesAreEqual = firstPatient[key] === secondPatient[key]
    const oneValueIsUndefined = firstPatient[key] === null ||
      firstPatient[key] === undefined ||
      secondPatient[key] === null ||
      secondPatient[key] === undefined
    if (valuesAreEqual || oneValueIsUndefined) {
      exactDuplicates[key] = { value: currentPatient[key], source: currentPatient.id, inferred: true }
    }
  })

  const intersectingKeys = firstPatientKeys.filter(key => secondPatientKeys.includes(key))

  const shouldMergeAddresses = intersectingKeys.includes('address') && addressesAreTheSame([firstPatient.address, secondPatient.address])
  if (shouldMergeAddresses) {
    exactDuplicates['address'] = {
      inferred: true,
      source: firstPatient.id,
      value: firstPatient.address
    }
  }

  const shouldMergePhones = intersectingKeys.includes('phones')
  if (shouldMergePhones) {
    const duplicatePhones = phoneDuplicates(firstPatient, secondPatient)

    if (duplicatePhones.length > 0) {
      exactDuplicates['phones'] = duplicatePhones
    }
  }

  const shouldMergeIdentifiers = intersectingKeys.includes('identifiers')
  if (shouldMergeIdentifiers) {
    const duplicateIdentifiers = identifierDuplicates(firstPatient, secondPatient)

    if (duplicateIdentifiers.length > 0) {
      exactDuplicates['identifiers'] = duplicateIdentifiers
    }
  }

  return exactDuplicates
}

export const assignInferredStatusToCompositeClient = (clients, compositeClient) => {
  const commonAttributes = commonAttributesBetweenClients({ firstPatient: clients[0], secondPatient: clients[1] })

  forOwn(commonAttributes, (object, key) => {
    if (key === 'identifiers') {
      commonAttributes[key].forEach(commonIdentifier => {
        (compositeClient[key] || []).forEach(compositeIdentifier => {
          const identifierKeyFromCommon = identifierString(commonIdentifier.value)
          const identifierKeyFromComposite = identifierString(compositeIdentifier.value)
          if (identifierKeyFromCommon === identifierKeyFromComposite) {
            compositeIdentifier['inferred'] = true
          }
        })
      })
    } else if (key === 'phones') {
      commonAttributes[key].forEach(commonPhone => {
        (compositeClient[key] || []).forEach(compositePhone => {
          const identifierKeyFromCommon = phoneString(commonPhone.value)
          const identifierKeyFromComposite = phoneString(compositePhone.value)
          if (identifierKeyFromCommon === identifierKeyFromComposite) {
            compositePhone['inferred'] = true
          }
        })
      })
    } else {
      compositeClient[key] = object
    }
  })

  return compositeClient
}

export const attributeExistsInComposite = (attributeName, compositeClient) => (
  Boolean(compositeClient[attributeName])
)

export const requiredNamesInComposite = (compositeClient) => {
  return every(
    ['firstName', 'lastName'].map((attr) => attributeExistsInComposite(attr, compositeClient)),
    Boolean
  )
}

export const valueIsUsedInComposite = (attributeName, attributeValue, compositeClient) => {
  if (!attributeExistsInComposite(attributeName, compositeClient)) { return false }

  const valueFromComposite = compositeClient[attributeName].value
  return valueFromComposite === attributeValue
}

export const identifierOfTypeExistsInComposite = (identifier, compositeClient) => {
  if (!compositeClient.identifiers) return false

  return Boolean(
    compositeClient
      .identifiers
      .filter(compositeIdentifier => compositeIdentifier.value.type === identifier.type)
      .length
  )
}

export const identifierIsPresentInComposite = (identifier, compositeClient) => {
  if (!compositeClient.identifiers) return false

  return Boolean(
    compositeClient.identifiers.find((currentIdentifier) => {
      return (currentIdentifier.value.id === identifier.id) || (
        currentIdentifier.value.value === identifier.value &&
        currentIdentifier.value.type === identifier.type
      )
    })
  )
}

export const phoneExtensionsMatch = (firstExtension, secondExtension) => {
  const bothValuesAreEmpty = isEmpty(firstExtension) && isEmpty(secondExtension)
  const bothValuesAreEqual = firstExtension === secondExtension

  return bothValuesAreEmpty || bothValuesAreEqual
}

export const phoneIsPresentInComposite = (phone, compositeClient) => {
  if (!compositeClient.phones) return false

  return Boolean(
    compositeClient.phones.find((currentPhone) => {
      return (currentPhone.value.id === phone.id) || (
        currentPhone.value.number === phone.number &&
        phoneExtensionsMatch(currentPhone.value.extension, phone.extension) &&
        currentPhone.value.type === phone.type
      )
    })
  )
}

export const getCollectionItemFromComposite = (attribute, item, compositeClient) => {
  if (!compositeClient[attribute]) return false

  return compositeClient[attribute].find(currentItem => currentItem.value.id === item.id)
}

export const isRedundantItem = (attribute, item, compositeClient) => {
  if (!compositeClient[attribute]) return false

  const inferredElements = compositeClient[attribute].filter(attr => attr.inferred)
  const isInferredElement = inferredElements.map(attr => attr.value.id).includes(item.id)

  const inferredValues = inferredElements.map(attr => attr.value.displayValue)
  const isInferredValue = inferredValues.includes(item.displayValue)

  return !isInferredElement && isInferredValue
}

const isEmpty = (attribute) => {
  const isEmptySimpleDatatype = attribute === null || attribute === undefined || attribute === ''
  const isEmptyCollectionDatatype = Array.isArray(attribute) && attribute.length === 0
  return (isEmptySimpleDatatype || isEmptyCollectionDatatype)
}

export const unselectableAttributesBetweenClients = (state) => {
  const { firstPatient, secondPatient } = state

  return mergeableAttributes.filter(attribute => {
    return isEmpty(firstPatient[attribute]) && isEmpty(secondPatient[attribute])
  })
}
