import React, { useContext } from 'react'

import Text from '@components/Text'
import Icon from '@components/Icon'

import { CartShareContext } from './CartShare'

export const DownloadButton = ({ ...props }) => {
  const { getString, cartShare } = useContext(CartShareContext)

  return (
    <a
      className='CartShare__DownloadButton Button Button--primary'
      href={cartShare.links.pdf}
      target='_blank'
      rel='noopener'
    >
      <Text style='buttons' withIcon>
        {getString('print_resources')}
        <Icon iconKey='print' size={12} />
      </Text>
    </a>
  )
}

export default DownloadButton
