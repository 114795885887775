import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@components/Form/Checkbox'
import Spacing from '@components/Spacing'

import './EndUserAttestation.scss'

class EndUserAttestation extends React.PureComponent {
  renderHeader () {
    if (this.props.header) {
      return <h6 className='orbital-form__divider-text EndUserAttestation__client-consent__header'>
        {this.props.header}
      </h6>
    }
  }

  renderDescription () {
    const { description, descriptionBelow } = this.props

    const marginTop = descriptionBelow ? 1 : 0
    const marginLeft = descriptionBelow ? 3 : 0

    return (
      <Spacing marginTop={marginTop} marginLeft={marginLeft}>
        <p className='EndUserAttestation__client-consent__description'>
          {description}
        </p>
      </Spacing>
    )
  }

  renderCheckbox () {
    const formName = this.props.formName

    return <Checkbox
      id={`${formName}_end_user_attestation`}
      name={`${formName}[end_user_attestation]`}
      label={this.props.checkboxLabel}
      onChange={this.props.onAttestation}
      required
    />
  }

  render () {
    const { descriptionBelow } = this.props

    return (
      <div data-test={this.props.dataTest}>
        {this.renderHeader()}
        {!descriptionBelow && this.renderDescription()}
        {this.props.children}
        {this.renderCheckbox()}
        {descriptionBelow && this.renderDescription()}
      </div>
    )
  }
}

EndUserAttestation.propTypes = {
  checkboxLabel: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  onAttestation: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  descriptionBelow: PropTypes.bool,
  children: PropTypes.node,
  dataTest: PropTypes.string,
  header: PropTypes.string
}

export default EndUserAttestation
