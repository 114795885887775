import axios from 'axios'
import apiConfig from '@config/apiConfig'

const baseEndpoint = '/api/internal/analytics'

const track = (modelName, eventType, properties, analyticsReferrer) => {
  const data = {
    ...analyticsReferrer,
    model_name: modelName,
    event_type: eventType,
    properties
  }

  return axios(apiConfig.post(baseEndpoint, data))
}

export default {
  track
}
