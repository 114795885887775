import { formatIdentifierForDisplay } from '@models/PatientIdentifier'
import AddressAdapter from './addressAdapter'

class ClientAdapter {
  constructor (networkResponse) {
    this.data = networkResponse.data.data
    this.included = networkResponse.data.included

    this.object = this.adaptObject(this.data.attributes)
  }

  gatherIncludedCollections () {
    let includes = {
      phone: [],
      patient_identifier: []
    }

    for (var i = this.included.length - 1; i >= 0; i--) {
      if (this.included[i].type === 'address') {
        includes.address = this.included[i].attributes
      } else {
        includes[this.included[i].type].push(this.included[i].attributes)
      }
    }

    return includes
  }

  normalizeClientIds (object) {
    const normalizedObject = {
      type: object.identifier_type,
      id: object.id,
      value: object.normalized_value
    }
    normalizedObject.displayValue = formatIdentifierForDisplay(normalizedObject)

    return normalizedObject
  }

  adaptObject (object) {
    const includes = this.gatherIncludedCollections()
    const client = {
      id: object.id,
      value: object.id,
      dateCreated: object.created_at,
      firstName: object.first_name,
      middleName: object.middle_name,
      lastName: object.last_name,
      dateOfBirth: object.date_of_birth,
      gender: object.gender,
      email: object.email,
      url: this.data.links.self
    }

    if (includes.address) {
      client.address = new AddressAdapter(includes.address).object
    }

    client.identifiers = includes.patient_identifier.map(this.normalizeClientIds)
    if (object.external_id) {
      client.identifiers.unshift({
        type: 'external',
        displayValue: `External: ${object.external_id}`,
        id: object.external_id,
        value: object.external_id
      })
    }

    client.phones = includes.phone

    return client
  }
}

export default ClientAdapter
