import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './Checkbox.css'

class Checkbox extends PureComponent {
  render () {
    const { dataTest, defaultChecked, name, label, id, onChange, required, isDisabled } = this.props
    let labelClass = 'Checkbox__label'
    if (required) labelClass += ' Checkbox__label--required'

    return (
      <div className='Checkbox'>
        <input
          data-test={dataTest}
          defaultChecked={defaultChecked}
          type='checkbox'
          name={name}
          className='Checkbox__input'
          id={id}
          onChange={onChange}
          disabled={isDisabled}
        />
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
      </div>
    )
  }
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataTest: PropTypes.string,
  defaultChecked: PropTypes.bool,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool
}

Checkbox.defaultProps = {
  required: false
}

export default Checkbox
