import React from 'react'
import PropTypes from 'prop-types'

import ReferralModalTrigger from './ReferralModalTrigger'
import ReferralModalContent from './ReferralModalContent'
import FollowupStep from './FollowupStep'
import ReferralShareActionsContent from './ReferralShareActionsContent'
import Modal from '@components/Modal'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import LegacyButton from '@components/legacy/LegacyButton'
import LoadingSpinner from '@components/LoadingSpinner'
import ErrorText from '@components/ErrorText'
import { COORDINATE, TRACK } from '@constants/referralTypes'

const View = ({ state, data, behavior }) => {
  return (
    <React.Fragment>
      <ReferralModalTrigger
        data={{
          isCoordinate: data.isCoordinate,
          referralContext: data.referralContext
        }}
        behavior={{
          toggleModal: behavior.toggleModal
        }}
      />
      {state.showModal && renderModal(state, data, behavior)}
    </React.Fragment>
  )
}

const renderModal = (state, data, behavior) => {
  if (state.showShareActions) {
    return renderModalSharing(state, data, behavior)
  } else {
    return renderModalForm(state, data, behavior)
  }
}

const renderModalFormFooter = (state) => {
  return state.submissionError
    ? <ErrorText text={state.submissionError} isCentered withIcon /> : null
}

const renderModalFormContent = (state, data, behavior) => {
  if (state.modalPage === 'creation') {
    return (
      <ReferralModalContent
        state={state}
        data={data}
        behavior={{
          loadOptions: behavior.loadOptions,
          handleSelectChange: behavior.handleSelectChange,
          handleClosedLoopConsentToggle: behavior.handleClosedLoopConsentToggle,
          handleConsentToggle: behavior.handleConsentToggle
        }}
      />
    )
  } else if (state.modalPage === 'followup') {
    return (
      <FollowupStep
        data={data}
        handleChange={behavior.handleFollowupFormChange}
        referralID={state.referralId}
        modalState={state}
      />
    )
  }
}

const renderModalForm = (state, data, behavior) => {
  return (
    <Modal
      classes={{
        paper: 'Modal ReferralModal'
      }}
      onSubmit={behavior.handleFormSubmit}
      open={state.showModal}
      onClose={behavior.toggleModal}
      headerText={window.local.t(`referral_modal.copy.title.${data.referralContext}`)}
      content={renderModalFormContent(state, data, behavior)}
      actions={renderSubmitButton(state, data, behavior)}
      dataTest='referral-modal-content'
      footer={renderModalFormFooter(state)}
    />
  )
}

const renderModalSharing = (state, data, behavior) => {
  return (
    <Modal
      classes={{
        paper: 'Modal ReferralModal'
      }}
      onSubmit={behavior.handleShareSubmit}
      open={state.showModal}
      onClose={behavior.toggleModal}
      content={
        <ReferralShareActionsContent
          state={state}
          data={data}
          behavior={{
            handleEmailInputChange: behavior.handleEmailInputChange,
            handleDownloadPDFClick: behavior.handleDownloadPDFClick,
            handleMemberLedCLRConsentClick: behavior.handleMemberLedCLRConsentClick,
            handleSendEmailClick: behavior.handleSendEmailClick,
            handleSendTextClick: behavior.handleSendTextClick,
            handleShareSubmit: behavior.handleShareSubmit,
            handleShareSelectChange: behavior.handleShareSelectChange
          }}
        />
      }
      actions={renderShareButtons(state, data, behavior)}
      dataTest='ReferralModal__Share'
    />
  )
}

const renderShareButtons = (state, data, behavior) => {
  return (
    <div>
      <LegacyButton
        style={{ marginBottom: '16px' }}
        color='blue'
        disabled={!state.shareFormValid}
        type={'submit'}
        data-test='ReferralModal__Share__Submit'
      >
        {window.local.t('referral_modal.fields.submit_share')}
      </LegacyButton>

      <LegacyButton
        color='blue'
        variant='outlined'
        onClick={behavior.toggleModal}
        type='button'
        data-test='ReferralModal__Share__Skip'
      >
        {window.local.t('referral_modal.fields.submit_skip')}
      </LegacyButton>

      { data.memberLedCLREnabled === true && (
        <Spacing paddingTop={2}>
          <Text style='body-text-small' tag='p'>
            {window.local.t('referral_modal.fields.enhanced_sharing_context')}
          </Text>
        </Spacing>
      )}
    </div>
  )
}

const renderSubmitButton = (state, data, behavior) => {
  const i18nKey = data.isCoordinate ? COORDINATE : TRACK

  const buttonContent = state.isProcessing
    ? <LoadingSpinner display='inline' size={18} />
    : window.local.t(`referral_modal.fields.submit.${i18nKey}`)

  const formIsInvalid = state.modalPage === 'creation'
    ? !state.formIsValid
    : !state.followupFormValid

  return (
    <LegacyButton
      color='blue'
      disabled={formIsInvalid || state.isProcessing}
      type={'submit'}
      data-test='ReferralModal__Create__Submit'
    >
      {buttonContent}
    </LegacyButton>
  )
}

View.propTypes = {
  state: PropTypes.object,
  data: PropTypes.object,
  behavior: PropTypes.object
}

export default View
