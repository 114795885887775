export const UNSTARTED = 'unstarted'
export const IN_REVIEW = 'in_review'
export const SERVICE_IN_PROGRESS = 'service_in_progress'
export const SERVICE_COMPLETED = 'service_completed'
export const CANCELED = 'canceled'

export default {
  UNSTARTED,
  IN_REVIEW,
  SERVICE_IN_PROGRESS,
  SERVICE_COMPLETED,
  CANCELED
}
