import React from 'react'
import PropTypes from 'prop-types'

import RadioButton from '@components/RadioButton'
import Spacing from '@components/Spacing'

import './ResourceKindFilter.scss'

const ResourceKindFilter = ({
  value,
  featureFlags,
  onFilterChange,
  ...props
}) => {
  const integratedSearchEnabled = featureFlags.integrated_search_results_flag === true

  return (
    <section className='ResourceKindFilter__Container' data-testid='ResourceKindFilter'>
      <header className='ResourceKindFilter__Header'>
        {window.local.t('resource_sites.kind.description')}
      </header>

      {integratedSearchEnabled && (<>
        <Spacing marginLeft={2}>
          <RadioButton
            checked={value === 'integrated'}
            label={window.local.t('resource_sites.kind.integrated')}
            name='resource_kind'
            onChange={onFilterChange}
            value='integrated'
          />
        </Spacing>
      </>)}

      <Spacing marginLeft={2}>
        <RadioButton
          checked={value === 'local'}
          label={window.local.t('resource_sites.kind.local')}
          name='resource_kind'
          onChange={onFilterChange}
          value='local'
        />
      </Spacing>

      <Spacing marginLeft={2}>
        <RadioButton
          checked={value === 'regional'}
          label={window.local.t('resource_sites.kind.regional')}
          name='resource_kind'
          onChange={onFilterChange}
          value='regional'
        />
      </Spacing>
    </section>
  )
}

ResourceKindFilter.propTypes = {
  featureFlags: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
  value: PropTypes.oneOf(['local', 'regional', 'integrated'])
}

ResourceKindFilter.defaultProps = {
  value: 'local'
}

export default ResourceKindFilter
