import React from 'react'
import PropTypes from 'prop-types'
import AddCoverageAreaForm from './CoverageAreaManager/Forms/AddCoverageAreaForm'
import useFetchResourceSiteCoverageAreaData from './hooks/useFetchResourceSiteCoverageAreaData'

import Grid from '@material-ui/core/Grid'
import Spacing from '@components/Spacing'
import LoadingDataWrapper from '@components/LoadingDataWrapper'
import CoverageAreaManager from './CoverageAreaManager'

const CoverageAreaManagerScene = ({ resourceSiteId }) => {
  const { loading } = useFetchResourceSiteCoverageAreaData(resourceSiteId)

  return (
    <Spacing paddingBottom={4}>
      <LoadingDataWrapper
        loadingData={loading}
        display='block'
        size={80}
      >
        <>
          {!loading && (
            <CoverageAreaManager resourceSiteId={resourceSiteId}>
              <Spacing paddingBottom={4}>
                <CoverageAreaManager.CoverageAreaCards />
              </Spacing>
              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <AddCoverageAreaForm />
                </Grid>
              </Grid>
            </CoverageAreaManager>
          )}
        </>
      </LoadingDataWrapper>
    </Spacing>
  )
}

CoverageAreaManagerScene.propTypes = {
  resourceSiteId: PropTypes.number.isRequired
}

export default CoverageAreaManagerScene
