import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import useGetGlobalState from '@hooks/useGetGlobalState'
import cartShareUtil from '../cartShareUtil'

import { format } from '@utils/selectUtil'
import { isPhone } from '@utils/stringUtil'

import Spacing from '@components/Spacing'
import Text from '@components/Text'
import CheckboxButton from '@components/CheckboxButton'
import Checkbox from '@components/Form/Checkbox'
import Select from '@components/Select'
import TextInput from '@components/TextInput'

const { getString } = cartShareUtil

const ShareAndConsentSection = (props) => {
  const showTextCheckbox = useGetGlobalState('settings.text_messaging_enabled')

  const showClientConsentField = useCallback(() => (
    props.sendText || props.sendEmail
  ))

  return <>
    <Spacing marginBottom={0.5}>
      <Text style='sub-header-extra-small' tag='h4'>
        {getString('form.share_options.header')}
      </Text>
    </Spacing>

    <Spacing marginBottom={2}>
      <Text style='body-text-small' tag='p'>
        {getString('form.enhanced_sharing_context')}
      </Text>
    </Spacing>

    <Spacing marginBottom={6}>
      {showTextCheckbox === true && <TextShareSection
        phones={props.phones}
        sendText={props.sendText}
        updateField={props.updateField}
      />}

      <EmailShareSection
        sendEmail={props.sendEmail}
        updateField={props.updateField}
        defaultEmail={props.defaultEmail}
      />

      {showClientConsentField() === true && <>
        <Spacing paddingTop={2}>
          <ClientConsentField
            consentGranted={props.consentGranted}
            updateField={props.updateField}
          />
        </Spacing>
      </>}
    </Spacing>
  </>
}

ShareAndConsentSection.propTypes = {
  consentGranted: PropTypes.bool.isRequired,
  defaultEmail: PropTypes.string.isRequired,
  phones: PropTypes.array.isRequired,
  sendEmail: PropTypes.bool.isRequired,
  sendText: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired
}

export default ShareAndConsentSection

const TextShareSection = (props) => {
  const showPhoneSelect = props.sendText

  const setSendText = () => {
    props.updateField('sendText', !props.sendText)

    if (props.sendText) {
      props.updateField('phone', null)
    }
  }

  return <>
    <CheckboxButton
      icon='chat'
      name='send_text'
      label={getString('form.share_options.send_text.checkbox')}
      selected={props.sendText}
      onChange={setSendText}
    />

    {showPhoneSelect === true && <Select
      isValidNewOption={isPhone}
      creatable
      formatCreateLabel={(phone) => getString(
        'form.share_options.send_text.create_label',
        { phone }
      )}
      name='phone_id'
      showDropdownIndicator
      required
      label={getString('form.share_options.send_text.select')}
      placeholder={getString('form.share_options.send_text.placeholder')}
      options={format.phones(props.phones)}
      onChange={value => { props.updateField('phone', value) }}
    />}
  </>
}

TextShareSection.propTypes = {
  phones: PropTypes.array.isRequired,
  sendText: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired
}

const EmailShareSection = (props) => {
  const setSendEmail = () => {
    props.updateField('sendEmail', !props.sendEmail)

    if (props.sendEmail) {
      props.updateField('email', props.defaultEmail)
    }
  }

  return <>
    <CheckboxButton
      icon='mail'
      name='send_email'
      label={getString('form.share_options.send_email.checkbox')}
      selected={props.sendEmail}
      onChange={setSendEmail}
    />

    {props.sendEmail && <TextInput
      id='member_share_email'
      label={getString('form.share_options.send_email.input')}
      onChange={value => { props.updateField('email', value) }}
      placeholder={getString('form.share_options.send_email.placeholder')}
      type='email'
      defaultValue={props.defaultEmail}
      required
    />}
  </>
}

EmailShareSection.propTypes = {
  sendEmail: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired,
  defaultEmail: PropTypes.string
}

const ClientConsentField = ({ consentGranted, updateField, ...props }) => {
  return <>
    <Spacing marginBottom={1}>
      <Checkbox
        id={'toggle-grant-success'}
        name='grant_consent'
        label={getString('form.grant_consent_label')}
        onChange={() => updateField('consentGranted', !consentGranted)}
        defaultChecked={consentGranted}
        required
      />
    </Spacing>
  </>
}

ClientConsentField.propTypes = {
  consentGranted: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired
}
