import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import './RadioButton.scss'

class RadioButton extends PureComponent {
  constructor (props) {
    super(props)

    this.input = React.createRef()
    this.inputId = props.id || `radio-button-id-${uuid()}`
    this.onChange = this.onChange.bind(this)
  }

  onChange (event) {
    this.props.onChange(event.target.value)
  }

  render () {
    const { ariaLabel, name, label, checked, value } = this.props

    return (
      <div className='RadioButton'>
        <input
          aria-label={ariaLabel}
          ref={this.input}
          type='radio'
          name={name}
          id={this.inputId}
          onChange={this.onChange}
          defaultChecked={checked}
          value={value}
          className='RadioButton__input'
        />

        <label htmlFor={this.inputId} style={{ display: 'inline' }} className='RadioButton__label'>
          {label}
        </label>
      </div>
    )
  }
}

RadioButton.displayName = 'RadioButton'

RadioButton.defaultProps = {
  checked: false,
  onChange: () => {}
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default RadioButton
