import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { timelineEventContentClassNames } from '../utils/referralTimelineUtil'

export default class AttachmentEvent extends React.PureComponent {
  renderTitle () {
    const { analyticsReferrer, attachment, userText } = this.props
    const url = `${attachment.links.self}?${(new window.URLSearchParams(analyticsReferrer)).toString()}`

    return <div className='ReferralTimeline__event__title'>
      {window.local.t('referrals.timeline.events.attachment.start', { user: userText })}
      <a href={url}>{attachment.assetFileName}</a>
      {window.local.t('referrals.timeline.events.attachment.end')}
    </div>
  }

  renderContent () {
    return <div className={timelineEventContentClassNames({
      eventType: this.props.type,
      isLastElement: this.props.isLastElement
    })} />
  }

  render () {
    return <Fragment>
      {this.renderTitle()}
      {this.renderContent()}
    </Fragment>
  }
}

AttachmentEvent.propTypes = {
  analyticsReferrer: PropTypes.object.isRequired,
  attachment: PropTypes.object.isRequired,
  isLastElement: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  userText: PropTypes.string.isRequired
}
