import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { getRecord } from '@selectors'
import { loadReferral, toggleKey, createReferralConsent } from '../actions'
import Alert from '@components/Alert/Alert'
import AlertWithCheckbox from '@components/AlertWithCheckbox/AlertWithCheckbox'
import ReferralEncounterModal from '../ReferralEncounterModal/ReferralEncounterModal'
import ReferralHeader from '../ReferralHeader/ReferralHeader'
import ReferralServiceDetails from '../ReferralServiceDetails/ReferralServiceDetails'
import ReferralTimeline from '../ReferralTimeline/ReferralTimeline'
import ReferralUpdateSidebar from '../ReferralUpdateSidebar/ReferralUpdateSidebar'
import ReferralConsentModal from '../ReferralConsentModal/ReferralConsentModal'
import useTitle from '@hooks/useTitle'
import { isPrivate } from '@utils/privacyStatusUtil'

import './Referral.scss'

export default (props) => {
  return (
    <Router basename='/en/referrals'>
      <Switch>
        <Route exact path='/:referralId' component={(props) => <ConnectedReferral {...props} />} />
      </Switch>
    </Router>
  )
}

const Referral = props => {
  const { referralId } = props.match.params

  useEffect(() => { props.loadReferral(referralId) }, [referralId])
  useTitle(window.local.t('referrals.overview.referral_number', { referral_id: referralId }))
  const [referralConsent, setReferralConsent] = useState(false)
  const [consentBannerVisible, showConsentBanner] = useState(true)

  useEffect(() => { showConsentBanner(!props.previousConsent) }, [props.previousConsent])

  if (!props.referral) return null

  const checkPrivacyStatus = () => {
    return props.clientPrivacyFlag && props.usesCoordinate && isPrivate(props.referral.privacyStatus)
  }

  const renderConsentAlert = (id) => {
    // Banner
    return <AlertWithCheckbox
      id={id}
      dataTest='Referral__AdditionalConsentRequired'
      label={window.local.t('referrals.show.consent.authorize_consent')}
      onChange={() => { setReferralConsent(!referralConsent) }}
      name='additional-consent-required'
      title={window.local.t('referrals.show.consent.additional_consent_title')}
      type='warning'
      body={window.local.t('referrals.show.consent.additional_consent')}
      onSubmit={submitReferralConsent}
      buttonDisabled={!referralConsent}
      isVisible={consentBannerVisible}
      defaultChecked={referralConsent}
    />
  }

  const submitReferralConsent = () => { props.createReferralConsent(referralId) }
  const closeConsentModal = () => { props.toggleKey('showConsentModal', false) }

  const renderAlert = () => {
    if (props.alert) {
      return (
        <Alert
          type={props.alert.type}
          title={props.alert.text} />
      )
    }
  }

  const hideEncounterModal = () => { props.toggleKey('showEncounterModal', false) }

  return (
    <div className='Referral_container'>
      { checkPrivacyStatus() && renderConsentAlert('banner-consent') }
      { checkPrivacyStatus() && renderAlert() }

      <Grid container justify='space-between'>
        <Grid item xs={12}>
          <ReferralHeader referralId={referralId} checkPrivacyStatus={checkPrivacyStatus} />
        </Grid>
        <Grid container justify='space-between'>
          <Grid item xs={12} md={3}>
            <ReferralUpdateSidebar
              referralId={referralId}
              checkPrivacyStatus={checkPrivacyStatus} />
          </Grid>
          <Grid item xs={false} md={1} />
          <Grid item md={8}>
            <ReferralServiceDetails referralId={referralId} />
            <ReferralTimeline referralId={referralId} />
          </Grid>
        </Grid>
      </Grid>

      <ReferralEncounterModal
        referralId={referralId}
        open={props.showEncounterModal}
        onClose={hideEncounterModal}
      />

      <ReferralConsentModal
        referralId={referralId}
        open={props.showConsentModal}
        onClose={closeConsentModal}
        content={renderConsentAlert('modal-consent')}
      />
    </div>
  )
}

Referral.propTypes = {
  alert: PropTypes.object,
  referral: PropTypes.object,
  match: PropTypes.object,
  loadReferral: PropTypes.func,
  createReferralConsent: PropTypes.func,
  showConsentModal: PropTypes.bool,
  showEncounterModal: PropTypes.bool,
  previousConsent: PropTypes.bool,
  toggleKey: PropTypes.func.isRequired,
  clientPrivacyFlag: PropTypes.bool,
  usesCoordinate: PropTypes.bool
}

const mapDispatchToProps = {
  loadReferral,
  toggleKey,
  createReferralConsent
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.match.params.referralId)

  return {
    referral,
    alert: state.referralView.alert.consentBanner,
    previousConsent: state.referralView.previousConsent,
    showConsentModal: state.referralView.showConsentModal,
    showEncounterModal: state.referralView.showEncounterModal,
    usesCoordinate: state.global.settings.uses_coordinate,
    clientPrivacyFlag: state.global.featureFlags.client_referral_privacy_flag
  }
}

export const ConnectedReferral = connect(mapStateToProps, mapDispatchToProps)(Referral)
