import { initialState } from './initial-state'
import {
  CLOSE_PUBLIC_TEXT_MODAL,
  OPEN_PUBLIC_TEXT_MODAL,
  UNAUTHENTICATED_TOGGLE_KEY,
  SURVEY_RESULTS_META_INFORMATION
} from '@scenes/unauthenticated/constants'

function unauthenticatedReducers (state = initialState, action) {
  switch (action.type) {
    case CLOSE_PUBLIC_TEXT_MODAL:
      return {
        ...state,
        textModal: {
          ...state.textModal,
          open: false
        }
      }
    case OPEN_PUBLIC_TEXT_MODAL:
      return {
        ...state,
        textModal: {
          ...state.textModal,
          open: true,
          currentModal: action.payload.key
        }
      }
    case UNAUTHENTICATED_TOGGLE_KEY:
      return {
        ...state,
        [action.payload.key]: action.payload.toggleValue
      }
    case SURVEY_RESULTS_META_INFORMATION:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default unauthenticatedReducers
