import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'

import useTitle from '@hooks/useTitle'
import { denormalizeRecords, getAssociation } from '@selectors'
import { initializeClosedLoopReferral } from '@scenes/unauthenticated/actions'

import LoadingDataWrapper from '@components/LoadingDataWrapper'
import PageHeader from '@components/PageHeader'
import ReferralPatientPanel from '@scenes/unauthenticated/components/ReferralPatientPanel'
import Spacing from '@components/Spacing'
import ClosedLoopReferralsUpdateForm from '@scenes/unauthenticated/ClosedLoopReferrals/cbo/ClosedLoopReferralsUpdateForm'

const renderHeader = ({ referral, action }) => {
  const sendingCompanyName = referral.company.name
  const descriptionKey = action
  const description = window.local.t(`closed_loop_referrals.description.${descriptionKey}`, { sendingCompanyName })

  return (
    <PageHeader
      title={window.local.t('closed_loop_referrals.title')}
      description={description}
    />
  )
}

renderHeader.propTypes = {
  referral: PropTypes.object.isRequired,
  action: PropTypes.string
}

const ClosedLoopReferralsPage = (props) => {
  const { referralUuid } = props.match.params

  const pageTitleType = props.action

  useTitle(window.local.t(`closed_loop_referrals.page_title.${pageTitleType}`))

  useEffect(() => {
    props.initializeClosedLoopReferral(referralUuid)
  }, [referralUuid])

  return (
    <div className='ClosedLoopReferralsPage'>
      <LoadingDataWrapper loadingData={!props.referral}>
        {() => (
          <Fragment>
            {renderHeader(props)}
            <Spacing marginX={12}>
              <Grid container spacing={5}>
                <Grid item sm={11} md={6}>
                  <ReferralPatientPanel patient={props.patient} referral={props.referral} />
                </Grid>
                <Grid item sm={11} md={5} lg={4}>
                  <ClosedLoopReferralsUpdateForm action={props.action} referralUuid={referralUuid} />
                </Grid>
              </Grid>
            </Spacing>
          </Fragment>
        )}
      </LoadingDataWrapper>
    </div>
  )
}

ClosedLoopReferralsPage.propTypes = {
  initializeClosedLoopReferral: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  action: PropTypes.string,
  patient: PropTypes.object,
  referral: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  let referrals = Object.values(state.global.entities.referral || {})

  let referral = referrals.find(referral => referral.uuid === ownProps.match.params.referralUuid)

  if (!referral) return {}

  referral = denormalizeRecords(state, referral)
  const patient = denormalizeRecords(state, getAssociation(state, referral, 'patient'))

  return {
    referral,
    patient
  }
}

const mapDispatchToProps = {
  initializeClosedLoopReferral
}

export default connect(mapStateToProps, mapDispatchToProps)(ClosedLoopReferralsPage)
