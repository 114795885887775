import React from 'react'
import PropTypes from 'prop-types'

import ElementWithTooltip from '@components/ElementWithTooltip'
import Icon from '@components/Icon'
import { CLOSED_LOOP, TRACK } from '@constants/referralTypes'

const ReferralModalTrigger = ({ data, behavior }) => {
  const { local } = window
  const buttonColor = data.referralContext === TRACK ? 'blue-inverse' : 'blue'
  const iconKey = data.referralContext === TRACK ? 'assignment' : 'send'

  const tooltipEnabled = data.referralContext === CLOSED_LOOP
  const tooltipContent = local.t('referral_modal.fields.submit.tooltip.closed_loop')

  return (
    <ElementWithTooltip
      enabled={tooltipEnabled}
      tooltipContent={tooltipContent}
    >
      <button
        className={`hfysg-button hfysg-button--${buttonColor}`}
        onClick={behavior.toggleModal}
        data-test='open-referral-modal'
        aria-label='Create referral'
        style={{ padding: '13px' }}
      >
        <span className='hfysg-button--with-icon'>
          {local.t(`referral_modal.fields.submit.${data.referralContext}`)}
          <Icon className='mi--push-left' iconKey={iconKey} size={12} />
        </span>
      </button>
    </ElementWithTooltip>
  )
}

ReferralModalTrigger.propTypes = {
  data: PropTypes.object,
  behavior: PropTypes.object
}

export default ReferralModalTrigger
