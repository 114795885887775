import React from 'react'
import PropTypes from 'prop-types'
import './Row.css'

class Row extends React.PureComponent {
  render () {
    return (
      <div
        {...this.props}
        className='Row'
      >
        {this.props.children}
      </div>
    )
  }
}

Row.propTypes = {
  children: PropTypes.node
}

export default Row
