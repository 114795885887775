import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import reject from 'lodash/reject'
import find from 'lodash/find'

import { searchSafeString } from '@utils/stringUtil'

import Text from '@components/Text'

import AutoCompleteResult from '../AutoCompleteResult'
import GuidedSearchResult from '../GuidedSearchResult'

import './AutoCompleter.scss'

class AutoCompleter extends React.Component {
  get normalizedTerm () {
    return searchSafeString(this.props.term)
  }

  optionName (option) {
    return option.attributes.name
  }

  optionId (option) {
    return +(option.attributes.id)
  }

  get unselectedOptions () {
    const { options, selectedOptions } = this.props

    return reject(options, (option) => {
      return find(selectedOptions, (selectedOption) => this.optionId(selectedOption) === this.optionId(option))
    })
  }

  get suggestions () {
    const { onOptionSelect, maxSuggestions } = this.props

    return this
      .unselectedOptions
      .filter((option) => this.optionName(option).match(new RegExp(this.normalizedTerm, 'ig')))
      .slice(0, maxSuggestions)
      .map((option, i) => <AutoCompleteResult
        term={this.normalizedTerm}
        key={i}
        result={option}
        onClick={onOptionSelect}
      />)
  }

  get searchOption () {
    const { onNonOptionSelect } = this.props

    if (onNonOptionSelect) {
      return (
        <GuidedSearchResult onClick={onNonOptionSelect}>
          <Text>
            {window.local.t(
              'search_filters.auto_completer.search_for',
              { term: this.normalizedTerm }
            )}
          </Text>
        </GuidedSearchResult>
      )
    }
  }

  renderNoMatchesText () {
    const { onNonOptionSelect } = this.props

    if (onNonOptionSelect === undefined && !this.suggestions.length) {
      return (
        <div className='AutoCompleter__options__no-matches'>
          {window.local.t(
            'search_filters.auto_completer.no_eligibility_matches',
            { term: this.normalizedTerm }
          )}
        </div>
      )
    }
  }

  optionsListClassNames () {
    return classNames(
      'AutoCompleter__options',
      {
        'AutoCompleter__options--without-term': !this.props.term
      }
    )
  }

  render () {
    return (
      <div className='AutoCompleter' data-testid='AutoCompleter'>
        <ul className={this.optionsListClassNames()} data-testid='AutoCompleter__options'>
          {this.suggestions}
          {this.searchOption}
          {this.normalizedTerm && this.renderNoMatchesText()}
        </ul>
      </div>
    )
  }
}

AutoCompleter.propTypes = {
  maxSuggestions: PropTypes.number,
  onNonOptionSelect: PropTypes.func,
  onOptionSelect: PropTypes.func,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  term: PropTypes.string
}

AutoCompleter.defaultProps = {
  maxSuggestions: 5,
  term: ''
}

AutoCompleter.displayName = 'AutoCompleter'

export default AutoCompleter
