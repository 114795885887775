import React, { PureComponent, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@components/Button'
import Story from '../Story'

class Buttons extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Primary Button'>
          <Grid item xs={3}>
            <Button type='primary'>Primary button</Button>
          </Grid>
        </Story>

        <Story title='Primary Button - Disabled'>
          <Grid item xs={3}>
            <Button type='primary' disabled>Primary button - Disabled</Button>
          </Grid>
        </Story>

        <Story title='Secondary Button'>
          <Grid item xs={3}>
            <Button type='secondary'>Secondary button</Button>
          </Grid>
        </Story>

        <Story title='Tertiary CTA'>
          <Button type='tertiary'>Tertiary button</Button>
        </Story>

        <Story title='Expansion Button'>
          <Button type='expansion'>Expansion button</Button>
        </Story>

        <Story title='Primary (Dark) Button'>
          <Grid item xs={3}>
            <Button type='primaryDark'>Primary (Dark) button</Button>
          </Grid>
        </Story>

        <Story title='Toolbar buttons'>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Button type='toolbar'>Toolbar item 1</Button>
            </Grid>
            <Grid item xs={2}>
              <Button type='toolbar'>Toolbar item 2</Button>
            </Grid>
            <Grid item xs={2}>
              <Button type='toolbar'>Toolbar item 3</Button>
            </Grid>
          </Grid>
        </Story>
      </Fragment>
    )
  }
}

Buttons.displayName = 'Buttons'

export default Buttons
