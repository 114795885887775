import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@components/Icon'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import './ProgressBar.scss'

const calculatePercentage = (completed, totalToComplete) => completed / totalToComplete * 100

const ProgressBar = ({ completed, totalToComplete }) => {
  const percentProcessed = calculatePercentage(completed, totalToComplete)

  const ShowTextOrCheck = () => {
    if (completed === totalToComplete) {
      return (
        <Icon iconKey='check_circle' color='green' size={64} />
      )
    } else {
      return <>
        <Spacing marginBottom={1}>
          <Text tag='h3' style='sub-header-medium'>
            {window.local.t('checkout.status_modal.title')}
          </Text>
        </Spacing>

        <Text tag='p'>
          {window.local.t('checkout.status_modal.context')}
        </Text>
      </>
    }
  }

  return (
    <section data-test='progress-modal' className='ProgressBar'>
      <ShowTextOrCheck />

      <div className='ProgressBar__progress'>
        <Spacing marginBottom={2}>
          <div className='ProgressBar__bar-container'>
            <div
              className='ProgressBar__bar-container__progress'
              style={{ width: `${percentProcessed}%` }}
            />
          </div>
        </Spacing>

        <Text>{completed} / {totalToComplete} Complete</Text>
      </div>
    </section>
  )
}

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  totalToComplete: PropTypes.number.isRequired
}

export default ProgressBar
