import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import reduce from 'lodash/reduce'
import includes from 'lodash/includes'

import { removeCartItem, removeCartItemUI, updateCartItem } from '@features/referralCheckout'
import {
  isInNetwork,
  isInNetworkPreferred,
  isOutOfNetwork,
  isOutOfNetworkClr
} from '@utils/networkTiersUtil'

import useEffectOnMount from '@hooks/useEffectOnMount'
import useLocalStorage from '@hooks/useLocalStorage'

import ResourceSiteTierBanner from '@scenes/resourceSites/ResourceSiteTierBanner'

import Card from '@components/Card'
import Icon from '@components/Icon'
import Checkbox from '@components/Form/Checkbox'
import Spacing from '@components/Spacing'
import ExpandableTextArea from '@components/ExpandableTextArea'
import Select from '@components/Select'
import RemovableTag from '@components/RemovableTag'
import StampWithIconAndToolTip from '@components/StampWithIconAndToolTip'

import Collapse from '@components/Animations/Collapse'
import SlideIn from '@components/Animations/SlideIn'

import './ReferralCard.scss'

const CardNoteSection = ({ onChangeNote, onFileUpload, onRemoveAttachment, cartItem }) => {
  const fileInputId = `referral_card_file_input${cartItem.id}`
  const textAreaId = `referral-note-field-${cartItem.id}`
  const wrapperClassName = 'ReferralCard__note__wrapper' +
    (cartItem.file ? ' ReferralCard__note__wrapper--with-attachment' : '')

  return (
    <div className='ReferralCard__form__element'>
      <label className='hfysg-h6' htmlFor={textAreaId}>
        {window.local.t('checkout.notes.label')}
      </label>
      <div className={wrapperClassName}>
        <ExpandableTextArea
          id={textAreaId}
          dataTest='referral-note'
          className='ReferralCard__note--textarea'
          inline
          placeholder={window.local.t(`checkout.notes.placeholder.${cartItem.legacyTier}`)}
          onChange={onChangeNote} />
        { cartItem.file &&
          <div className='ReferralCard__note__uploads'>
            <Spacing marginY={1}>
              <RemovableTag style='attachment' onRemove={onRemoveAttachment}>
                {cartItem.file.name}
              </RemovableTag>
            </Spacing>
          </div>
        }
        <label className='attachment-uploader ReferralCard__note__button' htmlFor={fileInputId}>
          <Icon className='attachment-uploader__icon' size={16} iconKey='attach_file' />
          <span className='attachment-uploader__copy font-sfd'>Add Attachment</span>
          <div className='file-select__shadow-dom'>
            <input
              id={fileInputId}
              className='file optional'
              type='file'
              onChange={onFileUpload} />
          </div>
        </label>
      </div>
    </div>
  )
}

CardNoteSection.propTypes = {
  onChangeNote: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  cartItem: PropTypes.shape({
    file: PropTypes.object,
    legacyTier: PropTypes.string,
    id: PropTypes.number
  }).isRequired
}

const CardContactServiceCheckbox = ({ cartItem, ...props }) => {
  const { id, tier } = cartItem

  return (isOutOfNetworkClr(tier) &&
    <div className='ReferralCard__form__element'>
      <Checkbox
        id={`${id}-toggle-contact-service`}
        name={'toggle_contact_service'}
        label={window.local.t('checkout.contact_service.label')}
        dataTest='ReferralCard__toggle_contact_service_checkbox'
        defaultChecked
        {...props}
      />
    </div>
  )
}

CardContactServiceCheckbox.propTypes = {
  cartItem: PropTypes.shape({
    id: PropTypes.number,
    tier: PropTypes.string
  }).isRequired
}

const ReferralCard = ({
  cartItem,
  removeCartItem,
  removeCartItemUI,
  updateCartItem,
  ...props
}) => {
  const [selectedProgramIds] = useLocalStorage('selectedProgramIds', [])

  useEffectOnMount(() => {
    // This gives non-React code a function to remove a referral
    if (!window.removeCartItemFromSpaUI) {
      window.removeCartItemFromSpaUI = {}
    }

    window.removeCartItemFromSpaUI[cartItem.id] = () => {
      removeCartItemUI(cartItem.id)
    }

    if (selectedProgramIds.length) {
      let matchedPrograms = reduce(
        cartItem.serviceOfferings,
        (matches, program) => {
          if (includes(selectedProgramIds, program.id)) matches.push(program)
          return matches
        }, [])

      updateCartItem(cartItem.id, { matchingPrograms: matchedPrograms })
    }
  })

  const onRemoveCartItem = () => {
    removeCartItem(cartItem.id, props.analytics)
  }

  const resourceNameContainer = () => {
    const { resourceSitesPath } = props
    const { organizationName, serviceName, item } = cartItem

    return (
      <>
        { organizationName && <h6 className='hfysg-h6'>{organizationName}</h6>}

        <h5 className='hfysg-h3'>
          <a href={`${resourceSitesPath}/${item.resource_site_id}`}>
            {serviceName}
          </a>
        </h5>
      </>
    )
  }

  const completeCardContent = () => {
    const { resourceSitesPath } = props

    return (
      <div className='ReferralCard--success'>
        <div>
          {resourceNameContainer(cartItem, resourceSitesPath)}
        </div>

        <span className='ReferralCard__context--success'>
          <Icon
            color='blue'
            iconKey='check'
            size={26}
          />
          <p className='ReferralCard__p--success'>Successful</p>
        </span>
      </div>
    )
  }

  const serviceOfferings = () => {
    const selectId = `select-resources-${cartItem.id}`

    return (
      <div className='ReferralCard__form__element'>
        <Select
          id={selectId}
          dataTest={`referral-checkout-service-select-${cartItem.id}`}
          required
          label={window.local.t('checkout.programs.label')}
          onChange={(value, event) => updateCartItem(cartItem.id, { matchingPrograms: value })}
          options={cartItem.serviceOfferings}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={window.local.t('checkout.programs.placeholder')}
          isMulti
          value={cartItem.matchingPrograms}
        />
      </div>
    )
  }

  const submitCompleteCard = () => {
    const { resourceSitesPath } = props

    return (
      <SlideIn direction='left'>
        <Spacing marginBottom={4}>
          <Card
            withPadding
            success
            content={completeCardContent(
              cartItem,
              cartItem.item.resource_site_id,
              resourceSitesPath
            )}
          />
        </Spacing>
      </SlideIn>
    )
  }

  const renderPrivacyStatusStamp = () => {
    const { privacyStatus } = cartItem

    if (privacyStatus === 'private') {
      return (
        <Spacing marginTop={1}>
          <StampWithIconAndToolTip
            text={privacyStatus}
            color='orange'
            iconKey='info'
            iconSize={12}
            tooltipContent={window.local.t('resource_sites.privacy_tooltip')}
            width='100px' />
        </Spacing>
      )
    }
  }

  const renderResourceInfoContainer = () => {
    const { resourceSitesPath } = props
    const { tier } = cartItem

    return (
      <div>
        {(isInNetwork(tier) || isOutOfNetworkClr(tier)) && <Spacing marginBottom={3}>
          <ResourceSiteTierBanner tier={tier} />
        </Spacing>}

        {resourceNameContainer(cartItem, resourceSitesPath)}
        {renderPrivacyStatusStamp(cartItem)}
      </div>
    )
  }

  const errors = Object.values(cartItem.errorObject).filter(error => error)
  const emphasisColor = isOutOfNetwork(cartItem.legacyTier) ? 'gray' : 'blue'

  return props.submitComplete ? submitCompleteCard() : (
    <Collapse collapse={cartItem.collapse}>
      <Spacing marginBottom={4}>
        <div
          className='ReferralCard'
          data-test={`checkout-referral-card-${cartItem.item.resource_site_id}`}
        >
          <Card
            errors={errors}
            outlineColor={emphasisColor}
            innerBorderColor={emphasisColor}
            shaded={isInNetworkPreferred(cartItem.tier)}
            onRemoveCard={onRemoveCartItem}
            header={renderResourceInfoContainer()}
          >
            {serviceOfferings(cartItem, props.updateCartItem)}
            <CardNoteSection
              onChangeNote={props.onChangeNote}
              onFileUpload={props.onFileUpload}
              onRemoveAttachment={props.onRemoveAttachment}
              cartItem={cartItem}
            />
            <CardContactServiceCheckbox
              cartItem={cartItem}
              onChange={props.onToggleContactService}
            />
          </Card>
        </div>
      </Spacing>
    </Collapse>
  )
}

ReferralCard.propTypes = {
  analytics: PropTypes.shape({
    referrerId: PropTypes.string, referrerType: PropTypes.string
  }).isRequired,
  resourceSitesPath: PropTypes.string,
  cartItem: PropTypes.shape({
    collapse: PropTypes.bool,
    id: PropTypes.number,
    item: PropTypes.object,
    tier: PropTypes.string,
    legacyTier: PropTypes.string,
    serviceName: PropTypes.string,
    errorObject: PropTypes.object,
    serviceOfferings: PropTypes.array,
    matchingPrograms: PropTypes.array,
    organizationName: PropTypes.string,
    privacyStatus: PropTypes.string
  }).isRequired,
  submitComplete: PropTypes.bool,
  onChangeNote: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onToggleContactService: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  removeCartItem: PropTypes.func.isRequired,
  removeCartItemUI: PropTypes.func.isRequired,
  updateCartItem: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  removeCartItem,
  removeCartItemUI,
  updateCartItem
}

export default connect(null, mapDispatchToProps)(ReferralCard)
