import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import useGetAssociation from '@hooks/useGetAssociation'
import { formatList } from '@utils/formatterUtil'

import ResourceSite from '@scenes/resourceSites/ResourceSite'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import { CartShareContext } from './CartShare'

export const ReferralCard = ({ referral, ...props }) => {
  const {
    getString
  } = useContext(CartShareContext)

  // @TODO: Replace these when a compound component for referrals is implemented
  const resourcePrograms = useGetAssociation(
    referral,
    'referralResourceProgramAssignments'
  )
  const resourceSite = useGetAssociation(referral, 'resourceSite')

  return (
    <article className='CartShare__ReferralCard card card--flat' data-testid='CartShare__ReferralCard'>
      <Spacing paddingY={1}>
        <header className='CartShare__ReferralCard__Header'>
          {resourceSite.organizationName && <Text tag='h4' style='sub-header-extra-small'>
            {resourceSite.organizationName}
          </Text>}
          <Spacing paddingBottom={4}>
            <Text tag='h2' style='sub-header-medium'>
              {referral.serviceName}
            </Text>
          </Spacing>
        </header>

        <section className='CartShare__ReferralCard__Body'>
          <Spacing paddingBottom={3}>
            <Spacing paddingBottom={1}>
              <Text tag='h3' style='sub-header-small'>
                {getString('referral_list.requested_services')}
              </Text>
            </Spacing>
            <Text tag='p' style='body-text'>
              {formatList({ elements: resourcePrograms.map(rp => rp.resourceProgramName) })}
            </Text>
          </Spacing>
        </section>

        <section className='CartShare__ReferralCard__Body'>
          <ResourceSite resourceSite={resourceSite}>
            <ResourceSite.ContactInfo />
          </ResourceSite>
        </section>
      </Spacing>
    </article>
  )
}

ReferralCard.propTypes = {
  referral: PropTypes.object.isRequired
}

export const ReferralList = ({ ...props }) => {
  const {
    associations: { getReferrals }
  } = useContext(CartShareContext)
  const referrals = getReferrals() || []

  return (
    <section className='CartShare__ReferralList'>
      {referrals.map((referral) => <ReferralCard referral={referral} key={referral.id} />)}
    </section>
  )
}

ReferralList.ReferralCard = ReferralCard

export default ReferralList
