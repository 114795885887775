import React from 'react'
import PropTypes from 'prop-types'

import PatientSelector from '@components/PatientSelector'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

const ClientSearchField = (props) => {
  const loadSelectionIntoState = (selection, { action }) => {
    if (action === 'clear') {
      props.onClientChange(null)
    } else if (action === 'select-option') {
      const eagerLoadedAssociations = ['address']
      props.onClientChange(selection.value, eagerLoadedAssociations)
    }
  }

  return (
    <div className='GuidedSearchForm__ClientSelect' data-testid='ClientSearchField'>
      <Spacing marginRight={1}>
        <Text brandingEmphasis style='body-text'>
          {window.local.t('resource_sites.guided_search_form.client.label')}
        </Text>
      </Spacing>

      <PatientSelector
        aria-label={window.local.t('resource_sites.guided_search_form.client.placeholder')}
        className='GuidedSearchForm__ClientSelect__PatientSelector'
        value={props.client}
        name='referral[patient_id]'
        onChange={loadSelectionIntoState}
        placeholder={window.local.t('resource_sites.guided_search_form.client.placeholder')}
      />
    </div>
  )
}

ClientSearchField.propTypes = {
  onClientChange: PropTypes.func.isRequired,
  client: PropTypes.object
}

export default ClientSearchField
