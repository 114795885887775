import React from 'react'
import PropTypes from 'prop-types'
import ReferralModal from '@scenes/resourceSites/ReferralModal'
import Referral from '@scenes/resourceSites/Referral'
import useFetchResourceSiteData from '../ResourceSite/useFetchResourceSiteData'
import useGetRecord from '@hooks/useGetRecord'
import useGetAssociation from '@hooks/useGetAssociation'
import resourceSiteService from '@services/resourceSiteService'

export const EmbeddedReferralModal = ({ resourceSiteId, ...props }) => {
  const { loading } = useFetchResourceSiteData(resourceSiteId)
  const resourceSite = useGetRecord(resourceSiteService.type, resourceSiteId)
  const programs = useGetAssociation(resourceSite, 'resourcePrograms', { defaultValue: [] })
  const { organizationName, privacyStatus } = resourceSite || {}
  const referralModalProps = { ...props, organizationName, programs, privacyStatus }

  return (!loading &&
    <Referral
      resourceSite={resourceSite}>
      <ReferralModal {...referralModalProps} />
    </Referral>
  )
}

EmbeddedReferralModal.propTypes = {
  resourceSiteId: PropTypes.number.isRequired
}

export default EmbeddedReferralModal
