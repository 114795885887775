import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Text.scss'

class Text extends React.PureComponent {
  getClasses () {
    const {
      alignment,
      brandingEmphasis,
      display,
      renderNewlines,
      style,
      withIcon
    } = this.props

    return classNames(
      'Text',
      `Text--style--${style}`,
      `Text--display--${display}`,
      `Text--alignment--${alignment}`,
      {
        'Text--display--with-icon': withIcon,
        'Text--pre-wrap': renderNewlines,
        'Text--style--branding-emphasis': brandingEmphasis
      }
    )
  }

  render () {
    const { tag } = this.props
    const Tag = tag

    return (
      <Tag className={this.getClasses()}>
        {this.props.children}
      </Tag>
    )
  }
}

Text.displayName = 'Text'

Text.defaultProps = {
  alignment: 'inherit',
  brandingEmphasis: false,
  display: 'inherit',
  style: 'body-text',
  tag: 'span',
  withIcon: false
}

Text.propTypes = {
  alignment: PropTypes.oneOf([
    'center',
    'inherit',
    'left',
    'right'
  ]),
  children: PropTypes.node.isRequired,
  brandingEmphasis: PropTypes.bool,
  display: PropTypes.oneOf(['inline', 'block', 'inherit']),
  renderNewlines: PropTypes.bool,
  style: PropTypes.oneOf([
    'body-text',
    'body-text-emphasis',
    'body-text-secondary',
    'body-text-small',
    'buttons',
    'error-message',
    'form-fields',
    'form-fields-disabled',
    'form-fields-hint',
    'global-navigation',
    'page-header',
    'sub-header-extra-small',
    'sub-header-extra-small-secondary',
    'sub-header-large',
    'sub-header-medium',
    'sub-header-small',
    'success-message',
    'stamp'
  ]),
  tag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'section',
    'span',
    'div'
  ]),
  withIcon: PropTypes.bool
}

export default Text
