export const serializeCoverageArea = ({
  city,
  county,
  state,
  type,
  zipcode
}) => {
  const params = {
    coverage_area: { city, county, state, zipcode },
    type
  }

  return params
}
