import React, { Component } from 'react'
import Routes from '@routes/clientMerge'

class ClientMergeApp extends Component {
  render () {
    return (
      <Routes />
    )
  }
}

export default ClientMergeApp
