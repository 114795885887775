import Client from '@models/Client'
import CompositeClient from '@models/CompositeClient'
import User from '@models/User'

export default function ClientMerge (object) {
  return {
    ...object,
    clients: object.clients.map(client => Client(client)),
    compositeClient: CompositeClient(object.compositeClient),
    requester: User(object.requester),
    object
  }
}
