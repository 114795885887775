import pick from 'lodash/pick'

export const formattedStateForHTTPRequest = (formState) => {
  const data = baseRequestStructure

  const responses = formState.intakeQuestions
  Object.entries(responses).forEach(pushResponses(data))

  return data
}

const keysPermittedOverAPI = [
  'intake_question_id',
  'intake_response_option_id',
  'value'
]

const baseRequestStructure = {
  intake_response_set: {
    intake_responses_attributes: []
  }
}

const permittedResponseObject = inputObject => pick(inputObject, keysPermittedOverAPI)

const pushResponses = (data) => {
  return ([questionID, response]) => {
    if (Array.isArray(response.value)) {
      response.value.forEach(responseValue => { pushResponse(data, questionID, responseValue) })
    } else {
      pushResponse(data, questionID, response)
    }
  }
}

const pushResponse = (data, questionID, response) => {
  const valueIsEmpty = !response.value || !response.value.length
  if (valueIsEmpty) return

  data.intake_response_set.intake_responses_attributes.push(
    permittedResponseObject({ ...response, intake_question_id: questionID })
  )
}
