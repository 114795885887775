import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '@components/ExpandableTextArea/ExpandableTextArea.scss'

const Textarea = ({ inline, ...otherProps }) => {
  return (
    <textarea
      className={classNames(
        'ExpandableTextArea__textarea',
        { 'ExpandableTextArea__textarea--withPadding': !inline }
      )}
      {...otherProps}
    />
  )
}

Textarea.propTypes = {
  inline: PropTypes.bool
}

export default Textarea
