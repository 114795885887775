import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'

const Toggle = ({ name, isToggled, onChange }) => {
  const fieldID = useMemo(() => `tier-filter-option-${name}-${uuid()}`, [name])

  return (
    <label htmlFor={fieldID} data-test='toggle' className='toggle'>
      <input
        name={name}
        id={fieldID}
        data-testid={name}
        defaultChecked={isToggled}
        onChange={onChange}
        type='checkbox' />
      <span className='toggle__slider' />
    </label>
  )
}

Toggle.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Toggle
