import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Text from '@components/Text'
import Spacing from '@components/Spacing'
import ExpansionPanel from '@components/ExpansionPanel'

import ReferralServiceDetailsRow from '@scenes/referralShow/ReferralServiceDetailsRow/ReferralServiceDetailsRow'

import './ReferralServiceDetails.scss'
import { getCollectionFromRelationship, denormalizeRecords, getAssociation, getRecord } from '@selectors'
import {
  mostRecentEncounterPerResourceProgram,
  sortReferralEncountersByDates
} from '@scenes/referralShow/selectors'
import { formatDate, formatName } from '@utils/formatterUtil'
import { serviceDetailSummaryText } from '@scenes/referralShow/utils/serviceDetailSummaryTextUtil'

class ReferralServiceDetails extends PureComponent {
  renderMostRecentReferralEncounter (resourceProgramAssignment) {
    const mostRecentReferralEncounter = this.props.mostRecentEncounters[resourceProgramAssignment.id]

    if (mostRecentReferralEncounter) {
      const user = formatName(mostRecentReferralEncounter.user)

      const text = serviceDetailSummaryText({
        referralEncounter: mostRecentReferralEncounter,
        resourceProgramAssignmentId: resourceProgramAssignment.id
      })

      return (
        <Fragment>
          <Text style='body-text' tag='div'>{text}</Text>
          <Spacing marginTop={1}>
            <Text style='body-text-small' tag='div'>{window.local.t('referrals.show.service_details.logged_by', { user })}</Text>
          </Spacing>
        </Fragment>
      )
    } else {
      return (
        <Text style='body-text-secondary'>
          {window.local.t('referrals.show.service_details.actions.none')}
        </Text>
      )
    }
  }

  renderSectionTitle () {
    if (this.props.mostRecentEncounterDate) {
      return (
        <Text style='sub-header-medium' tag='h1' display='inline'>
          {window.local.t('referrals.status.header.with_updates', {
            updated_at: formatDate(this.props.mostRecentEncounterDate)
          })}
        </Text>
      )
    } else {
      return (
        <Text style='body-text-secondary' tag='h1' display='inline'>
          {window.local.t('referrals.status.header.no_updates')}
        </Text>
      )
    }
  }

  render () {
    if (!this.props.resourceProgramAssignments.length) {
      return (
        <Spacing marginBottom={4}>
          <Text style='body-text-secondary' tag='h1' display='inline'>
            {window.local.t('referrals.status.header.no_service_offerings')}
          </Text>
        </Spacing>
      )
    }

    let referralServiceDetails = ''

    if (!this.props.pdf) {
      referralServiceDetails = <ExpansionPanel
        title={this.renderSectionTitle()}
        expandText={window.local.t('referrals.show.service_details.show_details')}
        collapseText={window.local.t('referrals.show.service_details.hide_details')}
        expanded
      >
        <ReferralServiceDetailsRow
          referralId={this.props.referralId}
          resourceProgramAssignments={this.props.resourceProgramAssignments} />
      </ExpansionPanel>
    } else {
      referralServiceDetails = <Fragment>
        {this.renderSectionTitle()}
        <ReferralServiceDetailsRow
          referralId={window.referralProps.data.id.toString()}
          resourceProgramAssignments={this.props.resourceProgramAssignments} />
      </Fragment>
    }

    return (
      <Spacing paddingBottom={4}>
        <div className='ReferralServiceDetails' data-test='service-details-section'>
          <Spacing paddingBottom={3}>
            {referralServiceDetails}
          </Spacing>
        </div>
      </Spacing>
    )
  }
}

ReferralServiceDetails.propTypes = {
  mostRecentEncounterDate: PropTypes.string,
  mostRecentEncounters: PropTypes.object.isRequired,
  resourceProgramAssignments: PropTypes.array.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  referralId: PropTypes.string,
  pdf: PropTypes.bool
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const resourceProgramAssignments = getAssociation(state, referral, 'referralResourceProgramAssignments')
  const referralEncounters = getCollectionFromRelationship({
    state,
    type: 'referral_encounter',
    association: 'referral',
    id: props.referralId
  })

  const denormalizedReferralEncounters = denormalizeRecords(state, referralEncounters)
  const mostRecentEncounters = mostRecentEncounterPerResourceProgram(
    resourceProgramAssignments,
    denormalizedReferralEncounters
  )

  const mostRecentEncounter = sortReferralEncountersByDates(denormalizedReferralEncounters)[0]
  const mostRecentEncounterDate = mostRecentEncounter && mostRecentEncounter.dateOfAction

  return {
    resourceProgramAssignments,
    mostRecentEncounterDate,
    mostRecentEncounters
  }
}

export default connect(mapStateToProps, null)(ReferralServiceDetails)
