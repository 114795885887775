import React from 'react'
import PropTypes from 'prop-types'

import Textarea from './Textarea'

const RFFTextarea = ({ input, meta, ...rest }) => (
  <Textarea
    {...rest}
    onChange={(event) => { input.onChange(event.target.value) }}
  />
)

RFFTextarea.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default RFFTextarea
