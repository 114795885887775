import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import EditClientButton from './Buttons/EditClientButton'

import Grid from '@material-ui/core/Grid'
import Icon from '@components/Icon'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import { ClientDetailsContext } from './ClientDetails'

const AssignedTeamMembers = props => {
  const {
    client: { canAssignUsers },
    getFormattedAssignedUsers,
    getString
  } = useContext(ClientDetailsContext)

  return (
    <Spacing paddingTop={1}>
      <Grid
        container
        spacing={4}
        direction='row'
        alignItems='baseline'
      >
        <Grid item>
          <Text style='body-text' tag='p'>
            <Text style='body-text-emphasis'>{getString('header.team_members')}:</Text>{' '}
            <Text style='body-text' tag='span'>
              {getFormattedAssignedUsers()}
            </Text>
          </Text>
        </Grid>
        {canAssignUsers && (
          <Grid item>
            {/* @TODO Enable this button when we receive requirements */}
            <a href='#'>
              <Text style='body-text-small' tag='span'>
                {getString('header.edit_team_members')}
              </Text>
            </a>
          </Grid>
        )}
      </Grid>
    </Spacing>
  )
}

const Header = ({ className = '', ...props }) => {
  const {
    client: {
      isAhcPatient,
      links
    },
    getFormattedName,
    getFormattedDateOfBirth,
    getString
  } = useContext(ClientDetailsContext)
  const combinedClassNames = ['ClientDetails__Header', className].join(' ')

  return (
    <header className={combinedClassNames}>
      <Spacing paddingBottom={3}>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='flex-end'
        >
          <Grid item>
            <a href={links.back}>
              <Icon
                iconKey='keyboard_arrow_left'
                size={20}
                color='black'
                verticalAlignment='middle'
              />
              <Text style='body-text-small' tag='span'>
                {getString('header.back')}
              </Text>
            </a>
          </Grid>
          {isAhcPatient && (
            <Grid item>
              <Text style='body-text-small' tag='span'>
                <a href={links.ahcNeeds}>
                  {getString('header.ahc_nav_needs_link')}
                </a>
              </Text>
            </Grid>
          )}
        </Grid>
      </Spacing>

      <Spacing paddingBottom={3}>
        <Grid
          container
          spacing={2}
          direction='row'
          justify='space-between'
          alignItems='flex-end'
        >
          <Grid item xs={12} sm={9} md={10}>
            <Text tag='h2' style='sub-header-large'>{`${getFormattedName()} ${getFormattedDateOfBirth()}`}</Text>
            <AssignedTeamMembers />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <EditClientButton />
          </Grid>
        </Grid>
      </Spacing>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header
