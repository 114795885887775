import React, { PureComponent } from 'react'
import ErrorPage from '@components/ErrorPage'
import '@components/ErrorPage/ErrorPage.css'

class DefaultErrorPage extends PureComponent {
  render () {
    const listOfSuggestions = [
      window.local.t('error_page.next_steps.refresh'),
      window.local.t('error_page.next_steps.address'),
      window.local.t('error_page.next_steps.notified'),
      window.local.t('error_page.next_steps.email')
    ]

    return <ErrorPage
      title={window.local.t('error_page.next_steps.header')}
      listOfSuggestions={listOfSuggestions} />
  }
}

export default DefaultErrorPage
