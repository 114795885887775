import React from 'react'
import PropTypes from 'prop-types'

import './ErrorText.scss'
import Icon from '@components/Icon'

export default class ErrorText extends React.PureComponent {
  render () {
    const { text, isCentered, withIcon } = this.props
    const className = 'ErrorText__container' + (isCentered ? ' center' : '')

    return <div className={className}>
      <div role='errormessage' className='text-with-icon'>
        {withIcon && <Icon className='mi--push-right' iconKey='error_outline' size={16} />}
        <span>{text}</span>
      </div>
    </div>
  }
}

ErrorText.propTypes = {
  withIcon: PropTypes.bool,
  text: PropTypes.string.isRequired,
  isCentered: PropTypes.bool
}
