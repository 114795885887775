import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sortBy, startCase } from 'lodash'
import Grid from '@material-ui/core/Grid'

import AddressDisplay from '@components/AddressDisplay'
import Text from '@components/Text'
import Alert from '@components/Alert'
import Icon from '@components/Icon'
import UploadAttachmentModal from '@components/UploadAttachmentModal'
import Button from '@components/Button'
import ExpansionPanel from '@components/ExpansionPanel'
import Spacing from '@components/Spacing'
import DeleteAttachmentModal from '@components/DeleteAttachment/DeleteAttachmentModal/DeleteAttachmentModal'

import { denormalizeRecords, getAssociation, getRecord } from '@selectors'
import { formatPhoneNumber, formatDate, formatName, formatAddress } from '@utils/formatterUtil'
import { toggleKey, uploadAttachment, destroyAttachment, setAlert } from '../actions'

import './ReferralDetails.scss'

// NOTE: Code sections:
// Callbacks
// Helpers
// Rendering
// Final render

class ReferralDetails extends PureComponent {
  constructor (props) {
    super(props)

    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.uploadAttachment = this.uploadAttachment.bind(this)
  }

  // --------------------
  // Callbacks
  // --------------------

  deleteAttachment (id) {
    return () => {
      if (this.props.checkPrivacyStatus() && this.props.previousConsent === false) {
        this.props.toggleKey('showConsentModal', true)
      } else {
        this.props.toggleKey('pendingDeleteAttachmentId', id)
      }
    }
  }

  uploadAttachment () {
    if (this.props.checkPrivacyStatus() && this.props.previousConsent === false) {
      this.props.toggleKey('showConsentModal', true)
    } else {
      this.props.setAlert('uploadAttachment', null)
      this.props.toggleKey('showUploadAttachmentModal', true)
    }
  }

  // --------------------
  // Helpers
  // --------------------

  notApplicableText () {
    return window.local.t('general.not_applicable')
  }

  getEmail () {
    return this.props.patient.email || this.notApplicableText()
  }

  getPhoneNumber () {
    const { phones } = this.props
    return phones.length ? formatPhoneNumber(this.props.phones[0]) : this.notApplicableText()
  }

  attachmentToDelete () {
    if (this.props.pendingDeleteAttachmentId) {
      const attachment = this.props.attachments.find(
        attachment => attachment.id === this.props.pendingDeleteAttachmentId
      )

      return {
        name: attachment.assetFileName,
        id: attachment.id
      }
    }

    return null
  }

  formatIdentifiers () {
    const formattedIdentifiers = []

    if (this.props.patient.externalId) {
      formattedIdentifiers.unshift({
        type: 'External',
        value: this.props.patient.externalId
      })
    }

    this.props.identifiers.map(identifier => {
      if (identifier) {
        formattedIdentifiers.unshift({
          type: startCase(identifier.identifierType),
          value: identifier.normalizedValue
        })
      }
    })

    return formattedIdentifiers
  }

  sortedIdentifiers () {
    return sortBy(this.formatIdentifiers(), ['type'])
  }

  receivingCompanyCannotReceiveAttachments () {
    return this.props.resourceSite.inUserNetwork &&
      !this.props.resourceSite.canReceiveAttachments &&
      this.props.resourceSiteCompany
  }

  getRecipientAttachmentDisabledWarning () {
    if (this.receivingCompanyCannotReceiveAttachments()) {
      return window.local.t('referrals.details.attachments.recipient_attachments_disabled',
        { company_name: this.props.resourceSiteCompany.name })
    } else {
      return null
    }
  }

  // For self referrals, the client is the displayed referrer
  referrerInformation () {
    const { selfReferral } = this.props.referral

    const referrerLine1 = selfReferral
      ? 'Client Created'
      : this.props.referrerCompany.name

    const referrerLine2 = selfReferral
      ? formatName(this.props.patient)
      : `${formatName(this.props.referrer)}, ${this.props.referrerTeam.name}`

    return { referrerLine1, referrerLine2 }
  }

  // --------------------
  // Rendering
  // --------------------

  renderAddress () {
    const { address } = this.props
    return address ? <AddressDisplay singleLine address={address} /> : this.notApplicableText()
  }

  renderContactInformation () {
    return (
      <Fragment>
        <Spacing marginY={0.5}>
          <Text style='body-text' tag='div'>
            {window.local.t('referrals.details.client.info.phone', { phone: this.getPhoneNumber() })}
          </Text>
        </Spacing>
        <Spacing marginY={0.5}>
          <Text style='body-text' tag='div'>
            {window.local.t('referrals.details.client.info.email', { email: this.getEmail() })}
          </Text>
        </Spacing>
        <Spacing marginY={0.5}>
          <Text style='body-text' tag='div'>
            {window.local.t('referrals.details.client.info.address')}
            {this.renderAddress()}
          </Text>
        </Spacing>
      </Fragment>
    )
  }

  renderClientConsent () {
    if (this.props.referral.endUserAttestation) {
      return (
        <Spacing marginTop={3}>
          <Text style='sub-header-extra-small' tag='h3' withIcon>
            <Icon iconKey='check_circle' color='green' size={12} />
            {window.local.t('referrals.details.client.consent.title')}
          </Text>
          <Spacing marginTop={0.5}>
            <Text style='body-text-secondary' tag='div'>
              {
                window.local.t(
                  'referrals.details.client.consent.context',
                  {
                    full_name: formatName(this.props.patient),
                    service_name: this.props.referral.serviceName,
                    date: formatDate(this.props.referral.createdAt)
                  }
                )
              }
            </Text>
          </Spacing>
        </Spacing>
      )
    }

    return null
  }

  renderIdentifiers () {
    return this.sortedIdentifiers().map(identifier => {
      const key = `ReferralDetails__identifier-${identifier.type}`

      return <div className='ReferralDetails__identifiers' key={key} data-testid='ReferralDetails__identifier'>
        {`${identifier.type} ID: ${identifier.value}`}
      </div>
    })
  }

  renderRecipients () {
    if (this.props.assignedUsers && this.props.assignedUsers.length > 0) {
      return this.props.assignedUsers.map(user => (
        <Spacing marginY={0.5} key={user.id}>
          <Text style='body-text-secondary' tag='div'>
            {`${formatName(user)}, ${user.team.name}`}
          </Text>
        </Spacing>
      ))
    } else {
      return <Spacing marginY={0.5}>
        <Text style='body-text-secondary' tag='div'>
          {window.local.t('referrals.details.care_team.no_recipients')}
        </Text>
      </Spacing>
    }
  }

  renderAssignedUsers () {
    if (this.props.resourceSite.inUserNetwork) {
      return (
        <Fragment>
          <Text style='sub-header-extra-small' tag='h3'>
            {window.local.t('referrals.details.care_team.recipients')}
          </Text>
          <Spacing marginY={0.5}>
            <Text style='body-text' tag='div'>
              {this.props.resourceSite.serviceName}
            </Text>
          </Spacing>
          <Spacing marginBottom={3} dataTest='recipient-details'>
            {this.renderRecipients()}
          </Spacing>
        </Fragment>
      )
    }

    return null
  }

  renderAttachmentInnerContent () {
    if (this.props.attachments.length) {
      return this.props.attachments.map(attachment => (
        <Spacing marginBottom={2} key={attachment.id}>
          <div className='ReferalDetails__attachments-delete'>
            <div>
              <Text style='body-text' tag='div'>
                <a
                  href={`${attachment.links.self}?${(new window.URLSearchParams(this.props.analyticsReferrer)).toString()}`}
                >
                  {attachment.assetFileName}
                </a>
              </Text>
              <Text style='body-text-secondary' tag='div'>
                {
                  window.local.t(
                    'referrals.details.attachments.uploaded_at',
                    { date: formatDate(attachment.createdAt) }
                  )
                }
              </Text>
            </div>
            {!this.props.pdf && attachment.canDelete &&
              <div>
                <Button type='tertiary' onClick={this.deleteAttachment(attachment.id)}>
                  {window.local.t('referrals.details.attachments.delete')}
                </Button>
              </div>
            }
          </div>
        </Spacing>
      ))
    } else {
      return (
        <Spacing marginBottom={2}>
          <Text style='body-text-secondary' tag='div'>
            {window.local.t('referrals.details.attachments.none')}
          </Text>
        </Spacing>
      )
    }
  }

  renderAttachments () {
    if (!this.props.attachmentsEnabled) {
      return null
    }

    return (
      <div data-test='referral-attachments'>
        <Spacing paddingTop={4} paddingBottom={3}>
          <Text style='sub-header-extra-small' tag='h2'>
            {window.local.t('referrals.details.attachments.title')}
          </Text>
        </Spacing>
        {this.renderAttachmentInnerContent()}
        {!this.props.pdf && <Button type='tertiary' onClick={this.uploadAttachment}>
          {window.local.t('referrals.details.attachments.upload')}
        </Button>}
        {this.getRecipientAttachmentDisabledWarning() &&
          <Alert
            type='warning'
            title={this.getRecipientAttachmentDisabledWarning()} inline
          />
        }
      </div>
    )
  }

  renderResourceDetailsAddress () {
    if (this.props.resourceSiteAddress) {
      return (
        <div data-test='referral-resource-details-address'>
          <Spacing marginY={0.5}>
            <Text style='body-text' tag='div'>
              {window.local.t('referrals.details.address', { address: formatAddress(this.props.resourceSiteAddress) })}
            </Text>
          </Spacing>
        </div>
      )
    }
  }

  renderResourceDetailsPhone () {
    if (this.props.resourceSitePhone) {
      return (
        <div data-test='referral-resource-details-phone'>
          <Spacing marginY={0.5}>
            <Text style='body-text' tag='div'>
              {window.local.t('referrals.details.phone', { phone: formatPhoneNumber(this.props.resourceSitePhone) })}
            </Text>
          </Spacing>
        </div>
      )
    }
  }

  renderResourceDetailsOrganization (resourceSite) {
    if (resourceSite.organizationName) {
      return (
        <div data-test='referral-resource-details-organization'>
          <Spacing marginY={0.5}>
            <Text style='body-text' tag='div'>
              {window.local.t('referrals.details.organization', { organization: resourceSite.organizationName })}
            </Text>
          </Spacing>
        </div>
      )
    }
  }

  renderResourceDetails () {
    const resourceSite = this.props.resourceSite
    return (
      <div data-test='referral-resource-details'>
        <Spacing paddingTop={4}>
          <Text style='sub-header-extra-small' tag='h2'>
            {window.local.t('referrals.details.resource_details')}
          </Text>
        </Spacing>
        <Spacing marginY={0.5}>
          <Text style='body-text' tag='div'>
            <a href={resourceSite.links.self}>
              {`${resourceSite.serviceName}`}
            </a>
          </Text>
        </Spacing>
        {this.renderResourceDetailsOrganization(resourceSite)}
        {this.renderResourceDetailsAddress()}
        {this.renderResourceDetailsPhone()}
      </div>
    )
  }

  renderDetails () {
    const { referrerLine1, referrerLine2 } = this.referrerInformation()

    return (
      <Grid container spacing={4} justify='space-between'>
        <Grid item xs={6} md={4}>
          <Spacing paddingTop={4}>
            <Text style='sub-header-extra-small' tag='h2'>
              {window.local.t('referrals.details.client.title')}
            </Text>
          </Spacing>
          <Spacing marginY={0.5}>
            <Text style='body-text' tag='div'>
              <a href={this.props.patient.links.self}>
                {`${formatName(this.props.patient)} (${formatDate(this.props.patient.dateOfBirth)})`}
              </a>
            </Text>
          </Spacing>
          {!this.props.pdf &&
           this.renderContactInformation()}
          <Spacing marginY={0.5}>
            {this.props.pdf && this.renderIdentifiers()}
          </Spacing>
          {this.renderClientConsent()}
          {this.props.pdf &&
           this.renderResourceDetails()}
        </Grid>
        <Grid item xs={6} md={4}>
          <Spacing paddingTop={4} paddingBottom={3}>
            <Text style='sub-header-extra-small' tag='h2'>
              {window.local.t('referrals.details.care_team.title')}
            </Text>
          </Spacing>
          {this.renderAssignedUsers()}

          <div data-testid='ReferralDetails__referrer-information'>
            <Text style='sub-header-extra-small' tag='h3'>
              {window.local.t('referrals.details.care_team.referrer')}
            </Text>
            <Spacing marginTop={0.5}>
              <Text style='body-text' tag='div'>
                {referrerLine1}
              </Text>
            </Spacing>
            <Text style='body-text-secondary' tag='div'>
              {referrerLine2}
            </Text>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          {this.renderAttachments()}
        </Grid>
      </Grid>
    )
  }

  // --------------------
  // Final render
  // --------------------

  render () {
    return (
      <Fragment>
        {this.props.pdf && <Fragment>
          <div className='ReferralDetails__border' />
          <Spacing paddingTop={4} paddingBottom={4}>
            <Text tag='h2' style='sub-header-large'>Referral Details</Text>
            {this.renderDetails()}
          </Spacing>
        </Fragment>}

        {!this.props.pdf &&
          <Spacing marginTop={3}>
            <ExpansionPanel
              expandText={window.local.t('referrals.details.toggle.show')}
              collapseText={window.local.t('referrals.details.toggle.hide')}>
              <Spacing paddingTop={4}>
                <div className='ReferralDetails__border' />
              </Spacing>
              {this.props.showUploadAttachmentModal &&
                <UploadAttachmentModal
                  error={this.props.uploadAttachmentError}
                  onClose={() => this.props.toggleKey('showUploadAttachmentModal', false)}
                  onFileClear={() => this.props.setAlert('uploadAttachment', null)}
                  onUpload={(file) => this.props.uploadAttachment(this.props.referralId, file)}
                  open={this.props.showUploadAttachmentModal}
                  warning={this.getRecipientAttachmentDisabledWarning()}
                />
              }
              {this.props.pendingDeleteAttachmentId && <DeleteAttachmentModal
                open={this.props.pendingDeleteAttachmentId}
                error={this.props.deleteAttachmentError}
                onClose={() => this.props.toggleKey('pendingDeleteAttachmentId', null)}
                onDelete={() => this.props.destroyAttachment(this.props.referralId, this.props.pendingDeleteAttachmentId)}
                attachment={this.attachmentToDelete()}
              />}
              {this.renderDetails()}
            </ExpansionPanel>
          </Spacing>
        }
        <Spacing paddingTop={this.props.pdf ? 2 : 4}>
          <div className='ReferralDetails__border' />
        </Spacing>
      </Fragment>
    )
  }
}

ReferralDetails.propTypes = {
  address: PropTypes.object,
  analyticsReferrer: PropTypes.object,
  assignedUsers: PropTypes.array,
  attachments: PropTypes.array,
  attachmentsEnabled: PropTypes.bool,
  checkPrivacyStatus: PropTypes.func,
  deleteAttachmentError: PropTypes.string,
  destroyAttachment: PropTypes.func.isRequired,
  identifiers: PropTypes.array,
  patient: PropTypes.object,
  pendingDeleteAttachmentId: PropTypes.number,
  phones: PropTypes.array,
  previousConsent: PropTypes.bool,
  referral: PropTypes.object.isRequired,
  referralId: PropTypes.string,
  referrer: PropTypes.object,
  referrerCompany: PropTypes.object.isRequired,
  referrerTeam: PropTypes.object.isRequired,
  resourceSite: PropTypes.object.isRequired,
  resourceSiteAddress: PropTypes.object,
  resourceSiteCompany: PropTypes.object,
  resourceSitePhone: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
  showUploadAttachmentModal: PropTypes.bool.isRequired,
  toggleKey: PropTypes.func.isRequired,
  uploadAttachment: PropTypes.func.isRequired,
  uploadAttachmentError: PropTypes.string,
  pdf: PropTypes.bool
}

const mapDispatchToProps = {
  destroyAttachment,
  toggleKey,
  uploadAttachment,
  setAlert
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const patient = getAssociation(state, referral, 'patient')
  const resourceSite = getAssociation(state, referral, 'resourceSite')
  const referrer = getAssociation(state, referral, 'referrer')
  const referrerCompany = getAssociation(state, referrer, 'company')
  const referrerTeam = getAssociation(state, referrer, 'team')
  const resourceSiteCompany = getAssociation(state, resourceSite, 'company')
  const resourceSiteAddress = getAssociation(state, resourceSite, 'address')
  const resourceSitePhone = getAssociation(state, resourceSite, 'primaryPhone')

  const destroyAttachmentAlert = state.referralView.alert.destroyAttachment
  const uploadAttachmentAlert = state.referralView.alert.uploadAttachment

  return {
    address: getAssociation(state, patient, 'address'),
    analyticsReferrer: state.global.analyticsReferrer,
    assignedUsers: denormalizeRecords(state, getAssociation(state, referral, 'assignedUsers')),
    attachments: getAssociation(state, referral, 'attachments'),
    attachmentsEnabled: state.global.settings.attachments_enabled,
    deleteAttachmentError: destroyAttachmentAlert && destroyAttachmentAlert.text,
    identifiers: getAssociation(state, patient, 'identifiers', 'patient_identifier'),
    patient,
    pendingDeleteAttachmentId: state.referralView.pendingDeleteAttachmentId,
    phones: getAssociation(state, patient, 'phones'),
    previousConsent: state.referralView.previousConsent,
    referral,
    referrer,
    referrerCompany,
    referrerTeam,
    resourceSite,
    resourceSiteAddress,
    resourceSiteCompany,
    resourceSitePhone,
    showUploadAttachmentModal: state.referralView.showUploadAttachmentModal,
    uploadAttachmentError: uploadAttachmentAlert && uploadAttachmentAlert.text
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralDetails)
