import React from 'react'
import PropTypes from 'prop-types'
import Text from '@components/Text'

import { timelineEventContentClassNames } from '../utils/referralTimelineUtil'

const renderTitle = ({ newStatus, oldStatus }) => {
  return <div className='ReferralTimeline__event__title'>
    {window.local.t('referrals.timeline.events.status_update.member_led_closed_loop.start')}
    <Text style='body-text-emphasis'>{oldStatus}</Text>
    {window.local.t('referrals.timeline.events.status_update.member_led_closed_loop.middle')}
    <Text style='body-text-emphasis'>{newStatus}</Text>
    {window.local.t('referrals.timeline.events.status_update.member_led_closed_loop.end')}
  </div>
}

renderTitle.propTypes = {
  newStatus: PropTypes.string.isRequired,
  oldStatus: PropTypes.string.isRequired
}

const MemberLedClosedLoopStatusUpdateEvent = ({ isLastElement, newStatus, oldStatus, type }) => {
  const contentClassNames = timelineEventContentClassNames({
    eventType: type,
    isLastElement: isLastElement
  })

  return <>
    {renderTitle({ newStatus, oldStatus })}
    <div className={contentClassNames} />
  </>
}

MemberLedClosedLoopStatusUpdateEvent.propTypes = {
  isLastElement: PropTypes.bool.isRequired,
  newStatus: PropTypes.string.isRequired,
  oldStatus: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default MemberLedClosedLoopStatusUpdateEvent
