import { LOCATION_SEARCH_ENDPOINT } from '@constants/apiEndpoints'
import { getApiResource } from '@utils/apiUtil'

const type = 'location_search_result'
const resource = getApiResource(LOCATION_SEARCH_ENDPOINT)

export default {
  type,
  ...resource
}
