import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import TextInput from '@components/TextInput'

class PhoneInput extends PureComponent {
  constructor (props) {
    super(props)

    this.updatePhoneNumber = this.updatePhoneNumber.bind(this)
  }

  updatePhoneNumber (value) {
    const onlyDigits = value.replace(/\D*/g, '')
    const trimmedPhoneNumber = onlyDigits.substring(0, 10)

    return this.props.onChange(trimmedPhoneNumber)
  }

  render () {
    const { value, placeholder, label, hint, errors, showErrorMessages } = this.props

    return <TextInput
      errors={errors}
      placeholder={placeholder}
      label={label}
      hint={hint}
      onChange={this.updatePhoneNumber}
      value={value}
      showErrorMessages={showErrorMessages}
      type='tel'
    />
  }
}

PhoneInput.propTypes = {
  errors: PropTypes.array,
  hint: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showErrorMessages: PropTypes.bool,
  value: PropTypes.string
}

PhoneInput.defaultProps = {
  showErrorMessages: false,
  errors: [],
  placeholder: 'Example: 555-555-5555',
  label: 'PHONE NUMBER',
  hint: 'Enter a valid 10-digit phone number'
}

export default PhoneInput
