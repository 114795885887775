export const ADD_ADDRESS_TO_COMPOSITE_CLIENT = 'ADD_ADDRESS_TO_COMPOSITE_CLIENT'
export const ADD_ATTRIBUTE_TO_COMPOSITE_CLIENT = 'ADD_ATTRIBUTE_TO_COMPOSITE_CLIENT '
export const ADD_CLIENT = 'ADD_CLIENT'
export const ADD_CLIENT_SELECT_ERROR = 'ADD_CLIENT_SELECT_ERROR'
export const ADD_IDENTIFIER_TO_COMPOSITE_CLIENT = 'ADD_IDENTIFIER_TO_COMPOSITE_CLIENT'
export const ADD_NOTE = 'ADD_NOTE'
export const ADD_PHONE_TO_COMPOSITE_CLIENT = 'ADD_PHONE_TO_COMPOSITE_CLIENT '
export const LOAD_MERGE_INTO_STATE = 'LOAD_MERGE_INTO_STATE'
export const PREPOPULATE_COMPOSITE_FROM_SELECTION = 'PREPOPULATE_COMPOSITE_FROM_SELECTION '
export const REMOVE_ADDRESS_FROM_COMPOSITE_CLIENT = 'REMOVE_ADDRESS_FROM_COMPOSITE_CLIENT '
export const REMOVE_ATTRIBUTE_FROM_COMPOSITE_CLIENT = 'REMOVE_ATTRIBUTE_FROM_COMPOSITE_CLIENT'
export const REMOVE_CLIENT_SELECTION = 'REMOVE_CLIENT_SELECTION'
export const REMOVE_IDENTIFIER_FROM_COMPOSITE_CLIENT = 'REMOVE_IDENTIFIER_FROM_COMPOSITE_CLIENT'
export const REMOVE_PHONE_FROM_COMPOSITE_CLIENT = 'REMOVE_PHONE_FROM_COMPOSITE_CLIENT'
export const RESET_COMPOSITE_CLIENT = 'RESET_COMPOSITE_CLIENT'
export const SET_SELECTION_VALIDATION_READY_STATE = 'SET_SELECTION_VALIDATION_READY_STATE'
