import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const CartFeedbackErrorModal = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} role='dialog'>
    <DialogTitle>There was an error</DialogTitle>
    <DialogContent>Sorry, your feedback failed to submit. Please try again.</DialogContent>
  </Dialog>
)

CartFeedbackErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
}

export default CartFeedbackErrorModal
