import axios from 'axios'
import * as Sentry from '@sentry/core'
import { getQueryParams } from '@utils/browserUtil'

const getSearchOptions = () => {
  return axios.get('/api/internal/search-options')
    .then(response => response.data)
    .catch(error => {
      console.error(error)
      Sentry.captureException(error)
    })
}

const getSearchResults = (urlQueryParams) => {
  const params = {
    ...getQueryParams(urlQueryParams),
    included: ['search_results']
  }

  return axios.get('/api/internal/resource_sites_search', {
    params: {
      ...params,
      included: [
        'patient',
        'patient.address',
        'search_results',
        'search_results.resource_programs',
        'search_results.primary_phone',
        'search_results.address'
      ]
    }
  }).then(response => response.data)
    .catch(error => {
      console.error(error)
      Sentry.captureException(error)
    })
}

export default {
  getSearchOptions,
  getSearchResults
}
