import get from 'lodash/get'
import isNil from 'lodash/isNil'

import {
  flushEntities,
  loadEntities,
  loadPaginationData,
  removeEntity,
  setAnalyticsReferrer,
  setFormValue,
  toggleKey,
  updateEntity
} from '@actions'
import {
  addCartItemToNonSpa,
  removeCartItemFromNonSpa,
  updateCartCountNonSpa,
  updateCheckoutButtonPath
} from '@services/updateNonSpa'
import favoritesService from '@services/favoritesService'
import resourceSiteSearchService from '@services/resourceSiteSearchService'
import resourceSiteShareService from '@services/resourceSiteShareService'
import clientsService from '@services/clientsService'
import { stringifyQueryParams } from '@utils/browserUtil'
import {
  CLOSE_DOWNLOAD_MODAL,
  CLOSE_EDIT_MODAL,
  CLOSE_SHARE_MODAL,
  LOAD_SHAREABLE_USERS,
  OPEN_DOWNLOAD_MODAL,
  SET_DOWNLOAD_MODAL_PROPS,
  OPEN_EDIT_MODAL,
  OPEN_SHARE_MODAL,
  SEARCH_QUERY_KEY_MAPPING
} from '@scenes/resourceSites/constants'
import { ANALYTIC_REFERRER_TYPES } from '@constants'
import { addItem, removeItem } from '@services/cartService'
import { denormalizeRecords, getAssociation, getRecord } from '@selectors'
import { formatAddress } from '@utils/formatterUtil'
import referralService from '@services/referralService'

const buildSearchQueryParams = (search) => {
  const keysToExclude = get(search, 'geofilterInferred.value', false) ? ['geofilter'] : []
  const keysToSubmit = Object
    .keys(SEARCH_QUERY_KEY_MAPPING)
    .filter(key => !keysToExclude.includes(key))

  return keysToSubmit.reduce((params, key) => {
    const mappedKey = SEARCH_QUERY_KEY_MAPPING[key]

    if (search[key] && !isNil(search[key].value)) {
      params[mappedKey] = search[key].value
    }

    return params
  }, {})
}

const closeModal = (type) => (dispatch, getState) => {
  dispatch({ type, payload: {} })
}

export const closeDownloadModal = () => closeModal(CLOSE_DOWNLOAD_MODAL)
export const closeEditModal = () => closeModal(CLOSE_EDIT_MODAL)
export const closeShareModal = () => closeModal(CLOSE_SHARE_MODAL)

export const loadFavorites = () => {
  return (dispatch) => {
    favoritesService
      .getFavorites()
      .then(response => { dispatch(loadEntities(response)) })
  }
}

export const loadSearchOptions = () => {
  return (dispatch) => {
    resourceSiteSearchService.getSearchOptions().then(response => {
      dispatch(loadEntities(response))
    })
  }
}

export const loadSearchResults = (searchQueryParams) => {
  return (dispatch, getState) => {
    dispatch(toggleKey({
      namespace: 'resourceSites',
      key: 'loadingSearchResults',
      value: true
    }))
    clearSearchResults()(dispatch)

    resourceSiteSearchService.getSearchResults(searchQueryParams).then(response => {
      dispatch(setAnalyticsReferrer({
        analyticsReferrerId: response.meta.analytics_event_id,
        analyticsReferrerType: ANALYTIC_REFERRER_TYPES.searchSubmitted
      }))
      dispatch(loadPaginationData({
        namespace: 'resourceSites',
        metadata: response.meta.search_pagination
      }))
      dispatch(loadEntities(response))
      dispatch(toggleKey({
        namespace: 'resourceSites',
        key: 'loadingSearchResults',
        value: false
      }))
      dispatch(toggleKey({
        namespace: 'resourceSites',
        key: 'searchFailed',
        value: false
      }))
    }).catch(() => {
      dispatch(toggleKey({
        namespace: 'resourceSites',
        key: 'searchFailed',
        value: true
      }))
      dispatch(toggleKey({
        namespace: 'resourceSites',
        key: 'loadingSearchResults',
        value: false
      }))
    })
  }
}

export const loadShareableUsers = () => {
  return (dispatch, getState) => {
    resourceSiteShareService.shareableUsers().then((response) => {
      dispatch({ type: LOAD_SHAREABLE_USERS, payload: { users: response.data } })
    })
  }
}

const openModal = (type, resourceSiteId) => (dispatch, getState) => {
  dispatch({ type, payload: { resourceSiteId } })
}

export const openDownloadModal = resourceSiteId => openModal(OPEN_DOWNLOAD_MODAL, resourceSiteId)
export const openEditModal = (resourceSiteId) => openModal(OPEN_EDIT_MODAL, resourceSiteId)
export const openShareModal = (resourceSiteId) => openModal(OPEN_SHARE_MODAL, resourceSiteId)

export const setDownloadModalProps = (props) => (dispatch, getState) => {
  dispatch({ type: SET_DOWNLOAD_MODAL_PROPS, payload: props })
}

const resetPageNumber = () => {
  return setFormValue({
    namespace: 'resourceSites',
    form: 'search',
    key: 'page',
    value: 1
  })
}

export const submitSearchForm = ({ history, target, newPage = false }) => {
  return (dispatch, getState) => {
    if (!newPage) { dispatch(resetPageNumber()) }

    const { search, searchFailed } = getState().resourceSites

    const searchQueryParams = stringifyQueryParams(
      buildSearchQueryParams(search)
    )

    history.push({
      pathname: target || history.location.pathname,
      search: searchQueryParams
    })

    if (searchFailed) {
      dispatch(loadSearchResults(`?${searchQueryParams}`))
    }
  }
}

export const clearSearchResults = () => {
  return (dispatch) => {
    dispatch(flushEntities('resource_site_search_results'))
    dispatch(flushEntities('resource_site_search_result'))
  }
}

export const addResourceToCart = (resourceSiteId, metadata) => {
  return (dispatch, getState) => {
    addItem(resourceSiteId, metadata).then(response => {
      const cartItem = response.data

      dispatch(loadEntities(response))
      dispatch(updateEntity({
        type: 'cart',
        id: response.data.attributes.cart_id,
        updatedObject: { id: cartItem.id, type: cartItem.type },
        path: ['relationships', 'cartItems'],
        updateMethod: 'append'
      }))

      // Now that we've successfully added the cart item, we need to update
      // the non-SPA "care plan drawer"
      const state = getState()
      const resourceSite = getRecord(state, 'resource_site_search_result', resourceSiteId)
      const activeCart = denormalizeRecords(state,
        getRecord(state, 'cart', state.global.activeCartId)
      )
      const updatedCartItemCount = activeCart.cartItems.length

      addCartItemToNonSpa({ resourceSite, cartItem })
      updateCartCountNonSpa(updatedCartItemCount)
    })
  }
}

export const removeResourceFromCart = (cartItemId) => {
  return (dispatch, getState) => {
    // These hardcoded values, while inaccurate, match the existing behavior
    // before the SPA so this keeps data consistency. In practice the referer
    // data is probably not used for this event
    const analytics = {
      referrerId: 'not instrumented',
      referrerType: 'Drawer Opened'
    }

    removeItem(cartItemId, analytics).then(() => {
      dispatch(removeEntity({
        type: 'cart_item',
        id: cartItemId
      }))

      // Now that we've successfully removed the cart item, we need to update
      // the non-SPA "care plan drawer"
      const state = getState()
      const activeCart = denormalizeRecords(state,
        getRecord(state, 'cart', state.global.activeCartId)
      )
      const updatedCartItemCount = activeCart.cartItems.length

      removeCartItemFromNonSpa(cartItemId)
      updateCartCountNonSpa(updatedCartItemCount)
    })
  }
}

const fillAddressFromClient = (clientID) => {
  return (dispatch, getState) => {
    if (getState().resourceSites.search.geofilter.value) return

    const clientFromState = getRecord(getState(), 'patient', clientID)
    const addressFromState = getAssociation(getState(), clientFromState, 'address')
    if (!addressFromState) return

    const value = formatAddress(addressFromState)

    dispatch(setFormValue({
      namespace: 'resourceSites',
      form: 'search',
      key: 'geofilter',
      value
    }))
    dispatch(setFormValue({
      namespace: 'resourceSites',
      form: 'search',
      key: 'geofilterInferred',
      value: true
    }))
  }
}

export const handleClientChange = (clientId, includedAssociations = []) => {
  return (dispatch, getState) => {
    dispatch(setFormValue({
      namespace: 'resourceSites',
      form: 'search',
      key: 'clientId',
      value: clientId
    }))
    updateCheckoutButtonPath(clientId)

    if (!clientId) {
      if (getState().resourceSites.search.geofilterInferred.value) {
        dispatch(setFormValue({
          namespace: 'resourceSites',
          form: 'search',
          key: 'geofilterInferred',
          value: false
        }))
        dispatch(setFormValue({
          namespace: 'resourceSites',
          form: 'search',
          key: 'geofilter',
          value: ''
        }))
      }

      return
    }

    const options = {
      extraFields: {
        patient: ['all_identifier_values', 'value', 'text']
      },
      included: includedAssociations
    }

    clientsService.show(clientId, options).then(response => {
      dispatch(loadEntities(response))

      if (!getState().resourceSites.search.geofilter.value) {
        dispatch(fillAddressFromClient(clientId))
      }

      dispatch(toggleKey({
        namespace: 'resourceSites',
        key: 'loadingSearchParams',
        value: false
      }))
    })
  }
}

export const removeCartItemFromSpaUI = (cartItemId) => {
  return (dispatch) => {
    dispatch(removeEntity({
      type: 'cart_item',
      id: cartItemId
    }))
  }
}

// TODO: 🔥Remove me when removing LegacyResourceSiteDownloadButton
export const manuallyLoadSearchResultIntoState = resourceSiteData => {
  const expectedDataStructure = {
    data: {
      type: 'resource_site_search_result',
      id: resourceSiteData.id,
      ...resourceSiteData
    }
  }

  return dispatch => dispatch(loadEntities(expectedDataStructure))
}

export const loadReferralForFollowupStep = referralID => {
  return (dispatch) => {
    const includedAssociations = [
      'intake_question_set',
      'intake_question_set.intake_questions',
      'intake_question_set.intake_questions.intake_response_options'
    ]

    referralService
      .getReferral(referralID, { included: includedAssociations })
      .then(response => {
        dispatch(loadEntities(response))
      })
  }
}
