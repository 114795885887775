import axios from 'axios'
import apiConfig from '@config/apiConfig'

const baseEndpoint = '/api/internal/favorites'

const DEFAULT_FAVORITES_INCLUDED = [
  'resource_site'
]

const getFavorites = (userID, options = {}) => {
  const included = options.included || DEFAULT_FAVORITES_INCLUDED

  return axios.get(baseEndpoint, { params: { included } })
    .then(response => response.data)
    .catch(error => { console.error('getFavorites', error) })
}

const createFavorite = (siteId, analyticsReferrer) => {
  return axios(apiConfig.post(baseEndpoint, { resource_site_id: siteId, ...analyticsReferrer }))
    .then(response => {
      if (response.status === 201) {
        return { success: true, favoriteId: Number(response.data.data.id) }
      } else {
        throw new Error('Error creating favorite for ' + siteId)
      }
    })
    .catch(error => {
      console.error('createFavorite', error)
      return { success: false }
    })
}

const destroyFavorite = (favoriteId) => {
  return axios(apiConfig.destroy(baseEndpoint + '/' + favoriteId))
    .then(response => {
      if (response.status === 204) {
        return { success: true, favoriteId: null }
      } else {
        console.log('Error here!')
        console.log(response)
        throw new Error('Error destroying favorite for ' + favoriteId)
      }
    })
    .catch(error => {
      console.error('destroyFavorite', error)
      return { success: false }
    })
}

export const toggleFavorite = (favoriteData, analyticsReferrer) => {
  const { favoriteId, siteId } = favoriteData
  if (favoriteId) {
    return destroyFavorite(favoriteId)
  } else if (siteId) {
    return createFavorite(siteId, analyticsReferrer)
  }
}

export default { getFavorites, toggleFavorite }
