import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'

import LoadingSpinner from '@components/LoadingSpinner'

export default class LoadingDataWrapper extends PureComponent {
  render () {
    const { children, loadingData } = this.props

    if (loadingData) {
      return <LoadingSpinner />
    } else {
      return isFunction(children) ? children() : children
    }
  }
}

LoadingDataWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    // Note: Sometimes it is better to pass children as a function so that you delay execution of that code until after data has been loaded, in order to do so you can wrap you children in a function like so:
    //
    // <LoadingDataWrapper loadingData={data.importantProperty}
    //    {() => (
    //      <div>{data.importantProperty}</div>
    //    )}
    // </LoadingDataWrapper
    PropTypes.func
  ]).isRequired,
  loadingData: PropTypes.bool.isRequired
}
