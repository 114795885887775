import {
  ADD_ADDRESS_TO_COMPOSITE_CLIENT,
  ADD_ATTRIBUTE_TO_COMPOSITE_CLIENT,
  ADD_CLIENT,
  ADD_CLIENT_SELECT_ERROR,
  ADD_IDENTIFIER_TO_COMPOSITE_CLIENT,
  ADD_NOTE,
  ADD_PHONE_TO_COMPOSITE_CLIENT,
  LOAD_MERGE_INTO_STATE,
  PREPOPULATE_COMPOSITE_FROM_SELECTION,
  REMOVE_ADDRESS_FROM_COMPOSITE_CLIENT,
  REMOVE_ATTRIBUTE_FROM_COMPOSITE_CLIENT,
  REMOVE_CLIENT_SELECTION,
  REMOVE_IDENTIFIER_FROM_COMPOSITE_CLIENT,
  REMOVE_PHONE_FROM_COMPOSITE_CLIENT,
  RESET_COMPOSITE_CLIENT,
  SET_SELECTION_VALIDATION_READY_STATE
} from '../constants'
import { commonAttributesBetweenClients } from '@scenes/clients/ClientMerge/utils'

export const addNote = payload => ({
  type: ADD_NOTE, payload
})

export const addAddressToCompositeClient = payload => ({
  type: ADD_ADDRESS_TO_COMPOSITE_CLIENT, payload
})

export const addAttributeToCompositeClient = payload => ({
  type: ADD_ATTRIBUTE_TO_COMPOSITE_CLIENT, payload
})

export const addIdentifierToCompositeClient = payload => ({
  type: ADD_IDENTIFIER_TO_COMPOSITE_CLIENT, payload
})

export const addPhoneToCompositeClient = payload => ({
  type: ADD_PHONE_TO_COMPOSITE_CLIENT, payload
})

export function addClient (payload) {
  return { type: ADD_CLIENT, payload }
}

export const prePopulateCompositeFromSelection = (payload) => (
  { type: PREPOPULATE_COMPOSITE_FROM_SELECTION, payload }
)

export const removeAddressFromCompositeClient = payload => ({
  type: REMOVE_ADDRESS_FROM_COMPOSITE_CLIENT, payload
})

export const removeAttributeFromComposite = payload => ({
  type: REMOVE_ATTRIBUTE_FROM_COMPOSITE_CLIENT, payload
})

export function removeClientSelection (payload) {
  return { type: REMOVE_CLIENT_SELECTION, payload }
}

export const removeIdentifierFromCompositeClient = payload => ({
  type: REMOVE_IDENTIFIER_FROM_COMPOSITE_CLIENT, payload
})

export const removePhoneFromCompositeClient = payload => ({
  type: REMOVE_PHONE_FROM_COMPOSITE_CLIENT, payload
})

export function addClientSelectError (payload) {
  return { type: ADD_CLIENT_SELECT_ERROR, payload }
}

export const resetCompositeClient = () => ({ type: RESET_COMPOSITE_CLIENT })

export const loadMergeIntoState = mergeModel => ({
  type: LOAD_MERGE_INTO_STATE,
  payload: mergeModel
})

export const setSelectionValidationReadyState = payload => ({
  type: SET_SELECTION_VALIDATION_READY_STATE, payload
})

export function addClientAndPrepopulateCompositeClient (payload) {
  return function (dispatch, getState) {
    dispatch(addClient(payload))

    const { firstPatient, secondPatient } = getState().clientMerge

    if (firstPatient && secondPatient) {
      const commonAttributes = commonAttributesBetweenClients({ firstPatient, secondPatient })
      dispatch(prePopulateCompositeFromSelection(commonAttributes))
    }
  }
}
