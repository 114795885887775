import { useMemo } from 'react'

import useGetRecord from '@hooks/useGetRecord'
import clientsService from '@services/clientsService'

import createPropGetters from './propGetters'

export default function useClientNeeds (clientId, options = {}) {
  const client = useGetRecord(clientsService.type, clientId)
  const propGetters = createPropGetters(client)

  const value = useMemo(() => ({
    ...propGetters
  }), [client])

  return value
}
