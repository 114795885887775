import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import useGetGlobalState from '@hooks/useGetGlobalState'
import useGetFeatureFlag from '@hooks/useGetFeatureFlag'
import useFormState from '@hooks/useFormState'
import cartShareValidator from './cartShareValidator'
import cartShareUtil from '../cartShareUtil'
import flatten from 'lodash/flatten'
import { createFlash } from '@services/flashService'

import Grid from '@material-ui/core/Grid'
import LanguageSelect from '@components/Forms/Elements/LanguageSelect'
import Spacing from '@components/Spacing'
import Button from '@components/Button'
import FieldErrors from '@components/Form/FieldErrors'
import ShareAndConsentSection from './ShareAndConsentSection'

import { CLIENT_INDEX } from '@constants'

const { getString, downloadCartPdfLink } = cartShareUtil

const CartShareForm = (props) => {
  const defaultEmail = props.patient.email || ''
  const { formState, updateField, setError } = useFormState({
    language: { label: 'English', value: 'en' },
    sendText: false,
    phone: null,
    sendEmail: false,
    consentGranted: false,
    email: defaultEmail,
    cartId: props.cartId,
    patientId: props.patient.id,
    submitted: false
  })
  const validateCartShare = cartShareValidator(setError)
  const { loading, createCartShare } = cartShareUtil.getCreateCartShare()

  const languages = useGetGlobalState('languages')

  const showShareOptions = useGetFeatureFlag('member_led_closed_loop_referrals_flag')

  const isSubmitDisabled = useCallback(() => (
    formState.data.submitted ||
    !formState.data.consentGranted ||
    !(formState.data.sendText ||
      formState.data.sendEmail)
  ), [loading, formState.data])

  const redirectToClientDetail = () => {
    const onFlashSet = () => {
      setTimeout(() => {
        window.location = `${CLIENT_INDEX}/${props.patient.id}`
      }, 1000)
    }

    createFlash('success', window.local.t('checkout.create_success'))
      .then(onFlashSet)
      .catch(onFlashSet)
  }

  const CartShareFormSubmitted = async (e) => {
    e.preventDefault()

    if (validateCartShare(formState.data)) {
      updateField('submitted', true)
      await createCartShare(formState.data)
      redirectToClientDetail()
    }
  }

  const ReferralLanguageFormSubmitted = (e) => {
    e.preventDefault()

    createCartShare({
      ...formState.data,
      sendEmail: false,
      sendText: false
    }).then(redirectToClientDetail)
  }

  return (
    <form className='CartShareForm' onSubmit={CartShareFormSubmitted}>
      <FieldErrors errors={flatten(Object.values(formState.errors))} showErrorMessages />

      <Spacing marginBottom={2}>
        <LanguageSelect
          name='referral-share-language'
          showDropdownIndicator
          label={getString('form.language')}
          languages={languages}
          onChange={(value) => updateField('language', value)}
          defaultValue={formState.data.language}
        />

        {showShareOptions && <DownloadPdfButton
          patientId={props.patient.id}
          cartId={props.cartId}
          language={formState.data.language}
        />}
      </Spacing>

      {showShareOptions && <ShareAndConsentSection
        updateField={updateField}
        defaultEmail={defaultEmail}
        consentGranted={formState.data.consentGranted}
        phones={props.patient.phones}
        sendText={formState.data.sendText}
        sendEmail={formState.data.sendEmail}
      />}

      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          {showShareOptions && <Button
            type='primary'
            htmlType='submit'
            disabled={isSubmitDisabled()}
            onClick={CartShareFormSubmitted}
          >
            {getString('form.submit')}
          </Button>}

          {!showShareOptions && <DownloadPdfButton
            patientId={props.patient.id}
            cartId={props.cartId}
            language={formState.data.language}
          />}
        </Grid>

        <Grid item md={6} xs={12}>
          <Button type='secondary' onClick={ReferralLanguageFormSubmitted}>
            {getString('form.skip')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

CartShareForm.propTypes = {
  cartId: PropTypes.number.isRequired,
  patient: PropTypes.object.isRequired
}

export default CartShareForm

const DownloadPdfButton = ({ patientId, cartId, language }) => (
  <a
    className='Button Button--primaryDark'
    href={downloadCartPdfLink({
      patientId: patientId,
      cartId: cartId,
      language: language
    })}
    target='_blank'
    rel='noopener'
  >
    {getString('form.download_pdf')}
  </a>
)

DownloadPdfButton.propTypes = {
  patientId: PropTypes.number.isRequired,
  cartId: PropTypes.number.isRequired,
  language: PropTypes.object
}
