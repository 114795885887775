import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import ExpandableTextArea from '@components/ExpandableTextArea'
import { Header } from '@components/Header'
import Text from '@components/Text'

class ReviewAddComment extends React.PureComponent {
  conditionallyRenderTextarea () {
    if (this.props.localeContext === 'admin') return

    return (
      <div>
        <Header
          rank='2'
          text={window.local.t('client_merge.review.note_title')}
          className='ReviewView__add-comment-header'
        />
        <p className='ReviewView__add-comment-text'>
          {window.local.t('client_merge.review.note_subtitle')}
        </p>

        <ExpandableTextArea
          placeholder={window.local.t('client_merge.review.note_placeholder')}
          id='note'
          value={this.props.note}
          onChange={this.props.handleChange}
          maxLength={500}
        />
      </div>
    )
  }

  render () {
    const { local } = window
    const { localeContext } = this.props

    return (
      <Grid item xs={4}>
        <form noValidate autoComplete='off'>
          {this.conditionallyRenderTextarea()}
          <Button
            onClick={this.props.handleSubmit}
            className='ReviewView__add-comment-button'
            variant='contained'
            size='large'
            color='primary'
            fullWidth
          >
            {local.t(`client_merge.review.note_submit.${localeContext}`)}
          </Button>
          <div className='ReviewView__add-comment-helper-text'>
            <Text style='body-text-secondary'>
              {local.t(`client_merge.review.note_subtext.${localeContext}`)}
            </Text>
          </div>
        </form>
      </Grid>
    )
  }
}

ReviewAddComment.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  localeContext: PropTypes.string.isRequired,
  note: PropTypes.string
}

export default ReviewAddComment
