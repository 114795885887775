import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@components/Form/Checkbox'
import Spacing from '@components/Spacing'

import './ClosedLoopConsent.scss'

const ClosedLoopConsent = (props) => {
  const formName = props.formName

  return (
    <div data-test={props.dataTest}>
      <Checkbox
        id={`${formName}_closed_loop`}
        name={`${formName}[closed_loop]`}
        label={props.checkboxLabel}
        onChange={props.onClosedLoopCheck}
        defaultChecked
      />
      <Spacing marginTop={1} marginLeft={3} marginBottom={3}>
        <p className='ClosedLoopConsent__description'>
          {props.description}
        </p>
      </Spacing>
    </div>
  )
}

ClosedLoopConsent.propTypes = {
  checkboxLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClosedLoopCheck: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  dataTest: PropTypes.string
}

export default ClosedLoopConsent
