import { RESOURCE_SITES_ENDPOINT } from '@constants/apiEndpoints'
import { getApiResource } from '@utils/apiUtil'

const type = 'resource_sites_coverage_area'
export const getResource = resourceSiteId => (
  getApiResource([RESOURCE_SITES_ENDPOINT, resourceSiteId, 'coverage_areas'].join('/'))
)

export default {
  type,
  getResource
}
