import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from '@components/Icon'
import cssVariables from '@config/theme/variables'
import './PageHeader.css'

class PageHeader extends Component {
  constructor (props) {
    super(props)

    this.renderBackLink = this.renderBackLink.bind(this)
  }

  renderBackLink () {
    if (this.props.backLink) {
      const { path, text, isExternal } = this.props.backLink

      const linkAttributes = {
        'className': 'PageHeader__back-link',
        'data-test': 'page-header__back-link'
      }

      const reactRouterLink = (
        <Link to={path} {...linkAttributes}>{text}</Link>
      )
      const externalLink = (
        <a href={path} {...linkAttributes}>{text}</a>
      )

      return (
        <div className='PageHeader__back-container'>
          <Icon iconKey='keyboard_arrow_left' size={12} />

          {isExternal === true ? externalLink : reactRouterLink}
        </div>
      )
    }
  }

  render () {
    return (
      <header className='PageHeader__container'>
        <div style={{ ...cssVariables.gridContainer, flexFlow: 'column' }}>
          {this.renderBackLink()}
          <h1 className='hfysg-h1 PageHeader__title'>
            {this.props.title}
          </h1>
          <p className='hfysg-p PageHeader__description'>
            {this.props.description}
          </p>
        </div>
      </header>
    )
  }
}

PageHeader.propTypes = {
  description: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  backLink: PropTypes.object
}

export default PageHeader
