import React from 'react'
import PropTypes from 'prop-types'
import { Popper, Paper } from '@material-ui/core'
import './ElementWithTooltip.css'

const initialState = {
  popoverTriggerElement: null
}

class ElementWithTooltip extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = initialState

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  handleMouseEnter (event) {
    this.setState({ popoverTriggerElement: event.currentTarget })
  }

  handleMouseLeave () {
    this.setState({ popoverTriggerElement: null })
  }

  render () {
    const { popoverTriggerElement } = this.state
    const { tooltipContent, tooltipOptions, enabled, width } = this.props

    return (
      <div
        data-testid='ElementWithTooltip'
        className='ElementWithTooltip'
        onMouseOut={this.handleMouseLeave}
        onBlur={this.handleMouseLeave}
        onMouseOver={this.handleMouseEnter}
        onFocus={this.handleMouseEnter}
        style={{ width: width }}
      >
        <Popper
          anchorEl={popoverTriggerElement}
          disablePortal
          placement='top'
          open={Boolean(popoverTriggerElement && enabled)}
          modifiers={{
            flip: { enabled: true },
            offset: { enabled: true, offset: '0, 12px' }
          }}
          {...tooltipOptions}
        >
          <Paper classes={{ root: 'Tooltip' }} elevation={2}>
            <div className='Tooltip__content'>
              {tooltipContent}
            </div>
          </Paper>
        </Popper>

        {this.props.children}
      </div>
    )
  }
}

ElementWithTooltip.defaultProps = {
  enabled: true
}

ElementWithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool.isRequired,
  tooltipContent: PropTypes.node.isRequired,
  tooltipOptions: PropTypes.object,
  width: PropTypes.string
}

export default ElementWithTooltip
