import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@components/Alert'
import './AttestationAlert.scss'
import { isUHC } from '@utils/companyUtil'

const AlertBody = ({ clientName, onConsentClicked, clientConsentGranted, sectionKey, company }) => {
  if (isUHC(company)) {
    return (
      <div>
        <div className='AttestationAlert__context'>
          {window.local.t('referral_modal.fields.client_consent.description.uhc')}
        </div>
        <div className='AttestationAlert__form-container'>
          <input
            onChange={onConsentClicked}
            className='AttestationAlert__checkbox'
            checked={clientConsentGranted}
            id={'checkout-consent-uhc'}
            name='consent'
            type='checkbox' />
          <label
            data-test='checkout__consent-label'
            className='AttestationAlert__label'
            htmlFor={`checkout-consent-uhc`}
          >
            {window.local.t('referral_modal.fields.client_consent.label.uhc')}
          </label>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className='AttestationAlert__context'>
          {window.local.t(`checkout.consent.context.${sectionKey}`, { client_name: clientName })}
          <a
            href='https://support.healthify.us/en/articles/3222148-client-sharing'
            target='_blank'
          >
            {window.local.t('checkout.consent.learn_more')}
          </a>
        </div>
        <div className='AttestationAlert__form-container'>
          <input
            onChange={onConsentClicked}
            className='AttestationAlert__checkbox'
            checked={clientConsentGranted}
            id={`checkout-consent-${sectionKey}`}
            name='consent'
            type='checkbox' />
          <label
            data-test='checkout__consent-label'
            className='AttestationAlert__label'
            htmlFor={`checkout-consent-${sectionKey}`}
          >
            {window.local.t('checkout.consent.label', { client_name: clientName })}
          </label>
        </div>
      </div>
    )
  }
}

AlertBody.propTypes = {
  clientConsentGranted: PropTypes.bool,
  clientName: PropTypes.string.isRequired,
  sectionKey: PropTypes.string.isRequired,
  onConsentClicked: PropTypes.func.isRequired
}

const AttestationAlert = (props) => {
  const alertTitle = props.clientConsentGranted
    ? window.local.t('checkout.consent.granted.title')
    : window.local.t('checkout.consent.needed.title')
  const alertType = props.clientConsentGranted ? 'success' : 'warning'

  return (
    <div className='AttestationAlert'>
      <Alert
        type={alertType}
        title={alertTitle}
        body={<AlertBody {...props} />} />
    </div>
  )
}

AttestationAlert.propTypes = {
  clientConsentGranted: PropTypes.bool,
  clientName: PropTypes.string.isRequired,
  sectionKey: PropTypes.string.isRequired,
  onConsentClicked: PropTypes.func.isRequired
}

export default AttestationAlert
