import { useMemo } from 'react'

import clientsService from '@services/clientsService'
import useGetRecord from '@hooks/useGetRecord'

import { getClientAssociations } from './clientDetailsUtil'
import createPropGetters from './propGetters'

export default function useClientDetails (clientId) {
  const client = useGetRecord(clientsService.type, clientId)
  const associations = getClientAssociations(client)
  const propGetters = createPropGetters(client, associations)

  const value = useMemo(() => ({
    client,
    ...propGetters
  }), [clientId])

  return value
}
