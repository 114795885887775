import React, { Fragment, PureComponent } from 'react'
import TextInput from '@components/TextInput'
import Checkbox from '@components/Form/Checkbox'
import Button from '@components/Button'
import Text from '@components/Text'
import Spacing from '@components/Spacing'
import Story from '../Story'

class TextInputs extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      showErrorMessages: false
    }

    this.toggleErrorMessages = this.toggleErrorMessages.bind(this)
  }

  toggleErrorMessages () {
    this.setState({ showErrorMessages: !this.state.showErrorMessages })
  }

  render () {
    return (
      <Fragment>
        <Story title='TextInput' key='basic'>
          <TextInput onChange={() => {}} />
        </Story>

        <Story title='TextInput with placeholder' key='placeholder'>
          <TextInput onChange={() => {}} placeholder='Enter a value' />
        </Story>

        <Story title='TextInput with label' key='label'>
          <TextInput onChange={() => {}} label='Test label' />
        </Story>

        <Story title='Disabled TextInput' key='disabled'>
          <TextInput onChange={() => {}} disabled />
        </Story>

        <Story title='Invalid TextInput with errors' key='errors'>
          <Checkbox
            id='showErrorMessages'
            name='showErrorMessages'
            onChange={this.toggleErrorMessages}
            label='Show error messages below'
          />

          <TextInput
            errors={['Error message 1', 'Error message 2']}
            showErrorMessages={this.state.showErrorMessages}
          />
        </Story>

        <Story title='Required TextInput' key='required'>
          <form onSubmit={(e) => {
            e.preventDefault()
            return false
          }}>
            <Text tag='p'>When using <Text style='body-text-emphasis'>required</Text>, a client-side validation will be performed, based on <Text style='body-text-emphasis'>value</Text> and <Text style='body-text-emphasis'>type</Text>. Here, a valid email will be checked.</Text>

            <Spacing marginY={1}>
              <TextInput
                label='Email Address'
                required
                type='email'
              />
            </Spacing>

            <Button htmlType='submit' type='primary'>Check</Button>
          </form>
        </Story>
      </Fragment>
    )
  }
}

TextInputs.displayName = 'TextInputs'

export default TextInputs
