import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { isNotPrivate } from '@utils/privacyStatusUtil'

const InNetworkEndUserAttestation = (data) => {
  let checkboxLabel, description, privacyDescription

  checkboxLabel = renderCheckboxLabel(data)
  privacyDescription = renderPrivacyDescription(data)
  description = renderDescriptionBasedOnOrgs(data, privacyDescription)

  return [checkboxLabel, description]
}

const renderCheckboxLabel = (data) => {
  const { local } = window
  let checkboxLabel = local.t('referral_modal.fields.client_consent.label.in_network_consent', { organization_name: data.organizationName || '' })

  return checkboxLabel
}

const renderDescriptionBasedOnOrgs = (data, privacyDescription) => {
  const { local } = window
  let description, orgNameTags

  orgNameTags = data.organizationName ? renderInNetworkPartners(data.organizationName) : null

  description = <Fragment>
    {local.t('referral_modal.fields.client_consent.description.in_network_header')} <br /> <br />
    {orgNameTags}
    {local.t('referral_modal.fields.client_consent.description.in_network_access_info')} <br /><br />
    {privacyDescription}
  </Fragment>

  return description
}

const renderPrivacyDescription = (data) => {
  const { local } = window
  const { name: sendingOrgName } = useSelector((store) => store.global.currentCompany.attributes)
  const notPrivate = isNotPrivate(data.privacyStatus)
  let privacyDescription

  if (notPrivate) {
    // Not private resource site
    privacyDescription = <span>{local.t('referral_modal.fields.client_consent.description.in_network_non_private_share')}</span>
  } else {
    // Private resource site
    privacyDescription = <span>
      {local.t('referral_modal.fields.client_consent.description.in_network_private_share')}<br /><br />
      <span className='organization-name'>Receiving Organization</span><br />
      {data.name}<br /><br />

      <span className='organization-name'>Sending Organization</span><br />
      {sendingOrgName}<br /><br />
    </span>
  }

  return privacyDescription
}

const renderInNetworkPartners = (organizationNames) => {
  const orgNameTags = []

  orgNameTags.push(`- ${organizationNames}`)
  orgNameTags.push(<br />)
  orgNameTags.push(<br />)

  return orgNameTags
}

InNetworkEndUserAttestation.propTypes = {
  data: PropTypes.object
}

export default InNetworkEndUserAttestation
