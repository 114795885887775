import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import classNames from 'classnames'
import uuid from 'uuid'

import Label from '@components/Label'
import FieldErrors from '@components/Form/FieldErrors'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.scss'

class DatePicker extends PureComponent {
  constructor (props) {
    super(props)

    this.inputId = props.id || `datepicker-id-${uuid()}`
  }

  getClasses () {
    return classNames(
      'DatePicker',
      { 'DatePicker--invalid': this.props.errors.length }
    )
  }

  render () {
    const {
      dataTestid,
      disabled,
      selected,
      onChange,
      label,
      required,
      minDate,
      maxDate,
      errors,
      showErrorMessages
    } = this.props

    return (
      <div data-testid={dataTestid} className={this.getClasses()}>
        {label && <Label text={label} id={this.inputId} required={required} />}

        <ReactDatePicker
          className='DatePicker__input'
          disabled={disabled}
          id={this.inputId}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          placeholderText='MM/DD/YYYY'
          selected={selected}
        />

        <FieldErrors errors={errors} showErrorMessages={showErrorMessages} />
      </div>
    )
  }
}

DatePicker.displayName = 'DatePicker'

DatePicker.defaultProps = {
  disabled: false,
  errors: [],
  onChange: () => {},
  required: false,
  showErrorMessages: false
}

DatePicker.propTypes = {
  dataTestid: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  id: PropTypes.string,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  selected: PropTypes.instanceOf(Date),
  showErrorMessages: PropTypes.bool
}

export default DatePicker
