import React from 'react'
import Grid from '@material-ui/core/Grid'

import IconButton from '@components/IconButton'
import Story from '../Story'

// list of available icons
// https://api.flutter.dev/flutter/material/Icons-class.html#constants

const IconButtons = () => (
  <Story title='Icon Buttons'>
    <Grid container spacing={2}>
      <Grid item>
        <IconButton icon='close' onClick={() => {}} aria-label='Close' />
      </Grid>

      <Grid item>
        <IconButton icon='favorite' onClick={() => {}} aria-label='Favorite' />
      </Grid>

      <Grid item>
        <IconButton icon='info' onClick={() => {}} aria-label='Info' />
      </Grid>

      <Grid item>
        <IconButton icon='keyboard_arrow_down' onClick={() => {}} aria-label='Show more' />
      </Grid>
    </Grid>
  </Story>
)

IconButtons.displayName = 'IconButtons'

export default IconButtons
