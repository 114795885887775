import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import RemovableTag from '@components/RemovableTag'

class GuidedSearchEnhancedInputSelection extends PureComponent {
  selectionName () {
    return this.props.selection.attributes.name
  }

  selectionId () {
    return this.props.selection.attributes.id
  }

  constructor (props) {
    super(props)

    this.onDelete = this.onDelete.bind(this)
  }

  onDelete () {
    this.props.onDelete(this.props.selection)
  }

  render () {
    const { inputName, selection } = this.props

    return (
      <Grid item>
        <div
          className='GuidedSearchEnhancedInputSelection'
          data-test={`selection-${selection.name}`}
          data-testid='GuidedSearchEnhancedInputSelection'
        >
          <RemovableTag style='guided-search' onRemove={this.onDelete}>
            {this.selectionName()}
          </RemovableTag>

          <input name={inputName} value={this.selectionId()} type='hidden' />
        </div>
      </Grid>
    )
  }
}

GuidedSearchEnhancedInputSelection.propTypes = {
  inputName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  selection: PropTypes.object.isRequired
}

GuidedSearchEnhancedInputSelection.displayName = 'GuidedSearchEnhancedInputSelection'

export default GuidedSearchEnhancedInputSelection
