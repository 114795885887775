import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import ModalHeader from '@components/Modal/ModalHeader'

const ReferralConsentModal = ({ open, content, onClose }) => {
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <ModalHeader onClose={onClose} />
        <DialogContent>
          {content}
        </DialogContent>
      </Dialog>
    </div>
  )
}

ReferralConsentModal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ReferralConsentModal
