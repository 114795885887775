import { localeStringGenerator } from '@utils/localeUtil'

export default function cartShareValidator (setError) {
  return ({ language, sendText, phone, sendEmail, email, consentGranted }) => {
    const getString = localeStringGenerator('cart_share.form.validation')
    let errorCount = 0

    const validateField = (name, invalidCondition) => {
      if (invalidCondition) {
        setError(name, [getString(name)])
        errorCount++
      } else {
        setError(name, [])
      }
    }

    validateField('language', !language)
    validateField('phone', sendText && !phone)
    validateField('email', sendEmail && !email)
    validateField('consentGranted', !consentGranted)

    return !errorCount
  }
}
