import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import DownloadButton from '@scenes/resourceSites/components/DownloadButton'
import DownloadModal from '@scenes/resourceSites/components/DownloadModal'
import { getRecord } from '@selectors'
import { manuallyLoadSearchResultIntoState, openDownloadModal } from '@scenes/resourceSites/actions'

const LegacyResourceSiteDownloadButton = props => {
  useEffect(() => {
    props.manuallyLoadSearchResultIntoState(props.resourceSiteInput)
  }, [props.resourceSiteInput])

  if (!props.resourceSite) return null

  return <React.Fragment>
    <DownloadButton
      data-behavior='DownloadResourceButton--legacy'
      openDownloadModal={props.openDownloadModal}
      path={props.resourceSite.links.pdf}
      resourceSiteId={props.resourceSite.id}
      analyticsReferrer={props.analyticsReferrer}
    />

    {props.modalIsOpen && <DownloadModal
      resourceSite={props.resourceSite}
      analyticsReferrer={props.analyticsReferrer}
    />}
  </React.Fragment>
}

const mapStateToProps = (state, ownProps) => {
  return {
    modalIsOpen: state.resourceSites.downloadModal.open,
    resourceSite: getRecord(state, 'resource_site_search_result', ownProps.resourceSiteInput.id)
  }
}

const mapDispatchToProps = {
  manuallyLoadSearchResultIntoState,
  openDownloadModal
}

LegacyResourceSiteDownloadButton.propTypes = {
  manuallyLoadSearchResultIntoState: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  openDownloadModal: PropTypes.func.isRequired,
  resourceSiteInput: PropTypes.shape({
    id: PropTypes.string,
    serviceName: PropTypes.string
  }).isRequired,
  analyticsReferrer: PropTypes.object,
  resourceSite: PropTypes.object
}

LegacyResourceSiteDownloadButton.defaultProps = {
  modalIsOpen: false
}

export default connect(mapStateToProps, mapDispatchToProps)(LegacyResourceSiteDownloadButton)
