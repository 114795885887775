import { useCallback, useMemo } from 'react'
import startCase from 'lodash/startCase'

import { localeStringGenerator } from '@utils/localeUtil'
import { serializeCoverageArea } from './utils'
import resourceSiteCoverageAreasService from '@services/resourceSiteCoverageAreasService'
import useGetCollection from '@hooks/useGetCollection'
import useCreateRecord from '@hooks/useCreateRecord'
import useDestroyRecord from '@hooks/useDestroyRecord'

const getFormatters = (resourceSiteId, getString) => {
  const coverageAreaDescription = (coverageArea) => {
    if (coverageArea.state && coverageArea.county) {
      return getString('card_type.county', coverageArea)
    } else if (coverageArea.state && coverageArea.zipcode) {
      return getString('card_type.zipcode', coverageArea)
    } else if (coverageArea.state && coverageArea.city) {
      return getString('card_type.city', coverageArea)
    } else {
      return getString('card_type.state', coverageArea)
    }
  }

  const locationSearchOptionLabel = ({ address, type }) => (
    `Coverage Area: ${address}, Type: ${startCase(type)}`
  )

  const value = useMemo(() => ({
    coverageAreaDescription,
    locationSearchOptionLabel
  }), [resourceSiteId])

  return value
}

const getAssociations = (resourceSiteId) => {
  const getCoverageAreas = useCallback(() => {
    return useGetCollection('resource_sites_coverage_area', 'resourceSite', resourceSiteId) || []
  })

  const value = useMemo(() => ({
    getCoverageAreas
  }), [resourceSiteId])

  return value
}

const getSubmitters = (resourceSiteId) => {
  const coveragAreaResource = resourceSiteCoverageAreasService.getResource(resourceSiteId)

  const createCoverageArea = () => {
    const { loading, createRecord } = useCreateRecord()
    const { create } = coveragAreaResource

    const createRequest = (formData, onFailure) => createRecord(
      () => create(serializeCoverageArea(formData), onFailure)
    )

    return useMemo(() => ({ loading, createRequest }))
  }

  const deleteCoverageArea = (coverageAreaId) => {
    const { loading, destroyRecord } = useDestroyRecord(
      coverageAreaId,
      'resource_sites_coverage_area'
    )
    const { destroy } = coveragAreaResource

    const deleteCoverageAreaRequest = () => destroyRecord(
      () => destroy(coverageAreaId)
    )

    return useMemo(() => ({ loading, deleteCoverageAreaRequest }))
  }

  const value = useMemo(() => ({
    createCoverageArea,
    deleteCoverageArea
  }), [resourceSiteId])

  return value
}

const getPropCollections = (resourceSite) => {
  const getString = localeStringGenerator('resource_sites.coverage_area')

  return {
    associations: getAssociations(resourceSite),
    formatters: getFormatters(resourceSite, getString),
    submitters: getSubmitters(resourceSite),
    getString
  }
}

export default getPropCollections
