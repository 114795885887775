import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Spacing from '@components/Spacing'
import Text from '@components/Text'
import TextInput from '@components/TextInput'

const GeoFilterField = ({ clientBasedSearch, errors, onChange, value }) => {
  const placeholder = clientBasedSearch
    ? window.local.t('resource_sites.guided_search_form.client_geo_filter.placeholder')
    : window.local.t('resource_sites.guided_search_form.geo_filter.placeholder')
  const label = clientBasedSearch
    ? window.local.t('resource_sites.guided_search_form.client_geo_filter.label')
    : window.local.t('resource_sites.guided_search_form.geo_filter.label')

  const wrapperClasses = classNames(
    'GuidedSearchForm__geo-filter',
    { 'client-based-search': clientBasedSearch }
  )

  return (
    <div className={wrapperClasses}>
      <Spacing marginRight={1}>
        <Text brandingEmphasis style='body-text'>
          {label}
        </Text>
      </Spacing>

      <TextInput
        id='GuidedSearchForm__geo-filter__input'
        errors={errors}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        required
      />
    </div>
  )
}

GeoFilterField.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  clientBasedSearch: PropTypes.bool,
  value: PropTypes.string
}

GeoFilterField.defaultProps = {
  clientBasedSearch: false,
  errors: []
}

export default GeoFilterField
