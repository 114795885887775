import React, { PureComponent } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Alerts from './StyleguidePages/Alerts'
import Buttons from './StyleguidePages/Buttons'
import LinkButtons from './StyleguidePages/LinkButtons'
import EmphasisBackgrounds from './StyleguidePages/EmphasisBackgrounds'
import Spacings from './StyleguidePages/Spacings'
import ExpansionPanels from './StyleguidePages/ExpansionPanels'
import ElementWithTooltips from './StyleguidePages/ElementWithTooltips'
import Stamps from './StyleguidePages/Stamps'
import Texts from './StyleguidePages/Texts'
import Selects from './StyleguidePages/Selects'
import SelectionMenus from './StyleguidePages/SelectionMenus'
import SelectWithMenus from './StyleguidePages/SelectWithMenus'
import RadioButtons from './StyleguidePages/RadioButtons'
import TextInputs from './StyleguidePages/TextInputs'
import PhoneInputs from './StyleguidePages/PhoneInputs'
import DatePickers from './StyleguidePages/DatePickers'
import ExpandableTextAreas from './StyleguidePages/ExpandableTextAreas'
import Cards from './StyleguidePages/Cards'
import RemovableTags from './StyleguidePages/RemovableTags'
import IconButtons from './StyleguidePages/IconButtons'

import StyleguideEntryContainer from './StyleguideEntryContainer'
import PaginationBars from './StyleguidePages/PaginationBars'

class StyleguideEntry extends PureComponent {
  render () {
    return (
      <Router basename='/en/styleguide'>
        <StyleguideEntryContainer>
          <Alerts />
          <Buttons />
          <Cards />
          <DatePickers />
          <ElementWithTooltips />
          <EmphasisBackgrounds />
          <ExpandableTextAreas />
          <ExpansionPanels />
          <IconButtons />
          <LinkButtons />
          <PhoneInputs />
          <RadioButtons />
          <RemovableTags />
          <SelectionMenus />
          <Selects />
          <SelectWithMenus />
          <Spacings />
          <Stamps />
          <TextInputs />
          <Texts />
          <PaginationBars />
        </StyleguideEntryContainer>
      </Router>
    )
  }
}

export default StyleguideEntry
