import React, { useCallback, useContext, useState } from 'react'

import useFormState from '@hooks/useFormState'

import Button from '@components/Button'
import Icon from '@components/Icon'
import Modal from '@components/Modal'
import Text from '@components/Text'

import CoverageAreaForm from './CoverageAreaForm'

import { CoverageAreaManagerContext } from '../CoverageAreaManager'

const AddCoverageAreaForm = ({ ...props }) => {
  const [modalOpened, openModal] = useState(false)
  const [errors, setError] = useState([])
  const {
    getString,
    resourceSiteId,
    submitters: { createCoverageArea }
  } = useContext(CoverageAreaManagerContext)
  const { formState, updateField, resetFormState } = useFormState({
    resourceSiteId: resourceSiteId,
    city: '',
    county: '',
    state: '',
    type: '',
    zipcode: ''
  })
  const { loading, createRequest } = createCoverageArea()
  const toggleModal = useCallback((e) => {
    openModal(!modalOpened)
    setError([])
  })

  const onFailure = ({ response }) => {
    const errorsArray = response.data.errors.map(e => e.detail)

    setError(errorsArray)
  }

  const CoverageAreaFormSubmitted = (e) => {
    e.preventDefault()
    // create new note
    createRequest(formState.data, onFailure).then(() => {
      resetFormState()
      toggleModal()
    })
  }

  const onLocationSelectChange = () => {
    setError([])
  }

  return (
    <CoverageAreaForm
      formState={formState}
      updateField={updateField}
      resetFormState={resetFormState}
    >
      <Button type='primary' onClick={toggleModal}>
        <Text style='buttons' withIcon>
          <Icon iconKey='add_circle' size={16} />{' '}
          {getString('form.add.button_text')}
        </Text>
      </Button>

      <Modal
        actions={
          <CoverageAreaForm.Submit
            loading={loading}
            buttonText={getString('form.add.button_text')}
            errors={errors}
          />
        }
        content={
          <CoverageAreaForm.LocationSearch
            loading={loading}
            errors={errors}
            onLocationSelectChange={onLocationSelectChange}
          />
        }
        headerText={getString('form.add.modal_header')}
        open={modalOpened}
        onClose={toggleModal}
        onSubmit={CoverageAreaFormSubmitted}
      />
    </CoverageAreaForm>
  )
}

export default AddCoverageAreaForm
