import { useMemo } from 'react'
import useGetSetting from '@hooks/useGetSetting'
import getStateReducer from '../Referral/stateReducer'

export default function useResourceSite (client, resourceSite) {
  const requiredFields = () => {
    const hidePatientUI = useGetSetting('can_create_referral_without_patient')
    const fields = hidePatientUI ? ['programs'] : ['client', 'programs']

    return fields
  }

  const [state, changeState, resetState] = getStateReducer({
    client,
    closedLoopable: resourceSite.closedLoopable,
    requiredFields: requiredFields()
  })

  const value = useMemo(() => ({
    client,
    resourceSite,
    state,
    changeState,
    resetState
  }), [resourceSite, client, JSON.stringify(state)])

  return value
}
