import React from 'react'
import PropTypes from 'prop-types'

import './GuidedSearchResult.scss'

class GuidedSearchResult extends React.Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  resultId () {
    return this.props.result.id || this.props.result.attributes.id
  }

  handleClick (e) {
    e.preventDefault()
    e.stopPropagation()
    this.props.onClick(this.props.result)
  }

  render () {
    const { result, children } = this.props
    const testId = result ? this.resultId() : 'no-match'

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <li className='GuidedSearchResult' data-testid='GuidedSearchResult'>
        <a
          href='#'
          className='GuidedSearchResult__clickable'
          onClick={this.handleClick}
          data-testid={`GuidedSearchResult__clickable--${testId}`}
          role='button'
        >
          {children}
        </a>
      </li>
    )
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

GuidedSearchResult.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  result: PropTypes.object
}

GuidedSearchResult.displayName = 'GuidedSearchResult'

export default GuidedSearchResult
