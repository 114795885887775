import React, { createContext } from 'react'
import PropTypes from 'prop-types'

// Pull in child components
import PatientInfo from './PatientInfo'
import Header from './Header'

import useClientDetails from './useClientDetails'

export const ClientDetailsContext = createContext()

const { Provider } = ClientDetailsContext

const ClientDetails = ({
  children,
  clientId,
  ...props }) => {
  const value = useClientDetails(clientId)

  return (
    <Provider value={value}>
      <div className='ClientDetails' {...props}>
        {children}
      </div>
    </Provider>
  )
}

ClientDetails.propTypes = {
  children: PropTypes.node.isRequired,
  clientId: PropTypes.number
}

ClientDetails.PatientInfo = PatientInfo
ClientDetails.Header = Header

export default ClientDetails
