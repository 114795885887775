import React, { Fragment, PureComponent } from 'react'
import Grid from '@material-ui/core/Grid'

import Text from '@components/Text'
import Icon from '@components/Icon'
import Button from '@components/Button'
import Story from '../Story'

class Texts extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Page Header'>
          <Text style='page-header'>
            page-header
          </Text>
        </Story>
        <Story title='Sub-header Large'>
          <Text style='sub-header-large'>
            sub-header-large
          </Text>
        </Story>
        <Story title='Sub-header Medium'>
          <Text style='sub-header-medium'>
            sub-header-medium
          </Text>
        </Story>
        <Story title='Sub-header Small'>
          <Text style='sub-header-small'>
            sub-header-small
          </Text>
        </Story>
        <Story title='Global Navigation'>
          <Text style='global-navigation'>
            global-navigation
          </Text>
        </Story>
        <Story title='Buttons'>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={3}>
              <Text style='buttons'>Outside Button</Text>
            </Grid>

            <Grid item xs={3}>
              <Button type='primary'>
                <Text style='buttons'>Inside Button</Text>
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Button type='primary'>
                <Text style='buttons' withIcon>
                  Inside with Icon
                  <Icon iconKey='search' size={12} />
                </Text>
              </Button>
            </Grid>
          </Grid>
        </Story>
        <Story title='Form Fields'>
          <Text style='form-fields'>
            form-fields
          </Text>
        </Story>
        <Story title='Form Fields Disabled'>
          <Text style='form-fields-disabled'>
            form-fields-disabled
          </Text>
        </Story>
        <Story title='Sub-header Extra Small'>
          <Text style='sub-header-extra-small'>
            sub-header-extra-small
          </Text>
        </Story>
        <Story title='Sub-header Extra Small Secondary'>
          <Text style='sub-header-extra-small-secondary'>
            sub-header-extra-small-secondary
          </Text>
        </Story>
        <Story title='Body Text'>
          <Text style='body-text'>
            body-text
          </Text>
        </Story>
        <Story title='Body Text Secondary'>
          <Text style='body-text-secondary'>
            body-text-secondary
          </Text>
        </Story>
        <Story title='Body Text Emphasis'>
          <Text style='body-text-emphasis'>
            body-text-emphasis
          </Text>
        </Story>
        <Story title='Error Message'>
          <Text style='error-message'>
            error-message
          </Text>
        </Story>
        <Story title='Success Message'>
          <Text style='success-message'>
            success-message
          </Text>
        </Story>
        <Story title='Body Text Small'>
          <Text style='body-text-small'>
            body-text-small
          </Text>
        </Story>
        <Story title='Stamp'>
          <Text style='stamp'>
            stamp
          </Text>
        </Story>
        <Story title='Render Newlines'>
          <Text tag='div'>{"Without\nnewlines\nrendered"}</Text>
          <Text tag='div' renderNewlines>{"With\nnewlines\nrendered"}</Text>
        </Story>
        <Story title='Text with Icon'>
          <Text style='sub-header-extra-small' withIcon>
            <Icon iconKey='check_circle' color='green' size={12} />
            text-with-icon
          </Text>
        </Story>
        <Story title='Branding Emphasis'>
          <Text tag='h1' style='page-header' brandingEmphasis>Styled page header</Text>
          <Text tag='p' style='body-text' brandingEmphasis>Styled body text</Text>
        </Story>
      </Fragment>
    )
  }
}

Texts.displayName = 'Text'

export default Texts
