import axios from 'axios'
import apiConfig from '@config/apiConfig'

const requestToCreate = (formData) => (
  axios(apiConfig.post(
    '/api/internal/resource_sites/request_to_create',
    formData
  ))
)

export default {
  requestToCreate
}
