import React, { PureComponent, Fragment } from 'react'
import SelectWithMenu from '@components/SelectWithMenu'
import Story from '../Story'

const items = {
  'Foo': [
    { name: 'Alice', id: 1 },
    { name: 'Bob', id: 2 },
    { name: 'Charlie', id: 3 },
    { name: 'Dave', id: 4 },
    { name: 'Evan', id: 5 }
  ],
  'Bar': [
    { name: 'Fred', id: 6 },
    { name: 'Greg', id: 7 },
    { name: 'Heather', id: 8 },
    { name: 'Iris', id: 9 },
    { name: 'Jessica', id: 10 }
  ]
}

const loadMenuOptions = (menuItem, callback) => {
  setTimeout(() => {
    callback(items[menuItem])
  }, 200)
}

const filter = (query, items) => {
  return items.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
}

const loadOptions = (query, callback) => {
  setTimeout(() => {
    callback(filter(query, Object.values(items).reduce((combinedArray, array) => {
      return [...combinedArray, ...array]
    }, [])))
  }, 200)
}

class SelectWithMenus extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Select With Menu'>
          <SelectWithMenu
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            selectionMenuTitle='Select from these options'
            selectionMenuOptions={items}
          />
        </Story>
        <Story title='Select With Menu Multi'>
          <SelectWithMenu
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            selectionMenuTitle='Select from these options'
            selectionMenuOptions={items}
            isMulti
          />
        </Story>
        <Story title='Select With Menu Async'>
          <SelectWithMenu
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            getMenuLabel={(option) => option.name}
            getMenuValue={(option) => option.id}
            selectionMenuTitle='Select from these options'
            selectionMenu={[{ name: 'Foo', id: 'Foo' }, { name: 'Bar', id: 'Bar' }]}
            loadSelectionMenuOptions={loadMenuOptions}
            loadOptions={loadOptions}
          />
        </Story>
      </Fragment>
    )
  }
}

SelectWithMenus.displayName = 'SelectWithMenus'

export default SelectWithMenus
