import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import EmphasisBackground from '@components/EmphasisBackground'
import { timelineEventContentClassNames } from '../utils/referralTimelineUtil'

export default class NoteEvent extends React.PureComponent {
  renderTitle () {
    return <div className='ReferralTimeline__event__title'>
      {window.local.t('referrals.timeline.events.note', { user: this.props.userText })}
    </div>
  }

  renderContent () {
    const classNames = timelineEventContentClassNames({
      eventType: this.props.type,
      isLastElement: this.props.isLastElement
    })

    return <div className={classNames}>
      <EmphasisBackground emphasisLevel='medium'>
        {this.props.note}
      </EmphasisBackground>
    </div>
  }

  render () {
    return <Fragment>
      {this.renderTitle()}
      {this.renderContent()}
    </Fragment>
  }
}

NoteEvent.propTypes = {
  isLastElement: PropTypes.bool.isRequired,
  note: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userText: PropTypes.string.isRequired
}
