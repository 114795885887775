import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Text from '@components/Text'
import Spacing from '@components/Spacing'
import ReferralTierStamp from '@components/ReferralTierStamp'
import EmphasisBackground from '@components/EmphasisBackground'

import './ReferralServiceDetailsRow.scss'
import { getCollectionFromRelationship, denormalizeRecords, getAssociation, getRecord } from '@selectors'
import {
  mostRecentEncounterPerResourceProgram,
  sortReferralEncountersByDates
} from '@scenes/referralShow/selectors'
import { formatName } from '@utils/formatterUtil'
import { serviceDetailSummaryText } from '@scenes/referralShow/utils/serviceDetailSummaryTextUtil'
import { isInNetworkPreferred } from '@utils/networkTiersUtil'

class ReferralServiceDetailsRow extends PureComponent {
  renderMostRecentReferralEncounter (resourceProgramAssignment) {
    const mostRecentReferralEncounter = this.props.mostRecentEncounters[resourceProgramAssignment.id]

    if (mostRecentReferralEncounter) {
      const user = formatName(mostRecentReferralEncounter.user)

      const text = serviceDetailSummaryText({
        referralEncounter: mostRecentReferralEncounter,
        resourceProgramAssignmentId: resourceProgramAssignment.id
      })

      return (
        <Fragment>
          <Text style='body-text' tag='div'>{text}</Text>
          <Spacing marginTop={1}>
            <Text style='body-text-small' tag='div'>{window.local.t('referrals.show.service_details.logged_by', { user })}</Text>
          </Spacing>
        </Fragment>
      )
    } else {
      return (
        <Text style='body-text-secondary'>
          {window.local.t('referrals.show.service_details.actions.none')}
        </Text>
      )
    }
  }

  render () {
    return (
      <Spacing paddingTop={2}>
        <EmphasisBackground emphasisLevel='medium'>

          {this.props.resourceProgramAssignments.map((resourceProgramAssignment) => (
            <div
              className='ReferralServiceDetailsRow__row'
              key={resourceProgramAssignment.id}
              data-test={`service-details-row-${resourceProgramAssignment.id}`}
            >
              <Grid container justify='space-between'>
                <Grid item xs={4}>
                  <Text style='body-text-emphasis'>{resourceProgramAssignment.resourceProgramName}</Text>

                  {isInNetworkPreferred(resourceProgramAssignment.tier) &&
                    <ReferralTierStamp
                      version='short'
                      tier={resourceProgramAssignment.tier}
                      tag='span'
                    />
                  }
                </Grid>
                <Grid item xs={8}>
                  {this.renderMostRecentReferralEncounter(resourceProgramAssignment)}
                </Grid>
              </Grid>
            </div>
          ))}
        </EmphasisBackground>
      </Spacing>
    )
  }
}

ReferralServiceDetailsRow.propTypes = {
  resourceProgramAssignments: PropTypes.array.isRequired,
  mostRecentEncounters: PropTypes.object
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const resourceProgramAssignments = getAssociation(state, referral, 'referralResourceProgramAssignments')
  const referralEncounters = getCollectionFromRelationship({
    state,
    type: 'referral_encounter',
    association: 'referral',
    id: props.referralId
  })

  const denormalizedReferralEncounters = denormalizeRecords(state, referralEncounters)
  const mostRecentEncounters = mostRecentEncounterPerResourceProgram(
    resourceProgramAssignments,
    denormalizedReferralEncounters
  )

  const mostRecentEncounter = sortReferralEncountersByDates(denormalizedReferralEncounters)[0]
  const mostRecentEncounterDate = mostRecentEncounter && mostRecentEncounter.dateOfAction

  return {
    resourceProgramAssignments,
    mostRecentEncounterDate,
    mostRecentEncounters
  }
}

export default connect(mapStateToProps, null)(ReferralServiceDetailsRow)
