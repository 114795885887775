import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import EmphasisBackground from '@components/EmphasisBackground'
import Text from '@components/Text'

import { formatDate } from '@utils/formatterUtil'
import { timelineEventContentClassNames } from '../utils/referralTimelineUtil'

export default class ClosedLoopStatusUpdateEvent extends React.PureComponent {
  isCanceled () {
    return this.props.newStatus === window.local.t('referrals.show.referral_statuses.canceled.name')
  }

  cancellationText () {
    const reasonBeginning = window.local.t(
      `referrals.timeline.events.status_update.cancellation.context_beginning`,
      { cancellation_date: formatDate(this.props.timestamp) }
    )
    const reasonEnding = window.local.t(
      `referrals.timeline.events.status_update.cancellation.context_ending.${this.props.contextCode}`
    )

    return `${reasonBeginning} ${reasonEnding}`
  }

  renderCancellationContextCode () {
    if (this.isCanceled()) {
      return <EmphasisBackground emphasisLevel='high'>
        {this.cancellationText()}
      </EmphasisBackground>
    }
  }

  renderCancellationContext () {
    const shouldDisplayContext = this.isCanceled() &&
      this.props.context &&
      this.props.contextCode === 'other_context'

    if (shouldDisplayContext) {
      return <EmphasisBackground emphasisLevel='medium'>
        {this.props.context}
      </EmphasisBackground>
    }
  }

  renderTitle () {
    const { newStatus, oldStatus, userText, serviceName, type, isLastElement } = this.props

    let statusType = 'accept'

    if (newStatus === window.local.t('referrals.show.referral_statuses.service_completed.name')) {
      statusType = 'complete'
    } else if (newStatus === window.local.t('referrals.show.referral_statuses.canceled.name')) {
      statusType = 'decline'
    }


    return <Fragment>
      <div className='ReferralTimeline__event__title'>
        {serviceName}
        {window.local.t(`referrals.timeline.events.status_update.closed_loop.${statusType}`)}
        {window.local.t('referrals.timeline.events.status_update.start', { user: userText })}
        <Text style='body-text-emphasis'>{oldStatus}</Text>
        {window.local.t('referrals.timeline.events.status_update.middle')}
        <Text style='body-text-emphasis'>{newStatus}</Text>
        {window.local.t('referrals.timeline.events.status_update.end')}
      </div>

    </Fragment>
  }

  renderContent () {
    const classNames = timelineEventContentClassNames({
      eventType: this.props.type,
      isLastElement: this.props.isLastElement
    })

    if (this.props.contextCode !== 'other_context') {

      return <div className={classNames}>
        {this.renderCancellationContextCode()}
        {this.renderCancellationContext()}
      </div>
    } else {
      return <div className={classNames} />
    }
  }

  render () {
    return <Fragment>
      {this.renderTitle()}
      {this.renderContent()}
    </Fragment>
  }
}

ClosedLoopStatusUpdateEvent.propTypes = {
  context: PropTypes.string,
  contextCode: PropTypes.string,
  isLastElement: PropTypes.bool.isRequired,
  newStatus: PropTypes.string.isRequired,
  oldStatus: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
  type: PropTypes.string.isRequired,
  userText: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired
}
