import React from 'react'
import PropTypes from 'prop-types'

import ActionButton from '@components/ActionButton'
import Icon from '@components/Icon'
import NotificationItem from './NotificationItem'

class NotificationPanel extends React.Component {
  renderEmptyState () {
    const { t, items } = this.props

    if (!items.length) {
      return (
        <article data-test='notification-panel--empty'>
          <h1 className='notification-panel__content--empty__title'>
            {t.notifications.items.empty.title}
          </h1>

          <p className='notification-panel__content--empty__message'>
            {t.notifications.items.empty.message}
          </p>
        </article>
      )
    }
  }

  render () {
    const { items, markAllRead, t, settingsPath, handleClick, markOneAsRead } = this.props
    const emptyClass = items.length ? '' : 'notification-panel__content--empty'

    return (
      <aside className='notification-panel' data-test='notification-panel'>
        <header
          className='notification-panel__close text-with-icon'
          onClick={handleClick}
        >
          {t.notifications.panel.close}

          <Icon
            iconKey='clear'
            className='mi--14 mi--push-left notification-panel__close__icon'
          />
        </header>

        <div className={`notification-panel__content ${emptyClass}`}>
          {this.renderEmptyState()}

          {items.map((item) => {
            return (
              <NotificationItem
                key={item.id}
                item={item}
                markOneAsRead={markOneAsRead}
              />
            )
          })}
        </div>

        <footer className='notification-panel__footer'>
          <ActionButton
            className='button--underlined'
            text={t.notifications.panel.mark_all_read}
            onClick={markAllRead}
          />
          <ActionButton
            className='button--underlined'
            text={t.notifications.panel.view_settings}
            onClick={() => { window.location.href = settingsPath }}
          />
        </footer>
      </aside>
    )
  }
}

NotificationPanel.propTypes = {
  handleClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  markAllRead: PropTypes.func.isRequired,
  markOneAsRead: PropTypes.func.isRequired,
  settingsPath: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired
}

export default NotificationPanel
