import { CART_SHARES_ENDPOINT, CART_SHARES_UNAUTHENTICATED_ENDPOINT } from '@constants/apiEndpoints'
import { getApiResource } from '@utils/apiUtil'

const type = 'cart_share'

export const unauthenticatedResource = getApiResource(
  CART_SHARES_UNAUTHENTICATED_ENDPOINT
)

export const resource = getApiResource(CART_SHARES_ENDPOINT)

export default {
  type,
  unauthenticated: unauthenticatedResource,
  ...resource
}
