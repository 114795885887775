import React from 'react'
import PropTypes from 'prop-types'
import './ContentSection.scss'

const containerClassNames = (emphasis, bottomMargin) => {
  let classNames = ''

  if (emphasis) {
    classNames += 'ContentSection--emphasis '
  }
  if (bottomMargin) {
    classNames += 'ContentSection__container--margin-bottom '
  }

  return classNames
}

const bodyClassNames = (contentWidth) => {
  return 'ContentSection__body ContentSection__body--' + contentWidth
}

class ContentSection extends React.PureComponent {
  render () {
    return (
      <div className={containerClassNames(this.props.emphasis, this.props.bottomMargin)}>
        <div className='ContentSection__content'>
          <h1 className='ContentSection__title'>{this.props.title}</h1>
          <div className={bodyClassNames(this.props.contentWidth)}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

ContentSection.defaultProps = {
  contentWidth: 'full'
}

ContentSection.propTypes = {
  bottomMargin: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  emphasis: PropTypes.bool,
  contentWidth: PropTypes.oneOf([
    'normal',
    'wide',
    'full'
  ])
}

export default ContentSection
