import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useCurrentUser from '@hooks/useCurrentUser'
import { formatName, formatList } from '@utils/formatterUtil'
import { GOAL_STATUSES, REFERRAL_BASE_URL } from './constants'

import ClientGoalNotes from './ClientGoalNotes'
import EditGoalForm from './Forms/EditGoalForm'
import ToggleGoalStatusForm from './Forms/ToggleGoalStatusForm'

import ExpansionPanel from '@components/ExpansionPanel'
import Grid from '@material-ui/core/Grid'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import { ClientGoalsContext } from './ClientGoals'

const ListItem = ({ goal, ...props }) => {
  const [expandedDetails, setExpandedDetails] = useState(false)
  const handleToggleExpanded = (newExpanded) => {
    setExpandedDetails(newExpanded)
  }
  const {
    getGoalAssociations,
    getGoalCreatedDate,
    getGoalLastUpdatedDate,
    getGoalFilterCategoryNames,
    getString
  } = useContext(ClientGoalsContext)
  const { user } = getGoalAssociations(goal)
  const currentUser = useCurrentUser()
  const goalUserName = user.id === currentUser.id ? getString('you') : formatName(user)
  const gridContainerProps = {
    container: true,
    spacing: 1,
    direction: 'row'
  }
  const gridMainColProps = {
    item: true,
    xs: 12,
    sm: 8,
    md: 9
  }
  const gridAsideColProps = {
    item: true,
    xs: 12,
    sm: 4,
    md: 3
  }
  const combinedClassNames = classNames(
    'ClientGoals__ListItem',
    { 'ClientGoals__ListItem--expanded-details': expandedDetails }
  )

  const renderLinkedReferrals = () => {
    const linkedReferrals = goal.referralIds || []
    if (!linkedReferrals.length) return getString('list.item.linked_referrals.none')

    return (
      <>
        {linkedReferrals.map((referralId, index) => (
          <span key={referralId}>
            {index > 0 && ', '}
            <a href={`/${REFERRAL_BASE_URL}/${referralId}`} target='_blank'>{referralId}</a>
          </span>
        ))}
      </>
    )
  }

  const renderNotesCount = () => {
    const noteCount = goal.noteCount
    const noteAttributions = goal.noteTeamAttributions
    const attributionText = formatList({ elements: noteAttributions })

    if (!noteCount) {
      return (
        <Text tag='p' style='body-text'>
          <strong>{getString('list.item.note_count.none')}</strong>
        </Text>
      )
    } else {
      return (
        <Text tag='p' style='body-text'>
          <strong>{getString('list.item.note_count.any', { smart_count: noteCount })}</strong>{' '}
          {getString('list.item.note_attribution', { attribution: attributionText })}
        </Text>
      )
    }
  }

  return (
    <article className={combinedClassNames}>
      <Spacing paddingX={4} paddingY={3}>
        <header className='ClientGoals__ListItem__Header'>
          <Grid {...gridContainerProps}>
            <Grid {...gridMainColProps}>
              <Spacing paddingBottom={1}>
                <Text tag='div' style='body-text-small'>
                  {getString('list.item.updated_on', {
                    date: getGoalLastUpdatedDate(goal),
                    name: goalUserName
                  })}
                </Text>
              </Spacing>
              <Spacing paddingBottom={2}>
                <Text tag='h3' style='sub-header-medium'>
                  {goal.title}
                </Text>
              </Spacing>
              <Spacing paddingBottom={2}>
                <Text tag='p' style='body-text'>
                  <strong>{getString('list.item.related_needs')}:</strong>{' '}
                  {getGoalFilterCategoryNames(goal).join(', ')}
                </Text>
              </Spacing>
              <Spacing paddingBottom={2}>
                <Text tag='p' style='body-text'>
                  <strong>{getString('list.item.description')}:</strong>{' '}
                  {goal.description}
                </Text>
              </Spacing>
              <Spacing paddingBottom={2}>
                {renderNotesCount()}
              </Spacing>
            </Grid>
            <Grid {...gridAsideColProps}>
              <Spacing paddingBottom={2}>
                <ToggleGoalStatusForm goal={goal} />
              </Spacing>
            </Grid>
          </Grid>
        </header>

        <section className='ClientGoals__ListItem__Body'>
          <ExpansionPanel
            expandText={getString('list.item.expandable.open')}
            collapseText={getString('list.item.expandable.close')}
            onToggle={handleToggleExpanded}
          >
            <Spacing paddingY={2}>
              <hr />
              <Grid {...gridContainerProps}>
                <Grid {...gridMainColProps}>
                  <Spacing paddingBottom={1}>
                    <Text tag='div' style='body-text'>
                      {getString('list.item.updated_on', {
                        date: getGoalCreatedDate(goal),
                        name: goalUserName
                      })}
                    </Text>
                  </Spacing>
                  <Spacing paddingBottom={2}>
                    <Text tag='p' style='body-text'>
                      <strong>{getString('list.item.linked_referrals.label')}:</strong>{' '}
                      {renderLinkedReferrals()}
                    </Text>
                  </Spacing>
                  {goal.canEdit && <EditGoalForm goal={goal} />}
                </Grid>
              </Grid>
              <Spacing paddingY={2}><hr /></Spacing>
              <Grid {...gridContainerProps}>
                <Grid {...gridMainColProps}>
                  <ClientGoalNotes goal={goal} />
                </Grid>
              </Grid>
            </Spacing>
          </ExpansionPanel>
        </section>
      </Spacing>
    </article>
  )
}

ListItem.propTypes = {
  goal: PropTypes.object.isRequired
}

const List = ({ status, ...props }) => {
  const {
    getGoalsForStatus,
    getString
  } = useContext(ClientGoalsContext)
  const combinedClassNames = classNames('ClientGoals__List', status)
  const goals = getGoalsForStatus(status)
  const { opened: openedStatus } = GOAL_STATUSES
  const expanded = status === openedStatus

  return (
    <section className={combinedClassNames}>
      <ExpansionPanel
        expandText={getString(`list.status.${status}`, { count: goals.length })}
        collapseText={getString(`list.status.${status}`, { count: goals.length })}
        expanded={expanded}
        withHeader
      >
        {goals.map((goal) => <ListItem goal={goal} key={goal.id} />)}
        {!goals.length && (
          <article className='ClientGoals__ListItem ClientGoals__ListItem--empty'>
            <Spacing paddingX={4} paddingY={3}>
              <Text tag='p'>{getString(`empty.${status}`)}</Text>
            </Spacing>
          </article>
        )}
      </ExpansionPanel>
    </section>
  )
}

List.propTypes = {
  status: PropTypes.string.isRequired
}

List.defaultProps = {
  status: 'opened'
}

export default List
