import axios from 'axios'
import apiConfig from '@config/apiConfig'
import serializer from './../serializers/mergeRequest'

const mergeRequestApiUrl = '/api/internal/patient_merge/requests'
const mergeValidationApiUrl = '/api/internal/patient_merge/merge_validations'

const post = (props) => {
  const data = serializer.serialize(props)

  return axios(apiConfig.post(mergeRequestApiUrl, data))
}

const validate = (ids, handleErrors) => {
  return axios(apiConfig.post(mergeValidationApiUrl, { patient_ids: ids })).then(() => true)
    .catch((err) => {
      handleErrors(err.response.data.merge_validation.errors)
      return false
    })
}

export default {
  post,
  validate
}
