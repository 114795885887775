import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import filter from 'lodash/filter'
import isBoolean from 'lodash/isBoolean'

import useTitle from '@hooks/useTitle'
import { scrollToElement } from '@utils/scrollUtil'
import { denormalizeRecords, getRecord } from '@selectors'
import { setFormValue, toggleKey } from '@actions'
import { loadSearchOptions, loadSearchResults, submitSearchForm } from '@scenes/resourceSites/actions'
import { getQueryParams } from '@utils/browserUtil'
import { localeKeyGenerator } from '@utils/localeUtil'
import Grid from '@material-ui/core/Grid'

import resourceSitesSearchResultsPolicy from '@policies/resourceSitesSearchResultsPolicy'
import DownloadModal from '@scenes/resourceSites/components/DownloadModal'
import GuidedSearchForm from '@scenes/resourceSites/GuidedSearchForm'
import MobileSearchFilters from '@scenes/resourceSites/MobileSearchFilters'
import SearchFilters from '@components/SearchFilters'
import ResourceKindFilter from '@scenes/resourceSites/ResourceKindFilter'
import ResourceSiteCreateModal from '@scenes/resourceSites/ResourceSiteCreateModal'
import ResourceSiteEditModal from '@scenes/resourceSites/ResourceSiteEditModal'
import ResourceSiteShareModal from '@scenes/resourceSites/ResourceSiteShareModal'
import ResourceSitesMap from '@scenes/resourceSites/ResourceSitesMap'
import SearchResultCard from '@scenes/resourceSites/SearchResultCard'
import Alert from '@components/Alert'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import LoadingDataWrapper from '@components/LoadingDataWrapper'
import PaginationBar from '@components/PaginationBar'

import './ResourceSiteSearchResults.scss'

const ResourceSiteSearchResults = props => {
  const componentHasMounted = useRef(false)
  const localeKey = localeKeyGenerator(props.localeNamespace)
  const localeString = (key) => window.local.t(localeKey(key))
  const currentSearchParams = getQueryParams(props.location.search)
  const pageTitle = props.pageTitle || localeString('search_results_page_title')
  const scrollToElememntName = 'js-scrollToElememnt'
  const [searchLat, searchLng] = get(
    props.searchData,
    ['searchPosition', 'coordinates'],
    [null, null]
  )

  useTitle(pageTitle)
  useEffect(() => {
    const searchIsValid = currentSearchParams.client_id || props.geofilter.length

    if (!searchIsValid) {
      props.history.push(props.urlBase)
      return
    }

    props.loadSearchResults(props.location.search)

    // only run when first mounting
    if (!componentHasMounted.current) {
      if (props.featureFlags.integrated_search_results_flag === true) {
        props.setFormValue({
          namespace: 'resourceSites',
          form: 'search',
          key: 'resourceKind',
          value: 'integrated'
        })
      }
    }

    componentHasMounted.current = true
  }, [props.location.search])

  const transformFilterValue = (value) => {
    if (isBoolean(value)) {
      return value ? 'on' : 'off'
    }

    return value
  }

  const handleSearchFilterChange = (changedState) => {
    for (var key in changedState) {
      props.setFormValue({
        namespace: 'resourceSites',
        form: 'search',
        key: key,
        value: transformFilterValue(changedState[key])
      })
    }

    const newPage = 'page' in changedState

    return props.submitSearchForm({ history: props.history, newPage })
  }

  const handlePageChange = (newPage) => {
    scrollToElement(scrollToElememntName)
    handleSearchFilterChange({ page: newPage })
  }

  const renderSearchResultsLocation = (searchData) => {
    return (
      <Text style='body-text-emphasis' tag='span'>
        {searchData.searchLocationText}
      </Text>
    )
  }

  const itemCountText = (paginationData) => {
    if (paginationData.totalItemsCount === 0) {
      return localeString('results.search_results_summary.with_none')
    } else {
      return window.local.t(
        localeKey('results.search_results_summary.with_count'),
        { first: paginationData.firstItem,
          last: paginationData.lastItem,
          total: paginationData.totalItemsCount }
      )
    }
  }

  const renderResourceType = (searchData) => {
    if (searchData.resourceKind && searchData.resourceKind !== 'integrated') {
      const path = `resource_sites.results.search_results_summary.resource_kind.${searchData.resourceKind}`
      return (window.local.t(path))
    }
  }

  const renderLocationSummary = (paginationData) => {
    return (window.local.t(
      localeKey('results.search_results_summary.near'),
      { smart_count: paginationData.totalItemsCount })
    )
  }

  const renderItemCountText = (paginationData, searchData) => {
    if (paginationData && searchData) {
      const resultSummary = itemCountText(paginationData)
      const locationSummary = renderLocationSummary(paginationData, searchData)
      const resourceType = renderResourceType(searchData)
      const specificLocation = renderSearchResultsLocation(searchData)

      return (
        <Spacing marginY={3}>
          <Text>
            {resultSummary} {resourceType} {locationSummary} {specificLocation}
          </Text>
        </Spacing>
      )
    }
  }

  const renderPaginationBar = (paginationData) => {
    if (paginationData && paginationData.totalItemsCount) {
      return (
        <Spacing marginTop={4} marginBottom={4}>
          <PaginationBar
            activePage={paginationData.activePage}
            onChange={handlePageChange}
            totalItemsCount={paginationData.totalItemsCount}
          />
        </Spacing>
      )
    }
  }

  const renderErrors = () => {
    const title = localeString('results.search_failure.title')
    const email = localeString('results.search_failure.email')

    const body = <span>
      {localeString('results.search_failure.body')}
      <a href={`mailto:${email}`}>
        {email}
      </a>.
    </span>

    return <Grid container>
      <Grid item xs={12}>
        <Alert title={title} type='error' body={body} />
      </Grid>
    </Grid>
  }

  const renderResultsList = () => {
    if (!props.resourceSites.length) return renderNoResultsSection()

    return <div className='ResourceSiteSearchResults__results__list'>
      {props.downloadModalOpen && <DownloadModal />}
      {props.editModalOpen && <ResourceSiteEditModal />}
      {props.shareModalOpen && <ResourceSiteShareModal />}
      {props.resourceSites.map((resourceSite, i) => (
        <Spacing marginBottom={4} key={i}>
          <SearchResultCard
            analyticsReferrer={props.analyticsReferrer}
            client={props.client}
            currentUser={props.currentUser}
            featureFlags={props.featureFlags}
            languages={props.languages}
            resourceSite={resourceSite}
            settings={props.settings}
          />
        </Spacing>
      ))}
      {renderPaginationBar(props.paginationData)}
    </div>
  }

  const renderNoResultsSection = () => {
    const searchTips = ['regional', 'filters', 'address', 'radius', 'doctors'].map(key => (
      <li key={key}>
        <Text>{localeString(`results.no_results.tips.${key}`)}</Text>
      </li>
    ))

    return <section className='ResourceSiteSearchResults__results__no-results'>
      <Text style='sub-header-large'>
        {localeString('results.no_results.title')}
      </Text>

      <Spacing marginY={1}>
        <Text tag='p'>
          {localeString('results.no_results.body')}
        </Text>
      </Spacing>

      <Spacing paddingLeft={5}>
        <ul style={{ listStyleType: 'unset' }}>{searchTips}</ul>
      </Spacing>
    </section>
  }

  const renderSidebar = () => {
    const mappableResourceSites = filter(props.resourceSites, ({ address }) => (!!address))

    return (
      <aside
        className='ResourceSiteSearchResults__results__sidebar'
        data-testid='ResourceSiteSearchResults__sidebar'
      >
        {mappableResourceSites.length > 0 && <>
          <Spacing marginBottom={4} dataTest='ResourceSiteSearchResults__map'>
            <ResourceSitesMap
              centerPoint={{ lat: searchLat, lng: searchLng }}
              resourceSites={mappableResourceSites}
            />
          </Spacing>
        </>}

        {resourceSitesSearchResultsPolicy.canAddResource(props.currentUser) &&
          <div className='ResourceSiteSearchResults__results__sidebar__add-resource'>
            {props.createModalOpen && <ResourceSiteCreateModal />}

            <Spacing marginY={3}>
              {localeString('results.add_new_resource.prompt') + ' '}

              <button
                type='button'
                className='ResourceSiteCreateModal__trigger'
                onClick={() => props.toggleKey({
                  namespace: 'resourceSites',
                  key: 'createModalOpen',
                  value: true
                })}
              >
                {localeString('results.add_new_resource.cta')}
              </button>
            </Spacing>
          </div>
        }
      </aside>
    )
  }

  const renderFilters = () => {
    const { featureFlags } = props
    const filterProps = {
      contractedNetwork: props.settings.has_preferred_partners,
      isAuthenticated: props.isAuthenticated,
      teamUsesCoordinate: props.teamUsesCoordinate,
      accessibility: currentSearchParams.accessibility,
      coordinate_partners_only: currentSearchParams.coordinate_only,
      eligibilities: props.eligibilities,
      onFilterChange: handleSearchFilterChange,
      preferred_partners_only: currentSearchParams.preferred_partners_only,
      preferred_providers_only: currentSearchParams.preferred_providers_only,
      verified_only: currentSearchParams.verified_only,
      selected_eligibility_ids: currentSearchParams['resource_site[eligibility_ids]'],
      team_uses_coordinate: props.teamUsesCoordinate
    }

    return (
      <div
        className='ResourceSiteSearchResults__form__filters'
        name={scrollToElememntName}
      >
        <Spacing paddingY={3}>
          <div className='ResourceSiteSearchResults__Filters__Container' data-testid='ResourceSiteSearchResults__Filters__Container'>
            <MobileSearchFilters {...filterProps} />

            <SearchFilters
              className='ResourceSiteSearchResults__Filters--Desktop'
              {...filterProps}
            />
            <ResourceKindFilter
              onFilterChange={(change) => handleSearchFilterChange({ 'resourceKind': change })}
              value={currentSearchParams.resource_kind}
              featureFlags={featureFlags}
            />
          </div>
        </Spacing>
      </div>
    )
  }

  return (
    <div
      className='ResourceSiteSearchResults'>
      <Spacing marginBottom={5}>
        <section className='ResourceSiteSearchResults__form'>
          <div className='ResourceSiteSearchResults__form__search'>
            <Spacing paddingY={4}>
              <GuidedSearchForm />
            </Spacing>
          </div>
          {renderFilters()}
        </section>
      </Spacing>

      <LoadingDataWrapper loadingData={props.loadingSearchResults} display='block' size={80}>
        <section className='ResourceSiteSearchResults__results'>
          {renderItemCountText(props.paginationData, props.searchData)}
        </section>

        <section className='ResourceSiteSearchResults__results'>
          {!props.searchFailed && renderResultsList()}
          {!props.searchFailed && renderSidebar()}
          {props.searchFailed && renderErrors()}
        </section>
      </LoadingDataWrapper>
    </div>
  )
}

const mapDispatchToProps = {
  loadSearchOptions,
  loadSearchResults,
  setFormValue,
  submitSearchForm,
  toggleKey
}

const mapStateToProps = (state, ownProps) => {
  const searchData = getRecord(state, 'resource_site_search_results', 'resource_site_search_results')
  const resultIDs = get(searchData, 'orderedIds', [])
  const searchResults = resultIDs.map(id => getRecord(state, 'resource_site_search_result', id))
  const { client_id: clientId } = getQueryParams(ownProps.location.search)
  const client = getRecord(state, 'patient', clientId)

  const searchOptions = getRecord(state, 'search_options', null) || {}
  const eligibilities = sortBy(searchOptions.eligibilities, 'attributes.name')
  const currentUser = state.global.currentUser
  const isAuthenticated = currentUser.attributes.anonymous === false

  return {
    analyticsReferrer: state.global.analyticsReferrer,
    client,
    currentUser,
    createModalOpen: state.resourceSites.createModalOpen,
    downloadModalOpen: state.resourceSites.downloadModal.open,
    editModalOpen: state.resourceSites.editModal.editModalOpen,
    eligibilities,
    featureFlags: state.global.featureFlags,
    geofilter: state.resourceSites.search.geofilter.value,
    isAuthenticated,
    languages: state.global.languages,
    loadingSearchResults: state.resourceSites.loadingSearchResults,
    paginationData: state.resourceSites.paginationData,
    resourceSites: denormalizeRecords(state, searchResults),
    searchFailed: state.resourceSites.searchFailed,
    settings: state.global.settings,
    shareModalOpen: state.resourceSites.shareModal.shareModalOpen,
    teamUsesCoordinate: state.global.settings.uses_coordinate,
    searchData,
    resourceSiteState: get(state, 'resourceSites', {})
  }
}

/* eslint-disable react/no-unused-prop-types */
ResourceSiteSearchResults.propTypes = {
  createModalOpen: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  downloadModalOpen: PropTypes.bool.isRequired,
  editModalOpen: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  languages: PropTypes.object.isRequired,
  loadingSearchResults: PropTypes.bool.isRequired,
  loadSearchResults: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  searchFailed: PropTypes.bool.isRequired,
  setFormValue: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  shareModalOpen: PropTypes.bool.isRequired,
  submitSearchForm: PropTypes.func.isRequired,
  teamUsesCoordinate: PropTypes.bool.isRequired,
  toggleKey: PropTypes.func.isRequired,
  analyticsReferrer: PropTypes.object,
  client: PropTypes.object,
  eligibilities: PropTypes.array,
  geofilter: PropTypes.string,
  paginationData: PropTypes.object,
  resourceSites: PropTypes.array,
  resourceSiteState: PropTypes.object,
  searchData: PropTypes.object,
  pageTitle: PropTypes.string,
  urlBase: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  localeNamespace: PropTypes.string
}
/* eslint-enable react/no-unused-prop-types */

ResourceSiteSearchResults.defaultProps = {
  resourceSites: [],
  teamUsesCoordinate: false,
  urlBase: '/resource_sites/overview',
  localeNamespace: 'resource_sites'
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResourceSiteSearchResults))
