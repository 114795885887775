import React from 'react'
import PropTypes from 'prop-types'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import ExpandButton from './ExpandButton'

const ExpansionPanelAccordion = (props) => (
  <Accordion
    className='ExpansionPanel ExpansionPanel--accordian'
    disabled={props.isDisabled}
    onChange={props.toggleExpanded}
    defaultExpanded={props.defaultExpanded}
    elevation={2}
    expanded={props.expanded}
  >
    <AccordionSummary
      expandIcon={<ExpandButton {...props} />}
      IconButtonProps={{
        disableFocusRipple: true,
        disableRipple: true,
        classes: { root: 'ExpansionPanel__expand-icon' }
      }}
    >
      {props.title}
    </AccordionSummary>

    <AccordionDetails className='ExpansionPanel__content'>
      {props.children}
    </AccordionDetails>
  </Accordion>
)

ExpansionPanelAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,

  title: PropTypes.node
}

export default ExpansionPanelAccordion
