import React from 'react'
import PropTypes from 'prop-types'

import ActionButton from '@components/ActionButton'
import Icon from '@components/Icon'
import Triangle from '@components/Triangle'

class PageExpander extends React.Component {
  constructor (props) {
    super(props)

    this.state = { open: false }

    this.toggleOpenState = this.toggleOpenState.bind(this)
  }

  toggleOpenState () {
    this.setState({
      open: !this.state.open
    })
  }

  render () {
    return (
      <section>
        <ActionButton
          style={{ marginBottom: '36px', marginLeft: '27px', width: '150px' }}
          onClick={this.toggleOpenState}
          className='hfysg-button--no-background'
          text={this.state.open ? this.props.triggerHideText : this.props.triggerShowText}
        />

        {this.state.open
          ? <Icon className='mi--blue mi--align-middle' iconKey='keyboard_arrow_up' />
          : <Icon className='mi--blue mi--align-middle' iconKey='keyboard_arrow_down' />}

        <article style={this.styleDropDown()}>
          <Triangle />
          {this.props.children}
        </article>
      </section>
    )
  }

  styleDropDown () {
    const visibility = this.state.open ? 'block' : 'none'

    return {
      backgroundColor: '#FAFAFA',
      borderTop: '1px solid #DCDFE2',
      display: visibility,
      padding: '1.5rem 2.25rem',
      position: 'relative'
    }
  }
}

PageExpander.propTypes = {
  triggerHideText: PropTypes.string.isRequired,
  triggerShowText: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default PageExpander
