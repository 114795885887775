import React from 'react'
import PropTypes from 'prop-types'

const ActionButton = ({ text, onClick, className, style }) =>
  (<button style={style} className={className} onClick={onClick}>{text}</button>)

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
}

export default ActionButton
