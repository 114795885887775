import { scroller } from 'react-scroll'
import { HEADER_HEIGHT } from '../constants'

export function scrollToElement (containerId, { ...props }) {
  const _props = Object.assign({}, {
    duration: 300,
    delay: 0,
    smooth: 'easeInOutQuad',
    offset: -HEADER_HEIGHT
  }, props)

  scroller.scrollTo(containerId, _props)
}
