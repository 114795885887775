export const formatPhoneForDisplay = ({ descriptor, extension, number }) => {
  const label = descriptor.charAt(0).toUpperCase() + descriptor.substring(1)

  return extension ? `${label}: ${number} ext: ${extension}` : `${label}: ${number}`
}

export default (object) => ({
  ...object,
  displayValue: formatPhoneForDisplay(object)
})
