import { sortBy } from 'lodash'

export const sortGoals = goals => (goals ? sortBy(goals, ['updatedAt']) : []).reverse()

export const sortNotes = notes => (notes ? sortBy(notes, ['createdAt']) : [])

export const serializeGoal = ({
  companyId,
  description,
  filterCategoryIds,
  patientId,
  status,
  title,
  userId
}) => {
  const params = {
    patient_goal: {
      company_id: companyId,
      description,
      filter_category_ids: filterCategoryIds,
      status,
      title,
      user_id: userId
    },
    patient_id: patientId
  }

  return params
}

export const serializeNote = ({ content, goalId }) => {
  const params = {
    note: {
      content,
      notable_id: goalId,
      notable_type: 'Patient::Goal'
    }
  }

  return params
}
