import { createMuiTheme } from '@material-ui/core/styles'
import css from './variables'

export default createMuiTheme({
  palette: {
    primary: {
      main: css.blue.main
    }
  },
  overrides: {
    MuiDialog: {
      paper: {
        width: '510px',
        minHeight: '290px',
        padding: '36px'
      },
      paperScrollBody: {
        margin: '10vh auto'
      }
    },
    MuiButton: {
      sizeLarge: {
        fontSize: '0.75rem',
        borderRadius: css.borderRadius,
        lineHeight: '2.15',
        letterSpacing: '0.0625rem',
        transition: 'all 300ms ease',

        '&:hover': {
          backgroundColor: css.blue,
          boxShadow: `0 1px 4px 0 ${css.boxShadow}`,
          transform: 'scale(1.05)',
          color: 'white'
        }
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        background: 'none',
        borderRadius: css.borderRadius
      }
    },
    MuiFormLabel: {
      root: {
        color: '#999999'
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: css.lightGray
      },
      input: {
        '&:focus': {
          borderBottom: '0'
        }
      }
    },
    MuiAccordion: {
      root: {
        padding: '0.75rem',
        color: 'inherit',
        '&$disabled': {
          backgroundColor: 'inherit',
          cursor: 'not-allowed'
        },
        '&::before': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        '&$disabled': {
          opacity: 'inherit',
          color: css.gray.main
        }
      },
      expandIcon: {
        transform: 'none',
        transition: 'none',
        '&$expanded': {
          transform: 'none',
          transition: 'none'
        }
      }
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          display: 'none'
        }
      }
    },
    MuiPaper: {
      root: {
        color: 'inherit'
      },
      elevation2: {
        boxShadow: `0 1px 4px 0 ${css.boxShadow}`
      }
    }
  }
})
