import { sortBy, startCase } from 'lodash'
import { localeStringGenerator } from '@utils/localeUtil'
import useGetAssociation from '@hooks/useGetAssociation'

export const formatIdentifiers = (client, identifiers = []) => {
  const formattedIdentifiers = []

  if (client && client.externalId) {
    formattedIdentifiers.unshift({
      type: 'External',
      value: client.externalId
    })
  }

  identifiers.map(identifier => {
    formattedIdentifiers.unshift({
      type: startCase(identifier.identifierType),
      value: identifier.normalizedValue
    })
  })

  return formattedIdentifiers
}

export const formatAssignedUsers = (assignedUsers, team) => {
  const localeString = localeStringGenerator('patients')

  if (!assignedUsers.length) return localeString('assigned_users.none')

  const localeOptions = {
    assigned_users_string: assignedUsers.map((user) => user.fullName).join(', '),
    team_name: team.name
  }

  return localeString('assigned_users.many', localeOptions)
}

export const sortIdentifiers = (identifiers) => sortBy(identifiers, ['type'])

export const sortAssignedUsers = (assignedUsers) => sortBy(assignedUsers, ['last_name'])

export const getClientAssociations = (client) => {
  const assignedUsers = sortAssignedUsers(useGetAssociation(client, 'assignedUsers')) || []
  const phones = useGetAssociation(client, 'phones') || []

  return {
    address: useGetAssociation(client, 'address'),
    assignedUsers,
    assignedUserTeam: useGetAssociation(assignedUsers[0], 'team'),
    identifiers: useGetAssociation(client, 'identifiers', { modelName: 'patient_identifier' }),
    interventions: useGetAssociation(client, 'interventions'),
    phone: phones[0],
    populations: useGetAssociation(client, 'populations')
  }
}
