import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'

import ModalHeader from '@components/Modal/ModalHeader'
import './Modal.scss'

const Modal = (props) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      scroll='body'
      classes={{
        container: 'ModalContainer',
        paper: props.classes.paper
      }}
      data-behavior={props.dataBehavior}
      data-test={props.dataTest}
      data-testid={props.dataTest}
      disableEscapeKeyDown
    >
      <ModalHeader onClose={props.onClose}>
        {props.headerText}
      </ModalHeader>

      <form onSubmit={props.onSubmit}>
        <div className='Modal__content'>
          {props.content}
        </div>

        {props.actions}

        {props.footer && <div className='Modal__footer'>{props.footer}</div>}
      </form>
    </Dialog>
  )
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,

  headerText: PropTypes.string,
  onSubmit: PropTypes.func,
  classes: PropTypes.shape({
    paper: PropTypes.string
  }),
  dataBehavior: PropTypes.string,
  dataTest: PropTypes.string,
  footer: PropTypes.element
}

Modal.defaultProps = {
  onSubmit: () => {},
  classes: {
    paper: 'Modal'
  },
  dataBehavior: '',
  dataTest: ''
}

Modal.displayName = 'Modal'

export default Modal
