import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '@components/Button'
import Icon from '@components/Icon'
import Modal from '@components/Modal'
import Text from '@components/Text'

import { CoverageAreaManagerContext } from '../CoverageAreaManager'

const DeleteCoverageAreaButton = ({ coverageArea }) => {
  const [modalOpened, openModal] = useState(false)
  const toggleModal = useCallback((e) => {
    openModal(!modalOpened)
  })
  const {
    getString,
    submitters: { deleteCoverageArea }
  } = useContext(CoverageAreaManagerContext)
  const { deleteCoverageAreaRequest, loading } = deleteCoverageArea(coverageArea.coverageAreaId)

  const deleteButtonClicked = (e) => {
    e.preventDefault()

    deleteCoverageAreaRequest()
  }

  return (<>
    <Button type='tertiary' onClick={toggleModal}>
      {getString('form.delete.button_text')}
    </Button>

    <Modal
      actions={(
        <Button type='primary' disabled={loading} htmlType='submit'>
          <Text style='buttons' withIcon>
            <Icon iconKey='remove_circle' size={16} />{' '}
            {getString('form.delete.button_text')}
          </Text>
        </Button>
      )}
      content={<Text tag='p'>{getString('form.delete.modal_content')}</Text>}
      headerText={getString('form.delete.modal_header')}
      open={modalOpened}
      onClose={toggleModal}
      onSubmit={deleteButtonClicked}
    />
  </>)
}

DeleteCoverageAreaButton.propTypes = {
  coverageArea: PropTypes.object
}

export default DeleteCoverageAreaButton
