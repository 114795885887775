import { CITY, COUNTY, STATE, ZIPCODE } from "@constants/coverageArea"

export const outsideOfSearchRadius = (distanceAway, searchRadius) => {
  if (distanceAway > searchRadius) {
    return true
  }
}

export const determineLargestCoverageArea = (coverageAreaTypeList) => {
  let type = ""

  if (coverageAreaTypeList.length === 1) {
    type = coverageAreaTypeList[0]
  } else if (coverageAreaTypeList.length > 1) {
    type = coverageAreaType(coverageAreaTypeList)
  }

  return type
}

const coverageAreaType = (coverageAreaTypeList) => {
  // the types list needs to be arranged in this specific order
  // from largest to smallest coverage area
  const types = [STATE, COUNTY, CITY]

  for (const type of types) {
    if (coverageAreaTypeList.includes(type)) {
      return type;
    }
  }

  return ZIPCODE;
}
