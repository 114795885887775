import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { loadJsonApiObject } from '@utils/apiUtil'
import unauthenticatedService from '@services/unauthenticatedService'

import Button from '@components/Button'
import Text from '@components/Text'
import Icon from '@components/Icon'
import SelfReferralModal from './SelfReferralModal'

import './SelfReferral.scss'

// NOTE: Only necessary while this component renders in a rails view (resource_sites#show)
const parseObject = (object, transformData) => {
  if (!transformData) return object

  const { id, type } = object.data

  return loadJsonApiObject(object)[type][id]
}

const SelfReferral = (props) => {
  const { companyId, transformData } = props
  const resourceSite = parseObject(props.resourceSite, transformData)
  const [modalOpened, toggleModal] = useState(false)
  const [isSuccessful, setFormSuccess] = useState(false)
  const [errors, setErrors] = useState([])

  const toggleModalWithAnalytics = () => {
    const modalWillOpen = !modalOpened
    const modelName = 'ResourceSite'
    const eventType = modalWillOpen ? 'referral_modal_opened' : 'referral_modal_closed'
    const properties = { uuid: resourceSite.uuid }

    unauthenticatedService.analytics.create({ modelName, eventType, properties })

    toggleModal(modalWillOpen)

    if (modalWillOpen) {
      setErrors([])
      setFormSuccess(false)
    }
  }

  return (
    <div>
      <Button type='primary' onClick={toggleModalWithAnalytics}>
        <Text style='buttons' withIcon>
          Contact
          <Icon iconKey='send' size={12} />
        </Text>
      </Button>

      <SelfReferralModal
        resourceSite={resourceSite}
        companyId={companyId}
        closeModal={toggleModalWithAnalytics}
        open={modalOpened}
        isSuccessful={isSuccessful}
        setFormSuccess={setFormSuccess}
        errors={errors}
        setErrors={setErrors}
      />
    </div>
  )
}

SelfReferral.propTypes = {
  resourceSite: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  transformData: PropTypes.bool
}

SelfReferral.defaultProps = {
  transformData: false
}

export default SelfReferral
