import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { StoryContext } from './storyContext'

class WrappedChildren extends PureComponent {
  render () {
    // Wraps the children in a route based on the child's name
    // Also provides a context which Stories use to register
    // with the StyleguideEntryContainer
    return React.Children.map(this.props.children, (child, index) => {
      const title = child.type.displayName

      return <Route exact path={`/${title}`} key={title} component={(props) =>
        <Fragment>
          <h1 className='hfysg-h1'>{title}</h1>
          <StoryContext.Provider value={{
            addStory: this.props.addStory(title),
            removeStory: this.props.removeStory(title)
          }}>
            {child}
          </StoryContext.Provider>
        </Fragment>
      } />
    })
  }
}

WrappedChildren.propTypes = {
  children: PropTypes.node,
  addStory: PropTypes.func,
  removeStory: PropTypes.func
}

export default withRouter(WrappedChildren)
