import React from 'react'
import PropTypes from 'prop-types'
import Button from '@components/Button'
import { withRouter } from 'react-router'

// This component was taken from this SO post:
// https://stackoverflow.com/a/49439893
const LinkButton = (props) => {
  // These props come from the `withRouter` higher-ordered component
  // We pull them out here since they are not valid props to our <Button/> component
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    ...rest
  } = props

  return (
    <Button
      {...rest}
      role='link'
      onClick={(event) => {
        onClick(event)
        history.push(to)
      }}
    />
  )
}

LinkButton.defaultProps = {
  onClick: () => {},
  type: 'expansion'
}

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  type: PropTypes.string.isRequired,
  location: PropTypes.any,
  match: PropTypes.any,
  staticContext: PropTypes.any
}

export default withRouter(LinkButton)
