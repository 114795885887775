import axios from 'axios'
import snakeCase from 'lodash/snakeCase'
import apiConfig from '@config/apiConfig'

import { transformNestedObjectForRails } from '@utils/browserUtil'

const parseData = (data) => {
  const camelCaseData = convertObjectToCamelCase(data)

  const objectAttributes = Object.keys(camelCaseData.attributes).reduce((objectAttributes, attr) => {
    objectAttributes[attr] = camelCaseData.attributes[attr]
    return objectAttributes
  }, {})

  let relationships = {}

  if (camelCaseData.relationships) {
    relationships = Object.keys(camelCaseData.relationships).reduce((relationships, attr) => {
      relationships[attr] = camelCaseData.relationships[attr].data
      return relationships
    }, {})
  }

  return { ...objectAttributes, relationships, links: camelCaseData.links }
}

export const convertObjectToCamelCase = (object) => {
  if (typeof object !== 'object' || !object) {
    return object
  }

  if (Array.isArray(object)) {
    return object.map(element => convertObjectToCamelCase(element))
  }

  return Object.keys(object).reduce((newObject, key) => {
    newObject[snakeToCamelCase(key)] = convertObjectToCamelCase(object[key])

    return newObject
  }, {})
}

const snakeToCamelCase = (key) => {
  return key.replace(/([-_])([a-zA-Z\d])/ig, (_match, _separator, letter) => {
    return letter.toUpperCase()
  })
}

// The object we receive from JSON API can either be a single object or an array.
// This function turns a single object into an array of size 1, and a null object
// into an empty array. Then it loops over all of the objects in the array,
// getting their types and data, and creating an object where entities are stored
// by their `type` and `id`.
const loadJsonApiObjectOrArray = (arrayToAdd, existingObject) => {
  let array = arrayToAdd || []

  if (!Array.isArray(array)) {
    array = [arrayToAdd]
  }

  return array.reduce((objects, data) => {
    if (!objects[data.type]) {
      objects[data.type] = {}
    }
    objects[data.type][data.id] = parseData(data)

    return objects
  }, existingObject)
}

export const loadJsonApiObject = (object) => {
  const loadedObject = loadJsonApiObjectOrArray(object.data, {})

  return loadJsonApiObjectOrArray(object.included, loadedObject)
}

// Sets keys to snake_case, and values to strings
// In some cases, the value is an object `{value, label}`
export const normalizeFormParameters = (params) => {
  return Object.entries(params).reduce((acc, [field, data]) => {
    if (!data.value) return acc

    const fieldName = snakeCase(field)

    if (!acc[fieldName]) {
      acc[fieldName] = data.value.value || data.value
    }

    return acc
  }, {})
}

const getRequestParams = (options, convertKeys = false) => {
  const params = options.params || {}

  return transformNestedObjectForRails({
    included: options.included || [],
    extra_fields: options.extraFields || {},
    ...params
  }, convertKeys)
}

// @TODO: Add more actions as needed
export const getApiResource = (endpoint, dependencies = []) => ({
  create: (data, onFailure) => {
    return axios(apiConfig.post(endpoint, data))
      .then(response => response.data)
      .catch(err => {
        if (onFailure) onFailure(err)

        console.log(err)

        return Promise.reject(err)
      })
  },
  destroy: (id) => {
    return axios(apiConfig.destroy([endpoint, id].join('/')))
      .then(response => response.data)
      .catch(err => { console.log(err) })
  },
  show: (id, options = {}) => {
    const params = getRequestParams(options, true)

    return axios.get([endpoint, id].join('/'), { params })
      .then(response => response.data)
      .catch(err => { console.log(err) })
  },
  index: options => {
    const params = getRequestParams(options, true)

    return axios.get(endpoint, { params })
      .then(response => response.data)
      .catch(err => { console.log(err) })
  },
  update: (id, data = {}) => {
    return axios(apiConfig.put([endpoint, id].join('/'), data))
      .then(response => response.data)
      .catch(err => { console.log(err) })
  }
})

// @TODO: Add more actions as needed
export const getMockApiResource = (endpoint, mockedResponses) => ({
  create: (options) => {
    const requestParams = getRequestParams(options, true)
    return new Promise(resolve => resolve(mockedResponses.new(requestParams)))
  },
  index: (options) => {
    return new Promise(resolve => resolve(mockedResponses.index))
  },
  show: (id, options = {}) => {
    return new Promise(resolve => resolve(mockedResponses.show))
  },
  update: (options) => {
    const requestParams = getRequestParams(options, true)
    return new Promise(resolve => resolve(mockedResponses.new(requestParams)))
  }
})
