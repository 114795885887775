import React from 'react'
import PropTypes from 'prop-types'

class FieldContainer extends React.PureComponent {
  render () {
    return (
      <div className='ReviewView__attribute'>
        <h6 className='ReviewView__attribute__header'>
          {this.props.label}
        </h6>
        {this.props.children}
      </div>
    )
  }
}

FieldContainer.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
}

export default FieldContainer
