import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useFetchData from '@hooks/useFetchData'
import cartSharesService from '@services/cartSharesService'

const DEFAULT_INCLUDED = []

export default function useFetchCartShareData (cartShareUuid, included) {
  const fetchDataCartShareId = cartShareUuid || useParams().cartShareUuid
  const fetchDataOptions = {
    included: included || DEFAULT_INCLUDED
  }
  // load cart share data
  const { loading, data } = useFetchData(
    () => cartSharesService.unauthenticated.show(fetchDataCartShareId, fetchDataOptions)
  )

  return useMemo(() => ({ loading, id: data.id }), [loading, data.id])
}
