import React, { createContext } from 'react'
import PropTypes from 'prop-types'

// Pull in child components
import ContactInfo from './ContactInfo'

import useResourceSite from './useResourceSite'

export const ResourceSiteContext = createContext()

const { Provider } = ResourceSiteContext

const ResourceSite = ({
  children,
  resourceSite,
  ...props }) => {
  const value = useResourceSite(resourceSite)

  return (
    <Provider value={value}>
      <div className='ResourceSite' {...props}>
        {children}
      </div>
    </Provider>
  )
}

ResourceSite.propTypes = {
  children: PropTypes.node.isRequired,
  resourceSite: PropTypes.object
}

ResourceSite.ContactInfo = ContactInfo

export default ResourceSite
