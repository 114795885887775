import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { serviceFrequencies } from '../constants'
import { isInNetworkPreferred } from '@utils/networkTiersUtil'

import Grid from '@material-ui/core/Grid'
import Text from '@components/Text'
import Spacing from '@components/Spacing'
import RadioButton from '@components/RadioButton'
import ServiceUnitSubform from './ServiceUnitSubform'
import FieldErrors from '@components/Form/FieldErrors'
import Collapse from '@material-ui/core/Collapse'
import ModalContentSeparator from '@components/Modal/ContentSeparator'

import './ReferralEncounterModal.scss'

class ClientEnrolledForm extends PureComponent {
  renderSubformFor (programAssignment) {
    const {
      expandedSections,
      programAssignments,
      renderSectionNameAndToggle,
      renderRemovalButtonFor,
      onServiceDetailsFieldChange
    } = this.props
    const required = isInNetworkPreferred(programAssignment.tier)
    const expanded = programAssignments.length === 1 ||
      expandedSections.includes(programAssignment.id)

    return (
      <div key={programAssignment.id}>
        <header className='ReferralEncounterModal__service-detail-header'>
          {renderSectionNameAndToggle(programAssignment)}
          {renderRemovalButtonFor(programAssignment.id)}
        </header>

        <Collapse in={expanded}>
          <Spacing marginTop={3}>
            <ServiceUnitSubform
              onServiceDetailsFieldChange={onServiceDetailsFieldChange}
              programAssignmentId={programAssignment.id}
              required={required}
              serviceUnitLabelKey='enrolled'
            />

            {this.renderServiceFrequencyField(programAssignment.id, required)}
          </Spacing>
        </Collapse>

        <ModalContentSeparator />
      </div>
    )
  }

  renderServiceFrequencyLabelAndHint (required) {
    return (
      <Spacing tag='div' marginBottom={2}>
        <Text style='form-fields'>
          {window.local.t('referrals.encounter_modal.service_frequency.label')}

          {required && <Spacing tag='span' paddingLeft={0.5}>
            <Text style='error-message' display='inline'>*</Text>
          </Spacing>}
        </Text>

        <Spacing tag='div' marginTop={1}>
          <Text style='form-fields-hint' tag='div'>
            {window.local.t('referrals.encounter_modal.service_frequency.hint')}
          </Text>
        </Spacing>
      </Spacing>
    )
  }

  renderServiceFrequencyField (programAssignmentId, required) {
    const [onetimeField, ongoingField] = this.serviceFrequencyRadioButtons(programAssignmentId)
    const errors = []
    const invalid = !!get(
      this.props.formSelections[programAssignmentId],
      'serviceFrequency.errors',
      []
    ).length

    // Show custom missing radio selection text if service_frequency is invalid
    if (invalid) {
      errors.push(window.local.t('referrals.encounter_modal.service_frequency.invalid'))
    }

    return (
      <div data-test='referral-encounter-modal__service-frequency'>
        {this.renderServiceFrequencyLabelAndHint(required)}

        <Grid container direction='row' spacing={4}>
          <Grid item xs={5}>{onetimeField}</Grid>
          <Grid item xs={5}>{ongoingField}</Grid>
        </Grid>

        <FieldErrors errors={errors} showErrorMessages />
      </div>
    )
  }

  serviceFrequencyRadioButtons (programAssignmentId) {
    const { serviceFrequency } = this.props.formSelections[programAssignmentId]

    return serviceFrequencies.map(status => (
      <RadioButton
        name={`programAssignment-${programAssignmentId}-service-frequency`}
        label={window.local.t(`referrals.encounter_modal.service_frequency.${status}`)}
        onChange={this.props.onServiceDetailsFieldChange(
          programAssignmentId,
          'serviceFrequency'
        )}
        value={status}
        checked={serviceFrequency && (serviceFrequency.value === status)}
      />
    ))
  }

  render () {
    const subforms = this.props.programAssignments
      .map(programAssignment => this.renderSubformFor(programAssignment))

    return <Fragment>{subforms}</Fragment>
  }
}

ClientEnrolledForm.propTypes = {
  onServiceDetailsFieldChange: PropTypes.func.isRequired,
  renderRemovalButtonFor: PropTypes.func.isRequired,
  renderSectionNameAndToggle: PropTypes.func.isRequired,

  expandedSections: PropTypes.array,
  formSelections: PropTypes.object,
  programAssignments: PropTypes.array
}

const mapDispatchToProps = {}

const mapStateToProps = (state, props) => {
  const { referralEncounter, programAssignmentServiceDetails } = state.referralView.form
  const { expandedReferralEncounterModalSections } = state.referralView

  return {
    expandedSections: expandedReferralEncounterModalSections,
    formSelections: programAssignmentServiceDetails,
    programAssignments: referralEncounter.referralResourceProgramAssignments.value
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientEnrolledForm)
