import React from 'react'
import PropTypes from 'prop-types'

import './GuidedSearchFilter.scss'

class GuidedSearchFilter extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      collapsed: true
    }

    this.wrappingDiv = React.createRef()

    this.cancel = this.cancel.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.onCollapseClick = this.onCollapseClick.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  onCollapseClick (e) {
    e.preventDefault()

    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  cancel (e) {
    if (e) e.preventDefault()

    this.setState({ collapsed: true })

    this.props.onCancel()
  }

  componentDidMount () {
    document.body.addEventListener('click', this.handleOutsideClick)
  }

  removeEventListener () {
    document.body.removeEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount () {
    this.removeEventListener()
  }

  handleOutsideClick (e) {
    if (this.wrappingDiv.current.contains(e.target)) return
    if (this.state.collapsed) return

    this.setState({ collapsed: true })

    this.cancel()
  }

  get collapseIcon () {
    if (this.state.collapsed) {
      return 'keyboard_arrow_down'
    } else {
      return 'keyboard_arrow_up'
    }
  }

  get filterClasses () {
    let filterClasses = ['guided-search__filter']
    if (!this.state.collapsed) {
      filterClasses.push('guided-search__filter--revealed')
    }

    if (this.props.applied) {
      filterClasses.push('guided-search__filter--applied')
    }

    return filterClasses.join(' ')
  }

  get filterCountBadge () {
    const { filtersCount } = this.props

    if (filtersCount > 0) {
      return (
        <span className='guided-search__filter-badge'>{ filtersCount }</span>
      )
    }
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ collapsed: true })

    this.props.onSubmit()
  }

  handleClear (e) {
    e.preventDefault()

    this.props.onClear()
  }

  renderCancelButton () {
    return (
      <button
        className='guided-search__cancel GuidedSearchFilter__Cancel-Button'
        href='#'
        onClick={this.cancel}
      >
        Cancel
      </button>
    )
  }

  renderClearButton () {
    const clearable = this.props.pristine && this.props.filtersCount > 0

    return (
      <button
        className='guided-search__cancel GuidedSearchFilter__Clear-Button'
        disabled={!clearable}
        href='#'
        onClick={this.handleClear}
      >
        {window.local.t('resource_sites.guided_search_filter.clear')}
      </button>
    )
  }

  renderFooter () {
    return (
      <div className='guided-search__filter__footer'>
        {this.props.clearable ? this.renderClearButton() : this.renderCancelButton()}

        <input
          className='guided-search__filter-submit'
          data-test='apply-guided-search-filter'
          disabled={this.props.pristine}
          type='submit'
          value={window.local.t('resource_sites.guided_search_filter.apply')}
          onClick={this.handleSubmit}
        />
      </div>
    )
  }

  render () {
    const { name, children } = this.props
    const filterOptionStyles = {
      display: this.state.collapsed ? 'none' : 'block'
    }

    const childrenWithOnClose = React.cloneElement(children, { onClose: this.cancel })

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <div className={this.filterClasses} ref={this.wrappingDiv} data-testid={`GuidedSearchFilter-${name}`}>
        <a
          className='guided-search__filter-name guided-search__filter_collapse'
          data-test-search-filter={name}
          href='#'
          onClick={this.onCollapseClick}
        >
          {name}
          {this.filterCountBadge}
          <i className='material-icons mi--16 notranslate'>{this.collapseIcon}</i>
        </a>

        <div className='guided-search__filter-options' style={filterOptionStyles}>
          {childrenWithOnClose}

          {this.renderFooter()}
        </div>
      </div>
    )
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

GuidedSearchFilter.propTypes = {
  applied: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  clearable: PropTypes.bool,
  filtersCount: PropTypes.number,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func
}

GuidedSearchFilter.defaultProps = {
  applied: false,
  clearable: false,
  onClear: () => null
}

export default GuidedSearchFilter
