import { useMemo } from 'react'

import { formatDate } from '@utils/formatterUtil'
import { sortNeeds, serializeNeed } from './clientNeedsUtil'
import useGetCollection from '@hooks/useGetCollection'
import useUpdateRecord from '@hooks/useUpdateRecord'
import clientsService from '@services/clientsService'
import { NEED_STATUSES } from './constants'
import { localeStringGenerator } from '@utils/localeUtil'
import { getClientNeedsResource } from '@services/clientNeedsService'

const getSortedNeeds = (clientId) => {
  const needs = useGetCollection('patient_need', clientsService.type, clientId)

  return sortNeeds(needs)
}

const getLastUpdatedDate = (need) => {
  if (!need) return ''

  return formatDate(need.updatedAt)
}

const getResourceProgramTitles = (need) => {
  if (!need.resourceProgramTitles || !need.resourceProgramTitles.length) return

  return need.resourceProgramTitles.join(', ')
}

const needsForStatus = (clientId) => (status) => (
  getSortedNeeds(clientId).filter((need) => (
    need.status === NEED_STATUSES[status]
  ))
)

const getUpdateNeed = (clientId) => (useLoading) => {
  const { loading, updateRecord } = useUpdateRecord({ useLoading })
  const { update } = getClientNeedsResource(clientId)

  const updateNeed = (needId, formData) => updateRecord(
    () => update(needId, serializeNeed(formData)),
    useLoading
  )

  return useMemo(() => ({ loading, updateNeed }))
}

const createPropGetters = (client) => {
  const getString = localeStringGenerator('patients.needs')

  return {
    getClient: () => client,
    getNeedsForStatus: needsForStatus(client.id),
    getLastUpdatedDate,
    getResourceProgramTitles,
    getString,
    getUpdateNeed: getUpdateNeed(client.id)
  }
}

export default createPropGetters
