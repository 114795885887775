import React, { useContext } from 'react'

import Text from '@components/Text'

import { ClientNeedsContext } from './ClientNeeds'

const Header = props => {
  const {
    getClient,
    getString
  } = useContext(ClientNeedsContext)

  return (
    <header className='ClientNeeds__Header'>
      <Text tag='h3' style='sub-header-medium'>
        {getString('users_needs', { clientName: getClient().firstName })}
      </Text>
    </header>
  )
}

export default Header
