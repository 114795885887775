import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'

import { getAbsolutePath } from '@utils/browserUtil'
import useTitle from '@hooks/useTitle'
import GuidedSearchForm from '@scenes/resourceSites/GuidedSearchForm'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import './PublicSearchOverview.scss'

const PublicSearchOverview = ({ location, currentCompany }) => {
  const formTarget = getAbsolutePath(location, 'search')
  useTitle(window.local.t('resource_sites.search_page_title'))

  return (
    <div className='SearchOverview' data-testid='SearchOverview'>
      <section className='SearchOverview__form SearchOverview__form--public'>
        <header className='SearchOverview__form__header'>
          <Text style='page-header' tag='h1' brandingEmphasis>
            {window.local.t('resource_sites.search_overview.unauthenticated_title')}
          </Text>
        </header>

        <GuidedSearchForm target={formTarget} />
        <GuestScreeningCallToAction company={currentCompany} />
      </section>
    </div>
  )
}

PublicSearchOverview.propTypes = {
  currentCompany: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const GuestScreeningCallToAction = ({ company }) => {
  if (!company.links.primary_guest_screening) return null

  return (
    <Spacing marginTop={10}>
      <Grid container justify='center' alignItems='center'>
        <Text style='sub-header-medium'>
          Don't know what you're looking for?
        </Text>
        <Spacing marginLeft={2} tag='span'>
          <a className='Button--primaryDark' href={company.links.primary_guest_screening} >
            <Spacing paddingX={2}>
              Take a screening
            </Spacing>
          </a>
        </Spacing>
      </Grid>
    </Spacing>
  )
}

GuestScreeningCallToAction.propTypes = {
  company: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  currentCompany: state.global.currentCompany
})

export default connect(mapStateToProps)(PublicSearchOverview)
