import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MuiTable from '@material-ui/core/Table'
import MuiTableBody from '@material-ui/core/TableBody'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'

import cssVariables from '@config/theme/variables'
import './SimpleTable.scss'

const Table = withStyles(theme => ({
  root: {
    borderSpacing: '0 8px',
    borderCollapse: 'separate'
  }
}))(MuiTable)

const TableHeadCell = withStyles(theme => ({
  head: {
    color: cssVariables.gray.main,
    borderBottom: 'none'
  }
}))(MuiTableCell)

const TableRow = withStyles(theme => ({
  root: {
    backgroundColor: cssVariables.blue.light,
    marginTop: '10px',
    '&:hover': {
      backgroundColor: cssVariables.blue.light
    }
  }
}))(MuiTableRow)

const TableRowCell = withStyles(theme => ({
  root: {
    borderBottom: 'none',
    padding: '16px 24px'
  },
  body: {
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit'
  }
}))(MuiTableCell)

class SimpleTable extends Component {
  getHeadCellProps (column) {
    const props = {
      align: column.align,
      key: column.header
    }

    if (column.width) {
      props.width = `${column.width}%`
    }

    return props
  }

  renderHead () {
    return (
      <MuiTableHead>
        <MuiTableRow>
          {this.props.columns.map(column => (
            <TableHeadCell {...this.getHeadCellProps(column)}>
              {column.header}
            </TableHeadCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
    )
  }

  renderBody () {
    const { rowObjects, columns } = this.props

    return (
      <MuiTableBody>
        {rowObjects.map(row => (
          <TableRow key={row.id} className='SimpleTable__row'>
            {columns.map(column => (
              <TableRowCell key={column.header} className={column.className}>
                {column.value(row)}
              </TableRowCell>
            ))}
          </TableRow>
        ))}
      </MuiTableBody>
    )
  }

  render () {
    return (
      <Table className='SimpleTable'>
        {this.renderHead()}
        {this.renderBody()}
      </Table>
    )
  }
}

SimpleTable.propTypes = {
  rowObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.oneOf(['left', 'right']),
    value: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    width: PropTypes.number
  })).isRequired
}

export default withStyles({})(SimpleTable)
