import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Modal from '@components/Modal'
import ModalContentSeparator from '@components/Modal/ContentSeparator'
import Button from '@components/Button'
import Select from '@components/Select'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import ExpandableTextArea from '@components/ExpandableTextArea'
import SuccessModal from '@components/SuccessModal'
import EndUserAttestation from '@scenes/resourceSites/EndUserAttestation'

import { getRecord } from '@selectors'
import resourceSiteShareService from '@services/resourceSiteShareService'
import { closeShareModal, loadShareableUsers } from '@scenes/resourceSites/actions'

import { isEmail } from '@utils/stringUtil'

import './ResourceSiteShareModal.scss'

const localeFor = (i18nPath, options = {}) => window.local.t(
  `resource_sites.share_modal.${i18nPath}`,
  options
)

class ResourceSiteShareModal extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      consentGathered: false,
      selectedEmails: [],
      selectedUsers: [],
      isProcessing: false,
      message: '',
      successOpen: false
    }

    this.onConsentToggle = this.onConsentToggle.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.handleShareSubmit = this.handleShareSubmit.bind(this)
  }

  componentDidMount (newProps) {
    if (!this.props.shareableUsers.length) {
      this.props.loadShareableUsers()
    }
  }

  renderSubheader () {
    return (
      <Spacing marginTop={3}>
        <Spacing marginBottom={0.5}>
          <Text style='sub-header-extra-small'>
            {localeFor('subheader')}
          </Text>
        </Spacing>

        <Text>{this.props.resourceSite.serviceName}</Text>
      </Spacing>
    )
  }

  onSelectChange (selections) {
    const emails = selections.filter(selection => selection['__isNew__'])
    const users = selections.filter(selection => !selection['__isNew__'])

    this.setState({
      selectedEmails: emails,
      selectedUsers: users
    })
  }

  renderUserSelect () {
    const sharingKey = this.props.strangerSharingEnabled ? 'enabled' : 'disabled'
    const noOptionsText = localeFor(`user_select.no_options.sharing_${sharingKey}`)
    const placeholderText = localeFor(`user_select.placeholder.sharing_${sharingKey}`)
    const formatLabel = (inputValue) => {
      return localeFor('user_select.add_email', { email: inputValue })
    }

    return <Select
      creatable={this.props.strangerSharingEnabled}
      formatCreateLabel={formatLabel}
      isValidNewOption={(inputValue, selectValue, selectOptions) => isEmail(inputValue)}
      label={localeFor('user_select.label')}
      isMulti
      noOptionsMessage={() => noOptionsText}
      placeholder={placeholderText}
      options={this.props.shareableUsers}
      onChange={this.onSelectChange}
    />
  }

  onTextChange (text) {
    this.setState({ message: text })
  }

  renderMessageInput () {
    return <ExpandableTextArea
      label={localeFor('message_input.label')}
      placeholder={localeFor('message_input.placeholder')}
      onChange={this.onTextChange}
    />
  }

  onConsentToggle () {
    this.setState({ consentGathered: !this.state.consentGathered })
  }

  renderRecipientConsent () {
    if (this.state.selectedEmails.length) {
      const emailList = <Spacing marginBottom={2}>
        {this.state.selectedEmails.map(email => {
          return <li className='ResourceSiteShareModal__client-consent__email-list-item' key={email.value}>
            {email.value}
          </li>
        })}
      </Spacing>

      return (
        <ModalContentSeparator>
          <EndUserAttestation
            checkboxLabel={localeFor('client_consent.checkbox')}
            children={emailList}
            description={localeFor('client_consent.description')}
            onAttestation={this.onConsentToggle}
            formName='resourceSiteShare'
          />
        </ModalContentSeparator>
      )
    }
  }

  renderModalContent () {
    return (
      <Fragment>
        {this.renderSubheader()}
        <ModalContentSeparator />
        {this.renderUserSelect()}
        {this.renderMessageInput()}
        {this.renderRecipientConsent()}
      </Fragment>
    )
  }

  recipients () {
    return this.state.selectedUsers.concat(this.state.selectedEmails)
  }

  submitDisabled () {
    const strangerSharingDisabled = this.state.selectedEmails.length && !this.state.consentGathered

    return !this.recipients().length || strangerSharingDisabled || this.state.isProcessing
  }

  renderModalActions () {
    return (
      <div className='Modal__submit--single-button'>
        <Button type='primary' onClick={this.handleShareSubmit} disabled={this.submitDisabled()}>
          {localeFor('submit')}
        </Button>
      </div>
    )
  }

  shareSubmitData () {
    const data = {
      resource_site_id: this.props.resourceSite.id,
      share: {
        recipient_user_ids: this.recipients().map(recipient => recipient.value),
        message: this.state.message,
        referrer_id: this.props.analyticsReferrer.analytics_referrer_id
      }
    }

    if (this.state.consentGathered) {
      data.share.consent_gathered = true
    }

    return data
  }

  handleShareSubmit (event) {
    event.preventDefault()

    this.setState({ isProcessing: true })

    resourceSiteShareService.share(
      this.shareSubmitData(),
      this.props.resourceSite.id
    ).then(() => {
      this.setState({ successOpen: true })
      setTimeout(this.props.closeShareModal, 2000)
    })
  }

  renderSuccessModal () {
    const modalText = localeFor('success.text', { smart_count: this.recipients().length })

    return <SuccessModal
      title={localeFor('success.title')}
      text={modalText}
      open={this.state.successOpen}
    />
  }

  render () {
    if (this.state.successOpen) {
      return this.renderSuccessModal()
    }

    return <Modal
      dataTest='ResourceSiteShareModal'
      headerText={localeFor('title')}
      content={this.renderModalContent()}
      actions={this.renderModalActions()}
      onClose={this.props.closeShareModal}
      open={this.props.shareModalOpen}
      {...this.props}
    />
  }
}

ResourceSiteShareModal.propTypes = {
  analyticsReferrer: PropTypes.object.isRequired,
  closeShareModal: PropTypes.func.isRequired,
  loadShareableUsers: PropTypes.func.isRequired,
  resourceSite: PropTypes.shape({
    id: PropTypes.number.isRequired,
    serviceName: PropTypes.string.isRequired
  }),
  shareableUsers: PropTypes.array,
  shareModalOpen: PropTypes.bool.isRequired,
  strangerSharingEnabled: PropTypes.bool.isRequired
}

const mapDispatchToProps = {
  closeShareModal,
  loadShareableUsers
}

const mapStateToProps = (state, props) => {
  const { resourceSiteId, shareableUsers, shareModalOpen } = state.resourceSites.shareModal
  const resourceSite = getRecord(state, 'resource_site_search_result', resourceSiteId)
  const strangerSharingEnabled = state.global.settings.stranger_sharing_enabled

  const shareableUsersDecorated = shareableUsers.map(user => {
    user.label = user.text
    return user
  })

  return {
    ...state.resourceSites.shareModal,
    analyticsReferrer: state.global.analyticsReferrer,
    resourceSite,
    shareableUsers: shareableUsersDecorated,
    shareModalOpen,
    strangerSharingEnabled
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSiteShareModal)
