import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './DashedModule.css'

class DashedModule extends Component {
  render () {
    const { children, color } = this.props
    const modifierClass = color ? `DashedModule--${color}` : ''

    return (
      <section className={`DashedModule ${modifierClass}`}>
        {children}
      </section>
    )
  }
}

DashedModule.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.oneOf([
    'blue'
  ])
}

export default DashedModule
