import { useCallback, useMemo } from 'react'

import useGetAssociation from '@hooks/useGetAssociation'
import { localeStringGenerator } from '@utils/localeUtil'
import { formatDate } from '@utils/formatterUtil'

const getFormatters = (cartShare) => {
  const { getUser } = getAssociations(cartShare)

  const createdByName = useCallback(() => {
    const user = getUser()

    if (!user.fullName) {
      throw new Error(
        `The user.full_name field was not returned by the serializer`
      )
    }

    return user.fullName
  })

  const createdOnDate = useCallback(() => {
    if (!cartShare.createdAt) {
      throw new Error(
        `The created_at field was not returned by the serializer`
      )
    }

    return formatDate(cartShare.createdAt)
  })

  const value = useMemo(() => ({
    createdByName,
    createdOnDate
  }), [cartShare])

  return value
}

const getAssociations = (cartShare) => {
  const getReferrals = useCallback(() => {
    return useGetAssociation(cartShare, 'referrals')
  })

  const getUser = useCallback(() => {
    return useGetAssociation(cartShare, 'user')
  })

  const value = useMemo(() => ({
    getReferrals,
    getUser
  }), [cartShare])

  return value
}

const getPropCollections = (cartShare) => {
  const getString = localeStringGenerator('cart_share')

  return {
    associations: getAssociations(cartShare),
    formatters: getFormatters(cartShare),
    getString
  }
}

export default getPropCollections
