import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { timelineEventContentClassNames } from '../utils/referralTimelineUtil'
import Text from '@components/Text'

export default class CreatedEvent extends React.PureComponent {
  renderCloseLoopContext () {
    const { closedLoop, serviceName } = this.props

    if (!closedLoop) return null

    return (
      <Text tag='span'>
        {window.local.t('referrals.timeline.events.created.closed_loop_context.start')}
        <Text style='body-text-emphasis'>{serviceName}</Text>
        {window.local.t('referrals.timeline.events.created.closed_loop_context.end')}
      </Text>
    )
  }

  selfReferralTitle () {
    return (
      <Text tag='span'>
        {window.local.t('referrals.timeline.events.created.self_referral_context.title.start')}
        <Text style='body-text-emphasis' tag='span'>{this.props.userText}</Text>
        {window.local.t('referrals.timeline.events.created.self_referral_context.title.end')}
      </Text>
    )
  }

  renderTitle () {
    const title = this.props.selfReferral
      ? this.selfReferralTitle()
      : <Text tag='span'>
        {window.local.t(
          'referrals.timeline.events.created.title',
          { user: this.props.userText }
        )}
      </Text>

    return <div className='ReferralTimeline__event__title'>
      {title}
      {this.renderCloseLoopContext()}
    </div>
  }

  selfReferralContent () {
    return (
      <Text tag='p'>
        <Text tag='span' style='body-text-emphasis'>
          {window.local.t('referrals.timeline.events.created.self_referral_context.content.start')}
        </Text>
        {window.local.t('referrals.timeline.events.created.self_referral_context.content.end')}
      </Text>
    )
  }

  renderContent () {
    const className = timelineEventContentClassNames({
      eventType: this.props.type,
      isLastElement: this.props.isLastElement
    })

    return (
      <div className={className}>
        {this.props.selfReferral && this.selfReferralContent()}
      </div>
    )
  }

  render () {
    return <Fragment>
      {this.renderTitle()}
      {this.renderContent()}
    </Fragment>
  }
}

CreatedEvent.propTypes = {
  closedLoop: PropTypes.bool.isRequired,
  isLastElement: PropTypes.bool.isRequired,
  serviceName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userText: PropTypes.string.isRequired,
  selfReferral: PropTypes.bool
}
