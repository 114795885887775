import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'

import { formatDate } from '@utils/formatterUtil'
import { sortGoals, sortNotes, serializeGoal, serializeNote } from './clientGoalsUtil'
import { GOAL_STATUSES } from './constants'
import { localeStringGenerator } from '@utils/localeUtil'
import clientsService from '@services/clientsService'
import useGetRecord from '@hooks/useGetRecord'
import useGetAssociation from '@hooks/useGetAssociation'
import useCreateRecord from '@hooks/useCreateRecord'
import useDestroyRecord from '@hooks/useDestroyRecord'
import useGetCollection from '@hooks/useGetCollection'
import useUpdateRecord from '@hooks/useUpdateRecord'
import clientGoalsService, { getClientGoalResource } from '@services/clientGoalsService'
import notesService from '@services/notesService'
import { getNewRecords } from '@selectors'
import { updateEntity } from '@actions'

const getGoalLastUpdatedDate = (goal) => {
  if (!goal) return ''

  return formatDate(goal.updatedAt)
}

const getGoalCreatedDate = (goal) => {
  if (!goal) return ''

  return formatDate(goal.createdAt)
}

const getGoalFilterCategoryIds = (goal) => (
  useMemo(() => Object.values(goal.relationships.filterCategories).map((relatedFilterCategory) => parseInt(relatedFilterCategory.id)
  ), [goal.relationships.filterCategories])
)

const getGoalFilterCategories = (goal) => {
  const filterCategories = getFilterCategories()
  const goalFilterCategories = getGoalFilterCategoryIds(goal)

  return filter(filterCategories, (filterCategory) => (
    goalFilterCategories.includes(filterCategory.id)
  ))
}

const getGoalNotes = (goal) => {
  const notes = useGetCollection('note', 'notable', goal.id)

  return sortNotes(notes)
}

// These populate as "Related Needs" in the UI
const getGoalFilterCategoryNames = (goal) => {
  if (!goal) return []

  return getGoalFilterCategories(goal).map(filterCategory => filterCategory.name)
}

const getSortedGoals = (clientId) => {
  const goals = useGetCollection(clientGoalsService.type, clientsService.type, clientId)

  return sortGoals(goals)
}

const getGoalsForStatus = (clientId) => (status) => (
  getSortedGoals(clientId).filter((goal) => (
    goal.status === GOAL_STATUSES[status]
  ))
)

const getMostRecentGoal = (clientId) => () => getSortedGoals(clientId)[0]

const getNewNotes = () => {
  const newNotes = useSelector(state => getNewRecords(state, notesService.type))

  return newNotes
}

const getGoalAssociations = (goal) => ({
  user: useGetAssociation(goal, 'user')
})

const getAddNote = (goalId) => {
  const { loading, createRecord } = useCreateRecord()

  const addNote = (formData) => createRecord(
    () => notesService.create(serializeNote(formData))
  )

  return useMemo(() => ({ loading, addNote }))
}

// run this method after submitting a note to update note count and
// team attribution
const getUpdateGoalEntity = (goal) => {
  const dispatch = useDispatch()

  return (newAttributes) => {
    const updatedObject = { ...goal, ...newAttributes }
    const updater = {
      type: clientGoalsService.type,
      id: goal.id,
      updatedObject
    }

    dispatch(updateEntity(updater))
  }
}

const getDeleteNote = (noteId) => {
  const { loading, destroyRecord } = useDestroyRecord(noteId, notesService.type)

  const deleteNote = () => destroyRecord(
    () => notesService.destroy(noteId)
  )

  return useMemo(() => ({ loading, deleteNote }))
}

const getCreateGoal = (clientId) => () => {
  const { loading, createRecord } = useCreateRecord()
  const { create } = getClientGoalResource(clientId)

  const createGoal = (formData) => createRecord(
    () => create(serializeGoal(formData))
  )

  return useMemo(() => ({ loading, createGoal }))
}

const getUpdateGoal = (clientId) => (useLoading) => {
  const { loading, updateRecord } = useUpdateRecord({ useLoading })
  const { update } = getClientGoalResource(clientId)

  const updateGoal = (goalId, formData) => updateRecord(
    () => update(goalId, serializeGoal(formData)),
    useLoading
  )

  return useMemo(() => ({ loading, updateGoal }))
}

const getFilterCategories = () => {
  const { filterCategories } = useGetRecord('search_options', null)

  return useMemo(() => {
    const categories = filterCategories.map((filterCategory) => filterCategory.attributes)

    return sortBy(categories, ['name'])
  })
}

const createPropGetters = (client) => {
  const getString = localeStringGenerator('patients.goals')

  return {
    getAddNote,
    getClient: () => (client || []),
    getCreateGoal: getCreateGoal(client.id),
    getDeleteNote,
    getFilterCategories,
    getGoalAssociations,
    getGoalCreatedDate,
    getGoalFilterCategories,
    getGoalFilterCategoryIds,
    getGoalFilterCategoryNames,
    getGoalLastUpdatedDate,
    getGoalNotes,
    getGoalsForStatus: getGoalsForStatus(client.id),
    getMostRecentGoal: getMostRecentGoal(client.id),
    getNewNotes,
    getString,
    getUpdateGoal: getUpdateGoal(client.id),
    getUpdateGoalEntity
  }
}

export default createPropGetters
