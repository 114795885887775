import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import uuid from 'uuid'

import FieldErrors from '@components/Form/FieldErrors'
import Label from '@components/Label'
import { styles, menuHeight } from './SelectStyles'
import './Select.scss'

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const cache = createCache({
  key: 'jss-class-prefix-key',
  nonce: document.querySelector('meta[property="csp-nonce"]')?.content || '',
});

class Select extends React.PureComponent {
  constructor(props) {
    super(props)

    this.inputId = this.props.inputId || `react-select-id-${uuid()}`
  }

  getSelectTag() {
    const reducedProps = { ...this.props }
    delete reducedProps.styles

    const commonProps = {
      classNamePrefix: 'Select',
      className: 'Select__container',
      maxMenuHeight: menuHeight,
      isClearable: !this.props.isMulti,
      openMenuOnFocus: true,
      menuShouldScrollIntoView: false,
      inputId: this.inputId,
      ...this.props,
      styles: {
        ...styles(reducedProps),
        ...this.props.styles
      },
      optionClassName: 'Select__option',
      value: this.props.value,
      isDisabled: this.props.disabled
    }

    const creatableProps = {
      formatCreateLabel: this.props.formatCreateLabel,
      isValidNewOption: this.props.isValidNewOption
    }

    if (this.props.loadOptions) {
      return <AsyncSelect
        cacheOptions
        maxMenuHeight={menuHeight}
        isClearable
        loadingMessage={() => window.local.t('client_merge.client_selection.select.searching')}
        noOptionsMessage={(input) => {
          if (input.inputValue) return window.local.t('client_merge.client_selection.select.no_results')
          return null
        }}
        {...commonProps}
      />
    }

    if (this.props.creatable) {
      return <CreatableSelect
        {...commonProps}
        {...creatableProps}
      />
    }

    return <ReactSelect
      {...commonProps}
    />
  }

  render() {
    const { label, required, errors, dataTest, showErrorMessages } = this.props

    return (
      <div className='Select' data-test={dataTest} data-testid={dataTest}>
        {label && <Label text={label} id={this.inputId} required={required} />}

        <CacheProvider value={cache}>
          {this.getSelectTag()}
        </CacheProvider>

        <FieldErrors errors={errors} showErrorMessages={showErrorMessages} />
      </div>
    )
  }
}

Select.displayName = 'Select'

export default Select

Select.defaultProps = {
  dataTest: '',
  errors: [],
  hideClearIndicator: false,
  required: false,
  showDropdownIndicator: false,
  showErrorMessages: false
}

Select.propTypes = {
  creatable: PropTypes.bool,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  formatCreateLabel: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  hideClearIndicator: PropTypes.bool,
  inputId: PropTypes.string,
  isMulti: PropTypes.bool,
  isValidNewOption: PropTypes.func,
  label: PropTypes.string,
  loadOptions: PropTypes.func,
  required: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  showDropdownIndicator: PropTypes.bool,
  showErrorMessages: PropTypes.bool,
  styles: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}
