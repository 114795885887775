import React, { Component, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import NotificationCount from './NotificationCount'
import NotificationPanel from './NotificationPanel'
import NotificationStore from '@services/legacyNotificationService'
import useOutsideClick from '@hooks/useOutsideClick'

const DomUtil = {
  isVisible: (element) => {
    return (element.offsetParent !== null)
  }
}

class NotificationContainer extends Component {
  constructor (props) {
    super(props)

    window.HealthifyNotifications = []

    this.store = new NotificationStore(this)

    this.bindClickToHidePanel = this.bindClickToHidePanel.bind(this)
    this.loadNotifications = this.loadNotifications.bind(this)
    this.markAllRead = this.markAllRead.bind(this)
    this.syncNotificationsFromWindow = this.syncNotificationsFromWindow.bind(this)
    this.togglePanelVisibility = this.togglePanelVisibility.bind(this)
    this.updateNotifications = this.updateNotifications.bind(this)
    this.hidePanel = this.hidePanel.bind(this)
    this.markOneAsRead = this.markOneAsRead.bind(this)
    this.updateNotification = this.updateNotification.bind(this)
  }

  componentDidMount (e) {
    // Two version of this component are rendered to the DOM, one mobile one desktop
    // If the component is visible, we will fetch the notifications from the backend
    // If the component is *not* visible, then we want to pull the notifications
    // from the window after being saved there by the other visible component
    if (DomUtil.isVisible(this._container)) {
      this.loadNotifications()
    }

    this.syncNotificationsFromWindow()
  }

  loadNotifications () {
    this.store.loadAll(this.updateNotifications)
  }

  // It is important to always update notifications through this function.
  // This ensures that we are persisting updates both to the current component and
  // to the window so that we can sync notifications between the two
  // NotificationContainer components in the DOM
  updateNotifications (notifications) {
    window.HealthifyNotifications = notifications
    this.props.setItems(notifications)
  }

  updateNotification (notification) {
    const items = [...this.props.items]
    const index = items.findIndex(n => n.id === notification.id)

    items.splice(index, 1, notification)

    this.updateNotifications(items)
  }

  syncNotificationsFromWindow () {
    window.setInterval(() => {
      this.props.setItems(window.HealthifyNotifications)
    }, 1000)
  }

  togglePanelVisibility () {
    this.props.setPanelVisibility(!this.props.isPanelVisible)
  }

  hidePanel () {
    this.props.setPanelVisibility(false)
  }

  bindClickToHidePanel (e) {
    if (this._container.contains(e.target)) {
      return
    }

    this.props.setPanelVisibility(false)
  }

  unviewedNotificationCount () {
    return this.props.items.filter((item) => {
      return item.viewed_at === null
    }).length
  }

  markAllRead () {
    return this.store.markAllRead(this.updateNotifications)
  }

  markOneAsRead (id) {
    return this.store.markOneAsRead(id, this.updateNotification)
  }

  render () {
    const { t, settingsPath } = this.props
    const { items, isPanelVisible } = this.props

    return (
      <div
        className='notification-container'
        ref={c => (this._container = c)}
      >
        <NotificationCount
          handleClick={this.togglePanelVisibility}
          count={this.unviewedNotificationCount()}
          t={t}
        />
        {isPanelVisible &&
          <NotificationPanel
            items={items}
            markAllRead={this.markAllRead}
            t={t}
            settingsPath={settingsPath}
            handleClick={this.hidePanel}
            markOneAsRead={this.markOneAsRead}
          />}
      </div>
    )
  }
}

NotificationContainer.defaultProps = {
  items: []
}

NotificationContainer.propTypes = {
  isPanelVisible: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  setPanelVisibility: PropTypes.func.isRequired,
  settingsPath: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired
}

// HACK: This is an incremental update to remove state management from
// NotificationContainer to make it easier to incrementally move to a
// function component and to implement the useRef and useOutsideClick
// hooks too
const WrappedNotificationContainer = (props) => {
  const wrapperRef = useRef()

  const [items, setItems] = useState([])
  const [isPanelVisible, setPanelVisibility] = useState(false)

  useOutsideClick(wrapperRef, () => { setPanelVisibility(false) })

  const allProps = {
    ...props,
    items,
    setItems,
    isPanelVisible,
    setPanelVisibility
  }

  return (
    <div ref={wrapperRef} className={props.wrapperClass}>
      <NotificationContainer {...allProps} />
    </div>
  )
}

WrappedNotificationContainer.propTypes = {
  wrapperClass: PropTypes.string
}

export default WrappedNotificationContainer
