import React from 'react'
import PropTypes from 'prop-types'

import { timelineEventContentClassNames } from '../utils/referralTimelineUtil'

import EmphasisBackground from '@components/EmphasisBackground'
import Text from '@components/Text'

const renderTitle = (patient) => {
  return <div className='ReferralTimeline__event__title'>
    <Text tag='span'>
      {window.local.t('referrals.timeline.events.referral_patient_followup.title', { patient })}
    </Text>
  </div>
}

const renderContent = (responseType, type, isLastElement) => {
  const className = timelineEventContentClassNames({
    eventType: type,
    isLastElement: isLastElement
  })

  return <div className={className}>
    <EmphasisBackground emphasisLevel='high'>
      {window.local.t(`referrals.timeline.events.referral_patient_followup.content.${responseType}`)}
    </EmphasisBackground>
  </div>
}

const ReferralPatientFollowupEvent = ({ type, isLastElement, patient, responseType }) => {
  return (
    <>
      {renderTitle(patient)}
      {renderContent(responseType, type, isLastElement)}
    </>
  )
}

ReferralPatientFollowupEvent.propTypes = {
  isLastElement: PropTypes.bool.isRequired,
  patient: PropTypes.string.isRequired,
  responseType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default ReferralPatientFollowupEvent
