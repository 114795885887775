import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import Spacing from '@components/Spacing'
import DatePicker from '@components/DatePicker'
import { onAllServiceDetailsFieldChange } from '@scenes/referralShow/actions'

class WaitlistedForm extends PureComponent {
  render () {
    const {
      onAllServiceDetailsFieldChange,
      encounterDateOfAction,
      formSelections: { endDate }
    } = this.props
    const minDate = moment(encounterDateOfAction).add(1, 'day').toDate()

    return (
      <Spacing marginBottom={3}>
        <DatePicker
          id='referral_encounter_expected_end_date'
          label={window.local.t('referrals.encounter_modal.end_date.label')}
          minDate={minDate}
          onChange={onAllServiceDetailsFieldChange('endDate')}
          selected={endDate && endDate.value}
          errors={endDate && endDate.errors}
        />
      </Spacing>
    )
  }
}

WaitlistedForm.propTypes = {
  encounterDateOfAction: PropTypes.instanceOf(Date),
  formSelections: PropTypes.object,
  onAllServiceDetailsFieldChange: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  onAllServiceDetailsFieldChange
}

const mapStateToProps = (state, props) => {
  const { referralEncounter, programAssignmentServiceDetails } = state.referralView.form

  return {
    encounterDateOfAction: referralEncounter.dateOfAction.value,
    formSelections: Object.values(programAssignmentServiceDetails)[0]
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitlistedForm)
