import moment from 'moment'
import compact from 'lodash/compact'

const formatAddress = ({ line1, line2, city, state, zipcode }) => {
  const conditionalLine2 = line2 ? (' ' + line2) : ''

  return `${line1}${conditionalLine2}, ${city}, ${state} ${zipcode}`
}

const formatName = ({ firstName, lastName }) => {
  return `${firstName} ${lastName}`
}

const formatDate = (backendDateString) => {
  return moment(backendDateString).format('MM/DD/YYYY')
}

// Pulled from: https://stackoverflow.com/a/8358141
const formatPhoneNumber = ({ number, extension }) => {
  const cleaned = number.replace(/\D/g, '')
  const withCountryCode = cleaned.length === 10 ? `1${cleaned}` : cleaned
  const sections = withCountryCode.match(/^(\d)(\d{3})(\d{3})(\d{4})$/)

  if (!sections) return null

  const [countryCode, areaCode, prefix, lineCode] = sections.slice(1)

  return compact([
    // Omit country code if default of US 1
    countryCode !== '1' && `+${countryCode} `,
    `(${areaCode}) `,
    `${prefix}-${lineCode}`,
    extension && ` x ${extension}`
  ]).join('')
}

const formatList = ({ elements, shouldJoin = true }) => {
  const sentence = []
  const len = elements.length
  const oxfordComma = len > 2 ? ',' : ''

  for (let i = 0; i < len; i++) {
    sentence.push(elements[i])

    if (i === len - 2) {
      sentence.push(`${oxfordComma} and `)
    } else if (i < len - 2) {
      sentence.push(', ')
    }
  }

  if (shouldJoin) {
    return sentence.join('')
  }
  return sentence
}

// Pulled from https://stackoverflow.com/questions/30827128/converting-a-distance-string-into-a-number
// This method converts distances with units to a number value
// Example: '50miles' => 50
const formatDistance = (dist) =>{
  return Number(dist.replace(/[^\d]/g, ''))
}

export {
  formatAddress,
  formatName,
  formatPhoneNumber,
  formatDate,
  formatList,
  formatDistance
}
