import React from 'react'
import PropTypes from 'prop-types'

import Select from '@components/Select'
import SelectionMenu from '@components/SelectionMenu'
import './SelectWithMenu.scss'

// This component has the following behavior:
// 1) When the select component gains focus, it opens the seleciton menu
// 2) When the user types, the selection menu disapears and the React Select's
//    dropdown menu with matching options appears.
// 3) When the search box is cleared either because a user selects an option
//    or they remove what they typed, the selection menu appears.
// 4) When the user clicks outside the select or selection menu component,
//    both dropdowns are hidden.
class SelectWithMenu extends React.PureComponent {
  constructor (props) {
    super(props)

    const defaultValue = props.isMulti ? [] : null

    this.state = {
      showSelectionMenu: false,
      showDropdownMenu: false,
      value: props.value || defaultValue,
      currentValue: null
    }

    this.onInputChange = this.onInputChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onMenuClose = this.onMenuClose.bind(this)
    this.onBlurSelectionMenu = this.onBlurSelectionMenu.bind(this)
    this.onClickSelectionMenu = this.onClickSelectionMenu.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef (node) {
    this.wrapperRef = node
  }

  handleClickOutside (event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.onBlurSelectionMenu()
    }
  }

  getUniqueOptions (values) {
    const valueSet = new Set()

    return values.reduce((uniqueArray, item) => {
      const itemValue = this.props.getOptionValue(item)
      if (valueSet.has(itemValue)) {
        return uniqueArray
      }

      valueSet.add(itemValue)

      return [...uniqueArray, item]
    }, [])
  }

  getOptionProps () {
    // If we are passed a preloaded menu, then the options for the select
    // component are all the unique items in the arrays in the key's values.
    if (this.props.selectionMenuOptions) {
      const values = Object.values(this.props.selectionMenuOptions)
        .reduce((combinedArray, array) => {
          return [...combinedArray, ...array]
        }, [])

      return { options: this.getUniqueOptions(values) }
    }

    return {}
  }

  onInputChange (value, options) {
    if (options.action === 'input-change') {
      if (value) {
        this.setState({
          currentValue: value,
          showSelectionMenu: false,
          showDropdownMenu: true
        })
      } else {
        this.setState({
          currentValue: null,
          showSelectionMenu: true,
          showDropdownMenu: false
        })
      }
    }
  }

  onFocus () {
    this.setState((prevState) => {
      if (!prevState.currentValue) {
        return {
          showSelectionMenu: true,
          showDropdownMenu: false
        }
      }
      return {}
    })
  }

  onMenuClose () {
    // This event is fired whenever ReactSelect thinks the dropdown menu
    // should be closed.
    this.setState({
      showDropdownMenu: false
    })
  }

  onBlurSelectionMenu () {
    this.setState({
      showSelectionMenu: false
    })
  }

  onSelectChange (value) {
    this.setState({
      value,
      showSelectionMenu: true,
      showDropdownMenu: false
    })
  }

  onClickSelectionMenu (value) {
    if (this.props.isMulti) {
      this.setState({
        value: this.getUniqueOptions([...this.state.value, value])
      })
    } else {
      this.setState({
        value
      })
    }
  }

  componentDidUpdate (_oldProps, oldState) {
    if (oldState.value !== this.state.value) {
      this.props.onChange(this.state.value)
    }
  }

  arrayOfValues () {
    if (Array.isArray(this.state.value)) {
      return this.state.value
    } else if (this.state.value) {
      return [this.state.value]
    } else {
      return []
    }
  }

  render () {
    return (
      <div
        className='SelectWithMenu'
        ref={this.setWrapperRef}
      >
        <Select
          dataTest={`${this.props.dataTest}-select`}
          {...this.getOptionProps()}
          {...this.props}
          onInputChange={this.onInputChange}
          onFocus={this.onFocus}
          onMenuClose={this.onMenuClose}
          menuIsOpen={this.state.showDropdownMenu}
          onChange={this.onSelectChange}
          value={this.state.value}
        />

        { this.state.showSelectionMenu &&
          <div className='SelectWithMenu__SelectionMenu'>
            <SelectionMenu
              dataTest={`${this.props.dataTest}-selection-menu`}
              onClick={this.onClickSelectionMenu}
              excludedValueObjects={this.arrayOfValues()}
              {...this.props}
            />
          </div>
        }
      </div>
    )
  }
}

SelectWithMenu.displayName = 'SelectWithMenu'

export default SelectWithMenu

SelectWithMenu.defaultProps = {
  onChange: () => {}
}

SelectWithMenu.propTypes = {
  dataTest: PropTypes.string,
  getOptionValue: PropTypes.func,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),

  // Use this for preloaded menus. It should take the form of
  // { menuKeyName: [array of options in menu], secondMenuKeyName: .... }
  selectionMenuOptions: PropTypes.object

  // All props will be passed along to the SelectionMenu and Select components
  // look at their prop types for more information.
}
