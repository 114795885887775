import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import trim from 'lodash/trim'

import Button from '@components/Button'
import EmphasisBackground from '@components/EmphasisBackground'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import ExpandableTextArea from '@components/ExpandableTextArea'
import ReferralEncounterActionSelect from '../ReferralEncounterActionSelect/ReferralEncounterActionSelect'

import { onFormFieldChange, submitNote, toggleKey } from '../actions'
import { getRecord, getAssociation } from '@selectors'
import analyticsService from '@services/analyticsService'

import './ReferralUpdateSidebar.scss'

class ReferralUpdateSidebar extends PureComponent {
  constructor (props) {
    super(props)

    this.showEncounterModal = this.showEncounterModal.bind(this)
    this.submitNote = this.submitNote.bind(this)
  }

  onFormFieldChange (form, field) {
    return (selection) => {
      this.props.onFormFieldChange(form, field, selection)
    }
  }

  showEncounterModal () {
    if (this.props.checkPrivacyStatus() && this.props.previousConsent === false) {
      this.props.toggleKey('showConsentModal', true)
    } else {
      this.props.toggleKey('referralEncounterModalPage', '1')
      this.props.toggleKey('showEncounterModal', true)
      this.trackEncounterModalOpened()
    }
  }

  submitNote () {
    if (this.props.checkPrivacyStatus() && this.props.previousConsent === false) {
      this.props.toggleKey('showConsentModal', true)
    } else {
      this.props.submitNote(this.props.referralId)
    }
  }

  submitNoteDisabled () {
    return trim(this.props.note).length === 0 || this.props.disableSubmitButton
  }

  trackEncounterModalOpened () {
    const { referral, analyticsReferrer, encounterAction } = this.props

    return analyticsService.track(
      'ReferralEncounter',
      'modal_opened',
      {
        referral_id: referral.id,
        referral_tier: referral.tier,
        selected_action_type: encounterAction
      },
      {
        analytics_referrer_type: analyticsReferrer.analytics_referrer_type,
        analytics_referrer_id: analyticsReferrer.analytics_referrer_id
      }
    )
  }

  renderReferralEncounterActionSelect () {
    if (this.props.referralResourceProgramAssignments.length) {
      return (
        <Fragment>
          <Spacing marginBottom={1}>
            <Text style='sub-header-extra-small' tag='h2'>
              {window.local.t('referrals.update_referral.log_details.header')}
            </Text>
          </Spacing>
          <Spacing marginBottom={2}>
            <Text style='body-text' tag='p'>
              {window.local.t('referrals.update_referral.log_details.context')}
            </Text>
          </Spacing>
          <Spacing marginBottom={3}>
            <ReferralEncounterActionSelect
              dataTest='referral-log-action-select'
            />
          </Spacing>
          <Spacing marginBottom={6}>
            <Button
              dataTest='referral-log-action-button'
              disabled={!this.props.encounterAction}
              type='primary'
              onClick={this.showEncounterModal}
            >
              {window.local.t('referrals.update_referral.log_details.button')}
            </Button>
          </Spacing>
        </Fragment>
      )
    }
  }

  render () {
    return (
      <aside className='ReferralUpdateSidebar'>
        <EmphasisBackground emphasisLevel='high'>
          <Spacing marginBottom={4}>
            <Text style='sub-header-medium' tag='h1'>
              {window.local.t('referrals.update_referral.header')}
            </Text>
          </Spacing>

          {this.renderReferralEncounterActionSelect()}

          <Spacing marginBottom={1}>
            <Text style='sub-header-extra-small' tag='h2'>
              {window.local.t('referrals.update_referral.note.header')}
            </Text>
          </Spacing>
          <Spacing marginBottom={3}>
            <Text style='body-text' tag='p'>
              {window.local.t('referrals.update_referral.note.context')}
            </Text>
          </Spacing>
          <Spacing marginBottom={3}>
            <ExpandableTextArea
              dataTest='referral-note-textarea'
              onChange={this.onFormFieldChange('note', 'content')}
              placeholder={window.local.t('referrals.update_referral.note.placeholder')}
              value={this.props.note}
            />
          </Spacing>
          <Button
            dataTest='referral-note-button'
            disabled={this.submitNoteDisabled()}
            type='secondary'
            onClick={this.submitNote}
          >
            {window.local.t('referrals.update_referral.note.button')}
          </Button>
        </EmphasisBackground>
      </aside>
    )
  }
}

ReferralUpdateSidebar.propTypes = {
  analyticsReferrer: PropTypes.object,
  encounterAction: PropTypes.string,
  note: PropTypes.string,
  onFormFieldChange: PropTypes.func.isRequired,
  referralId: PropTypes.string.isRequired,
  referral: PropTypes.object.isRequired,
  referralResourceProgramAssignments: PropTypes.array.isRequired,
  submitNote: PropTypes.func.isRequired,
  toggleKey: PropTypes.func.isRequired,
  previousConsent: PropTypes.bool,
  checkPrivacyStatus: PropTypes.func,
  disableSubmitButton: PropTypes.bool.isRequired
}

const mapDispatchToProps = {
  onFormFieldChange,
  submitNote,
  toggleKey
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const { action: { value: action } } = state.referralView.form.referralEncounter
  const { disableSubmitButton } = state.referralView
  const referralResourceProgramAssignments = getAssociation(state, referral, 'referralResourceProgramAssignments')

  return {
    analyticsReferrer: state.global.analyticsReferrer,
    encounterAction: action && action.value,
    note: state.referralView.form.note.content.value,
    referralResourceProgramAssignments: referralResourceProgramAssignments,
    previousConsent: state.referralView.previousConsent,
    referral,
    disableSubmitButton
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralUpdateSidebar)
