import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ExpansionPanel from '@components/ExpansionPanel'
import Grid from '@material-ui/core/Grid'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

import { ClientDetailsContext } from './ClientDetails'

const Demographics = props => {
  const {
    client: { email },
    getFormattedGender,
    getFormattedAddress,
    getFormattedDateOfBirth,
    getFormattedPhoneNumber,
    getString
  } = useContext(ClientDetailsContext)

  return (
    <>
      <Spacing paddingBottom={3}>
        <Text style='sub-header-extra-small' tag='h3'>
          {getString('patient_info.demographics')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={0.5}>
        <Text style='sub-header-extra-small' tag='h3'>
          {getString('patient_info.personal_details')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={3}>
        <ul>
          <li>
            <Text style='body-text' tag='span'>
              {getString('patient_info.date_of_birth')}: {getFormattedDateOfBirth()}
            </Text>
          </li>
          <li>
            <Text style='body-text' tag='span'>
              {getString('patient_info.gender')}: {getFormattedGender()}
            </Text>
          </li>
        </ul>
      </Spacing>
      <Spacing paddingBottom={0.5}>
        <Text style='sub-header-extra-small' tag='h3'>
          {getString('patient_info.contact_info')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={3}>
        <ul>
          <li>
            <Text style='body-text' tag='span'>
              {getString('patient_info.phone')}: {getFormattedPhoneNumber()}
            </Text>
          </li>
          <li>
            <Text style='body-text' tag='span'>
              {getString('patient_info.email')}: {email}
            </Text>
          </li>
          <li>
            <Text style='body-text' tag='span'>
              {getString('patient_info.address')}: {getFormattedAddress()}
            </Text>
          </li>
        </ul>
      </Spacing>
    </>
  )
}

const Identifiers = ({ className, ...props }) => {
  const { getFormattedIdentifiers } = useContext(ClientDetailsContext)
  const combinedClassNames = classNames(
    'ClientDetails__PatientInfo__Identifiers',
    className
  )

  return (
    <div className={combinedClassNames}>
      {getFormattedIdentifiers().map(identifier => {
        const key = `ReferralDetails__identifier-${identifier.type}`

        return <div className='ReferralDetails__identifiers' key={key} data-testid='ReferralDetails__identifier'>
          {`${identifier.type} ID: ${identifier.value}`}
        </div>
      })}
    </div>
  )
}

Identifiers.propTypes = {
  className: PropTypes.string
}

const CareInfo = props => {
  const { client, getString } = useContext(ClientDetailsContext)
  const externalIdValue = client.externalId || getString('patient_info.external_id_blank')
  const externalIdText = `${getString('patient_info.external_id')}: ${externalIdValue}`

  return (
    <>
      <Spacing paddingBottom={3}>
        <Text style='sub-header-extra-small' tag='h3'>
          {getString('patient_info.care_info')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={0.5}>
        <Text style='sub-header-extra-small' tag='h3'>
          {getString('patient_info.identification')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={3}>
        <Text style='body-text' tag='p'>
          {externalIdText}
        </Text>
        <Text style='body-text-secondary' tag='p'>
          {getString('patient_info.external_id_description')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={3}>
        <Identifiers />
      </Spacing>
    </>
  )
}

const PopulationsAndInterventions = props => {
  const {
    getInterventions,
    getPopulations,
    getString
  } = useContext(ClientDetailsContext)

  return (
    <>
      <Spacing paddingBottom={3}>
        <Text style='sub-header-extra-small' tag='h3'>
          {getString('patient_info.populations_interventions')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={0.5}>
        <Text style='sub-header-extra-small' tag='h3'>
          {getString('patient_info.populations')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={4}>
        <ul>
          {getPopulations().map(({ name }, key) => (
            <li key={key}>
              <Text style='body-text' tag='span'>{name}</Text>
            </li>
          ))}
        </ul>
      </Spacing>
      <Spacing paddingBottom={0.5}>
        <Text style='sub-header-extra-small' tag='h3'>
          {getString('patient_info.interventions')}
        </Text>
      </Spacing>
      <Spacing paddingBottom={4}>
        <ul>
          {getInterventions().map(({ name }, key) => (
            <li key={key}>
              <Text style='body-text' tag='span'>{name}</Text>
            </li>
          ))}
        </ul>
      </Spacing>
    </>
  )
}

const PatientInfo = ({ className, ...props }) => {
  const { getString } = useContext(ClientDetailsContext)
  const combinedClassNames = classNames(
    'ClientDetails__PatientInfo__Identifiers',
    className
  )

  return (
    <div className={combinedClassNames}>
      <ExpansionPanel
        expandText={getString('patient_info.expandable_expand')}
        collapseText={getString('patient_info.expandable_close')}
      >
        <Spacing paddingY={2}>
          <hr />
        </Spacing>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={5}>
            <Demographics />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CareInfo />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PopulationsAndInterventions />
          </Grid>
        </Grid>
      </ExpansionPanel>
      <Spacing paddingTop={2} paddingBottom={1}>
        <hr />
      </Spacing>
    </div>
  )
}

PatientInfo.propTypes = {
  className: PropTypes.string
}

PatientInfo.Demographics = Demographics
PatientInfo.CareInfo = CareInfo
PatientInfo.PopulationsAndInterventions = PopulationsAndInterventions
PatientInfo.Identifiers = Identifiers

export default PatientInfo
