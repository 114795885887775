import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import useReferral from './useReferral'

export const ReferralContext = createContext()

const { Provider } = ReferralContext

const Referral = ({
  client,
  children,
  resourceSite,
  ...props }) => {
  const value = useReferral(client, resourceSite)

  return (
    <Provider value={value}>
      <div className='Referral' {...props}>
        {children}
      </div>
    </Provider>
  )
}

Referral.propTypes = {
  children: PropTypes.node.isRequired,
  resourceSite: PropTypes.object,
  client: PropTypes.object
}

export default Referral
