class AddressAdapter {
  constructor (data) {
    this.object = this.adaptObject(
      data
    )
  }

  adaptObject (object) {
    return {
      id: object.id,
      line1: object.line_1,
      line2: object.line_2,
      city: object.city,
      state: object.state,
      zipcode: object.zipcode
    }
  }
}

export default AddressAdapter
