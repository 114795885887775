import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'

import unauthenticatedService from '@services/unauthenticatedService'
import { stringifyQueryParams } from '@utils/browserUtil'

import ModalHeader from '@components/Modal/ModalHeader'
import Spacing from '@components/Spacing'
import CreateForm from '../CreateForm'
import Confirmation from '../Confirmation'

const SelfReferralModal = (props) => {
  const { isSuccessful, setFormSuccess, errors, setErrors } = props

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  // NOTE: MUI Dialog doesn't respect padding-bottom set by .Modal selector
  // Whitespace is needed when fullscreen to move the submit button hitbox away
  // from the bottom of the screen, as some devices use that area for gestures
  const fullScreenMarginBottom = fullScreen ? 4 : 0

  const submitReferral = ({ companyId, patientParams, referralParams }) => {
    unauthenticatedService.selfReferrals.create({
      companyId,
      patientParams,
      referralParams
    }).then(response => {
      if (response.data.errors) {
        setErrors(response.data.errors)
        setFormSuccess(false)
      } else if (response.status !== 201) {
        setErrors([response.statusText])
        setFormSuccess(false)
      } else {
        setErrors([])
        setFormSuccess(true)
      }
    })
  }

  const downloadPdf = () => {
    const downloadPath = props.resourceSite.links.pdf
    const queryParams = stringifyQueryParams({
      company_id: props.companyId
    })

    window.location.assign(`${downloadPath}/?${queryParams}`)
  }

  return (
    <Dialog
      open={props.open}
      fullScreen={fullScreen}
      scroll='body'
      onClose={props.closeModal}
      classes={{
        paper: 'Modal SelfReferralModal'
      }}
      aria-label={`Contact ${props.resourceSite.serviceName}`}
      data-testid='SelfReferralModal'
      disableEscapeKeyDown
    >
      <Spacing marginBottom={fullScreenMarginBottom}>
        <ModalHeader onClose={props.closeModal}>
          {!isSuccessful ? 'Contact This Organization' : null}
        </ModalHeader>

        {!isSuccessful && (
          <CreateForm
            resourceSite={props.resourceSite}
            companyId={props.companyId}
            initialPatientInformation={props.patientInformation?.data?.attributes}
            errors={errors}
            submitReferral={submitReferral}
            fullScreen={fullScreen}
          />
        )}
        {isSuccessful && <Confirmation downloadPdf={downloadPdf} />}
      </Spacing>
    </Dialog>
  )
}

SelfReferralModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  resourceSite: PropTypes.object.isRequired,
  isSuccessful: PropTypes.bool.isRequired,
  setFormSuccess: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  setErrors: PropTypes.func.isRequired,

  patientInformation: PropTypes.object
}

const mapStateToProps = (state, props) => {
  const { patientInformation } = state.unauthenticated
  
  return {
    patientInformation
  }
}

export default connect(mapStateToProps, null)(SelfReferralModal)
