import React from 'react'
import PendingClientMergeRequests from '@scenes/clients/ClientIndex/PendingClientMergeRequests/PendingClientMergeRequests'
import clientMergeService from '@services/clientMergeService'

export default class PendingClientMergeRequestsApp extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isReady: false
    }
  }

  componentDidMount () {
    this.loadClientMergeRequests()
  }

  loadClientMergeRequests () {
    const params = { limit: '30', status: 'pending' }

    clientMergeService.all(params).then(pendingClientMergeRequests => {
      this.setState({
        isReady: true,
        pendingClientMergeRequests
      })
    })
  }

  render () {
    if (!this.state.isReady) return null

    return (
      <PendingClientMergeRequests
        pendingClientMergeRequests={this.state.pendingClientMergeRequests}
      />
    )
  }
}
