import React, { Component } from 'react'
import PropTypes from 'prop-types'

import RemovableTag from '@components/RemovableTag'
import Spacing from '@components/Spacing'

import './FileAttachmentFormControl.css'

class FileAttachmentFormControl extends Component {
  constructor (props) {
    super(props)

    this.handleFileSelection = this.handleFileSelection.bind(this)
    this.handleClearFile = this.handleClearFile.bind(this)

    this.fileInputRef = React.createRef()

    this.state = {
      file: this.props.file
    }
  }

  handleFileSelection (event) {
    const file = event.target.files[0]

    this.props.onFileSelect(file)
    this.setState({ file })
  }

  handleClearFile (event) {
    // Since this is an uncontrolled input,
    // we need to manually remove the file from the input itself.
    // Otherwise, the onChange event will not re-fire
    // on a 2nd upload attempt
    this.fileInputRef.current.value = null

    this.props.onFileClear()
    this.setState({ file: null })
  }

  renderDirections () {
    return (
      <p
        className='FileAttachmentFormControl__directions'
        data-testid='FileAttachmentFormControl__directions'
      >
        {this.props.directions}
      </p>
    )
  }

  renderFileSelection () {
    return (
      <Spacing marginLeft={3} tag='span'>
        <RemovableTag style='attachment' onRemove={this.handleClearFile}>
          {this.state.file.name}
        </RemovableTag>
      </Spacing>
    )
  }

  render () {
    return (
      <div className='FileAttachmentFormControl'>
        {this.props.directions && this.renderDirections()}

        <label
          className='button button-fancy button--small button-fancy--tertiary'
          htmlFor={this.props.id}
        >{window.local.t('components.file_attachment_form_control.label')}</label>

        <input
          type='file'
          name={this.props.name}
          id={this.props.id}
          onChange={this.handleFileSelection}
          ref={this.fileInputRef}
          hidden
        />

        {this.state.file && this.renderFileSelection()}
      </div>
    )
  }
}

FileAttachmentFormControl.defaultProps = {
  file: null,
  onFileClear: () => {},
  onFileSelect: () => {}
}

FileAttachmentFormControl.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  directions: PropTypes.string,
  onFileSelect: PropTypes.func,
  onFileClear: PropTypes.func,
  file: PropTypes.object
}

export default FileAttachmentFormControl
