import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import EmphasisBackground from '@components/EmphasisBackground'
import Text from '@components/Text'
import { formatList } from '@utils/formatterUtil'
import { actionsToDisplayIndividually } from '@scenes/referralShow/constants'
import { serviceDetailSummaryText } from '@scenes/referralShow/utils/serviceDetailSummaryTextUtil'
import { timelineEventContentClassNames } from '@scenes/referralShow/utils/referralTimelineUtil'

export default class EncounterEvent extends React.PureComponent {
  resourceProgramName (serviceDetail) {
    return serviceDetail.referralResourceProgramAssignment.resourceProgramName
  }

  renderResourceProgramNames () {
    const resourceProgramNames = this.props.serviceDetails.map(detail => {
      return <Text key={detail.id} style='body-text-emphasis'>
        {this.resourceProgramName(detail)}
      </Text>
    })

    return formatList({ elements: resourceProgramNames, shouldJoin: false })
  }

  renderTitle () {
    const hasNoteKey = this.props.note ? '_and_note' : ''
    const startTextKey = `referrals.timeline.events.action${hasNoteKey}.start`
    const endTextKey = `referrals.timeline.events.action${hasNoteKey}.end`

    return <div className='ReferralTimeline__event__title'>
      {window.local.t(startTextKey, { user: this.props.userText })}
      {this.renderResourceProgramNames()}
      {window.local.t(endTextKey)}
    </div>
  }

  displayIndividually () {
    return actionsToDisplayIndividually.includes(this.props.action)
  }

  renderResourceProgramName (resourceProgramName) {
    return <Text style='body-text-emphasis'>
      {`${resourceProgramName}: `}
    </Text>
  }

  renderEncounterSummaryTexts () {
    const referralEncounter = this.props.referralEncounter
    const serviceDetails = this.props.serviceDetails
    const summaryTexts = []
    const numToDisplay = this.displayIndividually() ? serviceDetails.length : 1

    for (let i = 0; i < numToDisplay; i++) {
      const serviceDetail = serviceDetails[i]
      const resourceProgramName = this.resourceProgramName(serviceDetail)
      const assignmentId = serviceDetail.referralResourceProgramAssignmentId
      const summaryText = serviceDetailSummaryText({
        referralEncounter: referralEncounter,
        resourceProgramAssignmentId: assignmentId
      })

      const testIdSubset = this.displayIndividually() ? assignmentId : 'all'
      const testId = `encounter-${referralEncounter.id}-assignment-${testIdSubset}`

      summaryTexts.push(<div data-testid={testId} key={testId}>
        <EmphasisBackground emphasisLevel='high'>
          {this.displayIndividually() &&
           serviceDetails.length > 1 &&
           this.renderResourceProgramName(resourceProgramName)}
          {summaryText}
        </EmphasisBackground>
      </div>)
    }

    return summaryTexts
  }

  renderNote () {
    return <EmphasisBackground emphasisLevel='medium'>
      {this.props.note}
    </EmphasisBackground>
  }

  renderContent () {
    const classNames = timelineEventContentClassNames({
      eventType: this.props.type,
      isLastElement: this.props.isLastElement
    })

    return <div className={classNames}>
      {this.renderEncounterSummaryTexts()}
      {this.props.note && this.renderNote()}
    </div>
  }

  render () {
    return <Fragment>
      {this.renderTitle()}
      {this.renderContent()}
    </Fragment>
  }
}

EncounterEvent.propTypes = {
  action: PropTypes.string.isRequired,
  isLastElement: PropTypes.bool.isRequired,
  note: PropTypes.string,
  referralEncounter: PropTypes.object.isRequired,
  serviceDetails: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  userText: PropTypes.string.isRequired
}
