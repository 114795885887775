import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import Select from '@components/Select'
import { search as searchClients } from '@services/clientsService'

const loadOptions = (filterClients) => (query, callback) => {
  searchClients(query).then((response) => {
    callback(filterClients(query, response.data))
  })
}

const PatientSelector = (props) => {
  const { local } = window

  return (
    <Select
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.value}
      loadOptions={debounce(loadOptions(props.filterClients), 500)}
      placeholder={local.t('components.patient_selector.placeholder')}
      showErrorMessages={props.showErrorMessages}
      {...props}
    />
  )
}

const furtherFilterPatientMatches = (query, clients) => {
  const normalizedQuery = query.toLowerCase()

  return clients.filter((client) => {
    const matchesDisplayedText = client.text.toLowerCase().includes(normalizedQuery)
    const matchesIdentifier = client.all_identifier_values.some(identifier => (
      identifier.toLowerCase().includes(normalizedQuery)
    ))
    return matchesDisplayedText || matchesIdentifier
  })
}

PatientSelector.defaultProps = {
  filterClients: furtherFilterPatientMatches,
  showErrorMessages: false
}

PatientSelector.propTypes = {
  filterClients: PropTypes.func,
  showErrorMessages: PropTypes.bool
}

export default PatientSelector
