import React from 'react'
import PropTypes from 'prop-types'
import SmallModule from '@components/SmallModule'

const PatientSidebarDetail = function ({ patient, subsection }) {
  return (
    <SmallModule
      hasMarginBottom={false}
      title={patient.full_name}
      subtitle={window.local.t('checkout.patient.profile_link')}
      subtitleLink={patient.patient_path}
      subtitleLinkInline
    >
      <div
        className='SmallModule__section'
        role='region'
        aria-label='patient-sidebar-detail'
      >
        <dl className='page-sidebar-panel__attributes-list font-sft'>
          <dt className='page-sidebar-panel__label font-sfd'>
            {window.local.t('checkout.patient.date_of_birth')}
          </dt>
          <dd>
            {patient.long_date_of_birth}
          </dd>
          <dd>
            {patient.age_in_words}
          </dd>
          <dt className='page-sidebar-panel__label font-sfd'>
            {window.local.t('checkout.patient.gender')}
          </dt>
          <dd>
            {patient.gender}
          </dd>
          <dt className='page-sidebar-panel__label font-sfd'>
            {window.local.t('checkout.patient.phone')}
          </dt>
          <dd>
            {patient.phone}
          </dd>
          <dt className='page-sidebar-panel__label font-sfd'>
            {window.local.t('checkout.patient.email')}
          </dt>
          <dd className='word-break'>
            {patient.email}
          </dd>
          <dt className='page-sidebar-panel__label font-sfd'>
            {window.local.t('checkout.patient.last_matched_programs')}
          </dt>
          <dd>
            {patient.last_matched_programs_list}
          </dd>
        </dl>
      </div>
      <div className='SmallModule__section'>
        {subsection}
      </div>
    </SmallModule>
  )
}

PatientSidebarDetail.propTypes = {
  patient: PropTypes.shape({
    full_name: PropTypes.string,
    patient_path: PropTypes.string,
    long_date_of_birth: PropTypes.string,
    gender: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    last_matched_programs_list: PropTypes.string
  }).isRequired,
  subsection: PropTypes.element.isRequired
}

export default PatientSidebarDetail
