import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DraggableTag from '@scenes/clients/ClientMerge/components/DraggableTag'

class PhoneTag extends Component {
  constructor (props) {
    super(props)

    this.handlePhoneSelection = this.handlePhoneSelection.bind(this)
    this.handlePhoneRemoval = this.handlePhoneRemoval.bind(this)
  }

  handlePhoneSelection () {
    this.props.handleClick({
      phone: this.props.phone,
      sourceClientId: this.props.clientId
    })
  }

  handlePhoneRemoval () {
    this.props.removalAction({ phone: this.props.phone })
  }

  render () {
    const { phone, handleClick, ...passThruProps } = this.props

    return (
      <DraggableTag
        {...passThruProps}
        handleRemoval={this.handlePhoneRemoval}
        handleTagSelection={this.handlePhoneSelection}
        icon='phone'
      >
        {phone.displayValue}
      </DraggableTag>
    )
  }
}

PhoneTag.propTypes = {
  phone: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  handleClick: PropTypes.func,
  removalAction: PropTypes.func
}

export default PhoneTag
