import React from 'react'
import PropTypes from 'prop-types'

import SuccessModal from '@components/SuccessModal'

const CartFeedbackSuccessModal = ({ open }) => (
  <SuccessModal
    open={open}
    text='Sharing your feedback is a helpful way for us to learn how to improve your results!'
    title='Thank you for your feedback'
  />
)

CartFeedbackSuccessModal.propTypes = {
  open: PropTypes.bool
}

export default CartFeedbackSuccessModal
