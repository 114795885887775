import React from 'react'
import PropTypes from 'prop-types'

import Spacing from '@components/Spacing'
import Text from '@components/Text'
import StampWithIconAndTooltip from '@components/StampWithIconAndToolTip'

const renderPrivacyStatusStamp = (privacyStatus) => {
  if (privacyStatus === 'private') {
    return (
      <Spacing marginTop={1}>
        <StampWithIconAndTooltip
          text={privacyStatus}
          color='orange'
          iconKey='info'
          iconSize={12}
          tooltipContent={window.local.t('resource_sites.privacy_tooltip')}
          width='100px' />
      </Spacing>
    )
  }
}

const ModalSubheader = ({ name, privacyStatus }) => (
  <Spacing marginTop={3}>
    <Spacing marginBottom={0.5}>
      <Text style='sub-header-extra-small'>
        {window.local.t('referral_modal.copy.subtitle')}
      </Text>
    </Spacing>

    <Text>{name}</Text>

    {renderPrivacyStatusStamp(privacyStatus)}
  </Spacing>
)

ModalSubheader.propTypes = {
  name: PropTypes.string.isRequired,
  privacyStatus: PropTypes.string
}

export default ModalSubheader
