import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import useFormState from '@hooks/useFormState'
import useCurrentUser from '@hooks/useCurrentUser'
import goalValidator from './goalValidator'

import Button from '@components/Button'
import Modal from '@components/Modal'
import ClientGoalForm from './ClientGoalForm'

import { ClientGoalsContext } from '../ClientGoals'

const EditGoalForm = ({ goal, ...props }) => {
  const [modalOpened, openModal] = useState(false)
  const {
    getClient,
    getGoalFilterCategories,
    getGoalFilterCategoryIds,
    getString,
    getUpdateGoal
  } = useContext(ClientGoalsContext)
  const { formState, updateField, setError } = useFormState({
    goalId: goal.id,
    patientId: getClient().id,
    userId: useCurrentUser().id,
    title: goal.title,
    status: goal.status,
    filterCategories: getGoalFilterCategories(goal),
    filterCategoryIds: getGoalFilterCategoryIds(goal),
    description: goal.description
  })
  const validateGoal = goalValidator(setError)
  const { loading, updateGoal } = getUpdateGoal()
  const formBodyAttributes = {
    loading,
    disabledFields: { selectFilterCategories: true }
  }
  const toggleModal = useCallback((e) => {
    openModal(!modalOpened)
  })
  const ClientGoalFormSubmitted = (e) => {
    e.preventDefault()
    // validate and udpate note
    if (validateGoal(formState.data)) {
      updateGoal(goal.id, formState.data).then(() => {
        toggleModal()
      })
    }
  }

  return (
    <ClientGoalForm formState={formState} updateField={updateField}>
      <Button type='tertiary' onClick={toggleModal}>
        {getString('form.edit_goal.trigger')}
      </Button>

      <Modal
        actions={<ClientGoalForm.Submit loading={loading} buttonText={getString('form.edit_goal.trigger')} />}
        content={<ClientGoalForm.Body {...formBodyAttributes} />}
        headerText={getString('form.edit_goal.modal_header')}
        open={modalOpened}
        onClose={toggleModal}
        onSubmit={ClientGoalFormSubmitted}
      />
    </ClientGoalForm>
  )
}

EditGoalForm.propTypes = {
  goal: PropTypes.object
}

export default EditGoalForm
