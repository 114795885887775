import React from 'react'
import PropTypes from 'prop-types'

import Button from '@components/Button'
import Icon from '@components/Icon'

import './IconButton.scss'

const IconButton = ({ onClick, 'aria-label': label, icon, color, size }) => (
  <Button type='toolbar' onClick={onClick} aria-label={label}>
    <Icon
      iconKey={icon}
      color={color}
      size={size}
      verticalAlignment='middle'
    />
  </Button>
)

IconButton.displayName = 'IconButton'

IconButton.defaultProps = {
  color: 'black',
  size: 20
}

IconButton.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  size: PropTypes.number
}

export default IconButton
