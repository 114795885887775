import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'

class AuthorizedRoute extends React.PureComponent {
  redirect () {
    const { local, to } = this.props.redirect

    if (local) {
      return <Redirect to={to} />
    } else {
      window.location = to
    }
  }

  renderRoutes () {
    const { children, condition, redirect, ...otherProps } = this.props
    if (children) {
      return <Switch>{children}</Switch>
    } else {
      return <Route {...otherProps} />
    }
  }

  render () {
    const { condition } = this.props

    return condition() ? this.renderRoutes() : this.redirect()
  }
}

AuthorizedRoute.propTypes = {
  children: PropTypes.node,
  condition: PropTypes.func.isRequired,
  redirect: PropTypes.shape({
    local: PropTypes.bool.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired
}

export default AuthorizedRoute
