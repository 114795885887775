import React, { Fragment, PureComponent } from 'react'
import RadioButton from '@components/RadioButton'
import Story from '../Story'

class RadioButtons extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Radio Button Group'>
          <Fragment>
            <RadioButton
              label='Button 1'
              name='button-group'
              value='value 1'
            />
            <RadioButton
              label='Button 2'
              name='button-group'
              value='value 2'
            />
          </Fragment>
        </Story>
      </Fragment>
    )
  }
}

RadioButtons.displayName = 'RadioButtons'

export default RadioButtons
