import { useReducer } from 'react'
import mapValues from 'lodash/mapValues'

function formReducer (state, [type, key, value]) {
  switch (type) {
    case 'updateField':
      const updatedField = {}

      updatedField[key] = value

      return { ...state, data: { ...state.data, ...updatedField } }
    case 'setError':
      const newErrors = {}

      newErrors[key] = value

      return { ...state, errors: { ...state.errors, ...newErrors } }
    default:
      throw new Error()
  }
}

const useFormState = (initialData = {}) => {
  const [formState, dispatch] = useReducer(formReducer, {
    data: initialData,
    errors: mapValues(initialData, () => [])
  })
  const updateField = (key, value) => {
    dispatch(['updateField', key, value])
  }
  const setError = (key, value) => {
    dispatch(['setError', key, value])
  }
  const resetFormState = () => {
    Object.keys(initialData).forEach((key) => {
      dispatch(['updateField', key, initialData[key]])
      dispatch(['setError', key, []])
    })
  }

  return { formState, updateField, setError, resetFormState }
}

export default useFormState
