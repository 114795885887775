import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getAssociation } from '@selectors'
import ExpandableTextArea from '@components/ExpandableTextArea'
import Select from '@components/Select'
import Spacing from '@components/Spacing'
import TextInput from '@components/TextInput'

const IntakeQuestionField = (props) => {
  const {
    fieldState,
    intakeQuestion,
    intakeResponseOptions
  } = props

  const textFieldOnChange = (value) => {
    props.handleChange({
      questionID: intakeQuestion.id,
      newValue: { value }
    })
  }

  const convertObject = ({ id, value }) => ({ intake_response_option_id: id, value })
  const selectFieldOnChange = (value) => {
    const determinedValue = () => {
      if (value === null || value === undefined) {
        return convertObject({ id: null, value: null })
      } else if (Array.isArray(value)) {
        return { value: value.map(convertObject) }
      } else {
        return convertObject(value)
      }
    }

    props.handleChange({
      questionID: intakeQuestion.id,
      newValue: determinedValue()
    })
  }

  const commonProps = {
    id: `intake-question-${intakeQuestion.id}`,
    label: intakeQuestion.prompt,
    name: intakeQuestion.id,
    required: Boolean(intakeQuestion.required)
  }
  const formElement = () => {
    if (intakeQuestion.questionType === 'short_response') {
      return <TextInput
        value={fieldState.value}
        onChange={textFieldOnChange}
        {...commonProps}
      />
    } else if (intakeQuestion.questionType === 'long_response') {
      return <ExpandableTextArea
        value={fieldState.value}
        onChange={textFieldOnChange}
        {...commonProps}
      />
    } else if (intakeQuestion.questionType === 'single_select') {
      return <Select
        getOptionLabel={option => option.value}
        getOptionValue={option => option.id}
        onChange={selectFieldOnChange}
        options={intakeResponseOptions}
        {...commonProps}
      />
    } else if (intakeQuestion.questionType === 'multi_select') {
      return <Select
        isMulti
        getOptionLabel={option => option.value}
        getOptionValue={option => option.id}
        options={intakeResponseOptions}
        onChange={selectFieldOnChange}
        {...commonProps}
      />
    }
  }

  return <Spacing marginBottom={2}>{formElement()}</Spacing>
}

IntakeQuestionField.propTypes = {
  fieldState: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  intakeQuestion: PropTypes.object.isRequired,
  intakeResponseOptions: PropTypes.array
}

IntakeQuestionField.defaultProps = {
  intakeResponseOptions: []
}

const mapStateToProps = (state, props) => ({
  intakeResponseOptions: getAssociation(state, props.intakeQuestion, 'intakeResponseOptions')
})

export default connect(mapStateToProps)(IntakeQuestionField)
