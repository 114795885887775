import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Collapse from '@material-ui/core/Collapse'
import Text from '@components/Text'
import './FieldErrors.scss'

class FieldErrors extends Component {
  render () {
    const { errors, showErrorMessages } = this.props

    return (
      <div className='FieldErrors'>
        <Collapse in={showErrorMessages && !!errors.length}>
          {errors.map((error, i) => (
            <Text tag='p' style='error-message' key={i}>
              {error}
            </Text>
          ))}
        </Collapse>
      </div>
    )
  }
}

FieldErrors.displayName = 'FieldErrors'

FieldErrors.defaultProps = {
  errors: [],
  showErrorMessages: false
}

FieldErrors.propTypes = {
  errors: PropTypes.array.isRequired,
  showErrorMessages: PropTypes.bool
}

export default FieldErrors
