import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'

import RemovableTag from '@components/RemovableTag'
import Story from '../Story'

const RemovableTags = () => (
  <Fragment>
    <Story title='Default RemovableTag'>
      <RemovableTag onRemove={() => {}}>default tag</RemovableTag>
    </Story>

    <Story title='Style variations'>
      <Grid container spacing={2}>
        <Grid item>
          <RemovableTag style='default' onRemove={() => {}}>
            default tag
          </RemovableTag>
        </Grid>

        <Grid item>
          <RemovableTag style='guided-search' onRemove={() => {}}>
            guided-search tag
          </RemovableTag>
        </Grid>

        <Grid item>
          <RemovableTag style='attachment' onRemove={() => {}}>
            a-very-long-attachment-filename.pdf
          </RemovableTag>
        </Grid>
      </Grid>
    </Story>
  </Fragment>
)

RemovableTags.displayName = 'RemovableTags'

export default RemovableTags
