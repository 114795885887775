import { RESOURCE_SITES_ENDPOINT } from '@constants/apiEndpoints'
import { getApiResource } from '@utils/apiUtil'

const type = 'resource_site'
const resource = getApiResource(RESOURCE_SITES_ENDPOINT)

export default {
  type,
  ...resource
}
