import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getRecord } from '@selectors'
import analyticsService from '@services/analyticsService'
import {
  onFormFieldChange,
  onServiceDetailsFieldChange,
  toggleKey,
  submitEncounter,
  setAlert,
  resetEncounterModal,
  resetServiceDetailsFormErrors
} from '../actions'

import Button from '@components/Button'
import Spacing from '@components/Spacing'
import Modal from '@components/Modal'
import SuccessModal from '@components/SuccessModal'
import Alert from '@components/Alert'
import PageOneContent from './PageOneContent'
import PageOneSubmit from './PageOneSubmit'
import PageTwoContent from './PageTwoContent'
import './ReferralEncounterModal.scss'

class ReferralEncounterModal extends Component {
  constructor (props) {
    super(props)

    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onFormFieldChange = this.onFormFieldChange.bind(this)
    this.onServiceDetailsFieldChange = this.onServiceDetailsFieldChange.bind(this)
    this.submitEncounter = this.submitEncounter.bind(this)
  }

  nextPage () {
    this.props.toggleKey('referralEncounterModalPage', '2')
  }

  previousPage () {
    this.props.toggleKey('referralEncounterModalPage', '1')
    this.props.resetServiceDetailsFormErrors()
    this.props.setAlert('referralEncounter', null)
  }

  onClose () {
    this.props.onClose()
    this.props.resetEncounterModal()
    this.trackModalClosed()
  }

  submitEncounter () {
    this.props.submitEncounter(this.props.referralId)
  }

  trackModalClosed () {
    const { referral, analyticsReferrer, selectedAction } = this.props

    return analyticsService.track(
      'ReferralEncounter',
      'modal_closed',
      {
        referral_id: referral.id,
        referral_tier: referral.tier,
        selected_action_type: selectedAction
      },
      {
        analytics_referrer_type: analyticsReferrer.analytics_referrer_type,
        analytics_referrer_id: analyticsReferrer.analytics_referrer_id
      }
    )
  }

  onFormFieldChange (field) {
    return (selection) => {
      this.props.onFormFieldChange('referralEncounter', field, selection)
    }
  }

  onServiceDetailsFieldChange (programAssignmentId, field) {
    return (value) => {
      this.props.onServiceDetailsFieldChange({ programAssignmentId, field, value })
    }
  }

  renderActionButtons () {
    if (this.props.pageToRender === '1') {
      return <PageOneSubmit onSubmit={this.nextPage} />
    } else {
      return (
        <div className='Modal__submit--single-button'>
          <Spacing marginBottom={2}>
            <Button
              type='primary'
              onClick={this.submitEncounter}
              disabled={this.props.disableSubmitButton}
              dataTest='referral-encounter-modal__submit'
            >
              {window.local.t('referrals.encounter_modal.submit')}
            </Button>
          </Spacing>

          <Button
            type='secondary'
            onClick={this.previousPage}
            dataTest='referral-encounter-modal__back'
          >
            {window.local.t('referrals.encounter_modal.back')}
          </Button>
        </div>
      )
    }
  }

  renderFormContent () {
    const { referralId, pageToRender } = this.props

    if (pageToRender === '1') {
      return <PageOneContent
        onFormFieldChange={this.onFormFieldChange}
        referralId={referralId}
      />
    } else {
      return <PageTwoContent
        onFormFieldChange={this.onFormFieldChange}
        onServiceDetailsFieldChange={this.onServiceDetailsFieldChange}
        referralId={referralId}
      />
    }
  }

  renderAlert () {
    if (this.props.pageToRender === '2') {
      return (
        <Spacing marginTop={4}>
          <Alert
            inline
            type={this.props.alert.type}
            title={this.props.alert.text}
          />
        </Spacing>
      )
    }
  }

  render () {
    const { pageToRender, open, alert } = this.props

    if (pageToRender === '3') {
      return <SuccessModal
        open={open}
        title={window.local.t('referrals.encounter_modal.success')}
        dataBehavior='referral-encounter-modal--success'
      />
    }

    return (
      <Modal
        dataTest='referral-encounter-modal'
        open={open}
        onClose={this.onClose}
        headerText={window.local.t('referrals.encounter_modal.header')}
        content={this.renderFormContent()}
        actions={this.renderActionButtons()}
        classes={{ paper: 'Modal ReferralEncounterModal' }}
        footer={alert && this.renderAlert()}
      />
    )
  }
}

ReferralEncounterModal.propTypes = {
  alert: PropTypes.object,
  analyticsReferrer: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onFormFieldChange: PropTypes.func.isRequired,
  onServiceDetailsFieldChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pageToRender: PropTypes.oneOf(['1', '2', '3']).isRequired,
  referral: PropTypes.object.isRequired,
  referralId: PropTypes.string.isRequired,
  resetEncounterModal: PropTypes.func,
  resetServiceDetailsFormErrors: PropTypes.func,
  selectedAction: PropTypes.string,
  setAlert: PropTypes.func.isRequired,
  submitEncounter: PropTypes.func.isRequired,
  toggleKey: PropTypes.func.isRequired,
  disableSubmitButton: PropTypes.bool.isRequired
}

const mapDispatchToProps = {
  onFormFieldChange,
  onServiceDetailsFieldChange,
  toggleKey,
  submitEncounter,
  setAlert,
  resetEncounterModal,
  resetServiceDetailsFormErrors
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const { action: { value: action } } = state.referralView.form.referralEncounter
  const { disableSubmitButton } = state.referralView

  return {
    alert: state.referralView.alert.referralEncounter,
    analyticsReferrer: state.global.analyticsReferrer,
    pageToRender: state.referralView.referralEncounterModalPage,
    referral,
    selectedAction: action && action.value,
    disableSubmitButton
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralEncounterModal)
