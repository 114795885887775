import get from 'lodash/get'

import {
  BOOTSTRAP_APP,
  FLUSH_ENTITIES,
  LOAD_ENTITIES,
  UPDATE_ENTITY,
  REMOVE_ENTITY,
  ADD_ENTITY,
  SET_ANALYTICS_REFERRER
} from '@constants'
import ConfigLocales from '@config/locales'

import mapValues from 'lodash/mapValues'

import { getQueryParams } from '@utils/browserUtil'

const initialState = {
  activeCartId: null,
  isReady: false,
  currentUser: null,
  locale: null,
  settings: {},
  featureFlags: {},
  entities: {},
  newEntities: {},
  analyticsReferrer: {}
}

const locales = new ConfigLocales()

const formatPaginationData = (data) => {
  let activePage = data.current_page
  let totalPages = data.total_pages
  let perCount = data.per_count
  let totalItemsCount = data.total_count
  let firstItem = ((activePage - 1) * perCount) + 1
  let lastItem = Math.min((firstItem + (perCount - 1)), totalItemsCount)

  return {
    activePage: activePage,
    totalPages: totalPages,
    perCount: perCount,
    totalItemsCount: totalItemsCount,
    firstItem: firstItem,
    lastItem: lastItem
  }
}

export const loadPaginationData = (state, action, formatter = formatPaginationData) => {
  let paginationData = formatter(action.payload)
  return {
    ...state,
    paginationData: paginationData
  }
}

export const setFormValue = (state, action) => {
  const { form, key, value } = action.payload

  return {
    ...state,
    [form]: {
      ...state[form],
      [key]: {
        ...state[form][key],
        value
      }
    }
  }
}

export const setFormFieldErrors = (state, action) => {
  const { form, key, errors } = action.payload

  return {
    ...state,
    [form]: {
      ...state[form],
      [key]: {
        ...state[form][key],
        errors
      }
    }
  }
}

export const toggleKey = (state, action) => {
  const { key, value } = action.payload

  return {
    ...state,
    [key]: value
  }
}

const loadEntitiesMappedToKeys = (stateEntities, payloadEntities) => {
  return Object.keys(payloadEntities).reduce((newEntities, key) => {
    newEntities[key] = { ...stateEntities[key], ...payloadEntities[key] }

    return newEntities
  }, {})
}

const loadEntityMappedToKeys = (stateEntities, payloadEntity) => {
  return Object.keys(payloadEntity).reduce((newEntities, key) => {
    let newEntity = payloadEntity[key]

    if (Array.isArray(stateEntities[key])) {
      newEntity = mapValues(payloadEntity[key], entity => [entity])
    }

    newEntities[key] = { ...stateEntities[key], ...newEntity }

    return newEntities
  }, {})
}

const getNewEntityIdMap = (newEntities) => {
  return Object.keys(newEntities).reduce((newEntityIds, type) => {
    newEntityIds[type] = newEntityIds[type] || []

    Object.values(newEntities[type]).forEach(entity => {
      newEntityIds[type].push({ id: entity.id, type })
    })

    return newEntityIds
  }, {})
}

const getUpdatedState = (pathArray, stateObject, updatedObject, updateMethod) => {
  if (!pathArray.length) {
    if (updateMethod === 'replace') {
      return updatedObject
    } else if (updateMethod === 'append') {
      if (Array.isArray(stateObject)) {
        return [...stateObject, updatedObject]
      } else {
        throw new Error('Object at path is not array. Append can only be used with arrays.')
      }
    } else {
      throw new Error(`${updateMethod} is not a valid updateMethod`)
    }
  }

  return {
    ...stateObject,
    [pathArray[0]]: getUpdatedState(pathArray.slice(1), stateObject[pathArray[0]], updatedObject, updateMethod)
  }
}

// Filter over an object keys and values
const objectFilter = (object, callback) => {
  const newObject = {}

  Object.keys(object).forEach(key => {
    if (callback(key, object[key])) {
      newObject[key] = object[key]
    }
  })

  return newObject
}

// Removes any relationship on any object in our entities that has the
// specified type and id.
const removeEntityRelationships = (state, type, id) => {
  const doesRelationMatch = relation => relation && relation.id === id && relation.type === type

  const removeMatchingRelationship = (entity) => {
    const newRelationships = {
      ...mapValues(entity.relationships, (relationship) => {
        if (Array.isArray(relationship)) {
          return relationship.filter(relation => !doesRelationMatch(relation))
        } else {
          if (doesRelationMatch(relationship)) {
            return null
          } else {
            return relationship
          }
        }
      })
    }

    return {
      ...entity,
      relationships: newRelationships
    }
  }

  return mapValues(state.entities, (entityType) => {
    return mapValues(entityType, (entity) => {
      if (entity.relationships) {
        return removeMatchingRelationship(entity)
      } else {
        return entity
      }
    })
  })
}

export default function globalAppReducer (state = initialState, action) {
  switch (action.type) {
    case BOOTSTRAP_APP:
      const queryParams = getQueryParams()
      const locale = locales.load(action.payload.locale)
      if (!window.local) window.local = locale
      window.featureFlags = action.payload.feature_flags

      return {
        ...state,
        activeCartId: get(action.payload, 'active_cart.data.id', null),
        analyticsReferrer: {
          analytics_referrer_id: queryParams.analytics_referrer_id,
          analytics_referrer_type: queryParams.analytics_referrer_type
        },
        languages: action.payload.languages,
        config: {
          googleMapsAPIKey: action.payload.config.google_maps_api_key
        },
        settings: action.payload.settings,
        currentCompany: action.payload.current_company.data,
        currentUser: action.payload.current_user.data,
        locale: locale,
        isReady: true,
        featureFlags: action.payload.feature_flags
      }
    case FLUSH_ENTITIES:
      const entitiesToFlush = get(state, `entities.${action.payload.type}`, undefined)
      if (!entitiesToFlush) return state

      // Destructure to separate the entity values we want from the ONE we're removing
      const { [action.payload.type]: value, ...remainingEntities } = state.entities

      return {
        ...state,
        entities: remainingEntities
      }
    case SET_ANALYTICS_REFERRER:
      return {
        ...state,
        analyticsReferrer: {
          analytics_referrer_id: action.payload.analyticsReferrerId,
          analytics_referrer_type: action.payload.analyticsReferrerType
        }
      }
    case LOAD_ENTITIES: {
      const loadedEntitiesMappedToKeys = loadEntitiesMappedToKeys(state.entities, action.payload.entities)

      return {
        ...state,
        entities: {
          ...state.entities,
          ...loadedEntitiesMappedToKeys
        }
      }
    }
    case ADD_ENTITY: {
      const loadedEntitiesMappedToKeys = loadEntityMappedToKeys(state.entities, action.payload.entities)

      return {
        ...state,
        entities: {
          ...state.entities,
          ...loadedEntitiesMappedToKeys
        },
        newEntities: getNewEntityIdMap(action.payload.entities)
      }
    }
    case UPDATE_ENTITY: {
      const fullPath = ['entities', action.payload.type, action.payload.id, ...action.payload.path]

      return getUpdatedState(fullPath, { ...state }, action.payload.updatedObject, action.payload.updateMethod)
    }
    case REMOVE_ENTITY: {
      const stringId = action.payload.id + ''

      return {
        ...state,
        entities: {
          ...removeEntityRelationships(state, action.payload.type, stringId),
          [action.payload.type]: objectFilter(
            state.entities[action.payload.type],
            (key, _value) => key !== stringId
          )
        }
      }
    }
    default: return state
  }
}
