import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@components/Button'
import MobileSearchFilterModal from '@scenes/resourceSites/MobileSearchFilterModal'
import {
  IN_NETWORK_FILTER_PARAM,
  IN_NETWORK_FILTER_STATE_KEY,
  PREFERRED_PARTNERS_FILTER_PARAM,
  PREFERRED_PARTNERS_STATE_KEY,
  PREFERRED_PROVIDERS_FILTER_PARAM,
  PREFERRED_PROVIDERS_STATE_KEY,
  VERIFIED_FILTER_PARAM,
  VERIFIED_FILTER_STATE_KEY,
} from '@scenes/resourceSites/constants'

import './MobileSearchFilters.scss'

const MobileSearchFilters = ({ appliedFilter, ...passThruProps }) => {
  const [modalIsOpen, setModalOpen] = useState(false)

  return (
    <div data-test='mobile-search-filters' className='MobileSearchFilters__Container'>
      <Button
        onClick={() => { setModalOpen(!modalIsOpen) }}
        type='expansion'
      >
        {`${window.local.t('search_filters.title')} ${appliedFilter ? '(1) ' : ''}+`}
      </Button>

      {modalIsOpen &&
        <MobileSearchFilterModal
          appliedFilter={appliedFilter}
          onClose={() => setModalOpen(false)}
          open={modalIsOpen}
          {...passThruProps}
        />}
    </div>
  )
}

MobileSearchFilters.propTypes = {
  appliedFilter: PropTypes.string
}

MobileSearchFilters.defaultProps = {
  appliedFilter: null
}

const mapStateToProps = state => {
  const { resourceSites: { search } } = state

  let appliedFilter = null
  if (search[IN_NETWORK_FILTER_STATE_KEY].value === 'on') {
    appliedFilter = IN_NETWORK_FILTER_PARAM
  } else if (search[PREFERRED_PARTNERS_STATE_KEY].value === 'on') {
    appliedFilter = PREFERRED_PARTNERS_FILTER_PARAM
  } else if (search[PREFERRED_PROVIDERS_STATE_KEY].value === 'on') {
    appliedFilter = PREFERRED_PROVIDERS_FILTER_PARAM
  } else if (search[VERIFIED_FILTER_STATE_KEY].value === 'on') {
    appliedFilter = VERIFIED_FILTER_PARAM
  }

  return {
    appliedFilter
  }
}

export default connect(mapStateToProps)(MobileSearchFilters)
