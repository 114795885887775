import React, { Fragment, PureComponent } from 'react'
import ExpandableTextArea from '@components/ExpandableTextArea'
import Text from '@components/Text'
import Story from '../Story'

class ExpandableTextAreas extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Basic ExpandableTextArea' key='basic'>
          <ExpandableTextArea
            label='Basic field'
            placeholder='Add a note here'
          />
        </Story>

        <Story title='Required ExpandableTextArea' key='required'>
          <ExpandableTextArea
            label='Required field'
            placeholder='Add a note here'
            required
          />
        </Story>

        <Story title='Inline ExpandableTextArea' key='inline'>
          <Fragment>
            <Text tag='p'>
              This variation is best used within a wrapper div, alongside an attachment control. The wrapper will override the border style below, and allow the attachment control to float over the textarea.
            </Text>

            <ExpandableTextArea
              inline
              label='Inline field'
              placeholder='Inline field'
            />
          </Fragment>
        </Story>
      </Fragment>
    )
  }
}

ExpandableTextAreas.displayName = 'ExpandableTextAreas'

export default ExpandableTextAreas
