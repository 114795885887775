import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { formatDate, formatName } from '@utils/formatterUtil'
import { getAssociation, getRecord } from '@selectors'

import Text from '@components/Text'
import Spacing from '@components/Spacing'
import Stamp from '@components/Stamp'
import ReferralDetails from '../ReferralDetails/ReferralDetails'
import ReferralTierStamp from '@components/ReferralTierStamp'
import StampWithIconAndToolTip from '@components/StampWithIconAndToolTip'
import './ReferralHeader.scss'
import ReferralState from '../ReferralState/ReferralState'
import ReferralAssignedUsers from '../ReferralAssignedUsers/ReferralAssignedUsers'

class ReferralHeader extends PureComponent {
  renderReferralTierStamp () {
    return <Spacing marginLeft={4}>
      <ReferralTierStamp tier={this.props.referral.tier} version='long' tag='span' />
    </Spacing>
  }

  renderReferralPrivacyStatusStamp () {
    if (this.props.checkPrivacyStatus()) {
      return <Spacing marginLeft={4}>
        <StampWithIconAndToolTip
          text={this.props.referral.privacyStatus}
          width='100px'
          color='orange'
          iconKey='info'
          iconSize={12}
          tooltipContent={window.local.t('referrals.show.privacy_tooltip')}
          tooltipOptions={{ placement: 'bottom' }}
        />
      </Spacing>
    }
  }

  render () {
    return (
      <section className='ReferralHeader' data-testid='ReferralHeader'>
        <Spacing marginBottom={3}>
          <div className='ReferralHeader__tier'>
            <Text style='sub-header-extra-small-secondary' tag='span'>
              {window.local.t('referrals.overview.referral_number', { referral_id: this.props.referral.id })}
            </Text>

            {this.renderReferralTierStamp()}
            {this.renderReferralPrivacyStatusStamp()}

            {this.props.referral.selfReferral && (
              <Spacing marginLeft={4}>
                <Stamp color='grey' tag='span' text='Client Created' />
              </Spacing>
            )}
          </div>
        </Spacing>
        <ReferralState
          referralId={String(this.props.referralId)}
          pdf={this.props.pdf}
          checkPrivacyStatus={this.props.checkPrivacyStatus} />
        <Spacing marginBottom={3}>
          <Text style='body-text' tag='div'>
            {window.local.t('referrals.overview.patient')}
            <a href={this.props.patient.links.self} data-test='client-link'>
              {
                `${formatName(this.props.patient)} ` +
                `(${formatDate(this.props.patient.dateOfBirth)})`
              }
            </a>  
          </Text>
        </Spacing>
        <ReferralAssignedUsers referralId={this.props.referralId} />
        <ReferralDetails referralId={this.props.referralId} pdf={this.props.pdf} checkPrivacyStatus={this.props.checkPrivacyStatus} />
      </section>
    )
  }
}

ReferralHeader.propTypes = {
  referral: PropTypes.shape({
    tier: PropTypes.string,
    serviceName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentStatus: PropTypes.string,
    privacyStatus: PropTypes.string,
    selfReferral: PropTypes.bool
  }),
  referralId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  patient: PropTypes.object,
  usesCoordinate: PropTypes.bool,
  pdf: PropTypes.bool,
  checkPrivacyStatus: PropTypes.func
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)

  return {
    referral,
    patient: getAssociation(state, referral, 'patient'),
    usesCoordinate: state.global.settings.uses_coordinate
  }
}

export default connect(mapStateToProps, null)(ReferralHeader)
