import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import Select from '@components/Select'
import ResourceSitePrivacyStatusModal from './ResourceSitePrivacyStatusModal'

const ResourceSitePrivacyStatusSelect = (props) => {
  const { name, label, options, privacyStatus, ...passThruProps } = props
  const [isModalOpen, setModalOpen] = useState(false)
  const [privacy, setPrivacy] = useState(privacyStatus)
  const [confirmText, setConfirmText] = useState('')
  const handleModalClose = () => { setModalOpen(false) }
  const handleModalOpen = () => { setModalOpen(true) }
  const handleReset = () => { handleModalClose(); setPrivacy(privacyStatus) }

  const handleOnChange = (selectedValue) => {
    handleModalOpen()
    setConfirmText(selectedValue.label)
    setPrivacy(selectedValue.value)
  }

  return (
    <>
      <label className='control-label' htmlFor='privacy-status-select'>{label}</label>

      <Select name={name} onChange={handleOnChange} options={options}
        defaultValue={options.filter((option) => option.value === privacyStatus)}
        value={options.filter((option) => option.value === privacy)}
        showDropdownIndicator hideClearIndicator inputId='privacy-status-select' {...passThruProps}
        id='ResourceSitePrivacyStatus__select'
      />

      <ResourceSitePrivacyStatusModal confirmText={confirmText} open={isModalOpen}
        onSuccess={handleModalClose} handleClose={handleReset} privacyStatus={privacy}
      />
    </>
  )
}

ResourceSitePrivacyStatusSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  privacyStatus: PropTypes.string
}

export default ResourceSitePrivacyStatusSelect
