import { useMemo } from 'react'

import cartSharesService from '@services/cartSharesService'
import useGetRecord from '@hooks/useGetRecord'

import getPropCollections from './propCollections'

export default function useCartShare (cartShareUuid) {
  const cartShare = useGetRecord(cartSharesService.type, cartShareUuid)
  const propCollections = getPropCollections(cartShare)

  const value = useMemo(() => ({
    cartShare,
    ...propCollections
  }), [cartShare])

  return value
}
