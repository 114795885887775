import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import useLoadOptions from '@hooks/useLoadOptions'
import locationSearchService from '@services/locationSearchService'

import Button from '@components/Button'
import Select from '@components/Select'
import Spacing from '@components/Spacing'
import ErrorText from '@components/ErrorText'

import { CoverageAreaManagerContext } from '../CoverageAreaManager'

export const CoverageAreaFormContext = createContext()

const { Provider } = CoverageAreaFormContext

const CoverageAreaForm = ({
  children,
  formState,
  updateField,
  resetFormState,
  ...props
}) => {
  const locationSearchOptions = useLoadOptions(locationSearchService.index)
  const value = { formState, updateField, resetFormState, locationSearchOptions }

  return (
    <Provider value={value}>
      <div className='CoverageAreaForm' {...props}>
        {children}
      </div>
    </Provider>
  )
}

CoverageAreaForm.propTypes = {
  children: PropTypes.node.isRequired,
  formState: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  resetFormState: PropTypes.func.isRequired
}

CoverageAreaForm.LocationSearch = ({ loading, errors, ...props }) => {
  const {
    getString,
    formatters: { locationSearchOptionLabel }
  } = useContext(CoverageAreaManagerContext)
  const {
    formState: { data: formData },
    updateField,
    resetFormState,
    locationSearchOptions
  } = useContext(CoverageAreaFormContext)

  const coverageAreaLocationSelected = (selection) => {
    props.onLocationSelectChange()

    if (!selection) {
      resetFormState()
      return
    }

    updateField('state', selection.state_code)

    switch (selection.type) {
      case 'city':
        updateField('city', selection.city)
        updateField('type', 'cities')
        break
      case 'county':
        updateField('county', selection.county)
        updateField('type', 'counties')
        break
      case 'zipcode':
        updateField('zipcode', selection.postal_code)
        updateField('type', 'zipcodes')
        break
      default:
        updateField('type', 'states')
    }
  }

  return (
    <section className='CoverageAreaForm__Body'>
      <Spacing paddingBottom={2}>
        <Select
          name='CoverageArea__LocationSearch'
          onChange={coverageAreaLocationSelected}
          options={locationSearchOptions.options}
          getOptionValue={(result) => result}
          getOptionLabel={locationSearchOptionLabel}
          label={getString('form.label.location_search')}
          placeholder={getString('form.placeholder.location_search')}
          value={formData.location}
          onInputChange={(value) => locationSearchOptions.loadOptions(value)}
          showDropdownIndicator
          required
        />
      </Spacing>

      {errors.map((error, index) => <ErrorText key={index} text={error} />)}
    </section>
  )
}

CoverageAreaForm.LocationSearch.propTypes = {
  errors: PropTypes.array,
  loading: PropTypes.bool,
  onLocationSelectChange: PropTypes.func
}

CoverageAreaForm.Submit = ({ loading, buttonText, errors, ...props }) => {
  const { getString } = useContext(CoverageAreaManagerContext)
  const { formState: { data: formData } } = useContext(CoverageAreaFormContext)
  // useEffect(() => {}, [loading, ])
  const isDisabled = useMemo(() => (
    loading || !formData.type || errors.length
  ), [loading, formData.type, errors.length])

  return (
    <Button htmlType='submit' type='primary' disabled={isDisabled}>
      {buttonText || getString('form.add.button_text')}
    </Button>
  )
}

CoverageAreaForm.Submit.propTypes = {
  errors: PropTypes.array,
  buttonText: PropTypes.string.isRequired,
  loading: PropTypes.bool
}

export default CoverageAreaForm
