import React from 'react'
import PropTypes from 'prop-types'
import useGetFeatureFlag from '@hooks/useGetFeatureFlag'

import Spacing from '@components/Spacing'
import Text from '@components/Text'
import Icon from '@components/Icon'

const SuccessContent = ({ clientName }) => {
  const showShareOptions = useGetFeatureFlag('member_led_closed_loop_referrals_flag')
  const shareOptionsContext = showShareOptions ? 'pdf_text_or_email' : 'pdf'

  return (
    <div className='ReferralModal__subheader--share'>
      <Spacing marginBottom={2}>
        <Icon iconKey='check_circle' color='green' size={64} />

        <Spacing marginBottom={0.5}>
          <Text style='body-text-emphasis' tag='p'>
            {window.local.t('referral_modal.copy.share_header_plural')}
          </Text>
        </Spacing>

        <Text tag='p'>
          {window.local.t('referral_modal.copy.share_subtext.generic_plural', {
            clientName,
            shareOptions: window.local.t(`referral_modal.copy.${shareOptionsContext}`)
          })}
        </Text>
      </Spacing>
    </div>
  )
}

SuccessContent.propTypes = {
  clientName: PropTypes.string.isRequired
}

export default SuccessContent
