import 'core-js/stable/'

import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from '@utils/serviceWorker'

import { componentWithAppWrapper } from '@utils/renderAppUtil'

import PendingClientMergeRequestsApp from '@scenes/clients/ClientIndex/PendingClientMergeRequests/PendingClientMergeRequestsApp'
import ClientDetailsScene from '@scenes/clients/ClientDetails/Scene'
import ClientMergeApp from '@scenes/clients/ClientMerge/ClientMergeApp'
import RiskScoreSection from '@features/clients/components/ClientRiskScores/Section'
import DeleteAttachmentApp from '@scenes/attachments/DeleteAttachment/DeleteAttachmentApp'
import DefaultErrorPage from '@scenes/errors/DefaultErrorPage/DefaultErrorPage'
import TerminatedPatientErrorPage from '@scenes/errors/TerminatedPatientErrorPage/TerminatedPatientErrorPage'
import ReferralCheckout from '@scenes/referralCheckout/ReferralCheckout'
import ResourceSiteTierBanner from '@scenes/resourceSites/ResourceSiteTierBanner'
import Referral from '@scenes/referralShow/Referral/Referral'
import CoverageAreaManagerScene from '@scenes/resourceSites/CoverageArea/CoverageAreaManagerScene'
import StyleguideEntry from '@styleguide/StyleguideEntry'
import SinglePageApp from '@root/routes/SinglePageApp'
import WhitelabeledScreeningTextButton from '@scenes/unauthenticated/WhitelabeledScreeningTextButton'
import UnauthenticatedApp from '@root/routes/UnauthenticatedApp'
import { TextResourceButton } from '@scenes/resourceSites/SearchResultCard/SearchResultCardActions'
import ResourceSitePrivacyStatusSelect from '@scenes/resourceSites/ResourceSitePrivacyStatusSelect'
import SelfReferral from '@scenes/unauthenticated/SelfReferral'
import EmbeddedReferralModal from '../scenes/resourceSites/ReferralModal/EmbeddedReferralModal'

import LegacyResourceSiteDownloadButton from '@components/LegacyResourceSiteDownloadButton'
import NotificationContainer from '@components/NotificationContainer/NotificationContainer'
import ReferralTierStamp from '@components/ReferralTierStamp'
import SearchFilters from '@components/SearchFilters'
import Stamp from '@components/Stamp'
import StampWithIconAndToolTip from '@components/StampWithIconAndToolTip'
import SurveyExpander from '@components/SurveyExpander'
import { SurveyContactForm, SurveyResultsPage } from '@scenes/unauthenticated/components/survey'

const pages = [
  {
    component: PendingClientMergeRequestsApp,
    destination: document.getElementById('react-pack-pending-client-merge-requests')
  },
  {
    component: ClientMergeApp,
    destination: document.getElementById('react-pack-client-merge')
  },
  {
    component: DeleteAttachmentApp,
    destination: document.getElementById('react-pack-delete-attachment')
  },
  {
    component: DefaultErrorPage,
    destination: document.getElementById('react-pack-error-page')
  },
  {
    component: Referral,
    destination: document.getElementById('react-pack-referral-show')
  },
  {
    component: TerminatedPatientErrorPage,
    destination: document.getElementById('react-pack-terminated-patient')
  },
  {
    component: StyleguideEntry,
    destination: document.getElementById('react-pack-styleguide')
  },
  {
    component: SinglePageApp,
    destination: document.getElementById('react-pack-single-page-app')
  },
  {
    component: UnauthenticatedApp,
    destination: document.getElementById('react-pack-unauthenticated-app')
  }
]

const components = [
  {
    component: SurveyContactForm,
    elements: document.querySelectorAll('[data-component="survey-contact-page"]')
  },
  {
    component: SurveyResultsPage,
    elements: document.querySelectorAll('[data-component="survey-results-page"]')
  },
  {
    component: ReferralCheckout,
    elements: document.querySelectorAll('[data-component="checkout-page"]')
  },
  {
    component: CoverageAreaManagerScene,
    elements: document.querySelectorAll('[data-component="coverage-area-manager"]')
  },
  {
    component: EmbeddedReferralModal,
    elements: document.querySelectorAll('[data-component="referral-modal-trigger"]')
  },
  {
    component: TextResourceButton,
    elements: document.querySelectorAll('[data-component="text-resource-button"]')
  },
  {
    component: ResourceSitePrivacyStatusSelect,
    elements: document.querySelectorAll('[data-component="resource-site-privacy-status-select"]')
  },
  {
    component: ClientDetailsScene,
    elements: document.querySelectorAll('[data-component="spa-client-details"]')
  },
  {
    component: RiskScoreSection,
    elements: document.querySelectorAll('[data-component="spa-client-risk-scores"]')
  },
  {
    component: ReferralTierStamp,
    elements: document.querySelectorAll('[data-component="referral-tier-stamp"]')
  },
  {
    component: ResourceSiteTierBanner,
    elements: document.querySelectorAll('[data-component="resource-site-tier-banner"]')
  },
  {
    component: LegacyResourceSiteDownloadButton,
    elements: document.querySelectorAll('[data-component="legacy-resource-site-download-button"]')
  },
  {
    component: SearchFilters,
    elements: document.querySelectorAll('[data-component="search-filters"]')
  },
  {
    component: Stamp,
    elements: document.querySelectorAll('[data-component="referral-stamp"]')
  },
  {
    component: StampWithIconAndToolTip,
    elements: document.querySelectorAll('[data-component="stamp_with_icon_and_tooltip"]')
  },
  {
    component: WhitelabeledScreeningTextButton,
    elements: document.querySelectorAll('[data-component="whitelabeled-survey-texting-modal"]'),
    withoutBootstrap: true
  },
  {
    component: SelfReferral,
    elements: document.querySelectorAll('[data-component="self-referral-trigger"]'),
    withoutBootstrap: true
  },
  {
    component: SurveyExpander,
    elements: document.querySelectorAll('[data-component="survey-expander"]'),
    withoutBootstrap: true
  },
  {
    component: NotificationContainer,
    elements: document.querySelectorAll('[data-component="notification-container"]'),
    withoutBootstrap: true
  }
]

components.map((component) => {
  Array.from(component.elements).map((element) => {
    element.removeAttribute('data-component')
    const props = JSON.parse(element.dataset.reactProps)
    const useBootstrap = !component.withoutBootstrap

    renderComponent(component.component, element, props, useBootstrap)
  })
})

pages.map((app) => {
  if (app.destination) {
    renderApp(app.component, app.destination)
  }
})

function renderApp (component, element, useBootstrap = true) {
  const NamedAppWrapper = componentWithAppWrapper(component, {}, useBootstrap)

  NamedAppWrapper.displayName = `_${component.name}`

  ReactDOM.render(
    <NamedAppWrapper />,
    element
  )
}

function renderComponent (component, element, props, useBootstrap) {
  const NamedComponentWrapper = componentWithAppWrapper(component, props, useBootstrap)

  NamedComponentWrapper.displayName = `_${component.name}`

  ReactDOM.render(
    <NamedComponentWrapper />,
    element
  )
}

if (module.hot) {
  module.hot.accept(() => { console.log('There was a problem reloading the module.') })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
