import React, { Fragment, PureComponent } from 'react'
import Alert from '@components/Alert'
import Story from '../Story'

class Alerts extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Error Alert'>
          <Alert title='test' body='Error alert content' type='error' />
        </Story>
        <Story title='Warning Alert'>
          <Alert title='test' body='Warning alert content' type='warning' />
        </Story>
        <Story title='Success Alert'>
          <Alert title='test' body='Success alert content' type='success' />
        </Story>
        <Story title='Inline Success Alert'>
          <Alert inline title='Alert for inline success messages' type='success' />
        </Story>
        <Story title='Inline Warning Alert'>
          <Alert inline title='Alert for inline warning messages' type='warning' />
        </Story>
        <Story title='Inline Error Alert'>
          <Alert inline title='Alert for inline errors' type='error' />
        </Story>
      </Fragment>
    )
  }
}

Alerts.displayName = 'Alerts'

export default Alerts
