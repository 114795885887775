import React from 'react'
import PropTypes from 'prop-types'

import './ModalContentSeparator.scss'

const ModalContentSeparator = ({ children }) => {
  if (children) {
    return <div className='Modal__ContentSeparator'>{children}</div>
  } else {
    return <hr className='Modal__ContentSeparator' />
  }
}

ModalContentSeparator.propTypes = {
  children: PropTypes.node
}

export default ModalContentSeparator
