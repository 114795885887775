import React from 'react'
import PropTypes from 'prop-types'

import Text from '@components/Text'
import Icon from '@components/Icon'

const RiskScoreSection = ({ socialRiskScores }) => {
  return (
    <section className='block' data-test='patient-social-risk-scores'>
      <header className='header-control'>
        <Text withIcon>
          <h3>{window.local.t('risk_scores.title')}</h3>
          <a href={window.local.t('risk_scores.knowledge_base.link')} target='_blank' rel='noreferrer'>
            <Icon iconKey='info' size={16} aria-label={window.local.t('risk_scores.knowledge_base.text')} />
          </a>
        </Text>
      </header>
      <hr />
      <table>
        <tbody>
          <tr>
            <td>
              <div className='b-text'>
                {window.local.t('risk_scores.algorex.sdoh_composite')}
              </div>
              {socialRiskScores.table.sdohComposite}
            </td>
            <td>
              <div className='b-text'>
                {window.local.t('risk_scores.algorex.unstable_housing')}
              </div>
              {socialRiskScores.table.unstableHousing}
            </td>
            <td>
              <div className='b-text'>
                {window.local.t('risk_scores.algorex.neighborhood_stress')}
              </div>
              {socialRiskScores.table.neighborhoodStress}
            </td>
            <td>
              <div className='b-text'>
                {window.local.t('risk_scores.algorex.food_access')}
              </div>
              {socialRiskScores.table.foodAccess}
            </td>
          </tr>
          <tr>
            <td>
              <div className='b-text'>
                {window.local.t('risk_scores.algorex.social_vulnerability')}
              </div>
              {socialRiskScores.table.socialVulnerability}
            </td>
            <td>
              <div className='b-text'>
                {window.local.t('risk_scores.algorex.homelessness_indicator')}
              </div>
              {socialRiskScores.table.homelessShelter}
            </td>
            <td>
              <div className='b-text'>
                {window.local.t('risk_scores.algorex.transportation_access')}
              </div>
              {socialRiskScores.table.transportationAccess}
            </td>
            <td>
              <div className='b-text'>
                {window.local.t('risk_scores.algorex.social_isolation')}
              </div>
              {socialRiskScores.table.socialIsolation}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

RiskScoreSection.propTypes = {
  socialRiskScores: PropTypes.object.isRequired
}

export default RiskScoreSection
