import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

import Dialog from '@material-ui/core/Dialog'
import Icon from '@components/Icon'
import './SuccessModal.css'

const SuccessModal = (props) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { open, title, text, dataBehavior } = props

  const conditionallyRenderContent = () => {
    if (title || text) {
      return (
        <div>
          {title && <div className='b-text'>{title}</div>}
          {text && <div>{text}</div>}
        </div>
      )
    }
  }

  const className = classNames('SuccessModal', {
    'SuccessModal--with-content': text || title,
    'SuccessModal--mobile': fullScreen
  })

  return (
    <Dialog
      open={open}
      classes={{ paper: className }}
      data-behavior={dataBehavior}
      fullScreen={fullScreen}
      disableEscapeKeyDown
    >
      <Icon
        iconKey='check_circle'
        className='mi--green SuccessModal__icon'
        size={72}
      />

      {conditionallyRenderContent()}
    </Dialog>
  )
}

SuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,

  title: PropTypes.string,
  text: PropTypes.string,
  dataBehavior: PropTypes.string
}

SuccessModal.defaultProps = {
  dataBehavior: ''
}

export default SuccessModal
