const OUT_OF_NETWORK = 'out_of_network'
const OUT_OF_NETWORK_CLR = 'out_of_network_clr'
const IN_NETWORK = 'in_network'
const IN_NETWORK_PREFERRED = 'in_network_preferred'
const NETWORKED_TIERS = [
  IN_NETWORK,
  IN_NETWORK_PREFERRED
]
const OUT_OF_NETWORK_TIERS = [
  OUT_OF_NETWORK,
  OUT_OF_NETWORK_CLR
]

export {
  OUT_OF_NETWORK,
  OUT_OF_NETWORK_CLR,
  OUT_OF_NETWORK_TIERS,
  IN_NETWORK,
  IN_NETWORK_PREFERRED,
  NETWORKED_TIERS
}
