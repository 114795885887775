import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@components/Button'
import ExpandableTextArea from '@components/ExpandableTextArea'
import Modal from '@components/Modal'
import ModalContentSeparator from '@components/Modal/ContentSeparator'
import Spacing from '@components/Spacing'
import SuccessModal from '@components/SuccessModal'
import Text from '@components/Text'

import { closeEditModal } from '@scenes/resourceSites/actions'
import { getRecord } from '@selectors'
import resourceSiteEditService from '@services/resourceSiteEditService'

const localeFor = (i18nPath, options = {}) => window.local.t(
  `resource_sites.edit_modal.${i18nPath}`,
  options
)

class ResourceSiteEditModal extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isProcessing: false,
      message: '',
      successOpen: false
    }

    this.onTextChange = this.onTextChange.bind(this)
    this.handleEditSubmit = this.handleEditSubmit.bind(this)
  }

  renderSubheader () {
    return (
      <Spacing marginTop={3}>
        <Spacing marginBottom={0.5}>
          <Text style='sub-header-extra-small'>
            {localeFor('subheader')}
          </Text>
        </Spacing>

        <Text>{this.props.resourceSite.serviceName}</Text>
      </Spacing>
    )
  }

  onTextChange (text) {
    this.setState({ message: text })
  }

  renderMessageInput () {
    return (
      <ModalContentSeparator>
        <ExpandableTextArea
          label={localeFor('message_input.label')}
          maxLength={500}
          onChange={this.onTextChange}
          placeholder={localeFor('message_input.placeholder')}
        />
      </ModalContentSeparator>
    )
  }

  renderModalContent () {
    return (
      <Fragment>
        {this.renderSubheader()}
        {this.renderMessageInput()}
      </Fragment>
    )
  }

  submitDisabled () {
    return !this.state.message || this.state.isProcessing
  }

  renderModalActions () {
    return (
      <div className='Modal__submit--single-button'>
        <Button type='primary' onClick={this.handleEditSubmit} disabled={this.submitDisabled()}>
          {localeFor('submit')}
        </Button>
      </div>
    )
  }

  submitData () {
    const { resourceSite, currentUser } = this.props

    return {
      resource_site_id: resourceSite.id,
      request_to_update: {
        from: currentUser.attributes.email,
        message: this.state.message
      }
    }
  }

  handleEditSubmit (event) {
    event.preventDefault()

    this.setState({ isProcessing: true })

    resourceSiteEditService.requestToUpdate(
      this.submitData(),
      this.props.resourceSite.id
    ).then(() => {
      this.setState({ successOpen: true })
      setTimeout(this.props.closeEditModal, 3000)
    })
  }

  renderSuccessModal () {
    const modalText = localeFor('success.text')

    return <SuccessModal
      open={this.state.successOpen}
      text={modalText}
      title={localeFor('success.title')}
    />
  }

  render () {
    if (this.state.successOpen) {
      return this.renderSuccessModal()
    }

    return <Modal
      actions={this.renderModalActions()}
      content={this.renderModalContent()}
      dataTest='ResourceSiteEditModal'
      headerText={localeFor('title')}
      onClose={this.props.closeEditModal}
      open={this.props.editModalOpen}
      {...this.props}
    />
  }
}

ResourceSiteEditModal.propTypes = {
  closeEditModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  editModalOpen: PropTypes.bool.isRequired,
  resourceSite: PropTypes.shape({
    id: PropTypes.number.isRequired,
    serviceName: PropTypes.string.isRequired
  })
}

const mapDispatchToProps = {
  closeEditModal
}

const mapStateToProps = (state, props) => {
  const { currentUser } = state.global
  const { editModalOpen, resourceSiteId } = state.resourceSites.editModal
  const resourceSite = getRecord(state, 'resource_site_search_result', resourceSiteId)

  return {
    ...state.resourceSites.editModal,
    currentUser,
    editModalOpen,
    resourceSite
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSiteEditModal)
