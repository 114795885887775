import React from 'react'
import PropTypes from 'prop-types'
import Column from '../Column'
import './Sidebar.css'

class Sidebar extends Column {
  render () {
    return (
      <aside className={`Sidebar Column ${this.columnGutter()}`} style={{ width: this.columnSpan() }}>
        {this.props.children}
      </aside>
    )
  }
}

Sidebar.propTypes = {
  children: PropTypes.node
}

export default Sidebar
