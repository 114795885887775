import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import classNames from 'classnames'
import omit from 'lodash/omit'

import FieldErrors from '@components/Form/FieldErrors'
import Label from '@components/Label'
import './TextInput.scss'

const TextInput = (props) => {
  const [internalUUID] = useState(uuid())
  const input = useRef(null)
  const inputId = props.id || `text-input-id-${internalUUID}`
  const classes = classNames(
    'TextInput',
    {
      'TextInput--invalid': props.errors.length
    }
  )
  const htmlProps = omit(props, [
    'errors',
    'hint',
    'id',
    'label',
    'showErrorMessages',
    'dataTestid'
  ])

  const onChange = (event) => {
    props.onChange(event.target.value)
  }

  return (
    <div data-testid={props.dataTestid} className={classes}>
      {props.label && <Label
        text={props.label}
        id={inputId}
        disabled={props.disabled}
        required={props.required}
        hint={props.hint}
      />}

      <input
        {...htmlProps}
        id={inputId}
        ref={input}
        onChange={onChange}
        className='TextInput__input'
      />

      <FieldErrors errors={props.errors} showErrorMessages={props.showErrorMessages} />
    </div>
  )
}

TextInput.displayName = 'TextInput'

TextInput.propTypes = {
  dataTestid: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  hint: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showErrorMessages: PropTypes.bool,
  type: PropTypes.string,
  value: (props, propName, componentName, ...rest) => {
    if (props.onChange) return PropTypes.string(props, propName, componentName, ...rest)

    return new Error(
      `When specifying a \`${propName}\` with ${componentName} ` +
      `you must provide an \`onChange\` value`
    )
  }
}

TextInput.defaultProps = {
  disabled: false,
  errors: [],
  required: false,
  showErrorMessages: false,
  type: 'text'
}

export default TextInput
