import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Spacing from '@components/Spacing'
import Text from '@components/Text'
import DeleteCoverageAreaButton from './Forms/DeleteCoverageAreaButton'
import Grid from '@material-ui/core/Grid'

import { CoverageAreaManagerContext } from './CoverageAreaManager'

const CardItem = ({ coverageArea, ...props }) => {
  const {
    formatters: { coverageAreaDescription }
  } = useContext(CoverageAreaManagerContext)

  return (
    <Grid item xs={6} sm={4}>
      <article className='CoverageAreaCards__CardItem'>
        <Spacing paddingY={2} paddingX={2}>
          <Text tag='p'>
            {coverageAreaDescription(coverageArea)}
          </Text>
          <DeleteCoverageAreaButton coverageArea={coverageArea} />
        </Spacing>
      </article>
    </Grid>
  )
}

CardItem.propTypes = {
  coverageArea: PropTypes.object.isRequired
}

const CoverageAreaCards = (props) => {
  const {
    associations: { getCoverageAreas },
    getString
  } = useContext(CoverageAreaManagerContext)

  return (
    <section className='CoverageAreaCards' data-testid='CoverageAreaCards'>
      <Grid container spacing={3} direction='row'>
        {getCoverageAreas().map((coverageArea) => (
          <CardItem coverageArea={coverageArea} key={coverageArea.id} />
        ))}
        {!getCoverageAreas().length && (
          <Grid item xs={6} sm={4}>
            <Spacing paddingTop={2}>
              <Text tag='p'>{getString('no_coverage_areas')}</Text>
            </Spacing>
          </Grid>
        )}
      </Grid>
    </section>
  )
}

export default CoverageAreaCards
