export function adaptUserObject (object) {
  return {
    id: object.attributes.id,
    email: object.attributes.email,
    firstName: object.attributes.first_name,
    lastName: object.attributes.last_name,
    role: object.attributes.role,
    url: object.links.self
  }
}
