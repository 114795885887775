import React, { createContext } from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import List from './List'

import './ClientGoals.scss'

import useClientGoals from './useClientGoals'

export const ClientGoalsContext = createContext()

const { Provider } = ClientGoalsContext

const ClientGoals = ({
  children,
  clientId,
  ...props }) => {
  const value = useClientGoals(clientId)

  return (
    <Provider value={value}>
      <div className='ClientGoals' {...props}>
        {children}
      </div>
    </Provider>
  )
}

ClientGoals.propTypes = {
  children: PropTypes.node.isRequired,
  clientId: PropTypes.number.isRequired
}

ClientGoals.Header = Header
ClientGoals.List = List

export default ClientGoals
