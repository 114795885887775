export const validateZipCode = (value, required) => {
  const { local } = window
  const valueIsPresent = value && value.length > 0

  if (required && !valueIsPresent) {
    return local.t('cannot_be_blank')
  }
  // TODO: Make this regex consistent with the backend to clear zip codes
  // like this: `'12345 - 7890'`
  return (valueIsPresent && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) ? undefined : local.t('zip_code_not_valid')
}

export const validatePhone = (value, required) => {
  const { local } = window
  const valueIsPresent = value && value.length > 0

  if (required && !valueIsPresent) { return local.t('cannot_be_blank') }
  if (!required && !valueIsPresent) { return undefined }

  // Ideally this regex should be identical to the one on the backend
  // app/models/phone.rb - NUMBER_PATTERN.
  // But it needed to be converted to this flavor and effectively, should achieve
  // the same as the backend regex.
  const phoneRegex = /^(?:\+?1[-.●]?)?(\D*\d\D*){10}$/
  return (valueIsPresent && phoneRegex.test(value)) ? undefined : local.t('phone_not_valid')
}
