export const BOOTSTRAP_APP = 'BOOTSTRAP_APP'
export const FLUSH_ENTITIES = 'FLUSH_ENTITIES'
export const LOAD_ENTITIES = 'LOAD_ENTITIES'
export const REMOVE_ENTITY = 'REMOVE_ENTITY'
export const ADD_ENTITY = 'ADD_ENTITY'
export const SET_ANALYTICS_REFERRER = 'SET_ANALYTICS_REFERRER'
export const SET_FORM_FIELD_ERRORS = 'SET_FORM_FIELD_ERRORS'
export const SET_FORM_VALUE = 'SET_FORM_VALUE'
export const TOGGLE_KEY = 'TOGGLE_KEY'
export const LOAD_PAGINATION_DATA = 'LOAD_PAGINATION_DATA'
export const UPDATE_ENTITY = 'UPDATE_ENTITY'

export const CLIENT_INDEX = '/en/clients'
export const RESOURCE_SHARE_ENDPOINT = '/api/internal/unauthenticated/resource_site_shares'

export const ANALYTIC_REFERRER_TYPES = {
  searchSubmitted: 'Search Submitted'
}

export const HEADER_HEIGHT = 64
