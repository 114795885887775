import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'

import { setPatientId } from '@features/referralCheckout'

import PatientSelector from '@components/PatientSelector'
import SmallModule from '@components/SmallModule'
import Button from '@components/Button'

export class ReferAnotherPatientForm extends React.Component {
  constructor (props) {
    super(props)

    this.handleOnChange = this.handleOnChange.bind(this)
    this.confirmUpdatePatient = this.confirmUpdatePatient.bind(this)
    this.loadPatients = this.loadPatients.bind(this)
  }

  handleOnChange (selection, event) {
    switch (event.action) {
      case 'select-option':
        this.props.setPatientId(selection.value)
        break
      case 'clear':
        this.props.setPatientId(null)
        break
    }
  }

  confirmButtonDisabled () {
    return !this.props.patientId
  }

  confirmUpdatePatient () {
    this.props.confirmUpdatePatient(this.props.patientId)
  }

  loadPatients (query, callback) {
    return axios.get(
      this.props.loadPatientsPath, { query: query }
    ).then(callback, callback)
  }

  render () {
    let { hideReferAnotherPatientForm } = this.props

    return (
      <SmallModule
        title=''
        hasMarginBottom={false}
        hasHeaderBackground={false}
      >
        <div className='SmallModule__section'>
          <h3 className='page-sidebar-panel__title font-sft'>
            {window.local.t('checkout.refer_another_client.title')}
          </h3>
          <p className='page-sidebar-panel__description font-sft'>
            {window.local.t('checkout.refer_another_client.note')}
          </p>
        </div>
        <div className='SmallModule__section'>
          <PatientSelector
            name='referral[patient_id]'
            label={window.local.t('checkout.refer_another_client.input_label')}
            onChange={this.handleOnChange}
          />
        </div>
        <div className='SmallModule__section'>
          <section className='page-sidebar-panel__footer'>
            <button
              disabled={this.confirmButtonDisabled()}
              onClick={this.confirmUpdatePatient}
              className='button care-plan-drawer__footer__button--primary care-plan-drawer__footer__button'>
              {window.local.t('checkout.refer_another_client.confirm_button')}
            </button>
            <div className='page-sidebar-panel__cancel'>
              <Button type='tertiary' onClick={hideReferAnotherPatientForm}>
                {window.local.t('checkout.refer_another_client.cancel')}
              </Button>
            </div>
          </section>
        </div>
      </SmallModule>
    )
  }
}

ReferAnotherPatientForm.propTypes = {
  loadPatientsPath: PropTypes.string.isRequired,
  confirmUpdatePatient: PropTypes.func.isRequired,
  hideReferAnotherPatientForm: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  setPatientId: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  patientId: state.referralCheckout.patientId
})

const mapDispatchToProps = {
  setPatientId
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferAnotherPatientForm)
