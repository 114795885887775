import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DraggableTag from '@scenes/clients/ClientMerge/components/DraggableTag'
import AddressDisplay from '@components/AddressDisplay'

class AddressTag extends Component {
  constructor (props) {
    super(props)

    this.handleRemoval = this.handleRemoval.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
  }

  handleSelection () {
    this.props.selectionAction({
      address: this.props.address,
      sourceClientId: this.props.clientId
    })
  }

  handleRemoval () {
    this.props.removalAction({ address: this.props.address })
  }

  render () {
    const { address, ...passThruProps } = this.props

    return (
      <DraggableTag
        {...passThruProps}
        handleRemoval={this.handleRemoval}
        handleTagSelection={this.handleSelection}
        icon='place'
      >
        <AddressDisplay address={address} />
      </DraggableTag>
    )
  }
}

AddressTag.propTypes = {
  address: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  removalAction: PropTypes.func,
  selectionAction: PropTypes.func
}

export default AddressTag
