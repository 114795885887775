import React from 'react'
import PropTypes from 'prop-types'

import referralPolicy from '@policies/referralPolicy'
import cartPolicy from '@policies/cartPolicy'
import selfReferralPolicy from '@policies/selfReferralPolicy'
import textPolicy from '@policies/textPolicy'
import { trackResourceSiteEvent } from '@utils/analyticsUtil'
import { RESOURCE_SHARE_ENDPOINT } from '@constants'
import { TEXTING_MODAL_OPENED, TEXTING_MODAL_CLOSED } from '@constants/trackingEvents'
import { isInNetwork } from '@utils/networkTiersUtil'

import AddRemoveCartActions from '@scenes/resourceSites/AddRemoveCartActions'
import ReferralModal from '@scenes/resourceSites/ReferralModal'
import Referral from '@scenes/resourceSites/Referral'
import SelfReferral from '@scenes/unauthenticated/SelfReferral'
import TextButton from '@scenes/unauthenticated/WhitelabeledScreeningTextButton'

import Spacing from '@components/Spacing'

const ActionContainer = (props) => (
  <div className='SearchResultCard__ReferralActions__ButtonContainer'>
    <Spacing marginRight={{ xs: 0, sm: 1 }} marginBottom={{ xs: 1, sm: 0 }}>
      {props.children}
    </Spacing>
  </div>
)

ActionContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export const TextResourceButton = ({ resourceSiteUuid }) => {
  const trackModalEvent = eventType => {
    trackResourceSiteEvent(resourceSiteUuid, eventType)
  }

  return (
    <ActionContainer>
      <TextButton
        buttonText={window.local.t('resource_sites.text_resource_info')}
        endpointPath={RESOURCE_SHARE_ENDPOINT}
        formData={{ uuid: resourceSiteUuid }}
        modalKey={resourceSiteUuid}
        onModalOpened={() => { trackModalEvent(TEXTING_MODAL_OPENED) }}
        onModalClosed={() => { trackModalEvent(TEXTING_MODAL_CLOSED) }}
      />
    </ActionContainer>
  )
}

TextResourceButton.propTypes = {
  resourceSiteUuid: PropTypes.string.isRequired
}

const SearchResultCardActions = (props) => {
  const {
    analyticsReferrer,
    client,
    languages,
    settings,
    featureFlags,
    currentUser,
    resourceSite
  } = props

  const {
    canReceiveAttachments,
    id,
    cartItemId,
    closedLoopable,
    organizationName,
    resourcePrograms,
    serviceName,
    tier
  } = resourceSite

  const inNetwork = isInNetwork(tier)

  return (
    <Spacing marginTop={4}>
      <div
        className='SearchResultCard__ReferralActions__Container'
        data-testid='ReferralActions__Container'
      >
        {referralPolicy.canCreate(settings) && (
          <ActionContainer>
            <Referral
              resourceSite={resourceSite}
              client={client}>
              <ReferralModal
                id={id}
                attachmentsEnabled={settings.attachments_enabled}
                client={client}
                currentUser={currentUser}
                languages={languages}
                name={serviceName}
                privacyStatus={resourceSite.privacyStatus}
                isCoordinate={inNetwork}
                closedLoopable={closedLoopable}
                textMessagingEnabled={settings.text_messaging_enabled}
                memberLedCLREnabled={featureFlags.member_led_closed_loop_referrals_flag}
                displayAttachmentsDisabledMessage={inNetwork && !canReceiveAttachments}
                hidePatientUI={settings.can_create_referral_without_patient}
                attestationRequired={(inNetwork || closedLoopable) && settings.end_user_attestation_required}
                programs={resourcePrograms}
                organizationName={organizationName}
                analytics={{
                  referrerId: analyticsReferrer.analytics_referrer_id,
                  referrerType: analyticsReferrer.analytics_referrer_type
                }}
              />
            </Referral>
          </ActionContainer>
        )}

        { textPolicy.canCreate(currentUser) && (
          <ActionContainer>
            <TextResourceButton
              resourceSiteUuid={resourceSite.uuid}
            />
          </ActionContainer>
        )}

        {selfReferralPolicy.canCreate(settings, resourceSite) && (
          <ActionContainer>
            <SelfReferral
              resourceSite={resourceSite}
              companyId={currentUser.attributes.company_id}
            />
          </ActionContainer>
        )}

        {cartPolicy.canAdd(currentUser) && (
          <ActionContainer>
            <AddRemoveCartActions
              resourceSiteId={id}
              cartItemId={cartItemId}
            />
          </ActionContainer>
        )}
      </div>
    </Spacing>
  )
}

SearchResultCardActions.propTypes = {
  analyticsReferrer: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  featureFlags: PropTypes.object.isRequired,
  resourceSite: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  client: PropTypes.object,
  languages: PropTypes.object
}

export default SearchResultCardActions
