import React from 'react'
import PropTypes from 'prop-types'

import LanguageSelect from '@components/Forms/Elements/LanguageSelect'
import Select from '@components/Select'
import CheckboxButton from '@components/CheckboxButton'
import TextInput from '@components/TextInput'
import Checkbox from '@components/Form/Checkbox'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import Icon from '@components/Icon'

import { format } from '@utils/selectUtil'
import { formatName } from '@utils/formatterUtil'
import { isPhone } from '@utils/stringUtil'

const renderSharingSubHeader = (state, data) => {
  const showTextCheckbox = state.formSelections.client.accepts_texts &&
    data.textMessagingEnabled &&
    state.phones &&
    state.phones.length !== 0

  const shareSubtextSuffix = state.formSelections.closedLoop ? 'closed_loop' : 'generic'

  const { client } = state.formSelections
  const clientName = client.name || formatName(client)

  const shareOptions = showTextCheckbox
    ? window.local.t('referral_modal.copy.pdf_or_text')
    : window.local.t('referral_modal.copy.pdf')

  return (
    <div className='ReferralModal__subheader--share' data-test='ReferralModal__Success'>
      <Spacing marginBottom={3}>
        <Spacing marginBottom={2}>
          <Icon
            iconKey='check_circle'
            className='mi--green ReferralModal__success-icon'
            size={72}
          />
        </Spacing>

        <Spacing marginBottom={0.5}>
          <Text style='body-text-emphasis'>
            {window.local.t('referral_modal.copy.share_header')}
          </Text>
        </Spacing>

        <Text>
          {window.local.t(
            `referral_modal.copy.share_subtext.${shareSubtextSuffix}`,
            { clientName, shareOptions }
          )}
        </Text>
      </Spacing>
    </div>
  )
}

// TODO: Remove this variable when we remove the member_led_closed_loop_referrals_flag
const showLegacyTextCheckbox = (state, data) => {
  return state.formSelections.client.accepts_texts &&
    data.textMessagingEnabled &&
    state.phones &&
    state.phones.length !== 0
}

// TODO: Remove this variable when we remove the member_led_closed_loop_referrals_flag
const showLegacyPhoneSelect = (state) => {
  return state.shareFormSelections.sendText && state.phones.length > 0
}

const showShareConsentCheckbox = (state, data) => {
  return data.memberLedCLREnabled &&
    (state.shareFormSelections.sendText || state.shareFormSelections.sendEmail)
}

const ReferralShareActionsContent = ({ state, data, behavior }) => {
  const showTextCheckbox = data.memberLedCLREnabled ? data.textMessagingEnabled : showLegacyTextCheckbox(state, data)
  const showEmailCheckbox = data.memberLedCLREnabled

  const showPhoneSelect = showLegacyPhoneSelect(state) ||
    (data.memberLedCLREnabled && state.shareFormSelections.sendText)
  const showEmailInput = data.memberLedCLREnabled && state.shareFormSelections.sendEmail

  return (
    <div className='ReferralShareActions'>
      {renderSharingSubHeader(state, data)}

      <div data-test='ReferralModal__Share__Language'>
        <LanguageSelect
          showDropdownIndicator
          dataTest='referral-modal-language'
          defaultValue={state.shareFormSelections.language}
          label={window.local.t('referral_modal.fields.share_language')}
          languages={data.languages}
          name='referral[language]'
          onChange={behavior.handleShareSelectChange('language')}
        />
      </div>

      <div className='ReferralShareActions__actions'>
        <div className='Select__label'>
          {window.local.t('referral_modal.fields.share_options')}
        </div>
        <div
          style={{ marginBottom: '16px' }}
          data-test='ReferralModal__Share__Options'
        >
          <CheckboxButton
            icon='picture_as_pdf'
            name='download_pdf'
            label={window.local.t('referral_modal.fields.share_pdf')}
            selected={state.shareFormSelections.downloadPDF}
            onChange={behavior.handleDownloadPDFClick}
          />

          {showTextCheckbox &&
            <CheckboxButton
              icon='chat'
              name='send_text'
              label={window.local.t('referral_modal.fields.share_text')}
              selected={state.shareFormSelections.sendText}
              onChange={behavior.handleSendTextClick}
            />
          }
          {showPhoneSelect && renderPhoneSelect(state, data, behavior)}

          {showEmailCheckbox &&
            <CheckboxButton
              dataTest='send-email-checkbox'
              icon='mail'
              name='send_email'
              label={window.local.t('referral_modal.fields.share_email')}
              selected={state.shareFormSelections.sendEmail}
              onChange={behavior.handleSendEmailClick}
            />
          }

          {showEmailInput &&
            <TextInput
              data-test='send-email-input'
              id='member_share_email'
              label={window.local.t('referral_modal.fields.share_email_input')}
              onChange={behavior.handleEmailInputChange}
              placeholder={window.local.t('referral_modal.fields.share_email_input_placeholder')}
              type='email'
              value={state.shareFormSelections.email}
            />
          }
          {showShareConsentCheckbox(state, data) &&
            <Spacing marginTop={3}>
              <Checkbox
                dataTest='member-led-clr-share-consent'
                label={window.local.t('referral_modal.fields.member_led_clr_consent')}
                id='member_led_clr_share_consent'
                name='member_led_clr_share_consent'
                onChange={behavior.handleMemberLedCLRConsentClick}
                required
              />
            </Spacing>
          }
        </div>
      </div>
    </div>
  )
}

const renderPhoneSelect = (state, data, behavior) => {
  const creatable = data.memberLedCLREnabled
  const formatCreateLabel = (phone) => {
    return window.local.t('referral_modal.fields.share_phone_create_label', { phone })
  }
  // TODO: Remove this variable when we remove the member_led_closed_loop_referrals_flag
  const placeholderKey = creatable ? 'select_number' : 'legacy_select_number'

  return (
    <div data-test='ReferralModal__Share__PhoneSelect'>
      <Select
        isValidNewOption={isPhone}
        creatable={creatable}
        formatCreateLabel={formatCreateLabel}
        dataTest='referral-modal-phone'
        name='phone_id'
        showDropdownIndicator
        required={state.shareFormSelections.sendText}
        label={window.local.t('referral_modal.fields.share_phone_select')}
        placeholder={window.local.t(`referral_modal.fields.${placeholderKey}`)}
        options={format.phones(state.phones)}
        onChange={behavior.handleShareSelectChange('phoneNumber')}
      />
    </div>
  )
}

ReferralShareActionsContent.propTypes = {
  data: PropTypes.object,
  state: PropTypes.object,
  behavior: PropTypes.object
}

export default ReferralShareActionsContent
