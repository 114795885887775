import React, { useCallback, useContext, useState } from 'react'

import useFormState from '@hooks/useFormState'
import useCurrentUser from '@hooks/useCurrentUser'
import goalValidator from './goalValidator'

import Button from '@components/Button'
import Icon from '@components/Icon'
import Modal from '@components/Modal'
import Text from '@components/Text'

import ClientGoalForm from './ClientGoalForm'

import { ClientGoalsContext } from '../ClientGoals'

const AddGoalForm = ({ ...props }) => {
  const [modalOpened, openModal] = useState(false)
  const { getString, getCreateGoal, getClient } = useContext(ClientGoalsContext)
  const { formState, updateField, resetFormState, setError } = useFormState({
    patientId: getClient().id,
    userId: useCurrentUser().id,
    companyId: useCurrentUser().company_id,
    title: '',
    status: 0,
    filterCategories: [],
    filterCategoryIds: [],
    relatedNeeds: [],
    description: ''
  })
  const validateGoal = goalValidator(setError)
  const { loading, createGoal } = getCreateGoal()
  const toggleModal = useCallback((e) => {
    openModal(!modalOpened)
  })

  const ClientGoalFormSubmitted = (e) => {
    e.preventDefault()
    // create new note
    if (validateGoal(formState.data)) {
      createGoal(formState.data).then(() => {
        resetFormState()
        toggleModal()
      })
    }
  }

  return (
    <ClientGoalForm formState={formState} updateField={updateField}>
      <Button type='primary' onClick={toggleModal}>
        <Text style='buttons' withIcon>
          <Icon iconKey='add_circle' size={16} />{' '}
          {getString('form.add_goal.trigger')}
        </Text>
      </Button>

      <Modal
        actions={<ClientGoalForm.Submit loading={loading} buttonText={getString('form.add_goal.trigger')} />}
        content={<ClientGoalForm.Body loading={loading} />}
        headerText={getString('form.add_goal.modal_header')}
        open={modalOpened}
        onClose={toggleModal}
        onSubmit={ClientGoalFormSubmitted}
      />
    </ClientGoalForm>
  )
}

export default AddGoalForm
