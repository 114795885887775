import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Form, Field } from 'react-final-form'

import Button from '@components/Button'
import RFFTextarea from '@components/Textarea/RFF'

const formValidations = (values) => {
  const errors = {}

  if (!values.feedback) {
    errors.feedback = 'Required'
  }

  return errors
}

const CartFeedbackFormModal = ({ handleSubmit, onClose, open }) => {
  return (
    <Dialog open={open} onClose={onClose} role='dialog' aria-labelledby='dialogTitle'>
      <Form
        onSubmit={handleSubmit}
        validate={formValidations}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id='dialogTitle'>Provide feedback</DialogTitle>

            <DialogContent>
              <Field
                autoFocus
                component={RFFTextarea}
                name='feedback'
                placeholder='Let us know what you’re having trouble finding.'
                rows='3'
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} type='tertiary'>Cancel</Button>
              <Button
                htmlType='submit'
                disabled={invalid}
                type='primary'
                inheritWidth
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}

CartFeedbackFormModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
}

export default CartFeedbackFormModal
