import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ExpansionPanelAccordion from './ExpansionPanelAccordion'
import ExpansionPanelUnstyled from './ExpansionPanelUnstyled'

import './ExpansionPanel.scss'

const ExpansionPanel = ({ onToggle, ...props }) => {
  const [expanded, setExpanded] = useState(props.expanded)

  const panelProps = {
    ...props,
    defaultExpanded: props.expanded,
    expanded,
    toggleExpanded: () => {
      setExpanded(!expanded)
      if (onToggle) onToggle(!expanded)
    }
  }

  const ExpansionPanelType = props.insideCard ? ExpansionPanelAccordion : ExpansionPanelUnstyled

  return <ExpansionPanelType {...panelProps} />
}

ExpansionPanel.displayName = 'ExpansionPanel'

ExpansionPanel.propTypes = {
  children: PropTypes.node.isRequired,
  collapseText: PropTypes.string.isRequired,
  expandText: PropTypes.string.isRequired,
  onToggle: PropTypes.func,

  expanded: PropTypes.bool,
  insideCard: PropTypes.bool,
  withHeader: PropTypes.bool,
  isDisabled: PropTypes.bool,
  title: PropTypes.node
}

ExpansionPanel.defaultProps = {
  expanded: false,
  isDisabled: false,
  insideCard: false
}

export default ExpansionPanel
