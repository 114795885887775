export const initialState = {
  alert: {},
  expandedReferralEncounterModalSections: [],
  form: {
    referralEncounter: {
      action: {
        errors: [],
        value: null
      },
      dateOfAction: {
        errors: [],
        value: new Date()
      },
      referralResourceProgramAssignments: {
        errors: [],
        value: []
      },
      note: {
        errors: [],
        value: ''
      }
    },
    programAssignmentServiceDetails: {},
    note: {
      content: {
        errors: [],
        value: ''
      }
    }
  },
  hasFetchedTeams: false,
  newState: null,
  pendingDeleteAttachmentId: null,
  recipients: [],
  referralEncounterModalPage: '1',
  showEditAssignedUsersView: false,
  showEditStateView: false,
  showEncounterModal: false,
  showUploadAttachmentModal: false,
  previousConsent: false,
  showConsentModal: false,
  disableSubmitButton: false
}
