import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import LegacyButton from '@components/legacy/LegacyButton'
import './ButtonLink.css'

class ButtonLink extends React.PureComponent {
  renderButton () {
    const { to, children, ...buttonProps } = this.props

    return (
      <LegacyButton
        {...buttonProps}
      >
        {children}
      </LegacyButton>
    )
  }

  render () {
    const { disabled, to } = this.props

    if (disabled) {
      return this.renderButton()
    } else {
      return (
        <Link to={to}>
          {this.renderButton()}
        </Link>
      )
    }
  }
}

ButtonLink.defaultProps = {
  disabled: false
}

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}

export default ButtonLink
