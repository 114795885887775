import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { canRenderIconVariants } from '@utils/browserUtil'

class Icon extends React.PureComponent {
  modifierClassName () {
    const { className, size, theme, color, verticalAlignment } = this.props

    return classNames(
      'Icon',
      'material-icons',
      `mi--${size}`,
      'notranslate',
      {
        [className]: className,
        [`material-icons-${theme}`]: theme && !canRenderIconVariants(),
        [`mi--align-${verticalAlignment}`]: verticalAlignment,
        [`mi--${color}`]: color
      }
    )
  }

  render () {
    const {
      iconKey,

      // Unused props extracted to avoid adding DOM attributes
      className, size, theme, color, verticalAlignment,

      ...passThruProps
    } = this.props

    return (
      <i {...passThruProps} className={this.modifierClassName()}>
        {iconKey}
      </i>
    )
  }
}

Icon.propTypes = {
  iconKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'light',
    'dark',
    'black',
    'blue',
    'faded',
    'green',
    'orange',
    'red'
  ]),
  'data-testid': PropTypes.string,
  size: PropTypes.number,
  theme: PropTypes.oneOf([
    'outlined',
    'round',
    'two-tone',
    'sharp'
  ]),
  verticalAlignment: PropTypes.oneOf([
    'top',
    'middle',
    'bottom'
  ])
}

export default Icon
