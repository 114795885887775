import { useCallback, useMemo } from 'react'

import useGetAssociation from '@hooks/useGetAssociation'
import { localeStringGenerator } from '@utils/localeUtil'
import { formatPhoneNumber } from '@utils/formatterUtil'

const getFormatters = (resourceSite) => {
  const { getPhone } = getAssociations(resourceSite)

  const phoneNumber = useCallback(() => {
    const phone = getPhone()

    if (!phone) return ''

    return formatPhoneNumber(phone)
  })

  const website = useCallback(() => {
    if (!resourceSite.links) {
      throw new Error(
        `The links attribute was not returned by the serializer`
      )
    }

    return resourceSite.links.external
  })

  const resourceSiteUrl = useCallback(() => {
    if (!resourceSite.links) {
      throw new Error(
        `The links attribute was not returned by the serializer`
      )
    }

    return resourceSite.links.self
  })

  const value = useMemo(() => ({
    phoneNumber,
    resourceSiteUrl,
    website
  }), [resourceSite])

  return value
}

const getAssociations = (resourceSite) => {
  const getPhone = useCallback(() => {
    return useGetAssociation(resourceSite, 'primaryPhone')
  })

  const getAddress = useCallback(() => {
    return useGetAssociation(resourceSite, 'address')
  })

  const value = useMemo(() => ({
    getPhone,
    getAddress
  }), [resourceSite])

  return value
}

const getPropCollections = (resourceSite) => {
  const getString = localeStringGenerator('resource_site')

  return {
    associations: getAssociations(resourceSite),
    formatters: getFormatters(resourceSite),
    getString
  }
}

export default getPropCollections
