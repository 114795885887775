import React from 'react'
import PropTypes from 'prop-types'

import { formatAddress } from '@utils/formatterUtil'

const AddressDisplay = ({ address, singleLine }) => {
  if (!address) return null

  if (singleLine) {
    return (
      <span aria-label='Address'>
        {formatAddress(address)}
      </span>
    )
  } else {
    return (
      <div aria-label='Address'>
        <div>{address.line1}</div>
        {address.line2 && <div>{address.line2}</div>}
        <div>{address.city}, {address.state} {address.zipcode}</div>
      </div>
    )
  }
}

AddressDisplay.propTypes = {
  address: PropTypes.object,
  singleLine: PropTypes.bool
}

export default AddressDisplay
