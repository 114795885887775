import {
  isConsentGrantedForCartItems,
  getConsentRequiredCartItems
} from '../ReferralCheckoutUtil'

export const selectRemainingCartItems = (state) => {
  return state.referralCheckout.cartItems.filter((cartItem) => !cartItem.removed)
}

export const selectCartItemsWithErrors = (state) => {
  return selectRemainingCartItems(state).filter(
    (cartItem) => Object.values(cartItem.errorObject).filter(
      (error) => error !== null
    ).length > 0
  )
}

export const isClientConsentGranted = (state) => (
  isConsentGrantedForCartItems(selectRemainingCartItems(state))
)

export const isClientConsentRequired = (state) => (
  getConsentRequiredCartItems(selectRemainingCartItems(state)).length > 0
)
