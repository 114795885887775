import { useReducer } from 'react'

function stateReducer (state, [type, newData = {}]) {
  switch (type) {
    case 'mergeState':
      return { ...state, ...newData }
    case 'resetState':
      return { ...newData }
    default:
      throw new Error()
  }
}

const useStateReducer = (initialState = {}) => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const changeState = (newData) => {
    dispatch(['mergeState', newData])
  }

  const resetState = (data = initialState) => {
    dispatch(['resetState', data])
  }

  return [state, changeState, resetState]
}

export default useStateReducer
