import React, { PureComponent, Fragment } from 'react'
import SelectionMenu from '@components/SelectionMenu'
import Story from '../Story'

const items = {
  'Foo': [
    { name: 'Alice', id: 1 },
    { name: 'Bob', id: 2 },
    { name: 'Charlie', id: 3 },
    { name: 'Dave', id: 4 },
    { name: 'Evan', id: 5 }
  ],
  'Bar': [
    { name: 'Fred', id: 6 },
    { name: 'Greg', id: 7 },
    { name: 'Heather', id: 8 },
    { name: 'Iris', id: 9 },
    { name: 'Jessica', id: 10 }
  ]
}

const loadMenuOptions = (menuItem, callback) => {
  setTimeout(() => {
    callback(items[menuItem])
  }, 500)
}

class SelectionMenus extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='Selection Menu'>
          <p>
            For regular selection menus you must provide `selectionMenuOptions`,
            an array of objects which have at least a label and value. You can specify which
            key is the label and which key is the value by providing functions to
            `getOptionLabel` and `getOptionValue`. `getOptionLabel` defaults to
            looking for the `text` key, while `getOptionValue` defaults to
            looking for the `value` key. The result from `getOptionLabel` is what
            is displayed to users while `getOptionValue` is what is passed to
            the `onClick` handler.
          </p>
          <SelectionMenu
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            selectionMenuTitle='Select from these options'
            selectionMenuOptions={items}
          />
        </Story>
        <Story title='Async Selection Menu'>
          <p>
            For async seleciton menus you must provide `loadSelectionMenuOptions`,
            a function that takes the `menuItem` just clicked as the first parameter,
            and a `callback` function to pass the loaded items as the second parameter.
            Instead of passing `selectionMenuOptions` you must specify `selectionMenu`,
            an array of objects with labels to be displayed to the user and values that are
            passed as `menuItem` to the provided `loadSelectionMenuOptions` function.
            `getMenuLabel` and `getMenuValue` are to called on each object inside
            `selectionMenu` to get the string displayed to the user, and the value
            passed on as `menuItem`. You can further specify a `loadingMessage`
            to be displayed whlie waiting on the values to be loaded.
          </p>
          <p>
            The `SelectionMenu` component caches loaded values, so that only one
            request is made for each menu item.
          </p>
          <SelectionMenu
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            getMenuLabel={(option) => option.name}
            getMenuValue={(option) => option.id}
            selectionMenuTitle='Select from these options'
            selectionMenu={[{ name: 'Foo', id: 'Foo' }, { name: 'Bar', id: 'Bar' }]}
            loadSelectionMenuOptions={loadMenuOptions}
          />
        </Story>
      </Fragment>
    )
  }
}

SelectionMenus.displayName = 'SelectionMenus'

export default SelectionMenus
