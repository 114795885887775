import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Stamp from '@components/Stamp'
import Text from '@components/Text'
import Alert from '@components/Alert'
import Spacing from '@components/Spacing'
import Select from '@components/Select'
import './ReferralState.scss'
import Button from '@components/Button'
import ReferralStateCancelModal from './ReferralStateCancelModal'
import { toggleKey, onReferralStateChange, onReferralStateUpdate } from '../actions'
import { getRecord, getAssociation } from '@selectors'
import { isCanceled, isInReview } from '@utils/referralStateUtil'

const REFERRAL_STATE_MAPPING = {
  unstarted: 'red',
  in_review: 'orange',
  service_in_progress: 'orange',
  service_completed: 'green',
  canceled: 'grey'
}

class ReferralState extends PureComponent {
  constructor (props) {
    super(props)

    this.props.onReferralStateChange({
      label: this.props.currentState.status,
      value: this.props.currentState.status
    })

    this.toggleEditState = this.toggleEditState.bind(this)
    this.formatOptionLabel = this.formatOptionLabel.bind(this)
  }

  statusOptions () {
    return ['unstarted', 'in_review', 'service_in_progress', 'service_completed', 'canceled'].map(value => ({
      label: value,
      value: value
    }))
  }

  formatOptionLabel (option, { context }) {
    if (context === 'menu') {
      return <Fragment>
        <Spacing marginBottom={0.5}>
          <Text style='body-text' tag='div'>
            {window.local.t(`referrals.show.referral_statuses.${option.label}.name`)}
          </Text>
        </Spacing>
        <Text style='body-text-small' tag='div'>
          {window.local.t(`referrals.show.referral_statuses.${option.label}.description`)}
        </Text>
      </Fragment>
    } else { // currently selected option
      return window.local.t(`referrals.show.referral_statuses.${option.label}.name`)
    }
  }

  toggleEditState (on) {
    return () => {
      this.props.toggleKey('showEditStateView', on)
    }
  }

  referralStateChange (option) {
    if (this.props.checkPrivacyStatus() && this.props.previousConsent === false && !isCanceled(option.value) && !isInReview(option.value)) {
      this.props.toggleKey('showConsentModal', true)
    } else {
      this.props.onReferralStateChange(option)
    }
  }

  renderState () {
    if (this.props.showEditStateView) {
      return (
        <div className='ReferralState__status'>
          <span className='ReferralState__select--text'>
            <Text style='body-text' tag='span'>
              {window.local.t('referrals.show.status.title')}
            </Text>
          </span>
          <span className='ReferralState__select' data-test='referral-state-select'>
            <Select
              formatOptionLabel={this.formatOptionLabel}
              options={this.statusOptions()}
              value={this.props.newState}
              onChange={(option) => this.referralStateChange(option)} />
          </span>
          <Spacing marginLeft={4}>
            <Button
              disabled={this.props.updateButtonDisabled}
              type='tertiary'
              onClick={() => this.props.onReferralStateUpdate(this.props.referralId)}
            >
              {window.local.t('referrals.show.status.update')}
            </Button>
          </Spacing>
          <Spacing marginLeft={2}>
            <Button type='tertiary' onClick={this.toggleEditState(false)}>{window.local.t('referrals.show.status.cancel')}</Button>
          </Spacing>
        </div>
      )
    } else {
      return (
        <div className='ReferralState__status'>
          <Spacing marginLeft={4}>
            <Stamp
              dataTest='referral-state-stamp'
              text={window.local.t(`referrals.show.stamp.${this.props.currentState.status}`)}
              color={REFERRAL_STATE_MAPPING[this.props.currentState.status]}
            />
          </Spacing>
          <Spacing marginLeft={2}>
            {!this.props.pdf &&
              <Button type='tertiary' onClick={this.toggleEditState(true)}>{window.local.t('referrals.show.status.edit')}</Button>
            }
          </Spacing>
          {this.props.updateReferralStateAlert &&
            <Alert
              type={this.props.updateReferralStateAlert.type}
              title={this.props.updateReferralStateAlert.text}
              inline
            />
          }
        </div>
      )
    }
  }

  render () {
    return <Spacing marginBottom={0.5}>
      <div className='ReferralState__container'>
        <Text style='sub-header-large' tag='h1' display='inline'>
          {window.local.t('referrals.overview.header')}
          <a href={this.props.resourceSite.links.self}>
            {this.props.referral.serviceName}
          </a>
        </Text>
        {this.renderState()}
        <ReferralStateCancelModal referralId={this.props.referralId} />
      </div>
    </Spacing>
  }
}

/* eslint-disable react/no-unused-prop-types */
ReferralState.propTypes = {
  currentState: PropTypes.object,
  newState: PropTypes.object,
  onReferralStateChange: PropTypes.func,
  onReferralStateUpdate: PropTypes.func,
  referral: PropTypes.shape({
    serviceName: PropTypes.string
  }),
  resourceSite: PropTypes.object,
  referralId: PropTypes.string,
  showEditStateView: PropTypes.bool.isRequired,
  toggleKey: PropTypes.func,
  updateButtonDisabled: PropTypes.bool.isRequired,
  updateReferralStateAlert: PropTypes.object,
  pdf: PropTypes.bool,
  previousConsent: PropTypes.bool,
  checkPrivacyStatus: PropTypes.func
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const currentState = getAssociation(state, referral, 'currentState')
  const resourceSite = getAssociation(state, referral, 'resourceSite')
  const newState = state.referralView.newState

  return {
    referral,
    currentState,
    resourceSite,
    updateButtonDisabled: !newState || currentState.status === newState.value,
    showEditStateView: state.referralView.showEditStateView,
    showCancelStateModal: state.referralView.showCancelStateModal,
    newState,
    updateReferralStateAlert: state.referralView.alert.updateReferralState,
    previousConsent: state.referralView.previousConsent
  }
}

const mapDispatchToProps = {
  toggleKey,
  onReferralStateChange,
  onReferralStateUpdate
}
export default connect(mapStateToProps, mapDispatchToProps)(ReferralState)
