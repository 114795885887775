import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './ErrorPage.css'
import Logo from '@railsAssets/images/wellsky_primary_logo.svg'

class ErrorPage extends PureComponent {
  render () {
    return <div className='wrapper'>
      <div className='block'>
        <img
          src={Logo}
          className='ErrorPage__logo'
          alt='Healthify Logo'
        />
        <h2 className='hfysg-h2 ErrorPage__header'>{window.local.t('error_page.exception')}</h2>
        <h5 className='hfysg-h5 ErrorPage__header'>{this.props.title}</h5>
        {this.props.listOfSuggestions &&
          <ul className='ErrorPage__list'>
            {this.props.listOfSuggestions.map((suggestion) => {
              return <li>{suggestion}</li>
            })}
          </ul>
        }
      </div>
    </div>
  }
}

ErrorPage.propTypes = {
  title: PropTypes.element.isRequired,
  listOfSuggestions: PropTypes.arrayOf(PropTypes.string)
}

export default ErrorPage
