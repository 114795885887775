import React from 'react'
import PropTypes from 'prop-types'

class Heading extends React.PureComponent {
  conditionallyApplyClass () {
    if (this.props.className) return this.props.className
  }

  render () {
    const { rank, text } = this.props

    const Tag = `h${rank}`

    return (
      <Tag className={`hfysg-h${rank} ${this.conditionallyApplyClass()}`}>
        {text}
      </Tag>
    )
  }
}

Heading.propTypes = {
  rank: PropTypes.oneOf([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6'
  ]).isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Heading
