import React, { PureComponent } from 'react'
import emptyCard from '@railsAssets/../views/svg/_checkout_empty_card.svg'
import './EmptyCart.scss'

export default class EmptyCart extends PureComponent {
  render () {
    return (
      <section className='EmptyCart__content'>
        <div>
          <img src={emptyCard} alt={window.local.t('checkout.empty.image')} />
          <h3 className='EmptyCart__title'>
            {window.local.t('checkout.empty.title')}
          </h3>
          <p className='EmptyCart__message'>
            {window.local.t('checkout.empty.message')}
          </p>
          <a
            href='resource_sites/overview'
            className='button button-secondary drawer-empty-notice__content__button EmptyCart__content__button'>
            {window.local.t('checkout.empty.button')}
          </a>
        </div>
      </section>
    )
  }
}
