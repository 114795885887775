import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Button from '@components/Button'
import Modal from '@components/Modal'
import Spacing from '@components/Spacing'
import TierFilterOption from '@components/SearchFilters/components/TierFilterOption'
import networkFilterOptions, { queryParamToStateKey } from '@scenes/resourceSites/config/searchFilterOptions'
import { ALL_NETWORK_TIER_STATE_KEYS } from '@scenes/resourceSites/constants'
import { setFormValue } from '@actions'
import { submitSearchForm } from '@scenes/resourceSites/actions'
import ModalContentSeparator from '@components/Modal/ContentSeparator'

import './MobileSearchFilterModal.scss'

const MobileSearchFilterModal = (props) => {
  const [selectedFilter, updateSelectedFilterTo] = useState(props.appliedFilter)

  const { contractedNetwork, teamUsesCoordinate } = props
  const content = (
    <section>
      <ModalContentSeparator />

      <Spacing marginBottom={2}>
        <header>{window.local.t('search_filters.tier_filters.dropdown.context')}</header>
      </Spacing>

      {networkFilterOptions(window, { contractedNetwork, teamUsesCoordinate }).map(option => (
        <TierFilterOption
          currentSelection={selectedFilter}
          key={option.name}
          onChange={(event) => { updateSelectedFilterTo(event.target.name) }}
          {...option}
        />
      ))}
    </section>
  )

  const { onClose, history, setFormValue, submitSearchForm } = props

  const turnOffAllTierFilters = () => {
    ALL_NETWORK_TIER_STATE_KEYS.map(formKey => {
      setFormValue({ namespace: 'resourceSites', form: 'search', key: formKey, value: 'off' })
    })
  }

  const onSubmit = () => {
    if (selectedFilter) {
      turnOffAllTierFilters()

      setFormValue({
        namespace: 'resourceSites',
        form: 'search',
        key: queryParamToStateKey(selectedFilter),
        value: 'on'
      })
    }

    onClose()
    submitSearchForm({ history })
  }

  const onClear = () => {
    turnOffAllTierFilters()
    onClose()
    submitSearchForm({ history })
  }

  const actions = (
    <section className='MobileSearchFilterModal__Actions-Container'>
      <Button type='tertiary' disabled={!selectedFilter} onClick={onClear}>Clear filter</Button>
      <Button type='tertiary' disabled={!selectedFilter} onClick={onSubmit}>Apply filter</Button>
    </section>
  )

  return (
    <Modal
      actions={actions}
      content={content}
      dataTest='MobileSearchFilterModal'
      headerText={window.local.t('search_filters.title')}
      onClose={onClose}
      onSubmit={onSubmit}
      open={props.open}
    />
  )
}

MobileSearchFilterModal.propTypes = {
  contractedNetwork: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setFormValue: PropTypes.func.isRequired,
  submitSearchForm: PropTypes.func.isRequired,
  teamUsesCoordinate: PropTypes.bool.isRequired,
  appliedFilter: PropTypes.string
}

MobileSearchFilterModal.defaultProps = {
  appliedFilter: null,
  contractedNetwork: false,
  open: false
}

const mapDispatchToProps = {
  setFormValue,
  submitSearchForm
}

export default connect(null, mapDispatchToProps)(withRouter(MobileSearchFilterModal))
