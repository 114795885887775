import { useEffect } from 'react'

const useOutsideClick = (ref, callback, propsToWatch = []) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    const onComponentUnmountEffect = () => {
      document.removeEventListener('click', handleClick)
    }

    return onComponentUnmountEffect
  }, propsToWatch)
}

export default useOutsideClick
