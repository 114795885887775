import { sortBy } from 'lodash'

export const sortNeeds = needs => (needs ? sortBy(needs, ['updatedAt']) : [])

export const serializeNeed = ({
  patientId,
  status
}) => {
  const params = {
    patient_need: {
      status
    },
    patient_id: patientId
  }

  return params
}
