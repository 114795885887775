import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Banner.css'

class Banner extends Component {
  render () {
    const { text, width, color } = this.props

    return (
      <div className={`Banner Banner--${color}`} style={{ width: width }}>
        {text}
      </div>
    )
  }
}

Banner.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'blue',
    'blue-05'
  ]).isRequired
}

export default Banner
