import css from '@config/theme/variables'

export const menuHeight = '210'

const displayNoneStyle = (base) => ({
  ...base,
  display: 'none'
})

export const styles = ({ errors, showDropdownIndicator, hideClearIndicator }) => ({
  control: (base) => ({
    ...base,
    borderRadius: '3px',
    backgroundColor: css.lightGray,
    borderColor: errors.length ? css.red : css.boxBorderGray
  }),
  input: (base) => ({
    ...base,
    color: css.textGray
  }),
  singleValue: (base) => ({
    ...base,
    color: css.textGray
  }),
  multiValue: (base) => ({
    ...base,
    padding: '0',
    backgroundColor: css.gray.fill,
    borderRadius: css.borderRadius,
    fontSize: '12px',
    color: css.textGray
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: '0',
    lineHeight: '2.1'
  }),
  multiValueRemove: (base) => ({
    ...base,
    fill: 'rgba(82, 93, 111, 0.66)'
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'inherit'
  }),
  clearIndicator: (base) => ({
    ...base,
    display: hideClearIndicator ? 'none' : 'flex'
  }),
  indicatorSeparator: displayNoneStyle,
  dropdownIndicator: (base) => ({
    ...base,
    display: showDropdownIndicator ? 'flex' : 'none'
  }),
  placeholder: (base) => ({
    ...base,
    color: css.gray.main
  })
})
