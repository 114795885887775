import { getRecord } from '@selectors'
import get from 'lodash/get'

const selectSearchMetadata = (state, resourceSiteId) => {
  const searchData = getRecord(state, 'resource_site_search_results', 'resource_site_search_results')
  const resultIDs = get(searchData, 'orderedIds', [])
  const { search: resourceSiteSearch, paginationData } = get(state, 'resourceSites', {})
  // Add 1 from the result index to convert it to a one base for rank calculation
  const resourceSiteCardPageRank = resultIDs.indexOf(resourceSiteId) + 1
  // Subtract 1 from the current page to convert it to a zero base for rank calculation
  // i.e., Page 1, Result 1 will be (0 * perPageCount) + (0 + 1) = 1
  const searchResultsPage = parseInt(get(resourceSiteSearch, 'page', {}).value) - 1
  const resultsPerPageCount = get(paginationData, 'perCount', 10)
  const compositeSearchResultRanking = (searchResultsPage * resultsPerPageCount) + resourceSiteCardPageRank

  const searchInputTerm = get(resourceSiteSearch, 'inputValue', {}).value
  const selectedProgramIds = get(resourceSiteSearch, 'selectedProgramIds', {}).value

  return [
    compositeSearchResultRanking,
    searchInputTerm,
    selectedProgramIds
  ]
}

export const selectSearchMetadataObject = (state, resourceSiteId) => {
  const [ranking, term, selectedProgramIds] = selectSearchMetadata(state, resourceSiteId)

  return {
    'search_metadata': {
      ranking,
      selected_resource_programs: selectedProgramIds,
      term
    }
  }
}

export const selectSearchMetadataFormParams = (state, resourceSiteId) => {
  const [ranking, term, selectedProgramIds] = selectSearchMetadata(state, resourceSiteId)

  return {
    'search_metadata[ranking]': ranking,
    'search_metadata[selected_resource_programs][]': selectedProgramIds,
    'search_metadata[term]': term
  }
}
