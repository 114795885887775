import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Text from '@components/Text'

import { formatDate, formatList, formatName, formatPhoneNumber } from '@utils/formatterUtil'

import SmallModule from '@components/SmallModule'
import './ReferralPatientPanel.scss'

const ReferralPatientPanel = ({ patient, referral }) => {
  let resourceProgramNames = referral.referralResourceProgramAssignments.map((resourceProgramAssignment) => {
    return resourceProgramAssignment.resourceProgramName
  })
  resourceProgramNames = formatList({ elements: resourceProgramNames })

  let phone = patient.phones[0]
  phone = phone && formatPhoneNumber(phone)

  const zipcode = patient.address && patient.address.zipcode

  return (
    <Fragment>
      <div className='ReferralPatientPanel__SmallModule-container'>
        <SmallModule
          title='Referral Details'>
          <div className='SmallModule__section'>
            <h6 className='hfysg-h6 SmallModule__section-header'>Client Name</h6>
            <Text>
              {formatName(patient)}
            </Text>
          </div>

          <div className='SmallModule__section'>
            <h6 className='hfysg-h6 SmallModule__section-header'>Client Date of Birth</h6>
            {formatDate(patient.dateOfBirth)}
          </div>

          <div className='SmallModule__section'>
            <h6 className='hfysg-h6 SmallModule__section-header'>Client Zip Code</h6>
            {zipcode || 'N/A'}
          </div>

          <div className='SmallModule__section'>
            <h6 className='hfysg-h6 SmallModule__section-header'>Client Phone Number</h6>
            {phone || 'N/A'}
          </div>

          <div className='SmallModule__section'>
            <h6 className='hfysg-h6 SmallModule__section-header'>Client Email Address</h6>
            {patient.email || 'N/A'}
          </div>

          <div className='SmallModule__section'>
            <h6 className='hfysg-h6 SmallModule__section-header'>Services Requested</h6>
            {resourceProgramNames}
          </div>
        </SmallModule>
      </div>
    </Fragment>
  )
}

ReferralPatientPanel.propTypes = {
  patient: PropTypes.object.isRequired,
  referral: PropTypes.object.isRequired
}

export default ReferralPatientPanel
