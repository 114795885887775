import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getRecord } from '@selectors'
import IntakeQuestionField from './IntakeQuestionField'

const IntakeQuestionsForm = (props) => {
  const formState = props.modalState.followupForm.intakeQuestions
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const newState = { ...formState }

    props.intakeQuestions.map(intakeQuestion => {
      newState[intakeQuestion.id] = {
        required: intakeQuestion.required,
        value: null
      }
    })

    props.handleChange({ intakeQuestions: newState })
    setIsLoading(false)
  }, props.intakeQuestions)

  const handleAnswerChange = ({ questionID, newValue }) => {
    const valueObject = formState[questionID]

    props.handleChange({
      intakeQuestions: {
        ...formState,
        [questionID]: { ...valueObject, ...newValue }
      }
    })
  }

  if (isLoading) return null

  return (
    <div>
      <ul>
        {props.intakeQuestions.map(intakeQuestion => (
          <li key={`intake-question-${intakeQuestion.id}`}>
            <IntakeQuestionField
              fieldState={formState[intakeQuestion.id]}
              handleChange={handleAnswerChange}
              intakeQuestion={intakeQuestion}
            />
          </li>
        ))}
      </ul>

      {props.intakeQuestionSet.staticText && <p>{props.intakeQuestionSet.staticText}</p>}
    </div>
  )
}

IntakeQuestionsForm.propTypes = {
  intakeQuestionSet: PropTypes.object.isRequired,
  modalState: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  intakeQuestions: PropTypes.array
}

IntakeQuestionsForm.defaultProps = {
  handleChange: () => {},
  intakeQuestions: []
}

const mapStateToProps = (state, props) => {
  const enabledQuestionsInOrder = props.intakeQuestionSet.questionOrder
    .map(questionID => getRecord(state, 'intake_question', questionID))
    .filter(question => question.enabled)

  return {
    intakeQuestions: enabledQuestionsInOrder
  }
}

export default connect(mapStateToProps)(IntakeQuestionsForm)
