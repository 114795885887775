import { useMemo } from 'react'

import useFetchData from '@hooks/useFetchData'
import resourceSiteCoverageAreasService from '@services/resourceSiteCoverageAreasService'

const DEFAULT_INCLUDED = []

export default function useFetchResourceSiteCoverageAreaData (resourceSiteId, included) {
  const fetchDataOptions = {
    included: included || DEFAULT_INCLUDED
  }
  const apiResource = resourceSiteCoverageAreasService.getResource(resourceSiteId)
  // load client data
  const { loading, data } = useFetchData(
    () => apiResource.index(fetchDataOptions),
    [resourceSiteId],
    []
  )

  return useMemo(() => ({ loading, data }), [loading, data.id])
}
