import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Spacing from '@components/Spacing'
import Text from '@components/Text'
import StampWithIconAndToolTip from '@components/StampWithIconAndToolTip'
import emptyFavoritesPNG from '@railsAssets/images/empty-favorites-cta.png'
import { formatPhoneNumber } from '@utils/formatterUtil'
import { getCollectionFromRelationship, denormalizeRecords } from '@selectors'
import { loadFavorites } from '@scenes/resourceSites/actions'

import './FavoriteResourceSites.scss'

class FavoriteResourceSites extends React.PureComponent {
  componentDidMount () {
    this.props.loadFavorites()
  }

  get favoritesPresent () {
    return this.props.favorites.length > 0
  }

  renderOrganizationName (organizationName) {
    return organizationName && (
      <Spacing marginBottom={1}>
        <Text tag='h4' style='sub-header-extra-small'>{organizationName}</Text>
      </Spacing>
    )
  }

  renderResourceSitePhone (phone) {
    return phone && (
      <Spacing marginTop={3}>
        <section>
          <a href={`tel:${phone.number}`}>
            {formatPhoneNumber(phone)}
          </a>
        </section>
      </Spacing>
    )
  }

  renderPrivayStamp () {
    return (
      <Spacing marginTop={2} marginBottom={1}>
        <StampWithIconAndToolTip
          text={window.local.t('resource_sites.search_overview.privacy')}
          color='orange'
          iconKey='info'
          iconSize={12}
          tooltipContent={window.local.t('resource_sites.privacy_tooltip')}
          width='100px' />
      </Spacing>
    )
  }

  renderFavoriteItem ({ id, organizationName, serviceName, primaryPhone, inUserNetwork, links, privacyStatus }) {
    const usesCoordinate = this.props.usesCoordinate

    return (
      <Spacing key={`favorite-${id}`} marginBottom={3}>
        <li aria-label={`Favorite record for: ${serviceName}`} className='FavoriteResourceSites__Favorite__Container'>
          <Spacing padding={3}>
            <header>
              {this.renderOrganizationName(organizationName)}
              <Text tag='h3'>
                <a href={links.self}>
                  {inUserNetwork &&
                    <Spacing tag='span' marginRight={1}>
                      <i className='material-icons mi--16 notranslate' style={{ verticalAlign: 'text-bottom' }}>wifi_tethering</i>
                    </Spacing>}
                  {serviceName}
                </a>
              </Text>
              {(privacyStatus === 'private' && usesCoordinate) && this.renderPrivayStamp()}
            </header>
            {this.renderResourceSitePhone(primaryPhone)}
          </Spacing>
        </li>
      </Spacing>
    )
  }

  renderFavoritesList () {
    return (
      <ul>
        {this.props.favorites.map(item => this.renderFavoriteItem(item.resourceSite))}
      </ul>
    )
  }

  render () {
    return (
      <section className='FavoriteResourceSites__Favorites__Container'>
        <Spacing margin={8}>
          <Spacing marginBottom={4}>
            <header className='FavoriteResourceSites__Title'>
              <Text tag='h2' style='sub-header-medium'>
                {window.local.t('resource_sites.search_overview.favorites')}
              </Text>
            </header>
          </Spacing>
          {this.favoritesPresent
            ? this.renderFavoritesList()
            : <NoFavorites />}
        </Spacing>
      </section>
    )
  }
}

const NoFavorites = () => (
  <section className='FavoriteResourceSites__NoFavorites'>
    <Spacing marginBottom={4}>
      <img
        alt={window.local.t('resource_sites.search_overview.favorites_placeholder_img_alt_text')}
        src={emptyFavoritesPNG}
        width='300px'
      />
    </Spacing>

    <Text style='body-text' tag='p'>
      {window.local.t('resource_sites.search_overview.favorites_empty')}
    </Text>
  </section>
)

FavoriteResourceSites.propTypes = {
  /* eslint-disable-next-line react/no-unused-prop-types */
  userID: PropTypes.string.isRequired,
  favorites: PropTypes.array.isRequired,
  loadFavorites: PropTypes.func.isRequired,
  usesCoordinate: PropTypes.bool.isRequired
}

FavoriteResourceSites.defaultProps = {
  favorites: []
}

const mapDispatchToProps = {
  loadFavorites
}

const mapStateToProps = (state, props) => ({
  favorites: denormalizeRecords(state, getCollectionFromRelationship({
    state,
    type: 'favorite',
    association: 'user',
    id: props.userID
  }))
})

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteResourceSites)
