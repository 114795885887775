import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  PRIVATE,
  NOT_PRIVATE
} from '@constants/privacyStatus'

import ResourceSitePrivacyStatusForm from './ResourceSitePrivacyStatusForm'

const ResourceSitePrivacyStatusModal = ({ onSuccess, handleClose, open, confirmText, privacyStatus }) => {
  const { local } = window

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ textAlign: 'center' }}>
        {local.t(`resource_site.privacy_status.${privacyStatus}_confirmation_text`)}
      </DialogTitle>

      <DialogContent>
        <ResourceSitePrivacyStatusForm
          onSuccess={onSuccess}
          onClose={handleClose}
          confirmText={confirmText}
        />
      </DialogContent>
    </Dialog>
  )
}

ResourceSitePrivacyStatusModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  confirmText: PropTypes.string.isRequired,
  privacyStatus: PropTypes.oneOf([PRIVATE, NOT_PRIVATE])
}

export default ResourceSitePrivacyStatusModal
