import React, { Fragment } from 'react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'

import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import TextInput from '@components/TextInput/ReactFinalFormTextInput'
import Button from '@components/Button'
import Label from '@components/Label'
import Alert from '@components/Alert'
import { validateZipCode, validatePhone } from '@utils/contactInfoUtils'
import surveyUtils from './surveyUtils'

const SurveyContactForm = ({ states, textPreferencesRequired, requiresIdentifiers, identifiers, screeningClientSlug }) => {
  const { local } = window
  const genders = ['female', 'male', 'trans_female', 'trans_male', 'non_binary', 'not_listed', 'no_answer']
  const initialValues = { gender: 'no_answer' }
  const identifierElements = []

  const onSubmit = async values => {
    try {
      await surveyUtils.submit(identifiers, requiresIdentifiers, screeningClientSlug, values)
    } catch (error) {
      return { [FORM_ERROR]: error.message }
    }
  }

  if (requiresIdentifiers) {
    initialValues['identifier'] = {}
    identifiers.forEach((identifier, index) => {
      const identifierValues = {}

      const identifierValueName = `identifier.identifier${index + 1}.value`
      const identifierEffectiveDateName = `identifier.identifier${index + 1}.effective_date`
      const identifierType = local.t(`identifier_types.${identifier.identifier_type}`)

      identifierValues['id'] = identifier.id
      identifierValues['identifier_type'] = identifierType
      identifierValues['value'] = identifier.value
      identifierValues['effective_date'] = identifier.effective_date

      initialValues['identifier'][`identifier${index + 1}`] = identifierValues

      identifierElements.push(<Grid item xs={6} key={identifierValueName}>
        <Label id={identifierValueName} text={identifierType} />
        <Field name={identifierValueName} component={TextInput} />
      </Grid>)
      identifierElements.push(<Grid item xs={6} key={identifierEffectiveDateName}>
        <Label id={identifierEffectiveDateName} text={`${identifierType} ${local.t('effective_date')}`} />
        <Field name={identifierEffectiveDateName}>
          {({ input, meta }) => surveyUtils.renderDatePicker(input, meta)}
        </Field>
      </Grid>)
    })
  }

  return (
    <Fragment>
      <header className='page-hero page-hero--short page-hero--grey font-sfd'>
        <div className='page-container--small page-container--block page-hero-content page-hero__content--small'>
          <h1 className='page-hero__title'>{ local.t('surveys.new.title') }</h1>
          <p className='page-hero__context font-sfd--light'>{ local.t('surveys.new.context') }</p>
        </div>
      </header>

      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ submitError, handleSubmit, submitting }) => (
          <form className='page-container--small page-container--block' onSubmit={handleSubmit}>
            <fieldset className='orbital-form__fieldset'>
              <legend className='orbital-form__section-title font-sft'>{ local.t('surveys.new.sections.personal_details') }</legend>
              <Grid container spacing={4} justify='space-between'>
                { submitError &&
                  <Grid item xs={12} data-testid='errors-grid'>
                    <Alert type='error' title={submitError} inline />
                  </Grid>
                }

                <Grid item xs={6}>
                  <Label id='first_name' text={local.t('surveys.new.first_name')} required />
                  <Field component={TextInput} name='first_name' validate={surveyUtils.notBlankValidation}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Label id='last_name' text={local.t('surveys.new.last_name')} required />
                  <Field component={TextInput} name='last_name' validate={surveyUtils.notBlankValidation}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Label id='date_of_birth' text={local.t('surveys.new.date_of_birth')} required />
                  <Field name='date_of_birth' validate={surveyUtils.notBlankValidation}>
                    {({ input, meta }) => surveyUtils.renderDatePicker(input, meta, new Date())}
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={4} justify='space-between'>
                <Grid item xs={6}>
                  <Label id='gender' text={local.t('surveys.new.gender')} />
                  <Field className='orbital-form__single-field select__field' name='gender' component='select'>
                    { genders.map((gender) => <option key={gender} value={gender}>{local.t(`gender.options.${gender}`)}</option>) }
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Label id='zip_code' text={local.t('surveys.new.zip_code')} required />
                  <Field name='zip_code' component={TextInput} validate={(value) => validateZipCode(value, true)} />
                </Grid>
              </Grid>
            </fieldset>
            { requiresIdentifiers &&
              <fieldset className='orbital-form__fieldset'>
                <legend className='orbital-form__section-title orbital-form__section-title-header font-sft'>{ local.t('surveys.new.sections.client_id.header') }</legend>
                <p className='page-hero__context font-sfd--light'>{ local.t('surveys.new.sections.client_id.context') }</p>
                <Grid container spacing={4} justify='space-between'>{identifierElements}</Grid>
              </fieldset>}
            <fieldset className='orbital-form__fieldset'>
              <legend className='orbital-form__section-title font-sft'>{ local.t('surveys.new.sections.contact_information') }</legend>
              <Grid container spacing={4} justify='space-between'>
                <Grid item xs={6}>
                  <Label id='phone' text={local.t('surveys.new.phone_number')} />
                  <Field name='phone' component={TextInput} placeholder='Example: 555-555-5555' validate={(value) => validatePhone(value, false)} />
                </Grid>
              </Grid>
              { textPreferencesRequired && <Fragment>
                <Grid container spacing={4} justify='space-between'>
                  <Grid item xs={12}>
                    <Label id='accepts_texts' text={local.t('phone.accepts_texts.prompt')} />
                  </Grid>
                </Grid>
                <Grid container spacing={4} justify='flex-start'>
                  <Grid item xs='auto'>
                    <Field
                      name='accepts_texts'
                      component='input'
                      type='radio'
                      value='true'
                      className='display-inline'
                      id='yes'
                    />
                    <Label id='accepts_texts_positive' text={local.t('accepts_texts.positive')} display='inline-block' />
                  </Grid>
                  <Grid item xs='auto'>
                    <Field
                      name='accepts_texts'
                      component='input'
                      type='radio'
                      value='false'
                      className='display-inline'
                      id='no'
                    />
                    <Label id='accepts_texts_negative' text={local.t('accepts_texts.negative')} display='inline-block' />
                  </Grid>
                </Grid>
              </Fragment>}

              <Grid container justify='flex-end'>
                <Grid item xs='auto'>
                  <Button
                    data-test={`survy-contact-from-submit`}
                    children={local.t('continue')}
                    disabled={submitting}
                    htmlType='submit'
                    type='primaryDark'
                  />
                </Grid>
              </Grid>
            </fieldset>
          </form>

        )}
      />
    </Fragment>
  )
}

SurveyContactForm.defaultProps = {
  textPreferencesRequired: false,
  requiresIdentifiers: false
}

SurveyContactForm.propTypes = {
  states: PropTypes.array,
  textPreferencesRequired: PropTypes.bool,
  requiresIdentifiers: PropTypes.bool,
  identifiers: PropTypes.array,
  screeningClientSlug: PropTypes.string
}

export default SurveyContactForm
