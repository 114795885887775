import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'

import Text from '@components/Text'
import Icon from '@components/Icon'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '1rem',
    padding: 0
  },
  closeButton: {
    margin: '-12px' // offsets padding from MUI IconButton
  }
}

const ModalHeader = props => (
  <DialogTitle disableTypography className={props.classes.root}>
    <IconButton
      aria-label='Close Modal'
      onClick={props.onClose}
      className={props.classes.closeButton}
      data-testid='ModalHeader__CloseModalButton'
    >
      <Icon iconKey='close' size={20} />
    </IconButton>

    {props.children && <Text style='sub-header-medium' tag='h3'>{props.children}</Text>}
  </DialogTitle>
)

ModalHeader.propTypes = {
  classes: PropTypes.object.isRequired, // Handled by MUI `withStyles`
  onClose: PropTypes.func.isRequired,

  children: PropTypes.string
}

export default withStyles(styles)(ModalHeader)
