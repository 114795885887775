import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import PatientSelector from '@components/PatientSelector'
import SmallModule from '@components/SmallModule'
import mergeService from '@scenes/clients/ClientMerge/services/mergeService'
import { Column, Row } from '@components/Layout'
import { formatDate } from '@utils/formatterUtil'
import { legacyFind as legacyFindClient } from '@services/clientsService'

class SelectSyncer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      firstLoading: false,
      secondLoading: false
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.processClientIds = this.processClientIds.bind(this)
    this.validateClients = this.validateClients.bind(this)
    this.handleValidationErrors = this.handleValidationErrors.bind(this)
    this.filterClients = this.filterClients.bind(this)
  }

  handleValidationErrors (errors) {
    const { firstPatient, secondPatient, addClientSelectError } = this.props

    const firstErrors = errors[firstPatient.id]
    const secondErrors = errors[secondPatient.id]

    firstErrors.forEach((error) => {
      addClientSelectError(error, 'first')
    })

    secondErrors.forEach((error) => {
      addClientSelectError(error, 'second')
    })
  }

  validateClients () {
    this.props.setSelectionValidationReadyState(false)

    const { firstPatient, secondPatient } = this.props
    if (!firstPatient || !secondPatient) return Promise.resolve(false)

    return mergeService.validate(
      [firstPatient.id, secondPatient.id],
      this.handleValidationErrors
    )
  }

  handleOnChange (direction) {
    return (selection, event) => {
      switch (event.action) {
        case 'select-option':
          this.setState({ [`${direction}Loading`]: true })
          legacyFindClient(selection.value, { included: ['address', 'identifiers', 'phones'] })
            .then((client) => {
              this.setState({ [`${direction}Loading`]: false })
              this.props.addClient(client, direction)
              this.validateClients().then(this.props.setSelectionValidationReadyState)
            })
          break
        case 'clear':
          this.props.removeClientSelection(direction)
          break
      }
    }
  }

  filterClients (query, clients) {
    const patientAlreadySelected = (queriedPatient) => {
      const { firstPatient, secondPatient } = this.props

      if (firstPatient && (queriedPatient.value === firstPatient.id)) return true
      if (secondPatient && (queriedPatient.value === secondPatient.id)) return true

      return false
    }

    return clients.filter((client) => {
      const normalizedQuery = query.toLowerCase()
      const hasTextMatch = client.text.toLowerCase().includes(normalizedQuery)
      const hasIdentifierMatch = client
        .all_identifier_values
        .find(id => id.toLowerCase().includes(normalizedQuery))
      const hasQueryMatch = hasTextMatch || hasIdentifierMatch

      return hasQueryMatch && !patientAlreadySelected(client)
    })
  }

  clientPreview (direction) {
    const directionKey = `${direction}Patient`
    const clientSelection = this.props[directionKey]

    if (this.state[`${direction}Loading`]) {
      return (<CircularProgress />)
    }

    if (clientSelection && !this.state[`${direction}Loading`]) {
      return (
        <div style={{ marginTop: '36px' }}>
          <SmallModule
            title={clientSelection.fullNameBackwards}
            subtitle={window.local.t('client_merge.client_selection.client_preview.profile_link')}
            subtitleLink={`/clients/${clientSelection.value}`}
          >
            <div className='SmallModule__section'>
              <h6 className='hfysg-h6 SmallModule__section-header'>
                {window.local.t('client_merge.client_selection.client_preview.client_ids')}
              </h6>

              {this.processClientIds(clientSelection.identifiers)}
            </div>

            <div className='SmallModule__section'>
              <h6 className='hfysg-h6 SmallModule__section-header'>
                {window.local.t('client_merge.client_selection.client_preview.date_created')}
              </h6>

              <div>{formatDate(clientSelection.dateCreated)}</div>
            </div>
          </SmallModule>
        </div>
      )
    }
  }

  processClientIds (clientIds) {
    if (clientIds.length === 0) {
      return 'N/A'
    } else {
      return clientIds.map((id, i) => <div key={i}>{id.displayValue}</div>)
    }
  }

  render () {
    const local = window.local

    return (
      <Row>
        <Column span='5'>
          <PatientSelector
            dataTest='client-1'
            defaultValue={this.props.firstPatient}
            errors={this.props.errors.firstPatient}
            filterClients={this.filterClients}
            label={local.t('client_merge.client_selection.client_1')}
            onChange={this.handleOnChange('first')}
            showErrorMessages
          />

          {this.clientPreview('first')}

        </Column>

        <Column span='5'>
          <PatientSelector
            dataTest='client-2'
            defaultValue={this.props.secondPatient}
            errors={this.props.errors.secondPatient}
            filterClients={this.filterClients}
            label={local.t('client_merge.client_selection.client_2')}
            onChange={this.handleOnChange('second')}
            showErrorMessages
          />

          {this.clientPreview('second')}

        </Column>
      </Row>
    )
  }
}

SelectSyncer.propTypes = {
  addClient: PropTypes.func,
  addClientSelectError: PropTypes.func,
  errors: PropTypes.object,
  firstPatient: PropTypes.object,
  removeClientSelection: PropTypes.func,
  secondPatient: PropTypes.object,
  setSelectionValidationReadyState: PropTypes.func.isRequired
}

export default SelectSyncer
