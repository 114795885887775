import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '@components/Button'
import Modal from '@components/Modal'
import Grid from '@material-ui/core/Grid'
import Alert from '@components/Alert'
import './DeleteAttachmentModal.scss'

export default class DeleteAttachmentModal extends PureComponent {
  renderModalContent () {
    const { name } = this.props.attachment
    const { local } = window

    return (
      <p className='DeleteAttachmentModal__copy'>
        {local.t('attachments.delete_attachment.copy_start')}
        <span className='b-text'>{name}</span>
        {local.t('attachments.delete_attachment.copy_end')}
      </p>
    )
  }

  renderModalActions () {
    const { onClose, onDelete } = this.props

    return (
      <Grid container spacing={4} justify='space-between'>
        <Grid item xs={6}>
          <Button type='secondary' onClick={onClose}>
            {window.local.t('attachments.delete_attachment.cancel')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type='secondary' onClick={onDelete}>
            {window.local.t('attachments.delete_attachment.delete')}
          </Button>
        </Grid>
        {this.props.error &&
          <Grid item xs={12}>
            <Alert type='error' title={this.props.error} inline />
          </Grid>
        }
      </Grid>
    )
  }

  render () {
    const { open, onClose } = this.props
    const { local } = window

    if (!open) {
      return null
    }

    return (
      <Modal
        open={open}
        onClose={onClose}
        headerText={local.t('attachments.delete_attachment.header')}
        content={this.renderModalContent()}
        actions={this.renderModalActions()}
        dataBehavior='DeleteAttachmentModal'
      />
    )
  }
}

DeleteAttachmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired
}
