import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import some from 'lodash/some'

import useFetchData from '@hooks/useFetchData'
import clientsService from '@services/clientsService'
import resourceSiteSearchService from '@services/resourceSiteSearchService'

const DEFAULT_INCLUDED = [
  'address',
  'assigned_users',
  'assigned_users.team',
  'patient_goals',
  'patient_goals.filter_categories',
  'patient_goals.notes',
  'patient_goals.patient',
  'patient_goals.user',
  'identifiers',
  'interventions',
  'patient_needs',
  'patient_needs.filter_category',
  'phones',
  'populations'
]

export default function useFetchClientData (clientId, included) {
  const fetchDataClientId = clientId || useParams().clientId
  const fetchDataOptions = {
    included: included || DEFAULT_INCLUDED
  }
  // load client data
  const { loading: loadingClientData } = useFetchData(
    () => clientsService.show(fetchDataClientId, fetchDataOptions)
  )
  // load search options
  const { loading: loadingSearchOptions } = useFetchData(
    () => resourceSiteSearchService.getSearchOptions()
  )

  const loading = some([loadingClientData, loadingSearchOptions], Boolean)

  return useMemo(() => ({ loading }), [loading])
}
