import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import TextInput from '@components/TextInput'
import PhoneInput from '@components/PhoneInput'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import RadioButton from '@components/RadioButton'

const ContactPreferences = (props) => {
  const { setContactPreference, fullScreen, setEmail, setPhoneNumber } = props
  const grid = {
    direction: fullScreen ? 'column' : 'row',
    spacing: fullScreen ? 1 : 2
  }

  return <>
    <Spacing tag='div' marginBottom={1}>
      <Text tag='span' style='form-fields'>
        Preferred Method of Contact
      </Text>

      <Spacing tag='span' paddingLeft={0.5}>
        <Text style='error-message' display='inline'>*</Text>
      </Spacing>
    </Spacing>

    <Grid container direction={grid.direction} spacing={grid.spacing}>
      <Grid item>
        <RadioButton
          label='Email'
          name='contactPreference'
          value='email'
          onChange={setContactPreference}
        />
      </Grid>

      <Grid item>
        <RadioButton
          label='Text Message'
          name='contactPreference'
          value='text_message'
          onChange={setContactPreference}
        />
      </Grid>

      <Grid item>
        <RadioButton
          label='Phone Call'
          name='contactPreference'
          value='phone_call'
          onChange={setContactPreference}
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput label='Email Address' type='email' onChange={setEmail} />
      </Grid>

      <Grid item xs={12}>
        <PhoneInput label='Phone Number' hint={null} onChange={setPhoneNumber} />
      </Grid>
    </Grid>
  </>
}

ContactPreferences.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  setContactPreference: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired
}

export default ContactPreferences
