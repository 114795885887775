import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Select from '@components/Select'
import { format } from '@utils/selectUtil'

const LanguageSelect = (props) => {
  const { defaultValue, label, name, onChange, languages, ...passThruProps } = props

  return (
    <Select
      defaultValue={defaultValue}
      label={label}
      name={name}
      onChange={onChange}
      options={format.languages(languages)}
      showDropdownIndicator
      {...passThruProps}
    />
  )
}

LanguageSelect.propTypes = {
  label: PropTypes.string.isRequired,
  languages: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func
}

LanguageSelect.defaultProps = {
  defaultValue: { label: 'English', value: 'en' },
  languages: {}
}

const mapStateToProps = state => {
  const languages = state.global.languages

  return { languages }
}

export default connect(mapStateToProps)(LanguageSelect)
