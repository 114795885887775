import { useMemo } from 'react'

import getPropCollections from './propCollections'

export default function useCoverageAreaManager (resourceSiteId) {
  const propCollections = getPropCollections(resourceSiteId)

  const value = useMemo(() => ({
    resourceSiteId,
    ...propCollections
  }), [resourceSiteId])

  return value
}
