import React, { Component, Fragment } from 'react'
import attachmentService from '@services/attachmentService'
import DeleteAttachmentModal from '@components/DeleteAttachment/DeleteAttachmentModal/DeleteAttachmentModal'
import AttachmentDeletedModal from '@components/DeleteAttachment/AttachmentDeletedModal/AttachmentDeletedModal'

const SUCCESS_STATUS = 200

export default class DeleteAttachmentApp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showDeleteModal: false,
      showSuccessModal: false,
      attachmentId: null,
      attachmentName: null
    }

    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.hideDeleteModal = this.hideDeleteModal.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    const deleteButtons = document.querySelectorAll('[data-behavior="delete-attachment"]')

    deleteButtons.forEach(button => {
      button.addEventListener('click', this.handleButtonClick())
    })
  }

  componentWillUnmount () {
    const deleteButtons = document.querySelectorAll('[data-behavior="delete-attachment"]')

    deleteButtons.forEach(button => {
      button.removeEventListener('click', this.handleButtonClick())
    })
  }

  handleButtonClick () {
    function showDeleteModal () {
      const { attachmentId, attachmentName } = this.dataset

      setStateOnClick(attachmentId, attachmentName)
    }

    const setStateOnClick = (id, name) => {
      this.setState({
        showDeleteModal: true,
        attachmentId: id,
        attachmentName: name
      })
    }

    return showDeleteModal
  }

  hideDeleteModal () {
    this.setState({
      showDeleteModal: false,
      attachmentId: null
    })
  }

  handleDelete (e) {
    const { attachmentId } = this.state

    e.preventDefault()

    attachmentService.destroy(attachmentId)
      .then((response) => {
        if (response.status === SUCCESS_STATUS) {
          this.setState({
            showDeleteModal: false,
            showSuccessModal: true,
            attachmentId: null
          })

          setTimeout(() => {
            window.location.reload(true)
          }, 2000)
        }
      }).catch((error) => {
        console.error(error)
      })
  }

  render () {
    const { showDeleteModal, showSuccessModal, attachmentId, attachmentName } = this.state

    return (
      <Fragment>
        <DeleteAttachmentModal
          open={showDeleteModal}
          onClose={this.hideDeleteModal}
          onDelete={this.handleDelete}
          attachment={{
            id: attachmentId,
            name: attachmentName
          }}
        />

        <AttachmentDeletedModal open={showSuccessModal} />
      </Fragment>
    )
  }
}
