import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

const useLoadOptions = (request) => {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [debouncedQuery] = useDebounce(query, 300)
  const updateOptions = async () => {
    setLoading(true)
    const response = await request({ params: { query } })
    const structuredResults = Object.values(response.data)
      .map(result => ({ id: result.id, ...result.attributes }))
    setOptions(structuredResults)
    setLoading(false)
  }

  const resetOptions = () => {
    setQuery('')
    setOptions([])
    setLoading(false)
  }

  useEffect(() => {
    // only request options if 2 or more characters have been typed
    if (query.length < 2) return

    updateOptions()
  }, [debouncedQuery])

  const loadOptions = (inputValue = '') => {
    if (inputValue.length) {
      setQuery(inputValue)
    } else {
      resetOptions()
    }
  }

  return useMemo(() => ({ loadOptions, options, query, setQuery, loading }))
}

export default useLoadOptions
