import React from 'react'
import PropTypes from 'prop-types'
import './LayoutContainer.css'

class LayoutContainer extends React.PureComponent {
  render () {
    return (
      <div
        {...this.props}
        className='LayoutContainer'
      >
        {this.props.children}
      </div>
    )
  }
}

LayoutContainer.propTypes = {
  children: PropTypes.node
}

export default LayoutContainer
