import React from 'react'
import PropTypes from 'prop-types'

import useFetchClientData from '@scenes/clients/hooks/useFetchClientData'

import Spacing from '@components/Spacing'
import Grid from '@material-ui/core/Grid'
import LoadingDataWrapper from '@components/LoadingDataWrapper'
import ClientDetails from './ClientDetails'
import ClientGoals from '@scenes/clients/ClientGoals'
import ClientNeeds from '@scenes/clients/ClientNeeds'
import { GOAL_STATUSES } from '@scenes/clients/ClientGoals/constants'
import { NEED_STATUSES } from '@scenes/clients/ClientNeeds/constants'

const Scene = ({ clientId, ...props }) => {
  const { loading } = useFetchClientData(clientId)

  return (
    <Spacing paddingBottom={4}>
      <LoadingDataWrapper
        loadingData={loading}
        display='block'
        size={80}
      >
        <ClientDetails clientId={clientId}>
          <Spacing paddingBottom={2}>
            <ClientDetails.Header />
            <ClientDetails.PatientInfo />
          </Spacing>
        </ClientDetails>
        <Grid
          container
          spacing={6}
          direction='row'
          justify='space-between'
        >
          <Grid item xs={12} sm={9} md={8}>
            <ClientGoals clientId={clientId}>
              <ClientGoals.Header />
              <ClientGoals.List status={GOAL_STATUSES.opened} />
              <ClientGoals.List status={GOAL_STATUSES.closed} />
            </ClientGoals>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <ClientNeeds clientId={clientId}>
              <Spacing paddingBottom={2}>
                <ClientNeeds.Header />
              </Spacing>
              <ClientNeeds.List status={NEED_STATUSES.opened} />
              <ClientNeeds.List status={NEED_STATUSES.closed} />
            </ClientNeeds>
          </Grid>
        </Grid>
      </LoadingDataWrapper>
    </Spacing>
  )
}

Scene.propTypes = {
  clientId: PropTypes.number
}

export default Scene
