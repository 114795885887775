import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

export const exposeRemoveCartItemForCarePlanDrawer = (cartItemId, removeCartItemFromSpaUI) => {
  // This gives non-React code a function to remove a referral
  if (!window.removeCartItemFromSpaUI) {
    window.removeCartItemFromSpaUI = {}
  }

  window.removeCartItemFromSpaUI[cartItemId] = () => {
    removeCartItemFromSpaUI(cartItemId)
  }
}

export const removeCartItemFromNonSpa = (cartItemId) => {
  // We need to reach into the non-SPA-part of the app to update some evaluates
  // We need to remove this referral from the checkout
  document.querySelectorAll(
    `[data-behavior='cart-item'][data-cart-item-id='${cartItemId}']`
  ).forEach((node) => {
    node.parentNode.removeChild(node)
  })
}

export const addCartItemToNonSpa = ({ resourceSite, cartItem }) => {
  // We need to reach into the non-SPA-part of the app to append the new
  // cartItem to the care plan drawer
  // we render it as static markup (i.e. a string of HTML), because that's what works
  // best within the care_plan_drawer.es6, which appends HTML using jquery

  // NOTE: This HTML structure must mirror that in `_cart_item.html.erb`.
  // If you update this please make the corresponding change there too
  const cartItemHTML = renderToStaticMarkup(
    <article className='card card--small care-plan-item'
      data-test='cart-item'
      data-behavior='cart-item'
      data-cart-item-id={cartItem.id}
      data-resource-site-id={resourceSite.id}
      data-analytics-referrer-type='Drawer Opened'
      data-analytics-referrer-id='not instrumented'
    >
      <div className='card__close material-icons mi--dark notranslate mi--16' data-behavior-removeable='trigger'>close</div>
      <div className='card__meta'>{cartItem.attributes.created_at_relative_time}</div>
      <h3 className='card__subtitle'>{resourceSite.organizationName}</h3>
      <h2 className='card__title'>
        <a href={resourceSite.links.self}>{resourceSite.serviceName}</a>
      </h2>
    </article>
  )

  window.addCartItemToNonSpa(cartItemHTML)
}

export const updateCartCountNonSpa = (count) => {
  // We need to update the number in your resource basket.
  document.querySelector(
    `[data-behavior='care-plan-count__digit']`
  ).innerHTML = count
}

export const updateCheckoutButtonPath = (id) => {
  const checkoutButton = document.querySelector(
    `[data-behavior='care-plan-drawer__client-save--open']`
  )

  if (id) {
    const cartPath = checkoutButton.getAttribute('data-cart-path-base')
    const queryParams = new URLSearchParams({ patient_id: id })

    checkoutButton.setAttribute('data-cart-path-existing-referral-patient', `${cartPath}?${queryParams}`)
  } else {
    checkoutButton.removeAttribute('data-cart-path-existing-referral-patient')
  }
}
