import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Stamp.scss'

class Stamp extends Component {
  render () {
    const { text, color, dataTest } = this.props
    const Tag = this.props.tag

    return (
      <Tag data-test={dataTest} className={`stamp stamp--color-${color}`}>
        {text}
        {this.props.children}
      </Tag>
    )
  }
}

Stamp.displayName = 'Stamp'

Stamp.defaultProps = {
  tag: 'div'
}

Stamp.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'red',
    'orange',
    'green',
    'grey',
    'blue',
    'blue-05'
  ]).isRequired,
  dataTest: PropTypes.string,
  tag: PropTypes.oneOf([
    'span',
    'div'
  ])
}

export default Stamp
