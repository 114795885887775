import React from 'react'
import ExpansionPanel from '@components/ExpansionPanel'
import Story from '../Story'

const ExpansionPanels = () => {
  const renderPanel = (props = {}) => {
    const defaultProps = {
      expandText: 'Show',
      collapseText: 'Hide'
    }

    return <ExpansionPanel {...defaultProps} {...props}>
      Inner content
    </ExpansionPanel>
  }

  return (
    <>
      <Story title='Unstyled ExpansionPanel'>
        {renderPanel()}
      </Story>

      <Story title='Unstyled and disabled'>
        {renderPanel({ isDisabled: true })}
      </Story>

      <Story title='Unstyled with title'>
        {renderPanel({ title: 'Open for content' })}
      </Story>

      <Story title='Card-styled ExpansionPanel'>
        {renderPanel({ insideCard: true, title: 'Test Card' })}
      </Story>

      <Story title='Disabled card-style'>
        {renderPanel({
          insideCard: true,
          title: 'Test Card',
          isDisabled: true
        })}
      </Story>

      <Story title='Stacked ExpansionPanels'>
        {renderPanel({
          insideCard: true,
          title: 'First item',
          key: 1
        })}

        {renderPanel({
          insideCard: true,
          title: 'Second item',
          key: 2
        })}
      </Story>

    </>
  )
}

ExpansionPanels.displayName = 'ExpansionPanels'

export default ExpansionPanels
