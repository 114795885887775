import Polyglot from 'node-polyglot'
import axios from 'axios'
import { isProduction } from '@utils/envUtil'

class ConfigLocales {
  constructor () {
    this.localesPath = '/api/internal/locales'
  }

  fetch () {
    return axios.get(this.localesPath, { credentials: 'same-origin' }).then((response) => {
      return response.data
    })
  }

  onMissingKey (key, options, locale) {
    if (!isProduction()) {
      throw new Error(`Missing translation key: ${key}`)
    }
  }

  load (data) {
    return new Polyglot({
      phrases: data,
      allowMissing: true,
      onMissingKey: this.onMissingKey.bind(this)
    })
  }
}

export default ConfigLocales
