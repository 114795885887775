import classNames from 'classnames'
import sortBy from 'lodash/sortBy'

import { formatName } from '@utils/formatterUtil'
import { CLOSED_LOOP_REFERRAL, MEMBER_LED_CLOSED_LOOP_REFERRAL } from '@constants/referralSources'

import {
  EVENT_TYPE_ATTACHMENT,
  EVENT_TYPE_NOTE,
  EVENT_TYPE_ENCOUNTER,
  EVENT_TYPE_CLOSED_LOOP_STATUS_UPDATE,
  EVENT_TYPE_MEMBER_LED_CLOSED_LOOP_STATUS_UPDATE,
  EVENT_TYPE_REFERRAL_PATIENT_FOLLOWUP,
  EVENT_TYPE_STATUS_UPDATE,
  EVENT_TYPE_CREATED,
  EVENT_TYPE_INTAKE_RESPONSE_SET
} from '../constants'

export function userText ({ actingUser, currentUser, pdf, serviceName }) {
  if (actingUser.apiUser) return actingUser.teamName
  if (pdf) return actingUser.fullName
  if (actingUser.anonymous) return serviceName

  const actingUserId = String(actingUser.id)
  const currentUserId = String(currentUser.id)

  return actingUserId === currentUserId
    ? window.local.t('referrals.timeline.you')
    : actingUser.fullName
}

export function timelineEventContentClassNames ({ eventType, isLastElement }) {
  const eventsWithContent = [
    EVENT_TYPE_NOTE,
    EVENT_TYPE_ENCOUNTER,
    EVENT_TYPE_STATUS_UPDATE
  ]
  const displayEventContent = eventsWithContent.includes(eventType)

  return classNames(
    'ReferralTimeline__event__content',
    {
      'ReferralTimeline__event__content--last': isLastElement,
      'ReferralTimeline__event__content--empty': !displayEventContent
    }
  )
}

export function attachmentEvents ({ attachments, analyticsReferrer }) {
  return attachments.map((attachment) => {
    return {
      analyticsReferrer: analyticsReferrer,
      type: EVENT_TYPE_ATTACHMENT,
      timestamp: attachment.createdAt,
      attachment: attachment,
      user: attachment.user,
      key: `attachment-${attachment.id}`
    }
  })
}

export function noteEvents ({ notes }) {
  return notes.map((note) => {
    return {
      type: EVENT_TYPE_NOTE,
      timestamp: note.updatedAt,
      note: note.content,
      user: note.user,
      key: `note-${note.id}`
    }
  })
}

export function intakeResponseSetEvent ({ intakeResponseSet, referrer }) {
  if (intakeResponseSet === null) return []

  return [{
    type: EVENT_TYPE_INTAKE_RESPONSE_SET,
    timestamp: intakeResponseSet.updatedAt,
    responseOrder: intakeResponseSet.responseOrder,
    user: referrer,
    intakeQuestionSetId: intakeResponseSet.intakeQuestionSetId,
    referralId: intakeResponseSet.referralId,
    key: `intake-response-set-${intakeResponseSet.id}`,
    intakeResponseSet
  }]
}

function referralStateEventType ({ user, priorState, source }) {
  if (!priorState) {
    return EVENT_TYPE_CREATED
  } else if (source === CLOSED_LOOP_REFERRAL) {
    return EVENT_TYPE_CLOSED_LOOP_STATUS_UPDATE
  } else if (source === MEMBER_LED_CLOSED_LOOP_REFERRAL) {
    return EVENT_TYPE_MEMBER_LED_CLOSED_LOOP_STATUS_UPDATE
  } else {
    return EVENT_TYPE_STATUS_UPDATE
  }
}

export function referralStateEvents ({ referralStates }) {
  return referralStates.map(state => {
    const oldStatus = state.priorState
      ? window.local.t(`referrals.show.referral_statuses.${state.priorState.status}.name`) : ''
    const newStatus = state.priorState
      ? window.local.t(`referrals.show.referral_statuses.${state.status}.name`) : ''

    return {
      context: state.context,
      contextCode: state.contextCode,
      type: referralStateEventType(state),
      timestamp: state.createdAt,
      oldStatus: oldStatus,
      newStatus: newStatus,
      source: state.source,
      user: state.user,
      key: `state-${state.id}`
    }
  })
}

export function referralPatientFollowupEvents ({ patient, referralPatientFollowups }) {
  return referralPatientFollowups.map(referralPatientFollowup => {
    return {
      key: `referral-patient-followup-${referralPatientFollowup.id}`,
      patient: formatName(patient),
      timestamp: referralPatientFollowup.createdAt,
      type: EVENT_TYPE_REFERRAL_PATIENT_FOLLOWUP,
      responseType: referralPatientFollowup.responseType
    }
  })
}

export function referralEncounterEvents ({ referralEncounters, programAssignments }) {
  return referralEncounters.map(referralEncounter => {
    return {
      action: referralEncounter.action,
      key: `encounter-${referralEncounter.id}`,
      note: referralEncounter.note,
      referralEncounter: referralEncounter,
      serviceDetails: processedServiceDetails({ referralEncounter, programAssignments }),
      timestamp: referralEncounter.createdAt,
      type: EVENT_TYPE_ENCOUNTER,
      user: referralEncounter.user
    }
  })
}

function processedServiceDetails ({ referralEncounter, programAssignments }) {
  const serviceDetails = referralEncounter.serviceDetails.map(serviceDetail => {
    const assignmentId = serviceDetail.referralResourceProgramAssignmentId
    const assignment = programAssignments.find(assignment => assignment.id === assignmentId)
    serviceDetail.referralResourceProgramAssignment = assignment
    return serviceDetail
  })

  return sortedServiceDetails(serviceDetails)
}

export function sortedServiceDetails (serviceDetails) {
  return sortBy(serviceDetails, (serviceDetails) => {
    return serviceDetails.referralResourceProgramAssignment.resourceProgramName
  })
}
