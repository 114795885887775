// @TODO canShare needs more validation
const canShare = (user) => user.attributes.authenticated
const canEdit = (user) => user.attributes.authenticated
const canFavorite = (user) => user.attributes.authenticated

export default {
  canShare,
  canEdit,
  canFavorite
}
