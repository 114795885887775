import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@components/Button'
import './ReferralEncounterModal.scss'

const requiredFields = [
  'action',
  'dateOfAction',
  'referralResourceProgramAssignments'
]

const validateForm = (formSelections) => {
  const completedFields = requiredFields
    .map(field => formSelections[field])
    .filter(selection => {
      if (Array.isArray(selection)) {
        return selection.length > 0
      } else {
        return selection != null
      }
    })

  return completedFields.length === requiredFields.length
}

class PageOneSubmit extends PureComponent {
  render () {
    return (
      <div className='Modal__submit--single-button'>
        <Button
          type='primary'
          disabled={!this.props.formIsValid}
          onClick={this.props.onSubmit}
          dataTest='referral-encounter-modal__next'
        >
          {window.local.t('referrals.encounter_modal.next')}
        </Button>
      </div>
    )
  }
}

PageOneSubmit.propTypes = {
  formIsValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const mapStateToProps = (state, props) => {
  const {
    action: { value: action },
    dateOfAction: { value: dateOfAction },
    referralResourceProgramAssignments: { value: referralResourceProgramAssignments }
  } = state.referralView.form.referralEncounter

  return {
    formIsValid: validateForm({ action, dateOfAction, referralResourceProgramAssignments })
  }
}

export default connect(mapStateToProps)(PageOneSubmit)
