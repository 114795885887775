import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@components/Icon'
import './SelectionMenu.scss'

class SelectionMenu extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      selectedCategory: null,
      loadedOptions: {}
    }
    this.selectCategory = this.selectCategory.bind(this)
  }

  selectCategory (selectedCategory) {
    return () => {
      this.setState({
        selectedCategory
      })
      if (selectedCategory && !this.props.selectionMenuOptions) {
        if (!this.state.loadedOptions[selectedCategory]) {
          this.props.loadSelectionMenuOptions(
            selectedCategory,
            this.menuOptionsLoaded(selectedCategory)
          )
        }
      }
    }
  }

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  makeListItem (itemLabel, onClick, isCategory) {
    return <li
      onClick={onClick}
      className='SelectionMenu__item'
      key={itemLabel}
    >
      {itemLabel}
      {isCategory && <Icon iconKey='keyboard_arrow_right' />}
    </li>
  }
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-enable jsx-a11y/click-events-have-key-events */

  menuOptionsLoaded (category) {
    return (options) => {
      this.setState({
        loadedOptions: { ...this.state.loadedOptions, [category]: options }
      })
    }
  }

  menuList () {
    if (this.props.selectionMenuOptions) {
      return Object.keys(this.props.selectionMenuOptions).map((menuItem) => {
        return this.makeListItem(
          menuItem,
          this.selectCategory(menuItem),
          true
        )
      })
    } else {
      return this.props.selectionMenu.map((menuItem) => {
        return this.makeListItem(
          this.props.getMenuLabel(menuItem),
          this.selectCategory(this.props.getMenuValue(menuItem)),
          true
        )
      })
    }
  }

  menuOptions () {
    const { selectedCategory } = this.state

    if (this.props.selectionMenuOptions) {
      return this.props.selectionMenuOptions[selectedCategory]
    } else if (this.state.loadedOptions[selectedCategory]) {
      return this.state.loadedOptions[selectedCategory]
    } else {
      return null
    }
  }

  excludedValuesSet () {
    return new Set(
      this.props.excludedValueObjects.map((object) => {
        return this.props.getOptionValue(object)
      })
    )
  }

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  renderMenuOptions () {
    const menuOptions = this.menuOptions()
    const excluedValuesSet = this.excludedValuesSet()

    if (menuOptions) {
      const filteredMenuOptions = menuOptions.filter((option) => {
        return !excluedValuesSet.has(this.props.getOptionValue(option))
      })

      if (filteredMenuOptions.length === 0) {
        return <li className='SelectionMenu__item--no-click'>
          {this.props.noResultsMessage || window.local.t('components.selection_menu.no_results')}
        </li>
      } else {
        return filteredMenuOptions.map((selection) => {
          return this.makeListItem(
            this.props.getOptionLabel(selection),
            () => this.props.onClick(selection),
            false
          )
        })
      }
    } else {
      return <li className='SelectionMenu__item--no-click'>
        {this.props.loadingMessage || window.local.t('components.selection_menu.loading')}
      </li>
    }
  }

  renderList () {
    if (this.state.selectedCategory) {
      return (
        <ul>
          <li
            className='SelectionMenu__item SelectionMenu__back'
            onClick={this.selectCategory(null)}
          >
            <Icon iconKey='keyboard_arrow_left' />
            {window.local.t('components.selection_menu.back')}
          </li>
          {this.renderMenuOptions()}
        </ul>
      )
    } else {
      return (
        <ul>
          <div className='SelectionMenu__title'>{this.props.selectionMenuTitle}</div>
          {this.menuList()}
        </ul>
      )
    }
  }
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-enable jsx-a11y/click-events-have-key-events */

  render () {
    return (
      <div
        className='SelectionMenu__container'
        data-test={this.props.dataTest}
      >
        {this.renderList()}
      </div>
    )
  }
}

SelectionMenu.displayName = 'SelectionMenu'

export default SelectionMenu

SelectionMenu.defaultProps = {
  getMenuValue: (option) => option.value,
  getMenuLabel: (option) => option.label,
  getOptionValue: (option) => option.value,
  getOptionLabel: (option) => option.label,
  onClick: () => {},
  onBlur: () => {},
  excludedValueObjects: []
}

SelectionMenu.propTypes = {
  dataTest: PropTypes.string,
  selectionMenuTitle: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onClick: PropTypes.func,
  excludedValueObjects: PropTypes.arrayOf(PropTypes.object),
  noResultsMessage: PropTypes.string,

  // Use this for preloaded menus
  // This is an object whose keys are the names of the menus,
  // and whose values are arrays of strings that are the options
  // when a user clicks into a menu.
  // { menuKeyName: [array of options in menu], secondMenuKeyName: .... }
  selectionMenuOptions: PropTypes.object,

  // Use these for async loaded menus
  selectionMenu: PropTypes.arrayOf(PropTypes.object),
  // A function that loads the options for a given menu.
  // Called with the currently selected menu, and a callback to call when
  // the options are loaded.
  loadSelectionMenuOptions: PropTypes.func,
  // A function called on the returned menu option to get its human readable label.
  getMenuLabel: PropTypes.func,
  // A function called on the returned menu option to get its value.
  getMenuValue: PropTypes.func,
  loadingMessage: PropTypes.string
}
