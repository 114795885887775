import React, { Component } from 'react'
import Icon from '@components/Icon'
import PropTypes from 'prop-types'
import Stamp from '@components/Stamp'
import './StampWithIcon.scss'

class StampWithIcon extends Component {
  render () {
    const { text, color, iconKey, iconSize } = this.props

    return (
      <div className='StampWithIcon'>
        <Stamp text={text} color={color}>
          <Icon iconKey={iconKey} size={iconSize} />
        </Stamp>
      </div>
    )
  }
}

StampWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
  iconKey: PropTypes.string.isRequired,
  iconSize: PropTypes.number.isRequired,
  color: PropTypes.oneOf([
    'red',
    'orange',
    'green',
    'grey',
    'blue',
    'blue-05'
  ]).isRequired
}

export default StampWithIcon
