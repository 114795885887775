import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import Icon from '@components/Icon'
import './LegacyModal.scss'

export default class LegacyModal extends PureComponent {
  componentDidMount () {
    document.querySelector('body').className =
      document.querySelector('body').className + ' is-locked'
  }

  componentWillUnmount () {
    document.querySelector('body').className =
    document.querySelector('body').className.replace(' is-locked', '')
  }

  render () {
    const { children, onClose } = this.props

    return (
      <Fragment>
        <div className='orbital-modal orbital-modal--flex-vertical font-sft orbital-modal--is-active'
          style={{ justifyContent: 'space-around' }}>
          <div className='orbital-modal__content' style={{ padding: 0 }}>
            {onClose &&
              <button
                className='LegacyModal__close'
                aria-label='Close'
                onClick={onClose}
                type='button'
              >
                <Icon color='black' iconKey='close' size={20} />
              </button>
            }
            {children}
          </div>
        </div>
        <div className='orbital-modal__background' />
      </Fragment>
    )
  }
}

LegacyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}
