import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import EmphasisBackground from '@components/EmphasisBackground'
import IntakeResponse from './intakeResponse'
import { getRecord, getAssociation } from '@selectors'
import { timelineEventContentClassNames } from '../utils/referralTimelineUtil'

const IntakeResponseSetEvent = props => {
  const questionOrder = props.intakeResponseSet.responseOrder

  return (
    <div>
      <div className='ReferralTimeline__event__title'>
        {window.local.t('referrals.timeline.events.intake_form', { user: props.userText })}
      </div>
      <div className={timelineEventContentClassNames({ isLastElement: props.isLastElement })}>
        <EmphasisBackground emphasisLevel='high'>
          {questionOrder.map((intakeQuestionID, index) => {
            const isFinalQuestion = index === questionOrder.length - 1

            return <IntakeResponse
              finalQuestion={isFinalQuestion}
              key={intakeQuestionID}
              questionID={intakeQuestionID}
              responses={props.intakeResponses}
            />
          })}
        </EmphasisBackground>
      </div>
    </div>
  )
}

IntakeResponseSetEvent.propTypes = {
  intakeResponseSet: PropTypes.object.isRequired,
  userText: PropTypes.string.isRequired,
  isLastElement: PropTypes.bool,
  intakeResponses: PropTypes.array
}

const mapStateToProps = (state, props) => {
  const referral = getRecord(state, 'referral', props.referralId)
  const intakeResponses = getAssociation(state, props.intakeResponseSet, 'intakeResponses')
  return {
    referral, intakeResponses
  }
}

export default connect(mapStateToProps)(IntakeResponseSetEvent)
