import React, { Fragment, PureComponent } from 'react'
import PhoneInput from '@components/PhoneInput'
import Story from '../Story'

class PhoneInputs extends PureComponent {
  render () {
    return (
      <Fragment>
        <Story title='PhoneInput'>
          <PhoneInput />
        </Story>
      </Fragment>
    )
  }
}

PhoneInputs.displayName = 'PhoneInput'

export default PhoneInputs
