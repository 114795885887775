import React from 'react'
import PropTypes from 'prop-types'
import {
  emptyFieldsInComposite,
  getAttributeFromOtherPatient,
  getCollectionItemFromComposite,
  isRedundantItem,
  requiredNamesInComposite
} from '@scenes/clients/ClientMerge/utils'
import {
  DiffToggle,
  EmptyFieldHeaderWarning,
  EmptyFieldWarning,
  FieldContainer,
  TextDiffGroup
} from '@scenes/clients/ClientMerge/ReviewView/components'
import TextDiff from '@scenes/clients/ClientMerge/components/TextDiff'
import SmallModule from '@components/SmallModule'
import AddressDisplay from '@components/AddressDisplay'
import Grid from '@material-ui/core/Grid'
import cssVariables from '@config/theme/variables'
import { displayValueFor } from '@models/Client'

class ClientDiff extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      emptyFields: emptyFieldsInComposite(this.props),
      showDiff: true
    }

    this.initLocales()

    this.renderAddress = this.renderAddress.bind(this)
    this.renderSingleAttributeFor = this.renderSingleAttributeFor.bind(this)
    this.sortCollection = this.sortCollection.bind(this)
    this.toggleShowDiff = this.toggleShowDiff.bind(this)
  }

  toggleShowDiff () {
    this.setState({ showDiff: !this.state.showDiff })
  }

  conditionallyRenderClientName () {
    const client = this.props.compositeClient

    if (!client.firstName || !client.lastName) return ''

    return `${client.lastName.value}, ${client.firstName.value}`
  }

  initLocales () {
    const { local } = window

    this.sectionLabels = {
      identifiers: local.t('client_merge.review.client_ids'),
      dateOfBirth: local.t('client_merge.review.client_date_of_birth'),
      gender: local.t('client_merge.review.client_gender'),
      phones: local.t('client_merge.review.client_phones'),
      email: local.t('client_merge.review.client_email'),
      address: local.t('client_merge.review.client_address'),
      name: local.t('client_merge.review.client_name')
    }

    this.attributePrefixeLabels = {
      firstName: local.t('client_merge.review.prefix_first_name'),
      lastName: local.t('client_merge.review.prefix_last_name'),
      middleName: local.t('client_merge.review.prefix_middle_name'),
      externalId: local.t('client_merge.review.prefix_external_id')
    }
  }

  getSectionLabelFor (key) {
    return this.sectionLabels[key]
  }

  getAttributeLabelPrefixFor (key) {
    return this.attributePrefixeLabels[key]
  }

  getAttributeFromOtherPatient (attribute) {
    return getAttributeFromOtherPatient(
      [
        this.props.firstPatient,
        this.props.secondPatient
      ],
      this.props.compositeClient,
      attribute
    )
  }

  renderNaAttributeFor (attribute) {
    return (
      <FieldContainer label={this.getSectionLabelFor(attribute)}>
        <TextDiff
          visualizeIntent
          text='N/A'
          kind={'unselectable'}
        />
      </FieldContainer>
    )
  }

  renderSingleAttributeFor (attribute, compositeClientAttribute) {
    const prefix = this.getAttributeLabelPrefixFor(attribute)
    const remainderText = this.getAttributeFromOtherPatient(attribute)
    const compositeString = displayValueFor(attribute, compositeClientAttribute.value)
    const remainderString = displayValueFor(attribute, remainderText)
    const client = {
      selectedText: prefix ? `${prefix} ${compositeString}` : compositeString,
      remainderText: prefix ? `${prefix} ${remainderString}` : remainderString
    }

    return (
      <TextDiffGroup
        selectedText={client.selectedText}
        remainderText={client.remainderText}
        compositeClientAttribute={compositeClientAttribute}
        showDiff={this.state.showDiff}
      />
    )
  }

  sortCollection (attribute, list) {
    const defaults = []
    const highlights = []
    const strikes = []
    const showDiff = this.state.showDiff
    const compositeClient = this.props.compositeClient

    list.forEach((item, index) => {
      if (isRedundantItem(attribute, item, compositeClient)) return

      let compositeItem = getCollectionItemFromComposite(attribute, item, compositeClient)
      const showDefault = !showDiff

      if (compositeItem) {
        defaults.push(
          <TextDiff key={`${compositeItem.value.id} - default`} kind='default' visualizeIntent={showDefault} text={compositeItem.value.displayValue} />
        )
        highlights.push(
          <TextDiff key={`${compositeItem.value.id} - highlight`} kind='highlight' visualizeIntent={showDiff} text={compositeItem.value.displayValue} />
        )
      } else {
        strikes.push(
          <TextDiff key={`${item.id} - strike`} kind='strike' text={item.displayValue} visualizeIntent={showDiff} />
        )
      }
    })

    return defaults.concat(highlights, strikes)
  }

  renderCollection (attribute) {
    if (this.props.unselectableAttributes.includes(attribute)) {
      return this.renderNaAttributeFor(attribute)
    }

    const compositeCollection = this.props.compositeClient[attribute]

    if (!compositeCollection) return <EmptyFieldWarning label={this.getSectionLabelFor(attribute)} />

    const allOptions = this.props.firstPatient[attribute].concat(
      this.props.secondPatient[attribute]
    )

    return (
      <FieldContainer label={this.getSectionLabelFor(attribute)}>
        {this.sortCollection(attribute, allOptions)}
      </FieldContainer>
    )
  }

  renderContainerFor (attribute, renderCallback) {
    if (this.props.unselectableAttributes.includes(attribute)) {
      return this.renderNaAttributeFor(attribute)
    }

    const compositeAttributeValue = this.props.compositeClient[attribute]

    if (!compositeAttributeValue) return <EmptyFieldWarning label={this.getSectionLabelFor(attribute)} />

    return (
      <FieldContainer label={this.getSectionLabelFor(attribute)}>
        {renderCallback(attribute, compositeAttributeValue)}
      </FieldContainer>
    )
  }

  renderNamesContainer () {
    const compositeClient = this.props.compositeClient
    const validName = requiredNamesInComposite(compositeClient)

    if (!validName) return <EmptyFieldWarning label={this.getSectionLabelFor('name')} />

    return (
      <FieldContainer label={this.getSectionLabelFor('name')}>
        {compositeClient.firstName && this.renderSingleAttributeFor('firstName', compositeClient.firstName)}
        {compositeClient.middleName && this.renderSingleAttributeFor('middleName', compositeClient.middleName)}
        {compositeClient.lastName && this.renderSingleAttributeFor('lastName', compositeClient.lastName)}
      </FieldContainer>
    )
  }

  renderAddress (attribute, compositeAttributeValue) {
    const originalAddress = this.getAttributeFromOtherPatient(attribute)

    return (
      <TextDiffGroup
        selectedText={<AddressDisplay address={compositeAttributeValue.value} />}
        remainderText={<AddressDisplay address={originalAddress} />}
        compositeClientAttribute={compositeAttributeValue}
        showDiff={this.state.showDiff}
      />
    )
  }

  render () {
    const { local } = window
    const { localeContext } = this.props

    return (
      <Grid
        container
        direction='row'
        justify='space-between'
        style={cssVariables.gridContainer}
      >
        <Grid item xs={7}>
          <SmallModule
            title={this.conditionallyRenderClientName()}
            banner={{
              text: local.t(`client_merge.review.banner.${localeContext}`),
              width: '240px'
            }}
            headerRight={
              <DiffToggle showDiff={this.state.showDiff} toggleShowDiff={this.toggleShowDiff} />
            }
            headerBottom={
              <EmptyFieldHeaderWarning emptyFields={this.state.emptyFields}>
                {local.t(`client_merge.review.header_warning.${localeContext}`)}
              </EmptyFieldHeaderWarning>
            }
          >
            <Grid item xs={12}>
              <Grid container direction='row' spacing={5}>
                <Grid item xs={6}>
                  {this.renderCollection('identifiers')}
                  {this.renderNamesContainer()}
                  {this.renderContainerFor('dateOfBirth', this.renderSingleAttributeFor)}
                  {this.renderContainerFor('gender', this.renderSingleAttributeFor)}
                </Grid>
                <Grid item xs={6}>
                  {this.renderCollection('phones')}
                  {this.renderContainerFor('email', this.renderSingleAttributeFor)}
                  {this.renderContainerFor('address', this.renderAddress)}
                </Grid>
              </Grid>
            </Grid>
          </SmallModule>
        </Grid>

        {this.props.children}
      </Grid>
    )
  }
}

ClientDiff.propTypes = {
  children: PropTypes.node.isRequired,
  compositeClient: PropTypes.object.isRequired,
  firstPatient: PropTypes.object.isRequired,
  localeContext: PropTypes.string.isRequired,
  secondPatient: PropTypes.object.isRequired,
  unselectableAttributes: PropTypes.array
}

export default ClientDiff
