const UHCEndUserAttestation = () => {
  const { local } = window
  let description, checkboxLabel

  description = local.t('referral_modal.fields.client_consent.description.uhc')
  checkboxLabel = local.t('referral_modal.fields.client_consent.label.uhc')

  return [checkboxLabel, description]
}

export default UHCEndUserAttestation
