import axios from 'axios'
import apiConfig from '@config/apiConfig'
import ClientMergeAdapter from '@adapters/clientMergeAdapter'
import ClientMerge from '@models/ClientMerge'
import { legacyFind as legacyFindClient } from '@services/clientsService'
import mergeRequestSerializer from '@scenes/clients/ClientMerge/serializers/mergeRequest'

const baseEndpoint = '/api/internal/patient_merge'

const logError = error => { console.error('Error:', error) }

const findClientWithIncludes = clientID => (
  legacyFindClient(clientID, { included: ['address', 'identifiers', 'phones'] })
)

const find = id => {
  const endpoint = `${baseEndpoint}/requests/${id}?include=patients,patient_merge_attributes,user`

  return axios.get(endpoint)
    .then(response => {
      const clientMerge = ClientMerge(new ClientMergeAdapter(response).object)

      const clientIDs = clientMerge.clients.map(patient => patient.id)
      return axios
        .all([findClientWithIncludes(clientIDs[0]), findClientWithIncludes(clientIDs[1])])
        .then(clients => ({ ...clientMerge, clients }))
        .catch(logError)
    })
    .catch(logError)
}

const all = (params) => {
  const { status, limit } = params
  const endpoint = `${baseEndpoint}/requests`

  return axios.get(endpoint, {
    params: {
      status,
      limit,
      include: 'patients,user'
    }
  })
    .then(response => {
      const rawClientMerges = new ClientMergeAdapter(response).collection
      return rawClientMerges.map(clientMerge => ClientMerge(clientMerge))
    })
    .catch(logError)
}

const update = (id, props, status) => {
  const data = mergeRequestSerializer.serialize(props, {
    status,
    id,
    context: props.contextNote,
    context_code: null
  })

  return axios(apiConfig.put(`${baseEndpoint}/requests/${id}`, data)).then((response) => {
    return response
  })
    .catch((err) => {
      console.log(err)
    })
}

export default {
  find,
  all,
  update
}
