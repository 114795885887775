import { configureStore } from '@reduxjs/toolkit'

import clientMergeReducer from '@scenes/clients/ClientMerge/reducers'
import referralCheckoutReducer from '@features/referralCheckout'
import referralShowReducer from '@scenes/referralShow/reducers'
import resourceSitesReducer from '@scenes/resourceSites/reducers'
import unauthenticatedReducer from '@scenes/unauthenticated/reducers'
import globalAppReducer from '@reducers'

import { internalAPI } from '@services/internalAPI'

export const createStoreFunction = () => {
  return configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'meta.baseQueryMeta.request',
          'meta.baseQueryMeta.response',
          'payload.cartItemUpdatedAttributes.file'
        ],
        ignoredPaths: [
          'global.locale',
          'referralCheckout.cartItems',
          'referralView.form.referralEncounter'
        ]
      }
    }).concat(internalAPI.middleware),

    reducer: {
      [internalAPI.reducerPath]: internalAPI.reducer,
      clientMerge: clientMergeReducer,
      global: globalAppReducer,
      referralCheckout: referralCheckoutReducer,
      referralView: referralShowReducer,
      resourceSites: resourceSitesReducer,
      unauthenticated: unauthenticatedReducer
    }
  })
}

const newStore = createStoreFunction()

export default newStore
