import React from 'react'
import PropTypes from 'prop-types'

class Button extends React.PureComponent {
  getColorClassNames () {
    const { color, variant } = this.props

    const classes = [`hfysg-button--${color}`]

    switch (variant) {
      case 'outlined':
        classes.push(`hfysg-button--${color}-inverse`)
        break
      case 'transparent':
        classes.push(`hfysg-button--${color}-transparent`)
        break
      default:
        break
    }

    return classes.join(' ')
  }

  getClassNames () {
    const { className, disabled } = this.props

    const classes = ['hfysg-button']

    if (className) {
      classes.push(className)
    }

    if (disabled) {
      classes.push('hfysg-button--disabled')
    }

    return classes.concat(this.getColorClassNames()).join(' ')
  }

  render () {
    const { children, disabled } = this.props

    return (
      <button
        {...this.props}
        className={this.getClassNames()}
        disabled={disabled}
      >
        {children}
      </button>
    )
  }
}

Button.defaultProps = {
  color: 'blue',
  disabled: false,
  type: 'submit',
  variant: 'default'
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['blue', 'red']),
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['default', 'outlined', 'transparent'])
}

export default Button
