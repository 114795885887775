import axios from 'axios'

import apiConfig from '@config/apiConfig'

class NotificationStore {
  constructor (container) {
    this.container = container
    this.pathPrefix = '/en'

    this.markAllRead = this.markAllRead.bind(this)
    this.markOneAsRead = this.markOneAsRead.bind(this)
    this.loadAll = this.loadAll.bind(this)
  }

  markAllRead (callback) {
    const path = `${this.pathPrefix}/notifications/mark_all_read`

    return axios(apiConfig.put(path)).then(response => {
      callback(response.data.notifications)
    })
  }

  markOneAsRead (id, callback) {
    const path = `${this.pathPrefix}/notifications/${id}`

    return axios(apiConfig.put(path)).then((response) => {
      callback(response.data.notification)
    })
  }

  loadAll (callback) {
    const path = `${this.pathPrefix}/notifications`
    const responseCallback = (response) => { callback(response.data.notifications) }

    axios(apiConfig.get(path)).then(responseCallback)
  }
}

export default NotificationStore
