import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@components/Button'
import Modal from '@components/Modal'
import PhoneInput from '@components/PhoneInput'
import SuccessModal from '@components/SuccessModal'
import {
  closePublicTextModal,
  openPublicTextModal
} from '@scenes/unauthenticated/actions'

import unauthenticatedService from '@services/unauthenticatedService'
import { formatPhoneNumber } from '@utils/formatterUtil'

const WhitelabeledScreeningTextButton = props => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [successOpen, setSuccessOpen] = useState(false)
  const [errored, setErrored] = useState(false)

  const isModalOpened = useMemo(
    () => {
      return (props.modalIsOpen && props.currentModal === props.modalKey)
    },
    [props.modalIsOpen, props.currentModal]
  )

  const errorMessage = 'We encountered an error. Please wait a moment and then resubmit. Contact our support team if the error persists.'

  const phoneInput = <PhoneInput
    errors={errored ? [errorMessage] : []}
    onChange={setPhoneNumber}
    showErrorMessages
    value={phoneNumber}
  />

  const handlePhoneSubmit = (event) => {
    event.preventDefault()
    setIsProcessing(true)
    setErrored(false)

    unauthenticatedService.submitTextForm(
      { phone_number: phoneNumber, ...props.formData },
      props.endpointPath
    ).then((response) => {
      const status = response.status
      setIsProcessing(false)

      if (status === 201) {
        props.closePublicTextModal()
        setSuccessOpen(true)
        setTimeout(() => { setSuccessOpen(false) }, 2000)
      } else {
        setErrored(true)
      }
    })
  }

  const handleModalOpenClick = (event) => {
    props.openPublicTextModal(props.modalKey)
    props.onModalOpened()
  }

  const handleModalCloseClick = (event) => {
    props.closePublicTextModal()
    props.onModalClosed()
  }

  const isInvalidPhone = () => {
    const regex = RegExp(/^\d{10}$/i)
    return !regex.test(phoneNumber)
  }

  const actionButton = <Button
    data-testid='text-submit'
    disabled={isInvalidPhone() || isProcessing}
    htmlType='submit'
    onClick={handlePhoneSubmit}
    type='primary'
  >
    <span>Send Text</span>
  </Button>

  return <React.Fragment>
    <Button
      type='primary'
      onClick={handleModalOpenClick}
      data-test='text-resource-button'
    >
      {props.buttonText}
    </Button>

    <Modal
      actions={actionButton}
      content={phoneInput}
      headerText={'Text Results'}
      onClose={handleModalCloseClick}
      open={isModalOpened}
    />

    <SuccessModal
      open={successOpen}
      title={'Text Sent!'}
      text={`Your results have been texted to ${formatPhoneNumber({ number: phoneNumber })}.`}
    />
  </React.Fragment>
}

const mapStateToProps = (state, ownProps) => {
  return {
    modalIsOpen: state.unauthenticated.textModal.open,
    currentModal: state.unauthenticated.textModal.currentModal
  }
}

const mapDispatchToProps = {
  closePublicTextModal,
  openPublicTextModal
}

WhitelabeledScreeningTextButton.propTypes = {
  closePublicTextModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  openPublicTextModal: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  endpointPath: PropTypes.string,
  formData: PropTypes.object,
  modalKey: PropTypes.string,
  onModalOpened: PropTypes.func,
  onModalClosed: PropTypes.func
}

WhitelabeledScreeningTextButton.defaultProps = {
  buttonText: 'Text Results',
  endpointPath: `${window.location.origin}/${window.location.pathname}/share`,
  formData: {},
  modalKey: '0',
  onModalOpened: () => (void (0)),
  onModalClosed: () => (void (0))
}

export default connect(mapStateToProps, mapDispatchToProps)(WhitelabeledScreeningTextButton)
