import React from 'react'
import PropTypes from 'prop-types'

const duration = '.35s'

// I think it's more idomatic to allow the passed parameter to be lowercase
// we can capitalize the first letter here and pass it below.
const captilizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.substring(1)

const SlideIn = (props) => {
  return <div style={{ animation: `slideIn${captilizeFirstLetter(props.direction)} ${duration} 1` }} >
    {props.children}
  </div>
}

SlideIn.defaultProps = {
  direction: 'left'
}

SlideIn.propTypes = {
  direction: PropTypes.oneOf([
    'left',
    'right'
  ]),
  children: PropTypes.element.isRequired
}

export default SlideIn
