import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Icon from '@components/Icon'
import ExpansionPanel from '@components/ExpansionPanel'
import SimpleTable from '@components/SimpleTable'

import './PendingClientMergeRequests.scss'

const tableColumns = () => {
  const local = window.local

  return (
    [
      {
        align: 'left',
        className: 'hfysg-body',
        header: local.t('pending_client_merge_requests.table.headers.request_date'),
        value: (row) => {
          return moment(row.createdAt).format('MM/DD/YY')
        },
        width: 25
      },
      {
        align: 'left',
        header: local.t('pending_client_merge_requests.table.headers.clients_impacted'),
        value: (row) => {
          return (
            <div>
              {row.clients.map(client => {
                return (
                  <div key={client.id}>
                    <a href={`/en/clients/${client.id}`}>{client.fullNameBackwards}</a>
                  </div>
                )
              })}
            </div>
          )
        },
        width: 25
      },
      {
        align: 'left',
        header: local.t('pending_client_merge_requests.table.headers.requested_by'),
        value: (row) => {
          return row.requester.fullName
        },
        width: 25
      },
      {
        align: 'right',
        header: '',
        value: (row) => {
          return (
            <a
              href={`/en/client-merge/${row.id}`}
              className='hfysg-button hfysg-button--blue PendingClientMergeRequests__button'
            >
              {local.t('pending_client_merge_requests.table.button')}
            </a>
          )
        },
        width: 40
      }
    ]
  )
}

export default class PendingClientMergeRequests extends React.PureComponent {
  isDisabled () {
    return !this.pendingClientMergeRequestsCount()
  }

  pendingClientMergeRequestsCount () {
    return this.props.pendingClientMergeRequests.length
  }

  warningIconClasses () {
    const classes = ['PendingClientMergeRequests__warningIcon']

    if (!this.isDisabled()) {
      classes.push('mi--orange')
    }

    return classes.join(' ')
  }

  pendingClientMergeRequestsMessage () {
    const count = this.pendingClientMergeRequestsCount()
    const { local } = window
    const localePath = 'pending_client_merge_requests.title'

    switch (count) {
      case 0:
        return local.t(`${localePath}.zero`)
      case 1:
        return local.t(`${localePath}.one`)
      default:
        return local.t(`${localePath}.other`, { count })
    }
  }

  renderTitle () {
    return (
      <h3 className='hfysg-h3 PendingClientMergeRequests__title'>
        <Icon
          className={this.warningIconClasses()}
          iconKey='warning'
          size={24}
          theme='round'
        />
        {this.pendingClientMergeRequestsMessage()}
      </h3>
    )
  }

  renderChildren () {
    return (
      <SimpleTable
        columns={tableColumns()}
        rowObjects={this.props.pendingClientMergeRequests}
      />
    )
  }

  render () {
    return (
      <section className='PendingClientMergeRequests'>
        <ExpansionPanel
          insideCard
          children={this.renderChildren()}
          title={this.renderTitle()}
          isDisabled={this.isDisabled()}
          expandText={window.local.t('pending_client_merge_requests.content.show')}
          collapseText={window.local.t('pending_client_merge_requests.content.hide')}
        />
      </section>
    )
  }
}

PendingClientMergeRequests.propTypes = {
  pendingClientMergeRequests: PropTypes.array.isRequired
}
