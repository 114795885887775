import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { toggleKey } from '@actions'
import resourceSiteCreateService from '@services/resourceSiteCreateService'

import Button from '@components/Button'
import ExpandableTextArea from '@components/ExpandableTextArea'
import Modal from '@components/Modal'
import ModalContentSeparator from '@components/Modal/ContentSeparator'
import SuccessModal from '@components/SuccessModal'

const localeFor = (i18nPath, options = {}) => window.local.t(
  `resource_sites.create_modal.${i18nPath}`,
  options
)

const ResourceSiteCreateModal = (props) => {
  const [message, setMessage] = useState(null)
  const [isProcessing, setProcessing] = useState(false)
  const [isSuccessful, setSuccessful] = useState(false)

  const closeCreateModal = () => {
    props.toggleKey({
      namespace: 'resourceSites',
      key: 'createModalOpen',
      value: false
    })
  }

  const handleSubmit = (event) => {
    setProcessing(true)

    resourceSiteCreateService.requestToCreate({
      request_to_create: {
        from: props.currentUser.attributes.email,
        message
      }
    }).then(() => {
      setProcessing(false)
      setSuccessful(true)
      setTimeout(closeCreateModal, 3000)
    }).catch(error => {
      console.error(error)
      setTimeout(() => setProcessing(false), 500)
    })
  }

  const renderModalActions = () => (
    <div className='Modal__submit--single-button'>
      <Button
        type='primary'
        onClick={handleSubmit}
        disabled={!message || isProcessing}
      >
        {localeFor('submit')}
      </Button>
    </div>
  )

  const renderModalContent = () => (
    <Fragment>
      <a href='/resource_sites/new'>{localeFor('full_creation_page')}</a>

      <ModalContentSeparator />

      <ExpandableTextArea
        label={localeFor('message_input.label')}
        maxLength={500}
        onChange={setMessage}
        placeholder={localeFor('message_input.placeholder')}
      />
    </Fragment>
  )

  return (
    <div className='ResourceSiteCreateModal'>
      {!isSuccessful && <Modal
        actions={renderModalActions(handleSubmit, message, isProcessing)}
        content={renderModalContent()}
        dataTest='ResourceSiteCreateModal'
        headerText={localeFor('title')}
        onClose={closeCreateModal}
        open={props.createModalOpen}
      />}

      {isSuccessful && <SuccessModal
        open={isSuccessful}
        text={localeFor('success.text')}
        title={localeFor('success.title')}
      />}
    </div>
  )
}

ResourceSiteCreateModal.displayName = 'ResourceSiteCreateModal'

ResourceSiteCreateModal.propTypes = {
  currentUser: PropTypes.object.isRequired,
  createModalOpen: PropTypes.bool.isRequired,
  toggleKey: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  toggleKey
}

const mapStateToProps = (state, props) => {
  return {
    createModalOpen: state.resourceSites.createModalOpen,
    currentUser: state.global.currentUser
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSiteCreateModal)
