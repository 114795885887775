import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { format } from '@utils/selectUtil'
import { actions } from '../constants'
import { onFormFieldChange } from '../actions'
import Select from '@components/Select'

class ReferralEncounterActionSelect extends PureComponent {
  onFormFieldChange (form, field) {
    return (selection) => {
      this.props.onFormFieldChange(form, field, selection)
    }
  }

  render () {
    return (
      <Select
        dataTest={this.props.dataTest}
        required
        maxMenuHeight={null}
        onChange={this.onFormFieldChange('referralEncounter', 'action')}
        options={format.transformToOptions(
          actions,
          'referrals.encounter_modal.actions'
        )}
        label={this.props.label}
        placeholder={window.local.t('referrals.encounter_modal.actions.placeholder')}
        value={this.props.selectedAction}
        showDropdownIndicator
        hideClearIndicator
      />
    )
  }
}

ReferralEncounterActionSelect.propTypes = {
  dataTest: PropTypes.string,
  label: PropTypes.string,
  onFormFieldChange: PropTypes.func.isRequired,
  selectedAction: PropTypes.object
}

const mapDispatchToProps = {
  onFormFieldChange
}

const mapStateToProps = (state, props) => {
  const { action } = state.referralView.form.referralEncounter

  return {
    selectedAction: action.value
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralEncounterActionSelect)
