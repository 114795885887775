import React, { Fragment, useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import EndUserAttestation from '@scenes/resourceSites/EndUserAttestation'
import ClosedLoopConsent from '@scenes/resourceSites/ClosedLoopConsent'
import FileAttachmentFormControl from '@components/FileAttachmentFormControl'
import ModalContentSeparator from '@components/Modal/ContentSeparator'
import ModalSubheader from './ModalSubheader'
import PatientSelector from '@components/PatientSelector'
import Select from '@components/Select'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import useLocalStorage from '@hooks/useLocalStorage'
import useEffectOnMount from '@hooks/useEffectOnMount'

import './ReferralModal.css'
import { ReferralContext } from '../Referral/Referral'
import ClosedLoopEndUserAttestation from './Consent/ClosedLoopEndUserAttestation'
import InNetworkEndUserAttestation from './Consent/InNetworkEndUserAttestation'
import UHCEndUserAttestation from './Consent/UHCEndUserAttestation'
import { isOutOfNetwork } from '@utils/networkTiersUtil'
import { isUHC } from '@utils/companyUtil'

const ReferralModalContent = ({ data, behavior }) => {
  const { state, changeState, resourceSite } = useContext(ReferralContext)
  const showClientConsent = state.formSelections.closedLoop || data.attestationRequired
  const { name: currentCompany } = useSelector((store) => store.global.currentCompany.attributes)

  const renderSeparator = (data) => {
    if (data.closedLoopable || data.attestationRequired) {
      return <ModalContentSeparator />
    }
  }

  const renderClientSelect = (client, behavior) => {
    return (
      <div
        className='orbital-form__section'
        data-test='ReferralModal__ClientSelect'
      >
        <PatientSelector
          defaultValue={client}
          dataTest='referral-modal-client'
          name='referral[patient_id]'
          label={window.local.t('referral_modal.fields.client.label')}
          onChange={behavior.handleSelectChange('client')}
        />
      </div>
    )
  }

  const renderProgramSelect = (programs, behavior) => {
    const [selectedProgramIds] = useLocalStorage('selectedProgramIds', [])

    useEffectOnMount(() => {
      const programReducer = (previousValue, currentValue) => {
        const parsedSelectedProgramIds = selectedProgramIds.map((id) => parseInt(id))
        if (parsedSelectedProgramIds.includes(currentValue.id)) {
          previousValue.push(currentValue)
        }
        return previousValue
      }
      const selectedPrograms = programs.reduce(programReducer, [])
      const formSelections = { ...state.formSelections }
      formSelections.programs = selectedPrograms
      changeState({ formSelections })
    })

    return (
      <div
        className='orbital-form__section'
        data-test='ReferralModal__ProgramSelect'
        data-testid='ReferralModal__ProgramSelect'
      >
        <Select
          dataTest='referral-modal-program'
          name='referral[resource_program_ids][]'
          required
          showDropdownIndicator
          onChange={behavior.handleSelectChange('programs')}
          options={programs}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          label={window.local.t('referral_modal.fields.programs.label')}
          placeholder={window.local.t('referral_modal.fields.programs.placeholder')}
          isMulti
          value={state.formSelections.programs}
        />
      </div>
    )
  }

  const renderNoteTextarea = (referralContext) => {
    return (
      <div className='orbital-form__section orbital-form'>
        <label>
          <span className='hfysg-h6'>
            {window.local.t('referral_modal.fields.note.label')}
          </span>
        </label>
        <textarea
          name='referral[notes_attributes][0][content]'
          placeholder={window.local.t(
            `referral_modal.fields.note.placeholder.${referralContext}`
          )}
          data-test='ReferralModal__NoteField'
        />
      </div>
    )
  }

  const renderHiddenFields = (data) => {
    return (
      <Fragment>
        <input
          value={data.id}
          type='hidden'
          name='referral[resource_site_id]'
        />
        <input
          value={data.currentUser.id}
          type='hidden'
          name='referral[notes_attributes][0][user_id]'
        />
        <input
          value={data.currentUser.id}
          type='hidden'
          name='referral[attachments_attributes][0][user_id]'
        />
        <input
          value={data.analytics.referrerType}
          type='hidden'
          name='analytics_referrer_type'
        />
        <input
          value={data.analytics.referrerId}
          type='hidden'
          name='analytics_referrer_id'
        />
      </Fragment>
    )
  }

  const renderAttachmentControl = () => {
    return (
      <div
        className='orbital-form orbital-form__section orbital-form__section--extra-margin'
        data-test='ReferralModal__AttachmentSelect'
      >
        <dl>
          <dt className='hfysg-h6'>
            {window.local.t('referral_modal.fields.attachment.label')}
          </dt>
        </dl>

        <FileAttachmentFormControl
          id='referral-create-attachment'
          name='referral[attachments_attributes][0][asset]'
          directions={
            window.local.t('referral_modal.fields.attachment.copy')
          }
        />
      </div>
    )
  }

  const renderClientConsent = (data, behavior) => {
    const { local } = window
    let description, header, checkboxLabel
    const userAttestationRequired = data.attestationRequired
    const inNetwork = data.isCoordinate
    const closeLoopConsentChecked = state.formSelections.closedLoop

    // Suggested improvement: this top level attestation required check
    // can actually be removed because we are combining the concept of
    // closedLoop = true and attestationRequired = true. Before these were two separate concepts.
    if (userAttestationRequired) {
      header = local.t('referral_modal.copy.client_consent')

      if (isUHC(currentCompany)) {
        // UnitedHealthcare consent language
        // For In-network & Closed-Loop Log & Contact referrals
        [checkboxLabel, description] = UHCEndUserAttestation()
      } else if (inNetwork) {
        // In-network referrals
        [checkboxLabel, description] = InNetworkEndUserAttestation(data)
      } else {
        // Out-of-network, Closed-Loop Log & Contact referrals
        if (closeLoopConsentChecked) {
          // Closed loop referrals have two checkboxes:
          // 1. Closed Loop Consent: pre-selected checkbox to verify
          // if we are allowed to contact the organization on the client's behalf.
          // 2. End User Attestation: checkbox to verify if we
          // are allowed to share the client's contact information
          [header, checkboxLabel, description] = ClosedLoopEndUserAttestation()
        } else {
          // If Closed Loop Consent is not selected, do not show End User Attestation
          return null
        }
      }
    }

    return (
      <Fragment>
        <EndUserAttestation
          checkboxLabel={checkboxLabel}
          description={description}
          descriptionBelow={data.closedLoopable && !isUHC(currentCompany)}
          onAttestation={behavior.handleConsentToggle}
          formName='referral'
          dataTest='ReferralModal__AttestationCheck'
          header={header}
        />
      </Fragment>
    )
  }

  const renderReferralModalWarning = () => {
    return (
      <div>
        <ModalContentSeparator />
        <div className='ReferralModal__render-warning'>
          {window.local.t('referral_modal.fields.client_consent.description.out_of_network')}
        </div>
      </div>
    )
  }

  const renderClosedLoopConsent = (behavior) => {
    const { local } = window

    return (
      <ClosedLoopConsent
        checkboxLabel={local.t('referral_modal.fields.closed_loop_consent.label')}
        description={local.t('referral_modal.fields.closed_loop_consent.description')}
        formName='referral'
        dataTest='ReferralModal__ClosedLoopConsentCheck'
        onClosedLoopCheck={behavior.handleClosedLoopConsentToggle}
      />
    )
  }

  const renderRecipientAttachmentsDisabled = (name) => {
    return <div className='ReferralModal__attachment-warning'>
      <p className='center'>
        {window.local.t('referral_modal.copy.recipient_attachments_disabled', { company_name: name })}
      </p>
    </div>
  }

  return (
    <div>
      <ModalSubheader name={data.name} privacyStatus={data.privacyStatus} />

      <ModalContentSeparator />

      <Spacing marginBottom={2}>
        <Text tag='h6' style='sub-header-small'>
          {window.local.t('referral_modal.copy.referral_details')}
        </Text>
      </Spacing>

      {!data.hidePatientUI && renderClientSelect(data.client, behavior)}
      {renderProgramSelect(data.programs, behavior)}
      {data.attachmentsEnabled && renderAttachmentControl()}
      {renderNoteTextarea(data.referralContext)}
      {renderHiddenFields(data)}
      {renderSeparator(data)}
      {data.closedLoopable && !isUHC(currentCompany) && renderClosedLoopConsent(behavior)}
      {showClientConsent && renderClientConsent(data, behavior)}
      {isOutOfNetwork(resourceSite.tier) && renderReferralModalWarning()}
      {data.displayAttachmentsDisabledMessage &&
        renderRecipientAttachmentsDisabled(data.name)}
    </div>
  )
}

ReferralModalContent.propTypes = {
  data: PropTypes.object,
  behavior: PropTypes.object
}

export default ReferralModalContent
