import React from 'react'
import PropTypes from 'prop-types'
import './EmphasisBackground.scss'

class EmphasisBackground extends React.PureComponent {
  render () {
    return (
      <div className={`EmphasisBackground--level-${this.props.emphasisLevel}`}>
        {this.props.children}
      </div>
    )
  }
}

EmphasisBackground.displayName = 'EmphasisBackground'

EmphasisBackground.propTypes = {
  children: PropTypes.node.isRequired,
  emphasisLevel: PropTypes.oneOf(['high', 'medium'])
}

export default EmphasisBackground
