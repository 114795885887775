import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addEntity } from '@actions'

const useCreateRecord = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const createRecord = createRecordRequest => {
    const request = createRecordRequest()

    setLoading(true)

    request.then(response => {
      dispatch(addEntity(response))
      setLoading(false)
    }).catch(() => { setLoading(false) })

    return request
  }

  return { loading, createRecord }
}

export default useCreateRecord
