import axios from 'axios'
import moment from 'moment'
import apiConfig from '@config/apiConfig'
import { normalizeFormParameters } from '@utils/apiUtil'

const baseEndpoint = '/api/internal/referrals/'
const notesEndpoint = '/api/internal/notes/'
const followupEndpoint = referralID => `${baseEndpoint}${referralID}/followup`
const referralConsentsEndpoint = referralID => `${baseEndpoint}${referralID}/consent`

const DEFAULT_REFERRAL_INCLUDED = [
  'assigned_users',
  'assigned_users.team',
  'attachments.user',
  'current_state',
  'notes.user',
  'patient.address',
  'patient.identifiers',
  'patient.phones',
  'referral_encounters',
  'referral_encounters.user',
  'referral_patient_followups',
  'referral_resource_program_assignments',
  'referral_states.user',
  'referrer',
  'referrer.company',
  'referrer.team',
  'resource_site',
  'resource_site.company',
  'intake_response_set',
  'intake_question_set',
  'intake_question_set.intake_questions',
  'intake_response_set.intake_responses',
  'intake_response_set.intake_responses.intake_question.intake_response_options'
]

const getReferral = (referralId, options = {}) => {
  const included = options.included || DEFAULT_REFERRAL_INCLUDED

  return axios.get(
    `${baseEndpoint}${referralId}`,
    {
      params: {
        included
      }
    }
  ).then(response => response.data)
}

const create = (formData) => {
  return axios(apiConfig.post(baseEndpoint, formData)).then((response) => {
    return response
  })
}

const submitFollowup = ({ referralID, data }) => {
  const endpoint = followupEndpoint(referralID)

  return axios(apiConfig.post(endpoint, data)).then(response => response)
}

const share = (formData, id) => {
  return axios(apiConfig.post(`${baseEndpoint}${id}/share`, formData)).then((response) => {
    return response
  })
    .catch((err) => {
      console.error(err)
    })
}

const updateRecipients = (referralId, recipients) => {
  const data = {
    referral: {
      assigned_user_ids: recipients.map(recipient => recipient.id)
    }
  }

  return axios(apiConfig.put(`/en/referrals/${referralId}/recipients/`, data))
}

const createState = (referralId, priorStateId, status, context = null, contextCode = null, analyticsReferrer) => {
  let data = {
    ...analyticsReferrer,
    prior_state_id: priorStateId,
    status,
    include: ['user']
  }

  if (status === 'canceled') {
    data.context = context
    data.context_code = contextCode
  }

  return axios(apiConfig.post(`/api/internal/referrals/${referralId}/states/`, data))
}

export const createNote = (referralId, content, analyticsReferrer) => {
  const data = {
    ...analyticsReferrer,
    note: {
      content,
      notable_id: referralId,
      notable_type: 'Referral'
    }
  }

  return axios(apiConfig.post(notesEndpoint, data))
}

export const createEncounter = ({
  referralId,
  referralEncounter,
  programAssignmentServiceDetails,
  analyticsReferrer
}) => {
  const {
    action: { value: action },
    dateOfAction: { value: dateOfAction },
    note: { value: note }
  } = referralEncounter
  const data = {
    ...analyticsReferrer,
    referral_encounter: {
      action: action.value,
      date_of_action: moment(dateOfAction).format('YYYY-MM-DD'),
      note
    }
  }
  const serviceDetails = Object.entries(programAssignmentServiceDetails)
    .map(entry => collectServiceDetails(entry))

  data.service_details = serviceDetails

  return axios(apiConfig.post(`/api/internal/referrals/${referralId}/referral_encounters/`, data))
}

export const collectServiceDetails = ([id, details]) => {
  const normalizedDetails = normalizeFormParameters(details)

  if (normalizedDetails.end_date) {
    normalizedDetails.end_date = moment(normalizedDetails.end_date).format('YYYY-MM-DD')
  }

  return {
    referral_resource_program_assignment_id: id,
    ...normalizedDetails
  }
}

export const createReferralConsent = (referralID) => {
  const consentEndpoint = referralConsentsEndpoint(referralID)

  const data = {
    referral_consent: {
      consent: true,
      event: 'update'
    }
  }

  return axios(apiConfig.post(consentEndpoint, data)).then(response => response.data)
}

export default {
  create,
  createEncounter,
  createNote,
  createReferralConsent,
  createState,
  getReferral,
  share,
  submitFollowup,
  updateRecipients
}
