import React, { useContext } from 'react'

import Spacing from '@components/Spacing'
import Text from '@components/Text'

import { CartShareContext } from './CartShare'

const Header = () => {
  const {
    formatters: { createdByName, createdOnDate },
    getString
  } = useContext(CartShareContext)

  return (
    <header
      className='CartShare__Header page-hero--grey'
    >
      <Spacing paddingTop={8} paddingBottom={6} paddingX={{ xs: 3, sm: 4, md: 12 }}>
        <Spacing paddingBottom={2}>
          <Text tag='h1' style='page-header'>
            {getString('page_title')}
          </Text>
        </Spacing>
        <Text tag='p' style='body-text'>
          {getString('header.intro', {
            created_by_user: createdByName(),
            created_on_date: createdOnDate()
          })}
        </Text>
        <Text tag='p' style='body-text'>
          {getString('header.more_info')}
        </Text>
      </Spacing>
    </header>
  )
}

export default Header
