import React from 'react'
import PropTypes from 'prop-types'

const DlRow = ({ dt, dd, header }) => (
  <div style={{ marginBottom: '24px', maxWidth: '672px' }}>
    <h6 style={{ marginBottom: '8px' }} className='hfysg-h6'>{header}</h6>
    <dt style={{ margin: '0', marginBottom: '8px' }} className='hfysg-h4'>{dt}</dt>
    <dd>{dd}</dd>
  </div>
)

DlRow.propTypes = {
  dd: PropTypes.string.isRequired,
  dt: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired
}

export default DlRow
