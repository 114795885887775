import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { OUT_OF_NETWORK, OUT_OF_NETWORK_CLR, IN_NETWORK, IN_NETWORK_PREFERRED } from '@constants/networkTiers'

import ElementWithTooltip from '@components/ElementWithTooltip'
import Stamp from '@components/Stamp'

import './ReferralTierStamp.scss'

const stampColorMapping = {
  [OUT_OF_NETWORK]: 'grey',
  [OUT_OF_NETWORK_CLR]: 'blue-outline',
  [IN_NETWORK]: 'blue-05',
  [IN_NETWORK_PREFERRED]: 'blue'
}

export class ReferralTierStamp extends PureComponent {
  partnershipTypeDescription (tier) {
    return <div className='ReferralTierStamp__tooltip'>
      {window.local.t(`partnership_types.${tier}.context`)}
    </div>
  }

  render () {
    const { tier, version, tag } = this.props

    return (
      <div>
        <ElementWithTooltip
          tooltipContent={this.partnershipTypeDescription(tier)}
          tooltipOptions={{ placement: 'bottom' }}
        >
          <Stamp
            color={stampColorMapping[tier]}
            tag={tag}
            text={window.local.t(`referrals.referral_tier_stamp.${tier}.${version}`)}
          />
        </ElementWithTooltip>
      </div>
    )
  }
}

ReferralTierStamp.defaultProps = {
  tag: 'div'
}

ReferralTierStamp.propTypes = {
  tier: PropTypes.oneOf([
    OUT_OF_NETWORK,
    OUT_OF_NETWORK_CLR,
    IN_NETWORK,
    IN_NETWORK_PREFERRED
  ]).isRequired,
  version: PropTypes.oneOf([
    'long',
    'short'
  ]).isRequired,
  tag: PropTypes.oneOf([
    'span',
    'div'
  ])
}

export default ReferralTierStamp
