import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'

import { referralEncounterCancelReasonOptions } from '@constants/referralEncounterCancel'

import Spacing from '@components/Spacing'
import Select from '@components/Select'
import Text from '@components/Text'
import Button from '@components/Button'
import ExpandableTextArea from '@components/ExpandableTextArea'
import Form from '@components/Form'
import FieldErrors from '@components/Form/FieldErrors'

import unauthenticatedService from '@services/unauthenticatedService'
import { getQueryParams } from '@utils/browserUtil'

const submitDisabled = (action, submitting, { note, cancelReason }) => {
  if (submitting) return true

  if (action === 'accept' || action === 'complete') {
    return !note.length
  } else if (action == 'decline') {
    return !cancelReason
  }
}

const transformParams = (action, formData, search) => {
  let transformedFormData = {
    actor_email: getQueryParams(search).actor_email
  }

  if (action === 'accept' || action === 'complete') {
    transformedFormData.content = formData.note
  } else if (action == 'decline') {
    transformedFormData.note = formData.note
    transformedFormData.reason_not_provided = get(formData, 'cancelReason.value')
  }

  return transformedFormData
}

const handleError = (response, setError) => {
  const errorMessage = get(response, 'data.errors[0].detail') ||
    get(response, 'data.message') ||
    window.local.t('closed_loop_referrals.update_form.submit.generic_error')

  setError([errorMessage])
}

const handleSubmit = (action, referralUuid, formData = {}, setSubmitting, setError, history) => {
  setSubmitting(true)

  let service;
  if (action === 'accept' || action === 'complete') {
    service = 'notes';
  } else {
    service = 'referralEncounters';
  }

  const transformedFormData = transformParams(action, formData, history.location.search)

  unauthenticatedService.closedLoopReferrals.create[service](referralUuid, transformedFormData)
    .then(response => {
      setSubmitting(false)
      if (response && response.status === 201) {
        const thankYouPath = `/closed_loop_referrals/${referralUuid}/thank_you`
        history.push(thankYouPath)
      } else {
        handleError(response, setError)
      }
    }).catch(response => {
      setSubmitting(false)
      handleError(response, setError)
    })
}

const ClosedLoopReferralsUpdateForm = ({ action, referralUuid }) => {
  const history = useHistory()

  const [cancelReason, setCancelReason] = useState(null)
  const [note, setNote] = useState('')
  const [errors, setError] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const { local } = window
  const handleSubmitWrapped = () => {
    handleSubmit(action, referralUuid, { note, cancelReason }, setSubmitting, setError, history)
  }

  return (
    <Form>
      <Spacing padding={2} marginLeft={3}>
        <Text style='sub-header-large' tag='div'>{local.t('closed_loop_referrals.update_form.title')}</Text>
        <Spacing marginTop={2}>
          <Text style='body-text' tag='div'>{local.t('closed_loop_referrals.update_form.subtitle')}</Text>
        </Spacing>
        {action === 'decline' && <Spacing marginTop={3}>
          <Select
            dataTest='closed-loop-cancel-reason'
            options={referralEncounterCancelReasonOptions(window.local)}
            label={local.t('closed_loop_referrals.update_form.cancel_reason.label')}
            showDropdownIndicator
            required
            onChange={(option) => setCancelReason(option)}
            placeholder={local.t('closed_loop_referrals.update_form.cancel_reason.placeholder')}
          />
        </Spacing>}

        <Spacing marginTop={3}>
          <ExpandableTextArea
            label={local.t('closed_loop_referrals.update_form.note.label')}
            onChange={setNote}
            value={note}
          />
        </Spacing>
        <Spacing marginTop={3}>
          <Spacing marginY={1}>
            <FieldErrors errors={errors} showErrorMessages />
          </Spacing>

          <Button type='primary' htmlType='submit' disabled={submitDisabled(action, submitting, { note, cancelReason })} onClick={handleSubmitWrapped}>
            {local.t('closed_loop_referrals.update_form.submit.button')}
          </Button>
        </Spacing>
      </Spacing>
    </Form>
  )
}

ClosedLoopReferralsUpdateForm.propTypes = {
  referralUuid: PropTypes.string.isRequired,
  action: PropTypes.string
}

export default ClosedLoopReferralsUpdateForm
