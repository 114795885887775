import React, { createContext } from 'react'
import PropTypes from 'prop-types'

// Pull in child components
import Header from './Header'
import ReferralList from './ReferralList'
import DownloadButton from './DownloadButton'

import useCartShare from './useCartShare'

export const CartShareContext = createContext()

const { Provider } = CartShareContext

const CartShare = ({
  children,
  cartShareUuid,
  ...props }) => {
  const value = useCartShare(cartShareUuid)

  return (
    <Provider value={value}>
      <div className='CartShare' {...props}>
        {children}
      </div>
    </Provider>
  )
}

CartShare.propTypes = {
  children: PropTypes.node.isRequired,
  cartShareUuid: PropTypes.string
}

CartShare.Header = Header
CartShare.ReferralList = ReferralList
CartShare.DownloadButton = DownloadButton

export default CartShare
