import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DashedModule from '@scenes/clients/ClientMerge/components/DashedModule'
import DraggableTagContainer from '@scenes/clients/ClientMerge/components/DraggableTagContainer'
import DraggableTag from '@scenes/clients/ClientMerge/components/DraggableTag'
import AddressTag from '@scenes/clients/ClientMerge/components/AddressTag'
import PhoneTag from '@scenes/clients/ClientMerge/components/PhoneTag'
import IdentifierTag from '@scenes/clients/ClientMerge/components/IdentifierTag'
import { iconDataFor, labelFor } from '@scenes/clients/ClientMerge/utils'
import { displayValueFor } from '@models/Client'

class CompositePatient extends Component {
  renderBasicTagFor (attributeName, attributeObject) {
    const { inferred, value } = attributeObject
    const wasInferred = Boolean(inferred)
    const displayValue = displayValueFor(attributeName, value)

    return (
      <DraggableTag
        attributeName={attributeName}
        handleRemoval={this.props.attributeRemovalAction}
        icon={iconDataFor(attributeName)}
        invalid={wasInferred}
        label={labelFor(window.local, attributeName)}
        removable={!wasInferred}
        value={displayValue}
      />
    )
  }

  renderNaTagFor (attributeName) {
    return (
      <DraggableTag
        attributeName={attributeName}
        icon={iconDataFor(attributeName)}
        invalid
        removable={false}
        value='N/A'
      />
    )
  }

  renderIdentifierTags (identifiers) {
    return identifiers.map(({ source, value, inferred }, index) => {
      const wasInferred = Boolean(inferred)

      return (
        <IdentifierTag
          attributeName='identifiers'
          clientId={source}
          identifier={value}
          invalid={wasInferred}
          key={index}
          removable={!wasInferred}
          removalAction={this.props.identifierRemovalAction}
        />
      )
    })
  }

  renderIdentifiersContainer () {
    const containerProps = { label: window.local.t('attributes.patient.identifiers.label') }
    const { identifiers } = this.props

    if (identifiers && identifiers.length > 0) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderIdentifierTags(identifiers)}
        </DraggableTagContainer>
      )
    } else if (this.props.unselectableAttributes.includes('identifiers')) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderNaTagFor('identifiers')}
        </DraggableTagContainer>
      )
    } else {
      return <DraggableTagContainer {...containerProps} />
    }
  }

  renderNameContainer () {
    const containerProps = { label: window.local.t('attributes.patient.name') }
    const { firstName, lastName, middleName } = this.props

    if (firstName || middleName || lastName) {
      return (
        <DraggableTagContainer {...containerProps}>
          {firstName && this.renderBasicTagFor('firstName', firstName)}
          {middleName && this.renderBasicTagFor('middleName', middleName)}
          {lastName && this.renderBasicTagFor('lastName', lastName)}
        </DraggableTagContainer>
      )
    } else {
      return <DraggableTagContainer {...containerProps} />
    }
  }

  renderGenderContainer () {
    const containerProps = { label: window.local.t('attributes.patient.gender.label') }
    const { gender } = this.props

    if (gender) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderBasicTagFor('gender', gender)}
        </DraggableTagContainer>
      )
    } else {
      return <DraggableTagContainer {...containerProps} />
    }
  }

  renderPhoneTags (phones) {
    return phones.map((phone, index) => {
      const wasInferred = Boolean(phone.inferred)

      return (
        <PhoneTag
          attributeName='phones'
          invalid={wasInferred}
          key={index}
          phone={phone.value}
          removable={!wasInferred}
          removalAction={this.props.phoneRemovalAction}
        />
      )
    })
  }

  renderPhonesContainer () {
    const containerProps = { label: window.local.t('attributes.patient.phones.label') }
    const { phones } = this.props

    if (phones && phones.length > 0) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderPhoneTags(phones)}
        </DraggableTagContainer>
      )
    } else if (this.props.unselectableAttributes.includes('phones')) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderNaTagFor('phones')}
        </DraggableTagContainer>
      )
    } else {
      return <DraggableTagContainer {...containerProps} />
    }
  }

  renderEmailContainer () {
    const containerProps = { label: window.local.t('attributes.patient.email.label') }
    const { email } = this.props

    if (email) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderBasicTagFor('email', email)}
        </DraggableTagContainer>
      )
    } else if (this.props.unselectableAttributes.includes('email')) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderNaTagFor('email')}
        </DraggableTagContainer>
      )
    } else {
      return <DraggableTagContainer {...containerProps} />
    }
  }

  renderDateOfBirthContainer () {
    const containerProps = { label: window.local.t('attributes.patient.date_of_birth.label') }
    const { dateOfBirth } = this.props

    if (dateOfBirth) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderBasicTagFor('dateOfBirth', dateOfBirth)}
        </DraggableTagContainer>
      )
    } else {
      return <DraggableTagContainer {...containerProps} />
    }
  }

  renderAddressContainer () {
    const containerProps = { label: window.local.t('attributes.patient.address.label') }
    const { address } = this.props

    if (address) {
      const wasInferred = Boolean(address.inferred)

      return (
        <DraggableTagContainer {...containerProps}>
          <AddressTag
            address={address.value}
            attributeName='address'
            clientId={address.source}
            invalid={wasInferred}
            removable={!wasInferred}
            removalAction={this.props.addressRemovalAction}
          />
        </DraggableTagContainer>
      )
    } else if (this.props.unselectableAttributes.includes('address')) {
      return (
        <DraggableTagContainer {...containerProps}>
          {this.renderNaTagFor('address')}
        </DraggableTagContainer>
      )
    } else {
      return <DraggableTagContainer {...containerProps} />
    }
  }

  render () {
    return (
      <DashedModule color='blue'>
        {this.renderIdentifiersContainer()}

        {this.renderNameContainer()}

        {this.renderDateOfBirthContainer()}

        {this.renderGenderContainer()}

        {this.renderPhonesContainer()}

        {this.renderEmailContainer()}

        {this.renderAddressContainer()}
      </DashedModule>
    )
  }
}

CompositePatient.propTypes = {
  addressRemovalAction: PropTypes.func.isRequired,
  attributeRemovalAction: PropTypes.func.isRequired,
  identifierRemovalAction: PropTypes.func.isRequired,
  phoneRemovalAction: PropTypes.func.isRequired,
  address: PropTypes.object,
  dateOfBirth: PropTypes.object,
  email: PropTypes.object,
  firstName: PropTypes.object,
  gender: PropTypes.object,
  identifiers: PropTypes.array,
  lastName: PropTypes.object,
  middleName: PropTypes.object,
  phones: PropTypes.array,
  unselectableAttributes: PropTypes.array
}

export default CompositePatient
