import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getRecord, getAssociation } from '@selectors'
import { loadReferralForFollowupStep } from '@scenes/resourceSites/actions'
import IntakeQuestionsForm from './IntakeQuestionsForm'
import ModalContentSeparator from '@components/Modal/ContentSeparator'
import ModalSubheader from './ModalSubheader'
import Spacing from '@components/Spacing'
import Text from '@components/Text'

const FollowupStep = (props) => {
  useEffect(() => {
    props.loadReferralForFollowupStep(props.referralID)
  }, [props.referralID])

  return (
    <div>
      <ModalSubheader name={props.data.name} />

      <ModalContentSeparator />

      <Spacing marginBottom={2}>
        <Text tag='h4' style='sub-header-small'>
          {window.local.t('referral_modal.copy.additional_questions')}
        </Text>
      </Spacing>

      {props.intakeQuestionSet && <section>
        <IntakeQuestionsForm
          handleChange={props.handleChange}
          intakeQuestionSet={props.intakeQuestionSet}
          modalState={props.modalState}
        />
      </section>}
    </div>
  )
}

FollowupStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  loadReferralForFollowupStep: PropTypes.func.isRequired,
  modalState: PropTypes.object.isRequired,
  referralID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  data: PropTypes.object,
  intakeQuestionSet: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  const referral = getRecord(state, 'referral', ownProps.referralID)
  const intakeQuestionSet = getAssociation(state, referral, 'intakeQuestionSet')

  return {
    intakeQuestionSet
  }
}

const mapDispatchToProps = {
  loadReferralForFollowupStep
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowupStep)
