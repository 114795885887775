import React from 'react'

import Grid from '@material-ui/core/Grid'
import Spacing from '@components/Spacing'
import Text from '@components/Text'
import VimeoVideo from '@components/VimeoVideo'

import './ClosedLoopReferralsThankYou.scss'
import '@root/components/Button/Button.scss'

const HEALTHIFY_VIMEO_ID = 431556956

const ClosedLoopReferralsThankYou = () => {
  const { local } = window

  return (
    <div className='ClosedLoopReferralsThankYou'>
      <div className='ClosedLoopReferralsThankYou-pageContent'>
        <Spacing marginX={3}>
          <Grid container spacing={5}>
            <Grid item sm={11} md={6}>
              <Text style='page-header' display='block'>
                {local.t('closed_loop_referrals.thank_you.title')}
              </Text>

              <Spacing marginTop={3}>
                <Text style='sub-header-medium' display='block'>
                  {local.t('closed_loop_referrals.thank_you.subtitle')}
                </Text>
              </Spacing>

              <Spacing marginTop={2}>
                <Text style='body-text' display='block'>
                  {local.t('closed_loop_referrals.thank_you.body')}
                </Text>
                <a href="mailto: networks@wellsky.com">networks@wellsky.com.</a>
              </Spacing>
              <Spacing marginTop={6}>
                <Grid container spacing={5}>
                  <Grid item md={6}>
                    <a className='Button--primaryDark' href='https://www.healthify.us'>
                      {local.t('closed_loop_referrals.thank_you.links.website')}
                    </a>
                  </Grid>
                </Grid>
              </Spacing>
            </Grid>
            <Grid item sm={11} md={6}>
              <VimeoVideo
                videoId={HEALTHIFY_VIMEO_ID}
                title={local.t('closed_loop_referrals.thank_you.video.title')}
              />
            </Grid>
          </Grid>
        </Spacing>
      </div>
    </div>
  )
}

export default ClosedLoopReferralsThankYou
