import {
  CLOSE_PUBLIC_TEXT_MODAL,
  OPEN_PUBLIC_TEXT_MODAL,
  UNAUTHENTICATED_TOGGLE_KEY,
  SURVEY_RESULTS_META_INFORMATION
} from '@scenes/unauthenticated/constants'
import { loadEntities } from '@actions'
import unauthenticatedService from '@services/unauthenticatedService'

export const closePublicTextModal = () => ({ type: CLOSE_PUBLIC_TEXT_MODAL })
export const openPublicTextModal = (key) => (dispatch) => {
  dispatch({ type: OPEN_PUBLIC_TEXT_MODAL, payload: { key } })
}

export const initializeClosedLoopReferral = (referralUuid) => (dispatch) => {
  unauthenticatedService.closedLoopReferrals.get(referralUuid).then(response => {
    dispatch(loadEntities(response))
  })
}

export const getMatchedResourceSites = (surveyId, screeningClient) => {
  return (dispatch) => {
    unauthenticatedService.matchedResourceSites.get(surveyId, screeningClient).then(response => {
      if (response.meta.has_public_search === true) {
        dispatch(toggleKey('hasPublicSearch', true))
      }

      const {
        survey_results_map_center: surveyResultsMapCenter,
        find_more_resources_link: findMoreResourcesLink,
        screening_home_link: screeningHomeLink,
        print_link: printLink,
        patient_information: patientInformation
      } = response.meta

      dispatch(addSurveyResultsInformation({ surveyResultsMapCenter, findMoreResourcesLink, screeningHomeLink, printLink, patientInformation }))
      dispatch(loadEntities(response))
    })
  }
}

export const toggleKey = (key, toggleValue) => ({
  type: UNAUTHENTICATED_TOGGLE_KEY,
  payload: { key, toggleValue }
})

const addSurveyResultsInformation = (payload) => ({
  type: SURVEY_RESULTS_META_INFORMATION,
  payload
})
